import { userRoles } from '../../../constants'
import { useAuth } from 'context/auth'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { webRoutes } from 'lib'

type Props = {
    allowedGeneralRoles: Array<string>
}

const ProtectedRoute: React.FC<Props> = ({ allowedGeneralRoles }) => {
  const { currentUser } = useAuth()
  return(
    Object.values(userRoles).find((role) => allowedGeneralRoles.includes(role.id))
      ? <Outlet /> 
      : currentUser 
        ? <Navigate to={'/unauthorized'} state={{ from: location }} replace />
        : <Navigate to={webRoutes.HOME} state={{ from: location }} replace />
  )
}

export default ProtectedRoute
