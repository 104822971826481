import React, { memo, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { leavesTypes, months } from '../../../constants'
import UserProfileLeaveBox from '../components/leave-box'
import { useUtility } from 'context/utility'
import { useGetUserLeaveCounters } from 'hooks/user-leave-counters/useGetUserLeaveCounters'
import Loader from 'components/reusable/Loader'
import { AdminUserLeave } from 'types/AdminUserLeave'

const leavesObject: { [k: string]: number } = {
  sick_leaves: 0,
  unpaid_leaves: 0,
  day_offs: 0,
  paid_leaves: 0,
  vacations: 0,
  extra_sick_leaves: 0,
  work_offs: 0,
}

const leaveRegex = /(full_day_|half_day_)/g

const UserProfileLeaves = () => {
  const { width } = useUtility()
  const userId = Number(useParams().userId)
  const getUsersLeaves = useGetUserLeaveCounters()

  const [leaves, isLoading] = useSelector(({ userLeaves: { indexData, isLoading } }): [AdminUserLeave, boolean] => [
    indexData,
    isLoading,
  ])

  const [index, setIndex] = useState(Object.keys(leavesTypes)[0])

  useEffect(() => {
    getUsersLeaves(userId)
  }, [])

  if (isLoading) <Loader />

  const byMonthRaw = leaves.by_month
  const totalRaw = leaves.total[0]

  const total = useMemo(() => {
    const obj = { ...leavesObject }
    Object.keys(totalRaw || {}).forEach((key) => {
      if (key.includes('full')) {
        obj[key.replace('full_day_', '')] += totalRaw[key]
        return
      }
      if (key.includes('half')) {
        obj[key.replace('half_day_', '')] += totalRaw[key] / 2
        return
      }
      if (key.includes('user')) return
      obj[key] = totalRaw[key]
    })
    return obj
  }, [totalRaw])

  const byMonth = useMemo(() => {
    const result: { [m: string]: { [k: string]: number } } = {}

    Object.keys(byMonthRaw).forEach((month) => {
      const obj = { ...leavesObject }

      Object.keys(byMonthRaw[month]).forEach((lt) => {
        const key = lt + 's'

        if (leaveRegex.test(key)) {
          obj[key.replace(leaveRegex, '')] += key.includes('half') ? byMonthRaw[month][lt] / 2 : byMonthRaw[month][lt]
          return
        }

        if (key.includes('user')) return
        obj[key] = byMonthRaw[month][lt]
      })

      result[month as string] = obj
    })
    return result
  }, [byMonthRaw])

  return (
    <>
      <h3 className="user-profile__title">Leaves</h3>
      <p className="user-profile__leaves-counter">Total Used: {0}</p>
      <div className="user-profile__leaves-wrapper">
        {Object.entries(leavesTypes).map(([raw, parsed]) => (
          <UserProfileLeaveBox
            key={parsed}
            counter={total?.[raw]}
            type={raw as keyof typeof leavesTypes}
            active={raw === index}
            onClick={() => setIndex(raw)}
          />
        ))}
      </div>

      <table className="user-profile__table user-leaves">
        <thead className="user-profile__table-thead">
          <tr>
            <th className="user-profile__table-th">Month</th>
            {Object.values(leavesTypes).map((lt) => (
              <th key={lt} className="user-profile__table-th">
                {lt}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {months.map((m: string) => (
            <tr className="user-profile__table-tr no-bg" key={m}>
              <td className="user-profile__table-td">{m}</td>
              {width < 991.98 ? (
                <td className="user-profile__table-td">{byMonth?.[m]?.[index] || 0}</td>
              ) : (
                Object.keys(leavesTypes).map((lt) => (
                  <td key={lt} className="user-profile__table-td">
                    {byMonth?.[m]?.[lt] || 0}
                  </td>
                ))
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default memo(UserProfileLeaves)
