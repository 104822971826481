import React from 'react'

const Loader = () => (
  <div className="d-flex justify-content-center">
    <div className="spinner-border spinner-custom" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
)

export default Loader
