import React, { memo, SyntheticEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import Modal from '../../reusable/Modal'
import CategoriesList from '../CategoriesList'
import CreateCategory from '../CreateCategory'
import { useGetCategories } from 'hooks/category/useGetCategories'
import { useDeleteCategory } from 'hooks/category/useDeleteCategory'
import useToggle from 'hooks/useToggle'
import ReactButton from '../../reusable/ReactButton'
import ReactForm from 'components/reusable/ReactForm'
import { Category } from 'types/Category'

const ManageCategories = () => {
  const getCategoriesHook = useGetCategories()
  const deleteCategoryHook = useDeleteCategory()
  const _categories = useSelector((state: { categories: { indexData: Category[] } }) => state.categories.indexData)

  const [open, toggle] = useToggle(false)
  const [search, setSearch] = useState('')
  const [categories, setCategories] = useState<Category[]>([])

  useEffect(() => {
    getCategoriesHook()
  }, [])

  useEffect(() => {
    if (_categories.length) setCategories(_categories)
  }, [_categories])

  useEffect(() => {
    if (search) setCategories(_categories.filter((c) => c.name === search))
  }, [search])

  function removeCategory(id: number) {
    if (window.confirm('Are you sure?')) {
      deleteCategoryHook(id)
    }
  }

  const createCategory = <CreateCategory closeModal={toggle} />

  const onSearchCategorySubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    categories
  }

  const onSearchCategoryChange = (e: SyntheticEvent<Element, Event>, value: Category | null) => {
    if (value?.name == null) {
      getCategoriesHook()
    } else {
      setSearch(value.name)
    }
  }

  return (
    <div>
      <div className="manage-categories__row">
        <div className="manage-categories__col">
          <ReactButton text={'Add Category'} onClick={toggle} variant="contained" />

          {open && <Modal title="Create Category" component={createCategory} open={open} handleClose={toggle} />}
          <ReactForm onSubmit={onSearchCategorySubmit}>
            <Autocomplete
              options={categories}
              getOptionLabel={(option) => option.name}
              onChange={onSearchCategoryChange}
              sx={{ width: 230, marginLeft: 2 }}
              renderInput={(params) => <TextField {...params} label="Category" />}
            />
          </ReactForm>
        </div>
      </div>

      <div className="manage-categories__row">
        <div className="manage-categories__table">
          <h3>Categories List</h3>
          <CategoriesList deleteCategory={removeCategory} categories={categories} />
        </div>
      </div>
    </div>
  )
}

export default memo(ManageCategories)
