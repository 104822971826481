import { useDispatch } from 'react-redux'

import { useGetAdminRequests, useGetRequests } from './useGetRequests'
import { createRequest, GetAdminRequest } from 'services/API/request'
import { addRequest, addRequestFailure, addRequestSuccess } from '../../store/requestsSlice'
import { Pages } from 'enums/StartPage'
import successNotify from '../../helpers/successNotify'
import dangerNotify from '../../helpers/dangerNotify'

export const useCreateRequest = () => {
  const getRequestsHook = useGetRequests()
  const dispatch = useDispatch()

  return (request: FormData, day: string | null, month: string | null) => {
    dispatch(addRequest())
    createRequest(request)
      .then((response) => {
        dispatch(addRequestSuccess(response.data))
        successNotify('Request created')
        getRequestsHook(Pages.START_PAGE, day, month)
      })
      .catch((error) => {
        dispatch(addRequestFailure(error))
        dangerNotify(error.response.data.message[0])
      })
  }
}

export const useCreateAdminRequest = () => {
  const getRequestsHook = useGetAdminRequests()
  const dispatch = useDispatch()

  return async (params: GetAdminRequest & { request: FormData }) => {
    dispatch(addRequest())
    await createRequest(params.request)
      .then((response) => {
        dispatch(addRequestSuccess(response.data))
        getRequestsHook(params)
        successNotify('Request created')
      })
      .catch((error) => {
        dispatch(addRequestFailure(error))
        dangerNotify(error.response.data.message[0])
      })
  }
}
