import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'

interface Props {
  params: TextFieldProps
}

const DatePickerTextField: React.FC<Props> = ({ params }) => {
  return (
    <TextField
      className="form_select__datepicker"
      variant="standard"
      {...params}
      onMouseDown={(event) => event.preventDefault()}
    />
  )
}

export default DatePickerTextField
