import React from 'react'
import Box from '@mui/material/Box'

import ReactInput from '../../../../reusable/ReactInput'
import { UseFormRegister } from 'react-hook-form'
import { IBookInputs } from './CvAddBook'

interface IProps {
  register: UseFormRegister<IBookInputs>
  onImageChange: (event: { target: { files: React.SetStateAction<undefined>[] } }) => void
}
const CvBookForm: React.FC<IProps> = ({ register, onImageChange }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <ReactInput
          className="modal__input"
          type="text"
          size="medium"
          label="Title"
          register={register}
          registerControl="title"
        />
        <ReactInput
          className="modal__input"
          type="text"
          size="medium"
          label="Author"
          register={register}
          registerControl="author"
        />
        <input type="file" id="upload-file" accept="image/*" multiple={false} onChange={onImageChange} />
      </Box>
    </>
  )
}

export default CvBookForm
