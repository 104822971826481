import React, { memo, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { useUtility } from 'context/utility'
import { useSelector } from 'react-redux'

import { EditIcon, TrashIcon } from 'icons'
import { UserProfileTabCard } from '../components/tab-card'
import { useGetInventories } from 'hooks/inventory/useGetInventories'
import { Inventory } from 'types'
import Loader from 'components/reusable/Loader'
import Modal from 'components/reusable/Modal'
import { UserProfileInventoryForm } from '../forms/inventory-form'
import { useDeleteInventory } from 'hooks/inventory/useDeleteInventory'

const UserProfileInventory = () => {
  const { width } = useUtility()
  const getInventory = useGetInventories()
  const deleteInventory = useDeleteInventory()

  const [inventory, loading] = useSelector(({ inventories: { indexData, isLoading } }): [Inventory[], boolean] => [
    indexData,
    isLoading,
  ])
  const profileId = useSelector(
    ({
      profile: {
        showData: { id },
      },
    }) => id,
  )

  const [modalState, setModalState] = useState<{ state: boolean; type: string; item: Inventory | undefined }>({
    state: false,
    type: '',
    item: undefined,
  })

  const toggleModal = (type: string, item?: Inventory) => () => {
    setModalState((ps) => ({ state: !ps.state, type, item }))
  }

  const handleDelete = (id?: number) => {
    if (profileId && id) deleteInventory(id, profileId)
  }

  useEffect(() => {
    if (profileId) getInventory(profileId)
  }, [profileId])

  if (loading) return <Loader />

  return (
    <>
      <h3 className="user-profile__title">Inventory</h3>
      {!inventory.length ? (
        <p>This user has no inventory</p>
      ) : width < 991.98 ? (
        <>
          {inventory.map((i) => (
            <UserProfileTabCard
              key={i.device + i.id}
              item={i}
              onEdit={toggleModal('Update', i)}
              onDelete={() => handleDelete(i.id)}
              isInventory
            />
          ))}
        </>
      ) : (
        <>
          <table className="user-profile__table">
            <thead className="user-profile__table-thead">
              <tr>
                <th className="user-profile__table-th">Device</th>
                <th className="user-profile__table-th">Device name</th>
                <th className="user-profile__table-th">Serial number</th>
                <th className="user-profile__table-th">Actions</th>
              </tr>
            </thead>
            <tbody>
              {inventory.map((i) => (
                <tr className="user-profile__table-tr" key={i.id + i.device}>
                  <td className="user-profile__table-td text-capitalize">{i.device}</td>
                  <td className="user-profile__table-td">{i.name}</td>
                  <td className="user-profile__table-td">{i.serial_number}</td>
                  <td className="user-profile__table-td">
                    <Button onClick={toggleModal('Update', i)}>
                      <EditIcon />
                    </Button>
                    <Button onClick={() => handleDelete(i.id)}>
                      <TrashIcon />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      <Button variant="contained" onClick={toggleModal('Create')}>
        Add Item
      </Button>

      <Modal
        title={`${modalState.type} Inventory Item`}
        open={modalState.state}
        handleClose={toggleModal(modalState.type)}
        component={
          <UserProfileInventoryForm
            onCancel={toggleModal(modalState.type)}
            type={modalState.type}
            item={modalState.item}
          />
        }
      />
    </>
  )
}

export default memo(UserProfileInventory)
