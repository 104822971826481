import React, {  useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { useUpdateCvProject } from '../../../../../hooks/resume/portfolio/useUpdateCvProject'
import { IResumeProject } from '../../../../../types/Resume/IResumeProject'
import { MIN_INPUTS_ELEMENTS } from '../../../../../constants'
import CvPortfolioProjectForm from './CvPortfolioProjectForm'
import EditFormButtons from '../FormButtons/EditFormButtons'

interface Inputs {
  title: string
  url: string
  description: string
  role: string
  team: string
  responsibilities: string
}

interface Props {
  setOpen: (value: boolean) => void
  project: IResumeProject
  cvId: number
}

const CvUpdatePortfolioProject: React.FC<Props> = ({ setOpen, project, cvId }) => {
  const inputFieldsValues = project.stack.map(value => ({ value: value }))
  const [inputFields, setInputFields] = useState(inputFieldsValues)
  const isDisabled = inputFields.length <= MIN_INPUTS_ELEMENTS
  const updateProject = useUpdateCvProject()

  const { register, handleSubmit } = useForm<Inputs>(
    {
      defaultValues: {
        title: project ? project.title : '',
        url: project ? project.url : '',
        description: project ? project.description : '',
        role: project ? project.role : '',
        team: project ? project.team : '',
        responsibilities: project ? project.responsibilities : ''
      }
    })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const projectStack = inputFields.map(({ value }) => value)
    const resume_project = {
      title: data.title,
      url: data.url,
      description: data.description,
      role: data.role,
      resume_id: cvId,
      stack: projectStack,
      team: data.team,
      responsibilities: data.responsibilities
    }
    updateProject(resume_project as IResumeProject, project.id as number, cvId)
    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvPortfolioProjectForm
        inputFields={inputFields}
        isDisabled={isDisabled}
        setInputFields={setInputFields}
        register={register}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <EditFormButtons setOpen={setOpen} />
      </Box>
    </form>
  )
}

export default CvUpdatePortfolioProject
