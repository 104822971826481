import { createSlice } from '@reduxjs/toolkit'

import { IRequest } from '../types'

const initialState = {
  indexData: <IRequest[]>[],
  pages: 1,
  error: {},
  isLoading: true,
  count: 0
}

const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    fetchRequests(state) {
      state.isLoading = true
    },
    fetchRequestsSuccess(state, action) {
      state.indexData = action.payload.paginate_requests
      state.pages = action.payload.pages
      state.isLoading = false
      state.count = action.payload.count
    },
    fetchRequestsFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    addRequest(state) {
      state.isLoading = true
    },
    addRequestSuccess(state, action) {
      state.indexData.push(action.payload)
      state.isLoading = false
    },
    addRequestFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    updateRequest(state) {
      state.isLoading = true
    },
    updateRequestSuccess(state) {
      state.isLoading = false
    },
    updateRequestFailure(state, action) {
      state.error = action.payload.paginate_requests
      state.isLoading = false
    },

    removeRequest(state) {
      state.isLoading = true
    },
    removeRequestSuccess(state, action) {
      state.indexData = state.indexData.filter((request: IRequest) => request.id !== action.payload.paginate_requests)
      state.isLoading = false
    },
    removeRequestFailure(state, action) {
      state.error = action.payload.paginate_requests
      state.isLoading = false
    },
  },
})

export default requestsSlice.reducer
export const {
  fetchRequests,
  fetchRequestsFailure,
  fetchRequestsSuccess,
  addRequest,
  addRequestFailure,
  addRequestSuccess,
  removeRequest,
  removeRequestFailure,
  removeRequestSuccess,
  updateRequest,
  updateRequestFailure,
  updateRequestSuccess,
} = requestsSlice.actions
