import React from 'react'
import { isMobile } from 'react-device-detect'

const ManagerIcon = () => {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.81237 2.99976C8.26008 2.99976 7.81237 
        2.55204 7.81237 1.99976V1.49976V0.999756C7.81237 0.447471 8.26008 -0.000244141 8.81237 
        -0.000244141C9.36465 -0.000244141 9.81237 0.447471 9.81237 0.999756V1.49976V1.99976C9.81237 
        2.55204 9.36465 2.99976 8.81237 2.99976ZM5.69923 5.28326C6.40497 4.46691 7.47301 3.99976 
        8.81237 3.99976C10.1517 3.99976 11.2198 4.46691 11.9255 5.28326C12.615 6.08081 12.8666 
        7.10528 12.8027 8.06611C12.6782 9.93784 11.2756 11.9998 8.81237 11.9998C6.34911 11.9998 
        4.9465 9.93784 4.82202 8.06611C4.75812 7.10528 5.00974 6.08081 5.69923 5.28326ZM6.81761 
        7.9334C6.89265 9.06167 7.68458 9.99976 8.81237 9.99976C9.94016 9.99976 10.7321 9.06167 
        10.8071 7.9334C10.843 7.39423 10.6956 6.91871 10.4125 6.59126C10.1457 6.2826 9.66756 5.99976 
        8.81237 5.99976C7.95718 5.99976 7.47906 6.2826 7.21222 6.59126C6.92914 6.91871 6.78175 
        7.39423 6.81761 7.9334ZM0.0310807 19.5217C0.813557 15.1874 4.98893 13.0613 8.81225 13.0613C12.636 
        13.0613 16.8117 15.1879 17.594 19.5229L17.5941 19.523C17.675 19.9716 17.5984 20.4627 17.3213 
        20.8675C17.0287 21.2948 16.5444 21.5712 15.9732 21.5712H1.65172C1.08093 21.5712 0.596428 21.2951 
        0.303541 20.868C0.0260303 20.4633 -0.0509997 19.9717 0.0310807 19.5217ZM2.06374 19.5712H15.5611C14.8718 
        16.7211 11.9568 15.0613 8.81225 15.0613C5.66759 15.0613 2.75274 16.7212 2.06374 19.5712ZM14.8124 
        5.99976C14.2601 5.99976 13.8124 6.44747 13.8124 6.99976C13.8124 7.55204 14.2601 7.99976 14.8124 
        7.99976H15.3124H15.8124C16.3647 7.99976 16.8124 7.55204 16.8124 6.99976C16.8124 6.44747 16.3647 
        5.99976 15.8124 5.99976H15.3124H14.8124ZM1.81237 5.99976C1.26008 5.99976 0.812369 6.44747 
        0.812369 6.99976C0.812369 7.55204 1.26008 7.99976 1.81237 7.99976H2.81237C3.36465 7.99976 3.81237 
        7.55204 3.81237 6.99976C3.81237 6.44747 3.36465 5.99976 2.81237 5.99976H1.81237ZM12.7318 3.96013C12.3412 
        3.56961 12.3412 2.93644 12.7318 2.54592L13.4389 1.83881C13.8294 1.44829 14.4626 1.44829 14.8531 
        1.83881C15.2436 2.22934 15.2436 2.8625 14.8531 3.25303L14.146 3.96013C13.7555 4.35066 13.1223 
        4.35066 12.7318 3.96013ZM4.18726 1.83902C3.79674 1.4485 3.16357 1.4485 2.77305 1.83902C2.38253 
        2.22955 2.38253 2.86271 2.77305 3.25323L3.48016 3.96034C3.87068 4.35087 4.50385 4.35087 4.89437 
        3.96034C5.28489 3.56982 5.28489 2.93665 4.89437 2.54613L4.18726 1.83902Z" fill={!isMobile ? 'white' : 'black'}/>
    </svg>
  )
}

export default ManagerIcon
