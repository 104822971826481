import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import uaLocale from 'date-fns/locale/uk'
import { DialogActions, SxProps } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'
import { isMobile } from 'react-device-detect'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import DatePickerInput from './DatePickerInput'
import CustomButton from 'components/custom/CustomButton/CustomButton'
import CalendarIcon from 'icons/CalendarIcon'

type Props = {
  value: Date | string | null
  onChange: (value: Date | null, keyboardInputValue?: string | undefined) => void
  mask?: string
  minDate?: null | Date | string
  maxDate?: null | Date | string
  arrowNextItem?: () => void
  arrowBackItem?: () => void
  disableMinusArrow?: boolean
  disablePlusArrow?: boolean
  inputType: 'day' | 'month' | 'default'
  datePickerType?: 'day' | 'month'
  placeholder?: string
  borderInputColor?: string
  open?: boolean
  onOpen?: () => void
  onClose?: () => void
  disableClearButton?: boolean
  width?: string
}

const CustomDatepicker: React.FC<Props> = ({
  value,
  onChange,
  mask,
  minDate,
  maxDate,
  arrowNextItem,
  arrowBackItem,
  disableMinusArrow,
  disablePlusArrow,
  inputType,
  placeholder,
  datePickerType,
  borderInputColor,
  open,
  onOpen,
  onClose,
  disableClearButton,
  width = '300px',
}) => {
  const CustomActionBar = (props: PickersActionBarProps) => {
    const { onClear, onAccept } = props

    return (
      <DialogActions>
        <div className="action-bar__container">
          <div className="action-bar__divider"></div>
          <div className="action-bar__buttons" style={{ gap: !isMobile ? '10px' : '20px' }}>
            <CustomButton
              style={{ backgroundColor: 'white', color: '#6172F3', border: '2px solid #EEEFF4', height: '50px' }}
              onClick={() => {
                onClear()
              }}
              text="Clear"
              disabled={disableClearButton}
            />
            <CustomButton
              style={{ backgroundColor: '#6172F3', color: 'white', height: '50px' }}
              onClick={() => {
                onAccept()
              }}
              text="Apply"
            />
          </div>
        </div>
      </DialogActions>
    )
  }

  const popperSx: SxProps = {
    // '& .css-1dozdou': {
    //   border: '1px solid #D4D9E2',
    //   borderRadius: '10px',
    //   padding: '30px 10px',
    //   margin: '20px'
    // },
    '& .Mui-selected': {
      borderRadius: '10%',
      backgroundColor: '#6172F3 !important',
      color: '#FFF !important'
    },
    '& .MuiTabs-root': { backgroundColor: 'rgba(120, 120, 120, 0.4)' },
    '& .PrivatePickersSlideTransition-root': { minHeight: '195px' },
    '& .MuiButtonBase-root-MuiPickersDay-root': { borderRadius: '10%' },
    '& .MuiMonthPicker-root': { flexDirection: 'column' },
    '& .MuiMonthPicker-root > button': {
      borderRadius: '4px !important',
      padding: '8px 0',
      marginRight: '10px',
      marginLeft: '10px'
    },
    '& .MuiCalendarPicker-viewTransitionContainer': { overflowX: 'hidden' },
    '& .MuiCalendarPicker-viewTransitionContainer::-webkit-scrollbar': { width: 0 },
    '& .PrivatePickersYear-yearButton': { borderRadius: '4px !important' },
  }

  return (
    <LocalizationProvider dateFormats={{ monthShort: 'MMMM' }} dateAdapter={AdapterDateFns} locale={uaLocale}>
      {datePickerType === 'month' ? (
        <DesktopDatePicker
          value={value}
          onChange={onChange}
          mask={mask}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          openTo="month"
          minDate={minDate}
          maxDate={maxDate}
          views={['year', 'month']}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <DatePickerInput
              inputRef={inputRef}
              inputProps={inputProps}
              InputProps={InputProps}
              arrowNextItem={arrowNextItem}
              arrowBackItem={arrowBackItem}
              disableMinusArrow={disableMinusArrow}
              disablePlusArrow={disablePlusArrow}
              inputType={inputType}
              placeholder={placeholder}
              borderInputColor={borderInputColor}
              onOpen={onOpen}
              width={width}
            />
          )}
          inputFormat="MMMM yyyy"
          InputProps={{
            startAdornment: (
              <div onClick={onOpen}>
                <ExpandMoreIcon />
              </div>
            )
          }}
          components={{
            ActionBar: CustomActionBar,
            OpenPickerIcon: CalendarIcon
          }}
          PopperProps={{
            sx: popperSx
          }}
          componentsProps={{
            actionBar: {
              actions: ['clear', 'accept']
            }
          }}
        />
      ) : (
        <DesktopDatePicker
          value={value}
          onChange={onChange}
          mask={mask}
          minDate={minDate}
          maxDate={maxDate}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          renderInput={({ inputRef, inputProps, InputProps }) => (
            <DatePickerInput
              inputRef={inputRef}
              inputProps={inputProps}
              InputProps={InputProps}
              arrowNextItem={arrowNextItem}
              arrowBackItem={arrowBackItem}
              disableMinusArrow={disableMinusArrow}
              disablePlusArrow={disablePlusArrow}
              borderInputColor={borderInputColor}
              inputType={inputType}
              placeholder={placeholder}
              width={width}
            />
          )}
          inputFormat={inputType === 'month' ? 'PPP' : 'dd.MM.yyyy'}
          InputProps={{ startAdornment: <ExpandMoreIcon /> }}
          components={{
            ActionBar: CustomActionBar,
            OpenPickerIcon: CalendarIcon
          }}
          PopperProps={{
            sx: popperSx
          }}
          componentsProps={{
            actionBar: {
              actions: ['clear', 'accept']
            }
          }}
        />
      )}
    </LocalizationProvider>
  )
}

export default CustomDatepicker
