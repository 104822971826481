import React from 'react'
import { Link } from 'react-router-dom'
import Switch from 'react-switch'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material'

import { candidateTitleTable, englishLevel, significate } from '../../../constants'
import { webRoutes } from '../../../lib'
import { tableHeadStyles } from 'utilts/styles'
import { ItemsOnPage } from 'enums/DashboardEnums'
import { Vacancy } from 'types'

interface Props {
  vacancy: Vacancy,
  candidates: []
  onUpdateCandidate: (id: number, ping_again: boolean) => void,
  candidatesCount: number,
  page: number
}

const CandidatesTable: React.FC<Props> = ({ 
  vacancy,
  candidates,
  onUpdateCandidate,
  candidatesCount,
  page 
}) => {
  const limit = ItemsOnPage.LIMIT
  let numbering = 1

  const onPing = (checked: boolean, id: number) => {
    const params = {
      id: id,
      ping_again: checked
    }
    onUpdateCandidate(params)
  }

  const onSignificateUpdate = (e, id) => {
    const params = {
      id: id,
      significate: e.target.value
    }
    onUpdateCandidate(params)
  }

  return (
    <div className="table-wrapper">
      <div className="table-heading">
        <span>{vacancy.title} <small className="text-secondary">{candidates && candidatesCount}</small></span>
      </div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className="requests-table__title">
            <TableRow>
              {candidateTitleTable.map((title, index) => {
                return <TableCell key={index} align="left" sx={tableHeadStyles}>
                  {title}
                </TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {candidates?.map((candidate, index) => {
              const sign = significate[candidate.significate.toUpperCase()]
              const color = sign.color

              return (
                <TableRow key={index}>
                  <TableCell className={'bg-' + candidate.significate}>
                    {page > 1 ?
                      `${numbering < limit ? page - 1 : ''}${numbering < limit ? numbering++ : page * limit}` :
                      numbering++
                    }
                  </TableCell>
                  <TableCell>
                    <Link to={webRoutes.CANDIDATE(candidate.id)}>
                      {candidate.full_name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <ul className="candidate-contacts">
                      <li>Mobile: {candidate.phone}</li>
                      <li>Email: {candidate.email}</li>
                      <li>Telegram: {candidate.telegram}</li>
                      <li>LinkedIn: {candidate.linkedin && 
                        <a href={candidate.linkedin} target="_blank" rel="noreferrer">
                          tap here to view
                        </a>}
                      </li>
                    </ul>
                  </TableCell>
                  <TableCell>
                    {candidate.experience}
                  </TableCell>
                  <TableCell>
                    {candidate.salary}
                  </TableCell>
                  <TableCell>
                    {candidate ? englishLevel[candidate.english.toUpperCase()].label : ''}
                  </TableCell>
                  <TableCell>
                    <Switch
                      onColor="#32D583"
                      checkedIcon={false}
                      uncheckedIcon={false}
                      width={50}
                      offColor="#D4D9E2"
                      height={30}
                      onChange={(checked) => onPing(checked, candidate.id)}
                      checked={candidate.ping_again} />
                  </TableCell>
                  <TableCell className={'bg-' + candidate.significate}>
                    {candidate ? <div className="label-significate" style={{ color: color }}>
                      <select
                        className="significate-select-main"
                        placeholder="Significate"
                        name="significate"
                        onChange={(e) => onSignificateUpdate(e, candidate.id)}
                        value={sign.value}>
                        {Object.values(significate).map((s) => (
                          <option key={s.value} value={s.value}>{s.name}</option>
                        ))}
                      </select>
                    </div> : ''}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default CandidatesTable
