import { useDispatch } from 'react-redux'

import { deleteContract } from '../../services/API/admin/contracts'
import { removeContract, removeContractFailure, removeContractSuccess } from '../../store/contractsSlice'
import { useGetContracts } from './useGetContracts'
import { getContractsParams, getUsersParams } from '../../constants'
import successNotify from '../../helpers/successNotify'
import dangerNotify from '../../helpers/dangerNotify'
import { useGetUserProjects } from '../project/useGetProjects'
import { useGetUsers } from '../user/useGetUsers'

export const useDeleteContract = () => {
  const getContractsHook = useGetContracts()
  const getUserProjectsHook = useGetUserProjects()
  const getUsers = useGetUsers()
  const dispatch = useDispatch()

  return (contract_id: string | undefined) => {
    dispatch(removeContract())
    deleteContract(contract_id)
      .then(() => {
        dispatch(removeContractSuccess(contract_id))
        getContractsHook(getContractsParams.userId, getContractsParams.projectId, getContractsParams.statusId)
        getUserProjectsHook()
        getUsers(
          getUsersParams.active,
          getUsersParams.user_id,
          getUsersParams.project_id,
          getUsersParams.status_id,
          getUsersParams.manager_id
        )
        successNotify('Contract deleted')
      })
      .catch((error) => {
        dispatch(removeContractFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
