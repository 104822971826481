export enum SelectType {
  USER = 'User',
  PROJECT = 'Project',
  EMPLOYMENT = 'Employment',
  COMPANY = 'Company',
  TECH_STACK = 'Tech stack',
  MANAGER = 'Manager',
  STATUS = 'Status',
  REPRESENTATIVE = 'Representative',
  CATEGORY = 'Category'
}

export enum SelectPlaceholder {
  ALL_USERS = 'All Users',
  ALL_PROJECTS = 'All Projects',
  ALL_EMPLOYMENTS = 'All Employment Statuses',
  ALL_COMPANIES = 'All Companies',
  ALL_TECH_STACKS = 'All Tech stacks',
  ALL_MANAGERS = 'All Managers',
  ALL_STATUSES = 'All Statuses',
  ALL_REPRESENTATIVES = 'All Representatives',
  ALL_CATEGORIES = 'All Categories'
}
