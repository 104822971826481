import { jobStatusesNames } from '../constants'

export const statusColor = (status: string) => {
  return status === jobStatusesNames.FREE ? 'status_free' :
    status === jobStatusesNames.INNER_PROJECT ? 'status_inner' : ''
}

export const statusColorMobile = (status: string) => {
  return status === jobStatusesNames.FREE ? 'status_free-mob' :
    status === jobStatusesNames.INNER_PROJECT ? 'status_inner-mob' : 'project-profile__card-status'
}
  
