import React from 'react'
import Select, { StylesConfig } from 'react-select'
import Box from '@mui/material/Box'

import { Customer, Project, User } from '../../../types'
import { applications, BENCH_MANAGER, fromScratchValues } from '../../../constants'
import { Technology } from '../../../types'
import { renderManager } from '../../../helpers/renderName'
import { IOptions, IStringOptions } from 'types/ISelectOptions'
import { Pages } from '../../../enums/StartPage'

interface OptionTypes {
  value: number | undefined
  label: string | undefined
}

interface Props {
  projects: Project[]
  technologies: Technology[]
  customers: Customer[]
  usersNames: User[]
  setTechnology: (technology: IOptions) => void
  setApplication: (application: IOptions) => void
  setProject: (project: IOptions) => void
  setCompany: (company: IOptions) => void
  setManager: (manager: IOptions) => void
  setFromScratch: (value: IStringOptions) => void
  setPage: (page: number) => void
  project: IOptions | null
  manager: IOptions | null
  technology: IOptions | null
  company: IOptions | null
  application: IOptions | null
  fromScratch: IStringOptions | null
}

const ProjectsFilters: React.FC<Props> = ({
  projects,
  technologies,
  customers,
  usersNames,
  setTechnology,
  setApplication,
  setManager,
  setProject,
  setCompany,
  setFromScratch,
  setPage,
  project,
  manager,
  technology,
  company,
  application,
  fromScratch
}) => {
  const filteringProjects = projects?.map((project) => ({
    value: project.id,
    label: project.name,
  }))

  const techStack = technologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }))

  const companies = customers?.map((company) => ({
    value: company.id,
    label: company.company_name,
  }))

  const managers = projects?.filter(project =>
    renderManager(project.id, projects as Project[], usersNames) != BENCH_MANAGER)
    .map((project) => ({
      value: project.manager_id,
      label: renderManager(project?.id, projects as Project[], usersNames)
    })) as IOptions[]

  const uniqueManagers = managers?.filter(
    (value, index, array) => array.findIndex((val) =>
      val.label != undefined && val.label === value.label) === index)

  const fromScratchOptions = fromScratchValues?.map(item => ({
    value: item.value,
    label: item.label,
  }))

  const applicationItems = applications?.map((value, index) => ({
    value: index,
    label: value,
  }))

  const onProjectChange = (option: IOptions) => {
    setProject(option)
    setPage(Pages.START_PAGE)
  }

  const onTechnologyChange = (technology: IOptions) => {
    setTechnology(technology)
    setPage(Pages.START_PAGE)
  }

  const onCompanyChange = (company: IOptions) => {
    setCompany(company)
    setPage(Pages.START_PAGE)
  }

  const onApplicationChange = (application: IOptions) => {
    setApplication(application)
    setPage(Pages.START_PAGE)
  }

  const onManagerChange = (manager: IOptions) => {
    setManager(manager)
    setPage(Pages.START_PAGE)
  }

  const onFromScratchChange = (value: IStringOptions) => {
    setFromScratch(value)
    setPage(Pages.START_PAGE)
  }

  const styles: StylesConfig<OptionTypes | IStringOptions, false> = {
    container: (base) => ({
      ...base,
      width: 230,
      marginLeft: 10,
    }),
    option: (base) => ({
      ...base,
      color: 'black',
      backgroundColor: 'white',
      '&::selection': {
        backgroundColor: 'white',
        color: 'black',
      },
      '&:hover': {
        backgroundColor: '#dfe8ff',
        color: 'black',
      },
    }),
  }

  return (
    <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '1rem' }}>
      <Select
        isClearable={true}
        placeholder="Project"
        options={filteringProjects}
        onChange={(option) => onProjectChange(option as IOptions)}
        styles={styles}
        value={project}
      />
      <Select
        isClearable={true}
        placeholder="Company"
        options={companies}
        onChange={(option) => onCompanyChange(option as IOptions)}
        styles={styles}
        value={company}
      />
      <Select
        isClearable={true}
        placeholder="Tech stack"
        options={techStack}
        onChange={(option) => onTechnologyChange(option as IOptions)}
        styles={styles}
        value={technology}
      />
      <Select
        isClearable={true}
        placeholder="From scratch"
        options={fromScratchOptions}
        onChange={(option) => onFromScratchChange(option as IStringOptions)}
        styles={styles}
        value={fromScratch}
      />
      <Select
        isClearable={true}
        placeholder="Web/mobile"
        options={applicationItems}
        onChange={(option) => onApplicationChange(option as IOptions)}
        styles={styles}
        value={application}
      />
      <Select
        isClearable={true}
        placeholder="Manager"
        options={uniqueManagers}
        onChange={(option) => onManagerChange(option as IOptions)}
        styles={styles}
        value={manager}
      />
    </Box>
  )
}

export default React.memo(ProjectsFilters)
