import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Pagination } from '@mui/material'

import { useUtility } from 'context/utility'
import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import { leaveTypesFormatted, paidLeaveTypes } from '../../../constants'
import { UserProfileTabCard } from '../components/tab-card'
import { IRequest } from 'types'
import Loader from 'components/reusable/Loader'
import { useGetAdminRequests } from 'hooks/request/useGetRequests'
import CustomPagination from 'components/reusable/Pagination/Pagination'

const limit = 10

const UserProfileRequests = () => {
  const { width } = useUtility()
  const user_id = Number(useParams().userId)
  const getUserRequests = useGetAdminRequests()

  const [requests, isLoading, totalPages] = useSelector(
    ({ requests: { indexData, isLoading, pages } }): [IRequest[], boolean, number] => [indexData, isLoading, pages],
  )

  const [page, setPage] = useState(1)

  useEffect(() => {
    getUserRequests({
      page,
      limit,
      user_id,
    })
  }, [page])

  if (isLoading) return <Loader />

  return (
    <div className="user-profile__requests">
      <h3 className="user-profile__title">Requests</h3>
      {width < 991.98 ? (
        <>
          {requests.map((rq) => (
            <UserProfileTabCard item={rq} key={rq.id + 'REQUEST'} isRequest />
          ))}
        </>
      ) : (
        <table className="user-profile__table">
          <thead className="user-profile__table-thead">
            <tr>
              <th className="user-profile__table-th">Type</th>
              <th className="user-profile__table-th">Start Date</th>
              <th className="user-profile__table-th">End Date</th>
              <th className="user-profile__table-th">Status</th>
            </tr>
          </thead>
          <tbody>
            {requests.map(({ id, request_type, start_date, end_date, status, paid_leave_type }) => (
              <tr className="user-profile__table-tr" key={id + 'REQUEST'}>
                <td className={`user-profile__table-td request-${request_type}`}>
                  {request_type === 'paid_leave'
                    ? paidLeaveTypes[paid_leave_type as keyof typeof paidLeaveTypes]
                    : leaveTypesFormatted[request_type]}
                </td>
                <td className="user-profile__table-td">{start_date && dateFormatDDMMYY(start_date)}</td>
                <td className="user-profile__table-td">{end_date && dateFormatDDMMYY(end_date)}</td>
                <td className="user-profile__table-td">
                  <span className={String(status)}>{status}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <CustomPagination totalPages={totalPages} page={page} setPage={setPage} />
    </div>
  )
}

export default memo(UserProfileRequests)
