import { StylesConfig } from 'react-select'

import { Options } from './availableDevsTableRow'
import { IOptions } from '../../../types/ISelectOptions'

export const busyOutstaff: StylesConfig<Options, false> = {
  control: (base) => ({
    ...base,
    border: 0,
    background: '#D1FADF',
    boxShadow: 'none',
  }),
  option: (base, state) => ({
    ...base,
    color: '#303540',
    backgroundColor: state.isSelected ? '#F9F9FA' : '#fff'
  }),
  singleValue: (provided) => {
    const color = '#667085'
    const paddingLeft = '20px'

    return { ...provided, color, paddingLeft, ...dot('#12B76A') }
  }
}

export const busyOutsource: StylesConfig<Options, false> = {
  control: (base) => ({
    ...base,
    border: 0,
    background: '#E0EAFF',
    boxShadow: 'none',
  }),
  option: (base, state) => ({
    ...base,
    color: '#303540',
    backgroundColor: state.isSelected ? '#F9F9FA' : '#fff'
  }),
  singleValue: (provided) => {
    const color = '#667085'
    const paddingLeft = '20px'

    return { ...provided, color, paddingLeft, ...dot('#6172F3') }
  }
}

export const mobileBusyOutstaff: StylesConfig<Options, false> = {
  control: (base) => ({
    ...base,
    border: 0,
    width: '215px',
    boxShadow: 'none',
  }),
  option: (base, state) => ({
    ...base,
    color: '#303540',
    backgroundColor: state.isSelected ? '#F9F9FA' : '#fff',
  }),
  singleValue: (provided) => {
    const color = '#667085'
    const paddingLeft = '20px'
    const fontSize = '12px'

    return { ...provided, color, paddingLeft, fontSize }
  },
  valueContainer: base => ({
    ...base,
    padding: 0
  }),
  dropdownIndicator: base => ({
    ...base,
    marginLeft: '5px'
  }),
  input: (styles) => ({ ...styles, ...dot('#12B76A') })
}

export const mobileBusyOutsource: StylesConfig<Options, false> = {
  control: (base) => ({
    ...base,
    border: 0,
    width: '215px',
    boxShadow: 'none',
  }),
  option: (base, state) => ({
    ...base,
    color: '#303540',
    backgroundColor: state.isSelected ? '#F9F9FA' : '#fff',
  }),
  singleValue: (provided) => {
    const color = '#747F96'
    const paddingLeft = '20px'
    const fontSize = '12px'

    return { ...provided, color, paddingLeft, fontSize }
  },
  valueContainer: base => ({
    ...base,
    padding: 0
  }),
  dropdownIndicator: base => ({
    ...base,
    marginLeft: '5px'
  }),
  input: (styles) => ({ ...styles, ...dot('#6172F3') })
}

export const dot = (color: string) => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 15,
    height: 10,
    width: 10,
  },
})

export const readyOutstaff: StylesConfig<Options, false> = {
  control: (base) => ({
    ...base,
    border: 0,
    background: '#FEF0C7',
    boxShadow: 'none',
  }),
  option: (base, state) => ({
    ...base,
    color: '#303540',
    backgroundColor: state.isSelected ? '#F9F9FA' : '#fff',
  }),
  singleValue: (provided) => {
    const color = '#667085'
    const paddingLeft = '20px'

    return { ...provided, color, paddingLeft,  ...dot('#F79009') }
  }
}

export const mobileReadyOutstaff: StylesConfig<Options, false> = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    width: '215px'
  }),
  option: (base, state) => ({
    ...base,
    color: '#303540',
    backgroundColor: state.isSelected ? '#F9F9FA' : '#fff',
  }),
  singleValue: (provided) => {
    const color = '#747F96'
    const paddingLeft = '15px'
    const fontSize = '12px'

    return { ...provided, color, paddingLeft, fontSize }
  },
  valueContainer: base => ({
    ...base,
    padding: 0
  }),
  dropdownIndicator: base => ({
    ...base,
    marginLeft: '5px'
  }),
  input: (styles) => ({ ...styles, ...dot('#F79009') })
}

export const dashboardFilterSelect: StylesConfig<IOptions, false> = {
  control: (css) =>
    ({ ...css,
      padding: '11px',
      borderRadius: '10px',
      border: '1px solid #D4D9E2',
      fontSize: '1.1em',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #D4D9E2',
      } }),
  menu: (css) => ({
    ...css,
    zIndex: 5,
    borderRadius: '8px'
  }),
  option: (css, { isFocused, isSelected }) => ({
    ...css,
    backgroundColor: isFocused || isSelected ? '#F9F9FA' : 'white',
    color: 'black'
  })
}

export const mobileDashboardFilterSelect: StylesConfig<IOptions, false> = {
  control: (css) =>
    ({ ...css,
      padding: '5px',
      borderRadius: '6px',
      border: '1px solid #D4D9E2',
      fontSize: '15px',
      boxShadow: 'none',
      width: '170px',
      '&:hover': {
        border: '1px solid #D4D9E2',
      } }),
  menu: (css) => ({
    ...css,
    zIndex: 5,
    borderRadius: '8px'
  }),
  option: (css, { isFocused, isSelected }) => ({
    ...css,
    backgroundColor: isFocused || isSelected ? '#F9F9FA' : 'white',
    color: 'black'
  })
}
