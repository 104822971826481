import * as React from 'react'
import ReactSelect, {
  ActionMeta,
  components,
  ControlProps,
  GroupBase,
  IndicatorSeparatorProps,
  MultiValue,
  MultiValueRemoveProps,
  PropsValue,
  SingleValue, 
  StylesConfig 
} from 'react-select'

import { OptionTypes } from 'components/admin/ManageContracts/ContractsFilters'
import { SelectPlaceholder, SelectType } from 'enums/SelectTypes'
import CompanyIcon from 'icons/DoubleSelectIcons/CompanyIcon'
import EmploymentIcon from 'icons/DoubleSelectIcons/EmploymentIcon'
import ManagerIcon from 'icons/DoubleSelectIcons/ManagerIcon'
import ProjectsIcon from 'icons/DoubleSelectIcons/ProjectsIcon'
import RepresentativeIcon from 'icons/DoubleSelectIcons/RepresentativeIcon'
import StatusIcon from 'icons/DoubleSelectIcons/StatusIcon'
import TechStackIcon from 'icons/DoubleSelectIcons/TechStackIcon'
import UsersIcon from 'icons/DoubleSelectIcons/UsersIcon'
import { IOptions } from 'types/ISelectOptions'
import { isMobile } from 'react-device-detect'
import { defaultSelectStyles } from '../DefaultSelect/DefaultSelect'
import RemoveSelectIcon from 'icons/RemoveSelectIcon'
import CategoriesIcon from 'icons/CategoriesIcon'
import Select from 'react-select/dist/declarations/src/Select'
import { IOmitedOptions } from 'containers/MonthlyReportContainer/MonthlyReportContainer'


interface Props {
  prefixIcon?: JSX.Element
  variants: SelectType[]
  users?: IOptions[]
  projects?: IOptions[]
  categories?: IOptions[]
  employment?: IOptions[]
  companies?: IOptions[]
  tech_stack?: IOptions[]
  managers?: IOptions[]
  statuses?: IOptions[]
  representatives?: IOptions[]
  onChange: ((newValue: SingleValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => void)
  selectedValue: MultiValue<IOmitedOptions> | undefined
  setSelectedValue: React.Dispatch<React.SetStateAction<PropsValue<IOmitedOptions> | undefined>>
  typeValue: IOptions | undefined
  setTypeValue: React.Dispatch<React.SetStateAction<PropsValue<MyOptionType> | undefined>>
  selectRef?: React.Ref<Select<MyOptionType, false, GroupBase<MyOptionType>>> | undefined
  isMulti?: boolean
}

type MyOptionType = {
  label: string
  value: number
}

type IsMulti = false

const IndicatorSeparator = ({
  innerProps,
}: IndicatorSeparatorProps<MyOptionType, IsMulti>) => {
  return <span style={{ display: 'none' }} {...innerProps} />
}

const secondSelectStyles: StylesConfig<MyOptionType, IsMulti> = {
  control: (css) => 
    ({ ...css, 
      padding: '10px', 
      borderRadius: '10px', 
      border: 0,
      width: '100%',
      height: '48px',
      alignContent: 'center',
      boxShadow: 'none',
      '&:hover': {
        border: 0,
      } }),
  menu: (css) => ({
    ...css,
    zIndex: 5,
    border: 0,
    boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)'
  }),
  multiValue: (css) => ({
    ...css,
    padding: '2px 10px',
    borderRadius: '6px'
  }),
  option: (css, { isFocused, isSelected }) => ({ 
    ...css, 
    backgroundColor: isFocused || isSelected ? '#F9F9FA' : 'white',
    color: 'black' })
}

const firstSelectStyles: StylesConfig<MyOptionType, IsMulti> = {
  ...secondSelectStyles,
  control: (css) => 
    ({ ...css, 
      padding: '10px', 
      borderRadius: '4px', 
      border: 0,
      height: '48px',
      width: '100%',
      boxShadow: 'none',
      alignContent: 'center',
      backgroundColor: '#FF655C',
      '&:hover': {
        border: 0,
      } }),
  placeholder: (css) => (
    {
      ...css,
      color: 'white',
      '&:hover': {
        color: 'white'
      },

    }
  ),
  singleValue: (css) => ({
    ...css,
    color: 'white'
  }),
  dropdownIndicator: (css) => ({
    ...css,
    color: 'white',
    '&:hover': {
      color: 'white',
    } 
  })
}

const DoubleSelect: React.FC<Props> = ({ 
  variants,
  users,
  projects,
  categories,
  employment,
  companies,
  tech_stack,
  managers,
  statuses,
  representatives,
  onChange,
  selectedValue,
  typeValue,
  selectRef,
  setTypeValue,
  isMulti }) => {

  const [options, setOptions] = React.useState<OptionTypes[]>()
  const [selectedPlaceholder, setSelectedPlaceholder] = React.useState<string>()
  const [selectedIcon, setSelectedIcon] = React.useState<JSX.Element | null>(null)

  const SelectControl = ({ children, ...props }: ControlProps<MyOptionType, false>) => {
    const style = {
      marginLeft: '5px'
    }
    
    return (
      <components.Control {...props}>
        <span style={style}>
          {selectedIcon}
        </span>

        {children}
      </components.Control>
    )
  }

  const MultiValueRemove = ({
    innerProps,
  }: MultiValueRemoveProps<MyOptionType, IsMulti>) => {
    return <div {...innerProps} >
      <RemoveSelectIcon/>
    </div>
  }
  
  const convertedVariants = variants?.map((variant, index) => ({
    value: index,
    label: variant
  }))

  const handleChangeType = (value: SingleValue<MyOptionType>) => {
    setTypeValue(value)
  }

  React.useEffect(() => {
    switch (typeValue?.label) {
    case SelectType.USER:
      setSelectedIcon(<UsersIcon/>)
      setSelectedPlaceholder(SelectPlaceholder.ALL_USERS)
      isMulti && selectedValue?.some(selected => selected.type === SelectType.USER)
        ? setOptions(undefined) : setOptions(users)
      break
    case SelectType.PROJECT:
      setSelectedIcon(<ProjectsIcon/>)
      setSelectedPlaceholder(SelectPlaceholder.ALL_PROJECTS)
      isMulti && selectedValue?.some(selected => selected.type === SelectType.PROJECT)
        ? setOptions(undefined) : setOptions(projects)
      break
    case SelectType.CATEGORY:
      setSelectedIcon(<CategoriesIcon color={'white'} />)
      setSelectedPlaceholder(SelectPlaceholder.ALL_CATEGORIES)
      setOptions(categories)
      break
    case SelectType.EMPLOYMENT:
      setSelectedIcon(<EmploymentIcon/>)
      setSelectedPlaceholder(SelectPlaceholder.ALL_EMPLOYMENTS)
      isMulti && selectedValue?.some(selected => selected.type === SelectType.EMPLOYMENT)
        ? setOptions(undefined) : setOptions(employment)
      break
    case SelectType.COMPANY:
      setSelectedIcon(<CompanyIcon/>)
      setSelectedPlaceholder(SelectPlaceholder.ALL_COMPANIES)
      isMulti && selectedValue?.some(selected => selected.type === SelectType.COMPANY)
        ? setOptions(undefined) : setOptions(companies)
      break
    case SelectType.TECH_STACK:
      setSelectedIcon(<TechStackIcon/>)
      setSelectedPlaceholder(SelectPlaceholder.ALL_TECH_STACKS)
      isMulti && selectedValue?.some(selected => selected.type === SelectType.TECH_STACK)
        ? setOptions(undefined) : setOptions(tech_stack)
      break
    case SelectType.MANAGER:
      setSelectedIcon(<ManagerIcon/>)
      setSelectedPlaceholder(SelectPlaceholder.ALL_MANAGERS)
      isMulti && selectedValue?.some(selected => selected.type === SelectType.MANAGER)
        ? setOptions(undefined) : setOptions(managers)
      break
    case SelectType.STATUS:
      setSelectedIcon(<StatusIcon/>)
      setSelectedPlaceholder(SelectPlaceholder.ALL_STATUSES)
      isMulti && selectedValue?.some(selected => selected.type === SelectType.STATUS)
        ? setOptions(undefined) : setOptions(statuses)
      break
    case SelectType.REPRESENTATIVE:
      setSelectedIcon(<RepresentativeIcon/>)
      setSelectedPlaceholder(SelectPlaceholder.ALL_REPRESENTATIVES)
      isMulti && selectedValue?.some(selected => selected.type === SelectType.REPRESENTATIVE)
        ? setOptions(undefined) : setOptions(representatives)
      break
    } 
  }, [typeValue, selectedValue])

  return (
    <>
      {!isMobile ?
        <div className="double_select__container">
          <ReactSelect
            className="double_select__input"
            options={convertedVariants} 
            styles={firstSelectStyles}
            value={typeValue}
            components={{ Control: SelectControl, IndicatorSeparator: IndicatorSeparator }}
            onChange={(value) => { handleChangeType(value) }}
            placeholder={'Select Type...'}
            isSearchable={false}
          />
          <ReactSelect
            className="double_select__input"
            placeholder={selectedPlaceholder} 
            isClearable={true}
            options={options} 
            styles={secondSelectStyles}
            components={{ IndicatorSeparator: IndicatorSeparator, MultiValueRemove: MultiValueRemove }}
            value={selectedValue}
            onChange={onChange}
            isMulti={isMulti}
          />
        </div> : 
        <div className="double_select__mobile_container">
          <ReactSelect
            ref={selectRef}
            options={convertedVariants} 
            styles={defaultSelectStyles}
            value={typeValue}
            components={{ Control: SelectControl, IndicatorSeparator: IndicatorSeparator }}
            onChange={(value) => { handleChangeType(value) }}
            placeholder={'Select Type...'}
            isSearchable={false}
          />
          <ReactSelect
            placeholder={selectedPlaceholder} 
            isClearable={true}
            options={options} 
            styles={defaultSelectStyles}
            components={{ IndicatorSeparator: IndicatorSeparator, MultiValueRemove: MultiValueRemove }}
            value={selectedValue}
            onChange={onChange}
            isMulti={isMulti}
          />
        </div>
      }
    </>
  )
}

export default React.memo(DoubleSelect)
