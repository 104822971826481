import { useDispatch } from 'react-redux'

//import { useGetCategories } from './useGetCategories'
import { createCategory } from '../../services/API/admin/categories'
import { addCategory, addCategoryFailure, addCategorySuccess } from '../../store/categoriesSlice'
import successNotify from '../../helpers/successNotify'
import dangerNotify from '../../helpers/dangerNotify'

export const useCreateCategory = () => {
  //const getCategoryHook = useGetCategories()
  const dispatch = useDispatch()

  return (data: { categoryName: string }) => {
    dispatch(addCategory())
    createCategory(data)
      .then((response) => {
        dispatch(addCategorySuccess(response.data))
        successNotify('Category created')
        //getCategoryHook()
      })
      .catch((error) => {
        dispatch(addCategoryFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
