import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { UseFormRegister } from 'react-hook-form'
import Box from '@mui/material/Box'

import ReactInput from '../../../../reusable/ReactInput'
import { ISkillSetInputs } from './CvAddSkillSet'
import AddInputField from '../AddInputField'

interface IProps {
  inputFields: { value: string }[]
  isDisabled: boolean
  setInputFields: Dispatch<SetStateAction<{ value: string }[]>>
  register: UseFormRegister<ISkillSetInputs>
}

const CvSkillSetForm: React.FC<IProps> = ({ inputFields, setInputFields, register, isDisabled }) => {
  const divRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: 'smooth' })
  })

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <ReactInput
          className="modal__input"
          type="text"
          label="Type of a skill set"
          register={register}
          registerControl="skill_set"
        />
      </Box>
      <Box sx={{ fontSize: '20px', marginTop: '15px', textAlign: 'center', fontWeight: '500' }}>Stack:</Box>
      <div className="added_div_skills">
        {inputFields.map((input, index) => {
          return (
            <AddInputField
              inputFields={inputFields}
              isDisabled={isDisabled}
              setInputFields={setInputFields}
              key={index}
              input={input}
              index={index}
              placeholder="Skill"
            />
          )
        })}
        <div ref={divRef} />
      </div>
    </>
  )
}

export default CvSkillSetForm
