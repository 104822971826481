import React from 'react'

import { Notification, Project, User } from '../../../types'
import NotificationsList from '../NotificationsList'

interface Props{
    readNotifications: Notification[]
    projectsNames: Project[]
    usersNames: User[]
    updateNotification: (notification: Notification) => void
    managerOrAdmin: boolean
}

const DesktopReadNotifications: React.FC<Props> = ({
  readNotifications,
  projectsNames,
  usersNames,
  updateNotification,
  managerOrAdmin
}) => {
  return (
    <>
      {readNotifications && readNotifications?.map(notification => {
        return (
          <div
            key={notification.id}
            onClick={() => updateNotification(notification)}
            className="notifications__item"
          >
            <NotificationsList
              notification={notification}
              projectsNames={projectsNames}
              usersNames={usersNames}
              managerOrAdmin={managerOrAdmin}
            />
          </div>
        )
      })}
    </>
  )
}

export default DesktopReadNotifications
