import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import ReactInput from '../../../../reusable/ReactInput'
import { ICourseInputs } from './CvUpdateCourse'
import AddInputField from '../AddInputField'

type IProps = {
  inputFields: { value: string }[]
  isDisabled: boolean
  setInputFields: Dispatch<SetStateAction<{ value: string }[]>>
  register: UseFormRegister<ICourseInputs>
  control: Control<ICourseInputs>
}
const CvCourseForm: React.FC<IProps> = ({ register, control, setInputFields, isDisabled, inputFields }) => {
  const divRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: 'smooth' })
  })

  return (
    <>
      <Box className="modal__input" sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <ReactInput
          type="text"
          label="Course name"
          register={register}
          registerControl="name"
        />
        <ReactInput
          type="text"
          label="Course license"
          register={register}
          registerControl="license"
        />
      </Box>
      <Box sx={{ width: '100%', marginBottom: '15px' }}>
        <Box sx={{ display: 'flex', marginTop: '15px' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="acquire_date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className="modal__input"
                  label="Acquire date"
                  views={['year', 'month']}
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: '500' }}>Stack:</Box>
      <div className="added_div_portfolio">
        {inputFields.map((input, index) => {
          return (
            <AddInputField
              inputFields={inputFields}
              isDisabled={isDisabled}
              setInputFields={setInputFields}
              key={index}
              input={input}
              index={index}
              placeholder="Technology"
            />
          )
        })}
        <div ref={divRef} />
      </div>
    </>
  )
}

export default CvCourseForm
