import React from 'react'

const SignificateIcon = ({ color }) => {
  return (
    <svg width="20" height="24" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 18.5713C15.6292 18.5713 18.5713 15.6292 18.5713
      11.9999C18.5713 8.3706 15.6292 5.42847 11.9999 5.42847C8.3706 5.42847 5.42847 8.3706 5.42847 11.9999C5.42847
      15.6292 8.3706 18.5713 11.9999 18.5713ZM11.9999 20.5713C16.7338 20.5713 20.5713 16.7338 20.5713 11.9999C20.5713
      7.26603 16.7338 3.42847 11.9999 3.42847C7.26603 3.42847 3.42847 7.26603 3.42847 11.9999C3.42847 16.7338 7.26603
      20.5713 11.9999 20.5713Z" fill={color} />
    </svg>
  )
}

export default SignificateIcon
