import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import { fetchSubRoles, fetchSubRolesFailure, fetchSubRolesSuccess } from 'store/subRolesSlice'
import { getSubRoles } from 'services/API/subRoles'

export const useGetSubRoles = () => {
  const dispatch = useDispatch()

  return () => [
    dispatch(fetchSubRoles()),
    getSubRoles()
      .then((response) => {
        dispatch(fetchSubRolesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchSubRolesFailure(error))
        dangerNotify('Server error: unable to download sub roles')
      }),
  ]
}
