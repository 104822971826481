import { useDispatch } from 'react-redux'

import { createAdminRecord, createRecord } from '../../services/API/record'
import { addRecordSuccess, addRecord, addRecordFailure } from '../../store/recordsSlice'
import dangerNotify from '../../helpers/dangerNotify'
import { useGetAdminUserRecords, useGetRecords } from './useGetRecords'
import { NewRecord } from '../../types/NewRecord'
import { CSV_LIMIT, getRecordsParams } from '../../constants'
import successNotify from '../../helpers/successNotify'
import { Pages } from 'enums/StartPage'

export const useCreateRecord = () => {
  const dispatch = useDispatch()
  const getRecordsHook = useGetRecords()

  return (record: NewRecord) => {
    dispatch(addRecord())
    createRecord(record)
      .then((response) => {
        dispatch(addRecordSuccess(response.data))
        successNotify('Record created')
        getRecordsHook(getRecordsParams.project_id, getRecordsParams.year, getRecordsParams.month)
      })
      .catch((error) => {
        dispatch(addRecordFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}

export const useCreateAdminRecord = () => {
  const dispatch = useDispatch()
  const getAdminRecords = useGetAdminUserRecords()

  return (record: NewRecord) => {
    dispatch(addRecord())
    createAdminRecord(record)
      .then((response) => {       
        dispatch(addRecordSuccess(response.data))
        successNotify('Record created')
        getAdminRecords(
          response.data.user_id,
          getRecordsParams.year,
          getRecordsParams.month,
          getRecordsParams.project_id,
          CSV_LIMIT, 
          Pages.START_PAGE)
      })
      .catch((error) => {
        dispatch(addRecordFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
