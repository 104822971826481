import React from 'react'

import { renderCustomer, renderManager, renderProjectStack } from '../../../../../helpers/renderName'
import Pencil from '../../../../../images/Pencil.svg'
import { NewProject, Project } from 'types'
import { EditProject } from 'components/admin/EditProject/EditProject'
import Modal from 'components/reusable/Modal'
import { ProjectProfile } from 'types/Manage/Project/ProjectProfile'
import { BENCH_MANAGER, BENCH_PROJECT } from '../../../../../constants'

const DesktopGeneralProject: React.FC<ProjectProfile> = ({
  project,
  projects,
  usersNames,
  allCustomers,
  projectTechnologies,
  technologies,
  toggleProject,
  openProject,
  setOpenProject,
  setProjectData,
  setPage,
}) => {
  const editProject = (
    <EditProject
      projectTechnologies={projectTechnologies}
      setOpen={setOpenProject}
      selectedProject={project as NewProject}
      technologies={technologies}
      customers={allCustomers}
      usersNames={usersNames}
      setProjectData={setProjectData}
      setPage={setPage}
    />
  )

  const handleEditForm = (project: NewProject) => () => {
    setOpenProject(!openProject)
    if (project) {
      setProjectData(project)
    }
  }

  const projectManager =
    project.name === BENCH_PROJECT ? BENCH_MANAGER : renderManager(project.id, projects as Project[], usersNames)

  return (
    <div className="project-profile_general horyzontal-line-general">
      <div className="project-profile_title-wrap">
        <div className="undeline-general">
          <div className="project-profile_title">
            <div>
              <div className="project-profile_general-title">{project.name}</div>
              <div className="project-profile_general-subtitle margin-bottom-8px">
                Company:
                <span className="project-profile_item">
                  {renderCustomer(project.customer_id as number, allCustomers)}
                </span>
              </div>
              <div className="project-profile_general-subtitle">
                Manager:
                <span className="project-profile_item">{projectManager}</span>
              </div>
            </div>
          </div>
          <div className="project-profile_url margin-bottom-40px">
            <a
              href={project.url}
              className={project.url ? 'project-profile_item-presents' : 'project-profile_item-none'}
              target="_blank"
              rel="noreferrer"
            >
              Projects
            </a>
            <a
              href={project.designs}
              className={project.designs ? 'project-profile_item-presents' : 'project-profile_item-none'}
              target="_blank"
              rel="noreferrer"
            >
              Design
            </a>
            <a
              href={project.specs}
              className={project.specs ? 'project-profile_item-presents' : 'project-profile_item-none'}
              target="_blank"
              rel="noreferrer"
            >
              Specs
            </a>
            <a
              href={project.milestones}
              className={project.milestones ? 'project-profile_item-presents' : 'project-profile_item-none'}
              target="_blank"
              rel="noreferrer"
            >
              Milestones
            </a>
          </div>
        </div>
        <div onClick={handleEditForm(project as NewProject)}>
          <img src={Pencil} className="project-profile_edit-button" />
        </div>
      </div>
      <div>
        <div className="project-profile_general-subtitle margin-bottom-28px margin-top-40px">
          Description:
          <span className="project-profile_item">{project.description}</span>
        </div>
        <div className="project-profile_general-subtitle margin-bottom-28px">
          Industry:
          <span className="project-profile_item">{project.industry ? project?.industry : '<None>'}</span>
        </div>
        <div className="project-profile_general-subtitle margin-bottom-28px">
          Tech Stack:
          <span className="project-profile_item">{renderProjectStack(projectTechnologies, technologies)}</span>
        </div>
        <div className="project-profile_general-subtitle margin-bottom-28px">
          From scratch:
          <span className="project-profile_item">{project.from_scratch ? 'Yes' : 'No'}</span>
        </div>
        <div className="project-profile_general-subtitle margin-bottom-28px">
          Payment Integration:
          <span className="project-profile_item">{project.payment_integration ? 'Yes' : 'No'}</span>
        </div>
        <div className="project-profile_general-subtitle margin-bottom-28px">
          Application:
          <span className="project-profile_item">{project?.app ? project?.app.join(', ') : '<None>'}</span>
        </div>
      </div>
      {openProject && (
        <Modal
          title="Edit Project Info"
          open={openProject}
          handleClose={toggleProject}
          component={editProject}
          style={{ width: 900, maxHeight: 'unset', height: 'auto' }}
        />
      )}
    </div>
  )
}

export default DesktopGeneralProject
