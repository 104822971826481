import React, { ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'

import { inventoryTypes } from '../../../constants'
import { useCreateInventory } from 'hooks/inventory/useCreateInventory'
import { useUpdateInventory } from 'hooks/inventory/useUpdateInventory'
import { Inventory } from 'types'

type Props = {
  onCancel: () => void
  item?: Inventory
  type: string
}

export const UserProfileInventoryForm = ({ onCancel, item, type }: Props) => {
  const updateInvetory = useUpdateInventory()
  const createInventory = useCreateInventory()

  const profileId = useSelector(
    ({
      profile: {
        showData: { id },
      },
    }) => id,
  )

  const [state, setState] = useState({
    id: item?.id,
    device: item?.device || inventoryTypes.NOTEBOOK.value,
    name: item?.name || '',
    serial_number: item?.serial_number || '',
    profile_id: profileId,
  })

  const handleChange =
    (field: keyof typeof state) =>
      ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        setState((ps) => ({
          ...ps,
          [field]: value,
        }))
      }

  const handleSubmit = () => {
    if (type === 'Create') {
      if (profileId) {
        createInventory(profileId, state)
        onCancel()
      }
    }

    if (type === 'Update') {
      if (item?.profile_id) {
        updateInvetory(item?.profile_id, state)
        onCancel()
      }
    }
  }

  return (
    <div className="modal__content">
      <FormControl className="w-100">
        <InputLabel id="user-profile-inventory-device">Device Type</InputLabel>
        <Select
          labelId="user-profile-inventory-device"
          value={state.device}
          onChange={handleChange('device')}
          input={<OutlinedInput label="Device Type" />}
        >
          {Object.values(inventoryTypes).map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        autoComplete="off"
        label="Device Name"
        className="modal__input"
        value={state.name}
        onChange={handleChange('name')}
      />
      <TextField
        autoComplete="off"
        label="Serial Number"
        className="modal__input"
        value={state.serial_number}
        onChange={handleChange('serial_number')}
      />

      <div className="modal__double-field">
        <Button className="modal__button modal__cancel" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button className="modal__button modal__submit" onClick={handleSubmit} variant="contained">
          {type}
        </Button>
      </div>
    </div>
  )
}
