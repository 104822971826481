import React from 'react'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { Category } from 'types/Category'
import { tableCellStyles, tableHeadStyles } from 'utilts/styles'
import DeleteIcon from '../../reusable/DeleteIcon'

interface Props {
  deleteCategory: (id: number) => void
  categories: Category[]
}
const CategoriesList: React.FC<Props> = ({ deleteCategory, categories }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell scope="col" align="left" sx={tableHeadStyles}>
              #
            </TableCell>
            <TableCell align="left" scope="col" sx={tableHeadStyles}>
              Name
            </TableCell>
            <TableCell align="left" scope="col" sx={tableHeadStyles} />
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((category: Category, id: number) => (
            <TableRow key={id}>
              <TableCell align="left" sx={tableCellStyles}>
                {id + 1}
              </TableCell>
              <TableCell align="left" sx={tableCellStyles}>
                {category.name}
              </TableCell>
              <TableCell align="right">
                <div onClick={() => !category.has_records && deleteCategory(category.id)}>
                  <DeleteIcon isRecords={category.has_records} text="category" />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CategoriesList
