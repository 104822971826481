import React from 'react'
import ReactPDF from '@react-pdf/renderer'

import { ArrowBack, DownloadIcon, EditIcon } from '../../../../../icons'
import './cvPreviewStyles.scss'
import { IResume } from '../../../../../types/Resume/IResume'
import { ICurrentUser } from '../../../../../types'

type Props = {
    resume: IResume | undefined
    currentUser: ICurrentUser | null
    instance: ReactPDF.UsePDFInstance
    onEditUserCv: () => void
    onHandleBackToProfile: () => void
    downloadFileName: string
}

const MobileCustomNavbar: React.FC<Props> = ({
  resume,
  currentUser,
  instance,
  onEditUserCv,
  onHandleBackToProfile,
  downloadFileName
}) => {
  return (
    <>
      {resume?.name &&
        <div className="custom_mobile_navbar">
          {currentUser &&
             <div className="custom_mobile_navbar__arrow">
               <ArrowBack onHandleBackToProfile={onHandleBackToProfile} />
             </div>
          }
          <div className="custom_mobile_navbar__username">
            {resume?.name} CV
          </div>
          <div className="custom_mobile_navbar__buttons">
            {currentUser &&
              <div className="custom_mobile_navbar__edit">
                <EditIcon onHandleBack={onEditUserCv} />
              </div>
            }
            <a href={instance.url as string} download={downloadFileName}>
              <DownloadIcon />
            </a>
          </div>
        </div>
      }
    </>
  )
}

export default MobileCustomNavbar
