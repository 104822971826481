import { useDispatch } from 'react-redux'

import { getLeaveCounters, getLeaveUserCounters } from '../../services/API/admin/leave-counters'
import dangerNotify from '../../helpers/dangerNotify'
import { fetchUserLeaves, fetchUserLeavesFailure, fetchUserLeavesSuccess } from 'store/userLeavesSlice'
import { UserLeaves } from '../../types/UserLeaves'

export const useGetUserLeaveCounters = () => {
  const dispatch = useDispatch()

  return (id: number) => {
    dispatch(fetchUserLeaves()),
    getLeaveCounters(id)
      .then((response) => {
        dispatch(fetchUserLeavesSuccess(response?.data))
      })
      .catch((error) => {
        dispatch(fetchUserLeavesFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}

export const useGetUsersLeaveCounters = () => {
  const dispatch = useDispatch()

  return (id: number, setUserLeaves: (data: UserLeaves) => void) => {
    dispatch(fetchUserLeaves())
    getLeaveUserCounters(id)
      .then((response) => {
        setUserLeaves(response?.data)
      })
      .catch((error) => {
        dispatch(fetchUserLeavesFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}

