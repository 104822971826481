import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import InputGroup from 'react-bootstrap/InputGroup'

import { Project } from '../../../types'
import { useGetCustomer } from '../../../hooks/customer/useGetCustomer'
import { getCustomerParams } from '../../../constants'
import { redSelectStyles } from './redSelectStyle'
import { selectStyles } from './selectStyles'
import { mobileSelectStyles } from './mobileSelectStyle'
import { styles } from './styles'
import DefaultSelect from 'components/custom/DefaultSelect/DefaultSelect'
import CompanyIcon from '../../../icons/DoubleSelectIcons/CompanyIcon'
import CompanyIconGrey from 'icons/DoubleSelectIcons/CompanyIconGrey'
import RepresentativeIcon from '../../../icons/DoubleSelectIcons/RepresentativeIcon'
import RepresentativeIconGrey from 'icons/DoubleSelectIcons/RepresentativeIconGrey'
import ProjectsIcon from '../../../icons/DoubleSelectIcons/ProjectsIcon'
import ProjectsIconGrey from 'icons/DoubleSelectIcons/ProjectsIconGrey'
import { Pages } from 'enums/StartPage'

interface Props {
  edited: boolean
  companiesSliced: []
  setEdited: (edited: boolean) => void
  setPage: (page: number) => void
  page: number
}
export interface OptionTypes {
  label: string
  value: number
}

const CustomersFilters: React.FC<Props> = ({ edited, companiesSliced, setEdited, setPage }) => {
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const getCustomers = useGetCustomer()

  const customerProjects = projects?.filter(project => project.customer_id).map((project) => ({
    label: project.name,
    value: project.id
  }))
  const companies = companiesSliced?.map((company) => ({
    label: company.company_name,
  }))

  const representatives = companiesSliced?.map((company) => ({
    label: company.full_legal_name,
  })).sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)

  const uniqueRepresentatives = representatives?.filter(
    (value, index, array) => array.findIndex((val) => val.label === value.label) === index,
  )

  const onCompanyChange = (company: string) => {
    if (company) {
      getCustomers(company, getCustomerParams.representative, getCustomerParams.projectId, Pages.START_PAGE)
    }
    else {
      getCustomers(company, getCustomerParams.representative, getCustomerParams.projectId, Pages.START_PAGE)
      setPage(Pages.START_PAGE)
    }
  }
  const onRepresentativeChange = (representative: string) => {
    if (representative) {
      getCustomers(getCustomerParams.company, representative, getCustomerParams.projectId, Pages.START_PAGE)
    }
    else {
      getCustomers(getCustomerParams.company, representative, getCustomerParams.projectId, Pages.START_PAGE)
      setPage(Pages.START_PAGE)
    }
  }
  const onProjectChange = (projectId: number) => {
    if (projectId) {
      getCustomers(getCustomerParams.company, getCustomerParams.representative, projectId, Pages.START_PAGE)
    }
    else {
      getCustomers(getCustomerParams.company, getCustomerParams.representative, projectId, Pages.START_PAGE)
      setPage(Pages.START_PAGE)
    }
  }

  const [active, setActive] = useState('Company')

  const optionsCompany = [
    { label: 'Representative', value: 'Representative' },
    { label: 'Project', value: 'Project' }
  ]

  const optionsRepresentative = [
    { label: 'Company', value: 'Company' },
    { label: 'Project', value: 'Project' }
  ]

  const optionsProject = [
    { label: 'Company', value: 'Company' },
    { label: 'Representative', value: 'Representative' }
  ]

  function setMainSelect(option) {
    setActive((option as OptionTypes)?.label)
    setEdited(false)
  }

  function setOnCompanySelect(option) {
    onCompanyChange((option as OptionTypes)?.label)
    
  }

  return (
    <>
      {edited ? <div onClick={setEdited(false)}>
        {!isMobile &&
          <>
            {active === 'Company' &&
              <>
                <InputGroup className="manage-customers__select">
                  <DefaultSelect
                    options={optionsCompany}
                    isClearable={false}
                    placeholder={<> <CompanyIcon /> Company </>}
                    styles={redSelectStyles}
                    isSearchable={false}
                    onChange={(option) => setMainSelect(option)} />
                  <DefaultSelect
                    isClearable={true}
                    placeholder="All Companies"
                    options={companies}
                    value={' '}
                    onChange={(option) => setOnCompanySelect(option)}
                    styles={selectStyles}
                  />
                </InputGroup>
              </>}

            {active === 'Representative' &&
              <>
                <InputGroup className="manage-customers__select">
                  <DefaultSelect
                    options={optionsRepresentative}
                    isClearable={false}
                    placeholder={<><RepresentativeIcon /> Representative</>}
                    styles={redSelectStyles}
                    isSearchable={false}
                    onChange={(option) => setMainSelect(option)} />
                  <DefaultSelect
                    isClearable={true}
                    placeholder="All Representatives"
                    options={uniqueRepresentatives}
                    value={' '}
                    onChange={(option) => onRepresentativeChange((option as OptionTypes)?.label)}
                    styles={selectStyles}
                  />
                </InputGroup>
              </>}

            {active === 'Project' && <>
              <InputGroup className="manage-customers__select">
                <DefaultSelect
                  options={optionsProject}
                  isClearable={false}
                  placeholder={<><ProjectsIcon /> Project</>}
                  styles={redSelectStyles}
                  isSearchable={false}
                  onChange={(option) => setMainSelect(option)} />
                <DefaultSelect
                  isClearable={true}
                  placeholder="All Projects"
                  options={customerProjects}
                  value={' '}
                  onChange={(option) => onProjectChange((option as OptionTypes)?.value)}
                  styles={selectStyles}
                />
              </InputGroup>
            </>}
          </>}
      </div>
        : <>
          {!isMobile &&
            <>
              {active === 'Company' &&
                <>
                  <InputGroup className="manage-customers__select">
                    <DefaultSelect
                      options={optionsCompany}
                      isClearable={false}
                      placeholder={<> <CompanyIcon /> Company </>}
                      styles={redSelectStyles}
                      isSearchable={false}
                      onChange={(option) => setActive((option as OptionTypes)?.label)} />
                    <DefaultSelect
                      isClearable={true}
                      placeholder="All Companies"
                      options={companies}
                      onChange={(option) => onCompanyChange((option as OptionTypes)?.label)}
                      styles={selectStyles}
                    />
                  </InputGroup>
                </>}
              {active === 'Representative' &&
                <>
                  <InputGroup className="manage-customers__select">
                    <DefaultSelect
                      options={optionsRepresentative}
                      isClearable={false}
                      placeholder={<><RepresentativeIcon /> Representative</>}
                      styles={redSelectStyles}
                      isSearchable={false}
                      onChange={(option) => setActive((option as OptionTypes)?.label)} />
                    <DefaultSelect
                      isClearable={true}
                      placeholder="All Representatives"
                      options={uniqueRepresentatives}
                      onChange={(option) => onRepresentativeChange((option as OptionTypes)?.label)}
                      styles={selectStyles}
                    />
                  </InputGroup>
                </>}
              {active === 'Project' && <>
                <InputGroup className="manage-customers__select">
                  <DefaultSelect
                    options={optionsProject}
                    isClearable={false}
                    placeholder={<><ProjectsIcon /> Project</>}
                    styles={redSelectStyles}
                    isSearchable={false}
                    onChange={(option) => setActive((option as OptionTypes)?.label)} />
                  <DefaultSelect
                    isClearable={true}
                    placeholder="All Projects"
                    options={customerProjects}
                    onChange={(option) => onProjectChange((option as OptionTypes)?.value)}
                    styles={selectStyles}
                  />
                </InputGroup>
              </>}
            </>}
        </>
      }

      {isMobile && 
      <>
        {active === 'Company' && 
        <>
          <div className="manage-customers__select-style">
            <DefaultSelect
              options={optionsCompany}
              isClearable={false}
              placeholder={<><CompanyIconGrey /> Company</>}
              styles={styles}
              isSearchable={false}
              onChange={(option) => setActive((option as OptionTypes)?.label)} />
          </div>
          <div className="manage-customers__select-style">
            <DefaultSelect
              isClearable={true}
              placeholder="All Companies"
              options={companies}
              styles={mobileSelectStyles}
              onChange={(option) => onCompanyChange((option as OptionTypes)?.label)}
            />
          </div>
        </>}

        {active === 'Representative' && 
        <>
          <div className="manage-customers__select-style">
            <DefaultSelect
              options={optionsRepresentative}
              isClearable={false}
              placeholder={<><RepresentativeIconGrey /> Representative</>}
              styles={styles}
              isSearchable={false}
              onChange={(option) => setActive((option as OptionTypes)?.label)} />
          </div>
          <div className="manage-customers__select-style">
            <DefaultSelect
              isClearable={true}
              placeholder="All Representatives"
              options={uniqueRepresentatives}
              styles={mobileSelectStyles}
              onChange={(option) => onRepresentativeChange((option as OptionTypes)?.label)}
            />
          </div>
        </>}

        {active === 'Project' && 
        <>
          <div className="manage-customers__select-style">
            <DefaultSelect
              options={optionsProject}
              isClearable={false}
              placeholder={<><ProjectsIconGrey /> Project</>}
              styles={styles}
              isSearchable={false}
              onChange={(option) => setActive((option as OptionTypes)?.label)} />
          </div>
          <div className="manage-customers__select-style">
            <DefaultSelect
              isClearable={true}
              placeholder="All Projects"
              options={customerProjects}
              styles={mobileSelectStyles}
              onChange={(option) => onProjectChange((option as OptionTypes)?.value)}
            />
          </div>
          
        </>}
      </>}
    </>
  )
}

export default React.memo(CustomersFilters)
