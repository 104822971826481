import { useDispatch } from 'react-redux'

import { deleteCandidate as removeCandidateService } from '../../services/API/candidates'
import { removeCandidate, removeCandidateSuccess, removeCandidateFailure } from '../../store/candidatesSlice'
import dangerNotify from 'helpers/dangerNotify'
import successNotify from 'helpers/successNotify'

export const useRemoveCandidate = () => {
  const dispatch = useDispatch()

  return (id: number | string) => {
    dispatch(removeCandidate())
    removeCandidateService(id)
      .then((response) => {
        dispatch(removeCandidateSuccess(response.data))
        successNotify('Candidate removed')
      })
      .catch((error) => {
        dispatch(removeCandidateFailure(error))
        dangerNotify('Server error: unable to remove Candidate')
      })
  }
}
