import API from '../..'
import ROUTES from '../../../../routes'
import { Contract } from '../../../../types'

export const getContracts = (id: number) => {
  return API.get(ROUTES.ADMIN_CONTRACTS + `/${id}`)
}

export const getAllContracts = (
  userId: number | undefined,
  projectId: number | undefined,
  statusId: number | undefined,
) => {
  return API.get(ROUTES.ADMIN_CONTRACTS, {
    params: {
      user_id: userId,
      project_id: projectId,
      status_id: statusId,
    },
  })
}

export const getUserAvailableContracts = (
  userId: number | undefined
) => {
  return API.get(ROUTES.USER_AVAILABLE_CONTRACTS, {
    params: { user_id: userId }
  })
}

export const getAvailableContracts = () => {
  return API.get(ROUTES.AVAILABLE_CONTRACTS)
}

export const deleteContract = (contract_id: string | undefined) => {
  return API.delete(ROUTES.ADMIN_CONTRACTS + `/${contract_id}`)
}

export const createContract = (params: Contract) => {
  return API.post(ROUTES.ADMIN_CONTRACTS, {
    contract: {
      user_id: params.user_id,
      project_id: params.project_id,
      job_status_id: params.job_status_id,
      admin_id: params.admin_id,
      start_date: params.start_date,
      end_date: params.end_date,
    },
  })
}

export const editContract = (data: Contract) => {
  return API.patch(ROUTES.ADMIN_CONTRACTS + `/${data.id}`, {
    contract: {
      user_id: data.user_id,
      project_id: data.project_id,
      job_status_id: data.job_status_id,
      admin_id: data.admin_id,
      start_date: data.start_date,
      end_date: data.end_date,
    },
  })
}
