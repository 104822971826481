import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'
import {  deleteInventory } from '../../services/API/admin/inventories'
import { removeInventory, removeInventoryFailure, removeInventorySuccess } from '../../store/inventoriesSlice'
import { useGetInventories } from './useGetInventories'

export const useDeleteInventory = () => {
  const dispatch = useDispatch()
  const getInventoriesHook = useGetInventories()

  return (inventoryId: number, profile_id: number) => {
    dispatch(removeInventory())
    deleteInventory(inventoryId)
      .then((response) => {
        dispatch(removeInventorySuccess(response.data))
        successNotify('Inventory deleted')
        getInventoriesHook(profile_id)
      })
      .catch((error) => {
        dispatch(removeInventoryFailure(error))
        dangerNotify('Server error: unable to remove inventory')
      })
  }
}
