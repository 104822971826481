/* eslint-disable indent */
import React, { ChangeEvent, useState } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { useSelector } from 'react-redux'

import { marriedStatuses } from '../../../constants'
import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import { dateFormatYYMMDD } from 'helpers/displayingDateFormat'
import { UserProfileChildForm } from '../components/child-form'
import { Child, UserProfile } from 'types'
import { useUpdateUsersProfile } from 'hooks/profile/useUpdateUsersProfile'

type Props = {
  onCancel: () => void
}

export const UserProfilePersonalInfoForm = ({ onCancel }: Props) => {
  const updateProfile = useUpdateUsersProfile()

  const {
    user_id,
    date_of_birth: profileDoB,
    marital_status,
    home_address,
    office,
    post_office,
    children: childrenData,
  } = useSelector(({ profile: { showData } }) => showData)

  const [state, setState] = useState({
    date_of_birth: profileDoB ? dateFormatYYMMDD(profileDoB) : '',
    marital_status: marital_status || '',
    home_address: home_address || '',
    office: office || '',
    post_office: post_office || '',
  })

  const [children, setChildren] = useState(childrenData || [])
  const [childrenToRemove, setChildrenToRemove] = useState<Child[]>([])

  const handleChangeDate = (v: Date | null) =>
    setState((ps) => ({ ...ps, date_of_birth: v ? dateFormatYYMMDD(v) : '' }))

  const handleChange =
    (field: keyof typeof state) =>
    ({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>) => {
      setState((ps) => ({
        ...ps,
        [field]: value,
      }))
    }

  const handleChangeChild = (id: number | string) => (data: Child) => {
    setChildren((ps) => {
      const idx = ps.findIndex((c) => c.id === id)
      const start = ps.slice(0, idx)
      const end = ps.slice(idx + 1)
      const child = { ...ps[idx], ...data }
      return [...start, child, ...end]
    })
  }

  const handleRemoveChild = (id: number | string) => {
    setChildren((ps) => ps.filter((c) => c.id !== id))
    if (childrenData.find((c) => c.id === id)) setChildrenToRemove((ps) => [...ps, { id, _destroy: true }])
  }

  const handleAddChild = () => {
    setChildren((ps) => [...ps, { id: ps.length + 'tmp', name: '', date_of_birth: new Date() }])
  }

  const handleSubmit = () => {
    const childrenProcessed = children
      .filter(({ name }) => {
        return name && name.length
      })
      .map(({ id, name, date_of_birth }) => {
        const obj: { id?: number; name?: string; date_of_birth: string } = {
          name,
          date_of_birth: dateFormatYYMMDD(date_of_birth as string | Date),
        }
        if (typeof id === 'number') obj['id'] = id

        return obj
      })

    const data = {
      ...state,
      children_attributes: [...childrenProcessed, ...childrenToRemove],
    }

    updateProfile(data as UserProfile, user_id as number)
    onCancel()
  }

  return (
    <div className="modal__content">
      <div className="modal__double-field">
        <CustomDatepicker
          value={state.date_of_birth as string | Date | null}
          onChange={handleChangeDate}
          maxDate={new Date()}
          mask={'__.__.____'}
          inputType="default"
          placeholder="Date of Birth"
        />
        <FormControl className="w-100">
          <InputLabel id="user-marital-status">Marital Status</InputLabel>
          <Select
            labelId="user-marital-status"
            value={state.marital_status}
            onChange={handleChange('marital_status')}
            input={<OutlinedInput label="Marital Status" />}
          >
            {marriedStatuses.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TextField
        autoComplete="off"
        label="Office"
        className="modal__input"
        value={state.office}
        onChange={handleChange('office')}
      />
      <TextField
        autoComplete="off"
        label="Home Location"
        className="modal__input"
        value={state.home_address}
        onChange={handleChange('home_address')}
      />
      <TextField
        autoComplete="off"
        label="Post Office"
        className="modal__input"
        value={state.post_office}
        onChange={handleChange('post_office')}
      />
      {children.map((c, idx) => (
        <UserProfileChildForm
          key={'CHILD' + idx}
          child={c}
          handleChangeChild={c.id ? handleChangeChild(c.id) : null}
          handleRemove={handleRemoveChild}
        />
      ))}
      <Button variant="contained" onClick={handleAddChild}>
        Add Child
      </Button>
      <div className="modal__double-field">
        <Button className="modal__button modal__cancel" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button className="modal__button modal__submit" onClick={handleSubmit} variant="contained">
          Update
        </Button>
      </div>
    </div>
  )
}
