import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import { getAdminRecords, getAdminUserRecords, getRecords } from '../../services/API/record'
import { fetchAdminRecordsFailure, 
  fetchAdminRecordsSuccess, 
  fetchRecords, 
  fetchRecordsFailure, 
  fetchRecordsSuccess } from '../../store/recordsSlice'


export const useGetRecords = () => {
  const dispatch = useDispatch()

  return (
    project_id: string | undefined,
    year: string | undefined,
    month: string | undefined,
    limit?: number,
    page?: number,
    setLoading?: (value: ((prevState: boolean) => boolean) | boolean) => void,
  ) => {
    dispatch(fetchRecords())
    getRecords(project_id, year, month, limit, page)
      .then((response) => {
        dispatch(fetchRecordsSuccess(response.data))

        if (setLoading) setLoading(false)
      })
      .catch((error) => {
        dispatch(fetchRecordsFailure(error))
        dangerNotify('Server error: unable to download Records')
      })
  }
}

export const useGetAdminRecords = () => {
  const dispatch = useDispatch()

  return (setLoading?: (value: ((prevState: boolean) => boolean) | boolean) => void) => {
    dispatch(fetchRecords())
    getAdminRecords()
      .then((response) => {
        dispatch(fetchRecordsSuccess(response.data))

        if (setLoading) setLoading(false)
      })
      .catch((error) => {
        dispatch(fetchRecordsFailure(error))
        dangerNotify('Server error: unable to download Records')
      })
  }
}

export const useGetAdminUserRecords = () => {
  const dispatch = useDispatch()

  return (
    user_id: number | undefined,
    year: string | undefined,
    month: string | undefined,
    project_id: number | undefined,
    limit?: number,
    page?: number,
    setLoading?: (value: ((prevState: boolean) => boolean) | boolean) => void,
  ) => {
    getAdminUserRecords(user_id, year, month, project_id, limit, page)
      .then((response) => {
        dispatch(fetchAdminRecordsSuccess(response.data))

        if (setLoading) setLoading(false)
      })
      .catch((error) => {
        dispatch(fetchAdminRecordsFailure(error))
        dangerNotify('Server error: unable to download Records')
      })
  }
}
