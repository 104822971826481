import { createSlice } from '@reduxjs/toolkit'

import { Project } from '../types'
import { IProjectTechnology } from '../components/admin/ProjectsList/ProjectsList'

const initialState = {
  indexData: <Project[]>[],
  indexUserData: <Project[]>[],
  projectTechnologies: <IProjectTechnology[]>[],
  showData: <Project>{},
  error: {},
  isLoading: true,
  totalPages: 0
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    fetchProjects(state) {
      state.isLoading = true
    },
    fetchProjectsSuccess(state, action) {
      state.indexData = action.payload.projects
      state.totalPages = action.payload.total_pages
      state.isLoading = false
    },
    fetchProjectsFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    fetchProjectTechnologies(state) {
      state.isLoading = true
    },
    fetchProjectTechnologiesSuccess(state, action) {
      state.projectTechnologies = action.payload
      state.isLoading = false
    },
    fetchProjectTechnologiesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    fetchUserProjects(state) {
      state.isLoading = true
    },
    fetchUserProjectsSuccess(state, action) {
      state.indexUserData = action.payload
      state.isLoading = false
    },
    fetchUserProjectsFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    getProject(state) {
      state.isLoading = true
    },
    getProjectSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    getProjectFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    addProject(state) {
      state.isLoading = true
    },
    addProjectSuccess(state, action) {
      state.indexData.push(action.payload)
      state.isLoading = false
    },
    addProjectFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    updateProject(state) {
      state.isLoading = true
    },
    updateProjectSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    updateProjectFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    removeProject(state) {
      state.isLoading = true
    },
    removeProjectSuccess(state, action) {
      state.indexData = state.indexData.filter((project: Project) => project.id !== action.payload)
      state.isLoading = false
    },
    removeProjectFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default projectsSlice.reducer
export const {
  fetchProjects,
  fetchProjectsSuccess,
  fetchProjectsFailure,
  fetchUserProjects,
  fetchUserProjectsSuccess,
  fetchUserProjectsFailure,
  addProject,
  addProjectFailure,
  addProjectSuccess,
  removeProject,
  removeProjectFailure,
  removeProjectSuccess,
  getProject,
  getProjectSuccess,
  getProjectFailure,
  updateProject,
  updateProjectFailure,
  updateProjectSuccess,
  fetchProjectTechnologies,
  fetchProjectTechnologiesSuccess,
  fetchProjectTechnologiesFailure
} = projectsSlice.actions
