import React from 'react'
import Select from 'react-select'
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form'
import Box from '@mui/material/Box'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@mui/material/Radio'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'

import ReactInput from '../../reusable/ReactInput/ReactInput'
import { applications, BENCH_PROJECT } from '../../../constants'
import { customStyles } from '../../../helpers/customSelectStyles'
import { NewProject } from '../../../types'
import { IEditProjectInputs } from '../EditProject/EditProject'
import { IProjectInputs } from '../CreateProject/CreateProject'
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr'

interface Props {
  selectedProject?: NewProject
  defaultValues: IEditProjectInputs | IProjectInputs
  register: UseFormRegister<IEditProjectInputs> | UseFormRegister<IProjectInputs>
  control: Control<IEditProjectInputs> | undefined
  companies: { value: number | undefined; label: string }[]
  errors: FieldErrors
  managers: { value: number; label: string }[]
  tech_stack: { value: number | undefined; label: string }[]
}

const ProjectForm: React.FC<Props> = ({
  defaultValues,
  selectedProject,
  register,
  control,
  companies,
  tech_stack,
  errors,
  managers,
}) => {
  return (
    <Box sx={{ width: '800px' }}>
      <Box>
        <ReactInput
          type="text"
          label="Name"
          disabled={selectedProject?.name == BENCH_PROJECT}
          value={defaultValues.name}
          registerControl="name"
          register={register}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Box sx={{ width: '100%', marginTop: '10px' }}>
            <Controller
              name="customer"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable={true}
                  placeholder="Company"
                  options={companies}
                  styles={customStyles(errors.customer)}
                />
              )}
            />
            {errors.customer && <p className="form_select__error">This field is required</p>}
          </Box>
          <Box sx={{ width: '100%', marginTop: '10px' }}>
            <Controller
              name="manager"
              control={control}
              render={({ field }) => (
                <Select
                  isClearable={true}
                  placeholder="Manager"
                  {...field}
                  options={managers}
                  styles={customStyles(errors.manager)}
                />
              )}
            />
            {errors.manager && <p className="form_select__error">This field is required</p>}
          </Box>
        </Box>
      </Box>
      <Controller
        name="technologies"
        control={control}
        render={({ field }) => (
          <Select
            isMulti
            isClearable={true}
            placeholder="Tech stack"
            {...field}
            options={tech_stack}
            styles={customStyles(errors.technologies)}
          />
        )}
      />
      {errors.technologies && <p className="form_select__error">This field is required</p>}
      <Box mb={1.5}>
        <ReactInput type="text" label="Description" register={register} registerControl="description" />
      </Box>
      <Box mb={1.5}>
        <ReactInput type="text" label="Industry" register={register} registerControl="industry" />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
        <span className="project-profile_title-inputs">Add links</span>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Box sx={{ width: '100%', marginTop: '10px' }}>
            <Box mb={1.5}>
              <ReactInput type="text" label="Project URL" register={register} registerControl="url" />
            </Box>
          </Box>
          <Box sx={{ width: '100%', marginTop: '10px' }}>
            <Box mb={1.5}>
              <ReactInput type="text" label="Design URL" register={register} registerControl="designs" />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Box sx={{ width: '100%', marginTop: '10px' }}>
            <Box mb={1.5}>
              <ReactInput type="text" label="Milestones URL" register={register} registerControl="milestones" />
            </Box>
          </Box>
          <Box sx={{ width: '100%', marginTop: '10px' }}>
            <Box mb={1.5}>
              <ReactInput type="text" label="Specs URL" register={register} registerControl="specs" />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <section>
          <FormLabel>From scratch</FormLabel>
          <Controller
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            )}
            name="from_scratch"
            control={control}
          />
        </section>
        <section>
          <FormLabel>Payment integration</FormLabel>
          <Controller
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            )}
            name="payment_integration"
            control={control}
          />
        </section>
        <div className={errors.applications ? 'form_checkbox_group__error' : ''}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Application</FormLabel>
            <FormGroup row>
              <Controller
                name="applications"
                control={control}
                render={({ field }) => (
                  <>
                    {applications.map((app) => (
                      <FormControlLabel
                        {...field}
                        key={app}
                        label={app}
                        control={
                          <Checkbox
                            icon={<GrCheckbox className="checkbox__icon" />}
                            checkedIcon={<GrCheckboxSelected className="checkbox__icon" />}
                            checked={field.value?.includes(app)}
                            onChange={() => {
                              if (!field.value?.includes(app)) {
                                field.onChange([...field.value, app])
                                return
                              }
                              const newApplications = field.value.filter((application) => application !== app)
                              field.onChange(newApplications)
                            }}
                          />
                        }
                      />
                    ))}
                  </>
                )}
              />
            </FormGroup>
            {errors.applications && <p className="form_select__error">Select at least one</p>}
          </FormControl>
        </div>
      </Box>
    </Box>
  )
}

export default ProjectForm
