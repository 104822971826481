import { StylesConfig } from 'react-select'

import { OptionTypes } from './CustomerFilters'

export const styles: StylesConfig<OptionTypes, false> = {
  container: (base) => ({
    ...base,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '4px',
    flex: 'none',
    order: 0,
    flexGrow: 0
  }),
  placeholder: (base) => ({
    ...base,
    color: '#303540',
    fontWeight: '400'
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #D4D9E2',
    width: '335px',
    height: '48px'
  }),
  option: (base) => ({
    ...base,
    color: 'black',
    backgroundColor: 'white',
    '&::selection': {
      backgroundColor: 'white',
      color: 'black'
    },
    '&:hover': {
      backgroundColor: '#dfe8ff',
      color: 'black'
    }
  })
}
