import { useDispatch } from 'react-redux'

import { Customer } from '../../types'
import { useGetCustomer } from './useGetCustomer'
import { editCustomer } from '../../services/API/admin/customers'
import { updateCustomer, updateCustomerFailure, updateCustomerSuccess } from '../../store/customersSlice'
import { getCustomerParams } from '../../constants'
import successNotify from '../../helpers/successNotify'
import dangerNotify from '../../helpers/dangerNotify'

export const useEditCustomer = () => {
  const getCustomersHook = useGetCustomer()
  const dispatch = useDispatch()

  return (page: number, updatedCustomer: Customer, customer_id: number | undefined) => {
    dispatch(updateCustomer())
    editCustomer(updatedCustomer, customer_id)
      .then((response) => {
        dispatch(updateCustomerSuccess(response.data))
        getCustomersHook(getCustomerParams.company, getCustomerParams.representative, getCustomerParams.projectId, page)
        successNotify('Customer edited')
      })
      .catch((error) => {
        dispatch(updateCustomerFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
