import React from 'react'

const UsersIcon = () => {
  return (
    <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.41313 1.63099C6.14406 0.629761 7.29994 0 8.81183
       0C9.8055 0 10.6454 0.272032 11.3119 0.742764C11.9785 0.272032 12.8183 0 13.812 0C15.3239 0 16.4798
       0.629761 17.2107 1.63099C17.9143 2.59475 18.1649 3.81678 18.1006 4.96204C18.0361 6.11 17.6507 7.2866 16.9496
       8.20015C16.2362 9.12957 15.1675 9.81193 13.812 9.81193C12.8141 9.81193 11.9716 9.4421 11.3119 8.87612C10.6522
       9.4421 9.80972 9.81193 8.81183 9.81193C7.45637 9.81193 6.38758 9.12957 5.67428 8.20015C4.97315 7.2866
       4.58769 6.11 4.52322 4.96204C4.4589 3.81678 4.70954 2.59475 5.41313 1.63099ZM12.5166 7.27029C12.8811
       7.62225 13.3152 7.81193 13.812 7.81193C14.4306 7.81193 14.9521 7.51781 15.363 6.98248C15.786 6.4313
       16.0586 5.65491 16.1038 4.8499C16.1491 4.04217 15.9611 3.31122 15.5954 2.81024C15.257 2.34673 14.7129
       2 13.812 2C13.3056 2 12.912 2.10955 12.6042 2.28781C13.0076 3.11873 13.1509 4.06398 13.1004
       4.96204C13.0562 5.74935 12.861 6.55013 12.5166 7.27029ZM7.26087 6.98248C7.67172 7.51781 8.19319
       7.81193 8.81183 7.81193C9.43047 7.81193 9.95194 7.51781 10.3628 6.98248C10.7858 6.4313 11.0584 5.65491
       11.1036 4.8499C11.1489 4.04217 10.9609 3.31122 10.5952 2.81024C10.2568 2.34673 9.71276 2 8.81183
       2C7.9109 2 7.36686 2.34673 7.02847 2.81024C6.66275 3.31122 6.47471 4.04217 6.52007 4.8499C6.56528
       5.65491 6.83785 6.4313 7.26087 6.98248ZM0.0310807 17.0933C0.813557 12.7591 4.98893 10.6329 8.81225
       10.6329C9.6418 10.6329 10.4879 10.733 11.3123 10.934C12.1368 10.733 12.9829 10.6329 13.8124
       10.6329C17.6362 10.6329 21.8119 12.7596 22.5942 17.0946C22.6752 17.5432 22.5986 18.0343 22.3214
       18.4391C22.0289 18.8664 21.5446 19.1429 20.9734 19.1429H15.9732H15.3127H1.65172C1.08093
       19.1429 0.596428 18.8668 0.303541 18.4396C0.0260303 18.0349 -0.0509997 17.5433 0.0310807
       17.0933ZM17.6021 17.1429H20.5613C19.959 14.6527 17.6577 13.0711 14.9886 12.7114C16.288 13.766
       17.2566 15.2249 17.594 17.0946L17.5941 17.0946C17.5969 17.1106 17.5996 17.1267 17.6021
       17.1429ZM2.06374 17.1429H15.5611C14.8718 14.2928 11.9568 12.6329 8.81225 12.6329C5.66759
       12.6329 2.75274 14.2928 2.06374 17.1429Z" fill="#8992A6"/>
    </svg>
  )
}

export default UsersIcon
