import React from 'react'

import './styles.scss'
import DefaultSelect from '../../../custom/DefaultSelect/DefaultSelect'
import { stylesTopMobileSelect, stylesBottomMobileSelect,  } from './styles'

const CandidatesMobileFiltersInputs = ({
  leftFilter,
  rightFilterName,
  renderIcon,
  returnLeftSelectOptions,
  returnRightSelectOptions,
  onChangeLeftFilter,
  onChangeRightFilter
}) => {
  return (
    <div className="candidate-filter-mobile-main-container">
      <DefaultSelect
        placeholder={leftFilter}
        styles={stylesTopMobileSelect}
        prefixIcon={renderIcon()}
        options={returnLeftSelectOptions()}
        onChange={newValue => onChangeLeftFilter(newValue.label)}
      />
      <DefaultSelect
        placeholder={rightFilterName}
        options={returnRightSelectOptions()}
        styles={stylesBottomMobileSelect}
        value={rightFilterName}
        onChange={newValue => onChangeRightFilter(
          newValue.label,
          leftFilter === ('Candidate' || 'Ping Again') ? newValue.value : newValue.value - 1
        )}
      />
    </div>
  )
}

export default CandidatesMobileFiltersInputs
