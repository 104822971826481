import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import dangerNotify from 'helpers/dangerNotify'
import { getJobStatuses } from 'services/API/admin/job-statuses'
import { User } from 'types/User'
import { Project } from 'types/Project'
import Modal from '../../reusable/Modal/index.tsx'
import useToggle from 'hooks/useToggle'
import ReactButton from '../../reusable/ReactButton'
import ContractsList from '../ContractsList'
import CreateContract from '../CreateContract'
import ContractsFilters from './ContractsFilters'
import { getContractsParams } from '../../../constants'
import { Contract } from '../../../types'
import { useGetContracts } from '../../../hooks/contract/useGetContracts'
import Loader from 'components/reusable/Loader'

const ManageContracts = () => {
  const [statuses, setStatuses] = useState([])
  const [open, toggle] = useToggle(false)

  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const contracts = useSelector((state: { contracts: { indexData: Contract[] } }) => state.contracts.indexData)
  const contractsLoading = useSelector((state: { contracts: { isLoading: boolean } }) => state.contracts.isLoading)

  const getContracts = useGetContracts()

  useEffect(() => {
    getContracts(getContractsParams.userId, getContractsParams.projectId, getContractsParams.statusId)
    getJobStatuses()
      .then((response) => {
        setStatuses(response.data)
      })
      .catch((error) => dangerNotify(error.response.data.error))
  }, [])

  const createContract =
  <CreateContract
    closeModal={toggle}
    usersNames={usersNames}
    projects={projects}
    statuses={statuses}
  />

  return (
    <div>
      <div className="manage-projects__row">
        <div className="manage-projects__col">
          <>
            <ReactButton text={'Add Contract'} onClick={toggle} variant="contained" />
            {open && <Modal title="Create Contract" component={createContract} open={open} handleClose={toggle} />}
          </>
          <ContractsFilters usersNames={usersNames} projects={projects} statuses={statuses} />
        </div>
      </div>
      <div className="manage-projects__row">
        <div className="manage-projects__table">
          <h3>Contracts list</h3>
          {contractsLoading ? <Loader/> :
            <ContractsList usersNames={usersNames} statuses={statuses} projects={projects} contracts={contracts} />
          }
        </div>
      </div>
    </div>
  )
}

export default ManageContracts
