import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { createResumeExperience } from '../../../services/API/admin/resume'
import { IResumeExperience } from '../../../types/Resume/IResumeExperience'
import { useGetUserCv } from '../useGetUserCv'

export const useCreateResumeExperience = () => {
  const getUserCv = useGetUserCv()

  return (resume_experience: IResumeExperience, cvId: number) => {
    createResumeExperience(resume_experience)
      .then(() => {
        successNotify('Company added')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Please fill in the contact section'))
  }
}
