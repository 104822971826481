import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { deleteResumeBook } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'

export const useDeleteResumeBook = () => {
  const getUserCv = useGetUserCv()
    
  return (id: number, cvId: number) => {
    deleteResumeBook(id)
      .then(() => {
        successNotify('Resume book deleted')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Something went wrong!'))
  }
}
