import React from 'react'

const Logo = () => {
  return (
    <svg width="142" height="39" viewBox="0 0 142 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.6106 21.0973V7.45222H48.098V9.28888H48.1763C49.0474 7.8183 50.3697 7.08301 52.1463
              7.08301C52.9986 7.08301 53.7568 7.2989 54.4243 7.73382C55.0885 8.16873 55.593 8.75071 55.9283
              9.47661H55.9815C56.9748 7.88088 58.3441 7.08301 60.0831 7.08301C61.5025 7.08301 62.6086 7.5148 63.3982
              8.37211C64.1878 9.23256 64.5826 10.4153 64.5826 11.9234V21.1005H60.9636V12.5335C60.9636 10.9722 60.2617
              10.1931 58.8579 10.1931C58.0934 10.1931 57.4949 10.4622 57.0594 11.0035C56.6239 11.5448 56.4077 12.2676
              56.4077 13.1718V21.0973H52.7855V12.5335C52.7855 10.9722 52.0836 10.1931 50.683 10.1931C49.9561 10.1931
              49.3639 10.4622 48.9127 11.0035C48.4615 11.5448 48.2327 12.2676 48.2327 13.1718V21.0973H44.6106Z"
      fill="#303540"/>
      <path d="M66.4503 5.3245V2.07983H70.0724V5.3245H66.4503ZM66.4503 21.0973V7.45215H70.0724V21.0973H66.4503Z"
        fill="#303540"/>
      <path d="M72.0963 21.0973V2.07983H75.7184V21.0973H72.0963Z" fill="#303540"/>
      <path d="M84.1597 21.4947C81.957 21.4947 80.2274 20.8157 78.9646 19.4609C77.705 18.1061 77.0752 16.3727
              77.0752 14.2607C77.0752 12.2206 77.705 10.5154 78.9646 9.14178C80.2242 7.76819 81.8411 7.07983 83.8119
              7.07983C85.9614 7.07983 87.619 7.82451 88.794 9.31387C89.9658 10.8032 90.5518 12.7995 90.5518
              15.2963H80.6441C80.7851 16.3789 81.1517 17.2143 81.7377 17.8088C82.3236 18.4033 83.1226 18.7006 84.1347
              18.7006C85.4663 18.7006 86.3437 18.1436 86.7729 17.0266H90.3418C90.0755 18.3032 89.4018 19.367 88.3177
              20.2181C87.2336 21.0691 85.8486 21.4947 84.1597 21.4947ZM83.8683 9.87394C82.1105 9.87394 81.0546 10.8502
              80.6974 12.7995H86.7698C86.7165 11.9296 86.4251 11.2256 85.8893 10.6843C85.3598 10.143 84.6861 9.87394
              83.8683 9.87394Z" fill="#303540"/>
      <path d="M97.3982 21.4947C95.4461 21.4947 93.917 21.066 92.8172 20.2056C91.7174 19.3451 91.1127 18.1718
              91.0061 16.6825H94.3619C94.5938 18.1906 95.5871 18.9447 97.3449 18.9447C98.1439 18.9447 98.7612 18.8039
              99.1967 18.5191C99.6322 18.2344 99.8484 17.8558 99.8484 17.3739C99.8484 17.1956 99.8171 17.036 99.7544
              16.8952C99.6918 16.7544 99.5852 16.6261 99.4348 16.5104C99.2844 16.3946 99.1497 16.3007 99.0369
              16.2319C98.921 16.1599 98.7204 16.0911 98.4384 16.0191C98.1533 15.9472 97.9371 15.894 97.7867
              15.8596C97.6363 15.8251 97.3794 15.7751 97.0159 15.7125C96.6524 15.6499 96.3986 15.603 96.2576
              15.5654C95.5119 15.4246 94.904 15.2776 94.434 15.1274C93.964 14.9772 93.4689 14.7551 92.9551
              14.4641C92.4412 14.17 92.0527 13.7726 91.7957 13.2657C91.5388 12.7588 91.4103 12.1518 91.4103
              11.4447C91.4103 10.0273 91.9305 8.9447 92.9676 8.20002C94.0047 7.45535 95.3427 7.08301 96.9752
              7.08301C98.733 7.08301 100.112 7.48664 101.117 8.29389C102.12 9.10115 102.694 10.2056 102.835
              11.6043H99.557C99.416 10.2745 98.5356 9.60803 96.9219 9.60803C96.195 9.60803 95.6216 9.73944 95.2048
              10.0054C94.7881 10.2714 94.5782 10.6437 94.5782 11.1224C94.5782 11.282 94.6095 11.429 94.6722
              11.5605C94.7348 11.6919 94.8414 11.8076 94.9918 11.9078C95.1422 12.0079 95.2894 12.0861 95.4304
              12.1456C95.5714 12.2081 95.7751 12.2707 96.0446 12.3302C96.3109 12.3927 96.5428 12.4459 96.737
              12.4897C96.9313 12.5335 97.1976 12.5867 97.536 12.6493C97.8744 12.7119 98.1408 12.7588 98.335
              12.7964C98.9022 12.9215 99.369 13.031 99.7325 13.128C100.096 13.225 100.519 13.3846 100.998
              13.6068C101.478 13.8289 101.854 14.0761 102.13 14.3514C102.405 14.6268 102.643 14.9991 102.847
              15.4684C103.051 15.9378 103.154 16.4853 103.154 17.1049C103.154 18.4534 102.625 19.5204 101.569
              20.3088C100.51 21.1005 99.1184 21.4947 97.3982 21.4947Z" fill="#303540"/>
      <path d="M103.336 9.84568V7.45208H105.228V4.35134L108.769 3.19678V7.45208H111.113V9.84568H108.769V17.0797C108.769
      17.9839 109.223 18.4345 110.126 18.4345L111.191 18.4095V21.069C110.64 21.1034 109.884 21.1222 108.929
      21.1222C107.882 21.1222 107.002 20.8688 106.291 20.365C105.579 19.8613 105.225 19.0227 105.225
      17.8525V9.84568H103.336Z" fill="#303540"/>
      <path d="M118.89 21.4947C116.687 21.4947 114.958 20.8157 113.695 19.4609C112.435 18.1061 111.805 16.3727 111.805
      14.2607C111.805 12.2206 112.435 10.5154 113.695 9.14178C114.954 7.76819 116.571 7.07983 118.542 7.07983C120.692
      7.07983 122.352 7.82451 123.524 9.31387C124.696 10.8032 125.282 12.7995 125.282 15.2963H115.374C115.515 16.3789
      115.882 17.2143 116.468 17.8088C117.054 18.4033 117.853 18.7006 118.865 18.7006C120.196 18.7006 121.074 18.1436
      121.503 17.0266H125.072C124.806 18.3032 124.132 19.367 123.048 20.2181C121.964 21.0691 120.579 21.4947 118.89
      21.4947ZM118.598 9.87394C116.841 9.87394 115.785 10.8502 115.428 12.7995H121.5C121.447 11.9296 121.152 11.2256
      120.623 10.6843C120.09 10.143 119.413 9.87394 118.598 9.87394Z" fill="#303540"/>
      <path d="M126.588 25.5654V7.45217H130.076V9.15429H130.154C131.166 7.77132 132.507 7.07983 134.174 7.07983C135.966
      7.07983 137.405 7.74316 138.489 9.07607C139.573 10.409 140.115 12.1424 140.115 14.2888C140.115 16.4884 139.573
      18.2375 138.489 19.5422C137.405 20.847 135.976 21.4978 134.202 21.4978C132.426 21.4978 131.113 20.8501 130.261
      19.5547H130.207V25.5654H126.588ZM133.432 18.4909C134.356 18.4909 135.086 18.128 135.628 17.3989C136.17 16.673
      136.44 15.6718 136.44 14.3952C136.44 13.031 136.183 11.9578 135.669 11.1756C135.155 10.3965 134.365 10.0054 133.3
      10.0054C132.216 10.0054 131.417 10.409 130.903 11.2162C130.389 12.0235 130.132 13.0811 130.132 14.3952C130.132
      15.653 130.417 16.6511 130.984 17.3864C131.548 18.1217 132.366 18.4909 133.432 18.4909Z" fill="#303540"/>
      <path d="M44.98 37.823V27.743H49.145C49.243 27.743 49.369 27.7476 49.523 27.757C49.677 27.7616 49.8193 27.7756
      49.95 27.799C50.5333 27.8876 51.014 28.0813 51.392 28.38C51.7747 28.6786 52.057 29.0566 52.239 29.514C52.4257
      29.9666 52.519 30.4706 52.519 31.026C52.519 31.5766 52.4257 32.0806 52.239 32.538C52.0523 32.9906 51.7677 33.3663
      51.385 33.665C51.007 33.9636 50.5287 34.1573 49.95 34.246C49.8193 34.2646 49.6747 34.2786 49.516 34.288C49.362
      34.2973 49.2383 34.302 49.145 34.302H46.667V37.823H44.98ZM46.667 32.727H49.075C49.1683 32.727 49.2733 32.7223
      49.39 32.713C49.5067 32.7036 49.614 32.685 49.712 32.657C49.992 32.587 50.2113 32.4633 50.37 32.286C50.5333
      32.1086 50.6477 31.908 50.713 31.684C50.783 31.46 50.818 31.2406 50.818 31.026C50.818 30.8113 50.783 30.592
      50.713 30.368C50.6477 30.1393 50.5333 29.9363 50.37 29.759C50.2113 29.5816 49.992 29.458 49.712 29.388C49.614
      29.36 49.5067 29.3436 49.39 29.339C49.2733 29.3296 49.1683 29.325 49.075 29.325H46.667V32.727Z" fill="#303540"/>
      <path d="M60.0044 38.033C58.9964 38.033 58.1331 37.8136 57.4144 37.375C56.6957 36.9316 56.1427 36.3156 55.7554
      35.527C55.3727 34.7383 55.1814 33.8236 55.1814 32.783C55.1814 31.7423 55.3727 30.8276 55.7554 30.039C56.1427
      29.2503 56.6957 28.6366 57.4144 28.198C58.1331 27.7546 58.9964 27.533 60.0044 27.533C61.0124 27.533 61.8757
      27.7546 62.5944 28.198C63.3177 28.6366 63.8707 29.2503 64.2534 30.039C64.6407 30.8276 64.8344 31.7423 64.8344
      32.783C64.8344 33.8236 64.6407 34.7383 64.2534 35.527C63.8707 36.3156 63.3177 36.9316 62.5944 37.375C61.8757
      37.8136 61.0124 38.033 60.0044 38.033ZM60.0044 36.444C60.6811 36.4486 61.2434 36.2993 61.6914 35.996C62.1441
      35.6926 62.4824 35.2656 62.7064 34.715C62.9351 34.1643 63.0494 33.5203 63.0494 32.783C63.0494 32.0456 62.9351
      31.4063 62.7064 30.865C62.4824 30.319 62.1441 29.8943 61.6914 29.591C61.2434 29.2876 60.6811 29.1313 60.0044
      29.122C59.3277 29.1173 58.7654 29.2666 58.3174 29.57C57.8694 29.8733 57.5311 30.3003 57.3024 30.851C57.0784
      31.4016 56.9664 32.0456 56.9664 32.783C56.9664 33.5203 57.0784 34.162 57.3024 34.708C57.5264 35.2493 57.8624
      35.6716 58.3104 35.975C58.7631 36.2783 59.3277 36.4346 60.0044 36.444Z" fill="#303540"/>
      <path d="M68.0477 37.823V27.743H72.2127C72.3107 27.743 72.4367 27.7476 72.5907 27.757C72.7447 27.7616 72.8871
      27.7756 73.0177 27.799C73.6011 27.8876 74.0817 28.0813 74.4597 28.38C74.8424 28.6786 75.1247 29.0566 75.3067
      29.514C75.4934 29.9666 75.5867 30.4706 75.5867 31.026C75.5867 31.8473 75.3791 32.5543 74.9637 33.147C74.5484
      33.735 73.9114 34.099 73.0527 34.239L72.3317 34.302H69.7347V37.823H68.0477ZM73.8367 37.823L71.8487 33.721L73.5637
      33.343L75.7477 37.823H73.8367ZM69.7347 32.727H72.1427C72.2361 32.727 72.3411 32.7223 72.4577 32.713C72.5744
      32.7036 72.6817 32.685 72.7797 32.657C73.0597 32.587 73.2791 32.4633 73.4377 32.286C73.6011 32.1086 73.7154
      31.908 73.7807 31.684C73.8507 31.46 73.8857 31.2406 73.8857 31.026C73.8857 30.8113 73.8507 30.592 73.7807
      30.368C73.7154 30.1393 73.6011 29.9363 73.4377 29.759C73.2791 29.5816 73.0597 29.458 72.7797 29.388C72.6817
      29.36 72.5744 29.3436 72.4577 29.339C72.3411 29.3296 72.2361 29.325 72.1427 29.325H69.7347V32.727Z"
      fill="#303540"/>
      <path d="M81.5319 37.823V29.325H78.2699V27.743H86.4809V29.325H83.2189V37.823H81.5319Z" fill="#303540"/>
      <path d="M88.4467 37.823L91.6247 27.743H94.0957L97.2737 37.823H95.5377L92.6537 28.779H93.0317L90.1827
      37.823H88.4467ZM90.2177 35.639V34.064H95.5097V35.639H90.2177Z" fill="#303540"/>
      <path d="M100.499 37.823V27.743H102.186V36.241H106.638V37.823H100.499Z" fill="#303540"/>
      <circle cx="18.8496" cy="19.8496" r="18.8496" fill="#303540"/>
      <path d="M6.9115 11.1845L7.46676 10.074L8.02202 9.79639L9.41016 10.3516L10.5207 11.1845L12.1865 11.7398L13.8522
      11.4622L16.9061 10.6293H19.4048L21.9035 10.9069L23.8469 11.4622L25.5127 11.7398L26.6232 11.4622L28.2889
      10.3516L29.6771 9.79639L30.51 10.3516L30.7876 11.7398L30.2323 13.4056L29.1218 15.349L28.5666
      17.0147V18.9581L28.2889 20.9016L27.4561 23.1226L25.7903 25.3436L24.4021 27.287L22.7364 29.7857L22.1811
      32.0067L20.793 33.1172L19.1272 33.6725L17.739 33.3949L16.3509 32.8396L15.518 31.4515L14.6851 28.9528L13.297
      27.0094L11.3536 25.066L9.68779 22.2897L9.13253 19.791L9.41016 17.57L8.8549 16.1819L7.74439 13.9608L6.9115
      11.1845Z" fill="#FDFDFD"/>
      <path d="M30.7715 11.2169C30.711 10.6775 30.4859 10.2708 30.1023 10.0096C29.7433 9.7648 29.338 9.73115 28.8637
      9.90685C28.4965 10.0427 28.1207 10.2922 27.7229 10.5544C27.0201 11.0191 26.2238 11.546 25.317 11.5485C24.3284
      11.51 23.7186 11.3148 23.0128 11.0887C22.0727 10.7877 21.0074 10.4465 18.8495 10.4465C16.6917 10.4465 15.6261
      10.7877 14.6863 11.0887C13.9805 11.3148 13.3707 11.51 12.3821 11.5485C11.4755 11.546 10.6798 11.0191 9.97624
      10.5544C9.57836 10.2914 9.20258 10.0427 8.83544 9.90685C8.36108 9.73115 7.95583 9.7648 7.59683 10.0096C7.21317
      10.2711 6.98705 10.6775 6.92759 11.2169C6.88034 11.6407 6.93725 12.1517 7.09706 12.7358C7.44413 14.0035 8.17028
      15.2619 8.46653 15.744C9.07199 16.7297 9.10171 17.5678 9.06436 18.4263C9.05928 18.5398 9.05293 18.6539 9.04658
      18.7685C9.02803 19.106 9.00872 19.4551 9.0232 19.8049C9.06385 20.7921 9.36443 21.8419 9.91602 22.9247C10.4582
      23.9886 11.2311 25.0624 12.2139 26.1163C14.0856 28.1233 14.6466 29.3439 15.0272 30.8942C15.2485 31.7963 15.7577
      32.5299 16.4991 33.0162C17.1541 33.4454 17.9669 33.6725 18.8495 33.6725C19.7322 33.6725 20.545 33.4454 21.2
      33.0162C21.9414 32.5299 22.4503 31.7963 22.6719 30.8942C23.053 29.3434 23.6135 28.1228 25.4852 26.1163C26.4677
      25.0631 27.2409 23.9886 27.7828 22.9247C28.3347 21.8419 28.635 20.7926 28.6759 19.8049C28.6904 19.4551 28.6711
      19.106 28.6525 18.7685C28.5976 17.789 28.5468 16.8607 29.2328 15.744C29.5291 15.2619 30.2552 14.0035 30.6023
      12.7358C30.7619 12.1517 30.8188 11.6407 30.7715 11.2169ZM9.73411 19.7759C9.72115 19.4615 9.73868 19.145 9.75723
      18.8101C9.76358 18.6927 9.77018 18.5735 9.77552 18.4528C9.8027 17.6678 10.135 16.6546 10.726 15.5295C10.7534
      15.5877 10.7841 15.6442 10.8177 15.6988C11.0342 16.0502 11.5744 16.6436 12.7568 16.668C14.8293 16.7166 15.6812
      16.9506 16.2631 17.6303L16.2748 17.6431C16.9338 18.3431 16.5052 19.8124 16.2844 20.3195C16.0557 20.8484 15.8642
      21.6578 16.864 22.5758C17.3043 22.9801 17.7578 23.2391 18.0891 23.4281C18.2398 23.5144 18.4232 23.619 18.4956
      23.6822V23.8181C18.0457 23.8615 17.5383 23.9722 17.0436 24.0826C16.1871 24.2717 15.216 24.4865 14.8032
      24.2625C14.3108 23.9951 14.0917 23.1009 14.5999 22.1227C14.7033 21.9231 14.8372 21.7007 14.979 21.4651C15.2922
      20.9449 15.6472 20.3554 15.8205 19.7638C16.0392 19.0169 15.9257 18.386 15.4833 17.8887C14.7973 17.1193 13.9802
      17.1391 13.2584 17.1566C12.7548 17.1689 12.2792 17.1805 11.8424 16.9485L11.2029 16.6089L11.3264 17.3282C11.5378
      18.5594 12.0447 19.3832 12.8328 19.7769C13.4525 20.0867 14.2302 20.1009 15.0407 19.8304C14.8883 20.2136 14.6456
      20.6349 14.3712 21.0906C14.2234 21.3362 14.0836 21.5684 13.9695 21.7885C13.4749 22.7436 13.4903 23.7711 13.9441
      24.4225C13.9695 25.1286 14.0445 26.2604 14.1898 27.3732C13.8087 26.8407 13.3343 26.2686 12.7345 25.6241C10.8721
      23.63 9.80753 21.5532 9.73385 19.7759H9.73411ZM17.3403 31.15C17.2463 31.008 17.1916 30.8431 17.1818
      30.6725C17.3061 30.8164 17.4379 30.9536 17.5766 31.0835C17.4967 31.1014 17.4177 31.1236 17.3401
      31.15H17.3403ZM18.5993 30.9943C18.4292 30.8743 18.2668 30.7433 18.1132 30.6023L18.225 30.5135L18.4324
      30.6321L18.563 30.5273L18.7464 30.6329L18.8498 30.5494L18.9532 30.6329L19.1367 30.5273L19.2673 30.6321L19.4746
      30.5135L19.6016 30.6144C19.4519 30.7518 19.2933 30.8788 19.1267 30.9946C19.0487 30.9946 18.9702 30.9946 18.8912
      30.9946H18.5993V30.9943ZM20.516 30.6964C20.5027 30.8586 20.4487 31.0147 20.3593 31.15C20.2881 31.1243 20.216
      31.1045 20.1428 31.0871C20.274 30.9637 20.3985 30.8333 20.5158 30.6964H20.516ZM21.1807 30.067C21.1553 29.9198
      21.1212 29.7613 21.0791 29.5872L20.8041 28.4402L20.3997 29.5474C20.3483 29.6825 20.2817 29.8112 20.2012
      29.9309L19.9342 29.6226L19.686 29.7662L19.5383 29.6488L19.3328 29.7665L19.2014 29.6612L19.0198 29.7657L18.8493
      29.6283L18.6788 29.7657L18.4971 29.6612L18.3655 29.7665L18.1602 29.6488L18.0124 29.7662L17.7644 29.6226L17.503
      29.9232C17.4202 29.8058 17.3519 29.6787 17.2997 29.5445L16.8932 28.4474L16.6203 29.5859C16.5786 29.7601 16.5441
      29.9198 16.5187 30.0657C16.5004 30.02 16.4821 29.9726 16.4638 29.9234C16.2262 29.2846 16.0387 28.4849 15.9081
      27.5687C16.1135 27.7956 16.3514 27.99 16.6139 28.1454L17.4039 28.6054L17.131 27.7261C17.0687 27.5201 17.0214
      27.3096 16.9895 27.0965L17.179 26.5448C17.2135 26.4428 17.2536 26.3428 17.2992 26.2453L17.5736 26.3388L17.8531
      26.125L18.2342 26.311L18.5175 26.0842L18.8498 26.3082L19.1821 26.0842L19.4654 26.311L19.8465 26.125L20.126
      26.3388L20.4004 26.2453C20.446 26.3428 20.4861 26.4428 20.5206 26.5448L20.6969 27.0585C20.6666 27.2882 20.6224
      27.5158 20.5646 27.74L20.3402 28.5802L21.0846 28.1466C21.3472 27.9913 21.5851 27.7969 21.7905 27.57C21.6599
      28.4875 21.4724 29.2859 21.2348 29.9247C21.2169 29.9737 21.1987 30.0213 21.1804 30.0675L21.1807 30.067ZM16.242
      26.8546C15.9015 26.3948 15.6533 25.7852 15.5194 25.0711C15.7499 25.0565 15.9795 25.0287 16.2069 24.9879C16.1734
      25.7025 16.1858 26.3221 16.2417 26.8546H16.242ZM21.436 26.8833C21.5 26.2536 21.5185 25.62 21.4914 24.9876C21.7192
      25.0287 21.9491 25.0565 22.18 25.0711C22.0425 25.8004 21.7884 26.4199 21.4358 26.8823L21.436 26.8833ZM20.7119
      24.8307C20.7155 25.0503 20.7119 25.2417 20.703 25.4117L20.686 25.3901L20.2541 25.5371L19.9281 25.2874L19.5566
      25.4687L19.2169 25.197L18.8488 25.4451L18.4806 25.197L18.1417 25.4695L17.7702 25.2882L17.4443 25.5378L17.0123
      25.3909L16.9953 25.4125C16.9854 25.2429 16.9829 25.0511 16.9864 24.8314L17.1927 24.786C17.786 24.655 18.3993
      24.5193 18.8485 24.5193C19.2977 24.5193 19.9111 24.655 20.5043 24.786L20.7119 24.8307ZM18.8495 23.0549C18.7312
      22.9701 18.5904 22.8897 18.4382 22.8029C18.1196 22.6208 17.723 22.3942 17.3416 22.0441C17.1961 21.9157 17.0694
      21.767 16.9653 21.6025C17.4593 21.4818 18.1849 21.4697 18.8495 21.4697C19.5142 21.4697 20.2398 21.4818 20.7338
      21.6025C20.6297 21.767 20.503 21.9157 20.3575 22.0441C19.9764 22.3942 19.5795 22.6208 19.2609 22.8029C19.1087
      22.889 18.9682 22.9694 18.8495 23.0549ZM15.2115 18.9891C14.7353 19.209 13.872 19.4957 13.1458 19.133C12.7004
      18.9102 12.3734 18.4692 12.1699 17.8178C12.551 17.8948 12.9271 17.8846 13.2734 17.8761C13.951 17.8599 14.4863
      17.8468 14.9523 18.3703C15.1072 18.5395 15.1989 18.7581 15.2115 18.9883V18.9891ZM18.8495 32.9558C17.2316 32.9558
      16.0608 32.1212 15.7178 30.7233C15.6103 30.272 15.4726 29.8286 15.3057 29.3961C14.9411 28.2039 14.7539 26.2674
      14.6802 24.9884C14.7152 24.9997 14.7511 25.01 14.7877 25.0192C14.8417 25.3703 14.9259 25.716 15.0392
      26.0524C15.1484 27.7305 15.4038 29.1186 15.7979 30.1785C16.1299 31.0714 16.5428 31.6792 16.992 31.9365L17.1699
      32.0393L17.3477 31.9335C17.7149 31.7138 18.1968 31.7138 18.8074 31.7141H18.892C19.5018 31.7141 19.9845 31.7141
      20.3516 31.9335L20.5295 32.0393L20.7089 31.9365C21.1578 31.6797 21.5707 31.0714 21.903 30.1785C22.2966 29.1171
      22.5507 27.73 22.6599 26.0513C22.7732 25.7151 22.8574 25.3695 22.9114 25.0184C22.948 25.0092 22.9839 24.9989
      23.0189 24.9876C22.9452 26.2663 22.758 28.2031 22.3934 29.3953C22.2265 29.8278 22.0888 30.2712 21.9813
      30.7226C21.6383 32.1205 20.4672 32.955 18.8495 32.955V32.9558ZM27.9653 19.7767C27.8916 21.554 26.827 23.6298
      24.9672 25.6249C24.367 26.2671 23.8929 26.8415 23.5118 27.374C23.6572 26.2612 23.7321 25.1297 23.7575
      24.4233C24.2105 23.7718 24.226 22.7443 23.7321 21.7893C23.618 21.5691 23.478 21.3369 23.3304 21.0914C23.0563
      20.6364 22.8154 20.2159 22.6619 19.8327C23.4879 20.1124 24.2781 20.0998 24.9014 19.7839C25.6829 19.3873 26.1791
      18.5596 26.375 17.3243L26.4878 16.6143L25.8582 16.9483C25.4215 17.1794 24.9458 17.1687 24.4422 17.1563C23.7207
      17.1389 22.9023 17.1188 22.2173 17.8884C21.775 18.3855 21.6614 19.0163 21.8802 19.7636C22.0534 20.3544 22.4084
      20.9452 22.7217 21.4649C22.8634 21.7004 22.9973 21.9229 23.1007 22.1224C23.6076 23.1006 23.3896 23.9948 22.8975
      24.2622C22.4843 24.4865 21.5135 24.272 20.657 24.0824C20.1624 23.973 19.655 23.8612 19.205
      23.8178V23.6819C19.2764 23.6187 19.4591 23.5142 19.6115 23.4279C19.9418 23.2388 20.3961 22.9799 20.8367
      22.5756C21.8365 21.6573 21.6462 20.8481 21.416 20.3192C21.1954 19.8121 20.7668 18.3428 21.4259 17.6428L21.4373
      17.63C22.0189 16.9501 22.8708 16.7163 24.9435 16.6677C26.1265 16.6433 26.6669 16.0513 26.8834 15.6986C26.9171
      15.6439 26.9477 15.5874 26.9751 15.5293C27.5661 16.6536 27.8984 17.6675 27.9256 18.4525C27.931 18.5732 27.9373
      18.6924 27.9439 18.8098C27.9607 19.145 27.9782 19.4615 27.9653 19.7759V19.7767ZM22.4876 18.9894C22.5001 18.7588
      22.5916 18.5399 22.7466 18.3703C23.2128 17.8468 23.7481 17.8596 24.4257 17.8761C24.7748 17.8846 25.1537 17.8938
      25.5383 17.816C25.3435 18.4725 25.0231 18.9172 24.5817 19.1412C23.8538 19.5101 22.9727 19.2134 22.4876
      18.9886V18.9894ZM29.9163 12.5447C29.5941 13.7224 28.9081 14.9095 28.6281 15.3654C28.4306 15.6837 28.2709 16.0245
      28.1522 16.3806C27.8278 15.5586 27.39 14.7707 26.9934 14.1383C26.7963 13.6978 26.7259 13.1052 27.4762
      12.7409C27.8667 12.5514 28.1967 12.3161 28.4876 12.1088C28.6886 11.9654 28.9856 11.7538 29.1081 11.7306C29.1154
      11.8051 29.0847 12.0235 28.8703 12.4368C28.759 12.6485 28.6352 12.8532 28.4993 13.0497C28.5762 13.1055 28.6641
      13.1442 28.757 13.1629C28.8498 13.1817 28.9456 13.1802 29.0379 13.1586C29.1301 13.137 29.2167 13.0956 29.292
      13.0374C29.3672 12.9792 29.4292 12.9055 29.4739 12.8211C29.4976 12.7764 29.5212 12.7307 29.5443 12.6842C29.6681
      12.4335 30.0451 11.6705 29.6294 11.2292C29.5049 11.0969 29.229 10.902 28.7826 11.0849C28.5639 11.1743 28.3385
      11.3348 28.0778 11.5216C27.8095 11.7129 27.5054 11.9297 27.168 12.0934C26.0226 12.65 25.9992 13.6816 26.3575
      14.4615C26.4761 14.7183 26.4459 15.0482 26.2784 15.3199C26.0358 15.7135 25.5561 15.9364 24.9265 15.9506C23.4021
      15.9863 22.3235 16.1173 21.5339 16.6125C22.0242 15.4139 22.8581 14.359 23.8205 13.8249C23.7747 13.7405 23.7112
      13.6673 23.6344 13.6102C23.5577 13.5532 23.4696 13.5138 23.3762 13.4948C23.2828 13.4758 23.1865 13.4776 23.0939
      13.5001C23.0013 13.5226 22.9147 13.5652 22.8401 13.6251C22.2557 14.0921 21.7239 14.7276 21.3009 15.4717C20.7353
      16.4661 20.4014 17.5861 20.3608 18.6251C20.3555 18.7993 20.3624 18.9736 20.3814 19.1468C20.4386 19.6508 20.567
      20.1438 20.7625 20.611C20.8036 20.7019 20.8362 20.7965 20.8598 20.8936C20.3623 20.7782 19.7091 20.751 18.8478
      20.751C17.9865 20.751 17.3332 20.7782 16.8358 20.8936C16.8593 20.7965 16.8919 20.7019 16.9331 20.611C17.1286
      20.1438 17.2569 19.6508 17.3142 19.1468C17.3332 18.9736 17.34 18.7993 17.3348 18.6251C17.2944 17.5861 16.9605
      16.4674 16.3947 15.4717C15.9714 14.7268 15.4394 14.0921 14.8555 13.6251C14.7809 13.5652 14.6943 13.5226 14.6017
      13.5001C14.5091 13.4776 14.4127 13.4758 14.3193 13.4948C14.226 13.5138 14.1379 13.5532 14.0611 13.6102C13.9844
      13.6673 13.9209 13.7405 13.875 13.8249C14.8385 14.359 15.6723 15.414 16.1617 16.6128C15.3723 16.1173 14.2935
      15.9863 12.7683 15.9506C12.1397 15.9375 11.66 15.7135 11.4174 15.3199C11.2499 15.0482 11.2197 14.7194 11.3383
      14.4615C11.6976 13.6816 11.6732 12.65 10.5278 12.0934C10.1904 11.9297 9.8863 11.7129 9.61799 11.5216C9.35731
      11.3356 9.13195 11.175 8.91319 11.0849C8.46678 10.902 8.19085 11.0969 8.0661 11.2292C7.65069 11.6705 8.02774
      12.4335 8.15147 12.6842C8.17459 12.7307 8.19822 12.7764 8.22185 12.8211C8.2666 12.9055 8.32864 12.9792 8.40385
      13.0374C8.47905 13.0956 8.56568 13.137 8.65794 13.1586C8.75021 13.1802 8.84597 13.1817 8.93884 13.1629C9.03172
      13.1442 9.11955 13.1055 9.19648 13.0497C9.06178 12.8553 8.939 12.6528 8.82884 12.4432C8.6116 12.0261 8.5801
      11.8054 8.58772 11.7306C8.71018 11.7538 9.0072 11.9654 9.20817 12.1088C9.49909 12.3161 9.82913 12.5514 10.2196
      12.7409C10.9692 13.1052 10.899 13.6978 10.7024 14.1381C10.3063 14.7707 9.868 15.5583 9.54355 16.3806C9.42492
      16.0245 9.26519 15.6837 9.06767 15.3654C8.78818 14.9095 8.10218 13.7214 7.77951 12.5447C7.59073 11.855 7.47462
      10.959 7.99115 10.6066C8.34686 10.3636 8.8296 10.6579 9.58344 11.1568C10.3322 11.652 11.2639 12.2683 12.3851
      12.2683H12.3986C13.4873 12.2267 14.1725 12.0073 14.8977 11.7751C15.8322 11.4761 16.7984 11.1666 18.8462
      11.1666C20.8941 11.1666 21.8603 11.4748 22.7948 11.7751C23.52 12.0063 24.2049 12.2267 25.2939
      12.2683H25.3074C26.4284 12.2683 27.3603 11.6518 28.1091 11.1568C28.8642 10.6574 29.3451 10.3638 29.7013
      10.6063C30.2217 10.9582 30.1051 11.8552 29.9163 12.5439V12.5447Z" fill="#303540"/>
    </svg>
  )
}

export default Logo
