import API from '../../index'
import ROUTES from '../../../../routes'

export const getUsersProfile = (userId: number) =>
  API.get(ROUTES.PROFILES + `/${userId}` )

export const fetchProfileTechnologies = () => API.get(ROUTES.PROFILE_TECHNOLOGIES)

export const fetchUsersProfiles = (
  hired: boolean | undefined,
  fired: boolean | undefined,
  page: number,
  date: string
) =>
  API.get(ROUTES.PROFILES, { params: { hired, fired, page, date } })
