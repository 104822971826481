import React from 'react'
import { useLocation } from 'react-router-dom'
import { Control, Controller, UseFormRegister, UseFormRegisterReturn } from 'react-hook-form'
import { Box } from '@mui/material'
import { FormGroup } from 'reactstrap'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'

import { IOmitedRecord } from './CreateRecord'
import ProjectsIcon from '../../../icons/ProjectsIcon'
import DefaultSelect from 'components/custom/DefaultSelect/DefaultSelect'
import CategoriesIcon from '../../../icons/CategoriesIcon'
import DefaultInput from 'components/custom/DefaultInput/DefaultInput'
import HoursIcon from '../../../icons/HoursIcon'
import CustomButton from 'components/custom/CustomButton/CustomButton'
import { webRoutes } from 'lib'
import { UserIcon } from 'icons'
import { IOptions } from 'types/ISelectOptions'

interface Props {
  hoursReg: UseFormRegisterReturn
  descriptionReg: UseFormRegisterReturn
  userProjects: { value: number; label: string }[]
  userCategories: { value: number; label: string }[]
  control: Control<IOmitedRecord>
  register: UseFormRegister<IOmitedRecord>
  validateForm: () => boolean
  usersList: IOptions[]
  setUserId: React.Dispatch<React.SetStateAction<IOptions | undefined>>
  userId: IOptions | undefined
  currentStatusContract: (id: number) => void
}

const CreateRecordInputs: React.FC<Props> = ({
  userProjects,
  userCategories,
  control, register,
  validateForm,
  usersList,
  setUserId,
  currentStatusContract,
  userId }) => {
  const location = useLocation()

  const isCreateAdminPage = location.pathname === webRoutes.SUBMIT_HOURS_ADMIN
  const disabledSelects = !userId && isCreateAdminPage
  const loading = useSelector((state: { records: { isLoading: boolean } }) => state.records.isLoading)
  const { ref, ...descriptionField } = register('description')

  return (
    <Box className="create-record__main">
      {!isMobile ?
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '1rem' }}>
            <Box className="create-record__row">
              {isCreateAdminPage &&
                <Box className="create-record__col">
                  <Controller
                    render={({ field: { onChange } })=> (
                      <DefaultSelect
                        placeholder="User"
                        isClearable
                        value={userId}
                        onChange={(val) => {
                          onChange(currentStatusContract(val?.value))
                          onChange(val)
                          setUserId(val)
                        }}
                        options={usersList}
                        prefixIcon={<UserIcon/>} />
                    )}
                    name="user"
                    control={control}
                  />
                </Box>
              }
              <Box className="create-record__col">
                <Controller
                  render={({ field }) => (
                    <DefaultSelect
                      placeholder="Project"
                      isClearable
                      {...field}
                      isDisabled={disabledSelects}
                      options={userProjects}
                      prefixIcon={<ProjectsIcon/>} />
                  )}
                  name="project"
                  control={control}
                />
              </Box>

              <Box className="create-record__col">
                <Controller
                  render={({ field }) => (
                    <DefaultSelect
                      placeholder="Category"
                      isClearable
                      {...field}
                      isDisabled={disabledSelects}
                      options={userCategories}
                      prefixIcon={<CategoriesIcon/>} />
                  )}
                  name="category"
                  control={control}
                />
              </Box>
            </Box>

            <Box className="create-record__col">
              <DefaultInput
                placeholder="Description (optional)"
                type="text"
                innerRef={ref}
                {...descriptionField}/>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', width: '15%' }}>
            <Box className="create-record__col mb-0">
              <Controller
                render={({ field: { ref, ...fieldProps } }) => (
                  <FormGroup>
                    <DefaultInput
                      placeholder={'Hours'} innerRef={ref} type="number" {...fieldProps} prefixIcon={<HoursIcon/>}/>
                  </FormGroup>
                )}
                name="hours"
                control={control}
              />
            </Box>

            <Box className="create-record__col--button">
              <CustomButton
                disabled={!validateForm() || loading}
                type="submit"
                variant="contained"
                text="Save"
              />
            </Box>
          </Box>
        </> : <>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '1rem' }}>
            {isCreateAdminPage &&
              <Box className="create-record__col">
                <Controller
                  render={({ field: { onChange, value } })=> (
                    <DefaultSelect
                      placeholder="User"
                      isClearable
                      value={usersList.find((option) => option.value === value)}
                      onChange={(val) => {
                        onChange(currentStatusContract(val?.value))
                        onChange(val)
                        setUserId(val)
                      }}
                      options={usersList}
                      prefixIcon={<UserIcon/>} />
                  )}
                  name="user"
                  control={control}
                />
              </Box>
            }
            <Box className="create-record__col">
              <Controller
                render={({ field }) => (
                  <DefaultSelect
                    placeholder="Project"
                    isClearable
                    {...field}
                    options={userProjects}
                    prefixIcon={<ProjectsIcon/>} />
                )}
                name="project"
                control={control}
              />
            </Box>
            <Box className="create-record__row">
              <Box className="create-record__col" style={{ flexGrow: 1.5 }}>
                <Controller
                  render={({ field }) => (
                    <DefaultSelect
                      placeholder="Category"
                      isClearable
                      {...field}
                      options={userCategories}
                      prefixIcon={<CategoriesIcon/>} />
                  )}
                  name="category"
                  control={control}
                />
              </Box>

              <Box className="create-record__col mb-0" style={{ flexGrow: 1 }}>
                <Controller
                  render={({ field: { ref, ...fieldProps } }) => (
                    <FormGroup>
                      <DefaultInput
                        placeholder={'Hours'} innerRef={ref} type="number" {...fieldProps} prefixIcon={<HoursIcon/>}/>
                    </FormGroup>
                  )}
                  name="hours"
                  control={control}
                />
              </Box>
            </Box>

            <Box className="create-record__col">
              <DefaultInput
                placeholder="Description"
                type="text"
                innerRef={ref}
                {...descriptionField}/>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box className="create-record__col--button">
              <CustomButton
                disabled={!validateForm() || loading}
                type="submit"
                variant="contained"
                text="Save"
              />
            </Box>
          </Box>

          {isMobile &&
          <div style={{
            width: '100%',
            height: '1px',
            backgroundColor: '#D4D9E2',
            marginTop: '20px',
            marginBottom: '40px',
            padding: '0 10px' }}>
          </div>}
        </>}
    </Box>
  )
}

export default CreateRecordInputs
