import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import { getUserCv } from '../../services/API/admin/resume'
import { getResume, getResumeFailure, getResumeSuccess } from '../../store/resumesSlice'

export const useGetUserCv = () => {
  const dispatch = useDispatch()

  return (cvId: number) => {
    getResume()
    getUserCv(cvId)
      .then((response) => {
        dispatch(getResumeSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getResumeFailure(error))
        dangerNotify('Server error: unable to download user cv')
      })
  }
}
