import React, { useEffect } from 'react'
import { SingleValue } from 'react-select'

import './mobile_dashboard.scss'
import DefaultSelect from '../../custom/DefaultSelect/DefaultSelect'
import { mobileDashboardFilterSelect } from '../desktop/dashboardStyles'
import { UserIcon } from '../../../icons'
import { useFetchDashboardUsers } from '../../../hooks/user/useFetchDashboardUsers'
import { useUpdateUserStatus } from '../../../hooks/user/useUpdateUserStatus'
import CustomDatepicker from '../../custom/CustomDatepicker/CustomDatepicker'
import { IStringOptions } from '../../../types/ISelectOptions'
import { MobileAvailableDevelopers, MobileFiredDevelopers, MobileHiredDevelopers } from './index'
import { dateFormatDDMMYY } from '../../../helpers/displayingDateFormat'
import { usersSubRoles } from '../../../constants'
import UsersIcon from '../../../icons/UsersIcon'

const filterData = [
  { label: 'Available', value: 'available' },
  { label: 'Hired', value: 'hired' },
  { label: 'Fired', value: 'fired' }
]

const options: IStringOptions[] = [
  { label: 'Busy on full-time outstaff', value: 'busy_on_full_time_outstaff' },
  { label: 'Ready for full-time outstaff', value: 'ready_for_full_time_outstaff' },
  { label: 'Busy on outsource', value: 'busy_on_outsource' }
]

type IProps = {
  isFired: boolean
  isHired: boolean
  date: Date
  option: IStringOptions
  setOption: React.Dispatch<React.SetStateAction<{label: string, value: string}>>
  setDate: (value: Date, keyboardInputValue?: (string | undefined)) => void
  subRole: IStringOptions
  page: number
  setPage: (value: number) => void
  onHandleSubRole: (value: IStringOptions) => void
}

const MobileDashboard: React.FC<IProps> = ({
  isFired,
  isHired,
  date,
  option,
  setOption,
  setDate,
  subRole,
  page,
  setPage,
  onHandleSubRole
}) => {
  const period = dateFormatDDMMYY(date as Date)
  const [open, setOpen] = React.useState(false)
  const fetchDashboardUsers = useFetchDashboardUsers()
  const updateUserStatusHook = useUpdateUserStatus()

  useEffect(() => {
    fetchDashboardUsers(page, subRole.value)
  }, [page, subRole.value])

  const onChangeStatus = (status: SingleValue<IStringOptions>, userId: number, page: number) => {
    if (status){
      updateUserStatusHook(status.value, userId, page)
    }
  }

  return (
    <div className="mobile_dashboard__wrapper">
      <div className="mobile_dashboard__filters">
        {option.value === 'available' &&
          <div className="mobile_dashboard__selectFilter">
            <DefaultSelect
              styles={mobileDashboardFilterSelect}
              options={usersSubRoles}
              value={subRole}
              onChange={newValue => onHandleSubRole(newValue as IStringOptions)}
              prefixIcon={<UsersIcon/>}
              isSearchable={false}
            />
          </div>
        }
        <div className="mobile_dashboard__selectFilter">
          <DefaultSelect
            styles={mobileDashboardFilterSelect}
            options={filterData}
            value={option}
            onChange={newValue => setOption(newValue as IStringOptions)}
            prefixIcon={<UserIcon/>}
            isSearchable={false}
          />
        </div>
        {(isFired || isHired) &&
          <div className="mobile_dashboard__datepicker">
            <CustomDatepicker
              value={date}
              onChange={(value) => setDate(value as Date)}
              mask={'__.__.____'}
              inputType="month"
              maxDate={new Date()}
              datePickerType="month"
              borderInputColor="#D4D9E2"
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              disableClearButton={true}
            />
          </div>
        }
      </div>
      {isHired ?
        <MobileHiredDevelopers
          period={period}
          isHired={isHired}
        /> :
        isFired ?
          <MobileFiredDevelopers
            period={period}
          /> :
          <MobileAvailableDevelopers
            page={page}
            setPage={setPage}
            onChangeStatus={onChangeStatus}
            options={options}
          />
      }
    </div>
  )
}

export default MobileDashboard
