import React from 'react'
import { CalendarApi } from '@fullcalendar/react'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import locale from 'date-fns/locale/en-GB'
import { useSelector } from 'react-redux'
import { CalendarPicker, CalendarPickerSkeleton, LocalizationProvider, PickersDay } from '@mui/x-date-pickers'

import { IOmitedRecord } from './MonthlyCalendarReport'
import { StatusType } from 'enums/Types'
import { renderCategory, renderProject } from 'helpers/renderName'
import { Category, Project } from 'types'
import { isAdmin, isManager } from 'helpers/accessHelpers'
import { useAuth } from 'context/auth'
import DefaultSelect from 'components/custom/DefaultSelect/DefaultSelect'
import { IOptions } from 'types/ISelectOptions'
import ProjectsIcon from 'icons/ProjectsIcon'
import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import { UserIcon } from 'icons'
import { dateFormatYYMM, dateFormatYYMMDD } from 'helpers/displayingDateFormat'
import { eventColors, mobileEventColors } from './MonthlyCalendarHelpers'

interface Props {
    projects: Project[]
    usersList: IOptions[]
    selectedProject: IOptions
    availableContractsOptions: IOptions[]
    onChangeProject: (project: IOptions) => void
    onChangeUser: (user: IOptions) => void
    date: Date
    setDate: React.Dispatch<React.SetStateAction<Date>>
    calendarAPI: CalendarApi | undefined
    arrowNextItem: () => void
    arrowBackItem: () => void
    selectedUser: IOptions
    allRecordNHolidays: IOmitedRecord[] | undefined
}

const MobileCalendar: React.FC<Props> = ({
  projects,
  selectedProject,
  availableContractsOptions,
  onChangeProject,
  onChangeUser,
  date,
  setDate,
  calendarAPI,
  arrowNextItem,
  arrowBackItem,
  usersList,
  selectedUser,
  allRecordNHolidays
}) => {
  const { currentUser } = useAuth()
  const isAdminUser = isManager(currentUser) || isAdmin(currentUser)
  const dates = allRecordNHolidays?.map((d) => {
    return { date: d.date, type: d.record_type }
  })

  const selDay = allRecordNHolidays?.filter((ev) => ev.date === dateFormatYYMMDD(date))
  const categories = useSelector((state: { categories: { indexData: Category[] } }) => state.categories.indexData)

  const useStyles = makeStyles({
    root: {
      width: '95%',
      '& .css-1dozdou': {
        display: 'none',
      },
      '& .Mui-selected': {
        borderRadius: '10%',
        backgroundColor: '#6172F3 !important',
        color: '#FFF !important',
      },
      '& .MuiCalendarPicker': {
        width: '400px',
      },
      '& .MuiPickersDay-today': {
        borderRadius: '10%',
      },
      '& [role=row]': {
        justifyContent: 'space-between',
        marginBottom: '5px',
      },
      '& [role=row] > div > button': {
        padding: '20px 20px',
      },
      '& [role=columnheader]': {
        fontSize: '0.85rem',
        fontWeight: '600'
      },
      '& [role=presentation]':{
        minHeight: '290px'
      }
    },
  })

  const classes = useStyles()
  
  return(
    <div style={{ height: '100%', position: 'relative' }}>
      <div style={{ margin: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <CustomDatepicker
          value={date}
          onChange={(value) => {
            setDate(value as Date)
            calendarAPI?.gotoDate(value)
          }}
          mask={'__.__.____'}
          inputType="day"
          minDate={dayjs().add(-1, 'M').startOf('month').toDate()}
          maxDate={dayjs().add(1, 'M').endOf('month').toDate()}
          disableMinusArrow={dateFormatYYMM(date) === dateFormatYYMM(dayjs().add(-1, 'M').startOf('month').toDate())}
          disablePlusArrow={dateFormatYYMM(date) === dateFormatYYMM(dayjs().add(1, 'M').endOf('month').toDate())}
          datePickerType="month"
          borderInputColor="#D4D9E2"
          arrowNextItem={arrowNextItem}
          arrowBackItem={arrowBackItem}
          disableClearButton={true}
          width="300px"
        />
        {isAdminUser && 
        <>
          <DefaultSelect
            options={[{ value: 0, label: 'Available Projects' }, ...availableContractsOptions]}
            value={selectedProject}
            prefixIcon={<ProjectsIcon />}
            onChange={(newValue) => onChangeProject(newValue as IOptions)}
            placeholder="All Users"
          />
          <DefaultSelect
            options={usersList}
            value={selectedUser}
            prefixIcon={<UserIcon />}
            onChange={(newValue) => onChangeUser(newValue as IOptions)}
            placeholder="All Users"
          />
        </>
        }
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <CalendarPicker
          date={new Date(date)}
          dayOfWeekFormatter={(day) => day}
          onChange={(newDate) => setDate(newDate)}
          renderLoading={() => <CalendarPickerSkeleton />}
          className={classes.root}
          showDaysOutsideCurrentMonth
          renderDay={(day, selectedDate, DayComponentProps) => {
            const isSelected =
              !DayComponentProps.outsideCurrentMonth && dates && dates.some((d) => d.date === dateFormatYYMMDD(day))

            return (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <PickersDay {...DayComponentProps} />
                {isSelected && (
                  <div
                    style={{
                      width: '5px',
                      height: '5px',
                      backgroundColor: eventColors(day, dates),
                      borderRadius: '50%',
                      marginTop: '-10px',
                      zIndex: 1,
                    }}
                  ></div>
                )}
              </div>
            )
          }}
        />
      </LocalizationProvider>
      {selDay?.length > 0 &&
        <div
          style={{
            width: '100%',
            height: '100%',
            marginTop: '60px',
            border: '1px solid #fff',
            boxShadow: '0px -19px 35px -14px rgba(198, 198, 198, 0.7)',
            borderRadius: '8px',
          }}
        >
          {selDay?.map((day, index) => (
            <div key={index} style={{ 
              display: 'flex',
              margin: '1.5rem', 
              padding: '16px', 
              borderRadius: '8px',
              width: '90%',
              backgroundColor: mobileEventColors(day?.record_type, 'background') }}>
              <div style={{ width: '2px', 
                height: '20px', 
                backgroundColor: mobileEventColors(day?.record_type, 'line'), 
                marginLeft: '-10px',
                marginRight: '20px' }}></div>
              {day?.record_type === StatusType.REGULAR ? 
                <h6 style={{ margin: 0 }}>
                  {renderProject(day.project_id, projects)}, {day.hours}, {renderCategory(day.category_id, categories)}
                </h6> :
                <h6 style={{ margin: 0 }}>{!day?.record_type ? day?.title : day?.description}</h6>
              }
            </div>
          ))}
        </div>
      }
    </div>
  )
}

export default React.memo(MobileCalendar)
