import { ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
    route?: string,
    title: string,
    toggleButton: () => void,
    key?: React.Key | null | undefined
}

const MobileNavbarItem: React.FC<Props> = ({ route, title, toggleButton, key }) => {
  const ListItemTextStyling: React.CSSProperties = {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '100%',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    fontFamily: 'Manrope',
    textDecoration: 'none',
    color: '#303540',
    margin: '1.5rem auto'
  }

  return (
    <>
      {!route ?
        <ListItemButton onClick={toggleButton}>
          <ListItemText primaryTypographyProps={{ style: ListItemTextStyling }} primary={title} />
        </ListItemButton>
        :     
        <Link key={key} to={route}>
          <ListItemButton onClick={toggleButton}>
            <ListItemText primaryTypographyProps={{ style: ListItemTextStyling }} primary={title} />
          </ListItemButton>
        </Link>}
    </>
  )
}

export default MobileNavbarItem
