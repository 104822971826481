import { Project, Status, User } from '../types'
import { BENCH_PROJECT, jobStatusesNames } from '../constants'

export const usersOptionsForContractSelect = (users: User[]) => {
  return users?.filter(user => user.active).map((user: User) => ({
    value: user.id,
    label: user.full_name,
  }))
}
export const projectsOptionsForContractSelect = (projects: Project[]) => {
  return projects?.filter(p => p.name != BENCH_PROJECT).map((project: Project) => ({
    value: project.id,
    label: project.name,
  }))
}
export const jobStatusesOptionsForContractSelect = (statuses: Status[]) => {
  return statuses?.filter(s => s.name != jobStatusesNames.FREE).map((status: Status) => ({
    value: status.id,
    label: status.name,
  }))
}
