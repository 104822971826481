import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Navbar } from 'reactstrap'
import { useSelector } from 'react-redux'

import { useAuth } from '../../../context/auth'
import { useDeleteSession } from '../../../hooks/session/useDeleteSession'
import { Notification } from '../../../types'
import DesktopNavbar from './components/DesktopNavbar'
import MobileNavbar from './components/MobileNavbar'
import webRoutes from 'lib/webRoutes'
import { useUtility } from 'context/utility'

const SiteNavbar = () => {
  const { width } = useUtility()
  const [isHidden, setHidden] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser } = useAuth()
  const deleteSession = useDeleteSession()
  const navigate = useNavigate()
  const notifications = useSelector(
    (state: { notifications: { indexData: Notification[] } }) => state.notifications.indexData,
  )
  const newNotifications = notifications?.filter((notification) => !notification.read)
  const location = useLocation()
  const isCv = location.pathname.match(/^(\/cv\/.*)/)
  const isMobile = width < 1199.98 && width > 0

  /*
   * Temporary solution until navbar redesign/rework
   */

  useEffect(() => {
    if (isMobile && window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/')) === '/users') {
      setHidden(true)
    } else {
      setHidden(false)
    }
  }, [location, isMobile])

  if (isHidden) return null

  const logout = () => {
    navigate(webRoutes.HOME)
    deleteSession()
    localStorage.clear()
    setIsOpen(false)
  }

  return (
    <>
      {!((!currentUser && isCv) || (isCv && isMobile)) && (
        <Navbar color="light" expand="md" container="xxl" light>
          {isMobile ? (
            <MobileNavbar
              newNotifications={newNotifications}
              logout={logout}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              currentUser={currentUser}
            />
          ) : (
            <DesktopNavbar notifications={notifications} newNotifications={newNotifications} logout={logout} />
          )}
        </Navbar>
      )}
    </>
  )
}

export default React.memo(SiteNavbar)
