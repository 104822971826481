import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import DesktopProjectProfile from './desktopProjectProfile'
import MobileProjectProfile from './mobileProjectProfile'
import { IProjectTechnology } from 'components/admin/ProjectsList/ProjectsList'
import { useGetProject } from 'hooks/project/useGetProject'
import { useFetchAllCustomers } from 'hooks/customer/useFetchAllCustomers'
import { useFetchProjectTechnologies } from 'hooks/project/useFetchProjectTechnologies'
import { useGetCategories } from 'hooks/category/useGetCategories'
import { useGetProjectContracts } from 'hooks/project/useGetProjectContracts'
import { Customer, NewProject, Status, Technology, User } from 'types'
import { Pages } from 'enums/StartPage'
import { getJobStatuses } from 'services/API/admin/job-statuses'
import { useGetTechnologies } from '../../../../hooks/Technology/useGetTecnologies'

const ProjectProfile = () => {
  const [openProject, setOpenProject] = useState(false)
  const [status, setStatus] = useState<Status[]>([])
  const [page, setPage] = useState(Pages.START_PAGE)
  const [totalPages, setTotalPages] = useState(1)
  const [, setProjectData] = useState<NewProject>()
  const { projectId } = useParams()
  const technologies = useSelector(
    (state: { technologies: { indexData: Technology[] } }) => state.technologies.indexData)
  const allCustomers = useSelector(
    (state: { customers: { allCustomersData: Customer[] } }) => state.customers.allCustomersData
  )
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const projects = useSelector((state: { projects: { indexUserData: NewProject[] } }) => state.projects.indexUserData)
  const project = useSelector((state: { projects: { showData: NewProject } }) => state.projects.showData)
  const allProjectTechnologies = useSelector(
    (state: { projects: { projectTechnologies: IProjectTechnology[] } }) => state.projects.projectTechnologies
  )

  const projectTechnologies = allProjectTechnologies?.filter(item => item.project_id === project.id)
  const getAllCustomers = useFetchAllCustomers()
  const getProject = useGetProject()
  const getTechnologies = useGetTechnologies()
  const fetchProjectTechnologiesHook = useFetchProjectTechnologies()
  const getCategoryHook = useGetCategories()
  const projectsContracts = useGetProjectContracts()
  
  const toggleProject = () => {
    setOpenProject(!openProject)
  }

  useEffect(() => {
    projectsContracts(Number(projectId), page, setTotalPages)
  }, [page])
  
  useEffect(() => {
    if (projectId) {
      getProject(+projectId)
    }

    // TODO: move job statuses to redux store or smth
    getJobStatuses().then((response) => {
      setStatus(response.data)
    })
    fetchProjectTechnologiesHook()
    getTechnologies()
    getAllCustomers()
    getCategoryHook()
  }, [projectId])
  
  return (
    <div>
      {
        isMobile ?
          <MobileProjectProfile
            project={project}
            projects={projects}
            usersNames={usersNames}
            allCustomers={allCustomers}
            allProjectTechnologies={allProjectTechnologies}
            projectTechnologies={projectTechnologies}
            technologies={technologies}
            status={status}
            setPage={setPage}
            page={page}
            totalPages={totalPages}
            openProject={openProject} 
            toggleProject={toggleProject}
            setProjectData={setProjectData}
            setOpenProject={setOpenProject}
          />
          :
          <DesktopProjectProfile
            project={project}
            projects={projects}
            usersNames={usersNames}
            allCustomers={allCustomers}
            allProjectTechnologies={allProjectTechnologies}
            projectTechnologies={projectTechnologies}
            technologies={technologies}
            status={status}
            setPage={setPage}
            page={page}
            totalPages={totalPages}
            openProject={openProject} 
            toggleProject={toggleProject}
            setProjectData={setProjectData}
            setOpenProject={setOpenProject}
          />
      }
    </div>
  )
}

export default ProjectProfile
