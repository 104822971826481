import * as React from 'react'
import ReactSelect, {
  ActionMeta,
  components,
  ControlProps,
  GroupBase,
  IndicatorSeparatorProps,
  Options,
  OptionsOrGroups,
  PropsValue,
  SingleValue,
  StylesConfig,
} from 'react-select'
import Select from 'react-select/dist/declarations/src/Select'

interface Props {
  className?: string
  styles?: StylesConfig<MyOptionType, IsMulti>
  placeholder?: string
  isClearable?: boolean
  options?: OptionsOrGroups<MyOptionType | DoubleStringType, GroupBase<MyOptionType | DoubleStringType>>
  prefixIcon?: JSX.Element
  onClick?: () => void
  disabled?: boolean
  style?: React.CSSProperties
  value?: PropsValue<MyOptionType | DoubleStringType>
  onChange?: (
    newValue: SingleValue<MyOptionType | DoubleStringType>,
    actionMeta: ActionMeta<MyOptionType | DoubleStringType>,
  ) => void
  defaultInputValue?: string
  ref?: React.Ref<Select<MyOptionType, false, GroupBase<MyOptionType>>>
  name?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  isSearchable?: boolean
  isOptionDisabled?:
    | ((option: MyOptionType | DoubleStringType, selectValue: Options<MyOptionType | DoubleStringType>) => boolean)
    | undefined
  isDisabled?: boolean
}

type MyOptionType = {
  label: string
  value: number
}

export type DoubleStringType = {
  label: string
  value: string
}

const indicatorSeparatorStyle = {
  display: 'none',
}

type IsMulti = false

const IndicatorSeparator = ({ innerProps }: IndicatorSeparatorProps<MyOptionType, IsMulti>) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />
}

export const defaultSelectStyles: StylesConfig<MyOptionType, IsMulti> = {
  control: (css) => ({
    ...css,
    padding: '10px',
    borderRadius: '10px',
    border: '1px solid #D4D9E2',
    boxShadow: 'none',
    height: '60px',
    '&:hover': {
      border: '1px solid #D4D9E2',
    }
  }),
  menu: (css) => ({
    ...css,
    zIndex: 5
  }),
  option: (css, { isFocused, isSelected, isDisabled }) => ({
    ...css,
    backgroundColor: isFocused || isSelected ? '#F9F9FA' : 'white',
    color: isDisabled ? 'gray' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default'
  })
}

const DefaultSelect: React.FC<Props> = ({
  styles,
  placeholder,
  isClearable,
  options,
  prefixIcon,
  value,
  onChange,
  defaultInputValue,
  ref,
  name,
  onBlur,
  isSearchable,
  isOptionDisabled,
  isDisabled,
  className
}) => {
  const SelectControl = ({ children, ...props }: ControlProps<MyOptionType, false>) => {
    const style = {
      marginLeft: '5px'
    }

    return (
      <components.Control {...props} className={className}>
        <span style={style}>{prefixIcon}</span>

        {children}
      </components.Control>
    )
  }

  return (
    <ReactSelect
      placeholder={placeholder}
      isClearable={isClearable}
      options={options}
      styles={styles || defaultSelectStyles}
      components={{ Control: SelectControl, IndicatorSeparator: IndicatorSeparator }}
      value={value}
      onChange={onChange}
      defaultInputValue={defaultInputValue}
      ref={ref}
      name={name}
      onBlur={onBlur}
      isSearchable={isSearchable}
      isOptionDisabled={isOptionDisabled}
      isDisabled={isDisabled}
    />
  )
}

export default DefaultSelect
