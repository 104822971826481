import React from 'react'

interface IProps {
    leaveType: string
    leavesCount: number
    maxLeaves?: number | undefined
}

const MobileLeaveItem: React.FC<IProps> = ({ leaveType,  leavesCount,  maxLeaves }) => {
  return (
    <span className="mobile_leaves__item">{leaveType}:
      <span className="mobile_leaves__item-counter">
        {leavesCount}{maxLeaves && `/${maxLeaves}`}
      </span>
    </span>
  )
}

export default MobileLeaveItem
