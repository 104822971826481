import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import { getUsersProfile } from '../../services/API/admin/profile'
import { getProfile, getProfileFailure, getProfileSuccess } from '../../store/profileSlice'

export const useGetUsersProfile = () => {
  const dispatch = useDispatch()

  return (userId: number) => {
    dispatch(getProfile())
    getUsersProfile(userId)
      .then((response) => {
        dispatch(getProfileSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getProfileFailure(error))
        dangerNotify('Server error: unable to download profile')
      })
  }
}
