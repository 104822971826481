import { useDispatch } from 'react-redux'

//import { useGetCategories } from './useGetCategories'
import { deleteCategory } from '../../services/API/admin/categories'
import { removeCategory, removeCategoryFailure, removeCategorySuccess } from '../../store/categoriesSlice'
import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'

export const useDeleteCategory = () => {
  //const getCategoryHook = useGetCategories()
  const dispatch = useDispatch()

  return (id: number) => {
    dispatch(removeCategory())
    deleteCategory(id)
      .then(() => {
        dispatch(removeCategorySuccess(id))
        successNotify('Category deleted')
        //getCategoryHook()
      })
      .catch((error) => {
        dispatch(removeCategoryFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
