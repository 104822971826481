import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { tableCellStyles } from '../../../utilts/styles'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { renderUserName } from '../../../helpers/renderName'
import { User, UserProfile } from '../../../types'
import { dateFormatDDMMYY } from '../../../helpers/displayingDateFormat'
import { BENCH_MANAGER } from '../../../constants'

type Props = {
    profiles: UserProfile[]
    isHired: boolean
}

const FiredOrHiredDevsTableRow: React.FC<Props> = ({ profiles, isHired }) => {
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)

  return (
    <>
      {profiles?.map(profile => {
        const managersWithoutBenchManagerId = profile.manager_ids?.filter(managerId =>
          renderUserName(managerId, usersNames) !== BENCH_MANAGER)
        const benchManagerId = profile.manager_ids
        const minLengthWithoutBenchManagerId = profile.manager_ids?.length as number >= 2
        const managers = minLengthWithoutBenchManagerId ? managersWithoutBenchManagerId : benchManagerId

        return (
          <TableRow key={profile.user_id}>
            <TableCell align="left" key={profile.user_id} sx={tableCellStyles} style={{ width: '23%' }}>
              <Link to={`/users/${profile.user_id}`}>
                {renderUserName(profile.user_id as number, usersNames)}
              </Link>
            </TableCell>
            {isHired ?
              <TableCell align="left" sx={tableCellStyles} style={{ width: '23%' }}>
                {dateFormatDDMMYY(profile.job_started_on as string)}
              </TableCell> :
              <TableCell align="left" sx={tableCellStyles} style={{ width: '23%' }}>
                {dateFormatDDMMYY(profile.job_ended_on as string)}
              </TableCell>
            }
            <TableCell align="left" sx={tableCellStyles} style={{ width: '26%' }}>
              {profile.home_address}
            </TableCell>
            <TableCell align="left" sx={tableCellStyles} style={{ width: '23%' }}>
              {managers?.map(managerId => (
                <div key={managerId}>
                  <div>
                    {renderUserName(managerId, usersNames)}
                  </div>
                </div>
              ))}
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}

export default FiredOrHiredDevsTableRow
