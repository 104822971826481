import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import FullCalendar, { CalendarApi } from '@fullcalendar/react' // must go before plugins
import { useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect'
import holidays from 'date-holidays'

import { WeekDay } from '../../../constants'
import dangerNotify from 'helpers/dangerNotify'
import { getAdminUserRecords, getRecords } from '../../../services/API/record'
import { renderProjectCalendar } from 'helpers/renderName'
import { useGetUser } from 'hooks/user/useGetUser'
import { useAuth } from 'context/auth'
import { Contract, Project, Record, User } from '../../../types'
import { isDefault } from '../../../helpers/accessHelpers'
import { dateFormatMM,dateFormatYYMMDD,dateFormatYYYY } from 'helpers/displayingDateFormat'
import { getUserAvailableContracts } from 'services/API/admin/contracts'
import { IOptions } from 'types/ISelectOptions'
import { useGetCategories } from 'hooks/category/useGetCategories'
import { Modify } from 'helpers/ModifyInterface'
import DesktopCalendar from './DesktopCalendar'
import MobileCalendar from './MobileCalendar'
import { webRoutes } from 'lib'

export type IOmitedRecord = Modify<
  Record,
  {
    title?: string
    type?: string
  }
>

function MonthlyCalendarReport() {
  const hd = new holidays('UA')
  const holiday = hd.getHolidays()
  const dispatch = useDispatch()
  const { currentUser } = useAuth()
  const isDefaultUser = isDefault(currentUser)
  const user = localStorage.getItem('user_monthly_report')
  const userFullName = localStorage.getItem('user_full_name_monthly_report')
  const projectId = localStorage.getItem('project_monthly_report')
  const projectName = localStorage.getItem('project_name_monthly_report')
  const location = useLocation()
  const defaultDate = (location.state ? location.state : localStorage.getItem('monthly_report_date')) as Date
  const [date, setDate] = useState<Date>(defaultDate)
  const convertedDate = new Date(localStorage.getItem('monthly_report_date'))
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)

  const [selectedUser, setSelectedUser] = useState<IOptions>({ value: Number(user), label: userFullName })
  const [selectedProject, setSelectedProject] = useState<IOptions>({ value: Number(user), label: projectName })
  const [availableContracts, setAvailableContracts] = useState<Contract[]>([])
  const [calendarAPI, setCalendarAPI] = useState<CalendarApi>()
  const [allRecordNHolidays, setAllRecord] = useState<IOmitedRecord[]>()

  const calendarRef = useRef<FullCalendar>()
  const getUserHook = useGetUser()
  const navigate = useNavigate()
  const getCategoryHook = useGetCategories()
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)

  const arrowNextItem = () => {
    if (setDate) {
      setDate(new Date(new Date(date).setMonth(new Date(date).getMonth() + WeekDay.OneDay)))
      calendarAPI?.next()
    }
  }
  const arrowBackItem = () => {
    if (setDate) {
      setDate(new Date(new Date(date).setMonth(new Date(date).getMonth() - WeekDay.OneDay)))
      calendarAPI?.prev()
    }
  }

  const usersList: IOptions[] = usersNames
    ?.filter((user) => user.active)
    .map((user) => ({
      label: user.full_name,
      value: user.id,
    }))

  const availableContractsOptions: IOptions[] = availableContracts?.map((contract) => ({
    label: renderProjectCalendar(contract.project_id as number, projects),
    value: contract.project_id,
  })) as IOptions[]

  useEffect(() => {
    const calendarAPI = calendarRef?.current?.getApi()
    setCalendarAPI(calendarAPI)
    if (location.pathname.startsWith(webRoutes.MONTHLY_REPORT)) {
      calendarAPI?.gotoDate(convertedDate)
    }
  })

  useEffect(() => {
    getCategoryHook()
    if (location.pathname.startsWith(webRoutes.MONTHLY_REPORT)) {
      getUserHook(Number(user))
      setAvailableContracts([])
      getUserAvailableContracts(Number(user)).then((response) => {
        setAvailableContracts(response.data)
      })

      if (!projectId && !projectName) setSelectedProject({ value: 0, label: 'Available Projects' })
    }
  }, [])

  useEffect(() => {
    if (location.pathname.startsWith(webRoutes.MONTHLY_REPORT)) {
      getAdminUserRecords(Number(user), dateFormatYYYY(date), dateFormatMM(date), projectId)
        .then((response) => {
          const holidayItem = holiday?.map((item) => {
            return {
              title: item.name,
              date: dateFormatYYMMDD(item.date),
            }
          })

          const recordsHoliday = [...response.data, ...holidayItem] as IOmitedRecord[]
          setAllRecord(recordsHoliday)
        })
        .catch(() => dangerNotify('Server error: unable to download Records'))
      localStorage.setItem('monthly_report_date', String(date))
      navigate(webRoutes.ADMIN_MONTHLY_CALENDAR(user), { replace: true })
    }
  }, [date, selectedUser, selectedProject])

  useEffect(() => {
    if (isDefaultUser) {
      getRecords(undefined, dateFormatYYYY(date), dateFormatMM(date)).then((response) => {
        const holidayItem = holiday?.map((item) => {
          return {
            title: item.name,
            date: dateFormatYYMMDD(item.date),
          }
        })
        const recordsHoliday = [...response.data.paginate_records, ...holidayItem]
        setAllRecord(recordsHoliday)
      })
      calendarAPI?.gotoDate(new Date(date))
    }
  }, [currentUser, date, calendarAPI])

  const onChangeProject = (project: IOptions) => {
    if (project.label === 'Available Projects') {
      setSelectedProject(project)
      localStorage.setItem('project_monthly_report', '')
      localStorage.setItem('project_name_monthly_report', '')
    } else {
      setSelectedProject(project)
      localStorage.setItem('project_monthly_report', project?.value)
      localStorage.setItem('project_name_monthly_report', project?.label)
    }
  }

  const onChangeUser = (user: IOptions) => {
    setSelectedUser(user)
    getUserHook(user?.value)
    getUserAvailableContracts(user.value).then((response) => {
      setAvailableContracts(response.data)
    })
    setSelectedProject({ value: 0, label: 'Available Projects' })
    localStorage.setItem('project_monthly_report', '')
    localStorage.setItem('project_name_monthly_report', '')
    localStorage.setItem('user_monthly_report', user?.value)
    localStorage.setItem('user_full_name_monthly_report', user?.label)
  }

  return !isMobile ? (
    <div className="container calendar_container">
      <DesktopCalendar
        allRecordNHolidays={allRecordNHolidays}
        calendarRef={calendarRef}
        convertedDate={convertedDate}
        projects={projects}
        selectedProject={selectedProject}
        onChangeProject={onChangeProject}
        onChangeUser={onChangeUser}
        availableContractsOptions={availableContractsOptions}
        date={date}
        setDate={setDate}
        calendarAPI={calendarAPI}
        arrowNextItem={arrowNextItem}
        arrowBackItem={arrowBackItem}
        usersList={usersList}
        selectedUser={selectedUser}
      />
    </div>
  ) : (
    <MobileCalendar
      allRecordNHolidays={allRecordNHolidays}
      calendarRef={calendarRef}
      convertedDate={convertedDate}
      projects={projects}
      selectedProject={selectedProject}
      onChangeProject={onChangeProject}
      onChangeUser={onChangeUser}
      availableContractsOptions={availableContractsOptions}
      date={date}
      setDate={setDate}
      calendarAPI={calendarAPI}
      arrowNextItem={arrowNextItem}
      arrowBackItem={arrowBackItem}
      usersList={usersList}
      selectedUser={selectedUser}
    />
  )
}

export default MonthlyCalendarReport
