import React from 'react'
import { isMobile } from 'react-device-detect'

const CompanyIconGrey = () => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.8474 18.7083C18.7926 18.7641 18.7373 
        18.8192 18.6813 18.8738L18.8122 19.1542L20.318 22.381L16.779 21.9878L10.9998 21.3456L5.22069 
        21.9878L1.68164 22.381L3.18746 19.1542L3.31843 18.8736C3.26257 18.8191 3.20729 18.764 3.1526 
        18.7083C1.20271 16.7235 0 14.0022 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 
        14.0022 20.7973 16.7235 18.8474 18.7083ZM17.8988 16.7806L14.1337 9.12884L13.7258 8.25477C13.9018 
        7.87301 14 7.44797 14 7C14 5.34315 12.6569 4 11 4C9.34315 4 8 5.34315 8 7C8 7.44783 8.09812 
        7.87274 8.27405 8.25441L7.85977 9.14215L4.10119 16.7806C2.7883 15.2157 2 13.1998 2 11C2 6.02944 
        6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.1998 19.2117 15.2157 17.8988 16.7806ZM12 7C12 
        7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 
        12 7ZM9.66667 10L9.6634 10.0066L7.79983 14H14.1998L12.3332 10H11H9.66667ZM15.1332 16H6.8665L5.99026 
        17.8776L4.99983 20L7.32759 19.7414L10.9998 19.3333L14.6721 19.7414L16.9998 20L16.0094 
        17.8776L15.1332 16Z" fill={!isMobile ? 'white' : 'black'} />
    </svg>
  )
}

export default CompanyIconGrey
