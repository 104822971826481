import { webRoutes } from 'lib'

export const navbarCollapsedData = {
  submitHoursCollapse: [
    { title: 'For me', route: webRoutes.SUBMIT_HOURS },
    { title: 'For users', route: webRoutes.SUBMIT_HOURS_ADMIN }
  ],
  manageCollapse: [
    { title: 'Users', route: webRoutes.USERS },
    { title: 'Projects', route: webRoutes.PROJECTS },
    { title: 'Customers', route: webRoutes.CUSTOMERS },
    { title: 'Contracts', route: webRoutes.CONTRACTS },
    { title: 'Categories', route: webRoutes.CATEGORIES },
  ],
  dashboardCollapse: [
    { title: 'Users', route: webRoutes.DASHBOARD_USERS_LIST },
    { title: 'Leaves', route: webRoutes.DASHBOARD_LEAVES },
    { title: 'Managers', route: webRoutes.DASHBOARD_MANAGERS }
  ],
  reportCollapse: [
    { title: 'Monthly Report', route: webRoutes.MY_REPORT },
    { title: 'Monthly Calendar', route: webRoutes.USER_MONTHLY_REPORT }
  ],
}

export enum NavbarTitle {
    ADMIN_MAIN = 'Dashboard',
    REPORT = 'Report',
    RECRUITERS = 'Recruiters',
    SUBMIT_TIME = 'Submit hours',
    MANAGE = 'Manage',
    REQUESTS = 'Requests',
    NOTIFICATIONS = 'Notifications',
    NEW_PASSWORD = 'New password',
    LOGOUT = 'Logout'
}
