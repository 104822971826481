import React from 'react'

const AddCandidateIcon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="8" fill="#444A58"/>
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M31 22C31 21.4477 30.5523 21 30 21C29.4477 21 29 21.4477 29 22V29H22C21.4477 29 21 29.4477 21 30C21 30.5523 21.4477 31 22 31H29V38C29 38.5523 29.4477 39 30 39C30.5523 39 31 38.5523 31 38V31H38C38.5523 31 39 30.5523 39 30C39 29.4477 38.5523 29 38 29H31V22Z" fill="white"/>
    </svg>
  )
}

export default AddCandidateIcon
