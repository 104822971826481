import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import { editNotification } from '../../services/API/notifications'
import { Notification } from '../../types'
import {
  updateNotification,
  updateNotificationFailure,
  updateNotificationSuccess
} from '../../store/notificationsSlice'
import { useFetchNotifications } from './useFetchNotifications'

export const useUpdateNotification = () => {
  const dispatch = useDispatch()
  const useFetchNotificationsHook = useFetchNotifications()

  return (notification: Notification) => {
    dispatch(updateNotification())
    editNotification(notification)
      .then((response) => {
        dispatch(updateNotificationSuccess(response.data))
        useFetchNotificationsHook()
      })
      .catch((error) => {
        dispatch(updateNotificationFailure(error))
        dangerNotify('Server error: unable to download notifications')
      })
  }
}
