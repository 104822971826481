import { useDispatch } from 'react-redux'

import { deleteVacancy } from '../../services/API/vacancies'
import { removeVacancy, removeVacancyFailure } from '../../store/vacanciesSlice'
import dangerNotify from '../../helpers/dangerNotify'
import { useFetchVacancies } from 'hooks/vacancies/useFetchVacancies'
import successNotify from 'helpers/successNotify'
import { wasLastOnPage } from 'helpers/paginationPage'
import { PaginateLimit, RequestLimits, VacancyLimits } from 'enums/RequestEnums'

export const useDeleteVacancy = () => {
  const dispatch = useDispatch()
  const fetchVacancies = useFetchVacancies()

  return (id: number, page: number, count: number) => {
    const lastOnPage = wasLastOnPage(count, VacancyLimits.VACANCY_LIMIT, page) ?
      page - PaginateLimit.CURRENT_ITEM : page

    dispatch(removeVacancy())
    deleteVacancy(id)
      .then(() => {
        fetchVacancies(lastOnPage)
        successNotify('Vacancy deleted')
      })
      .catch((error) => {
        dispatch(removeVacancyFailure(error))
        dangerNotify('Server error: unable to remove Vacancy')
      })
  }
}
