import axios from 'axios'

import ROUTES from '../../routes'
import { webRoutes } from 'lib'
import { responseCode } from '../../constants'

const isCvPage = window.location.pathname.match(/^(\/cv\/.*)/)

const API = axios.create({
  withCredentials: true,
  baseURL: ROUTES.API
})

API.interceptors.response.use(response => {
  return response
}, err => {
  const status = err.response ? err.response.status : null

  if(status === responseCode.unauthorized && window.location.pathname !== webRoutes.HOME && !isCvPage) {

    return (
      window.location.pathname = webRoutes.HOME
    )
  }

  return Promise.reject(err)
})

export default API
