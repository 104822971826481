import { StatusType } from 'enums/Types'
import { dateFormatYYMMDD } from 'helpers/displayingDateFormat'
import { renderProject, renderType } from 'helpers/renderName'
import { IOmitedRecord } from './MonthlyCalendarReport'
import calendarStyles from '../../../styles/_exports.module.scss'
import { paidLeave } from '../../../constants'
import { Project } from 'types'

export const eventColors = (day: Date, dates: { date: string, type: string }[]) => {
  if (dates?.some((d) => d.date === dateFormatYYMMDD(day) && d.type === StatusType.REGULAR)) {
    return 'rgba(55, 136, 216, 1)'
  } else if (dates?.some((d) => d.date === dateFormatYYMMDD(day) && d.type === StatusType.DAY_OFF)) {
    return 'rgba(67, 101, 177, 1)'
  } else if (dates?.some((d) => d.date === dateFormatYYMMDD(day) && d.type === StatusType.EXTRA_SICK_LEAVE)) {
    return 'rgba(255, 35, 0, 1)'
  } else if (dates?.some((d) => d.date === dateFormatYYMMDD(day) && 
      d.type === (StatusType.FULL_DAY_SICK_LEAVE || StatusType.HALF_DAY_SICK_LEAVE))) {
    return 'rgba(212, 135, 43, 1)'
  } else if (dates?.some((d) => d.date === dateFormatYYMMDD(day) && 
      d.type === (StatusType.HALF_DAY_UNPAID_LEAVE || StatusType.UNPAID_LEAVE))) {
    return 'rgba(222, 90, 90, 1)'
  } else if (dates?.some((d) => d.date === dateFormatYYMMDD(day) && d.type === StatusType.PAID_LEAVE)) {
    return 'rgba(107, 142, 35, 1)'
  } else if (dates?.some((d) => d.date === dateFormatYYMMDD(day) && d.type === StatusType.VACATION)) {
    return 'rgba(66, 158, 66, 1)'
  } else if (dates?.some((d) => d.date === dateFormatYYMMDD(day) && d.type === StatusType.WORK_OFF)) {
    return 'rgba(95, 95, 95, 1)'
  } else if (dates?.some((d) => d.date === dateFormatYYMMDD(day) && !d.type)) {
    return 'rgba(66, 158, 66, 1)'
  }
}

export const mobileEventColors = (type: string, elementType: 'background' | 'line') => {
  if (type === StatusType.REGULAR) {
    return elementType === 'line' ? 'rgba(55, 136, 216, 1)' : 'rgba(55, 136, 216, 0.1)'
  } else if (type === StatusType.DAY_OFF) {
    return elementType === 'line' ? 'rgba(67, 101, 177, 1)' : 'rgba(67, 101, 177, 0.1)'
  } else if (type === StatusType.EXTRA_SICK_LEAVE) {
    return elementType === 'line' ? 'rgba(255, 35, 0, 1)' : 'rgba(255, 35, 0, 0.1)'
  } else if (type === (StatusType.FULL_DAY_SICK_LEAVE || StatusType.HALF_DAY_SICK_LEAVE)) {
    return elementType === 'line' ? 'rgba(212, 135, 43, 1)' : 'rgba(212, 135, 43, 0.1)'
  } else if (type === (StatusType.HALF_DAY_UNPAID_LEAVE || StatusType.UNPAID_LEAVE)) {
    return elementType === 'line' ? 'rgba(222, 90, 90, 1)' : 'rgba(222, 90, 90, 0.1)'
  } else if (type === StatusType.PAID_LEAVE) {
    return elementType === 'line' ? 'rgba(107, 142, 35, 1)' : 'rgba(107, 142, 35, 0.1)'
  } else if (type === StatusType.VACATION) {
    return elementType === 'line' ? 'rgba(66, 158, 66, 1)' : 'rgba(66, 158, 66, 0.1)'
  } else if (type === StatusType.WORK_OFF) {
    return elementType === 'line' ? 'rgba(95, 95, 95, 1)' : 'rgba(95, 95, 95, 0.1)'
  } else {
    return elementType === 'line' ? 'rgba(66, 158, 66, 1)' : 'rgba(66, 158, 66, 0.1)'
  }
}

export const destopCalendarEvents = (record: IOmitedRecord, projects: Project[]) => {
  if (record['title']) {
    return {
      title: record.title,
      date: record.date,
      backgroundColor: 'rgba(66, 158, 66, 0.1)',
      textColor: calendarStyles['calendar-holiday-text-color'],
    }
  }
  if (record.record_type === StatusType.REGULAR) {
    return {
      title: `${
        renderProject(record.project_id, projects) ? renderProject(record.project_id, projects) : 'Loading...'
      }, ${record.hours}`,
      date: record.date,
      backgroundColor: 'rgba(55, 136, 216, 0.1)',
      textColor: calendarStyles['calendar-regular-text-color'],
    }
  }
  if (record.record_type === StatusType.WORK_OFF) {
    return {
      title: 'Work Off',
      date: record.date,
      backgroundColor: 'rgba(95, 95, 95, 0.1)',
      textColor: calendarStyles['calendar-workoff-text-color'],
    }
  } else if (record.record_type === StatusType.UNPAID_LEAVE) {
    return {
      title: 'Unpaid Leave',
      date: record.date,
      backgroundColor: 'rgba(222, 90, 90, 0.1)',
      textColor: calendarStyles['calendar-unpaid-text-color'],
    }
  } else if (record.record_type === StatusType.HALF_DAY_UNPAID_LEAVE) {
    return {
      title: 'Half day unpaid leave',
      date: record.date,
      backgroundColor: 'rgba(222, 90, 90, 0.1)',
      textColor: calendarStyles['calendar-unpaid-text-color'],
    }
  } else if (record.record_type === StatusType.PAID_LEAVE) {
    return {
      title: `Paid Leave, ${renderType(record.paid_leave_type, paidLeave)}`,
      date: record.date,
      backgroundColor: 'rgba(107, 142, 35, 0.1)',
      textColor: calendarStyles['calendar-paid-text-color'],
    }
  } else if (record.record_type === StatusType.VACATION) {
    return {
      title: 'Vacation',
      date: record.date,
      backgroundColor: 'rgba(66, 158, 66, 0.1)',
      textColor: calendarStyles['calendar-vacation-text-color'],
    }
  } else if (record.record_type === StatusType.EXTRA_SICK_LEAVE) {
    return {
      title: 'Extra sick leave',
      date: record.date,
      backgroundColor: 'rgba(255, 35, 0, 0.1)',
      textColor: calendarStyles['calendar-extra-text-color'],
    }
  } else if (record.record_type === StatusType.FULL_DAY_SICK_LEAVE) {
    return {
      title: 'Full day sick leave',
      date: record.date,
      backgroundColor: 'rgba(212, 135, 43, 0.1)',
      textColor: calendarStyles['calendar-sick-text-color'],
    }
  } else if (record.record_type === StatusType.HALF_DAY_SICK_LEAVE) {
    return {
      title: 'Half day sick leave',
      date: record.date,
      backgroundColor: 'rgba(212, 135, 43, 0.1)',
      textColor: calendarStyles['calendar-sick-text-color'],
    }
  } else if (record.record_type === StatusType.DAY_OFF) {
    return {
      title: 'Day off',
      date: record.date,
      backgroundColor: 'rgba(67, 101, 177, 0.1)',
      textColor: calendarStyles['calendar-dayoff-text-color'],
    }
  } else {
    return {
      title: `${
        renderProject(record.project_id, projects) ? renderProject(record.project_id, projects) : 'Loading...'
      }, ${record.hours}`,
      date: record.date,
    }
  }
}
