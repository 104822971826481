import { createSlice } from '@reduxjs/toolkit'

import { Record } from '../types'

const initialState = {
  indexData: <Record[]>[],
  adminIndex: <Record[]>[],
  showData: <Record>{},
  pages: 1,
  count: 0,
  hours_sum: 0,
  error: {},
  isLoading: false,
}

const recordsSlice = createSlice({
  name: 'records',
  initialState,
  reducers: {
    fetchRecords(state) {
      state.isLoading = true
    },
    fetchRecordsSuccess(state, action) {
      state.adminIndex = action.payload.paginate_records
      state.indexData = action.payload.paginate_records
      state.pages = action.payload.pages
      state.count = action.payload.count
      state.hours_sum = action.payload.hours_sum
      state.isLoading = false
    },
    fetchRecordsFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    fetchAdminRecords(state) {
      state.isLoading = true
    },
    fetchAdminRecordsSuccess(state, action) {
      state.adminIndex = action.payload
      state.pages = action.payload.pages
      state.count = action.payload.count
      state.hours_sum = action.payload.hours_sum
      state.isLoading = false
    },
    fetchAdminRecordsFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    addRecord(state) {
      state.isLoading = true
    },
    addRecordSuccess(state, action) {
      state.adminIndex.push(action.payload)
      state.isLoading = false
    },
    addRecordFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default recordsSlice.reducer
export const { 
  fetchRecords, 
  fetchRecordsSuccess, 
  fetchRecordsFailure, 
  fetchAdminRecords, 
  fetchAdminRecordsSuccess, 
  fetchAdminRecordsFailure, 
  addRecord, 
  addRecordFailure, 
  addRecordSuccess } =
  recordsSlice.actions
