export const blackColor = '#000'
export const tableHeadStyles = {
  fontWeight: 500,
  fontSize: 14,
  verticalAlign: 'top',
  textAlign: 'left',
  color: '#747F96'
}
export const tableCellStyles = {
  fontSize: 18,
  fontWeight: 500,
  textAlign: 'left',
  color: '#667085'
}
