import { useDispatch } from 'react-redux'

import {
  fetchAllCustomers,
  fetchAllCustomersFailure,
  fetchAllCustomersSuccess,
} from '../../store/customersSlice'
import { fetchAllCustomersData } from '../../services/API/admin/customers'
import dangerNotify from '../../helpers/dangerNotify'

export const useFetchAllCustomers = () => {
  const dispatch = useDispatch()

  return () => [
    dispatch(fetchAllCustomers()),
    fetchAllCustomersData()
      .then((response) => {
        dispatch(fetchAllCustomersSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchAllCustomersFailure(error))
        dangerNotify('Server error: unable to download Customers')
      })
  ]
}
