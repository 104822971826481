export const notificationTimestamp = (date_notified: string) => {
  const date_sent_tmp = new Date(date_notified)

  let date_sent
  if (date_notified?.indexOf('-') != -1) {
    date_sent = date_sent_tmp.getTime()
  } else {
    date_sent = date_notified
  }
  const today = new Date().getTime()
  const calc = new Date(today - date_sent)
  calc.setHours( calc.getUTCHours())
  const calcDate = calc.getDate()+'-'+(calc.getMonth()+1)+'-'+calc.getFullYear()
  const calcTime = calc.getHours()+':'+calc.getMinutes()+':'+calc.getSeconds()

  const date_passed = calcDate.split('-')
  const time_passed = calcTime.split(':')

  let days_passed
  let months_passed
  let years_passed
  if (!(date_passed.includes('1-1-1970'))) {
    days_passed = ((parseInt(date_passed[0]) - 1) != 0) && parseInt(date_passed[0]) - 1
    months_passed = ((parseInt(date_passed[1]) - 1) != 0) && parseInt(date_passed[1]) - 1
    years_passed = ((parseInt(date_passed[2]) - 1970) != 0) && parseInt(date_passed[2]) - 1970
  } else {
    days_passed = null
    months_passed = null
    years_passed = null
  }

  const hours_passed = parseInt(time_passed[0])
  const mins_passed = parseInt(time_passed[1])
  const secs_passed = parseInt(time_passed[2])

  const seconds = ['sec']
  const minutes = ['min']
  const hours = ['h']
  const days = ['d']
  const months = ['mnth']

  let result
  if (!(days_passed) && !(months_passed) && !(years_passed) && !(hours_passed) && !(mins_passed)) {
    result =  secs_passed +' '+ seconds[0]
  }else if (!(days_passed) && !(months_passed) && !(years_passed) && !(hours_passed)) {
    result =  mins_passed +' '+ minutes[0]
  }else if (!(days_passed) && !(months_passed) && !(years_passed)){
    result = hours_passed +' '+ hours[0]
  }else if (!(years_passed) && !(months_passed)) {
    result = days_passed +' '+ days[0]
  }else{
    result = months_passed +' '+ months[0]
  }

  return(result)
}
