import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import AdminTableItem from '../AdminTableItem'
import { Project } from 'types/Project'
import { Category } from 'types/Category'
import { ReadyRecord } from 'types/ReadyRecord'
import { tableHeadStyles } from 'utilts/styles'
import { IOptions } from 'types/ISelectOptions'

interface Props {
  records: ReadyRecord[]
  categories: Category[]
  projects: Project[]
  userId: IOptions | undefined
  setUserId: React.Dispatch<React.SetStateAction<IOptions | undefined>>
}

export const AdminRecordsTable: React.FC<Props> = ({ records, categories, projects, userId, setUserId }) => {    
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ ...tableHeadStyles, width: '220px' }}>
              User
            </TableCell>
            <TableCell align="left" sx={{ ...tableHeadStyles, width: '250px' }}>
              Project
            </TableCell>
            <TableCell align="left" sx={{ ...tableHeadStyles, width: '210px' }}>
              Category
            </TableCell>
            <TableCell align="left" sx={{ ...tableHeadStyles }}>
              Description
            </TableCell>
            <TableCell align="left" sx={{ ...tableHeadStyles, width: '150px' }}>
              Date
            </TableCell>
            <TableCell align="left" sx={{ ...tableHeadStyles, width: '50px' }}>
              Hours
            </TableCell>
            <TableCell align="right" sx={{ ...tableHeadStyles, width: '150px', textAlign: 'center' }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((record) => (
            <AdminTableItem record={record} key={record.id} 
              projects={projects} 
              categories={categories} 
              userId={userId}
              setUserId={setUserId}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(AdminRecordsTable)
