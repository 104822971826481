import React from 'react'
import { IRequest } from '../types'

interface Props  {
    handleChangeLeave: (value: IRequest) => void
    request: IRequest
}

const ExtraSickLeave: React.FC<Props> = ({ handleChangeLeave, request }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => handleChangeLeave(request)}
      cursor="pointer"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.8 3C7.3292 3 6.87351 3.19065 6.53431 3.53677C6.19445
      3.88357 6 4.35815 6 4.85714V20.2152L11.4778 16.8614C11.7983 16.6652 12.2017 16.6652 12.5222 16.8614L18
      20.2152V4.85714C18 4.35815 17.8056 3.88357 17.4657 3.53677C17.1265 3.19065 16.6708 3 16.2 3H7.8ZM5.10589
      2.13691C5.8169 1.41139 6.78558 1 7.8 1H16.2C17.2144 1 18.1831 1.41139 18.8941 2.13691C19.6044 2.86174 20
      3.84061 20 4.85714V22C20 22.3619 19.8045 22.6955 19.4888 22.8724C19.1731 23.0493 18.7865 23.0418 18.4778
      22.8529L12 18.8868L5.52215 22.8529C5.21353 23.0418 4.8269 23.0493 4.5112 22.8724C4.1955 22.6955 4 22.3619
      4 22V4.85714C4 3.84061 4.39555 2.86174 5.10589 2.13691Z" fill="#8992A6"/>
    </svg>
  )
}

export default ExtraSickLeave
