import React, { memo } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'
import { Button, IconButton } from '@mui/material'

import { IRequest } from 'types'
import RequestDescription from './RequestDescription/RequestDescription'
import RequestTitle from './RequestTItle/RequestTitle'
import RequestStatusType from './RequestType/RequestStatusType'
import { ExtraLeaveOffIcon, ExtraLeaveOnIcon, FileIcon, TrashIcon } from 'icons'
import RequestDate from './RequestDate/RequestDate'

type Props = {
  request: IRequest
  isAdmin?: boolean
  onApprove?: () => void
  onDecline?: () => void
  onDelete?: (id: number) => void
  onExtra?: () => void
  onShowFile: (v: string) => void
}

export const RequestCard = memo(function RequestCard({
  request,
  isAdmin,
  onApprove,
  onDecline,
  onDelete,
  onExtra,
  onShowFile,
}: Props) {
  const {
    id,
    attached_file,
    description,
    end_date,
    managers,
    note,
    paid_leave_type,
    request_type,
    start_date,
    status,
    user_id,
  } = request
  return (
    <div className="requests__mobile-card">
      <div className="d-flex flex-column justify-content-between gap-3">
        <div>
          <RequestTitle titleUser={user_id} titleType={request_type} titlePaidLeave={paid_leave_type} />
          <RequestDescription note={note} description={description} managers={managers} />
        </div>
        <RequestDate start={start_date} end={end_date} />
      </div>

      <div className="d-flex flex-column justify-content-end align-items-end gap-2">
        <div className="d-flex gap-1">
          {attached_file && (
            <IconButton onClick={() => onShowFile(attached_file)}>
              <FileIcon />
            </IconButton>
          )}
          {isAdmin && ['full_day_sick_leave', 'extra_sick_leave'].includes(request_type) && (
            <IconButton onClick={onExtra} disabled={status !== 'pending'}>
              {request_type === 'extra_sick_leave' ? <ExtraLeaveOnIcon /> : <ExtraLeaveOffIcon />}
            </IconButton>
          )}
          {((isAdmin && status !== 'pending') || (!isAdmin && status === 'pending')) && onDelete && (
            <IconButton onClick={() => onDelete(id)}>
              <TrashIcon />
            </IconButton>
          )}
        </div>
        <RequestStatusType request={request} />
        {isAdmin ? (
          status === 'pending' ? (
            <div className="d-flex gap-2">
              <Button
                variant="outlined"
                disableElevation
                className="requests__action-button"
                color="error"
                onClick={onDecline}
              >
                <ClearIcon />
              </Button>
              <Button
                variant="contained"
                disableElevation
                className="requests__action-button"
                color="success"
                onClick={onApprove}
              >
                <CheckIcon />
              </Button>
            </div>
          ) : (
            <div className={`requests__${status}-text`}>{status}</div>
          )
        ) : (
          <div className={`requests-desktop-label requests__${status}`}>{status}</div>
        )}
      </div>
    </div>
  )
})
