import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './mobile_dashboard.scss'
import { User, UserProfile } from '../../../types'
import { renderUserName } from '../../../helpers/renderName'
import { dateFormatDDMMYY } from '../../../helpers/displayingDateFormat'

type IProps = {
    profiles: UserProfile[]
    isHired?: boolean
}

const MobileFiredOrHiredDevelopers: React.FC<IProps> = ({ profiles, isHired }) => {
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)

  return (
    <>
      {profiles?.map(profile => {
        const managers = profile.manager_ids

        return (
          <div className="mobile_dashboard__cardList" key={profile.user_id}>
            <div className="mobile_dashboard__card">
              <Link to={`/users/${profile.user_id}`} className="mobile_dashboard__card-userName">
                {renderUserName(profile.user_id as number, usersNames)}
              </Link>
              <div className="mobile_dashboard__card-data">
                <div style={{ width: '50%' }}>
                  {isHired ?
                    <div className="mobile_dashboard__card-inner">
                      Hire Date: {dateFormatDDMMYY(profile.job_started_on as string)}
                    </div> :
                    <div className="mobile_dashboard__card-inner">
                      Release Date: {dateFormatDDMMYY(profile.job_ended_on as string)}
                    </div>
                  }
                  <div className="mobile_dashboard__card-inner">
                    Location: {profile.home_address}
                  </div>
                </div>
                <div>
                  <div className="mobile_dashboard__card-manager">Manager:</div>
                  {managers?.map(managerId => (
                    <div key={managerId}>
                      <div className="mobile_dashboard__card-managerName">
                        {renderUserName(managerId, usersNames)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default MobileFiredOrHiredDevelopers
