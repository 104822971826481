import { createSlice } from '@reduxjs/toolkit'

import { Category } from '../types'

const initialState = {
  indexData: <Category[]>[],
  error: {},
  isLoading: true,
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    fetchCategories(state) {
      state.isLoading = true
    },
    fetchCategoriesSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchCategoriesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    addCategory(state) {
      state.isLoading = true
    },
    addCategorySuccess(state, action) {
      state.indexData.push(action.payload)
      state.isLoading = false
    },
    addCategoryFailure(state, action) {
      state.error = action.payload
    },

    removeCategory(state) {
      state.isLoading = true
    },
    removeCategorySuccess(state, action) {
      state.indexData = state.indexData.filter((category: Category) => category.id !== action.payload)
      state.isLoading = false
    },
    removeCategoryFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default categoriesSlice.reducer
export const {
  fetchCategories,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  addCategory,
  addCategoryFailure,
  addCategorySuccess,
  removeCategory,
  removeCategoryFailure,
  removeCategorySuccess,
} = categoriesSlice.actions
