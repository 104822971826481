import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useBreadcrumbs from 'use-react-router-breadcrumbs'

import { home } from '../../images'
import { User, Project, Vacancy, Candidate } from 'types'
import { useAuth } from '../../context/auth'
import { disableBreadcrumbs } from '../../helpers/disabledBreadcrumbs'

const homeBreadcrumb = () => (
  <img className="breadcrumb-home-icon" src={home} alt="" />
)

const dynamicUserBreadcrumb = ({ match }) => {
  if(match.params.userId) {
    const user = useSelector((state: { users: { showData: User } }) => state.users.showData)

    return (
      <span>{user?.full_name}</span>
    )
  } else {
    return (
      <span>User</span>
    )
  }
}

const dynamicProjectBreadcrumb = ({ match }) => {
  if(match.params.projectId) {
    const project = useSelector((state: { projects: { showData: Project } }) => state.projects.showData)

    return (
      <span>{project?.name}</span>
    )
  } else {
    return (
      <span>Project</span>
    )
  }
}

const manageUsersBreadcrumb = () => (
  <Link to="/manage-users" className="breadcrumb-nav-link">Manage users</Link>
)

const manageProjectsBreadcrumb = () => (
  <Link to="/manage-projects" className="breadcrumb-nav-link">Manage projects</Link>
)

const vacanciesBreadcrumb = () => (
  <Link to="/vacancies" className="breadcrumb-nav-link">Recruiters</Link>
)

const dynamicVacanciesBreadcrumb = ({ match }) => {
  if(match.params.vacancyId) {
    const vacancy = useSelector((state: { vacancies: { showData: Vacancy } }) => state.vacancies.showData)

    return (
      <span>{vacancy?.title}</span>
    )
  } else {
    return (
      <span>Vacancy</span>
    )
  }
}

const candidatesBreadcrumb = () => {
  const candidate = useSelector((state: { candidates: { showData: Candidate } }) => state.candidates.showData)

  return (
    <Link to={`/vacancies/${candidate?.vacancy_id}`} className="breadcrumb-nav-link">Candidates</Link>
  )
}

const dynamicCandidatesBreadcrumb = ({ match }) => {
  if(match.params.candidateId) {
    const candidate = useSelector((state: { candidates: { showData: Candidate } }) => state.candidates.showData)
    const isLoading = useSelector((state: { candidates: { isLoading: boolean } }) => state.candidates.isLoading)

    return (
      isLoading ? null : <span>{candidate?.full_name}</span>
    )
  } else {
    return (
      <span>Candidate</span>
    )
  }
}

const monthlyReportBreadcrumb = () => (
  <>
    <Link to="/my-report" className="breadcrumb-nav-link calendar">My report</Link>
    <span className="breadcrumb-main-span"> &gt; </span>
    <Link to="/monthly-report" className="breadcrumb-nav-link current">Monthly report</Link>
  </>
)

const routes = [
  { path: '/', breadcrumb: homeBreadcrumb },
  { path: '/users', breadcrumb: manageUsersBreadcrumb },
  { path: '/projects', breadcrumb: manageProjectsBreadcrumb },
  { path: '/users/:userId', breadcrumb: dynamicUserBreadcrumb },
  { path: '/admin-monthly-report/:userId', breadcrumb: dynamicUserBreadcrumb },
  { path: '/projects/:projectId', breadcrumb: dynamicProjectBreadcrumb },
  { path: '/vacancies', breadcrumb: vacanciesBreadcrumb },
  { path: '/vacancies/:vacancyId', breadcrumb: dynamicVacanciesBreadcrumb },
  { path: '/candidates', breadcrumb: candidatesBreadcrumb },
  { path: '/candidates/:candidateId', breadcrumb: dynamicCandidatesBreadcrumb },
  { path: '/monthly-report', breadcrumb: monthlyReportBreadcrumb }
]

const Breadcrumbs: React.FC<Props> = () => {
  const { currentUser } = useAuth()
  const location = useLocation()
  const breadcrumbs = useBreadcrumbs(routes)
  const currentUrl = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
  const currentBreadcrumb = (match: {pathname: string}) => {
    return match.pathname.substring(match.pathname.lastIndexOf('/') + 1)
  }

  return (
    !disableBreadcrumbs(location.pathname) && currentUser ?
      <div className="breadcrumb-main-container container-sm">
        {breadcrumbs.map(({
          match,
          breadcrumb
        }) => (
          <div key={match.pathname} >
            <span className="breadcrumb-main-span">
              <NavLink
                to={match.pathname}
                className={`breadcrumb-nav-link ${currentBreadcrumb(match) === currentUrl && 'current'}`}>
                {breadcrumb}
              </NavLink>
            </span>
            <span className="breadcrumb-main-span">
              {currentBreadcrumb(match) !== currentUrl && '>'}
            </span>
          </div>
        ))}
      </div> : <></>
  )
}

export default React.memo(Breadcrumbs)
