import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { updateCvEducation } from '../../../services/API/admin/resume'
import { IResumeEducation } from '../../../types/Resume/IResumeEducation'
import { useGetUserCv } from '../useGetUserCv'

export const useUpdateCvEducation = () => {
  const getUserCv = useGetUserCv()

  return (education: IResumeEducation, educationId: number, cvId: number ) => {
    updateCvEducation(education, educationId)
      .then(() => {
        successNotify('Education updated')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Update error'))
  }
}
