import React from 'react'
import { isMobile } from 'react-device-detect'
import Box from '@mui/material/Box'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import ReactInput from '../../reusable/ReactInput'
import { Customer } from '../../../types/Customer'
import { createCustomerSchema } from '../../../helpers/validationShema'
import { useCreateCustomer } from '../../../hooks/customer/useCreateCustomer'
import CustomButton from 'components/custom/CustomButton/CustomButton'

type Props = {
  closeModal: () => void
}

interface Inputs {
  company_name: string
  full_legal_name: string
  title: string
  company_address: string
  email: string
}

const CreateCustomer: React.FC<Props> = ({ closeModal }) => {
  const resolver = yupResolver(createCustomerSchema)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Inputs>({ resolver })

  const createCustomer = useCreateCustomer()

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const newCustomer: Customer = {
      company_name: data.company_name,
      full_legal_name: data.full_legal_name,
      title: data.title,
      company_address: data.company_address,
      email: data.email,
    }
    createCustomer(newCustomer)
    closeModal()
  }

  return (
    <>
      {!isMobile && <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ReactInput
            className="modal__input"
            type="text"
            size="medium"
            label="Company name"
            registerControl="company_name"
            register={register}
            error={!!errors.company_name}
            helperText={errors.company_name?.message}
          />
          <ReactInput
            className="modal__input"
            type="text"
            label="Representative"
            size="medium"
            registerControl="full_legal_name"
            register={register}
            error={!!errors.full_legal_name}
            helperText={errors.full_legal_name?.message}
          />
          <ReactInput
            className="modal__input"
            type="text"
            label="Title"
            size="medium"
            registerControl="title"
            register={register}
            error={!!errors.title}
            helperText={errors.title?.message}
          />
          <ReactInput
            className="modal__input"
            type="text"
            label="Email"
            size="medium"
            registerControl="email"
            register={register}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <ReactInput
            className="modal__input"
            type="text"
            size="medium"
            label="Company address"
            registerControl="company_address"
            register={register}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: '1rem', marginTop: '40px' }}>
          <CustomButton variant="outlined" onClick={() => closeModal()} text="Cancel" />
          <CustomButton type="submit" text="Create" />
        </Box>
      </form>}

      {isMobile && <form onSubmit={handleSubmit(onSubmit)}>
        <div className="filters-modal-wrapper">
          <div className="filters-modal-navbar">
            <div onClick={() => closeModal()} className="filters-modal-back-arrow">
              <ArrowBackIcon onClick={() => closeModal()} />
            </div>
            <p className="filters-modal-navbar-text">Create Customer</p>
          </div>
          <div className="filters-modal-body">
            <div className="customers-input-modal-wrapper">
              <div>
                <ReactInput
                  className="modal__input"
                  type="text"
                  size="medium"
                  label="Company name"
                  registerControl="company_name"
                  register={register}
                  error={!!errors.company_name}
                  helperText={errors.company_name?.message}
                />
              </div>
              <div className="customers-create-row">
                <ReactInput
                  className="modal__input"
                  type="text"
                  label="Representative"
                  size="medium"
                  registerControl="full_legal_name"
                  register={register}
                  error={!!errors.full_legal_name}
                  helperText={errors.full_legal_name?.message}
                />
              </div>
              <div className="customers-create-row">
                <ReactInput
                  className="modal__input"
                  type="text"
                  label="Title"
                  size="medium"
                  registerControl="title"
                  register={register}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                />
              </div>
              <div className="customers-create-row">
                <ReactInput
                  className="modal__input"
                  type="text"
                  label="Email"
                  size="medium"
                  registerControl="email"
                  register={register}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              </div>
              <div className="customers-create-row">
                <ReactInput
                  className="modal__input"
                  type="text"
                  size="medium"
                  label="Company address"
                  registerControl="company_address"
                  register={register}
                />
              </div>
            </div>
          </div>
          <div className="customers-button-submit">
            <CustomButton type="submit" text="Create" />
          </div>
        </div>
      </form>}
    </>
  )
}

export default React.memo(CreateCustomer)
