import React, { memo } from 'react'
import { SVGProps } from 'react'

const UploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={40} height={40} rx={20} fill="#E0EAFF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      /* eslint-disable-next-line max-len */
      d="M21 23.214a1 1 0 0 1-2 0V15.63l-1.626 1.626a1 1 0 0 1-1.414-1.414l3.333-3.334a1 1 0 0 1 1.414 0l3.334 3.334a1 1 0 0 1-1.415 1.414L21 15.629v7.585Zm-8.816.004a1 1 0 0 1 1.265.633l.377 1.132a1 1 0 0 0 .949.684h10.45a1 1 0 0 0 .949-.684l.377-1.133a1 1 0 0 1 1.898.633l-.378 1.132a3 3 0 0 1-2.846 2.052h-10.45a3 3 0 0 1-2.846-2.052l-.378-1.132a1 1 0 0 1 .633-1.265Z"
      fill="#6172F3"
    />
  </svg>
)

export default memo(UploadIcon)
