import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { deleteResumeCertificate } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'

export const useDeleteResumeCertificate = () => {
  const getUserCv = useGetUserCv()
    
  return (id: number, cvId: number) => {
    deleteResumeCertificate(id)
      .then(() => {
        successNotify('Certificate deleted')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Something went wrong!'))
  }
}
