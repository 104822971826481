import { useDispatch } from 'react-redux'

import { getCandidates } from '../../services/API/candidates'
import { fetchCandidates, fetchCandidatesSuccess, fetchCandidatesFailure } from '../../store/candidatesSlice'
import dangerNotify from '../../helpers/dangerNotify'

export const useFetchCandidates = () => {
  const dispatch = useDispatch()

  return (vacancy_id: number, filter: string, page: number) => {
    dispatch(fetchCandidates())
    getCandidates(vacancy_id, filter, page)
      .then((response) => {
        dispatch(fetchCandidatesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchCandidatesFailure(error))
        dangerNotify('Server error: unable to download Candidates')
      })
  }
}
