import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { useUpdateCvLanguage } from '../../../../../hooks/resume/languages/useUpdateCvLanguage'
import { resumeLanguages, resumeLevels } from '../../../../../constants'
import { IResumeLanguage } from '../../../../../types/Resume/IResumeLanguage'
import CvLanguageForm from './CvLanguageForm'
import { ILanguageInputs } from './CvAddLanguage'
import EditFormButtons from '../FormButtons/EditFormButtons'

interface Props {
    language: IResumeLanguage
    setOpen: (value: boolean) => void
    cvId: number
}

const CvUpdateLanguage: React.FC<Props> = ({ language, setOpen, cvId }) => {
  const updateCvLanguage = useUpdateCvLanguage()
    
  const { handleSubmit, control } = useForm<ILanguageInputs>({
    defaultValues: {
      language: {
        label: language.name || '',
        value: language.name || ''
      },
      level: {
        label: language.level || '',
        value: language.level || ''
      }
    }
  })

  const onSubmit: SubmitHandler<ILanguageInputs> = (data) => {
    const updatedLanguage = {
      name: data?.language?.label,
      level: data?.level?.label,
      resume_id: cvId
    }

    updateCvLanguage(updatedLanguage as IResumeLanguage, language.id as number, cvId)
    setOpen(false)
  }

  const selectLanguage = resumeLanguages.map( language => (
    {
      label: language,
      value: language
    }))

  const languagesLevels = resumeLevels.map( level => (
    {
      label: level,
      value: level
    }
  ))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvLanguageForm languagesLevels={languagesLevels} selectLanguage={selectLanguage} control={control} />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <EditFormButtons setOpen={setOpen} />
      </Box>
    </form>
  )
}

export default CvUpdateLanguage
