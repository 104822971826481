import axios from 'axios'
import API from '..'
import ROUTES from '../../../routes'
import { Session } from '../../../types/Session'

export const createSession = async ({ email, password }: Session) => {
  return await axios.post(ROUTES.API + ROUTES.SIGN_IN, {
    session: {
      email: email,
      password: password,
    }
  })
}

export const deleteSession = async () => {
  return await axios.delete(ROUTES.API + ROUTES.SIGN_IN)
}

export const getCurrentUser = async () => {
  return await API.get(ROUTES.SIGN_IN)
}
