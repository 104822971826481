import React, { memo } from 'react'
import { Button } from '@mui/material'
import { BiLinkExternal } from 'react-icons/bi'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'

import { CopyIcon, EditIcon } from 'icons'
import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import { defaultAvatar } from 'images'
import IOSSwitch from 'components/reusable/IOSSwitch'
import successNotify from 'helpers/successNotify'
import { User, UserProfile } from 'types'
import Loader from 'components/reusable/Loader'
import { useUtility } from 'context/utility'
import { useUpdateUser } from 'hooks/user/useUpdateUser'

type Props = {
  onEditClick?: () => void
}

const UserProfileGeneral = ({ onEditClick }: Props) => {
  const { width } = useUtility()
  const updateUser = useUpdateUser()

  const [user, userLoading] = useSelector(({ users: { showData, isLoading } }): [User, boolean] => [
    showData,
    isLoading,
  ])
  const [profile, profileLoading] = useSelector(({ profile: { showData, isLoading } }): [UserProfile, boolean] => [
    showData,
    isLoading,
  ])

  if ((userLoading || profileLoading) && width < 1199.98) return <Loader />

  const onCopy = () => successNotify('Copied!')

  const handleStatusChange = () => {
    updateUser(user.id, { active: !user.active })
  }

  return (
    <div className="user-profile__main-info">
      <div className="user-profile__main-header">
        <div className="user-profile__main-header-info">
          <div className="d-flex justify-content-between">
            <img
              className="user-profile__avatar"
              src={profile.avatar || defaultAvatar}
              alt={`${user.full_name}'s Avatar`}
            />
            <Button className="user-profile__edit-button d-none d-xl-block" onClick={onEditClick}>
              <EditIcon />
            </Button>
            <div className="user-profile__active-switch d-block d-xl-none">
              <IOSSwitch checked={!!user.active} onChange={handleStatusChange} />
            </div>
          </div>
          <h2 className="user-profile__name">{user.full_name}</h2>
          <div className="user-profile__info-text">
            <span>Position:</span>
            {profile.position}
          </div>
          <div className="user-profile__info-text">
            <span>Stack:</span>
            {profile?.technologies?.map((t) => t.name).join(', ')}
          </div>
        </div>
      </div>

      <div className="user-profile__main-info-block">
        <div className="user-profile__info-text">
          <span>Email:</span>
          {user.email ? (
            <>
              <a target="_blank" href={`mailto:${user.email}`} rel="noreferrer">
                {user.email}
              </a>
              <CopyToClipboard text={user.email} onCopy={onCopy}>
                <button className="btn-copy">
                  <CopyIcon />
                </button>
              </CopyToClipboard>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="user-profile__info-text">
          <span>Skype:</span>
          {profile.skype ? (
            <>
              <a target="_blank" href={`tel:${profile.skype}`} rel="noreferrer">
                {profile.skype}
              </a>
              <CopyToClipboard text={profile.skype} onCopy={onCopy}>
                <button className="btn-copy">
                  <CopyIcon />
                </button>
              </CopyToClipboard>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="user-profile__info-text">
          <span>Github:</span>
          {profile.github ? (
            <>
              <a
                href={profile.github.startsWith('http') ? profile.github : `https://github.com/${profile.github}`}
                target="_blank"
                rel="noreferrer"
              >
                Link
                <BiLinkExternal />
              </a>
              <CopyToClipboard
                text={profile.github.startsWith('http') ? profile.github : `https://github.com/${profile.github}`}
                onCopy={onCopy}
              >
                <button className="btn-copy">
                  <CopyIcon />
                </button>
              </CopyToClipboard>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="user-profile__info-text">
          <span>Telegram:</span>
          {profile.telegram ? (
            <>
              <a
                href={profile.telegram.startsWith('http') ? profile.telegram : `https://t.me/${profile.telegram}`}
                target="_blank"
                rel="noreferrer"
              >
                {profile.telegram}
              </a>
              <CopyToClipboard
                text={profile.telegram.startsWith('http') ? profile.telegram : `https://t.me/${profile.telegram}`}
                onCopy={onCopy}
              >
                <button className="btn-copy">
                  <CopyIcon />
                </button>
              </CopyToClipboard>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="user-profile__info-text">
          <span>Phone:</span>
          {profile.phone_number ? (
            <>
              <a href={`tel:${profile.phone_number}`} target="_blank" rel="noreferrer">
                {profile.phone_number}
              </a>
              <CopyToClipboard text={String(profile.phone_number)} onCopy={onCopy}>
                <button className="btn-copy">
                  <CopyIcon />
                </button>
              </CopyToClipboard>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="user-profile__info-text">
          <span>Payment:</span>
          {profile.salary ? `$${profile.salary}` : ''}
        </div>
        <div className="user-profile__info-text">
          <span>English Level:</span>
          {profile.english_level || ''}
        </div>
        <div className="user-profile__info-text">
          <span>Date of admission:</span>
          {profile.job_started_on ? dateFormatDDMMYY(profile.job_started_on) : ''}
        </div>
        <div className="user-profile__info-text">
          <span>Date of dismission:</span>
          {profile.job_ended_on ? dateFormatDDMMYY(profile.job_ended_on) : ''}
        </div>
      </div>
    </div>
  )
}

export default memo(UserProfileGeneral)
