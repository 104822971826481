import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { useCreateResumeProject } from '../../../../../hooks/resume/portfolio/useCreateResumeProject'
import { MIN_INPUTS_ELEMENTS } from '../../../../../constants'
import { IResumeProject } from '../../../../../types/Resume/IResumeProject'
import CvPortfolioProjectForm from './CvPortfolioProjectForm'
import AddFormItemButton from '../FormButtons/AddFormItemButton'
import { IResume } from '../../../../../types/Resume/IResume'

export interface IPortfolioProjectInputs {
  title: string
  url: string
  description: string
  role: string
  team: string
  responsibilities: string
}

interface IProps {
  cvId: number
  cv: IResume
}

const CvAddPortfolioProject: React.FC<IProps> = ({ cvId, cv }) => {
  const [inputFields, setInputFields] = useState([{ value: '' }])
  const isDisabled = inputFields.length <= MIN_INPUTS_ELEMENTS
  const createResumeProjectHook = useCreateResumeProject()
  const cvProjectsPositions = cv.resume_projects?.map(p => p.position)
  const maxPosition = cvProjectsPositions && Math.max(...cvProjectsPositions)

  const { register, handleSubmit, reset } = useForm<IPortfolioProjectInputs>(
    {
      defaultValues: {
        title: '',
        url: '',
        description: '',
        role: '',
        team: '',
        responsibilities: ''
      }
    })

  const onSubmit: SubmitHandler<IPortfolioProjectInputs> = (data) => {
    const projectStack = inputFields.map(({ value }) => value)
    const resume_project = {
      title: data.title,
      url: data.url,
      description: data.description,
      role: data.role,
      resume_id: cvId,
      stack: projectStack,
      team: data.team,
      responsibilities: data.responsibilities,
      position: maxPosition && maxPosition + 1
    }
    createResumeProjectHook(resume_project as IResumeProject, cvId)
    reset()
    setInputFields(  [{ value: '' }])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvPortfolioProjectForm
        inputFields={inputFields}
        isDisabled={isDisabled}
        setInputFields={setInputFields}
        register={register}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <AddFormItemButton isDisabled={false}/>
      </Box>
    </form>
  )
}

export default CvAddPortfolioProject
