import React, { SyntheticEvent, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useSelector } from 'react-redux'

import UsersCv from '../UsersCv'
import CvNavButtons from '../CvNavButtons'
import CvAddEducation from './CvAddEducation'
import { useDeleteResumeEducation } from '../../../../../hooks/resume/education/useDeleteResumeEducation'
import CvUpdateEducation from './CvUpdateEducation'
import Modal from '../../../../reusable/Modal/index.tsx'
import { IResume } from '../../../../../types/Resume/IResume'
import { cvNavLinks } from '../../../../../constants'
import { IResumeEducation } from '../../../../../types/Resume/IResumeEducation'
import { dateFormatMMYY } from 'helpers/displayingDateFormat'
import { useGetUserCv } from '../../../../../hooks/resume/useGetUserCv'
import { useChangeCvEducationsSorting } from '../../../../../hooks/resume/education/useChangeCvEducationsSorting'

const CvEducations: React.FC = () => {
  const { resumeId } = useParams()
  const [open, setOpen] = useState(false)
  const [currentEducation, setCurrentEducation] = useState<IResumeEducation | null>(null)
  const [education, setEducation] = useState<IResumeEducation>()
  const resume = useSelector((state: { resumes: { showData: IResume } }) => state.resumes.showData)
  const getUserCv = useGetUserCv()
  const deleteEducation = useDeleteResumeEducation()
  const changeCvEducationsSorting = useChangeCvEducationsSorting()
  const nextPath = cvNavLinks.CERTIFICATES
  const prevPath = cvNavLinks.LANGUAGES

  useEffect(() => {
    if (resumeId) {
      getUserCv(+resumeId)
    }
  }, [resumeId])

  const onDeleteResumeEducation = (id: number | undefined) => {
    deleteEducation(id as number, Number(resumeId))
  }

  const onEditEducation = (education: IResumeEducation) => {
    setOpen(!open)
    setEducation(education)
  }

  const editEducation = (
    <CvUpdateEducation setOpen={setOpen} education={education as IResumeEducation} cvId={Number(resumeId)} />
  )

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, education: IResumeEducation) => {
    setCurrentEducation(education)
  }

  const dropHandler = (e: React.DragEvent<HTMLDivElement>, education: IResumeEducation) => {
    e.preventDefault()
    if (currentEducation?.id != education.id) {
      changeCvEducationsSorting(
        currentEducation?.id as number,
        education.id as number,
        currentEducation?.position as number,
        education.position,
        Number(resumeId),
      )
    }
  }

  const dragOverHandler = (e: SyntheticEvent) => e.preventDefault()

  return (
    <div className="cv__form">
      <UsersCv resumeId={resume?.id} userId={resume?.user_id} />
      <div className="cv__form_inner">
        <h4 className="cv__form_title">Education</h4>
      </div>
      <Box sx={{ display: 'flex', width: '95%', gap: '10px' }}>
        <div className="leftSide_section">
          <Box sx={{ width: '60%' }}>
            <CvAddEducation cvId={Number(resumeId)} cv={resume} />
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            width: '50%',
            minHeight: '600px',
            flexDirection: 'column',
            marginBottom: 'auto',
            gap: '15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              maxHeight: '500px',
              flexDirection: 'column',
              gap: '15px',
              overflowY: 'auto',
              padding: '15px',
              marginBottom: '30px',
            }}
          >
            {resume?.educations?.map((education) => (
              <Box
                draggable={true}
                onDragStart={(e) => dragStartHandler(e, education)}
                onDragOver={(e) => dragOverHandler(e)}
                onDrop={(e) => dropHandler(e, education)}
                key={education.id}
                sx={{
                  padding: '15px',
                  width: '100%',
                  maxHeight: '200px',
                  border: '1px solid #dee0df',
                  borderRadius: 2,
                  cursor: 'pointer',
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontSize: '17px',
                      alignItems: 'center',
                    }}
                  >
                    {education.university_name} (
                    {dateFormatMMYY(education.start_date) + ' - ' + dateFormatMMYY(education.end_date)})
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <IconButton onClick={() => onEditEducation(education)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => onDeleteResumeEducation(education.id)}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ fontWeight: 'bold', color: '#65696d', fontSize: '13px', fontStyle: 'italic' }}>
                    {education.level}
                  </Box>
                  <Box sx={{ color: '#65696d', fontSize: '13px' }}>
                    {education.city}, {education.country}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          {open && (
            <Modal title={'Edit education'} component={editEducation} open={open} handleClose={() => setOpen(false)} />
          )}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', marginTop: 'auto' }}>
            <CvNavButtons resumeId={resumeId} nextPath={nextPath} prevPath={prevPath} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CvEducations
