import dangerNotify from '../../helpers/dangerNotify'
import { editContract } from '../../services/API/admin/contracts'
import { Contract } from '../../types'
import successNotify from '../../helpers/successNotify'
import { updateContract, updateContractFailure, updateContractSuccess } from '../../store/contractsSlice'
import { useDispatch } from 'react-redux'
import { useGetContracts } from './useGetContracts'
import { getContractsParams, getUsersParams } from '../../constants'
import { useGetUsers } from '../user/useGetUsers'

export const useEditContract = () => {
  const dispatch = useDispatch()
  const getUsers = useGetUsers()
  const getContracts = useGetContracts()

  return (contract: Contract) => {
    dispatch(updateContract())
    editContract(contract)
      .then((response) => {
        dispatch(updateContractSuccess(response.data))
        getContracts(getContractsParams.userId, getContractsParams.projectId, getContractsParams.statusId)
        getUsers(
          getUsersParams.active,
          getUsersParams.user_id,
          getUsersParams.project_id,
          getUsersParams.status_id,
          getUsersParams.manager_id
        )
        successNotify('Contract updated')
      })
      .catch((error) => {
        dispatch(updateContractFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
