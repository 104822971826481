import { localStorageUser } from 'helpers/localStorageUser'
import API from '..'
import ROUTES from '../../../routes'
import { UserProfile } from '../../../types'

export const updateUserPassword = (password: string) => {
  return API.patch(ROUTES.CHANGE_PASSWORD + `/${JSON.parse(localStorageUser || '{}')?.id}`, {
    user: {
      password: password
    }
  })
}

export const updateUserInfo = (profile: UserProfile, userId: number) => {
  return API.put(ROUTES.PROFILES + `/${userId}`, {
    profile
  })
}

export const updateUserStatus = (active: boolean, id: number) => {
  return API.patch(ROUTES.CHANGE_PASSWORD + `/${id}`, {
    user: {
      active: active
    }
  })
}

export const getUserContracts = (id: number, page: number) => {
  return API.get(ROUTES.ADMIN_USERS_CONTRACT + `/${id}/contracts?page=${page}`)
}
