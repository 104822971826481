import { createSlice } from '@reduxjs/toolkit'

import { AdminUserLeave } from 'types/AdminUserLeave'

const initialState = {
  indexData: <AdminUserLeave>{ total: [{}], by_month: {} },
  error: {},
  isLoading: true,
}

const userLeavesSlice = createSlice({
  name: 'userLeaves',
  initialState,
  reducers: {
    fetchUserLeaves(state) {
      state.isLoading = true
    },
    fetchUserLeavesSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchUserLeavesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default userLeavesSlice.reducer
export const { fetchUserLeaves, fetchUserLeavesSuccess, fetchUserLeavesFailure } = userLeavesSlice.actions
