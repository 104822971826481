import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { IResumeCertificate } from '../../../../../types/Resume/IResumeCertificate'
import { useUpdateCvCertificate } from '../../../../../hooks/resume/certificates/useUpdateResumeCertificate'
import CvCertificateForm from './CvCertificateForm'
import EditFormButtons from '../FormButtons/EditFormButtons'

interface Inputs {
    name: string
    url: string
}

interface Props {
    setOpen: (value: boolean) => void
    certificate: IResumeCertificate
    cvId: number
}

const CvUpdateCertificate: React.FC<Props> = ({ setOpen, certificate, cvId }) => {
  const updateCertificate = useUpdateCvCertificate()
    
  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      name: certificate.name || '',
      url: certificate.url || ''
    }
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const updatedCertificate = {
      name: data.name,
      url: data.url,
      resume_id: cvId
    }

    updateCertificate(updatedCertificate as IResumeCertificate, certificate.id as number, cvId)
    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvCertificateForm register={register} />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <EditFormButtons setOpen={setOpen} />
      </Box>
    </form>
  )
}

export default CvUpdateCertificate
