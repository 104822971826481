import React, { useEffect } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'reactstrap'

import { Category, User, Project } from '../../../types'
import CreateRecordInputs from './CreateRecordInputs'
import { Record } from 'types/Record'
import { Contract } from 'types/Contract'
import { useCreateAdminRecord, useCreateRecord } from 'hooks/record/useCreateRecord'
import { useUpdateAdminRecord, useUpdateRecord } from 'hooks/record/useUpdateRecord'
import { renderProject } from 'helpers/renderName'
import { IOptions } from 'types/ISelectOptions'
import { NewRecord } from 'types/NewRecord'
import { Modify } from 'helpers/ModifyInterface'
import { dateFormatYYMMDD } from 'helpers/displayingDateFormat'
import { StatusType } from 'enums/Types'
import { defaultItemsInput, getRecordsParams } from '../../../constants'
import { fetchAvailableContractsSuccess } from 'store/availableContractsSlice'
import { useGetUserAvailableContracts } from 'hooks/contract/useGetContracts'
import { useGetAdminUserRecords } from 'hooks/record/useGetRecords'
import { getUserSuccess } from 'store/usersSlice'
import { webRoutes } from 'lib'
import { findRecord, isSquashRecord } from 'helpers/recordsHelpers'

export type IOmitedRecord = Modify<
  NewRecord,
  {
    project: IOptions
    category: IOptions
  }
>

interface Props {
  categories: Category[]
  date: Date | null
  currentUser: User | undefined
  projects: Project[]
  records: Record[]
  usersNames: User[]
  setUserId: React.Dispatch<React.SetStateAction<IOptions | undefined>>
  userId: IOptions | undefined
}

const CreateRecord: React.FC<Props> = ({
  date,
  categories,
  projects,
  records,
  usersNames,
  setUserId,
  userId,
  currentUser }) => {
  const convertedCurrentDate = dateFormatYYMMDD(date)

  const dispatch = useDispatch()
  const getUserAvailableContractsHook = useGetUserAvailableContracts()
  const getAllRecords = useGetAdminUserRecords()
  const availableContracts = useSelector(
    (state: { availableContracts: { indexData: Contract[] } }) => state.availableContracts.indexData
  )
  const isCreateAdminPage = location.pathname === webRoutes.SUBMIT_HOURS_ADMIN

  const { register, handleSubmit, watch, control, setValue } = useForm<IOmitedRecord>({
    defaultValues: {
      project: undefined,
      category: undefined,
      description: '',
      hours: 8,
      user: null,
      date: convertedCurrentDate,
    },
  })

  useEffect(() => {
    if(!userId){
      setValue('project', '')
      setValue('category', '')
    }
  }, [userId])

  const currentStatusContract = (id: number) => {
    if (id) {
      dispatch(fetchAvailableContractsSuccess([]))
      getUserAvailableContractsHook(id)
      getAllRecords(id, getRecordsParams.year, getRecordsParams.month, getRecordsParams.project_id)
      setValue('project', '')
    } else {
      dispatch(fetchAvailableContractsSuccess([]))
      dispatch(getUserSuccess({}))
      getAllRecords(
        getRecordsParams.user_id,
        getRecordsParams.year,
        getRecordsParams.month,
        getRecordsParams.project_id,
      )
      setValue('project', '')
      setValue('category', '')
      setValue('project.label', '')
    }
  }

  const createRecordHook = useCreateRecord()
  const updateRecordHook = useUpdateRecord()

  const updateAdminRecordHook = useUpdateAdminRecord()
  const createAdminRecordHook = useCreateAdminRecord()

  const onSubmit: SubmitHandler<IOmitedRecord> = (data) => {
    const selectedContract = availableContracts.find((contract: Contract) => {
      return +data?.project.value === contract.project_id
    })

    const record = {
      project_id: data.project.value,
      category_id: data.category.value,
      description: data.description,
      date: convertedCurrentDate,
      hours: defaultItemsInput.workOff,
      job_status_id: selectedContract?.job_status_id as number,
      record_type: data.record_type
    }

    const adminRecord = { ...record, user_id: userId?.value }

    if (isSquashRecord(data, records, convertedCurrentDate, false) && !isCreateAdminPage) {
      updateRecordHook({
        ...record,
        id: findRecord(data, records, convertedCurrentDate, false)?.id as number,
        hours: findRecord(data, records, convertedCurrentDate, false)?.hours + +data.hours,
        record_type: StatusType.REGULAR,
        user_id: currentUser?.id })
    } else if(isSquashRecord(data, records, convertedCurrentDate, true, userId?.value)) {
      updateAdminRecordHook({
        ...adminRecord,
        id: findRecord(data, records, convertedCurrentDate, true, userId?.value)?.id as number,
        hours: findRecord(data, records, convertedCurrentDate, true, userId?.value)?.hours + +data.hours,
        record_type: StatusType.REGULAR,
        user_id: data.user?.value })
      setValue('project', '')
      setValue('category', '')
      setUserId(null)
    } else {
      if (isCreateAdminPage) {
        createAdminRecordHook({ ...adminRecord, hours: data.hours, record_type: StatusType.REGULAR })
        setValue('project', '')
        setValue('category', '')
      }else{
        createRecordHook({ ...record,
          user_id: currentUser?.id, hours: data.hours, record_type: StatusType.REGULAR })
      }
    }
  }

  const validateForm = () => {
    const validProject = watch('project')
    const validCategory = watch('category')
    const validHours = watch('hours') > 0

    return isCreateAdminPage ?
      (userId?.value && validProject && validCategory && validHours) :
      (validProject && validCategory && validHours)
  }

  const userProjects: IOptions[] = availableContracts?.map((contract: Contract) => ({
    value: contract.project_id as number,
    label: renderProject(contract?.project_id, projects) as string,
  })) as IOptions[]

  const userCategories: IOptions[] = categories?.map((category: Category) => ({
    value: category.id,
    label: category.name,
  }))

  const usersList: IOptions[] = usersNames?.filter((user) => user.active).map((user: User) => ({
    value: user.id,
    label: user.full_name,
  }))

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CreateRecordInputs
        hoursReg={register('hours')}
        descriptionReg={register('description')}
        userProjects={userProjects}
        userCategories={userCategories}
        control={control}
        register={register}
        validateForm={validateForm}
        usersList={usersList}
        currentStatusContract={currentStatusContract}
        setUserId={setUserId}
        userId={userId}
      />
    </Form>
  )
}

export default React.memo(CreateRecord)
