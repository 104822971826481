import React, { memo } from 'react'
import { Button } from '@mui/material'
import { useUtility } from 'context/utility'
import { UserProfileLeaveIcon } from './leave-icon'
import { leavesTypes } from '../../../constants'

type Props = {
  type: keyof typeof leavesTypes
  counter?: number
  active?: boolean
  onClick?: () => void
}

const UserProfileLeaveBox = ({ type, counter = 0, active, onClick }: Props) => {
  const { width } = useUtility()

  return width < 991.98 ? (
    <Button className={'user-profile__leaves-type' + (active ? ' active' : '')} onClick={onClick}>
      <div className="user-profile__leaves-type-top">
        <UserProfileLeaveIcon type={type} />
        <p className="user-profile__leaves-type-counter">{counter}</p>
      </div>
      {leavesTypes[type]}
    </Button>
  ) : (
    <div className="user-profile__leaves-type">
      <div className="user-profile__leaves-type-top">
        <UserProfileLeaveIcon type={type} />
        <p className="user-profile__leaves-type-counter">{counter}</p>
      </div>
      {leavesTypes[type]}
    </div>
  )
}

export default memo(UserProfileLeaveBox)
