import React from 'react'
import { isMobile } from 'react-device-detect'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import { UnpackNestedValue, useForm } from 'react-hook-form'

import { createCustomerSchema } from '../../../helpers/validationShema'
import { Customer } from '../../../types/Customer'
import { useEditCustomer } from '../../../hooks/customer/useEditCustomer'
import CustomButton from 'components/custom/CustomButton/CustomButton'
import ReactForm from '../../reusable/ReactForm'
import ReactInput from '../../reusable/ReactInput/ReactInput'

interface Props {
  setOpen: (show: boolean) => void
  selectedCustomer: Customer | null
  customers: Customer[]
  setEdited: (edited: boolean) => void
  setPage: (page: number) => void
  page: number
}

interface Inputs {
  company_name: string
  full_legal_name: string
  title: string
  company_address: string
  email: string
}

export const EditCustomer: React.FC<Props> = ({ setOpen, selectedCustomer, setEdited,
  page }) => {
  const editCustomer = useEditCustomer()
  const defaultValues = {
    company_name: selectedCustomer?.company_name || '',
    full_legal_name: selectedCustomer?.full_legal_name || '',
    email: selectedCustomer?.email || '',
    title: selectedCustomer?.title || '',
    company_address: selectedCustomer?.company_address || '',
  }
  const resolver = yupResolver(createCustomerSchema)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<Inputs>({ resolver, defaultValues })

  function onSubmit(data: UnpackNestedValue<Inputs>): void {
    const updatedCustomer: Customer = {
      company_name: data.company_name,
      full_legal_name: data.full_legal_name,
      email: data.email,
      title: data.title,
      company_address: data.company_address,
    }
    editCustomer(page, updatedCustomer, selectedCustomer?.id)
    setOpen(false)
    setEdited(true)
  }

  return (
    <>
      {!isMobile && <ReactForm onSubmit={handleSubmit(onSubmit)}>
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            <ReactInput
              className="modal__input"
              type="text"
              size="medium"
              label="Company name"
              registerControl="company_name"
              register={register}
              error={!!errors.company_name}
              helperText={errors.company_name?.message}
            />
            <ReactInput
              className="modal__input"
              type="text"
              size="medium"
              label="Representative"
              registerControl="full_legal_name"
              register={register}
              error={!!errors.full_legal_name}
              helperText={errors.full_legal_name?.message}
            />
            <ReactInput
              className="modal__input"
              type="text"
              size="medium"
              label="Title"
              registerControl="title"
              register={register}
              error={!!errors.title}
              helperText={errors.title?.message}
            />
            <ReactInput
              className="modal__input"
              type="text"
              size="medium"
              label="Email"
              registerControl="email"
              register={register}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <ReactInput
              className="modal__input"
              size="medium"
              type="text"
              label="Company address"
              registerControl="company_address"
              register={register}
            />
          </Box>
        </>
        <Box sx={{ display: 'flex', gap: '1rem', marginTop: '40px' }}>
          <CustomButton variant="outlined" onClick={() => setOpen(false)} text="Cancel" />
          <CustomButton type="submit" text="Edit" />
        </Box>
      </ReactForm>}

      {isMobile && <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className="filters-modal-wrapper">
            <div className="filters-modal-navbar">
              <div onClick={() => setOpen(false)} className="filters-modal-back-arrow">
                <ArrowBackIcon onClick={() => setOpen(false)} />
              </div>
              <p className="filters-modal-navbar-text">Edit Customer</p>
            </div>
            <div className="filters-modal-body">
              <div className="customers-input-modal-wrapper">
                <div>
                  <ReactInput
                    className="modal__input"
                    type="text"
                    size="medium"
                    label="Company name"
                    registerControl="company_name"
                    register={register}
                    error={!!errors.company_name}
                    helperText={errors.company_name?.message}
                  />
                </div>
                <div className="customers-create-row">
                  <ReactInput
                    className="modal__input"
                    type="text"
                    size="medium"
                    label="Representative"
                    registerControl="full_legal_name"
                    register={register}
                    error={!!errors.full_legal_name}
                    helperText={errors.full_legal_name?.message}
                  />
                </div>
                <div className="customers-create-row">
                  <ReactInput
                    className="modal__input"
                    type="text"
                    size="medium"
                    label="Title"
                    registerControl="title"
                    register={register}
                    error={!!errors.title}
                    helperText={errors.title?.message}
                  />
                </div>
                <div className="customers-create-row">
                  <ReactInput
                    className="modal__input"
                    type="text"
                    size="medium"
                    label="Email"
                    registerControl="email"
                    register={register}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </div>
                <div className="customers-create-row">
                  <ReactInput
                    className="modal__input"
                    size="medium"
                    type="text"
                    label="Company address"
                    registerControl="company_address"
                    register={register}
                  />
                </div>
              </div>
            </div>
            <div className="customers-button-submit">
              <CustomButton type="submit" text="Edit" />
            </div>
          </div>
        </>
      </form>}
    </>
  )
}
