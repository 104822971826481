import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { createResumeProject } from '../../../services/API/admin/resume'
import { IResumeProject } from '../../../types/Resume/IResumeProject'
import { useGetUserCv } from '../useGetUserCv'

export const useCreateResumeProject = () => {
  const getUserCv = useGetUserCv()

  return (resume_project: IResumeProject, cvId: number) => {
    createResumeProject(resume_project)
      .then(() => {
        successNotify('CV project created')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Please fill in the contact section'))
  }
}
