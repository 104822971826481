import React from 'react'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { IconButton, MenuItem } from '@mui/material'
import { isMobile } from 'react-device-detect'

import { Category } from 'types/Category'
import { ReadyRecord } from 'types/ReadyRecord'
import Modal from 'components/reusable/Modal'
import EditForm from 'components/reusable/EditForm'
import useToggle from 'hooks/useToggle'
import { User, Project } from '../../types'
import { IOptions } from 'types/ISelectOptions'

interface Props {
  record: ReadyRecord
  categories: Category[]
  currentUser: User | undefined
  projects: Project[]
  chosenUser: User | undefined
  userId: IOptions
  setUserId: React.Dispatch<React.SetStateAction<IOptions | undefined>>
}

export const ModalContainer: React.FC<Props> = ({ record, categories, currentUser,
  projects, chosenUser, userId, setUserId }) => {
  const [open, toggle] = useToggle(false)

  const editForm = (
    <EditForm
      record={record}
      categories={categories}
      currentUser={currentUser}
      chosenUser={chosenUser}
      closeModal={toggle}
      projects={projects}
      userId={userId}
      open={open}
      setUserId={setUserId}
    />
  )

  return (
    <React.Fragment>
      <IconButton onClick={toggle} >  
        {!isMobile ? <EditOutlinedIcon/> : <MenuItem>Edit</MenuItem>}
      </IconButton>
      {open && <Modal title="Edit Record" component={editForm} open={open} handleClose={toggle} />}
    </React.Fragment>
  )
}
