import API from '..'
import ROUTES from '../../../routes'
import { Vacancy } from '../../../types'

export const getVacancies = (page: number) => {
  return API.get(ROUTES.VACANCIES + `?page=${page}`)
}

export const getVacancy = (id: number) => {
  return API.get(ROUTES.VACANCY(id))
}

export const createVacancy = (params: Vacancy | Partial<Vacancy>) => {
  return API.post(ROUTES.VACANCIES, params)
}

export const updateVacancy = (params: Vacancy | Partial<Vacancy>) => {
  return API.put(ROUTES.VACANCY(params.id), params)
}

export const deleteVacancy = (id: number) => {
  return API.delete(ROUTES.VACANCY(id))
}
