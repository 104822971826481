import {
  Box,
  FilledInputProps,
  IconButton,
  InputBaseComponentProps,
  InputProps,
  OutlinedInputProps
} from '@mui/material'
import React from 'react'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { isMobile } from 'react-device-detect'

type Props = {
  inputRef?: React.Ref<HTMLInputElement>
  inputProps?: InputBaseComponentProps
  InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>
  arrowBackItem?: () => void
  arrowNextItem?: () => void
  onOpen?: () => void
  disableMinusArrow?: boolean
  disablePlusArrow?: boolean
  inputType: 'day' | 'month' | 'default'
  placeholder: string | undefined
  borderInputColor?: string
  width?: string
}

const DatePickerInput: React.FC<Props> = ({
  inputRef,
  inputProps,
  InputProps,
  arrowBackItem,
  arrowNextItem,
  disableMinusArrow,
  disablePlusArrow,
  inputType,
  placeholder,
  borderInputColor,
  onOpen,
  width
}) => {
  return (
    <>
      {inputType === 'day' ? (
        <Box
          sx={{
            backgroundColor: 'white',
            boxShadow: '0px 0px 0px 15px white',
            width: !isMobile ? width : '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: inputType === 'day' ? 'space-between' : 'flex-start',
            gap: '10px',
            border: `1px solid ${borderInputColor ? borderInputColor : '#FF655C'}`,
            padding: '10px 10px',
            borderRadius: '10px',
          }}
        >
          <IconButton sx={{ height: '100%', maxHeight: '40px' }} onClick={arrowBackItem} disabled={disableMinusArrow}>
            <ArrowBackIosNewIcon sx={{ width: '15px' }} />
          </IconButton>

          <input
            ref={inputRef}
            {...inputProps}
            style={{
              border: 'none',
              outline: 'none',
              textAlign: 'center',
              width: '50%'
            }}
            onMouseDown={(event) => event.preventDefault()}
            onKeyDown={(event) => event.preventDefault()}
          />

          {InputProps?.endAdornment}

          <IconButton sx={{ height: '100%', maxHeight: '40px' }} onClick={arrowNextItem} disabled={disablePlusArrow}>
            <ArrowForwardIosIcon sx={{ width: '15px' }} />
          </IconButton>
        </Box>
      ) : inputType === 'month' ? (
        <Box
          onClick={onOpen}
          sx={{
            backgroundColor: 'white',
            boxShadow: !isMobile ? '0px 0px 0px 15px white' : 'none',
            width: !isMobile ? '300px' : '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
            border: isMobile ? '1px solid #D4D9E2' : '1px solid #FF655C',
            padding: isMobile ? '10px 3px' : '10px 10px',
            borderRadius: isMobile ? '6px' : '10px',
          }}
        >
          {InputProps?.endAdornment}

          <input
            ref={inputRef}
            {...inputProps}
            style={{
              border: 'none',
              outline: 'none',
              textAlign: 'center',
              width: '50%',
              fontSize: isMobile ? '13px' : '16px'
            }}
            onMouseDown={(event) => event.preventDefault()}
            onKeyDown={(event) => event.preventDefault()}
          />
          {InputProps?.startAdornment}
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'white',
            boxShadow: '0px 0px 0px 15px white',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px',
            border: '1px solid #D4D9E2',
            padding: '13px 18px',
            borderRadius: '10px',
          }}
        >
          <input
            ref={inputRef}
            {...inputProps}
            style={{ border: 'none', outline: 'none', width: '100%', fontSize: '14px' }}
            placeholder={placeholder}
          />

          {InputProps?.endAdornment}
        </Box>
      )}
    </>
  )
}

export default DatePickerInput
