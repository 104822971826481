import { createSlice } from '@reduxjs/toolkit'

import { IResume } from '../types/Resume/IResume'

const initialState = {
  indexData: <IResume[]>[],
  showData: <IResume>{},
  error: {},
  isLoading: true,
}

const resumesSlice = createSlice({
  name: 'resumes',
  initialState,
  reducers: {
    getResume(state) {
      state.isLoading = true
    },
    getResumeSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    getResumeFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    fetchResumes(state) {
      state.isLoading = true
    },
    fetchResumesSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchResumesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    addResume(state) {
      state.isLoading = true
    },
    addResumeSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    addResumeFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    updateResume(state) {
      state.isLoading = true
    },
    updateResumeSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    updateResumeFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    deleteUserResume(state) {
      state.isLoading = true
    },
    deleteUserResumeSuccess(state, action) {
      state.indexData = state.indexData.filter((cv: IResume) => cv.id !== action.payload)
      state.isLoading = false
    },
    deleteUserResumeFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default resumesSlice.reducer
export const {
  getResume,
  getResumeSuccess,
  getResumeFailure,
  addResume,
  addResumeSuccess,
  addResumeFailure,
  updateResume,
  updateResumeSuccess,
  updateResumeFailure,
  fetchResumes,
  fetchResumesSuccess,
  fetchResumesFailure,
  deleteUserResume,
  deleteUserResumeSuccess,
  deleteUserResumeFailure
} = resumesSlice.actions
