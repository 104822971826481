import { useDispatch } from 'react-redux'

import { Project } from '../../types'
import { fetchProjects, 
  fetchProjectsFailure, 
  fetchProjectsSuccess, 
  fetchUserProjects, 
  fetchUserProjectsFailure, 
  fetchUserProjectsSuccess } from '../../store/projectsSlice'
import { getProjects } from '../../services/API/admin/projects'
import dangerNotify from '../../helpers/dangerNotify'
import { getUserProjects } from 'services/API/projects'

export const useGetProjects = () => {
  const dispatch = useDispatch()

  return (
    name: string | undefined,
    technology: string | undefined,
    company: string | undefined,
    fromScratch: string | undefined,
    application: string | undefined,
    manager: number | undefined,
    page: number
  ) => [
    dispatch(fetchProjects()),
    getProjects(name, technology, company, fromScratch, application, manager, page)
      .then((response: { data: Project[] }) => {
        dispatch(fetchProjectsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchProjectsFailure(error))
        dangerNotify('Server error: unable to download Projects')
      }),
  ]
}

export const useGetUserProjects = () => {
  const dispatch = useDispatch()

  return () => [
    dispatch(fetchUserProjects()),
    getUserProjects()
      .then((response: { data: Project[] }) => {
        dispatch(fetchUserProjectsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchUserProjectsFailure(error))
        dangerNotify('Server error: unable to download Projects')
      }),
  ]
}
