import { createSlice } from '@reduxjs/toolkit'

import { SubRole } from 'types/SubRole'

const initialState = {
  indexData: <SubRole[]>[],
  error: {},
  isLoading: true,
}

const subRolesSlice = createSlice({
  name: 'sub roles',
  initialState,
  reducers: {
    fetchSubRoles(state) {
      state.isLoading = true
    },
    fetchSubRolesSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchSubRolesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default subRolesSlice.reducer
export const { fetchSubRoles, fetchSubRolesSuccess, fetchSubRolesFailure } = subRolesSlice.actions
