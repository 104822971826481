import React, { memo, useState } from 'react'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'

import { PlusIcon } from 'icons'
import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import Loader from 'components/reusable/Loader'
import { calculateAge } from 'helpers/calculateAge'
import Modal from 'components/reusable/Modal'
import { UserProfilePersonalInfoForm } from '../forms/personal-info'
import { useUtility } from 'context/utility'

const UserProfilePersonalInfo = () => {
  const { width } = useUtility()

  const {
    showData: { date_of_birth, marital_status, home_address, office, post_office, children },
    isLoading,
  } = useSelector(({ profile }) => profile)

  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => setModalOpen((ps) => !ps)

  if (isLoading) return <Loader />

  return (
    <>
      {width < 1199.98 && isLoading ? (
        <Loader />
      ) : (
        <>
          <h3 className="user-profile__title">Personal info</h3>
          <table className="user-profile__table personal-info">
            <thead className="user-profile__table-thead">
              <tr>
                <th className="user-profile__table-th">Personal Info:</th>
                <th className="user-profile__table-th">
                  <Button variant="contained" onClick={toggleModal}>
                    Add Personal Info&nbsp;
                    <PlusIcon />
                  </Button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="user-profile__table-tr no-bg">
                <td className="user-profile__table-td">Date of birth:</td>
                <td className="user-profile__table-td">{date_of_birth ? dateFormatDDMMYY(date_of_birth) : ''}</td>
              </tr>
              <tr className="user-profile__table-tr no-bg">
                <td className="user-profile__table-td">Marital status:</td>
                <td className="user-profile__table-td">{marital_status || 'Single'}</td>
              </tr>
              <tr className="user-profile__table-tr no-bg">
                <td className="user-profile__table-td">Children:</td>
                <td className="user-profile__table-td">
                  {children?.length ? children.map((c) => c.name).join(', ') : 'No'}
                </td>
              </tr>
              <tr className="user-profile__table-tr no-bg">
                <td className="user-profile__table-td">Children&apos;s age:</td>
                <td className="user-profile__table-td">
                  {children?.length
                    ? children.map((c) => c?.date_of_birth && calculateAge(new Date(c?.date_of_birth)).years).join(', ')
                    : 'No'}
                </td>
              </tr>
              <tr className="user-profile__table-tr no-bg">
                <td className="user-profile__table-td">Office:</td>
                <td className="user-profile__table-td">{office}</td>
              </tr>
              <tr className="user-profile__table-tr no-bg">
                <td className="user-profile__table-td">Home Location:</td>
                <td className="user-profile__table-td">{home_address}</td>
              </tr>
              <tr className="user-profile__table-tr no-bg">
                <td className="user-profile__table-td">Post Office:</td>
                <td className="user-profile__table-td">{post_office}</td>
              </tr>
            </tbody>
          </table>
          <div className="user-profile__personal-info-button-wrap">
            <Button className="w-100" variant="contained" onClick={toggleModal}>
              Add Personal Info&nbsp;
              <PlusIcon />
            </Button>
          </div>
        </>
      )}
      <Modal
        title="Personal Info"
        open={modalOpen}
        handleClose={toggleModal}
        component={<UserProfilePersonalInfoForm onCancel={toggleModal} />}
      />
    </>
  )
}

export default memo(UserProfilePersonalInfo)
