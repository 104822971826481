import { Status } from '../types'

export const backgroundColorUser = (id: number | undefined, statuses: Status[]) => {
  if (!id) return
  const statusColor = statuses.find((status) => status.id === +id)

  if (statusColor) {
    return statusColor.tag + '_color'
  } else {
    return 'opacity'
  }
}
