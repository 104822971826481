import React from 'react'

import { IResume } from '../../../../../types/Resume/IResume'
import './mobileStyles.scss'
import { dateFormatMMYY } from '../../../../../helpers/displayingDateFormat'

interface Props {
  resume: IResume
}

const MobileEducationTab: React.FC<Props> = ({ resume }) => {
  return (
    <div className="mobile_preview">
      <div className="education_wrapper">
        {resume?.educations?.map(education => (
          <div key={education.id} className="university_item">
            <span className="university_name">
              {education.university_name}
            </span>
            <span className="education_level">
              {education.level}
            </span>
            <div className="education_interval">
              {dateFormatMMYY(education.start_date)} - {dateFormatMMYY(education.end_date)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MobileEducationTab
