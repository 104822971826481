import React, { memo } from 'react'
import { IconButton, TextField } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import { Child } from 'types'

type Props = {
  child: Child
  handleRemove: (id: number | string) => void
  handleChangeChild: ((data: Child) => void) | null
}

export const UserProfileChildForm = memo(function UserProfileChildForm({
  child,
  handleRemove,
  handleChangeChild,
}: Props) {
  const handleChange = (field: keyof Child) => (value: string | Date | null) => {
    if (handleChangeChild) handleChangeChild({ [field]: value })
  }

  return (
    <div className="modal__input">
      <TextField
        autoComplete="off"
        label="Child Name"
        value={child.name}
        onChange={({ target: { value } }) => handleChange('name')(value)}
        sx={{ flex: 2 }}
      />
      <div style={{ flex: 1 }}>
        <CustomDatepicker
          value={child.date_of_birth as string | Date | null}
          onChange={(v) => handleChange('date_of_birth')(v)}
          maxDate={new Date()}
          mask={'__.__.____'}
          inputType="default"
          placeholder="Date of Birth"
        />
      </div>

      <IconButton onClick={() => child.id && handleRemove(child.id)}>
        <DeleteOutlineIcon />
      </IconButton>
    </div>
  )
})
