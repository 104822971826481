import React from 'react'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { tableCellStyles } from '../../../../../utilts/styles'
import { tableHeadStyles } from '../../../../../utilts/styles'
import { Contract } from 'types'
import { renderStatus, renderUser } from 'helpers/renderName'
import { dateFormatDDMMYY } from '../../../../../helpers/displayingDateFormat'
import CustomPagination from 'components/reusable/Pagination/Pagination'
import { ProjectProfile } from 'types/Manage/Project/ProjectProfile'
import { statusColor } from '../../../../../helpers/statusColor'

const DesktopContractsLogsProject: React.FC<ProjectProfile> = ({
  status,
  setPage,
  page,
  usersNames,
}) => {
  const contracts = useSelector(
    (state: { contracts: { indexLogsData: Contract[] } }) => state.contracts.indexLogsData
  )
  const totalPages = useSelector(
    (state: { contracts: { indexLogsData: { pages: number } } }) => state.contracts.indexLogsData.pages)

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={tableCellStyles}>
                    User
            </TableCell>
            <TableCell align="left" sx={tableHeadStyles}>
                    Job Status
            </TableCell>
            <TableCell align="left" sx={tableHeadStyles}>
                    Start Date
            </TableCell>
            <TableCell align="left" sx={tableHeadStyles}>
                    End Date
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contracts?.paginate_contracts?.map((item: Contract, index: number) => (
            <TableRow key={index} className="requests-table__style">
              <TableCell align="center" sx={tableCellStyles} className="project-profile_contracts-name">
                <Link to={`/users/${item.user_id}`}>{renderUser(item.user_id, usersNames)}</Link>
              </TableCell>
              <TableCell align="center" sx={tableCellStyles}>
                <div className={statusColor(renderStatus(item.job_status_id as number,status))}>
                  {renderStatus(item.job_status_id as number,status)}  
                </div>
              </TableCell>
              <TableCell align="center" sx={tableCellStyles}>
                {item?.start_date && dateFormatDDMMYY(item?.start_date)}
              </TableCell>
              <TableCell align="center" sx={tableCellStyles}>
                {item?.end_date && dateFormatDDMMYY(item?.end_date)}
              </TableCell>
            </TableRow>
          ))
          }
        </TableBody>
      </Table>
      {contracts && 
          <CustomPagination
            totalPages={totalPages}
            page={page}
            setPage={setPage}
          />
      }
    </TableContainer>
  )
}

export default DesktopContractsLogsProject
