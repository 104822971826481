import React from 'react'

const CalendarIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" 
        d="M4 4V4.14283C4 4.61622 4.38376 4.99998 4.85714 4.99998C5.33053 4.99998 5.71429 
        4.61622 5.71429 4.14284V4H14.2857L14.2857 4.14283C14.2857 4.61622 14.6695 4.99998 
        15.1429 4.99998C15.6162 4.99998 16 4.61622 16 4.14284V4H17.8C17.9105 4 18 4.08954 
        18 4.2V6.49999H2V4.2C2 4.08954 2.08954 4 2.2 4H4ZM2 8.49999H18V18.8C18 18.9105 17.9105 
        19 17.8 19H2.2C2.08954 19 2 18.9104 2 18.8V8.49999ZM16 2H17.8C19.015 2 20 2.98497 
        20 4.2V18.8C20 20.015 19.015 21 17.8 21H2.2C0.984976 21 0 20.015 0 18.8V4.2C0 2.98497 
        0.984974 2 2.2 2H4V1.57141C4 1.09802 4.38376 0.714264 4.85714 0.714264C5.33053 0.714264 
        5.71429 1.09802 5.71429 1.57141V2H14.2857L14.2857 1.57141C14.2857 1.09802 14.6695 0.714264 
        15.1429 0.714264C15.6162 0.714264 16 1.09802 16 1.57141V2ZM5.2 12.9C5.86274 12.9 6.4 12.3627 
        6.4 11.7C6.4 11.0373 5.86274 10.5 5.2 10.5C4.53726 10.5 4 11.0373 4 11.7C4 12.3627 4.53726 12.9 
        5.2 12.9ZM6.4 15.4C6.4 16.0627 5.86274 16.6 5.2 16.6C4.53726 16.6 4 16.0627 4 15.4C4 14.7372 
        4.53726 14.2 5.2 14.2C5.86274 14.2 6.4 14.7372 6.4 15.4ZM8.89999 12.9C9.56273 12.9 10.1 12.3627 
        10.1 11.7C10.1 11.0373 9.56273 10.5 8.89999 10.5C8.23724 10.5 7.69999 11.0373 7.69999 11.7C7.69999 
        12.3627 8.23724 12.9 8.89999 12.9ZM13.8 11.7C13.8 12.3627 13.2627 12.9 12.6 12.9C11.9372 12.9 11.4 
        12.3627 11.4 11.7C11.4 11.0373 11.9372 10.5 12.6 10.5C13.2627 10.5 13.8 11.0373 13.8 11.7ZM8.89999 
        16.6C9.56273 16.6 10.1 16.0627 10.1 15.4C10.1 14.7372 9.56273 14.2 8.89999 14.2C8.23724 14.2 7.69999 
        14.7372 7.69999 15.4C7.69999 16.0627 8.23724 16.6 8.89999 16.6Z" fill="#303540"/>
    </svg>
  )
}

export default CalendarIcon
