import { firstPage, WeekDay } from '../constants'

export const arrowNextItem = (date: Date, setDate: (value: Date) => void, setPage?: (page: number) => void) => {
  if (date) {
    const _date = new Date(new Date(date).setDate(1))

    setDate(new Date(_date.setMonth(_date.getMonth() + WeekDay.OneDay)))
    setPage && setPage(firstPage)
  }
}
export const arrowBackItem = (date: Date, setDate: (value: Date) => void, setPage?: (page: number) => void) => {
  if (date) {
    const _date = new Date(new Date(date).setDate(1))

    setDate(new Date(_date.setMonth(_date.getMonth() - WeekDay.OneDay)))
    setPage && setPage(firstPage)
  }
}
