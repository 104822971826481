import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import holidays from 'date-holidays'
import { useLocation } from 'react-router-dom'

import CreateRecord from 'components/admin/CreateRecord'
import DatePicker from 'components/user/DatePicker'
import { useGetCategories } from 'hooks/category/useGetCategories'
import { useGetAdminUserRecords, useGetRecords } from 'hooks/record/useGetRecords'
import { Project } from 'types/Project'
import { Category } from 'types/Category'
import { Record } from 'types/Record'
import { useAuth } from 'context/auth'
import { getRecordsParams, holidaysUkraine } from '../../constants'
import RecordsTable from 'components/user/RecordsTable'
import Total from 'components/reusable/Total'
import RecordsCard from 'components/reusable/RecordsCard/RecordsCard'
import { ReadyRecord } from 'types/ReadyRecord'
import { userRecordsFilter } from 'helpers/userRecordsFilter'
import { dateFormatYYMMDD } from 'helpers/displayingDateFormat'
import { useGetAvailableContracts, useGetUserAvailableContracts } from 'hooks/contract/useGetContracts'
import { User } from 'types'
import { webRoutes } from 'lib'
import AdminRecordsTable from 'components/admin/AdminRecordsTable'
import newRecordFromId from 'helpers/newRecordFromId'
import { IOptions } from 'types/ISelectOptions'
import { Pages } from 'enums/StartPage'

const CreateRecordContainer = () => {
  const [date, setDate] = useState<Date | null>(new Date())
  const [userId, setUserId] = useState<IOptions>()
  const uaHolidays = new holidays('UA')
  const holiday = uaHolidays.getHolidays()
  const location = useLocation()
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const userRecords = useSelector((state: { records: { indexData: Record[] } }) => state.records.indexData)
  const adminRecords = useSelector((state: { records: { adminIndex: Record[] } }) => state.records.adminIndex)
  const categories = useSelector((state: { categories: { indexData: Category[] } }) => state.categories.indexData)
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const isCreateAdminPage = location.pathname === webRoutes.SUBMIT_HOURS_ADMIN
  const { currentUser } = useAuth()
  const adminRecord = [...adminRecords].filter((record) => record?.date === dateFormatYYMMDD(date)) 
  const userRecord = [...userRecords].filter((record) => record?.date === dateFormatYYMMDD(date))
  const recordsFromId: ReadyRecord[] = userRecordsFilter(userRecord, projects, categories)
  const rewrittenAdminRecords = newRecordFromId(projects, categories, adminRecord, usersNames)
  const adminTotal = rewrittenAdminRecords.reduce((a, b) => a + b.hours, 0)
  const total = recordsFromId.reduce((a, b) => a + b.hours, 0)
  const getRecordsHook = useGetRecords()
  const getAdminRecords = useGetAdminUserRecords()
  const getCategoryHook = useGetCategories()
  const getAvaliableContracts = useGetAvailableContracts()
  const getUserAvaliableContracts = useGetUserAvailableContracts()

  const updateDate = (value: Date | null) => {
    setDate(value)
  }

  const currentHoliday = holiday?.map((item) => {
    const holidayTranslate = dateFormatYYMMDD(date) === 
    dateFormatYYMMDD(item?.date) && holidaysUkraine[dateFormatYYMMDD(item?.date)]
    return holidayTranslate
  })

  useEffect(() => {
    getCategoryHook()
    
    if(isCreateAdminPage) {
      getUserAvaliableContracts(userId?.value)
      getAdminRecords(
        getRecordsParams.user_id,
        getRecordsParams.year,
        getRecordsParams.month,
        getRecordsParams.project_id,
        undefined, 
        Pages.START_PAGE)
    } else {
      getRecordsHook(
        getRecordsParams.project_id, 
        getRecordsParams.year, 
        getRecordsParams.month, 
        undefined, 
        Pages.START_PAGE)
      getAvaliableContracts()
    }
  }, [])

  return (
    <div className="container">
      <div style={{ padding: !isMobile ? '3rem 0' : 0 }}>
        <div style={{ 
          position: !isMobile ? 'absolute' : 'relative', 
          marginLeft: !isMobile ? '3rem' : 0,
          marginTop: !isMobile ? '-2rem' : 0 }}>
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            <DatePicker
              date={date}
              setDate={setDate}
              projects={projects}
              categories={categories}
              updateDate={updateDate}
              currentUser={currentUser}
              maxDate={new Date()}
            />
            <p className="holiday-title" 
              style={{ marginTop: isMobile ? 0 : '20px', textAlign: isMobile ? 'center' : 'start' }}>
              {currentHoliday}
            </p>
          </div>
        </div>
        <div style={{ 
          border: !isMobile ? '1px solid #D4D9E2' : 'none', 
          borderRadius: !isMobile ? '20px' : 0, 
          padding: !isMobile ? '3rem' : 0
        }}>
          <CreateRecord
            date={date}
            records={isCreateAdminPage ? adminRecords : userRecords}
            categories={categories}
            currentUser={currentUser}
            projects={projects}
            usersNames={usersNames}
            setUserId={setUserId}
            userId={userId}
          />
          <div style={{ paddingTop: !isMobile ? '6rem' : 0 }}>
            <>
              {!isMobile ?
                !isCreateAdminPage ? 
                  <RecordsTable 
                    records={recordsFromId} 
                    categories={categories} 
                    currentUser={currentUser} 
                    projects={projects} /> :          
                  <AdminRecordsTable 
                    records={rewrittenAdminRecords} 
                    projects={projects} 
                    categories={categories}
                    userId={userId}
                    setUserId={setUserId}
                  /> :
                <>
                  {isCreateAdminPage ? 
                    rewrittenAdminRecords && rewrittenAdminRecords.map((record) =>
                      <>    
                        <RecordsCard
                          record={record}
                          records={rewrittenAdminRecords} 
                          categories={categories} 
                          currentUser={currentUser} 
                          projects={projects}
                          setUserId={setUserId}
                        />
                      </>
                    )
                    : recordsFromId && recordsFromId.map((record) =>
                      <>    
                        <RecordsCard
                          record={record}
                          records={recordsFromId} 
                          categories={categories} 
                          currentUser={currentUser} 
                          projects={projects}/>
                      </>
                    )
                  }
                </>
              }
            </>
            <Total total={isCreateAdminPage ? adminTotal : total} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateRecordContainer
