import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  cv_page: {
    height: '100vh',
    padding: '60px',
    width: '940px'
  },
  cv_header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cv_author: {
    width: '65%'
  },
  page_wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  left_side: {
    width: '35%'
  },
  right_side: {
    width: '65%',
  },
  avatar_wrapper: {
    width: '30%'
  },
  avatar: {
    borderRadius: '5%',
    width: '210px',
    height: '210px'
  },
  name_wrapper: {
    margin: '15px 0'
  },
  name: {
    fontSize: '18px',
    color: '#303540',
    fontFamily: 'ManropeBold',
  },
  user_data: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  user_data__title: {
    color: '#545C6E',
    fontSize: '12px',
    fontFamily: 'ManropeSemiBold',
    margin: '5px 0'
  },
  user_data__subtitle: {
    color: '#303540',
    fontSize: '13px',
    fontFamily: 'ManropeSemiBold',
    textDecoration: 'none'
  },
  border_line: {
    width: '80%',
    borderBottom: '1px solid #D4D9E2',
    margin: '20px 0'
  },
  block_title: {
    color: '#303540',
    fontSize: '17px',
    fontFamily: 'ManropeBold',
    marginBottom: '10px'
  },
  skill_name: {
    color: '#303540',
    fontSize: '15px',
    fontFamily: 'ManropeRegular',
  },
  skill_name__item: {
    width: '80%',
    marginTop: '10px'
  },
  skill_items: {
    display: 'flex',
    flexDirection: 'row'
  },
  rectangle: {
    width: '5px',
    height: '5px',
    marginTop: '10px'
  },
  skill_item: {
    fontSize: '12px',
    fontFamily: 'ManropeRegular',
    color: '#545C6E',
    margin: '5px 0 0 5px',
  },
  language_item: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px'
  },
  language_name: {
    color: '#303540',
    fontSize: '15px',
    fontFamily: 'ManropeBold'
  },
  language_level: {
    color: '#545C6E',
    fontSize: '12px',
    fontFamily: 'ManropeRegular'
  },
  book_items: {
    display: 'flex',
    flexDirection: 'column'
  },
  book_item: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px 0'
  },
  book_cover: {
    width: '20%'
  },
  book_image: {
    width: 50,
    height: 50,
    objectFit: 'cover',
    borderRadius: '8px'
  },
  book_inner: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%'
  },
  book_title: {
    color: '#303540',
    fontSize: '10px',
    fontFamily: 'ManropeBold'
  },
  book_author: {
    color: '#545C6E',
    fontSize: '9px',
    fontFamily: 'ManropeRegular'
  },
  user_name: {
    color: '#303540',
    fontFamily: 'ManropeExtraBold',
    fontSize: '40px',
    marginBottom: '8px'
  },
  user_summary: {
    color: '#545C6E',
    fontFamily: 'ManropeRegular',
    fontSize: '14px'
  },
  cv_block: {
    width: '100%',
    padding: '4px 10px',
    backgroundColor: '#FF655C',
    margin: '10px 0'
  },
  cv_block__name : {
    color: '#fff',
    fontSize: '16px',
    fontFamily: 'ManropeBold'
  },
  experience_item: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '25px'
  },
  experience_job_title: {
    color: '#303540',
    fontSize: '15px',
    fontFamily: 'ManropeBold'
  },
  experience_company: {
    fontSize: '11px',
    color: '#545C6E',
    fontFamily: 'ManropeRegular'
  },
  experience_interval: {
    fontSize: '11px',
    color: '#303540',
    fontFamily: 'ManropeRegular',
    margin: '5px 0 10px'
  },
  experience_responsibilities_list: {
    display: 'flex',
    flexDirection: 'row',
  },
  experience_responsibility_before: {
    width: '6px',
    height: '5px',
    borderRadius: '50%',
    marginTop: '7px'
  },
  experience_responsibility: {
    color: '#545C6E',
    fontSize: '12px',
    marginLeft: '8px',
    fontFamily: 'ManropeRegular'
  },
  education_wrapper: {
    marginBottom: '20px'
  },
  education_interval: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#303540',
    fontSize: '11px',
    fontFamily: 'ManropeRegular'
  },
  education_level: {
    color: '#545C6E',
    fontFamily: 'ManropeSemiBold',
    fontSize: '12px'
  },
  education_university: {
    color: '#303540',
    fontSize: '16px',
    fontFamily: 'ManropeBold',
    marginTop: '4px'
  },
  project_item: {
    margin: '10px 0'
  },
  project_title: {
    color: '#303540',
    fontFamily: 'ManropeBold',
    fontSize: '17px'
  },
  project_role: {
    color: '#545C6E',
    fontSize: '13px',
    fontFamily: 'ManropeSemiBold',
    marginBottom: '7px'
  },
  project_url: {
    marginBottom: '6px'
  },
  project_link: {
    color: '#303540',
    fontSize: '13px',
    fontFamily: 'ManropeSemiBold',
    textDecoration: 'none'
  },
  project_stack__wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  project_stack__item: {
    padding: '5px 15px',
    border: '2px solid #EEEFF4',
    margin: '2px 2px 2px 0',
    color: '#444CE7',
    fontSize: '12px',
    fontFamily: 'ManropeRegular'
  },
  project_description: {
    color: '#545C6E',
    fontFamily: 'ManropeRegular',
    fontSize: '12px',
    margin: '10px 0'
  },
  responsibilities_wrapper: {
    margin: '7px 0'
  },
  project_responsibilities__title: {
    color: '#545C6E',
    fontSize: '15px',
    fontFamily: 'ManropeBold',
  },
  project_responsibilities: {
    color: '#545C6E',
    fontFamily: 'ManropeRegular',
    fontSize: '12px',
  },
  project_team__title: {
    color: '#545C6E',
    fontSize: '15px',
    fontFamily: 'ManropeBold'
  },
  project_team: {
    color: '#545C6E',
    fontFamily: 'ManropeRegular',
    fontSize: '12px'
  },
  course_item: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5px 0'
  },
  course_name: {
    color: '#303540',
    fontFamily: 'ManropeBold',
    fontSize: '15px'
  },
  course_license: {
    color: '#545C6E',
    fontSize: '13px',
    fontFamily: 'ManropeSemiBold'
  },
  course_issued: {
    color: '#545C6E',
    fontSize: '13px',
    fontFamily: 'ManropeSemiBold'
  },
  course_stack: {
    color: '#545C6E',
    fontSize: '13px',
    fontFamily: 'ManropeSemiBold'
  },
  certificate_item: {
    width: '80%',
    margin: '8px 0'
  },
  certificate_name: {
    color: '#303540',
    fontFamily: 'ManropeBold',
    fontSize: '14px',
    marginBottom: '5px'
  },
  certificate_link: {
    color: '#303540',
    fontSize: '13px',
    fontFamily: 'ManropeSemiBold',
    textDecoration: 'none'
  }
})
