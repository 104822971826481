import React from 'react'
import ReactPDF from '@react-pdf/renderer'

import { DownloadIcon, EditIcon } from '../../../../../icons'
import './cvPreviewStyles.scss'
import { IResume } from '../../../../../types/Resume/IResume'
import { ICurrentUser } from '../../../../../types'

type Props = {
  resume: IResume | undefined
  currentUser: ICurrentUser | null
  instance: ReactPDF.UsePDFInstance
  onEditUserCv: () => void
  downloadFileName: string
  downloadDoc: () => void
}

const DesktopCvNavbar: React.FC<Props> = ({
  resume,
  currentUser,
  instance,
  onEditUserCv,
  downloadFileName,
  downloadDoc,
}) => {
  return (
    <div className="cv__preview_wrapper">
      <div className="cv__preview_username">
        <div className="cv__preview_filename">{resume?.name && downloadFileName}</div>
      </div>
      <div className="cv__preview_buttons">
        {currentUser && (
          <>
            <button className="cv__preview_docdownload" onClick={downloadDoc}>
              Download Doc
            </button>
            <div className="cv__preview_edit" onClick={onEditUserCv}>
              <EditIcon />
            </div>
          </>
        )}
        <a href={instance.url as string} download={downloadFileName} className="cv__preview_download">
          <DownloadIcon color="white" />
        </a>
      </div>
    </div>
  )
}

export default DesktopCvNavbar
