import React from 'react'
import { isMobile } from 'react-device-detect'

const UsersIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.99933 0.428467C7.48744 0.428467 6.33156 
        1.05823 5.60063 2.05945C4.89704 3.02322 4.6464 4.24524 4.71072 5.3905C4.77519 6.53847 5.16065 
        7.71507 5.86178 8.62862C6.57508 9.55804 7.64387 10.2404 8.99933 10.2404C10.3548 10.2404 11.4236 
        9.55804 12.1369 8.62862C12.838 7.71507 13.2235 6.53847 13.2879 5.3905C13.3523 4.24524 13.1016 
        3.02322 12.398 2.05945C11.6671 1.05823 10.5112 0.428467 8.99933 0.428467ZM8.99933 8.2404C8.38069 
        8.2404 7.85922 7.94627 7.44837 7.41095C7.02535 6.85976 6.75278 6.08338 6.70757 5.27836C6.66221 
        4.47064 6.85025 3.73968 7.21597 3.23871C7.55436 2.7752 8.0984 2.42847 8.99933 2.42847C9.90026 2.42847 
        10.4443 2.7752 10.7827 3.23871C11.1484 3.73968 11.3364 4.47064 11.2911 5.27836C11.2459 6.08338 
        10.9733 6.85976 10.5503 7.41095C10.1394 7.94627 9.61797 8.2404 8.99933 8.2404ZM8.99975 
        11.0614C5.17643 11.0614 1.00106 13.1875 0.218581 17.5218C0.1365 17.9717 0.21353 18.4634 0.491041 
        18.8681C0.783928 19.2952 1.26843 19.5713 1.83922 19.5713H16.1607C16.7319 19.5713 17.2162 19.2948 
        17.5088 18.8676C17.7859 18.4628 17.8625 17.9717 17.7816 17.5231L17.7815 17.523C16.9992 13.188 12.8235 
        11.0614 8.99975 11.0614ZM15.7486 17.5713H2.25124C2.94024 14.7213 5.85509 13.0614 8.99975 13.0614C12.1443 
        13.0614 15.0593 14.7212 15.7486 17.5713Z" fill={!isMobile ? 'white' : 'black'}/>
    </svg>
  )
}

export default UsersIcon
