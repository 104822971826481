import React from 'react'

const HoursIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" 
        clipRule="evenodd" 
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 
        2 18 5.58172 18 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 
        0 10 0C15.5228 0 20 4.47715 20 10ZM11 4.75553C11 4.20324 10.5523 3.75553 10 3.75553C9.44771 3.75553 
        9 4.20324 9 4.75553V9.51743C9 10.0697 9.44772 10.5174 10 10.5174C10.5523 10.5174 11 10.0697 11 9.51743V4.75553Z"
        fill="#303540"/>
    </svg>
  )
}

export default HoursIcon
