import { IUpdateRequest } from 'types/Request/IUpdateRequest'
import API from '..'
import ROUTES from '../../../routes'

export type GetAdminRequest = {
  page: number
  limit: number
  date?: string
  is_pending?: boolean
  user_id?: number | string
  by_user_id?: number
  by_date?: string | null
  by_month?: string | null
}

export const getRequests = (page: number, day: string | null, month: string | null, year?: number) => {
  return API.get(ROUTES.REQUESTS + `?page=${page}`, {
    params: {
      by_day: day,
      by_month: month,
      by_year: year,
    },
  })
}

export const getAdminRequests = (params: GetAdminRequest) => {
  return API.get(ROUTES.ADMIN_REQUESTS, { params })
}

export const createRequest = (request: FormData) => API.post(ROUTES.REQUESTS, request)

export const updateRequest = (params: IUpdateRequest) => {
  return API.put(ROUTES.ADMIN_REQUESTS + `/${params.id}`, {
    request: {
      id: params.id,
      status: params.status,
      request_type: params.request_type,
    },
  })
}

export const deleteRequest = (id: number) => {
  return API.delete(ROUTES.REQUESTS + `/${id}`)
}
