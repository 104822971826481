import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, SubmitHandler } from 'react-hook-form'

import { requestsSchema } from '../../helpers/validationShema'
import { StatusType } from '../../enums/Types'
import { RequestLimits, Status, Type } from '../../enums/RequestEnums'
import { dateFormatYYMMDD } from '../../helpers/displayingDateFormat'
import { IOmitedRequest } from '../../containers/RequestsContainer/RequestsContainer'
import { useCreateAdminRequest, useCreateRequest } from '../../hooks/request/useCreateRequest'
import { IOptions } from '../../types/ISelectOptions'
import { getRequests } from '../../services/API/request'
import { Pages } from '../../enums/StartPage'
import { IPaginateRequest } from '../../types/Request/IPaginateRequests'
import { useAuth } from '../../context/auth'
import { isManager } from '../../helpers/accessHelpers'
import RequestForm from './RequestForm'
import {
  dayTime,
  defaultItemsInput,
  nullableRequestParams,
  paidLeave,
  sickLeaveVariants, tabDayDate, tabMonthDate,
  typeParams
} from '../../constants'


interface Props {
  handleClose: () => void
  page: number
  monthDate: Date
  filterDate: Date
  alignment: string
  setFilterUser: (user: IOptions | null) => void
  setYearRequests: React.Dispatch<React.SetStateAction<IPaginateRequest | undefined>>
  disableTwoWeeks?: boolean
  disableOneWeek?: boolean
}

const defaultValues = {
  user: NaN,
  job_status_id: NaN,
  id: NaN,
  user_id: NaN,
  note: '',
  hours: 8,
  sick_leave: false,
  unpaid_leave: false,
  work_status: false,
  vacation: false,
  paid_leave: false,
  paid_leave_type: null,
  end_date: '',
  start_date: '',
  status: 0,
  type: { value: typeParams[0].value, label: typeParams[0].label },
  dayTime: { value: dayTime[0].value, label: dayTime[0].label },
  paidLeave: { value: paidLeave[0].value, label: paidLeave[0].label },
}

const CreateRequest: React.FC<Props> = ({
  handleClose,
  page,
  monthDate,
  filterDate,
  alignment,
  setFilterUser,
  setYearRequests,
  disableOneWeek,
  disableTwoWeeks
}) => {
  const { currentUser } = useAuth()
  const isAdmin = isManager(currentUser)
  const [illnesFile, setIllnessFile] = useState()
  const [date, setDate] = useState([null, null])
  const startDate = date[0]
  const endDate = date[1]
  const [workOffDay, setWorkOffDay] = useState<Date | null>(null)
  const formatedWorkOffDay = dateFormatYYMMDD(workOffDay)
  const currentYear = new Date().getFullYear()
  const resolver = yupResolver(requestsSchema)
  const { register, handleSubmit, control, watch, formState: { errors }, unregister, getValues, reset } =
      useForm({ resolver, defaultValues })
  const type = watch('type')
  const dayTimePart = watch('dayTime')
  const isPending = false

  const createAdminRequest = useCreateAdminRequest()
  const createUserRequest = useCreateRequest()

  useEffect(() => {
    getRequests(
      Pages.START_PAGE,
      nullableRequestParams.day,
      nullableRequestParams.month,
      currentYear
    ).then((response) => {
      setYearRequests(response.data)
    })
  }, [])

  useEffect(() => {
    if (watch('type.value') === Type.WORK_OFF) {
      register('hours')
    } else {
      unregister('hours')
    }
  }, [unregister, watch('type.value')])

  getValues('user')

  const onSubmit: SubmitHandler<IOmitedRequest> = (data, event) => {
    const request = new FormData()
    const appendRequest = (request_type: StatusType, start_date: string, end_date: string) => {
      event?.preventDefault()
      request.append('note', data.note)
      request.append('start_date', start_date)
      request.append('end_date', end_date)
      request.append('hours', defaultItemsInput.workOff)
      request.append('status', Status.PENDING)
      request.append('paid_leave_type', data?.paidLeave.value)
      request.append('request_type', request_type)
      request_type === StatusType.VACATION && request.append('vacation_type', data?.vacationType?.value)
      illnesFile && request.append('illness_file', illnesFile)
    }

    switch (data.type.value) {
    case Type.SICK_LEAVE:
      if (watch('dayTime.label') === sickLeaveVariants.fullDay) {
        appendRequest(StatusType.FULL_DAY_SICK_LEAVE, dateFormatYYMMDD(startDate), dateFormatYYMMDD(endDate))
      } else {
        appendRequest(StatusType.HALF_DAY_SICK_LEAVE, formatedWorkOffDay, '')
      }
      break
    case Type.WORK_OFF:
      appendRequest(StatusType.WORK_OFF, formatedWorkOffDay, '')
      break
    case Type.UNPAID_LEAVE:
      if (watch('dayTime.label') === sickLeaveVariants.fullDay) {
        appendRequest(StatusType.UNPAID_LEAVE, dateFormatYYMMDD(startDate), dateFormatYYMMDD(endDate))
      } else {
        appendRequest(StatusType.HALF_DAY_UNPAID_LEAVE, formatedWorkOffDay, '')
      }
      break
    case Type.VACATION:
      appendRequest(StatusType.VACATION, dateFormatYYMMDD(startDate), dateFormatYYMMDD(endDate))
      break
    case Type.PAID_LEAVE:
      appendRequest(StatusType.PAID_LEAVE, dateFormatYYMMDD(startDate), dateFormatYYMMDD(endDate))
      break
    case Type.DAY_OFF:
      appendRequest(StatusType.DAY_OFF, dateFormatYYMMDD(startDate), dateFormatYYMMDD(endDate))
      break
    }
    {isAdmin ?
      createAdminRequest({
        request,
        page,
        limit: RequestLimits.REQUEST_DASHBOARD_LIMIT,
        is_pending: isPending,
        by_date: tabDayDate(alignment, filterDate),
        by_month: tabMonthDate(alignment, monthDate),
      }) :
      createUserRequest(request, tabDayDate(alignment, filterDate), tabMonthDate(alignment, monthDate))
    }
    setIllnessFile('')
    setFilterUser('')
    reset(
      { note: '',
        end_date: '',
        start_date: '',
        type: { value: typeParams[0].value, label: typeParams[0].label },
        dayTime: { value: dayTime[0].value, label: dayTime[0].label },
        paidLeave: { value: paidLeave[0].value, label: paidLeave[0].label },
      }
    )
    setDate([null, null])
    setWorkOffDay(null)
    handleClose()
  }

  const onIllnesFileChange = (event: { target: { files: React.SetStateAction<undefined>[] } }) => {
    setIllnessFile(event.target.files[0])
  }

  return (
    <form className="requests__inputs_form" onSubmit={handleSubmit(onSubmit)}>
      <RequestForm
        type={type}
        disableTwoWeeks={disableTwoWeeks}
        disableOneWeek={disableOneWeek}
        dayTimePart={dayTimePart}
        control={control}
        errors={errors}
        register={register}
        onIllnesFileChange={onIllnesFileChange}
        startDate={startDate}
        endDate={endDate}
        workOffDay={workOffDay}
        setWorkOffDay={setWorkOffDay}
        handleClose={handleClose}
        setDate={setDate}
      />
    </form>
  )
}

export default React.memo(CreateRequest)
