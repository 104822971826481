export const minDateDisable = (currentDate: Date | null) => {
  const currentDay = new Date()
  let day = 0
  if (currentDate?.getDay() === 0) {
    day = currentDay.setDate(currentDay.getDate() - currentDate?.getDay() - 6)
  } else {
    day = currentDay.setDate(currentDay.getDate() - currentDate?.getDay() + 1)
  }
  return day
}

export const getFirstDayOfWeek = (selectedDay: Date) => {
  const date = new Date(selectedDay)
  const day = date.getDay()
  const minDayOfWeek = date.getDate() - day + (day === 0 ? -6 : 1)

  return new Date(date.setDate(minDayOfWeek))
}
