export const switchByDate = [ 
  {
    name: 'Month',
    alignment: 'month'
  },
  {
    name: 'Day',
    alignment: 'day'
  }
]
