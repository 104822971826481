import * as React from 'react'
import ReactSelect, { 
  ActionMeta,
  components,
  ControlProps,
  GroupBase, 
  IndicatorSeparatorProps, 
  MultiValue, 
  MultiValueRemoveProps, 
  OptionsOrGroups, 
  PropsValue, 
  StylesConfig 
} from 'react-select'

import RemoveSelectIcon from 'icons/RemoveSelectIcon'

interface Props {
  placeholder?: string
  isClearable?: boolean
  options?: OptionsOrGroups<MyOptionType, GroupBase<MyOptionType>>
  prefixIcon?: JSX.Element
  onClick?: () => void
  disabled?: boolean
  style?: React.CSSProperties
  value?: PropsValue<MyOptionType>
  onChange?: ((newValue: MultiValue<MyOptionType>, actionMeta: ActionMeta<MyOptionType>) => void)
  defaultInputValue?: string
}

type MyOptionType = {
  label: string
  value: number
}

const indicatorSeparatorStyle = {
  display: 'none'
}

type IsMulti = true

const IndicatorSeparator = ({
  innerProps,
}: IndicatorSeparatorProps<MyOptionType, IsMulti>) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />
}

const MultiValueRemove = ({
  innerProps,
}: MultiValueRemoveProps<MyOptionType, IsMulti>) => {
  return <div {...innerProps} >
    <RemoveSelectIcon/>
  </div>
}

const customStyles: StylesConfig<MyOptionType, IsMulti> = {
  control: (css) => 
    ({ ...css, 
      padding: '10px', 
      borderRadius: '10px', 
      border: '1px solid #D4D9E2', 
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #D4D9E2',
      } }),
  menu: (css) => ({
    ...css,
    zIndex: 5,
  }),
  multiValue: (css) => ({
    ...css,
    padding: '2px 10px',
    borderRadius: '6px'
  }),
  option: (css, { isFocused, isSelected }) => ({ 
    ...css, 
    backgroundColor: isFocused || isSelected ? '#F9F9FA' : 'white',
    color: 'black' })
}

const MultiSelect: React.FC<Props> = ({ 
  placeholder, 
  isClearable, 
  options, 
  prefixIcon,
  value,
  onChange,
  defaultInputValue }) => {

  const SelectControl = ({ children, ...props }: ControlProps<MyOptionType, IsMulti>) => {
    const style = {
      marginLeft: '5px'
    }
    return (
      <components.Control {...props}>
        <span style={style}>
          {prefixIcon}
        </span>

        {children}
      </components.Control>
    )
  }

  return (
    <ReactSelect
      isMulti
      placeholder={placeholder} 
      isClearable={isClearable}
      options={options} 
      styles={customStyles}
      components={{ 
        Control: SelectControl, 
        IndicatorSeparator: IndicatorSeparator, 
        MultiValueRemove: MultiValueRemove }}
      value={value}
      onChange={onChange}
      defaultInputValue={defaultInputValue}
    />
  )
}

export default MultiSelect
