import React, { SVGProps } from 'react'

const CopyIcon = ({ width = '22', height = '22' }: SVGProps<SVGElement>) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536
            2.48043 2 2.73478 2 3V12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3
            13H4C4.55228 13 5 13.4477 5 14C5 14.5523 4.55228 15 4 15H3C2.20435 15 1.44129 14.6839 0.87868
            14.1213C0.31607 13.5587 0 12.7956 0 12V3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.31607
            2.20435 0 3 0H12C12.7956 0 13.5587 0.31607 14.1213 0.87868C14.6839 1.44129 15 2.20435 15 3V4C15 4.55228
            14.5523 5 14 5C13.4477 5 13 4.55228 13 4V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536
            12.2652 2 12 2H3ZM10 9C9.44771 9 9 9.44771 9 10V19C9 19.5523 9.44771 20 10 20H19C19.5523 20 20 19.5523
            20 19V10C20 9.44771 19.5523 9 19 9H10ZM7 10C7 8.34315 8.34315 7 10 7H19C20.6569 7 22 8.34315 22 10V19C22
            20.6569 20.6569 22 19 22H10C8.34315 22 7 20.6569 7 19V10Z"
      fill="#8992A6"
    />
  </svg>
)

export default CopyIcon
