import React, { createContext, ReactNode, useContext, useState } from 'react'

import { useDimensions } from 'hooks/useDimensions'

type ContextProps = {
  width: number
  height: number
  state: any
  setState: (v: any) => void
}

type State = { [k: string]: any }

type ProviderProps = {
  children: ReactNode
}

const UtilityContext = createContext({} as ContextProps)

export const UtilityProvider = ({ children }: ProviderProps) => {
  const [width, height] = useDimensions()

  const [state, _setState] = useState<State>({})
  const setState = (value: any) => _setState((ps: State) => ({ ...ps, ...value }))

  const value = { width, height, state, setState }

  return <UtilityContext.Provider value={value}>{children}</UtilityContext.Provider>
}

export const useUtility = () => useContext(UtilityContext)
export const UtilityConsumer = UtilityContext.Consumer
