import React from 'react'

import { useAuth } from 'context/auth'
import { SubRole } from 'types/SubRole'

type Props = {
  generalRoles?: Array<string>
  subRoles?: SubRole[] | string[]
  children?: React.ReactNode
}

const RolesWrapper: React.FC<Props> = ({ generalRoles, subRoles, children }) => {
  const { currentUser } = useAuth()

  if (!currentUser) return null

  const generalRolesVisibility = generalRoles?.includes(currentUser.role) && children
  const subRolesVisibility = currentUser?.sub_roles
    .map((name) => ({ name }))
    .some((role) => subRoles?.some((subRole) => subRole === role.name))

  return <>{generalRoles ? generalRolesVisibility : !subRolesVisibility ? <></> : children}</>
}

export default RolesWrapper
