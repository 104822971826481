import React, { useEffect, useState } from 'react'

import DashboardUsersTable from '../../../components/dashboard/desktop/DashboardUsersTable'
import { dateFormatDDMMYY } from '../../../helpers/displayingDateFormat'
import { dashboardDefaultParams } from '../../../constants'
import { useFetchProfiles } from '../../../hooks/profile/useFetchProfiles'
import { UserProfile } from '../../../types'

export type IDashboardTableProps = {
  isFired: boolean
  isHired: boolean
  date?: Date
  subRole?: string
  page: number
  setPage: (value: number) => void
}

const hiredUsersTableTitles = ['Full name', 'Hire date', 'Location', 'Manager']

const HiredUsersTable: React.FC<IDashboardTableProps> = ({ isFired, isHired, date, page, setPage, subRole }) => {
  const [profiles, setProfiles] = useState<UserProfile[]>([])
  const [totalPages, setTotalPages] = useState(1)
  const period = dateFormatDDMMYY(date as Date)
  const fetchProfilesHook = useFetchProfiles()
  const isUsersHired = true

  useEffect(() => {
    fetchProfilesHook(isUsersHired, dashboardDefaultParams.FIRED, page, period, setTotalPages, setProfiles)
  }, [page, period])

  return (
    <DashboardUsersTable
      tableTitles={hiredUsersTableTitles}
      profiles={profiles}
      isFired={isFired}
      isHired={isHired}
      totalPages={totalPages}
      page={page}
      setPage={setPage}
      subRole={subRole}
    />
  )
}

export default HiredUsersTable
