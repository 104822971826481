import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import actionCable from 'actioncable'
import store from './store'

const cable = actionCable.createConsumer(process.env.REACT_APP_WS_URL as string)

ReactDOM.render(
  <Provider store={store} >
    <BrowserRouter>
      <App cable={cable} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

reportWebVitals()
