import React, { useEffect, useState } from 'react'

import DashboardUsersTable from '../../../components/dashboard/desktop/DashboardUsersTable'
import { IDashboardTableProps } from './hiredUsersTable'
import { dateFormatDDMMYY } from '../../../helpers/displayingDateFormat'
import { dashboardDefaultParams } from '../../../constants'
import { useFetchProfiles } from '../../../hooks/profile/useFetchProfiles'
import { UserProfile } from '../../../types'

const firedUsersTableTitles = ['Full name', 'Release date', 'Location', 'Manager']

const FiredUsersTable: React.FC<IDashboardTableProps> = ({ isFired,  isHired, date, page, setPage }) => {
  const [profiles, setProfiles] = useState<UserProfile[]>([])
  const [totalPages, setTotalPages] = useState(1)
  const period = dateFormatDDMMYY(date as Date)
  const fetchProfilesHook = useFetchProfiles()
  const isFiredUsers = true

  useEffect(() => {
    fetchProfilesHook(dashboardDefaultParams.HIRED, isFiredUsers, page, period, setTotalPages, setProfiles)
  }, [page, period])

  return (
    <DashboardUsersTable
      tableTitles={firedUsersTableTitles}
      profiles={profiles}
      isFired={isFired}
      isHired={isHired}
      totalPages={totalPages}
      page={page}
      setPage={setPage}
    />
  )
}

export default FiredUsersTable
