import React, { useEffect, useState } from 'react'
import Select, { StylesConfig } from 'react-select'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'

import { BaseUser, Status, Project, User } from 'types'
import { getUsers } from 'services/API/admin/users'
import { fetchUsersSuccess } from 'store/usersSlice'
import { BENCH_MANAGER, BENCH_PROJECT, jobStatusesNames, usersActiveTypes } from '../../../constants'
import { renderProject, renderUser, renderManager } from '../../../helpers/renderName'
import { IOptions } from '../../../types/ISelectOptions'

interface OptionTypes {
  label: string | undefined
  value: number | undefined
}

type UsersFiltersProps = {
  users: Array<BaseUser>
  statuses: Array<Status>
  projects: Array<Project>
  userNames: User[]
  managerId: number | undefined
  projectId: number | undefined
}

const UsersFilters: React.FC<UsersFiltersProps> = ({
  projects,
  statuses,
  users,
  userNames,
  managerId,
  projectId
}) => {
  const projectFromNotification = projectId &&
    {
      label: renderProject(projectId, projects),
      value: projectId
    } || undefined
  const managerFromNotification = managerId &&
    {
      label: renderUser(managerId, userNames),
      value: managerId
    } || undefined

  const [active, setActive] = useState('true')
  const [userId, setUser] = useState<number | undefined>(undefined)
  const [manager, setManager] = useState(managerFromNotification)
  const [statusId, setStatus] = useState<number | undefined>(undefined)
  const [project, setProject] = useState(projectFromNotification)
  const dispatch = useDispatch()

  useEffect(() => {
    setProject(projectFromNotification)
    setManager(managerFromNotification)
  },[managerId, projectId])

  const activeStatuses = usersActiveTypes?.map(type => ({
    label: type.name,
    value: type.value,
  }))
  const teammates = users?.filter(u => u.full_name != BENCH_MANAGER).map(user => ({
    label: user.full_name,
    value: user.id
  })).sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
  const allProjects = projects?.filter(project => project.name != BENCH_PROJECT).map(item => ({
    label: item.name,
    value: item.id
  }))
  const jobStatuses = statuses?.filter(status => status.name != jobStatusesNames.FREE).map(item => ({
    label: item.name,
    value: item.id
  }))

  const managers = projects?.map((project) => ({
    value: project.manager_id,
    label: renderManager(project?.id, projects, users)
  }))
  const uniqueManagers = managers?.filter(
    (value, index, array) => array.findIndex((val) =>
      (val.label != undefined && val.label != BENCH_MANAGER) && val.label === value.label) === index
  )

  const onUserChange = (user_id: number | undefined) => {
    const managerId = manager?.value
    const projectId = project?.value
    setUser(user_id)
    getUsers(active, user_id, projectId, statusId, managerId).then((response) => {
      dispatch(fetchUsersSuccess(response?.data))
    })
  }
  const onProjectChange = (project: IOptions) => {
    const managerId = manager?.value
    const projectId = project?.value
    setProject(project)
    getUsers(active, userId, projectId, statusId, managerId).then((response) => {
      dispatch(fetchUsersSuccess(response?.data))
    })
  }
  const onJobStatusChange = (status_id: number | undefined) => {
    const managerId = manager?.value
    const projectId = project?.value
    setStatus(status_id)
    getUsers(active, userId, projectId, status_id, managerId).then((response) => {
      dispatch(fetchUsersSuccess(response?.data))
    })
  }
  const onActiveStatusChange = (activeStatus: string ) => {
    const managerId = manager?.value
    const projectId = project?.value
    setActive(activeStatus)
    getUsers(activeStatus, userId, projectId, statusId, managerId).then((response) => {
      dispatch(fetchUsersSuccess(response?.data))
    })
  }
  const onManagerChange = (selectedManager: IOptions) => {
    setManager(selectedManager)
    getUsers(active, userId, project?.value, statusId, selectedManager?.value).then((response) => {
      dispatch(fetchUsersSuccess(response?.data))
    })
  }

  const styles: StylesConfig<OptionTypes, false> = {
    container: (base) => ({
      ...base,
      width: 230,
      marginLeft: 10,
    }),
    option: (base) => ({
      ...base,
      color: 'black',
      backgroundColor: 'white',
      '&::selection': {
        backgroundColor: 'white',
        color: 'black',
      },
      '&:hover': {
        backgroundColor: '#dfe8ff',
        color: 'black',
      }
    })
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Select
          placeholder="Active"
          options={activeStatuses}
          onChange={(option) => onActiveStatusChange(option?.value)}
          styles={styles}
        />
        <Select
          isClearable={true}
          placeholder="Users"
          options={teammates}
          onChange={(option) => onUserChange((option as OptionTypes)?.value)}
          styles={styles}
        />
        <Select
          isClearable={true}
          placeholder="Project"
          options={allProjects}
          value={project}
          onChange={(option) => onProjectChange(option as IOptions)}
          styles={styles}
        />
        <Select
          isClearable={true}
          placeholder="Status"
          options={jobStatuses}
          onChange={(option) => onJobStatusChange((option as OptionTypes)?.value)}
          styles={styles}
        />
        <Select
          isClearable={true}
          placeholder="Manager"
          value={manager}
          onChange={(option) => onManagerChange(option as IOptions)}
          options={uniqueManagers}
          styles={styles}
        />
      </Box>
    </>
  )
}

export default UsersFilters
