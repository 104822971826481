import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import {
  fetchNotifications,
  fetchNotificationsFailure,
  fetchNotificationsSuccess
} from '../../store/notificationsSlice'
import { getNotifications } from '../../services/API/notifications'

export const useFetchNotifications = () => {
  const dispatch = useDispatch()

  return () => {
    dispatch(fetchNotifications())
    getNotifications()
      .then((response) => {
        dispatch(fetchNotificationsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchNotificationsFailure(error))
        dangerNotify('Server error: unable to download notifications')
      })
  }
}
