import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { useCreateResumeBook } from '../../../../../hooks/resume/books/useCreateResumeBook'
import CvBookForm from './CvBookForm'
import AddFormItemButton from '../FormButtons/AddFormItemButton'

export interface IBookInputs {
  title: string
  author: string
}

interface IProps {
  cvId: string | undefined
}

const CvAddBook: React.FC<IProps> = ({ cvId }) => {
  const [image, setImage] = useState()
  const createBook = useCreateResumeBook()

  const { register, handleSubmit, reset } = useForm<IBookInputs>({
    defaultValues: {
      title: '',
      author: ''
    }
  })

  const onSubmit: SubmitHandler<IBookInputs> = (data, event) => {
    event?.preventDefault()
    const book = new FormData()
    book.append('title', data.title)
    book.append('author', data.author)
    book.append('resume_id', cvId as string)
    image && book.append('image', image)

    createBook(book, Number(cvId))
    setImage(undefined)
    reset()
  }

  const onImageChange = (event: { target: { files: React.SetStateAction<undefined>[] } }) => {
    setImage(event.target.files[0])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvBookForm register={register} onImageChange={onImageChange} />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <AddFormItemButton isDisabled={false}/>
      </Box>
    </form>
  )
}

export default CvAddBook
