import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useParams } from 'react-router-dom'

import { User } from '../../../../../types'
import Modal from '../../../../reusable/Modal/index.tsx'
import CvAddBook from './CvAddBook'
import { useDeleteResumeBook } from '../../../../../hooks/resume/books/useDeleteResumeBook'
import CvUpdateBook from './CvUpdateBook'
import { IResume } from '../../../../../types/Resume/IResume'
import { cvNavLinks } from '../../../../../constants'
import CvNavButtons from '../CvNavButtons'
import UsersCv from '../UsersCv'
import { IResumeBook } from '../../../../../types/Resume/IResumeBook'
import { useGetUserCv } from '../../../../../hooks/resume/useGetUserCv'

const CvBooks = () => {
  const { resumeId } = useParams()
  const [open, setOpen] = useState(false)
  const [book, setBook] = useState<IResumeBook>()
  const user = useSelector((state: { users: { showData: User } }) => state.users.showData)
  const resume = useSelector((state: { resumes: { showData: IResume } }) => state.resumes.showData)
  const getUserCv = useGetUserCv()
  const isResumeExist = user?.has_cv
  const deleteBook = useDeleteResumeBook()
  const nextPath = `${user.slug}`
  const prevPath = cvNavLinks.COURSES

  useEffect(() => {
    if(resumeId) {
      getUserCv(+resumeId)
    }
  },[resumeId])

  const onEditBook = (book: IResumeBook) => {
    setOpen(!open)
    setBook(book)
  }

  const onDeleteBook = (id: number | undefined) => {
    deleteBook(id as number, Number(resumeId))
  }

  const editBook = <CvUpdateBook setOpen={setOpen} book={book as IResumeBook} cvId={resumeId} />

  return (
    <div className="cv__form" >
      <UsersCv resumeId={resume?.id} userId={resume?.user_id}/>
      <div className="cv__form_inner">
        <h4 className="cv__form_title">Books</h4>
      </div>
      <Box sx={{ display: 'flex', width: '95%', gap: '10px' }} >
        <div className="leftSide_section">
          <Box sx={{ width: '60%' }}>
            <CvAddBook cvId={resumeId} />
          </Box>
        </div>
        <Box sx={{
          display: 'flex',
          width: '50%',
          minHeight: '600px',
          flexDirection: 'column',
          marginBottom: 'auto',
          gap: '15px'
        }} >
          <Box sx={{
            display: 'flex',
            maxHeight: '500px',
            flexWrap: 'wrap',
            gap: '15px',
            padding: '15px',
            marginBottom: '30px'
          }}>
            {resume?.books?.map(book => (
              <>
                <div key={book.id} className="fullwrap">
                  <img src={book.cover} width="180px" height="180px" alt="cover.pic"/>
                  <div className="fullcap">
                    <div>
                      {book.title}
                    </div>
                    {book.author}
                  </div>
                  <Box sx={{ display: 'flex' }}>
                    <IconButton onClick={() => onEditBook(book)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteBook(book.id)} >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                </div>
              </>
            ))}
            {open && <Modal title={'Edit book'} component={editBook} open={open} handleClose={() => setOpen(false)} />}
          </Box>
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'end', marginTop: 'auto' }}>
            <CvNavButtons resumeId={resumeId} nextPath={nextPath} prevPath={prevPath} isResumeExist={isResumeExist} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CvBooks
