import * as React from 'react'
import Button from '@mui/material/Button'
import { SxProps, Theme } from '@mui/material'

interface Props {
  className?: string
  text?: string
  type?: 'button' | 'submit' | 'reset'
  variant?: 'contained' | 'outlined' | 'text'
  sx?: SxProps<Theme>
  size?: 'small' | 'large' | 'medium'
  onClick?: () => void
  disabled?: boolean
}

const ButtonMaterial: React.FC<Props> = ({ className, text, type, variant, onClick, disabled, sx, size }) => {
  return (
    <Button
      className={className}
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
      size={size}
    >
      {text}
    </Button>
  )
}

export default ButtonMaterial
