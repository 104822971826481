import React, { SVGProps } from 'react'

type Props = {
  onHandleBackToProfile?: () => void
}

const ArrowBack: React.FC<Props & SVGProps<SVGSVGElement>> = ({ onHandleBackToProfile, ...rest }) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onHandleBackToProfile}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.51478 12.0002L12.7071 20.1925L11.2929
       21.6068L1.39339 11.7073L0.686279 11.0002L1.39339 10.293L11.2929 0.393555L12.7071
        1.80777L4.51463 10.0002H20.4853V12.0002H4.51478Z"
        fill="#303540"
      />
    </svg>
  )
}

export default ArrowBack
