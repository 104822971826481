import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { updateCvCourse } from '../../../services/API/admin/resume'
import { IResumeCourse } from '../../../types/Resume/IResumeCourse'
import { useGetUserCv } from '../useGetUserCv'

export const useUpdateCvCourse = () => {
  const getUserCv = useGetUserCv()

  return (course: IResumeCourse, courseId: number, cvId: number ) => {
    updateCvCourse(course, courseId)
      .then(() => {
        successNotify('Course updated')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Update error'))
  }
}
