import React from 'react'
import { isMobile } from 'react-device-detect'

const EmploymentIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 6H3C2.44772 6 2 6.44771 2 7V17C2 17.5523 
        2.44772 18 3 18H17C17.5523 18 18 17.5523 18 17V7C18 6.44772 17.5523 6 17 6H14H12H8H6ZM14 
        4H17C18.6569 4 20 5.34315 20 7V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V7C0 
        5.34314 1.34315 4 3 4H6V3C6 1.34315 7.34315 0 9 0H11C12.6569 0 14 1.34315 14 3V4ZM12 4V3C12 
        2.44772 11.5523 2 11 2H9C8.44771 2 8 2.44772 8 3V4H9H10H11H12Z" fill={!isMobile ? 'white' : 'black'}/>
    </svg>
  )
}

export default EmploymentIcon
