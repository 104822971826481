import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  indexData: [],
  error: {},
  pages: 1,
  total_hours: 0,
  count: 0,
}

const monthlyReportSlice = createSlice({
  name: 'monthly report',
  initialState,
  reducers: {
    fetchReport(state) {
      state.isLoading = true
    },
    fetchReportSuccess(state, action) {
      state.indexData = action.payload.paginate_report
      state.isLoading = false
      state.pages = action.payload.pages
      state.count = action.payload.count
      state.total_hours = action.payload.hours_sum
    },
    fetchReportFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default monthlyReportSlice.reducer
export const { fetchReport, fetchReportFailure, fetchReportSuccess } = monthlyReportSlice.actions
