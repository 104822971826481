import React from 'react'
import { Link } from 'react-router-dom'

import { renderProject, renderUserName } from '../../../helpers/renderName'
import { DashboardManagerListProps } from './desktopManagersList'
import { BENCH_PROJECT } from '../../../constants'
import { Contract } from '../../../types'

const MobileManagersList: React.FC<DashboardManagerListProps> = ({
  usersNames,
  developers,
  managersContracts,
  projects
}) => {
  const developersOnProjects = (contract: Contract) =>
    developers?.map(developer => developer.available_contracts.some(item =>
      item.project_id === contract.project_id) && developer.id).filter(developer => developer !== false)

  const renderManagerProjects = (contract: Contract) => (
    renderProject(contract.project_id, projects) !== BENCH_PROJECT && (
      <span key={contract.id}>
        {renderProject(contract.project_id, projects)}{', '}
      </span>
    ))

  return (
    <div className="mobile_dashboard__wrapper">
      <div className="mobile_managers__card-list">
        {managersContracts?.map(manager => {
          return(
            <div className="mobile_managers__card" key={manager.id}>
              <Link to={`/users/${manager.id}`} className="mobile_managers__card-managerName">
                {renderUserName(manager.id as number, usersNames)}
              </Link>
              <span className="mobile_managers__card-title">Projects:</span>
              <div className="mobile_managers__card-items">
                <div>
                  {manager?.available_contracts.map(contract =>
                    renderManagerProjects(contract)
                  )}
                </div>
                <div className="mobile_managers__card-title">Developers:</div>
                <div>
                  {manager?.available_contracts.map(contract => (
                    renderProject(contract.project_id, projects) !== BENCH_PROJECT && (
                      developersOnProjects(contract).map(developer => (
                        renderUserName(developer as number, usersNames) + ', '
                      ))
                    )
                  ))}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MobileManagersList
