import { useGetAdminUserRecords, useGetRecords } from './useGetRecords'
import dangerNotify from '../../helpers/dangerNotify'
import { deleteRecord } from '../../services/API/record'
import { getRecordsParams } from '../../constants'
import successNotify from '../../helpers/successNotify'

export const useDeleteRecord = () => {
  const getRecordsHook = useGetRecords()

  return (id: number) => {
    deleteRecord(id)
      .then(() => {
        successNotify('Record deleted')
        getRecordsHook(getRecordsParams.project_id, getRecordsParams.year, getRecordsParams.month)
      })
      .catch((error) => dangerNotify(error.response.data.error))
  }
}

export const useDeleteAdminRecord = () => {
  const getAllRecords = useGetAdminUserRecords()

  return (id: number) => {
    deleteRecord(id)
      .then(() => {
        successNotify('Record deleted')
        getAllRecords()
      })
      .catch((error) => dangerNotify(error.response.data.error))
  }
}
