import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import UsersList from '../UsersList'
import dangerNotify from 'helpers/dangerNotify'
import { getJobStatuses } from 'services/API/admin/job-statuses'
import { User, Project, BaseUser } from 'types'
import { useGetUsers, useGetUsersNames } from 'hooks/user/useGetUsers'
import Modal from '../../reusable/Modal/index.tsx'
import CreateUser from '../CreateUser'
import useToggle from 'hooks/useToggle'
import ReactButton from '../../reusable/ReactButton'
import { getUsersParams } from '../../../constants'
import UsersFilters from './UsersFilters'
import { createUser } from '../../../services/API/admin/users'
import Loader from 'components/reusable/Loader'
import { getProfileSuccess } from '../../../store/profileSlice'
import { getUserSuccess } from '../../../store/usersSlice'
import { fetchChildrenSuccess } from '../../../store/childrenSlice'
import successNotify from 'helpers/successNotify'

interface Location {
  state: { managerId: number | undefined, projectId: number | undefined } | null
}

const ManageUsers = () => {
  const [statuses, setStatuses] = useState([])
  const [open, toggle] = useToggle(false)
  const location: Location = useLocation()
  const managerIdFromNotification = location?.state?.managerId
  const projectIdfromNotification = location?.state?.projectId
  const users = useSelector((state: { users: { indexData: User[] } }) => state.users.indexData)
  const usersLoading = useSelector((state: { users: { isLoading: User[] } }) => state.users.isLoading)
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const getUsers = useGetUsers()
  const getUsersNames = useGetUsersNames()
  const dispatch = useDispatch()
  window.history.replaceState({}, document.title)

  const onLinkClickHandler = () => {
    dispatch(getProfileSuccess({}))
    dispatch(getUserSuccess({}))
    dispatch(fetchChildrenSuccess({}))
  }

  useEffect(() => {
    getJobStatuses()
      .then((response) => {
        setStatuses(response.data)
      })
      .catch((error) => dangerNotify(error.response.data.error))
  }, [])

  useEffect(() => {
    if (managerIdFromNotification && projectIdfromNotification) {
      getUsers(
        getUsersParams.active,
        getUsersParams.user_id,
        projectIdfromNotification,
        getUsersParams.status_id,
        managerIdFromNotification
      )
    } else {
      getUsers(
        getUsersParams.active,
        getUsersParams.user_id,
        getUsersParams.project_id,
        getUsersParams.status_id,
        getUsersParams.manager_id
      )
    }
  }, [managerIdFromNotification, projectIdfromNotification])
  //TODO move it to hook
  function newUser(data: BaseUser | FormData) {
    createUser(data)
      .then(() => {
        successNotify('User created!')
        getUsers(
          getUsersParams.active,
          getUsersParams.user_id,
          getUsersParams.project_id,
          getUsersParams.status_id,
          getUsersParams.manager_id
        )
        getUsersNames()
        toggle()
      })
      .catch((error) => {
        console.log(error.response)
        dangerNotify(('Email ' + error.response.data?.email[0]) || 'Something went wrong')
      })
    getJobStatuses().then((response) => {
      setStatuses(response.data)
    })
  }

  const createUserComponent = <CreateUser handleSubmit={newUser} closeModal={toggle} />

  return (
    <div>
      <div className="manage-users__row">
        <div className="manage-users__col">
          <React.Fragment>
            <ReactButton text="Add User" onClick={toggle} variant="contained" />
            {open && <Modal title="Create User" component={createUserComponent} open={open} handleClose={toggle} />}
          </React.Fragment>
          <UsersFilters
            projects={projects}
            statuses={statuses}
            users={users}
            managerId={managerIdFromNotification}
            projectId={projectIdfromNotification}
            userNames={usersNames}
          />
        </div>
      </div>
      <div className="manage-users__row">
        <div className="manage-users__table">
          <h3>Users List</h3>
          {usersLoading ? <Loader/> :
            <UsersList users={users} statuses={statuses} projects={projects} onLinkClickHandler={onLinkClickHandler} />
          }
        </div>
      </div>
    </div>
  )
}

export default React.memo(ManageUsers)
