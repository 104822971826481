import Close from '@mui/icons-material/Close'
import { Button } from '@mui/material'
import { ArrowBack } from 'icons'
import React, { CSSProperties, ReactNode, useEffect } from 'react'

interface Props {
  title?: string
  open: boolean
  component: ReactNode
  style?: CSSProperties
  handleClose: () => void
}

const Modal = ({ title, open, component, style, handleClose }: Props) => {
  useEffect(() => {
    if (open) document.body.style.setProperty('overflow', 'hidden')
    if (!open) document.body.style.removeProperty('overflow')

    return () => {
      document.body.style.removeProperty('overflow')
    }
  }, [open])

  return open ? (
    <div className="modal__backdrop" onClick={handleClose}>
      <div className="modal__content-container" style={style} onClick={(e) => e.stopPropagation()}>
        <div className="modal__header">
          <Button className="modal__close-button" onClick={handleClose}>
            <Close htmlColor="black" className="d-none d-sm-block" />
            <ArrowBack width="24" height="24" className="d-sm-none" />
          </Button>
          {title && <h3 className="modal__title">{title}</h3>}
        </div>
        {component}
      </div>
    </div>
  ) : null
}

export default Modal
