import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Container, Button } from 'reactstrap'
import { isEmpty } from 'lodash'
import { isMobile } from 'react-device-detect'

import { useGetVacancy } from '../../hooks/vacancies/useGetVacancy'
import { CandidatesTable, CandidateForm, CandidateFilters } from 'components/candidates'
import { AddCandidate, PlusIcon } from '../../icons'
import { useFetchCandidates } from '../../hooks/candidate/useFetchCandidates'
import { CandidatesMobileTable } from '../../components/mobile/candidates'
import { Pages } from '../../enums/StartPage'
import CustomPagination from '../../components/reusable/Pagination/Pagination'
import { useUpdateCandidate } from '../../hooks/candidate/useUpdateCandidate'
import { useFetchCandidateNames } from '../../hooks/candidate/useFetchCandidateNames'

const VacancyShow = () => {
  const [isFormOpen, setFormOpen] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const vacancy = useSelector(({ vacancies: { showData } }) => showData)
  const {
    indexData: candidates,
    indexNames: candidateNames,
    count: candidatesCount,
    pages: candidatePages,
    filterData: { filter: filterParams }
  } = useSelector(({ candidates }) => candidates)

  const recruitPage =
    localStorage.getItem('page') === ('NaN' || undefined) ? Pages.START_PAGE : parseInt(localStorage.getItem('page'))
  const [page, setPage] = React.useState(recruitPage)
  const { vacancyId } = useParams()
  const getVacancy = useGetVacancy()
  const fetchCandidates = useFetchCandidates()
  const fetchCandidateNames = useFetchCandidateNames()
  const toggleForm = () => setFormOpen(!isFormOpen)
  const updateCandidate = useUpdateCandidate()

  useEffect(() => {
    getVacancy(vacancyId)
    fetchCandidateNames(vacancyId)
  }, [vacancyId])

  useEffect(() => {
    localStorage.setItem('page', page)
    fetchCandidates(vacancyId, filterParams, page)
  }, [page, filterParams])

  useEffect(() => {
    isUpdated && fetchCandidates(vacancyId, filterParams, page)
    setIsUpdated(false)
  }, [isUpdated])

  const onUpdateCandidate = (params) => {
    updateCandidate(params)
    setTimeout(() => setIsUpdated(true), 500)
  }

  return (
    <Container fluid="xxl">
      {!isMobile && (
        <div className="page-top with-filters">
          <CandidateFilters
            candidateNames={candidateNames}
            setPage={setPage}
          />
          <Button onClick={toggleForm} size={'lg'}>
            Add New Candidate
            <div style={{ display: 'inline-block', marginLeft: '10px' }}>
              <PlusIcon />
            </div>
          </Button>
        </div>
      )}
      {isMobile && (
        <>
          <div onClick={toggleForm} className="candidates-add-button-mobile">
            <AddCandidate />
          </div>
        </>
      )}
      {!isEmpty(vacancy) && isMobile ? (
        <CandidatesMobileTable onUpdateCandidate={onUpdateCandidate} candidates={candidates} vacancy={vacancy} />
      ) : (
        <CandidatesTable
          onUpdateCandidate={onUpdateCandidate}
          candidates={candidates}
          vacancy={vacancy}
          candidatesCount={candidatesCount}
          page={page}
        />
      )}
      <CandidateForm isNewRecord toggle={toggleForm} isOpen={isFormOpen} vacancyId={vacancyId} />
      <CustomPagination totalPages={candidatePages} page={page} setPage={setPage} />
    </Container>
  )
}

export default VacancyShow
