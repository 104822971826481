import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { ReadyRecord } from 'types/ReadyRecord'
import { Category } from 'types/Category'
import { User, Project } from '../../../types'
import { tableHeadStyles } from 'utilts/styles'
import { recordsTableRows } from '../../../constants'
import TableItem from '../TableItem'
import { webRoutes } from '../../../lib'

interface Props {
  records: ReadyRecord[]
  categories: Category[]
  currentUser: User | undefined
  projects: Project[]
}

const RecordsTable: React.FC<Props> = ({ records, categories, currentUser, projects }) => {
  const location = useLocation()
  const mainPage = location.pathname == webRoutes.ADMIN_MAIN || location.pathname == webRoutes.SUBMIT_HOURS
  const reportPage = location.pathname === webRoutes.MY_REPORT

  return (
    <TableContainer style={{ height: '85%' }}>
      <Table>
        <TableHead className="requests-table__title">
          <TableRow>
            {recordsTableRows.map((cellItem: string, index) => {
              return (
                <TableCell key={index} align="left" sx={tableHeadStyles}>
                  {cellItem}
                </TableCell>
              )
            })}
            {reportPage &&
              <TableCell align={mainPage ? 'left' : 'center'} sx={tableHeadStyles}>
              Manager
              </TableCell>
            }
            <TableCell align={mainPage ? 'left' : 'center'} sx={tableHeadStyles}>
              Hours
            </TableCell>
            {mainPage ? (
              <TableCell align="left" sx={tableHeadStyles}>
                Options
              </TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((record) =>
            <TableItem
              record={record}
              key={record.id}
              categories={categories}
              currentUser={currentUser}
              projects={projects}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(RecordsTable)
