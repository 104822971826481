import React, { useState } from 'react'
import TableRow from '@mui/material/TableRow'
import { TableCell } from '@mui/material'
import { useSelector } from 'react-redux'

import { Project } from 'types/Project'
import { Category } from 'types/Category'
import { ReadyRecord } from 'types/ReadyRecord'
import { useDeleteAdminRecord, useDeleteRecord } from 'hooks/record/useDeleteRecord'
import { tableCellStyles } from 'utilts/styles'
import DeleteIcon from '../../reusable/DeleteIcon'
import ModalContainer from 'containers/ModalContainer'
import { User } from 'types'
import { useAuth } from 'context/auth'
import { isManager } from '../../../helpers/accessHelpers'
import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import { IOptions } from 'types/ISelectOptions'
import { StatusType } from 'enums/Types'

interface Props {
  record: ReadyRecord
  categories: Category[]
  projects: Project[]
  userId: IOptions | undefined
  setUserId: React.Dispatch<React.SetStateAction<IOptions | undefined>>
}

const AdminTableItem: React.FC<Props> = ({ record, categories, projects, userId, setUserId }) => {
  const { currentUser } = useAuth()
  const [chosenUser, setChosenUser] = useState()
  const deleteRecordHook = isManager(currentUser) ? useDeleteAdminRecord() : useDeleteRecord()

  const user = useSelector((state: { users: { showData: User } }) => state.users.showData)
  const users = useSelector((state: { users: { indexData: User[] } }) => state.users.indexData)

  const findUser = (value) => {
    const userFromRecords = users?.find((item) => item.id === value)
    setChosenUser(userFromRecords)
  }

  const deleteRecords = (id: number) => (): void => {
    deleteRecordHook(id, user?.id)
    setUserId(null)
  }

  return (
    <TableRow id={record.id.toString()}>
      <TableCell align="left" sx={tableCellStyles}>
        {record.user}
      </TableCell>
      <TableCell align="left" sx={tableCellStyles}>
        {record.project}
      </TableCell>
      <TableCell align="left" sx={tableCellStyles}>
        {record.category}
      </TableCell>
      <TableCell align="left" sx={tableCellStyles}>
        {record.description}
      </TableCell>
      <TableCell align="left" sx={tableCellStyles}>
        {dateFormatDDMMYY(record.date)}
      </TableCell>
      <TableCell align="left" sx={tableCellStyles}>
        {record.hours}
      </TableCell>
      <TableCell align="left" sx={tableCellStyles} onClick={() => findUser(record.user_id)}>
        <div className={'action_icon_admin'} >
          {record?.record_type === StatusType.REGULAR ? <>
            <ModalContainer 
              record={record} 
              projects={projects}
              categories={categories} 
              userId={userId}
              setUserId={setUserId}
              chosenUser={chosenUser}/>
            <div onClick={deleteRecords(record.id)}>
              <DeleteIcon />
            </div> 
          </> : ''}
        </div>
      </TableCell>
    </TableRow>
  )
}

export default React.memo(AdminTableItem)
