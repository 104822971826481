import React, { ReactNode } from 'react'
import { renderProject } from '../../../helpers/renderName'
import { Contract, Project, User } from '../../../types'
import { BENCH_PROJECT, userRoles } from '../../../constants'

interface IProps {
    contract: Contract
    projects: Project[]
    user: User
    renderFunc: (id: number | undefined, projects: Project[] | undefined, users: User[]) => ReactNode
    value: string
    users: User[]
}
export const DisplayingItemInList: React.FC<IProps> = ({
  contract,
  projects,
  user,
  renderFunc,
  value,
  users
}) => {
  const isDeveloperContract = contract.user_id === user.id && user.role === userRoles.DEFAULT.id

  return (
    <>
      {(renderProject(contract.project_id, projects) != BENCH_PROJECT) &&
          (user.available_contracts.length >= 1 && contract.user_id === user.id) ?
        <div className="user-list__multiply">
          {contract && renderFunc(contract.project_id, projects, users)}
        </div> :
        (renderProject(contract.project_id, projects) === BENCH_PROJECT) &&
          (user.available_contracts.length === 1) && isDeveloperContract &&
            <div className="user-list__multiply">
              {value}
            </div>
      }
    </>
  )
}
