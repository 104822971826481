import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'
import { createInventory } from '../../services/API/admin/inventories'
import { Inventory } from '../../types'
import { addInventory, addInventoryFailure, addInventorySuccess } from '../../store/inventoriesSlice'
import { useGetInventories } from './useGetInventories'

export const useCreateInventory = () => {
  const dispatch = useDispatch()
  const getInventory = useGetInventories()

  return (profileId: number, inventory: Inventory) => {
    dispatch(addInventory())
    createInventory(inventory)
      .then((response) => {
        dispatch(addInventorySuccess(response.data))
        successNotify('Inventory created')
        getInventory(profileId)
      })
      .catch((error) => {
        dispatch(addInventoryFailure(error))
        dangerNotify('Something went wrong!')
      })
  }
}
