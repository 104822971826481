import { createSlice } from '@reduxjs/toolkit'

import { Inventory } from '../types'

const initialState = {
  indexData: <Inventory[]>[],
  showData: <Inventory>{},
  error: {},
  isLoading: true,
}

const inventoriesSlice = createSlice({
  name: 'inventories',
  initialState,
  reducers: {
    fetchInventories(state) {
      state.isLoading = true
    },
    fetchInventoriesSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchInventoriesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    addInventory(state) {
      state.isLoading = true
    },
    addInventorySuccess(state, action) {
      state.indexData.push(action.payload)
      state.isLoading = false
    },
    addInventoryFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    updateInventory(state) {
      state.isLoading = true
    },
    updateInventorySuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    updateInventoryFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    removeInventory(state) {
      state.isLoading = true
    },
    removeInventorySuccess(state, action) {
      state.indexData = state.indexData.filter((inventory: Inventory) => inventory.id !== action.payload)
      state.isLoading = false
    },
    removeInventoryFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default inventoriesSlice.reducer
export const {
  fetchInventories,
  fetchInventoriesSuccess,
  fetchInventoriesFailure,
  addInventory,
  addInventorySuccess,
  addInventoryFailure,
  removeInventory,
  removeInventorySuccess,
  removeInventoryFailure,
  updateInventory,
  updateInventorySuccess,
  updateInventoryFailure
} = inventoriesSlice.actions
