import * as React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { validationSchema } from 'helpers/validationShema'
import ReactInput from '../../reusable/ReactInput'
import ReactForm from '../../reusable/ReactForm'
import { Col, Container, Row } from 'reactstrap'
import CustomButton from 'components/custom/CustomButton/CustomButton'
import { useAuth } from 'context/auth'
import { Session } from 'types/Session'
import { useCreateSession } from 'hooks/session/useCreateSession'
import { webRoutes } from '../../../lib'
import { isAdmin, isManager } from 'helpers/accessHelpers'

type LoginForm = {
  email: string
  password: string
}

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LoginForm>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(validationSchema),
  })


  const navigate = useNavigate()
  const [showPassword, setShowPassword] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const createSession = useCreateSession()

  const onSubmit = (params: Session) => {
    createSession({
      email: params.email,
      password: params.password,
    }, setLoading)
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const { currentUser } = useAuth()

  const showButton = () => watch('email').length >= 5 && watch('password').length >= 6

  React.useEffect(() => {
    currentUser?.id && 
        (isManager(currentUser) || isAdmin(currentUser) ? 
          navigate(webRoutes.DASHBOARD_USERS_LIST) : navigate(webRoutes.SUBMIT_HOURS))
  })

  return (
    <Container className="login-form">
      <Row className="login-form__container">
        <Col className="login-form__col">
          <ReactForm onSubmit={handleSubmit(onSubmit)}>

            <div className="login-form__form-group">
              <h4 className="login-form__form-label">Log in</h4>
              <ReactInput
                focus={true}
                registerControl="email"
                error={errors.email}
                helperText={errors.email?.message}
                type="email"
                label="Email"
                autoComplete="true"
                register={register}
                size="large"
              />

              <FormControl>
                <InputLabel className="login-form__password_label">Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText className="login-form__helper_label">{errors.password?.message}</FormHelperText>
              </FormControl>

            </div>
            <div className="login-form__button">
              <CustomButton disabled={!showButton() || loading} text="Log In" type="submit"/>
            </div>
          </ReactForm>
        </Col>
      </Row>

    </Container>
  )
}

export default LoginForm
