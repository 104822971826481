import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import { Button } from '@mui/material'

import UsersCv from '../UsersCv'
import CvAddContactsData from './CvAddContactsData'
import Modal from '../../../../reusable/Modal/index.tsx'
import CvUpdateContactsData from './CvUpdateContactsData'
import { IResume } from '../../../../../types/Resume/IResume'
import { cvNavLinks } from '../../../../../constants'
import { defaultAvatar } from '../../../../../images'
import { useGetUserCv } from '../../../../../hooks/resume/useGetUserCv'
import { webRoutes } from '../../../../../lib'
import { getResumeSuccess } from '../../../../../store/resumesSlice'
import { User } from '../../../../../types'

const CvContactsData = () => {
  const [open, setOpen] = useState(false)
  const [readyFile, setReadyFile] = useState()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { resumeId } = useParams()
  const cvIdFromUrl = useParams().cvId
  const [usersResume, setUsersResume] = useState<IResume>()
  const resume = useSelector((state: { resumes: { showData: IResume } }) => state.resumes.showData)
  const user = useSelector((state: { users: { showData: User } }) => state.users.showData)
  const getUserCv = useGetUserCv()
  const isContactSectionPage = location.pathname === `${webRoutes.RESUMES}${cvNavLinks.CONTACT_SECTION}`
  const prevPath = cvNavLinks.BACK_TO_PROFILE

  useEffect(() => {
    dispatch(getResumeSuccess({}))
    if (resumeId || cvIdFromUrl) {
      getUserCv(Number(resumeId) || Number(cvIdFromUrl))
    }
  }, [resumeId, cvIdFromUrl])

  const onEditContacts = (resume: IResume) => {
    setOpen(!open)
    setUsersResume(resume)
  }

  const onHandleContinue = () => {
    navigate(webRoutes.CV_EXPERIENCE((resumeId as string) || resume.id.toString()))
  }
  const onHandleBack = () => {
    navigate(`/users/${user.id}${prevPath}`)
  }

  const editContactsData = (
    <CvUpdateContactsData
      setOpen={setOpen}
      usersResume={usersResume}
      readyFile={readyFile}
      setReadyFile={setReadyFile}
      cvId={Number(resumeId)}
      cvIdFromUrl={Number(cvIdFromUrl)}
    />
  )

  return (
    <div className="cv__form">
      <UsersCv resumeId={resume?.id} userId={resume?.user_id} />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Button
          variant="outlined"
          style={{ width: '200px', marginLeft: '15px', marginTop: '8px' }}
          onClick={onHandleBack}
        >
          Back to profile
        </Button>
      </Box>
      <Box>
        <h4 className="cv__form_title">Contact section</h4>
      </Box>
      <Box sx={{ display: 'flex', width: '95%', gap: '10px' }}>
        <div className="leftSide_section">
          <Box sx={{ width: '60%' }}>
            <CvAddContactsData setReadyFile={setReadyFile} readyFile={readyFile} />
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            width: '50%',
            minHeight: '600px',
            flexDirection: 'column',
            marginBottom: 'auto',
            gap: '15px',
          }}
        >
          {resume.user_id && (
            <Box
              sx={{
                display: 'flex',
                maxHeight: '500px',
                flexDirection: 'column',
                gap: '15px',
                overflowY: 'auto',
                padding: '15px',
                marginBottom: '30px',
              }}
            >
              <Box
                sx={{
                  padding: '15px',
                  width: '98%',
                  minHeight: '200px',
                  border: '1px solid #dee0df',
                  borderRadius: 2,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '17px', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    {resume.name}
                    {resume.avatar ? (
                      <img src={resume.avatar} style={{ width: 100, height: 100, borderRadius: '50%' }} />
                    ) : (
                      <img src={defaultAvatar} style={{ width: 100, height: 100, borderRadius: '50%' }} />
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton onClick={() => onEditContacts(resume)}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ color: '#65696d', fontSize: '14px' }}>{resume.role}</Box>
                <Box sx={{ fontWeight: 'bold', color: '#65696d', fontSize: '13px', fontStyle: 'italic' }}>
                  {resume.github_url}
                </Box>
                <Box sx={{ color: '#65696d', fontSize: '14px' }}>
                  {resume.city}, {resume.country}
                </Box>
                <Box sx={{ color: '#65696d', fontSize: '13px', wordWrap: 'break-word' }}>{resume.summary}</Box>
              </Box>
            </Box>
          )}
          {open && (
            <Modal
              title={'Edit Contacts'}
              component={editContactsData}
              open={open}
              handleClose={() => setOpen(false)}
            />
          )}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', marginTop: 'auto' }}>
            <Button
              disabled={isContactSectionPage && !user.has_cv}
              onClick={onHandleContinue}
              variant="contained"
              color="primary"
              style={{ width: '200px', marginLeft: '15px' }}
              type="submit"
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CvContactsData
