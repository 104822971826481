import React from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { webRoutes } from 'lib'

import { UserIcon } from 'icons'

const UserMenu = ({ logout }) => {
  return (
    <UncontrolledDropdown nav inNavbar className="user-menu ml-auto">
      <DropdownToggle nav>
        <UserIcon />
      </DropdownToggle>
      <DropdownMenu end className="user-menu-dropdown">
        <DropdownItem tag={Link} to={webRoutes.SETTINGS}>
          Settings
        </DropdownItem>
        <DropdownItem onClick={() => logout()}>
          Logout
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserMenu
