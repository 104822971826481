import React, { memo } from 'react'

import { IRequest } from 'types/Request/IRequest'
import { leaveTypesFormatted, paidLeaveTypes } from '../../../constants'

interface Props {
  request: IRequest
}

const RequestStatusType = ({ request: { request_type: type, paid_leave_type } }: Props) => (
  <div className={`requests-desktop-label requests__${type}`}>
    {type === 'paid_leave' ? paidLeaveTypes[paid_leave_type as keyof typeof paidLeaveTypes] : leaveTypesFormatted[type]}
  </div>
)

export default memo(RequestStatusType)
