import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import UserReportFilters from 'components/user/UserReportFilters'
import Loader from 'components/reusable/Loader'
import { useGetRecords } from 'hooks/record/useGetRecords'
import { useGetCategories } from 'hooks/category/useGetCategories'
import { Project } from 'types/Project'
import { Category } from 'types/Category'
import { useAuth } from 'context/auth'
import { getRecordsParams } from '../../constants'
import { Record } from 'types/Record'
import Total from 'components/reusable/Total'
import RecordsTable from 'components/user/RecordsTable'
import CustomPagination from 'components/reusable/Pagination/Pagination'
import { Pages } from 'enums/StartPage'
import { dateFormatMM, dateFormatYYYY } from 'helpers/displayingDateFormat'
import { useGetUsersNames } from 'hooks/user/useGetUsers'
import { RecordLimits } from 'enums/RecordsEnums'
import ReportCard from './ReportCard'
import { IOptions } from 'types/ISelectOptions'
import MobileReportFilters from './MobileReportFilters'
import Filter from 'icons/Filter'
import { useGetAvailableContracts } from 'hooks/contract/useGetContracts'
import { webRoutes } from 'lib'

const UserReportContainer = () => {
  const [loading, setLoading] = useState(true)
  const [page, setPage] = React.useState(Pages.START_PAGE)
  const [date, setDate] = useState<Date>(new Date())
  const [project, setProject] = useState<IOptions>()
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const categories = useSelector((state: { categories: { indexData: Category[] } }) => state.categories.indexData)
  const records = useSelector((state: { records: { indexData: Record[] } }) => state.records.indexData)
  const recordsHoursSum = useSelector((state: { records: { hours_sum: number } }) => state.records.hours_sum)
  const recordsCount = useSelector((state: { records: { count: number } }) => state.records.count)
  const recordsPages = useSelector((state: { records: { pages: number } }) => state.records.pages)
  const recordsLoading = useSelector((state: { records: { isLoading: boolean } }) => state.records.isLoading)
  const { currentUser } = useAuth()
  const location = useLocation()
  const getAvaliableContracts = useGetAvailableContracts()
  const isMyReportPage = location.pathname === webRoutes.MY_REPORT

  const [reportFilterOpen, setReportFilterOpen] = useState(false)
  const handleReportFilterOpen = () => {
    setReportFilterOpen(true)
  }
  const handleReportFilterClose = () => {
    setPage(Pages.START_PAGE)
    setReportFilterOpen(false)
  }

  const getRecordsHook = useGetRecords()
  const getCategoryHook = useGetCategories()
  const getUsersNamesHook = useGetUsersNames()

  useEffect(() => {
    getCategoryHook()
    getRecordsHook(
      getRecordsParams.project_id, 
      dateFormatYYYY(date), 
      dateFormatMM(date), 
      RecordLimits.USER_RECORD_TABLE_LIMIT, 
      Pages.START_PAGE, 
      setLoading)
    getUsersNamesHook()
    if(isMyReportPage) {
      getAvaliableContracts()
    }
  }, [])

  useEffect(() => {
    getRecordsHook(
      project?.value, 
      dateFormatYYYY(date), 
      dateFormatMM(date), 
      RecordLimits.USER_RECORD_TABLE_LIMIT, page)
  }, [project, date, page])

  return (
    <>
      {!isMobile ? 
        <div className="container">
          {loading ? (
            <Loader />
          ) : (
            <>
              <UserReportFilters
                projects={projects}
                setProject={setProject}
                records={records}
                date={date}
                setDate={setDate}
                project={project}
                setPage={setPage}
              />
              {recordsLoading ? <Loader/> : 
                <>
                  <div className="user-report__title">
                    Monthly Report <span className="user-report__title_counter">{recordsCount}</span>
                  </div>
                  <RecordsTable
                    records={records}
                    projects={projects}
                    categories={categories}
                    currentUser={currentUser}
                  />
                  <div className="user-report__footer">
                    <CustomPagination totalPages={recordsPages} page={page} setPage={setPage} />
                    <Total total={recordsHoursSum} />
                  </div>
                </>
              }
            </>
          )}
        </div>  : 
        <div className="user-report__mobile_bg">
          {reportFilterOpen && 
          <MobileReportFilters 
            closeModal={handleReportFilterClose}
            projects={projects}
            records={records}
            page={page}
            date={date}
            setProject={setProject}
            project={project}
            setDate={setDate}
            setPage={setPage}
          />}
          <div onClick={() => handleReportFilterOpen()} className="user-report__mobile_filters">
            <Filter />
          </div>
          {records.map((record) => (
            <ReportCard 
              key={record.id} 
              record={record}
              categories={categories}
              projects={projects} />
          ))}
          {records.length > 0 ?          
            <div className={`${!isMobile ? 'user-report__footer' : 'user-report__mobile_bg'}`}>
              <CustomPagination totalPages={recordsPages} page={page} setPage={setPage} />
            </div>
            : <div className="user-report__mobile_nodata">There is no records on this date.</div>}
        </div>
      }

    </>
  )
}

export default React.memo(UserReportContainer)
