import React, { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import countryList from 'react-select-country-list'
import Box from '@mui/material/Box'

import { useCreateResumeEducation } from '../../../../../hooks/resume/education/useCreateResumeEducation'
import { Modify } from '../../../../../helpers/ModifyInterface'
import { IOptions } from '../../../../../types/ISelectOptions'
import { IResumeEducation } from '../../../../../types/Resume/IResumeEducation'
import CvEducationForm from './CvEducationForm'
import AddFormItemButton from '../FormButtons/AddFormItemButton'
import { IResume } from '../../../../../types/Resume/IResume'

export interface IEducationInputs {
  university: string
  level: string
  city: string
  startOfStudies: Date
  endOfStudies: Date
}

export type IOmitedEducationOption = Modify<IOptions, { value: string }>

interface IProps {
  cvId: number
  cv: IResume
}

const CvAddEducation: React.FC<IProps> = ({ cvId, cv }) => {
  const [country, setCountry] = useState({ label: 'Ukraine', value: 'UA' })
  const options = useMemo(() => countryList().getData(), [])
  const createResumeEducation = useCreateResumeEducation()
  const cvEducationsPositions = cv.educations?.map(e => e.position)
  const maxPosition = cvEducationsPositions && Math.max(...cvEducationsPositions)

  const { register, handleSubmit, control, reset } = useForm<IEducationInputs>({
    defaultValues: {
      university: '',
      level: '',
      city: '',
      startOfStudies: new Date(),
      endOfStudies: new Date()
    }
  })

  const changeHandler = (value: IOmitedEducationOption) => {
    setCountry(value)
  }

  const onSubmit: SubmitHandler<IEducationInputs> = (data) => {
    const location = `${data.city}, ${country.label}`
    const education = {
      university_name: data.university,
      level: data.level,
      location: location,
      start_date: data.startOfStudies,
      end_date: data.endOfStudies,
      resume_id: cvId,
      position: maxPosition && maxPosition + 1
    }

    createResumeEducation(education as IResumeEducation, cvId)
    reset()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvEducationForm
        control={control}
        register={register}
        country={country}
        changeHandler={changeHandler}
        options={options}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <AddFormItemButton isDisabled={false}/>
      </Box>
    </form>
  )
}

export default CvAddEducation
