import React, { useRef, useEffect, Dispatch, SetStateAction } from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import Select, { GroupBase, OptionsOrGroups } from 'react-select'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import ReactInput from '../../../../reusable/ReactInput'
import { styles } from '../../../../../helpers/customSelectStyles'
import { IEducationCompanyInputs, IEducationOmitedOption } from './CvAddExperienceCompany'
import AddInputField from '../AddInputField'

interface IProps {
  inputFields: { value: string }[]
  setInputFields: Dispatch<SetStateAction<{ value: string }[]>>
  register: UseFormRegister<IEducationCompanyInputs>
  options: OptionsOrGroups<{ label: string, value: string }, GroupBase<{ label: string, value: string }>>
  changeHandler: (value: IEducationOmitedOption) => void
  country: {
    label: string | undefined,
    value?: string | undefined
  }
  control: Control<IEducationCompanyInputs>
  checked: boolean
  setChecked: (value: boolean) => void
  isDisabled: boolean
}
const CvExperienceCompanyForm: React.FC<IProps> = ({
  inputFields,
  setInputFields,
  register,
  options,
  changeHandler,
  country,
  control,
  checked,
  setChecked,
  isDisabled
}) => {
  const divRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: 'smooth' })
  })

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ReactInput type="text" label="Job title" register={register} registerControl="job_title" />
          <ReactInput type="text" label="Company" register={register} registerControl="company" />
        </Box>
        <Box sx={{ display: 'flex', gap: '5px', width: '100%' }} >
          <Box sx={{ width: '100%' }} >
            <Select isClearable={true} styles={styles} options={options} value={country} onChange={changeHandler} />
          </Box>
          <Box sx={{ width: '100%' }} >
            <ReactInput
              type="text"
              label="City"
              register={register}
              registerControl="city"
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ReactInput type="text" label="Description" register={register} registerControl="description" />
        </Box>
      </Box>
      <Box sx={{ width: '100%' }} >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px', gap: '5px' }} >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="job_started_on"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="Beginning of work"
                  views={['year', 'month']}
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="job_ended_on"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="End of work"
                  views={['year', 'month']}
                  disabled={checked}
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box sx={{ width: '45%' }} >
        <FormGroup>
          <FormControlLabel control={
            <Checkbox
              checked={checked}
              color="primary"
              onChange={() => setChecked(!checked)}
            />
          }
          label="I currently work here"
          />
        </FormGroup>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', fontSize: '20px', fontWeight: '500' }}>
                Work responsibilities:
      </Box>
      <div className="added_div_portfolio">
        {inputFields.map((input, index) => {
          return (
            <AddInputField
              inputFields={inputFields}
              isDisabled={isDisabled}
              setInputFields={setInputFields}
              key={index}
              input={input}
              index={index}
              placeholder="Responsibility"
            />
          )
        })}
        <div ref={divRef} />
      </div>
    </>
  )
}

export default CvExperienceCompanyForm
