import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { PropsValue } from 'react-select'

import MonthlyReportFilters from 'components/admin/MonthlyReport/MonthlyReportFilters'
import MonthlyReportTable from 'components/admin/MonthlyReport/MonthlyReportTable'
import { getMonthlyReport } from 'services/API/admin/monthly-report'
import { getJobStatuses } from 'services/API/admin/job-statuses'
import dangerNotify from 'helpers/dangerNotify'
import { MonthlyReport } from 'types/MonthlyReport'
import ExportSalaryCSV from 'components/admin/MonthlyReport/ExportCSV/ExportSalaryCSV'
import ExportCSV from 'components/admin/MonthlyReport/ExportCSV/ExportCSV'
import { fetchReport, fetchReportSuccess, fetchReportFailure } from '../../store/montlyReportSlice'
import { Project, User } from 'types'
import Loader from 'components/reusable/Loader'
import { dateFormatYYMM, dateFormatYYMMDD } from 'helpers/displayingDateFormat'
import { renderProject, renderUser } from 'helpers/renderName'
import { IOptions } from 'types/ISelectOptions'
import { Pages } from 'enums/StartPage'
import CustomPagination from 'components/reusable/Pagination/Pagination'
import { TableLimits } from 'enums/TableLimits'
import { Filter } from 'icons'
import ReportCard from 'containers/UserReportContainer/ReportCard'
import MobileAdminReportFilters from 'containers/UserReportContainer/MobileAdminReportFilters'
import { useGetCategories } from 'hooks/category/useGetCategories'
import { Modify } from 'helpers/ModifyInterface'
import Total from 'components/reusable/Total'
import { SelectType } from '../../enums/SelectTypes'

export type IOmitedOptions = Modify<
  IOptions,
  {
    type: SelectType
  }
>

const ReportsMonthlyContainer = () => {
  const localStorageFilters = JSON.parse(localStorage.getItem('filter_array') as string)
  const [month, setMonth] = useState<Date>(localStorage.getItem('monthly_report_date') as never)
  const [allStatuses, setStatusBar] = useState()
  const [selectedValue, setSelectedValue] = useState<Array<IOmitedOptions> | null>(localStorageFilters)
  const [typeValue, setTypeValue] = useState<PropsValue<IOptions>>(
    () => { const storedTypeValueString = localStorage.getItem('typeValue')
      return storedTypeValueString ? JSON.parse(storedTypeValueString) : null
    }
  )
  const [alignment, setAlignment] = React.useState('month')
  const [reportFilterOpen, setReportFilterOpen] = useState(false)
  const [page, setPage] = React.useState(Pages.START_PAGE)
  const dispatch = useDispatch()

  const reportRecords = useSelector(
    (state: { monthlyReport: { indexData: MonthlyReport[] } }) => state.monthlyReport.indexData,
  )
  const reportRecordsPages = useSelector((state: { monthlyReport: { pages: number } }) => state.monthlyReport.pages)
  const reportRecordsCount = useSelector((state: { monthlyReport: { count: number } }) => state.monthlyReport.count)
  const reportRecordsTotal = useSelector((
    state: { monthlyReport: { total_hours: number } }) => state.monthlyReport.total_hours
  )
  const reportRecordsLoading = useSelector(
    (state: { monthlyReport: { isLoading: boolean } }) => state.monthlyReport.isLoading,
  )
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const variantTypes = [SelectType.USER, SelectType.PROJECT, SelectType.STATUS, SelectType.CATEGORY]
  const selectRef = React.useRef()
  const getCategoryHook = useGetCategories()

  useEffect(() => {
    getJobStatuses()
      .then((response) => {
        setStatusBar(response.data)
      })
      .catch((error) => dangerNotify(error.message))
    getCategoryHook()
  }, [])

  useEffect(() => {
    localStorage.setItem('typeValue', JSON.stringify(typeValue))
  }, [typeValue])

  useEffect(() => {
    dispatch(fetchReport())
    getMonthlyReport(paramsType(TableLimits.REPORT_LIMIT))
      .then((response) => {
        dispatch(fetchReportSuccess(response.data))
        localStorage.setItem('monthly_report_date', String(month))
      })
      .catch((error) => {
        dispatch(fetchReportFailure(error))
        dangerNotify(error.message)
      })
  }, [month, selectedValue, alignment, page])

  const handleReportFilterOpen = () => {
    setReportFilterOpen(true)
  }
  const handleReportFilterClose = () => {
    setPage(Pages.START_PAGE)
    setReportFilterOpen(false)
  }

  const paramsType = (limit: number | undefined, csv_page?: number | undefined) => {
    return {
      params: {
        year_month: alignment === 'month' ? dateFormatYYMM(month) : dateFormatYYMMDD(month),
        user_id: selectedValue?.find(selected => selected.type === SelectType.USER)?.value,
        job_status_id: selectedValue?.find(selected => selected.type === SelectType.STATUS)?.value,
        project_id: selectedValue?.find(selected => selected.type === SelectType.PROJECT)?.value,
        category_id: selectedValue?.filter(selected => selected.type === SelectType.CATEGORY).map(val => val.value),
        page: csv_page ? csv_page : page,
        limit: limit,
      },
    }
  }

  const newArrRecords = reportRecords.map((record) => {
    if (record['project']) {
      return { ...record, project: renderProject(record.project as number, projects) as string }
    }
    return record
  })

  const sortedRecords = newArrRecords.sort(function (a, b) {
    const recordA = (renderUser(a.user, usersNames) as string || '').toUpperCase()
    const recordB = (renderUser(b.user, usersNames) as string || '').toUpperCase()

    if (recordA < recordB) return -1
    if (recordA > recordB) return 1

    return 0
  })

  return (
    <>
      {!isMobile ? (
        <div className="container">
          <MonthlyReportFilters
            month={month}
            setMonth={setMonth}
            allStatuses={allStatuses}
            usersNames={usersNames}
            projects={projects}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            typeValue={typeValue}
            variantTypes={variantTypes}
            setTypeValue={setTypeValue}
            alignment={alignment}
            setAlignment={setAlignment}
            paramsType={paramsType}
            setPage={setPage}
          />
          <div className="admin-report__title">
            Monthly Report <span className="admin-report__title_counter">{reportRecordsCount}</span>
          </div>
          {reportRecordsLoading ? (
            <Loader />
          ) : (
            <>
              <MonthlyReportTable reportRecords={sortedRecords}/>
              <Total total={reportRecordsTotal} />
            </>
          )}
          <div className="admin-report__footer">
            <CustomPagination totalPages={reportRecordsPages} page={page} setPage={setPage} />
          </div>
        </div>
      ) : (
        <div className="admin-report__mobile_bg">
          {reportFilterOpen && (
            <MobileAdminReportFilters
              closeModal={handleReportFilterClose}
              month={month}
              setMonth={setMonth}
              allStatuses={allStatuses}
              usersNames={usersNames}
              projects={projects}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              typeValue={typeValue}
              setTypeValue={setTypeValue}
              alignment={alignment}
              setAlignment={setAlignment}
              paramsType={paramsType}
              selectRef={selectRef}
              setPage={setPage}
            />
          )}
          <div onClick={() => handleReportFilterOpen()} className="user-report__mobile_filters">
            <Filter />
          </div>
          <div className="admin-report__csv_mobile_buttons">
            <ExportSalaryCSV 
              month={dateFormatYYMM(month)} 
              filename={`${dateFormatYYMM(month)}-monthly-salary-report.csv`} />
            <ExportCSV
              paramsType={paramsType}
              filename={`${dateFormatYYMM(month)}-monthly-report.csv`}
            />
          </div>
          {sortedRecords.map((record, index) => (
            <ReportCard key={index} record={record} projects={projects} />
          ))}
          {sortedRecords.length > 0 ? (
            <div className={`${!isMobile ? 'user-report__footer' : 'user-report__mobile_bg'}`}>
              <CustomPagination totalPages={reportRecordsPages} page={page} setPage={setPage} />
            </div>
          ) : (
            <div className="user-report__mobile_nodata">There is no records on this date.</div>
          )}
        </div>
      )}
    </>
  )
}

export default React.memo(ReportsMonthlyContainer)
