import dangerNotify from '../../helpers/dangerNotify'
import { fetchUsersProfiles } from '../../services/API/admin/profile'
import { UserProfile } from '../../types'

export const useFetchProfiles = () => {
  return (
    hired: boolean | undefined,
    fired: boolean | undefined,
    page: number,
    period: string,
    setTotalPages: (value: number) => void,
    setProfiles: (value: UserProfile[]) => void
  ) => {
    fetchUsersProfiles(hired, fired, page, period)
      .then((response) => {
        setTotalPages(response.data.total_pages)
        setProfiles(response.data.profiles)
      })
      .catch((error) => dangerNotify(error.message))
  }
}
