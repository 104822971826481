import React, { SyntheticEvent, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import UsersCv from '../UsersCv'
import CvNavButtons from '../CvNavButtons'
import CvAddSkillSet from './CvAddSkillSet'
import { useDeleteSkillSet } from '../../../../../hooks/resume/skills/useDeleteSkillSet'
import CvUpdateSkillSet from './CvUpdateSkillSet'
import Modal from '../../../../reusable/Modal/index.tsx'
import { IResume } from '../../../../../types/Resume/IResume'
import { cvNavLinks } from '../../../../../constants'
import { IResumeSkill } from '../../../../../types/Resume/IResumeSkill'
import { useGetUserCv } from '../../../../../hooks/resume/useGetUserCv'
import { useChangeCvSkillSetSorting } from '../../../../../hooks/resume/skills/useChangeCvSkillSetSorting'

const CvSkillSets = () => {
  const { resumeId } = useParams()
  const [open, setOpen] = useState(false)
  const [currentSkillSet, setCurrentSkillSet] = useState<IResumeSkill | null>(null)
  const [skill, setSkill] = useState<IResumeSkill>()
  const resume = useSelector((state: { resumes: { showData: IResume } }) => state.resumes.showData)
  const getUserCv = useGetUserCv()
  const deleteSkillSet = useDeleteSkillSet()
  const changeCvSkillSetSorting = useChangeCvSkillSetSorting()
  const nextPath = cvNavLinks.LANGUAGES
  const prevPath = cvNavLinks.PORTFOLIO

  useEffect(() => {
    if (resumeId) {
      getUserCv(+resumeId)
    }
  }, [resumeId])

  const onEditSkill = (skill: IResumeSkill) => {
    setOpen(!open)
    setSkill(skill)
  }

  const onDeleteSkillSet = (id: number | undefined) => {
    deleteSkillSet(id as number, Number(resumeId))
  }

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, skillSet: IResumeSkill) => {
    setCurrentSkillSet(skillSet)
  }

  const dropHandler = (e: React.DragEvent<HTMLDivElement>, skillSet: IResumeSkill) => {
    e.preventDefault()
    if (currentSkillSet?.id != skillSet.id) {
      changeCvSkillSetSorting(
        currentSkillSet?.id as number,
        skillSet.id as number,
        currentSkillSet?.position as number,
        skillSet.position,
        Number(resumeId),
      )
    }
  }

  const dragOverHandler = (e: SyntheticEvent) => e.preventDefault()

  const editSkill = <CvUpdateSkillSet setOpen={setOpen} skill={skill as IResumeSkill} cvId={Number(resumeId)} />

  return (
    <div className="cv__form">
      <UsersCv resumeId={resume?.id} userId={resume?.user_id} />
      <div className="cv__form_inner">
        <h4 className="cv__form_title">Skills</h4>
      </div>
      <Box sx={{ display: 'flex', width: '95%', gap: '10px' }}>
        <div className="leftSide_section">
          <Box sx={{ width: '60%' }}>
            <CvAddSkillSet cvId={Number(resumeId)} cv={resume} />
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            width: '50%',
            minHeight: '600px',
            flexDirection: 'column',
            marginBottom: 'auto',
            gap: '15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              maxHeight: '500px',
              flexDirection: 'column',
              gap: '15px',
              overflowY: 'auto',
              padding: '15px',
              marginBottom: '30px',
            }}
          >
            {resume?.skills?.map((skillSet) => (
              <Box
                draggable={true}
                onDragStart={(e) => dragStartHandler(e, skillSet)}
                onDragOver={(e) => dragOverHandler(e)}
                onDrop={(e) => dropHandler(e, skillSet)}
                key={skillSet.id}
                sx={{
                  padding: '15px',
                  width: '100%',
                  maxHeight: '200px',
                  border: '1px solid #dee0df',
                  borderRadius: 2,
                  cursor: 'pointer',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ fontSize: '17', fontWeight: '500' }}>{skillSet.skill_set}</Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton onClick={() => onEditSkill(skillSet)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteSkillSet(skillSet.id)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ fontSize: '13px' }}>{skillSet.skills.map((skill: string) => skill + ', ')}</Box>
              </Box>
            ))}
          </Box>
          {open && <Modal title={'Edit skill'} component={editSkill} open={open} handleClose={() => setOpen(false)} />}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', marginTop: 'auto' }}>
            <CvNavButtons resumeId={resumeId} nextPath={nextPath} prevPath={prevPath} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CvSkillSets
