import { useDispatch } from 'react-redux'

import { useGetProjects, useGetUserProjects } from './useGetProjects'
import { editProject } from '../../services/API/admin/projects'
import { updateProject, updateProjectSuccess, updateProjectFailure } from '../../store/projectsSlice'
import { NewProject } from '../../types'
import { getProjectParams } from '../../constants'
import successNotify from '../../helpers/successNotify'
import dangerNotify from '../../helpers/dangerNotify'
import { Pages } from '../../enums/StartPage'
import { useFetchProjectTechnologies } from './useFetchProjectTechnologies'

export const useEditProject = () => {
  const getProjectHook = useGetProjects()
  const getUserProjectsHook = useGetUserProjects()
  const fetchProjectTechnologiesHook = useFetchProjectTechnologies()
  const dispatch = useDispatch()

  return (updatedProject: NewProject, project_id: number | undefined) => {
    dispatch(updateProject())
    editProject(updatedProject, project_id)
      .then((response) => {
        dispatch(updateProjectSuccess(response.data))   
        getProjectHook(
          getProjectParams.name,
          getProjectParams.technology,
          getProjectParams.company,
          getProjectParams.fromScratch,
          getProjectParams.application,
          getProjectParams.manager,
          Pages.START_PAGE
        )
        getUserProjectsHook()
        fetchProjectTechnologiesHook()
        successNotify('Project edited')
      })
      .catch((error) => {
        dispatch(updateProjectFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
