import React from 'react'

const EnglishIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13 5H19.3712L13 9.95536V5ZM4.62882 5H11V9.95536L4.62882 5ZM3
      11V6.26686L9.08546 11H3ZM3 13L3 17.7331L9.08546 13H3ZM14.9145 13L21 17.7331V13H14.9145ZM21 11V6.26686L14.9145
      11H21ZM11 19H4.62882L11 14.0446V19ZM19.3712 19H13V14.0446L19.3712 19ZM3 3C1.89543 3 1 3.89543 1 5V19C1 20.1046
      1.89543 21 3 21H21C22.1046 21 23 20.1046 23 19V5C23 3.89543 22.1046 3 21 3H3Z" fill={color} />
    </svg>

  )
}

export default EnglishIcon
