import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useSelector } from 'react-redux'

import ModalContainer from 'containers/ModalContainer'
import { ReadyRecord } from 'types/ReadyRecord'
import { Category } from 'types/Category'
import { User, Project } from '../../../types'
import { tableCellStyles } from 'utilts/styles'
import { useDeleteRecord } from 'hooks/record/useDeleteRecord'
import DeleteIcon from '../../reusable/DeleteIcon'
import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import { isManager } from 'helpers/accessHelpers'
import { StatusType } from 'enums/Types'
import { webRoutes } from '../../../lib'
import { renderCategory, renderManager, renderProject } from 'helpers/renderName'

interface Props {
  record: ReadyRecord
  categories: Category[]
  currentUser: User | undefined
  projects: Project[]
}

const TableItem: React.FC<Props> = ({ record, categories, currentUser, projects }) => {
  const deleteRecordHook = useDeleteRecord()
  const location = useLocation()
  const mainPage = location.pathname == webRoutes.ADMIN_MAIN || location.pathname == webRoutes.SUBMIT_HOURS
  const reportPage = location.pathname === webRoutes.MY_REPORT
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)

  const onDelete = () => {
    if (record.id) {
      deleteRecordHook(record.id)
    }
  }

  return (
    <TableRow className="requests-table__style">
      <TableCell align="left" sx={tableCellStyles}>
        {isManager(currentUser) ? <Link to={`/projects/${record.project_id}`}>{record.project}</Link> :
          renderProject(record.project_id, projects)
        }
      </TableCell>
      <TableCell align="left" sx={tableCellStyles}>
        {reportPage ? renderCategory(record.category_id, categories) : record.category}
      </TableCell>
      <TableCell align="left" sx={tableCellStyles}>
        {record.description}
      </TableCell>
      <TableCell align="left" sx={tableCellStyles}>
        {dateFormatDDMMYY(record.date)}
      </TableCell>
      {reportPage &&   
        <TableCell align="left" sx={tableCellStyles}>
          {renderManager(record.project_id, projects, usersNames)}
        </TableCell>
      }
      <TableCell align={mainPage ? 'left' : 'center'} sx={tableCellStyles}>
        {record.hours}
      </TableCell>
      {mainPage &&
        (record.record_type === StatusType.REGULAR ? (
          <TableCell align="left" sx={tableCellStyles}>
            <span onClick={onDelete}>
              <DeleteIcon />
            </span>
            <ModalContainer record={record} categories={categories} currentUser={currentUser} projects={projects} />
          </TableCell>
        ) : (
          <TableCell align="left" sx={tableCellStyles}>
            {''}
          </TableCell>
        ))}
    </TableRow>
  )
}

export default TableItem
