import { createSlice } from '@reduxjs/toolkit'

import { Notification } from '../types'

const initialState = {
  indexData: <Notification[]>[],
  showData: <Notification>{},
  error: {},
  isLoading: true,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchNotifications(state) {
      state.isLoading = true
    },
    fetchNotificationsSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchNotificationsFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    updateNotification(state) {
      state.isLoading = true
    },
    updateNotificationSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    updateNotificationFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    }
  }
})

export default notificationsSlice.reducer
export const {
  fetchNotifications,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
  updateNotification,
  updateNotificationSuccess,
  updateNotificationFailure
} = notificationsSlice.actions
