import * as React from 'react'
import { FormFeedback, Input, InputGroup, InputGroupText } from 'reactstrap'
import { InputType } from 'reactstrap/types/lib/Input'

interface Props {
    type?: InputType
    name?: string
    id?: string
    placeholder?: string
    errorMessage?: string
    isError?: boolean
    disabled?: boolean
    bsSize?: 'lg' | 'sm'
    innerRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>
    className?: string
    prefixIcon?: JSX.Element
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    defaultValue?: string | number | readonly string[]
    value?: string | number | readonly string[]
}

const DefaultInput: React.FC<Props> = ({ 
  type,
  name,
  id,
  placeholder,
  errorMessage,
  isError,
  disabled,
  bsSize,
  innerRef,
  className,
  prefixIcon,
  onChange,
  defaultValue,
  value
}) => {

  const customStyles: React.CSSProperties = {
    padding: '16px',
    borderLeft: prefixIcon ? 'none' : '1px solid #D4D9E2',
    boxShadow: 'none',
    borderColor: '#D4D9E2',
  }

  const iconStyles: React.CSSProperties = {
    backgroundColor: 'white',
    paddingLeft: '20px',
    paddingRight: '5px',
    borderRight: 'none',
  }

  return (
    <>
      <InputGroup>
        {prefixIcon &&     
        <InputGroupText style={iconStyles}>
          {prefixIcon}
        </InputGroupText>
        }
        
        <Input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          invalid={isError}
          disabled={disabled}
          bsSize={bsSize}
          style={customStyles}
          innerRef={innerRef}
          className={className}
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
        />
      </InputGroup>
      <FormFeedback>{errorMessage}</FormFeedback>
    </>
  )
}

export default DefaultInput
