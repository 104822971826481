import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { updateCvCertificate } from '../../../services/API/admin/resume'
import { IResumeCertificate } from '../../../types/Resume/IResumeCertificate'
import { useGetUserCv } from '../useGetUserCv'

export const useUpdateCvCertificate = () => {
  const getUserCv = useGetUserCv()

  return (certificate: IResumeCertificate, certificateId: number, cvId: number ) => {
    updateCvCertificate(certificate, certificateId)
      .then(() => {
        successNotify('Certificate updated')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Update error'))
  }
}
