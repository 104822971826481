import React, { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import countryList from 'react-select-country-list'
import Box from '@mui/material/Box'

import { useUpdateCvEducation } from '../../../../../hooks/resume/education/useUpdateCvEducation'
import { IResumeEducation } from '../../../../../types/Resume/IResumeEducation'
import { IOmitedEducationOption } from './CvAddEducation'
import CvEducationForm from './CvEducationForm'
import EditFormButtons from '../FormButtons/EditFormButtons'

interface Inputs {
  university: string
  level: string
  city: string
  startOfStudies: Date
  endOfStudies: Date
}

interface Props {
  setOpen: (value: boolean) => void
  education: IResumeEducation
  cvId: number
}

const CvUpdateEducation: React.FC<Props> = ({ setOpen, education, cvId }) => {
  const [country, setCountry] = useState(    { label: education.country || 'Ukraine' })
  const options = useMemo(() => countryList().getData(), [])
  const updateEducation = useUpdateCvEducation()

  const { register, handleSubmit, control } = useForm<Inputs>({
    defaultValues: {
      university: education.university_name || '',
      level: education.level || '',
      city: education.city || '',
      startOfStudies: education.start_date || undefined,
      endOfStudies: education.end_date || undefined
    }
  })

  const changeHandler = (value: IOmitedEducationOption) => {
    setCountry(value)
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const location = `${data.city}, ${country.label}`

    const updatedEducation = {
      university_name: data.university,
      level: data.level,
      location: location,
      start_date: data.startOfStudies,
      end_date: data.endOfStudies,
      resume_id: cvId
    }
    updateEducation(updatedEducation as IResumeEducation, education.id as number, cvId)
    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvEducationForm
        control={control}
        register={register}
        country={country}
        changeHandler={changeHandler}
        options={options}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <EditFormButtons setOpen={setOpen} />
      </Box>
    </form>
  )
}

export default CvUpdateEducation
