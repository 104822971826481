import React from 'react'
import { isMobile } from 'react-device-detect'

const ProjectsIconGrey = () => {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.25 3.2V2.25H11.75V3.2V4.15H6.25V3.2ZM14.625 
        2.2H13.7498C13.7368 1.15703 12.9104 0.25 11.8125 0.25H6.1875C5.08961 0.25 4.26318 1.15703 4.25016 
        2.2H3.375C2.59957 2.2 1.86421 2.52073 1.32834 3.07803C0.793752 3.63401 0.5 4.37999 0.5 5.15V7V11.5V18.8C0.5 
        19.57 0.793753 20.316 1.32834 20.872C1.86421 21.4293 2.59956 21.75 3.375 21.75H14.625C15.4004 21.75 
        16.1358 21.4293 16.6717 20.872C17.2062 20.316 17.5 19.57 17.5 18.8V5.15C17.5 4.37999 17.2062 3.63401 
        16.6717 3.07803C16.1358 2.52073 15.4004 2.2 14.625 2.2ZM2.5 11.5V18.8C2.5 19.0643 2.60134 19.3103 
        2.77001 19.4858C2.9374 19.6598 3.15587 19.75 3.375 19.75H14.625C14.8441 19.75 15.0626 19.6598 15.23 
        19.4858C15.3987 19.3103 15.5 19.0643 15.5 18.8V5.15C15.5 4.88567 15.3987 4.63967 15.23 4.46425C15.0626 
        4.29016 14.8441 4.2 14.625 4.2H13.7498C13.7368 5.24297 12.9104 6.15 11.8125 6.15H6.1875C5.08961 6.15 
        4.26318 5.24297 4.25016 4.2H3.375C3.15587 4.2 2.9374 4.29016 2.77001 4.46425C2.60134 4.63967 2.5 
        4.88566 2.5 5.15V7V11.5Z" fill={!isMobile ? 'white' : 'black'} />
    </svg>
  )
}

export default ProjectsIconGrey
