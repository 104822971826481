import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'context/auth'

import { isManager } from 'helpers/accessHelpers'
import { renderType, renderUser } from 'helpers/renderName'
import { useSelector } from 'react-redux'
import { User } from 'types'
import { StatusType } from 'enums/Types'
import { paidLeave } from '../../../constants'

interface Props {
  titleUser: number | undefined
  titleType: string | undefined
  titlePaidLeave: number | undefined
}

const RequestTitle: React.FC<Props> = ({ titleUser, titleType, titlePaidLeave }) => {
  const { currentUser } = useAuth()
  const isAdmin = isManager(currentUser)
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)

  return (
    <div>
      {
        isAdmin ? 
          <>
            <Link 
              className="requests-mobile__name"
              to={`/users/${titleUser}`}
            >
              {renderUser(titleUser, usersNames)}
            </Link>
          </>  
          : 
          <>
            <div className="requests__user-title-mobile">
              {titleType === StatusType.PAID_LEAVE && 
                    renderType(titlePaidLeave, paidLeave)
              }
              {titleType === StatusType.FULL_DAY_SICK_LEAVE && 
                  <div className="requests__user-title-mobile">Sick Leave</div>
              }
              {titleType === StatusType.HALF_DAY_SICK_LEAVE && 
                  <div className="requests__user-title-mobile">Half day sick Leave</div>
              }
              {titleType === StatusType.EXTRA_SICK_LEAVE && 
                  <div className="requests__user-title-mobile">Extra sick leave</div>
              }
              {titleType === StatusType.VACATION && 
                  <div className="requests__user-title-mobile">Vacation</div>
              }
              {titleType === StatusType.UNPAID_LEAVE && 
                  <div className="requests__user-title-mobile">Unpaid Leave</div>
              }
              {titleType === StatusType.HALF_DAY_UNPAID_LEAVE && 
                  <div className="requests__user-title-mobile">Half day unpaid Leave</div>
              }
              {titleType === StatusType.WORK_OFF && 
                  <div className="requests__user-title-mobile">Work Off</div>
              }
              {titleType === StatusType.DAY_OFF && 
                  <div className="requests__user-title-mobile">Day Off</div>
              }
            </div>
          </>   
      }
    </div>
  )
}

export default RequestTitle
