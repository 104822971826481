import React, { memo } from 'react'
import { Button } from '@mui/material'

import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import { leaveTypesFormatted, paidLeaveTypes } from '../../../constants'
import { EditIcon, TrashIcon } from 'icons'
import { Link } from 'react-router-dom'

type Props = {
  isRequest?: boolean
  isInventory?: boolean
  onEdit?: () => void
  onDelete?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any
  // item: {
  //   type?: string
  //   manager?: string
  //   end_date?: string
  //   start_date?: string
  //   status?: string
  //   name?: string
  //   device?: string
  //   serial_number?: string
  // }
}

export const UserProfileTabCard = memo(function UserProfileTabCard({
  item,
  isRequest,
  isInventory,
  onEdit,
  onDelete,
}: Props) {
  return (
    <div className="user-profile__card">
      <div className="d-flex justify-content-between">
        <div>
          {isInventory ? (
            <>
              <p className="user-profile__card-title text-reset text-decoration-none fw-bold text-capitalize">
                {item.device}
              </p>
              <p className="user-profile__card-title text-reset text-decoration-none">{item.name}</p>
              <p className="user-profile__card-date">{item.serial_number}</p>
            </>
          ) : (
            <p className={`user-profile__card-title${isRequest ? ` request-${item.request_type}` : ''}`}>
              {isRequest ? (
                item.request_type === 'paid_leave' ? (
                  paidLeaveTypes[item.paid_leave_type as keyof typeof paidLeaveTypes]
                ) : (
                  leaveTypesFormatted[item.request_type as keyof typeof leaveTypesFormatted]
                )
              ) : (
                <Link to={`/projects/${item.project_id}`}>{item.project_name}</Link>
              )}
            </p>
          )}

          {!isInventory && (
            <>
              <p className="user-profile__card-date">
                Start Date:&nbsp;{(item.start_date && dateFormatDDMMYY(item.start_date)) || '-'}
              </p>
              <p className="user-profile__card-date">
                End Date:&nbsp;{(item.end_date && dateFormatDDMMYY(item.end_date)) || '-'}
              </p>
            </>
          )}
        </div>

        <div>
          {isInventory ? (
            <div>
              <Button onClick={onEdit}>
                <EditIcon />
              </Button>
              <Button onClick={onDelete}>
                <TrashIcon />
              </Button>
            </div>
          ) : (
            <>
              {!isRequest && <p className="user-profile__card-manager">{item.admin_name || '-'}</p>}
              <p
                className={`user-profile__card-status${
                  isRequest ? ` ${item.status}` : ` contract-${item.status.replace(/\s/g, '-').toLowerCase()}`
                }`}
              >
                {item.status}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
})
