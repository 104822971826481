import { StylesConfig } from 'react-select'

type MyOptionType = {
  label: string
  value: number
}

type IsMulti = false

const stylesTopMobileSelect: StylesConfig<MyOptionType, IsMulti> = {
  control: (css) =>
    ({
      ...css,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0 14px',
      width: '100%',
      height: '48px',
      background: '#FFFFFF',
      borderRadius: '6px',
      marginBottom: '18px'
    }),
  placeholder: (css) => ({
    ...css,
    position: 'absolute',
    left: '10px',
    color: '#303540',
    letterSpacing: '0.01em',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '15px',
    margin: '0'
  }),
  singleValue: (css) => ({
    ...css,
    position: 'absolute',
    left: '10px',
    color: '#303540',
    letterSpacing: '0.01em',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '15px',
    margin: '0'
  }),
  dropdownIndicator: (css) => ({
    ...css,
    color: '#8992A6'
  }),
  menu: (css) => ({
    ...css,
    zIndex: 5,
  }),
  option: (css) => ({
    ...css,
    backgroundColor: 'white',
    color: 'black'
  })
}

const stylesBottomMobileSelect: StylesConfig<MyOptionType, IsMulti> = {
  control: (css) =>
    ({
      ...css,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      height: '48px',
      padding: '0 14px',
      background: '#FFFFFF',
      borderRadius: '6px',
      border: '1px solid #D4D9E2'
    }),
  placeholder: (css) => ({
    ...css,
    position: 'absolute',
    left: '0',
    color: '#9EA5B8',
    letterSpacing: '0.01em',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '136%',
    fontSize: '15px',
    margin: '0'
  }),
  singleValue: (css) => ({
    ...css,
    position: 'absolute',
    left: '0',
    color: '#9EA5B8',
    letterSpacing: '0.01em',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '136%',
    fontSize: '15px',
    margin: '0'
  }),
  dropdownIndicator: (css) => ({
    ...css,
    color: '#9EA5B8'
  }),
  menu: (css) => ({
    ...css,
    zIndex: 5,
  }),
  option: (css) => ({
    ...css,
    backgroundColor: 'white',
    color: 'black'
  })
}

export { stylesTopMobileSelect, stylesBottomMobileSelect }
