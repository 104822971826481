import React from 'react'

import { IResume } from '../../../../../types/Resume/IResume'
import './mobileStyles.scss'

interface Props {
  resume: IResume
}

const MobileProjectsTab: React.FC<Props> = ({ resume }) => {
  return (
    <div className="mobile_preview">
      <div className="projects_wrapper">
        {resume?.resume_projects?.map(project => (
          <div key={project.id} className="project_item">
            <span className="project_title">
              {project.title}
            </span>
            {project.role &&
              <span className="project_role">{project.role}</span>
            }
            {project.url &&
              <span className="project_url">
                <a className="project_link" target="_blank" href={project.url ? project.url : ''} rel="noreferrer" >
                  {project.url}
                </a>
              </span>
            }
            <div className="project_stack__wrapper">
              {!project.stack[0] ? null : project.stack.map((item, index) =>
                <div key={index} className="project_stack__item">
                  {item}
                </div>
              )}
            </div>
            <div className="project_description">
              {project.description}
            </div>
            {project.responsibilities &&
              <div className="project_responsibilities__wrapper">
                <div className="responsibility__title">
                  Responsibilities:
                </div>
                <div className="project_responsibilities">
                  {project.responsibilities}
                </div>
              </div>
            }
            {project.team &&
              <>
                <span className="project_team__title">Team:</span>
                <span className="project_team">{project.team}</span>
              </>
            }
          </div>
        ))}
      </div>
    </div>
  )
}

export default MobileProjectsTab
