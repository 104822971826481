import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import { tableCellStyles, tableHeadStyles } from 'utilts/styles'
import { MonthlyReport } from 'types/MonthlyReport'
import { User } from 'types/User'
import { Project } from 'types/Project'
import { renderCategory, renderProjectId, renderUser } from 'helpers/renderName'
import { dateFormatMM } from 'helpers/displayingDateFormat'
import { colorizeJobStatus } from 'helpers/jobStatusStyling'
import { Category } from 'types'
import { webRoutes } from 'lib'
import { BENCH_MANAGER, BENCH_PROJECT } from '../../../../constants'

interface Props {
  reportRecords: MonthlyReport[]
}

const MonthlyReportTable: React.FC<Props> = ({ reportRecords }) => {
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const categories = useSelector((state: { categories: { indexData: Category[] } }) => state.categories.indexData)
  
  const onSearchRecord = (record: MonthlyReport) => {
    localStorage.setItem('user_monthly_report', record?.user as string)
    localStorage.setItem('user_full_name_monthly_report', renderUser(record.user as number, usersNames) as string)
    localStorage.setItem('project_monthly_report', renderProjectId(record?.project, projects))
    localStorage.setItem('project_name_monthly_report', record?.project as string),
    dateFormatMM(localStorage.getItem('monthly_report_date'))
  }

  return (
    <TableContainer style={{ minHeight: '85%' }}>
      <Table>
        <TableHead className="requests-table__title">
          <TableRow>
            <TableCell align="left" sx={tableHeadStyles}>
              User
            </TableCell>
            <TableCell align="left" sx={tableHeadStyles}>
              Project
            </TableCell>
            <TableCell align="left" sx={tableHeadStyles}>
              Category
            </TableCell>
            <TableCell align="left" sx={tableHeadStyles}>
              Manager
            </TableCell>
            <TableCell align="left" sx={tableHeadStyles}>
              Job Status
            </TableCell>
            <TableCell align="left" sx={tableHeadStyles}>
              Hours
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {reportRecords.map(
            (record, index) =>
              record.project && (
                <TableRow key={index} className="requests-table__style">
                  <TableCell align="left" sx={tableCellStyles}>
                    <Link to={`/users/${record.user}`}>{renderUser(Number(record.user), usersNames)}</Link>
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    <Link to={`/projects/${renderProjectId(record.project, projects)}`}>
                      {record.project}
                    </Link>
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {renderCategory(record.category, categories)}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {record.project === BENCH_PROJECT ? BENCH_MANAGER : renderUser(record.manager, usersNames)}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    <span style={colorizeJobStatus(record.job_status)}>{record.job_status}</span>
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles} onClick={() => onSearchRecord(record)}>
                    <Link to={webRoutes.ADMIN_MONTHLY_CALENDAR(record.user)}>
                      {record.hours.toFixed(1)}
                    </Link>
                  </TableCell>
                </TableRow>
              ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MonthlyReportTable
