import * as React from 'react'

interface Props {
  // eslint-disable-next-line
  onSubmit?: any
  className?: string
  children: React.ReactNode
}

const ReactForm: React.FC<Props> = ({ onSubmit, children, className }) => {
  return (
    <form onSubmit={onSubmit} className={className}>
      {children}
    </form>
  )
}

export default ReactForm
