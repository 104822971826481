import API from '../../index'
import ROUTES from '../../../../routes'
import { Inventory } from '../../../../types'

export const getInventories = (profile_id: number) =>
  API.get(ROUTES.INVENTORIES,  { params: { profile_id: profile_id } })

export const createInventory = (inventory: Inventory) => API.post(ROUTES.INVENTORIES, { inventory })

export const editInventory = (inventory: Inventory) =>
  API.put(ROUTES.INVENTORIES + `/${inventory.id}`, { inventory })

export const deleteInventory = (id: number) => API.delete(ROUTES.INVENTORIES + `/${id}`)
