import React from 'react'
import Select from 'react-select'
import { Control, Controller, FieldErrors, UseFormRegister } from 'react-hook-form'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@mui/material/Radio'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import { isMobile } from 'react-device-detect'

import ReactInput from '../../reusable/ReactInput/ReactInput'
import { applications, BENCH_PROJECT } from '../../../constants'
import { customStyles } from '../../../helpers/customSelectStyles'
import { NewProject } from '../../../types'
import { IEditProjectInputs } from '../EditProject/EditProject'
import { IProjectInputs } from '../CreateProject/CreateProject'
import MobileEditProjectLinks from './mobileEditProjectLinks'
import DesktopEditProjectLinks from './desktopEditProjectLinks'

interface Props {
    selectedProject?: NewProject | undefined
    defaultValues: IEditProjectInputs | IProjectInputs
    register: UseFormRegister<IEditProjectInputs> | UseFormRegister<IProjectInputs>
    control:  Control<IEditProjectInputs> | undefined
    companies: { value: number | undefined, label: string }[]
    errors: FieldErrors
    managers: { value: number; label: string }[]
    tech_stack: { value: number | undefined, label: string }[]
}

const EditProjectForm: React.FC<Props> = ({
  selectedProject,
  register,
  control,
  companies,
  tech_stack,
  errors,
  managers,
}) => {
  return (
    <div className={isMobile && 'project-profile_mobile-edit-wrap'}>
      <div className="mrg-bottm24px">
        <ReactInput
          type="text"
          label="Name"
          disabled={selectedProject?.name == BENCH_PROJECT}
          registerControl="name"
          register={register}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
      </div>
      <div className="mrg-bottm24px">
        <ReactInput type="text" label="Description" register={register} registerControl="description" />
      </div>
      <div className="mrg-bottm24px">
        <ReactInput type="text" label="Industry" register={register} registerControl="industry" />
      </div>
      <div className={isMobile ? 'mrg-bottm24px' : 'select-edit-profile mrg-bottm24px'}>
        <div className={!isMobile ?
          'project-profile_links-wrap' : 'mrg-bottm24px'}>
          <div className="lbl-title">Manager</div>
          <Controller
            name="manager"
            control={control}
            render={({ field }) => (
              <Select
                isClearable={true}
                placeholder="Manager"
                {...field}
                options={managers}
                styles={customStyles(errors.manager)}
              />
            )}
          />
          {errors.manager && <p className="form_select__error">This field is required</p>}
        </div>
        <div className={!isMobile && 'project-profile_links-wrap'}>
          <div className="lbl-title">Customer</div>
          <Controller
            name="customer"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isClearable={true}
                placeholder="Company"
                options={companies}
                styles={customStyles(errors.customer)}
              />
            )}
          />
          {errors.customer && <p className="form_select__error">This field is required</p> }
        </div>
      </div>
      <div className="mrg-bottm24px">
        <div className="lbl-title">Tech Stack</div>
        <Controller
          name="technologies"
          control={control}
          render={({ field }) => (
            <Select
              isMulti
              isClearable={true}
              placeholder="Tech stack"
              {...field}
              options={tech_stack}
              styles={customStyles(errors.technologies)}
            />
          )}
        />
        {errors.technologies && <p className="form_select__error">This field is required</p> }
      </div>
      <div className={isMobile ?
        'project-profile_edit-buttons-mobile mrg-bottm24px undeline-general'
        :
        'project-profile_edit-buttons mrg-bottm24px undeline-general'
      }>
        <section className="project-profile_edit-radio-buttons">
          <div className="lbl-title">From scratch</div>
          <Controller
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            )}
            name="from_scratch"
            control={control}
          />
        </section>
        <section className="project-profile_edit-radio-buttons">
          <div className="lbl-title">Payment integration</div>
          <Controller
            render={({ field }) => (
              <RadioGroup row {...field}>
                <FormControlLabel
                  value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            )}
            name="payment_integration"
            control={control}
          />
        </section>
        <div className={errors.applications ? 'form_checkbox_group__error' : ''}>
          <FormControl component="fieldset">
            <div className="lbl-title">Application</div>
            <FormGroup row>
              <Controller
                name="applications"
                control={control}
                render={({ field }) => (
                  <>
                    {applications.map((app) => (
                      <FormControlLabel
                        {...field}
                        key={app}
                        label={app}
                        control={
                          <Checkbox
                            checked={field.value?.includes(app)}
                            onChange={() => {
                              if (!field.value?.includes(app)) {
                                field.onChange([...field.value, app])
                                return
                              }
                              const newApplications = field.value.filter((application) => application !== app)
                              field.onChange(newApplications)
                            }}
                          />
                        }
                      />
                    ))}
                  </>
                )}
              />
            </FormGroup>
            {errors.applications && <p className="form_select__error">Select at least one</p>}
          </FormControl>
        </div>
      </div>
      <div>
        <span className="project-profile_title-inputs">Add links</span>
        <div className="add-links-wrap">
          {
            !isMobile ?
              <DesktopEditProjectLinks
                register={register}
              />
              :
              <MobileEditProjectLinks
                register={register}
              />
          }
        </div>
      </div>
    </div>
  )
}

export default EditProjectForm
