import * as Yup from 'yup'

export const passwordValidation = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(6, 'Password must contain at least 6 characters')
    .max(40, 'Password must contain not more 40 characters'),
  submitPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
})
