import React from 'react'

import { DayOffIcon, ExtraIcon, PaidIcon, SickIcon, UnpaidIcon, VacationIcon, WorkOffIcon } from 'icons'

export const requestIcons = {
  sick_leaves: <SickIcon />,
  extra_sick_leaves: <ExtraIcon />,
  vacations: <VacationIcon />,
  paid_leaves: <PaidIcon />,
  unpaid_leaves: <UnpaidIcon />,
  day_offs: <DayOffIcon />,
  work_offs: <WorkOffIcon />
}
