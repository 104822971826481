import { createSlice } from '@reduxjs/toolkit'

import { Vacancy } from '../types'

const initialState = {
  indexData: <Vacancy[]>[],
  showData: <Vacancy>{},
  error: {},
  isLoading: true,
}

const vacanciesSlice = createSlice({
  name: 'vacancies',
  initialState,
  reducers: {
    fetchVacancies(state) {
      state.isLoading = true
    },
    fetchVacanciesSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchVacanciesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    getVacancy(state) {
      state.isLoading = true
    },
    getVacancySuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    getVacancyFailure(state, action) {
      state.error = action.payload
      state.isLoading= false
    },

    addVacancy(state) {
      state.isLoading = true
    },
    addVacancySuccess(state, action) {
      state.indexData.push(action.payload)
      state.isLoading = false
    },
    addVacancyFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    updateVacancy(state) {
      state.isLoading = true
    },
    updateVacancySuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    updateVacancyFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    removeVacancy(state) {
      state.isLoading = true
    },
    removeVacancySuccess(state, action) {
      state.indexData = state.indexData.filter((vacancy: Vacancy) => vacancy.id !== action.payload)
      state.isLoading = false
    },
    removeVacancyFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default vacanciesSlice.reducer
export const {
  fetchVacancies,
  fetchVacanciesFailure,
  fetchVacanciesSuccess,
  removeVacancy,
  removeVacancyFailure,
  removeVacancySuccess,
  addVacancy,
  addVacancyFailure,
  addVacancySuccess,
  updateVacancy,
  updateVacancyFailure,
  updateVacancySuccess,
  getVacancy,
  getVacancyFailure,
  getVacancySuccess
} = vacanciesSlice.actions
