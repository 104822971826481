import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Container, Col, Button, Label, Row, Input, Table } from 'reactstrap'
import Switch from 'react-switch'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { useGetCandidate } from '../../hooks/candidate/useGetCandidate'
import { useUpdateCandidate } from '../../hooks/candidate/useUpdateCandidate'
import { useRemoveCandidate } from 'hooks/candidate/useRemoveCandidate'
import { EditIcon, CopyIcon, TrashIcon } from '../../icons'
import { CandidateForm, CandidateNote } from '../../components/candidates'
import { englishLevel, significate } from '../../constants'
import successNotify from 'helpers/successNotify'
import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import { useGetTechnologies } from 'hooks/Technology/useGetTecnologies'
import Loader from '../../components/reusable/Loader'
import { webRoutes } from 'lib'
import { BiLinkExternal } from 'react-icons/bi'

const CandidateShow = () => {
  const [isFormOpen, setFormOpen] = useState(false)
  const [copied, _setCopied] = useState(false)
  const { showData: candidate, isLoading } = useSelector(({ candidates }) => candidates)
  const { indexData: technologies } = useSelector(({ technologies }) => technologies)
  const getCandidate = useGetCandidate()
  const updateCandidate = useUpdateCandidate()
  const removeCandidate = useRemoveCandidate()
  const getTechnologies = useGetTechnologies()
  const navigate = useNavigate()
  const candidateId = Number(useParams().candidateId)
  const { pathname } = useLocation()
  const toggleForm = () => setFormOpen(!isFormOpen)

  const setCopied = () => {
    _setCopied(true)
    setTimeout(() => _setCopied(false), 1000)
  }

  const convertedTechnologies = technologies?.filter((technology) => {
    return candidate.technologies?.some((item) => technology.name === item)
  })

  const techIds = convertedTechnologies.map(value => value.id)

  useEffect(() => {
    if (candidateId) {
      getCandidate(candidateId)
      getTechnologies()
    }
  }, [candidateId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (copied) successNotify('Copied!')
  }, [copied])

  const onPing = (value: boolean) => {
    const params = {
      id: candidateId,
      technologies: techIds,
      ping_again: value
    }
    updateCandidate(params)
  }

  const onNoteSave = (note: { [x: string]: string }) => {
    const params = {
      id: candidateId,
      technologies: techIds,
      ...note
    }

    updateCandidate(params)
  }

  const onSignificateUpdate = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const params = {
      id: candidateId,
      technologies: techIds,
      significate: value
    }
    updateCandidate(params)
  }

  const linkedInLink = 'https://www.linkedin.com/in/' + candidate.linkedin
  const telegramLink = 'https://t.me/' + candidate.telegram

  return (
    isLoading ? <Loader /> : <Container fluid="xxl">
      <div className="page-top">
        <div>{/* Breadcrumbs */}</div>
        <div className="ping">
          <Label>Ping again</Label>
          <Switch
            onColor="#32D583"
            checkedIcon={false}
            uncheckedIcon={false}
            width={50}
            offColor="#D4D9E2"
            height={30}
            onChange={onPing}
            checked={candidate.ping_again} />
        </div>
      </div>
      <Row>
        <Col md={4}>
          <div className="candidate-info">
            <div className="name">
              <h3>{candidate.full_name}</h3>
              <div className="name-buttons">
                <Button
                  color="light"
                  onClick={toggleForm}>
                  <EditIcon />
                </Button>
                <Button onClick={() => {
                  if (candidateId && confirm('Are you sure?')) {
                    removeCandidate(candidateId)
                    navigate(webRoutes.VACANCY(candidate.vacancy_id))
                  }
                }}>
                  <TrashIcon color="white" />
                </Button>
              </div>
            </div>
            <ul className="tech-info">
              <li><strong>Position:</strong> {candidate.position}</li>
              <li><strong>Old Stack:</strong> {candidate.stack}</li>
              <li><strong>Stack:</strong> {candidate.technologies?.join(', ')}</li>
            </ul>
            <Button tag={'a'} href={candidate.cv_url} color="primary">
              CV
            </Button>
            <div className="divider" />
            <ul className="contact-info">
              <li><strong>Phone:</strong> {candidate.phone}</li>
              <li>
                <strong>Email:</strong>&nbsp;
                <a rel="noreferrer" target="_blank" href={'mailto:' + candidate.email}>{candidate.email}</a>
                {candidate.email &&
                  <CopyToClipboard
                    text={candidate.email}
                    onCopy={setCopied}>
                    <button className="btn-copy"><CopyIcon /></button>
                  </CopyToClipboard>
                }
              </li>
              <li>
                <strong>Telegram:</strong>&nbsp;
                {candidate.telegram ?
                  <>
                    <a rel="noreferrer" href={telegramLink} target="_blank">Link<BiLinkExternal /></a>
                    <CopyToClipboard
                      text={telegramLink}
                      onCopy={setCopied}>
                      <button className="btn-copy"><CopyIcon /></button>
                    </CopyToClipboard>
                  </>
                  : ''
                }
              </li>
              <li>
                <strong>LinkedIn:</strong>&nbsp;
                {candidate.linkedin ?
                  <>
                    <a href={linkedInLink}>Link<BiLinkExternal /></a>
                    <CopyToClipboard
                      text={linkedInLink}
                      onCopy={setCopied}>
                      <button className="btn-copy"><CopyIcon /></button>
                    </CopyToClipboard>
                  </>
                  : ''
                }
              </li>
              <li><strong>Resource:</strong>&nbsp;
                {candidate.resource_url ?
                  <>
                    <a href={candidate?.resource_url?.startsWith('http')
                      ? candidate?.resource_url
                      : `https://${candidate?.resource_url}`}>Link<BiLinkExternal /></a>
                    <CopyToClipboard
                      text={candidate.resource_url}
                      onCopy={setCopied}>
                      <button className="btn-copy"><CopyIcon /></button>
                    </CopyToClipboard>
                  </>
                  : ''
                }
              </li>
              <li><strong>Salary:</strong>&nbsp;{candidate.salary && `$${candidate.salary}`}</li>
              <li>
                <strong>English:</strong> {candidate.english
                  ? englishLevel[candidate.english.toUpperCase() as keyof typeof englishLevel].label
                  : ''}
              </li>
              <li><strong>Location:</strong> {candidate.location}</li>
              <li><strong>Experience:</strong> {candidate.experience}</li>
              <li><strong>First Contact:</strong> {candidate.first_interview &&
                  dateFormatDDMMYY(candidate.first_interview)}</li>
              <li>
                <strong>Additional Link:</strong>&nbsp;
                {candidate.first_additional_link ?
                  <>
                    <a href={candidate.first_additional_link}>Link<BiLinkExternal /></a>
                    <CopyToClipboard
                      text={candidate.first_additional_link}
                      onCopy={setCopied}>
                      <button className="btn-copy"><CopyIcon /></button>
                    </CopyToClipboard>
                  </>
                  : ''
                }
              </li>
              <li>
                <strong>Additional Link:</strong>&nbsp;
                {candidate.second_additional_link ?
                  <>
                    <a href={candidate.second_additional_link}>Link<BiLinkExternal /></a>
                    <CopyToClipboard
                      text={candidate.second_additional_link}
                      onCopy={setCopied}>
                      <button className="btn-copy"><CopyIcon /></button>
                    </CopyToClipboard>
                  </>
                  : ''
                }
              </li>
            </ul>
          </div>
        </Col>
        <Col md={8}>
          <div className="candidate-notes">
            <div className="heading">
              <h3>Notes:</h3>
              <div className="significate">
                <Input
                  bsSize={'lg'}
                  className="w-100"
                  placeholder="Significate"
                  name="significate"
                  onChange={(e) => onSignificateUpdate(e)}
                  value={candidate.significate}
                  type="select">
                  {Object.values(significate).map((s) => (
                    <option key={s.value} value={s.value}>{s.name}</option>
                  ))}
                </Input>
              </div>
            </div>
            <div className="notes">
              <Table borderless>
                <tbody>
                  <tr className="note-title">
                    <th>Recruiter&apos;s note</th>
                    <th />
                  </tr>
                  <CandidateNote
                    onSave={onNoteSave}
                    inputName={'recruiter_note'}
                    note={candidate.recruiter_note} />
                  <tr className="note-title">
                    <th>Tech lead note</th>
                    <th />
                  </tr>
                  <CandidateNote
                    onSave={onNoteSave}
                    inputName={'tech_lead_note'}
                    note={candidate.tech_lead_note} />
                  <tr className="note-title">
                    <th>Final Decision</th>
                    <th />
                  </tr>
                  <CandidateNote
                    onSave={onNoteSave}
                    inputName={'final_decision'}
                    note={candidate.final_decision} />
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
      <CandidateForm
        isNewRecord={false}
        isOpen={isFormOpen}
        toggle={toggleForm}
        vacancyId={candidate.vacancy_id}/>
    </Container>
  )
}

export default CandidateShow
