import React from 'react'
import { toast, ToastContentProps } from 'react-toastify'

interface Toast {
  success:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | ((props: ToastContentProps) => React.ReactNode)
    | null
    | undefined
}

const successNotify = (success: Toast | string) =>
  toast.success(success, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })

export default successNotify
