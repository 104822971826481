import React, { useEffect, useState } from 'react'
import { usePDF } from '@react-pdf/renderer'
import { useNavigate, useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import { IResume } from '../../../../../types/Resume/IResume'
import { cvNavLinks } from '../../../../../constants'
import CvDocument from '../CvDocument'
import PreviewDesktop from './PreviewDesktop'
import Loader from '../../../../reusable/Loader'
import { useAuth } from '../../../../../context/auth'
import './cvPreviewStyles.scss'
import MobileNavigation from '../MobileNavigation'
import MobileCustomNavbar from './MobileCustomNavbar'
import DesktopCvNavbar from './DesktopCvNavbar'
import { getUserCv } from '../../../../../services/API/admin/resume'
import LoaderForClients from '../../../../reusable/Loader/LoaderForClients'
import { webRoutes } from '../../../../../lib'
import { useDocCv } from 'hooks/resume/useDocCv'

const CvPreview = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const params = useParams()
  const [resume, setResume] = useState<IResume>()
  const document = resume?.user_id && <CvDocument resume={resume} />
  const [instance, update] = usePDF({ document } as never)
  const onlyUpperLetters = resume?.user_full_name.trim().match(/[A-Z]/g)
  const firstLetterOfLastName = onlyUpperLetters && onlyUpperLetters[1]
  const downloadFileName = `CV_${resume?.name}${firstLetterOfLastName}.pdf`

  const { downloadDocCv } = useDocCv({ resume, filename: downloadFileName.split('.').shift() })

  useEffect(update, [resume])

  useEffect(() => {
    if (params.cvId) {
      getUserCv(Number(params.cvId)).then((response) => setResume(response.data as IResume))
    }
  }, [params.cvId])

  const onEditUserCv = () => {
    navigate(`${webRoutes.RESUMES}/${resume?.id}${cvNavLinks.CONTACT_SECTION}?userId=${resume?.user_id}`)
  }
  const onHandleBackToProfile = () => {
    navigate(`/users/${resume?.user_id}`)
  }

  if (!instance.blob)
    return (
      <div className="loader_wrapper">
        <LoaderForClients />
      </div>
    )

  return (
    <>
      {!isMobile && (
        <>
          <DesktopCvNavbar
            resume={resume}
            currentUser={currentUser}
            instance={instance}
            onEditUserCv={onEditUserCv}
            downloadFileName={downloadFileName}
            downloadDoc={downloadDocCv}
          />
          {!resume?.name ? <Loader /> : <PreviewDesktop resume={resume} />}
        </>
      )}
      {isMobile && (
        <>
          <div className="navbar">
            <MobileCustomNavbar
              downloadFileName={downloadFileName}
              resume={resume}
              currentUser={currentUser}
              instance={instance}
              onEditUserCv={onEditUserCv}
              onHandleBackToProfile={onHandleBackToProfile}
            />
          </div>
          <MobileNavigation resume={resume} />
        </>
      )}
    </>
  )
}

export default CvPreview
