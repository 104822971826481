import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { updateCvBook } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'

export const useUpdateCvBook = () => {
  const getUserCv = useGetUserCv()
    
  return (book: FormData, bookId: number, cvId: number ) => {
    updateCvBook(book, bookId)
      .then(() => {
        successNotify('Book updated')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Update error'))
  }
}
