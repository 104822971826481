import React, { useState } from 'react'
import { PropsValue } from 'react-select'
import { AxiosRequestConfig } from 'axios'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

import { Status } from 'types/Status'
import { Category, Project, User } from 'types'
import { switchByDate } from 'helpers/tabHeaderRequests'
import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import { WeekDay } from '../../../../constants'
import DoubleSelect from 'components/custom/DoubleSelect/DoubleSelect'
import { IOptions } from 'types/ISelectOptions'
import { SelectType } from 'enums/SelectTypes'
import ExportCSV from '../ExportCSV/ExportCSV'
import ExportSalaryCSV from '../ExportCSV/ExportSalaryCSV'
import { dateFormatYYMM } from 'helpers/displayingDateFormat'
import { IOmitedOptions } from 'containers/MonthlyReportContainer/MonthlyReportContainer'
import { Pages } from 'enums/StartPage'
import { FilterSwitch } from 'components/reusable/FIlterSwitch'

interface Props {
  variantTypes: string[]
  month: Date
  setMonth: React.Dispatch<React.SetStateAction<Date>>
  allStatuses?: Status[]
  usersNames: User[]
  projects: Project[]
  typeValue: PropsValue<IOptions> | undefined
  setTypeValue: React.Dispatch<React.SetStateAction<PropsValue<IOptions> | undefined>>
  selectedValue: PropsValue<IOptions> | null
  setSelectedValue: React.Dispatch<React.SetStateAction<IOmitedOptions[] | null>>
  alignment: string
  setAlignment: React.Dispatch<React.SetStateAction<string>>
  selectRef?: React.MutableRefObject<undefined>
  paramsType: (limit: number | undefined, page: number | undefined) => AxiosRequestConfig
  setPage: React.Dispatch<React.SetStateAction<Pages>>
}

const MonthlyReportFilters: React.FC<Props> = ({
  month,
  setMonth,
  allStatuses,
  usersNames,
  projects,
  selectedValue,
  setSelectedValue,
  typeValue,
  setTypeValue,
  alignment,
  setAlignment,
  paramsType,
  selectRef,
  variantTypes,
  setPage
}) => {
  const onChangeDate = (value: Date) => {
    localStorage.setItem('monthly_report_date', String(value))
    setMonth(value)
  }
  const [active, setActive] = useState('month')
  const categories = useSelector((state: { categories: { indexData: Category[] } }) => state.categories.indexData)

  const handleSelectChange = (value: IOmitedOptions[]) => {
    setSelectedValue(value)
    localStorage.setItem('filter_array', JSON.stringify(value))
  }

  const arrowNextItem = () => {
    alignment === 'month'
      ? setMonth(new Date(new Date(month).setMonth(new Date(month).getMonth() + WeekDay.OneDay)))
      : setMonth(new Date(new Date(month).setDate(new Date(month).getDate() + WeekDay.OneDay)))
    setPage(Pages.START_PAGE)
  }
  const arrowBackItem = () => {
    alignment === 'month'
      ? setMonth(new Date(new Date(month).setMonth(new Date(month).getMonth() - WeekDay.OneDay)))
      : setMonth(new Date(new Date(month).setDate(new Date(month).getDate() - WeekDay.OneDay)))
    setPage(Pages.START_PAGE)
  }

  const usersList: IOptions[] = usersNames
    .map((user) => ({
      label: user.full_name,
      value: user.id,
      type: SelectType.USER
    }))

  const projectsList: IOmitedOptions[] = projects?.map((project) => ({
    label: project.name,
    value: project.id,
    type: SelectType.PROJECT
  }))

  const categoriesList: IOmitedOptions[] = categories?.map((category) => ({
    label: category.name,
    value: category.id,
    type: SelectType.CATEGORY
  }))

  const jobStatusList: IOmitedOptions[] | undefined = allStatuses?.map((status) => ({
    label: status.name,
    value: status.id,
    type: SelectType.STATUS
  }))

  const changeTab = (name: string) => {
    setActive(name)
    setAlignment(name)
  }

  return (
    <div className={`${!isMobile ? 'admin-report__filters_container' : 'admin-report__filters_mobile'}`}>
      <FilterSwitch changeTab={changeTab} active={alignment} />
      {alignment === 'month' && (
        <CustomDatepicker
          value={month}
          onChange={(value) => onChangeDate(value as Date)}
          mask={'__.__.____'}
          inputType="day"
          maxDate={new Date()}
          datePickerType="month"
          borderInputColor="#D4D9E2"
          arrowNextItem={arrowNextItem}
          arrowBackItem={arrowBackItem}
          disableClearButton={true}
        />
      )}
      {alignment === 'day' && (
        <CustomDatepicker
          value={month}
          onChange={(value) => onChangeDate(value as Date)}
          inputType="day"
          maxDate={new Date()}
          datePickerType="day"
          borderInputColor="#D4D9E2"
          arrowNextItem={arrowNextItem}
          arrowBackItem={arrowBackItem}
          disableClearButton={true}
        />
      )}
      <div className={`${!isMobile ? 'admin-report__filters_select' : 'admin-report__filters_mobile_select'}`}>
        {isMobile && <h5>Filter by:</h5>}
        <DoubleSelect
          onChange={handleSelectChange}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          typeValue={typeValue}
          setTypeValue={setTypeValue}
          users={usersList}
          categories={categoriesList}
          projects={projectsList}
          statuses={jobStatusList}
          selectRef={selectRef}
          variants={variantTypes}
          isMulti
        />
      </div>
      {!isMobile && (
        <div className="admin-report__csv_buttons">
          <ExportSalaryCSV
            month={dateFormatYYMM(month)}
            filename={`${dateFormatYYMM(month)}-monthly-salary-report.csv`} />
          <ExportCSV
            paramsType={paramsType}
            filename={`${dateFormatYYMM(month)}-monthly-report.csv`}
          />
        </div>
      )}
    </div>
  )
}

export default MonthlyReportFilters
