import { useDispatch } from 'react-redux'

import { updateCandidate as updateCandidateService } from 'services/API/candidates'
import { Candidate } from 'types'
import { updateCandidateSuccess, updateCandidateFailure } from '../../store/candidatesSlice'
import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'

export const useUpdateCandidate = () => {
  const dispatch = useDispatch()

  return (candidate: Partial<Candidate>) => {
    updateCandidateService(candidate)
      .then((response) => {
        dispatch(updateCandidateSuccess(response.data))
        successNotify('Candidate updated')
      })
      .catch((error) => {
        dispatch(updateCandidateFailure(error))
        dangerNotify('Candidate update error.')
      })
  }
}
