import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Category, Project, User } from 'types'
import { ReadyRecord } from 'types/ReadyRecord'
import { dateFormatDDMMYY, dateFormatMM } from 'helpers/displayingDateFormat'
import { renderCategory, renderManager, renderProject, renderProjectId, renderUser } from 'helpers/renderName'
import { webRoutes } from 'lib'
import { colorizeJobStatus } from 'helpers/jobStatusStyling'
import { MonthlyReport } from 'types/MonthlyReport'

interface Props {
  records?: ReadyRecord[]
  record: MonthlyReport | ReadyRecord
  categories?: Category[]
  currentUser?: User
  projects: Project[]
}

const ReportCard: React.FC<Props> = ({ categories, projects, record }) => { 
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const location = useLocation()
  const userReportPage = location.pathname === webRoutes.MY_REPORT

  const onSearchRecord = (record: MonthlyReport) => {
    localStorage.setItem('user_monthly_report', record?.user as string)
    localStorage.setItem('user_full_name_monthly_report', renderUser(record.user as number, usersNames) as string)
    localStorage.setItem('project_monthly_report', renderProjectId(record?.project, projects))
    localStorage.setItem('project_name_monthly_report', record?.project as string),
    dateFormatMM(localStorage.getItem('monthly_report_date'))
  }
  
  return (
    <>
      <div className="mobile-card__container report-card">
        <div className="report-card__info">
          <div className="mobile-card__container_header report-card__header">
            <h5>{userReportPage ? 
              renderProject(record.project_id, projects) : 
              <Link to={`/users/${record.user}`}>{renderUser(record.user, usersNames)}</Link>}
            </h5>
          </div>
          <div className="mobile-card__container_body">
            {userReportPage ? 
              <>
                <h6>Manager: {renderManager(record.project_id, projects, usersNames)}</h6>
                <h6>Category: {renderCategory(record.category_id, categories)}</h6>
                <h6>Date: {dateFormatDDMMYY(record.date)}</h6>
                <h6>Description: {record.description}</h6>
              </>
              : <>
                <h6>Project: {record.project}</h6>
                <h6>Manager: {renderUser(record.manager, usersNames)}</h6>
              </>} 

          </div>
        </div>
        <div className="report-card__hours">
          <h6>Hours: </h6>
          <h3>{userReportPage ? record.hours : 
            <Link
              to={webRoutes.ADMIN_MONTHLY_CALENDAR(record.user)}
              onClick={() => onSearchRecord(record)}>
              {record.hours.toFixed(1)}
            </Link>}
          </h3>
          <span style={colorizeJobStatus(record.job_status)}>{record.job_status}</span>
        </div>
      </div>
    </>
  )
}

export default ReportCard
