import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { User } from 'types/User'
import { Project } from 'types/Project'
import { Status } from 'types/Status'
import { tableCellStyles, tableHeadStyles } from 'utilts/styles'
import { renderProject, renderStatus, renderManager } from '../../../helpers/renderName'
import { jobStatusesNames, userRoles, BENCH_MANAGER, BENCH_PROJECT } from '../../../constants'
import { DisplayingItemInList } from '../ManageUsers/DisplayingItemInList'

interface Props {
  users: User[]
  projects: Project[]
  statuses: Status[]
  onLinkClickHandler: () => void
}

const UsersList: React.FC<Props> = ({ users, projects, statuses, onLinkClickHandler }) => {
  let numbering = 1
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)

  const usersWithFreeStatus = users.filter(user =>
    user.role === userRoles.DEFAULT.id &&
      user.available_contracts.length === 1 && user.available_contracts.some(contract =>
      renderProject(contract.project_id, projects) === BENCH_PROJECT))

  const usersWithoutOutstaffStatus = users.filter(
    (user) =>
      user.available_contracts.length > 1 &&
        !user.available_contracts.some(contract =>
          renderStatus(contract.job_status_id, statuses) === jobStatusesNames.OUTSTAFF
        )
  )

  const usersWithOutstaffStatus = users.filter(user =>
    user.available_contracts.length > 1 && user.available_contracts.some(contract =>
      renderStatus(contract.job_status_id, statuses) === jobStatusesNames.OUTSTAFF
    ),
  ).sort((a, b) => (a.full_name > b.full_name ? 1 : -1))

  const usersWithoutContracts = users.filter(user =>
    (user.available_contracts.length === 1 && user.available_contracts.some(contract =>
      renderProject(contract.project_id, projects) === BENCH_PROJECT) &&
          (user.role != userRoles.DEFAULT.id))
  )

  const sortedUsersByStatus = [
    ...usersWithFreeStatus,
    ...usersWithoutOutstaffStatus,
    ...usersWithOutstaffStatus,
    ...usersWithoutContracts
  ]

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={tableHeadStyles}>
              #
            </TableCell>
            <TableCell align="center" sx={tableHeadStyles}>
              Full Name
            </TableCell>
            <TableCell align="center" sx={tableHeadStyles}>
              Project
            </TableCell>
            <TableCell align="center" sx={tableHeadStyles}>
              Manager
            </TableCell>
            <TableCell align="center" sx={tableHeadStyles}>
              Job Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsersByStatus?.map((user) => {
            const isOutstaffStatus = user.available_contracts.some(c =>
              renderStatus(c.job_status_id, statuses) === jobStatusesNames.OUTSTAFF)
            return (
              <TableRow key={user.id} className={isOutstaffStatus ? 'Outstaff_color' : ''}>
                <TableCell align="center" sx={tableCellStyles}>
                  {numbering++}
                </TableCell>
                <TableCell align="center" sx={tableCellStyles}>
                  <Link to={`/users/${user.id}`} state={{ slug: user.slug }} onClick={() => onLinkClickHandler()}>
                    {user.full_name}
                  </Link>
                </TableCell>
                <TableCell align="center" sx={tableHeadStyles}>
                  {user?.available_contracts?.map((contract) => (
                    <DisplayingItemInList
                      contract={contract}
                      projects={projects}
                      user={user}
                      key={contract.id}
                      renderFunc={renderProject}
                      value={BENCH_PROJECT}
                      users={users}
                    />
                  ))}
                </TableCell>
                <TableCell align="center" sx={tableHeadStyles}>
                  {user?.available_contracts?.map(contract => (
                    <DisplayingItemInList
                      contract={contract}
                      projects={projects}
                      user={user}
                      key={contract.id}
                      renderFunc={renderManager}
                      value={BENCH_MANAGER}
                      users={usersNames}
                    />
                  ))}
                </TableCell>
                <TableCell align="center" sx={tableHeadStyles}>
                  {user?.available_contracts?.map(contract => (
                    (renderProject(contract.project_id, projects) != BENCH_PROJECT) &&
                      (user?.available_contracts.length > 1) ?
                      <div key={contract.id} className="user-list__multiply">
                        {contract && renderStatus(contract.job_status_id, statuses)}
                      </div> :
                      (renderProject(contract.project_id, projects) === BENCH_PROJECT) &&
                          (user?.available_contracts.length === 1) && user.role === userRoles.DEFAULT.id &&
                        <div className="user-list__multiply" key={contract.id}>
                          Free
                        </div>
                  ))}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(UsersList)
