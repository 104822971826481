import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { updateCvLanguage } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'
import { IResumeLanguage } from '../../../types/Resume/IResumeLanguage'

export const useUpdateCvLanguage = () => {
  const getUserCv = useGetUserCv()
    
  return (language: IResumeLanguage, languageId: number, cvId: number ) => {
    updateCvLanguage(language, languageId)
      .then(() => {
        successNotify('Language updated')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Update error'))
  }
}
