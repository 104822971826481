import API from '../..'
import ROUTES from '../../../../routes'

export const createCategory = (data: { categoryName: string }) => {
  return API.post(ROUTES.ADMIN_CATEGORIES, {
    category: {
      name: data.categoryName,
    },
  })
}

export const deleteCategory = (id: number) => {
  return API.delete(ROUTES.ADMIN_CATEGORIES + `/${id}`)
}

export const searchCategory = (type: string) => {
  return API.get(ROUTES.ADMIN_SEARCH_CATEGORY, { params: { search: type } })
}
