import React from 'react'
import { Document, Page, Text, View, Image, Font, Link } from '@react-pdf/renderer'

import { IResume } from '../../../../types/Resume/IResume'
import { defaultBookCover, dot, rectangle, defaultAvatar } from '../../../../images/index'
import { ManropeRegular, ManropeBold, ManropeSemiBold, ManropeExtraBold } from '../../../../fonts/index'
import { styles } from './cvStyles'
import { dateFormatMMYY } from '../../../../helpers/displayingDateFormat'

interface Props {
  resume: IResume
}

const CvDocument: React.FC<Props> = ({ resume }) => {
  Font.register({
    family: 'ManropeRegular',
    src: ManropeRegular
  })
  Font.register({
    family: 'ManropeBold',
    src: ManropeBold
  })
  Font.register({
    family: 'ManropeSemiBold',
    src: ManropeSemiBold
  })
  Font.register({
    family: 'ManropeExtraBold',
    src: ManropeExtraBold
  })

  return (
    <Document>
      <Page style={styles.cv_page} size={[940, 1330]}>
        <View style={styles.cv_header}>
          <View style={styles.avatar_wrapper}>
            {resume.avatar ?
              <Image style={styles.avatar} src={resume.avatar}/> :
              <Image style={styles.avatar} src={defaultAvatar}/>
            }
          </View>
          <View style={styles.cv_author}>
            <Text style={styles.user_name}>
              {resume.name}
            </Text>
            <Text style={styles.user_summary}>
              {resume.summary}
            </Text>
          </View>
        </View>
        <View style={styles.page_wrapper}>
          <View style={styles.left_side}>
            <View style={styles.name_wrapper}>
              <Text style={styles.name}>
                {resume.name}
              </Text>
            </View>
            <View style={styles.user_data}>
              <Text style={styles.user_data__title}>
                Role: {' '}<Text style={styles.user_data__subtitle}>{resume.role}</Text>
              </Text>
              {resume.github_url &&
                <Text style={styles.user_data__title}>
                    Github: {' '}
                  <Link style={styles.user_data__subtitle} src={resume && resume.github_url}>
                    {resume.github_url}
                  </Link>
                </Text>
              }
              <Text style={styles.user_data__title}>
                Location: {' '}<Text style={styles.user_data__subtitle}>{resume.location}</Text>
              </Text>
            </View>
            <View style={styles.border_line} />
            <View>
              <Text style={styles.block_title}>
                Skills:
              </Text>
              {resume?.skills?.map(skillName => (
                <View key={skillName.id} style={styles.skill_name__item}>
                  <Text style={styles.skill_name}>
                    {skillName.skill_set}
                  </Text>
                  <View>
                    {skillName.skills.map((skill, index) => (
                      <View key={index} style={styles.skill_items} wrap={false}>
                        <Image src={rectangle} style={styles.rectangle} />
                        <Text style={styles.skill_item}>
                          {skill}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              ))}
              <View style={styles.border_line} />
            </View>
            <View>
              <Text style={styles.block_title}>
                Languages:
              </Text>
              {resume?.languages?.map(language => (
                <View key={language.id} style={styles.language_item}>
                  <Text style={styles.language_name}>
                    {language.name}
                  </Text>
                  <Text style={styles.language_level}>
                    {language.level}
                  </Text>
                </View>
              ))}
              <View style={styles.border_line} />
            </View>
            {resume?.certificates?.length > 0 &&
              <View>
                <Text style={styles.block_title}>
                  Certifications:
                </Text>
                {resume?.certificates?.map(certificate => (
                  <View key={certificate.id} style={styles.certificate_item}>
                    <Text style={styles.certificate_name}>
                      {certificate.name}
                    </Text>
                    <Text>
                      <Link src={certificate.url} style={styles.certificate_link}>
                        {certificate.url}
                      </Link>
                    </Text>
                  </View>
                ))}
                <View style={styles.border_line} />
              </View>
            }
            <View>
              <Text style={styles.block_title}>
                Books:
              </Text>
              {resume?.books?.map(book => (
                <View key={book.id} style={styles.book_items}>
                  <View style={styles.book_item} wrap={false}>
                    <View style={styles.book_cover}>
                      {!book.cover ?
                        <Image style={styles.book_image} src={defaultBookCover} /> :
                        <Image style={styles.book_image} src={book.cover} />
                      }
                    </View>
                    <View style={styles.book_inner}>
                      <Text style={styles.book_title}>
                        {book.title}
                      </Text>
                      <Text style={styles.book_author}>
                        {book.author}
                      </Text>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.right_side}>
            <View style={styles.cv_block}>
              <Text style={styles.cv_block__name}>
                Experience:
              </Text>
            </View>
            {resume?.experiences?.map(experience => (
              <View key={experience.id} style={styles.experience_item}>
                <Text style={styles.experience_job_title}>
                  {experience.job_title}
                </Text>
                <Text style={styles.experience_company}>
                  {experience.company}
                </Text>
                <Text style={styles.experience_interval}>
                  {dateFormatMMYY(experience.job_started_on)} -
                  {' '}{experience.job_ended_on ? dateFormatMMYY(experience.job_ended_on) : 'Ongoing'}
                </Text>
                <View>
                  {experience.responsibilities.map((responsibility, index) => (
                    <View key={index} style={styles.experience_responsibilities_list} wrap={false}>
                      <Image src={dot} style={styles.experience_responsibility_before}/>
                      <Text style={styles.experience_responsibility}>
                        {responsibility.trim()}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
            <View style={styles.education_wrapper}>
              <View style={styles.cv_block}>
                <Text style={styles.cv_block__name}>
                  Education:
                </Text>
              </View>
              {resume?.educations?.map(education => (
                <View key={education.id}>
                  <Text style={styles.education_university}>
                    {education.university_name}
                  </Text>
                  <Text style={styles.education_level}>
                    {education.level}
                  </Text>
                  <View style={styles.education_interval}>
                    <Text>
                      {dateFormatMMYY(education.start_date)} - {dateFormatMMYY(education.end_date)}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View>
              <View style={styles.cv_block}>
                <Text style={styles.cv_block__name}>
                  Projects:
                </Text>
              </View>
              {resume?.resume_projects?.map(project => (
                <View key={project.id} style={styles.project_item}>
                  <Text style={styles.project_title}>
                    {project.title}
                  </Text>
                  <View>
                    {project.role &&
                      <Text style={styles.project_role}>
                        {project.role}
                      </Text>
                    }
                  </View>
                  <View>
                    {project.url &&
                      <Text style={styles.project_url}>
                        <Link style={styles.project_link} src={project.url ? project.url : ''} >
                          {project.url}
                        </Link>
                      </Text>
                    }
                  </View>
                  <View style={styles.project_stack__wrapper}>
                    {!project.stack[0] ? null : project.stack.map((item, index) =>
                      <View key={index} style={styles.project_stack__item} wrap={false}>
                        <Text>
                          {item}
                        </Text>
                      </View>
                    )}
                  </View>
                  <View>
                    <Text style={styles.project_description}>
                      {project.description}
                    </Text>
                  </View>
                  <View>
                    {project.responsibilities &&
                      <View style={styles.responsibilities_wrapper}>
                        <Text style={styles.project_responsibilities__title}>
                          Responsibilities:
                        </Text>
                        <Text style={styles.project_responsibilities}>
                          {project.responsibilities}
                        </Text>
                      </View>
                    }
                  </View>
                  <View>
                    {project.team &&
                      <View>
                        <Text style={styles.project_team__title}>
                          Team:
                        </Text>
                        <Text style={styles.project_team}>
                          {project.team}
                        </Text>
                      </View>
                    }
                  </View>
                </View>
              ))}
            </View>
            {resume?.courses?.length > 0 &&
              <View>
                <View style={styles.cv_block} wrap={false}>
                  <Text style={styles.cv_block__name}>
                    Training and Courses:
                  </Text>
                </View>
                {resume?.courses?.map(course => (
                  <View key={course.id} style={styles.course_item}>
                    <View>
                      <Text style={styles.course_name}>
                        {course.name}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.course_license}>
                       License: {course.license}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.course_issued}>
                        Issued: {dateFormatMMYY(course.acquire_date)}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.course_stack}>
                        Tech stack: {course.stack.map((item, index) =>
                          `${item.trim()}${index < course.stack.length - 1 ? ', ' : ''}`)}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            }
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default CvDocument
