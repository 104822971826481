const EPOCH = new Date(0)
const EPOCH_YEAR = EPOCH.getUTCFullYear()
const EPOCH_MONTH = EPOCH.getUTCMonth()

export const calculateAge = (value: Date, endDate?: string | undefined) => {
  const date = !endDate ? Date.now() : new Date(endDate).getTime()
  const diff = new Date(date - value.getTime())
  return {
    years: Math.abs(diff.getUTCFullYear() - EPOCH_YEAR),
    months: Math.abs(diff.getUTCMonth() - EPOCH_MONTH)
  }
}

export const declension = (years: number, array: string[], cases = [2, 0, 1, 1, 1, 2]) =>
  array[(years % 100 > 4 && years % 100 < 20) ? 2 : cases[(years % 10 < 5) ? years % 10 : 5]]
