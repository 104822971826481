import React from 'react'
import Box from '@mui/material/Box'
import Select from 'react-select'

import { Status, Project, User } from '../../../types'
import { useGetContracts } from '../../../hooks/contract/useGetContracts'
import { BENCH_MANAGER, BENCH_PROJECT, getContractsParams, jobStatusesNames } from '../../../constants'
import { contractFiltersSelectStyles } from '../../../helpers/customSelectStyles'

export interface OptionTypes {
  value: number
  label: string
}

type ContractsFiltersProps = {
  statuses: Status[]
  projects: Project[]
  usersNames: User[]
}

const ContractsFilters: React.FC<ContractsFiltersProps> = ({ statuses, projects, usersNames }) => {
  const getContractsHook = useGetContracts()

  const onUserChange = (option: number | undefined) => {
    getContractsHook(option, getContractsParams.projectId, getContractsParams.statusId)
  }
  const onProjectChange = (projectId: number | undefined) => {
    getContractsHook(getContractsParams.userId, projectId, getContractsParams.statusId)
  }
  const onJobStatusChange = (statusId: number | undefined) => {
    getContractsHook(getContractsParams.userId, getContractsParams.projectId, statusId)
  }

  const usersSelectedNames: OptionTypes[] =
      usersNames?.filter(u => u.full_name != BENCH_MANAGER && u.active).map((user) => ({
        label: user.full_name,
        value: user.id,
      }))
  const projectsSelectedNames: OptionTypes[] = projects?.filter(p => p.name != BENCH_PROJECT).map((project) => ({
    label: project.name,
    value: project.id,
  }))
  const jobStatusSelectedNames: OptionTypes[] =
    statuses?.filter(s => s.name != jobStatusesNames.FREE).map((status) => ({
      label: status.name,
      value: status.id,
    }))

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Select
          isClearable={true}
          placeholder="Users"
          options={usersSelectedNames}
          onChange={(option) => onUserChange((option as OptionTypes)?.value)}
          styles={contractFiltersSelectStyles}
        />
        <Select
          isClearable={true}
          placeholder="Project"
          options={projectsSelectedNames}
          onChange={(option) => onProjectChange((option as OptionTypes)?.value)}
          styles={contractFiltersSelectStyles}
        />
        <Select
          isClearable={true}
          placeholder="Status"
          options={jobStatusSelectedNames}
          onChange={(option) => onJobStatusChange((option as OptionTypes)?.value)}
          styles={contractFiltersSelectStyles}
        />
      </Box>
    </>
  )
}

export default ContractsFilters
