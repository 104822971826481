import { useDispatch } from 'react-redux'

import { fetchUsers, 
  fetchUsersFailure, 
  fetchUsersNames, 
  fetchUsersNamesFailure, 
  fetchUsersNamesSuccess, 
  fetchUsersSuccess } from '../../store/usersSlice'
import { getUsers, getUsersNames } from '../../services/API/admin/users'
import dangerNotify from '../../helpers/dangerNotify'

export const useGetUsers = () => {
  const dispatch = useDispatch()

  return (
    active: string,
    user_id: number | undefined,
    project_id: number | undefined,
    status_id: number | undefined,
    manager_id: number | undefined
  ) => [
    dispatch(fetchUsers()),
    getUsers(active, user_id, project_id, status_id, manager_id)
      .then((response) => {
        dispatch(fetchUsersSuccess(response?.data))
      })
      .catch((error) => {
        dispatch(fetchUsersFailure(error))
        dangerNotify(error)
      }),
  ]
}

export const useGetUsersNames = () => {
  const dispatch = useDispatch()

  return () => [
    dispatch(fetchUsersNames()),
    getUsersNames()
      .then((response) => {
        dispatch(fetchUsersNamesSuccess(response?.data))
      })
      .catch((error) => {
        dispatch(fetchUsersNamesFailure(error))
        dangerNotify(error)
      }),
  ]
}
