import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { createResumeEducation } from '../../../services/API/admin/resume'
import { IResumeEducation } from '../../../types/Resume/IResumeEducation'
import { useGetUserCv } from '../useGetUserCv'

export const useCreateResumeEducation = () => {
  const getUserCv = useGetUserCv()

  return (education: IResumeEducation, cvId: number) => {
    createResumeEducation(education)
      .then(() => {
        successNotify('Education created')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Please fill in the contact section'))
  }
}
