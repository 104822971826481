import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { UseFormRegister } from 'react-hook-form'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

import ReactInput from '../../../../reusable/ReactInput'
import { IPortfolioProjectInputs } from './CvAddPortfolioProject'
import AddInputField from '../AddInputField'

interface IProps {
  inputFields: { value: string }[]
  isDisabled: boolean
  setInputFields: Dispatch<SetStateAction<{ value: string }[]>>
  register: UseFormRegister<IPortfolioProjectInputs>
}

const CvPortfolioProjectForm: React.FC<IProps> = ({ inputFields, isDisabled, setInputFields, register }) => {
  const divRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: 'smooth' })
  })

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <ReactInput className="modal__input" type="text" label="Title" register={register} registerControl="title" />
        <ReactInput
          className="modal__input"
          type="text"
          label="Url (optional)"
          register={register}
          registerControl="url"
        />
        <ReactInput
          className="modal__input"
          type="text"
          label="Description"
          register={register}
          registerControl="description"
        />
        <ReactInput className="modal__input" type="text" label="Role" register={register} registerControl="role" />
        <ReactInput className="modal__input" type="text" label="Team" register={register} registerControl="team" />
        <TextField
          className="modal__input"
          fullWidth
          multiline
          {...register('responsibilities')}
          label="Responsibilities"
          InputProps={{
            rows: 3,
          }}
        />
      </Box>
      <Box sx={{ fontSize: '20px', marginTop: '15px', textAlign: 'center', fontWeight: '500' }}>Stack:</Box>
      <div className="added_div_portfolio">
        {inputFields?.map((input, index) => {
          return (
            <AddInputField
              inputFields={inputFields}
              isDisabled={isDisabled}
              setInputFields={setInputFields}
              key={index}
              input={input}
              index={index}
              placeholder="Technology"
            />
          )
        })}
        <div ref={divRef} />
      </div>
    </>
  )
}

export default CvPortfolioProjectForm
