import { createSession } from '../../services/API/session'
import { Session } from '../../types/Session'
import { useAuth } from '../../context/auth'
import dangerNotify from '../../helpers/dangerNotify'
import { useNavigate } from 'react-router-dom'
import { webRoutes } from '../../lib'
import { isAdmin, isManager } from 'helpers/accessHelpers'

export const useCreateSession = () => {
  const { setCurrentUser, setAuthToken, currentUser } = useAuth()
  const navigate = useNavigate()

  return (session: Session, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    setLoading(true)
    createSession(session)
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data.user))
        localStorage.setItem('monthly_report_date', String(new Date()))
        setAuthToken(response.data.token)
        setCurrentUser(response.data.user)
        setLoading(false)
        isManager(currentUser) || isAdmin(currentUser)
          ? navigate(webRoutes.ADMIN_MAIN)
          : navigate(webRoutes.SUBMIT_HOURS)
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message
        dangerNotify(errorMessage)
        setLoading(false)
      })
  }
}
