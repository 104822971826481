import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { changeCvSkillSetSorting } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'

export const useChangeCvSkillSetSorting = () => {
  const getUserCv = useGetUserCv()

  return (
    prevCvSkillSetId: number,
    lastCvSkillSetId: number,
    prevSkillSetPosition: number,
    lastSkillSetPosition: number,
    resumeId: number
  ) => {
    changeCvSkillSetSorting(prevCvSkillSetId, lastCvSkillSetId, prevSkillSetPosition, lastSkillSetPosition)
      .then(() => {
        getUserCv(resumeId)
        successNotify('Sorting changed!')
      })
      .catch((error) => dangerNotify(error.response.data.message[0]))
  }
}
