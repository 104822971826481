import React from 'react'
import { isMobile } from 'react-device-detect'

const TechStackIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17 2H3C2.44771 2 2 2.44772 2 3V17C2 17.5523 
        2.44772 18 3 18H17C17.5523 18 18 17.5523 18 17V3C18 2.44771 17.5523 2 17 2ZM3 0C1.34315 0 0 
        1.34315 0 3V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V3C20 1.34315 18.6569 0 
        17 0H3ZM12.2929 7.29289C12.6834 6.90237 13.3166 6.90237 13.7071 7.29289L15.3738 8.95956L16.0809 
        9.66667L15.3738 10.3738L13.7071 12.0404C13.3166 12.431 12.6834 12.431 12.2929 12.0404C11.9024 
        11.6499 11.9024 11.0168 12.2929 10.6262L13.2525 9.66667L12.2929 8.70711C11.9024 8.31658 11.9024 
        7.68342 12.2929 7.29289ZM6.37395 7.29289C6.76447 6.90237 7.39764 6.90237 7.78816 7.29289C8.17869 
        7.68342 8.17869 8.31658 7.78816 8.70711L6.8286 9.66667L7.78816 10.6262C8.17869 11.0168 8.17869 
        11.6499 7.78816 12.0404C7.39764 12.431 6.76447 12.431 6.37395 12.0404L4.70728 10.3738L4.00017 
        9.66667L4.70728 8.95956L6.37395 7.29289Z" fill={!isMobile ? 'white' : 'black'}/>
    </svg>
  )
}

export default TechStackIcon
