import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { useUpdateCvSkill } from '../../../../../hooks/resume/skills/useUpdateCvSkill'
import { IResumeSkill } from '../../../../../types/Resume/IResumeSkill'
import { MIN_INPUTS_ELEMENTS } from '../../../../../constants'
import CvSkillSetForm from './CvSkillSetForm'
import EditFormButtons from '../FormButtons/EditFormButtons'

interface Inputs {
  skill_set: string
}

interface Props {
  setOpen: (value: boolean) => void
  skill: IResumeSkill
  cvId: number
}

const CvUpdateSkillSet: React.FC<Props> = ({ setOpen, skill, cvId }) => {
  const inputFieldsValues = skill.skills.map((value: string) => ({ value: value }))
  const [inputFields, setInputFields] = useState(inputFieldsValues)
  const isDisabled = inputFields.length <= MIN_INPUTS_ELEMENTS
  const updateSkill = useUpdateCvSkill()

  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      skill_set: skill ? skill.skill_set : '',
    }
  })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const skills = inputFields.map(({ value } ) => value)
    const newSkillSet = {
      skill_set: data.skill_set,
      resume_id: cvId,
      skills: skills
    }

    updateSkill(newSkillSet as IResumeSkill, skill.id as number, cvId)
    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvSkillSetForm
        inputFields={inputFields}
        isDisabled={isDisabled}
        setInputFields={setInputFields}
        register={register}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <EditFormButtons setOpen={setOpen} />
      </Box>
    </form>
  )
}

export default CvUpdateSkillSet
