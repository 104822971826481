import { useDispatch } from 'react-redux'

//import { useGetCustomer } from './useGetCustomer'
import { removeCustomer, removeCustomerFailure, removeCustomerSuccess } from '../../store/customersSlice'
import { deleteCustomer } from '../../services/API/admin/customers'
import successNotify from '../../helpers/successNotify'
import dangerNotify from '../../helpers/dangerNotify'

export const useDeleteCustomer = () => {
  //const getCustomerHook = useGetCustomer()
  const dispatch = useDispatch()

  return (id: number | undefined) => {
    dispatch(removeCustomer())
    deleteCustomer(id)
      .then(() => {
        dispatch(removeCustomerSuccess(id))
        //getCustomerHook()
        successNotify('Customer deleted')
      })
      .catch((error) => {
        dispatch(removeCustomerFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
