import React from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import FullCalendar, { CalendarApi } from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

import Loader from '../Loader'
import { IOmitedRecord } from './MonthlyCalendarReport'
import { Project, User } from 'types'
import { isAdmin, isDefault, isManager } from 'helpers/accessHelpers'
import { useAuth } from 'context/auth'
import DefaultSelect from 'components/custom/DefaultSelect/DefaultSelect'
import { IOptions } from 'types/ISelectOptions'
import ProjectsIcon from 'icons/ProjectsIcon'
import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import { UserIcon } from 'icons'
import { dateFormatYYMM } from 'helpers/displayingDateFormat'
import { destopCalendarEvents } from './MonthlyCalendarHelpers'

interface Props {
    allRecordNHolidays: IOmitedRecord[] | undefined
    calendarRef: React.LegacyRef<FullCalendar> | undefined
    convertedDate: Date
    projects: Project[]
    usersList: IOptions[]
    selectedProject: IOptions
    availableContractsOptions: IOptions[]
    onChangeProject: (project: IOptions) => void
    onChangeUser: (user: IOptions) => void
    date: Date
    setDate: React.Dispatch<React.SetStateAction<Date>>
    calendarAPI: CalendarApi | undefined
    arrowNextItem: () => void
    arrowBackItem: () => void
    selectedUser: IOptions
}

const DesktopCalendar: React.FC<Props> = ({ 
  allRecordNHolidays, 
  calendarRef, 
  convertedDate, 
  projects,
  selectedProject,
  availableContractsOptions,
  onChangeProject,
  onChangeUser,
  date,
  setDate,
  calendarAPI,
  arrowNextItem,
  arrowBackItem,
  usersList,
  selectedUser
}) => {
  const chosenUser = useSelector((state: { users: { showData: User } }) => state.users.showData)
  const chosenUserLoading = useSelector((state: { users: { isLoading: boolean } }) => state.users.isLoading)
  const { currentUser } = useAuth()
  const isAdminUser = isManager(currentUser) || isAdmin(currentUser)
  const isDefaultUser = isDefault(currentUser)

  return(
    <>
      {isAdminUser && (
        <div className="monthly_calendar_title">
          {!chosenUserLoading && (
            <>
              {chosenUser && !isMobile && (
                <div className="calendar_container__select">
                  <DefaultSelect
                    options={[{ value: 0, label: 'Available Projects' }, ...availableContractsOptions]}
                    value={selectedProject}
                    prefixIcon={<ProjectsIcon />}
                    onChange={(newValue) => onChangeProject(newValue as IOptions)}
                    placeholder="All Users"
                  />
                </div>
              )}
              <CustomDatepicker
                value={date}
                onChange={(value) => {
                  setDate(value as Date)
                  calendarAPI?.gotoDate(value)
                }}
                mask={'__.__.____'}
                inputType="day"
                maxDate={new Date()}
                datePickerType="month"
                borderInputColor="#D4D9E2"
                arrowNextItem={arrowNextItem}
                arrowBackItem={arrowBackItem}
                disableClearButton={true}
                width="320px"
              />
              {chosenUser && !isMobile && (
                <div className="calendar_container__select">
                  <DefaultSelect
                    options={usersList}
                    value={selectedUser}
                    prefixIcon={<UserIcon />}
                    onChange={(newValue) => onChangeUser(newValue as IOptions)}
                    placeholder="All Users"
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
      {isDefaultUser && 
        <div className="calendar_container__user_picker">
          <CustomDatepicker
            value={date}
            onChange={(value) => {
              setDate(value as Date)
              calendarAPI?.gotoDate(value)
            }}
            mask={'__.__.____'}
            inputType="day"
            minDate={dayjs().add(-1, 'M').startOf('month').toDate()}
            maxDate={dayjs().add(1, 'M').endOf('month').toDate()}
            datePickerType="month"
            borderInputColor="#D4D9E2"
            arrowNextItem={arrowNextItem}
            arrowBackItem={arrowBackItem}
            disableMinusArrow={dateFormatYYMM(date) === dateFormatYYMM(dayjs().add(-1, 'M').startOf('month').toDate())}
            disablePlusArrow={dateFormatYYMM(date) === dateFormatYYMM(dayjs().add(1, 'M').endOf('month').toDate())}
            disableClearButton={true}
            width="320px"
          />
        </div>
      }
      {allRecordNHolidays ? (
        <FullCalendar
          headerToolbar={{
            start: '',
            center: '',
            end: '',
          }}
          ref={calendarRef}
          eventDisplay="block"
          contentHeight={'1000px'}
          dayHeaderFormat={{ weekday: 'long' }}
          firstDay={1}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          initialDate={convertedDate}
          events={allRecordNHolidays?.map((record) => destopCalendarEvents(record, projects))}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}

export default React.memo(DesktopCalendar)
