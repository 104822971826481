import API from '../..'
import ROUTES from '../../../../routes'
import { Customer } from '../../../../types'

export const getCustomers = (
  company: string | undefined,
  representative: string | undefined,
  projectId: number | undefined,
  page: number
) => {
  return API.get(ROUTES.ADMIN_CUSTOMERS, {
    params: {
      company,
      representative,
      project: projectId,
      page
    }
  })
}

export const fetchAllCustomersData = () => API.get(ROUTES.ALL_CUSTOMERS)

export const createCustomer = (newCustomer: Customer) => API.post(ROUTES.ADMIN_CUSTOMERS, { customer: newCustomer })

export const editCustomer = (customer: Customer, customer_id: number | undefined) =>
  API.patch(ROUTES.ADMIN_CUSTOMERS + `/${customer_id}`, { customer: customer })

export const deleteCustomer = (id: number | undefined) => {
  return API.delete(ROUTES.ADMIN_CUSTOMERS + `/${id}`)
}
