import { createSlice } from '@reduxjs/toolkit'

import { Technology } from '../types'

const initialState = {
  indexData: <Technology[]>[],
  error: {},
  isLoading: true,
}

const technologiesSlice = createSlice({
  name: 'technologies',
  initialState,
  reducers: {
    fetchTechnologies(state) {
      state.isLoading = true
    },
    fetchTechnologiesSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchTechnologiesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default technologiesSlice.reducer
export const { fetchTechnologies, fetchTechnologiesFailure, fetchTechnologiesSuccess } = technologiesSlice.actions
