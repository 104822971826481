import React from 'react'
import { IRequest } from '../types'

interface Props  {
    handleChangeLeave: (value: IRequest) => void
    request: IRequest
}

const ApprovedExtraSickLeave: React.FC<Props> = ({ handleChangeLeave, request }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => handleChangeLeave(request)}
      cursor="pointer"
    >
      <path d="M16.2 2C16.9426 2 17.6548 2.30102 18.1799 2.83684C18.705 3.37266 19 4.09938 19 4.85714V22L12 17.7143L5
      22V4.85714C5 4.09938 5.295 3.37266 5.8201 2.83684C6.3452 2.30102 7.05739 2 7.8 2H16.2Z" fill="#FF655C"
      />
    </svg>
  )
}

export default ApprovedExtraSickLeave
