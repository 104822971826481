import API from '../..'
import ROUTES from '../../../../routes'

export const getLeaveCounters = (id: number) => {
  return API.get(`${ROUTES.ADMIN_USER_LEAVE_COUNTER}/${id}`)
}

export const getLeaveUserCounters = (id: number) => {
  return API.get(`${ROUTES.USER_LEAVE_COUNTER}/${id}`)
}
export const fetchUsersLeaves = (page: number, userId: number | undefined) =>
  API.get(ROUTES.USERS_LEAVES_COUNTER, { params: { page, user_id: userId } })
