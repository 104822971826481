import { useDispatch } from 'react-redux'

import { fetchUser } from '../../services/API/admin/users'
import dangerNotify from '../../helpers/dangerNotify'
import { getUserSuccess, getUserFailure, getUser } from '../../store/usersSlice'

export const useGetUser = () => {
  const dispatch = useDispatch()

  return (id: number) => {
    dispatch(getUser())
    fetchUser(id)
      .then((response) => {
        dispatch(getUserSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getUserFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
