import React from 'react'

type Props = {
    color?: string
}

const DownloadIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg"
      color="white"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10 0C10.5523 0 11 0.447715 11 1V10.5858L13.2929 8.29289C13.6834
       7.90237 14.3166 7.90237 14.7071 8.29289C15.0977 8.68342 15.0977 9.31658 14.7071 9.70711L10.7071 13.7071C10.3166
        14.0976 9.68344 14.0976 9.29292 13.7071L5.29292 9.70711C4.9024 9.31658 4.9024 8.68342 5.29292 8.29289C5.68344
         7.90237 6.31661 7.90237 6.70713 8.29289L9.00003 10.5858V1C9.00003 0.447715 9.44774 0 10 0ZM0.683799
          14.0513C1.20774 13.8767 1.77406 14.1598 1.94871 14.6838L2.49286 16.3162C2.62898 16.7246 3.01112
           17 3.44155 17H16.5585C16.9889 17 17.3711 16.7246 17.5072 16.3162L18.0513 14.6838C18.226 14.1598 18.7923
            13.8767 19.3163 14.0513C19.8402 14.226 20.1234 14.7923 19.9487 15.3162L19.4046 16.9487C18.9962 18.1737
             17.8498 19 16.5585 19H3.44155C2.15025 19 1.00384 18.1737 0.595496 16.9487L0.0513439 15.3162C-0.123304
              14.7923 0.159856 14.226 0.683799 14.0513Z" fill={color ? color : '#8992A6'}
      />
    </svg>
  )
}

export default DownloadIcon
