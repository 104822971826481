import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Link } from 'react-router-dom'
import { FiEdit } from 'react-icons/all'

import { User, Project, Status, Contract  } from '../../../types'
import Modal from '../../reusable/Modal/index.tsx'
import { tableCellStyles, tableHeadStyles } from 'utilts/styles'
import { BENCH_PROJECT, contractsTableRows } from '../../../constants'
import EditContract from '../EditContract'
import { renderProject, renderStatus, renderUser } from '../../../helpers/renderName'
import DeleteIcon from '../../reusable/DeleteIcon'
import { useDeleteContract } from '../../../hooks/contract/useDeleteContract'
import { backgroundColorUser } from '../../../helpers/addBackgroundColorForUser'
import { dateFormatDDMMYY } from '../../../helpers/displayingDateFormat'

interface Props {
  usersNames: User[]
  projects: Project[]
  statuses: Status[]
  contracts: Contract[]
}

const ContractsList: React.FC<Props> = ({ usersNames, projects, statuses, contracts }) => {
  const [open, setOpen] = useState(false)
  const [selectedContract, setSelectedContract] = useState<Contract>()

  let numbering = 1
  const contractsList = contracts.filter(contract => renderProject(contract?.project_id, projects) !== BENCH_PROJECT )

  const deleteContractHook = useDeleteContract()

  const handleEditContract = (contract: Contract) => {
    setOpen(true)
    setSelectedContract(contract)
  }
  const deleteContract = (contract_id: string | undefined) => {
    if (window.confirm('Are you sure?')) {
      deleteContractHook(contract_id)
    }
  }

  const editContract = (
    <EditContract
      usersNames={usersNames}
      projects={projects}
      statuses={statuses}
      setOpen={setOpen}
      selectedContract={selectedContract as Contract}
    />
  )

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" style={{ width: 100 }} sx={tableHeadStyles}>
              #
            </TableCell>
            {contractsTableRows.map((row) => {
              return (
                <TableCell align="left" sx={tableHeadStyles} key={row}>
                  {row}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {contractsList?.map((contract) => {
            return (
              <TableRow
                key={contract.id}
                className={contract ? backgroundColorUser(contract?.job_status_id, statuses) : ''}>
                <TableCell align="left" sx={tableCellStyles}>
                  {numbering++}
                </TableCell>
                <TableCell align="left" sx={tableCellStyles}>
                  <Link to={`/users/${contract.user_id}`}>{renderUser(contract.user_id, usersNames)}</Link>
                </TableCell>
                <TableCell align="left" sx={tableCellStyles}>
                  <Link to={`/projects/${contract.project_id}`}>{renderProject(contract?.project_id, projects)}</Link>
                </TableCell>
                <TableCell align="left" sx={tableCellStyles}>
                  {renderStatus(contract?.job_status_id, statuses)}
                </TableCell>
                <TableCell align="left" sx={tableCellStyles}>
                  {contract?.start_date && dateFormatDDMMYY(contract?.start_date)}
                </TableCell>
                <TableCell align="left" sx={tableCellStyles}>
                  {contract?.end_date && dateFormatDDMMYY(contract?.end_date)}
                </TableCell>
                <TableCell align="left" sx={tableCellStyles}>
                  <span onClick={() => deleteContract(contract.id)}>
                    <DeleteIcon text="project" />
                  </span>
                  <FiEdit size="20" cursor="pointer" onClick={() => handleEditContract(contract)} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {open && (
        <Modal component={editContract} open={open} handleClose={() => setOpen(!open)} title="Edit Contract" />
      )}
    </TableContainer>
  )
}

export default ContractsList
