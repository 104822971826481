import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'

import DefaultSelect from '../../custom/DefaultSelect/DefaultSelect'
import './styles.scss'
import { stylesLeftSelect, stylesRightSelect } from './styles'
import { CustomerIcon, EnglishIcon, PingIcon, SignificateIcon } from '../../../icons'
import { CandidatesMobileFiltersInputs } from '../../mobile/candidates'
import { Pages } from 'enums/StartPage'
import { filterCandidates } from 'store/candidatesSlice'

interface Props {
  candidateNames: [string]
  setPage: (page: number) => void
}

const CandidateFilters: React.FC<Props> = ({ candidateNames, setPage }) => {
  const { left, right } = useSelector(({ candidates: { filterData } }) => filterData)
  const dispatch = useDispatch()

  const [leftFilter, setLeftFilter] = useState(left)
  const [rightFilterName, setRightFilterName] = useState(right)

  const onSetFilterParams = (
    filter:
      | '{}'
      | {
          type: string
          value: string
        },
    right?: string,
  ) => {
    dispatch(filterCandidates({ left: leftFilter, right: right || rightFilterName, filter }))
  }

  const renderIcon = () => {
    const color = isMobile ? 'black' : 'white'

    if (leftFilter === 'Candidate') {
      return <CustomerIcon color={color} />
    } else if (leftFilter === 'English') {
      return <EnglishIcon color={color} />
    } else if (leftFilter === 'Ping Again') {
      return <PingIcon color={color} />
    } else {
      return <SignificateIcon color={color} />
    }
  }

  const defaultRightFilters = ['All Candidates', 'All Levels', 'All Statuses', 'All Significates']

  const renderOptions = (options, isCandidate = false) => {
    if (isCandidate) {
      return options.map((option) => {
        return { value: option.id, label: option.name }
      })
    } else {
      return options.map((option, i) => {
        return { value: i, label: option }
      })
    }
  }

  const returnLeftSelectOptions = () => {
    const options = ['Candidate', 'English', 'Ping Again', 'Significate']

    return renderOptions(options)
  }

  const returnRightSelectOptions = () => {
    const parsedCandidates = [{ id: '-1', name: 'All Candidates' }]
    parsedCandidates.push(
      ...candidateNames.map((candidate) => {
        return { id: candidate.id, name: candidate.full_name }
      }),
    )
    if (leftFilter === 'Candidate') {
      return renderOptions(parsedCandidates, true)
    } else if (leftFilter === 'English') {
      return renderOptions([
        'All Levels',
        'Beginner',
        'Elementary',
        'Intermediate',
        'Upper Intermediate',
        'Advanced',
        'Native',
      ])
    } else if (leftFilter === 'Ping Again') {
      return renderOptions(['All Statuses', 'On', 'Off'])
    } else {
      return renderOptions(['All Significates', 'Rejected', 'Interviewing', 'Test Task', 'Preoffer', 'Hired'])
    }
  }

  const onChangeLeftFilter = (value) => {
    if (value === leftFilter) return

    if (value === 'Candidate') {
      setRightFilterName('All Candidates')
    } else if (value === 'English') {
      setRightFilterName('All Levels')
    } else if (value === 'Ping Again') {
      setRightFilterName('All Statuses')
    } else {
      setRightFilterName('All Significates')
    }
    setLeftFilter(value)
    onSetFilterParams('{}')
    setPage(Pages.START_PAGE)
    localStorage.setItem('page', Pages.START_PAGE)
  }

  const onChangeRightFilter = (label: string, value: string) => {
    if (label === rightFilterName) return
    const filterParams = defaultRightFilters.includes(label) ? '{}' : { type: leftFilter, value }

    setRightFilterName(label)
    onSetFilterParams(filterParams, label)
  }

  return isMobile ? (
    <CandidatesMobileFiltersInputs
      leftFilter={leftFilter}
      rightFilterName={rightFilterName}
      renderIcon={renderIcon}
      returnLeftSelectOptions={returnLeftSelectOptions}
      returnRightSelectOptions={returnRightSelectOptions}
      onChangeLeftFilter={onChangeLeftFilter}
      onChangeRightFilter={onChangeRightFilter}
    />
  ) : (
    <>
      <div className="candidate-filter-main-container">
        <div className="candidate-filter-left-content">
          <DefaultSelect
            placeholder={leftFilter}
            styles={stylesLeftSelect}
            prefixIcon={renderIcon()}
            options={returnLeftSelectOptions()}
            onChange={(newValue) => onChangeLeftFilter(newValue.label)}
          />
        </div>
        <div className="candidate-filter-right-content">
          <DefaultSelect
            placeholder={rightFilterName}
            options={returnRightSelectOptions()}
            styles={stylesRightSelect}
            value={rightFilterName}
            onChange={(newValue) =>
              onChangeRightFilter(
                newValue.label,
                leftFilter === ('Candidate' || 'Ping Again') ? newValue.value : newValue.value - 1,
              )
            }
          />
        </div>
      </div>
    </>
  )
}

export default CandidateFilters
