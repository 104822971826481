import API from '../..'
import ROUTES from '../../../../routes'
import { BaseUser, UserWithProfile } from '../../../../types'

export const getUsers = (
  active: string,
  user_id: number | undefined,
  project_id: number | undefined,
  status_id: number | undefined,
  manager_id: number | undefined,
) => {
  return API.get(ROUTES.ADMIN_USERS, {
    params: {
      active,
      user_id,
      project_id,
      status_id,
      manager_id,
    },
  })
}

export const getUsersNames = () => API.get(ROUTES.USERS_NAMES)

export const fetchUser = (id: number) => API.get(ROUTES.ADMIN_USERS + `/${id}`)

export const createUser = (params: BaseUser | FormData) => {
  return API.post(ROUTES.ADMIN_USERS, params)
}

export const updateUser = (userId: number, data: FormData | { active: boolean }) => {
  return API.put(ROUTES.ADMIN_USERS + `/${userId}`, data)
}

export const updateUserStatus = (status: string, userId: number) => {
  return API.put(ROUTES.ADMIN_USERS + `/${userId}`, {
    status: status,
  })
}

export const deleteUser = (id: number) => API.delete(ROUTES.ADMIN_USERS + `/${id}`)

export const fetchRecords = (user_id: number, project_id: number, date: string) => {
  return API.get(ROUTES.ADMIN_RECORDS, {
    params: { user_id: user_id, project_id: project_id, date: date },
  })
}
