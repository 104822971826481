import React from 'react'
import { isMobile } from 'react-device-detect'

const RepresentativeIcon = () => {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 2.5C12 2.77614 11.7761 3 11.5 3C11.2239 3 
        11 2.77614 11 2.5C11 2.22386 11.2239 2 11.5 2C11.7761 2 12 2.22386 12 2.5ZM14 2.5C14 3.88071 
        12.8807 5 11.5 5C10.1193 5 9 3.88071 9 2.5C9 1.11929 10.1193 0 11.5 0C12.8807 0 14 1.11929 14 
        2.5ZM18.7052 10.9453C18.4759 10.9813 18.2409 11 18.0015 11C16.7236 11 15.5702 10.4674 14.751 
        9.61201C13.9319 10.4674 12.7784 11 11.5006 11C10.2232 11 9.07011 10.4678 8.25102 9.61296C7.43192 
        10.4678 6.27885 11 5.00147 11C4.76138 11 4.52569 10.9812 4.29578 10.945L7.31936 18H15.6818L18.7052 
        10.9453ZM19.9269 8.09469C19.4683 8.64774 18.776 9 18.0015 9C17.0707 9 16.2588 8.49139 15.8284 
        7.73695C15.7706 7.63556 15.7196 7.52973 15.6762 7.4201C15.5634 7.13533 15.5015 6.8249 15.5015 
        6.5C15.5015 6.23989 15.3083 6 15.0481 6H14.4539C14.1938 6 14.0006 6.23989 14.0006 6.5C14.0006 
        6.8249 13.9386 7.13533 13.8258 7.42009C13.7824 7.52973 13.7314 7.63556 13.6736 7.73695C13.2432 
        8.49139 12.4313 9 11.5006 9C10.5707 9 9.75941 8.49234 9.32873 7.73905C9.27066 7.63749 9.21951 
        7.53146 9.17592 7.4216C9.06277 7.13642 9.00057 6.82547 9.00057 6.5C9.00057 6.23989 8.80735 6 
        8.54724 6H7.95479C7.69468 6 7.50147 6.23989 7.50147 6.5C7.50147 6.82547 7.43927 7.13642 7.32612 
        7.4216C7.28253 7.53146 7.23138 7.63749 7.17331 7.73905C6.74262 8.49234 5.93133 9 5.00147 9C4.22478 9 
        3.53081 8.64581 3.07228 8.09015C2.77557 7.73059 2.57744 7.28667 2.51925 6.79976C2.50751 6.70148 
        2.50147 6.60144 2.50147 6.5C2.50147 6.23989 2.30825 6 2.04814 6H1.41249C1.04275 6 0.739649 6.20912 
        0.587166 6.5C0.556076 6.55931 0.531248 6.62201 0.513393 6.68703C0.510951 6.69593 0.508639 6.70486 
        0.50646 6.71384C0.461446 6.89927 0.47286 7.10202 0.556749 7.29776L0.583664 7.36056L1.33064 
        9.10351L5.48107 18.7878C5.79623 19.5232 6.5193 20 7.31936 20H15.6818C16.4818 20 17.2049 19.5232 
        17.5201 18.7878L21.6678 9.10989L22.4207 7.3529L22.4448 7.29684C22.5278 7.10305 22.5398 6.90237 
        22.4963 6.71847L22.4936 6.70767L22.4891 6.6903C22.4712 6.62412 22.4461 6.5603 22.4146 6.5C22.2622 
        6.20915 21.9593 6 21.5896 6H20.9548C20.6947 6 20.5015 6.23989 20.5015 6.5C20.5015 6.59944 20.4957 
        6.69753 20.4844 6.79393C20.4268 7.28518 20.2269 7.7329 19.9269 8.09469ZM4 23C4 22.4477 4.44772 22 5 
        22H18C18.5523 22 19 22.4477 19 23C19 23.5523 18.5523 24 18 24H5C4.44772 24 4 23.5523 4 23Z" 
      fill={!isMobile ? 'white' : 'black'}/>
    </svg>
  )
}

export default RepresentativeIcon
