import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import {
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap'
import { useSelector } from 'react-redux'

import webRoutes from 'lib/webRoutes'
import { useAuth } from 'context/auth'
import Logo from './Logo'
import { isAdmin, isDefault, isManager } from 'helpers/accessHelpers'
import { HiOutlineBell } from 'react-icons/hi'
import DesktopNotifications from 'components/notifications'
import UserMenu from './UserMenu'
import { navbarCollapsedData, NavbarTitle } from '../SiteNavbarData'
import { Notification } from 'types'
import RolesWrapper from './RolesWrapper'
import { disableAccessForRoles } from 'helpers/disableAccessForRoles'
import { userRoles } from '../../../../constants'
import { rolesForRecruiters } from 'helpers/subRolesHelper'
import { SubRoleType } from 'enums/subRoles'
import { SubRole } from 'types/SubRole'

type Props = {
    newNotifications: Notification[]
    notifications: Notification[]
    logout: () => void
}

const DesktopNavbar: React.FC<Props> = ({ 
  newNotifications,
  notifications,
  logout
}) => {
  const { currentUser } = useAuth()
  const subRoles = useSelector((state: { subRoles: { indexData: SubRole[] }} ) => state.subRoles.indexData)
  const isCFORole = currentUser?.sub_roles.map(name => ({ name })).every((role) => role.name == SubRoleType.CFO)

  return (
    <>
      <NavbarBrand
        tag={Link}
        to={(currentUser && (isManager(currentUser) || isAdmin(currentUser))) 
          ? isCFORole ? webRoutes.MONTHLY_REPORT : webRoutes.DASHBOARD_USERS_LIST :
          (currentUser && isDefault(currentUser)) ? 
            webRoutes.SUBMIT_HOURS : webRoutes.HOME}>
        <Logo />
      </NavbarBrand>
      {currentUser && 
        <Nav
          className="ms-auto align-items-center" navbar>
          <RolesWrapper generalRoles={[userRoles.MANAGER.id, userRoles.ADMIN.id]}>
            <RolesWrapper subRoles={disableAccessForRoles(subRoles, [SubRoleType.CFO])}>
              <NavItem>
                <NavLink
                  className="nav-link"
                  to={webRoutes.DASHBOARD_USERS_LIST}>
                  {NavbarTitle.ADMIN_MAIN}
                </NavLink>
              </NavItem>
              <RolesWrapper subRoles={rolesForRecruiters}>
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to={webRoutes.VACANCIES}>
                    {NavbarTitle.RECRUITERS}
                  </NavLink>
                </NavItem>
              </RolesWrapper>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {NavbarTitle.SUBMIT_TIME}
                </DropdownToggle>
                <DropdownMenu end>
                  {navbarCollapsedData.submitHoursCollapse.map((item, key) => (
                    <DropdownItem key={key} tag={Link} to={item.route}>
                      {item.title}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </RolesWrapper>
            <NavItem>
              <NavLink
                className="nav-link"
                to={webRoutes.MONTHLY_REPORT}>
                {NavbarTitle.REPORT}
              </NavLink>
            </NavItem>
            <RolesWrapper subRoles={disableAccessForRoles(subRoles, [SubRoleType.CFO])}>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {NavbarTitle.MANAGE}
                </DropdownToggle>
                <DropdownMenu end>
                  {navbarCollapsedData.manageCollapse.map((item, key) => (
                    <DropdownItem key={key} tag={Link} to={item.route}>
                      {item.title}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  className="nav-link"
                  to={webRoutes.REQUEST}>
                  {NavbarTitle.REQUESTS}
                </NavLink>
              </NavItem>
            </RolesWrapper>
          </RolesWrapper>
          <RolesWrapper generalRoles={[userRoles.DEFAULT.id]}>
            <RolesWrapper subRoles={rolesForRecruiters}>
              <NavItem>
                <NavLink
                  className="nav-link"
                  to={webRoutes.VACANCIES}>
                  {NavbarTitle.RECRUITERS}
                </NavLink>
              </NavItem>
            </RolesWrapper>
            <NavItem>
              <NavLink
                to={webRoutes.MY_REPORT}
                className="nav-link">
                {NavbarTitle.REPORT}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="nav-link"
                to={webRoutes.REQUEST}>
                {NavbarTitle.REQUESTS}
              </NavLink>
            </NavItem>
          </RolesWrapper>
          {currentUser &&
            <>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  className={ newNotifications.length > 0 ? 'bellIcon_after' : 'bellIcon' }>
                  <HiOutlineBell fontSize="30px" color="#8992A6"  />
                </DropdownToggle>
                <DropdownMenu className="notifications">
                  <DesktopNotifications notifications={notifications} />
                </DropdownMenu>
              </UncontrolledDropdown>
              <UserMenu logout={logout}/>
            </>
          }
        </Nav>
      }
    </>
  )
}

export default DesktopNavbar
