import React from 'react'
import CustomButton from 'components/custom/CustomButton/CustomButton'


interface Props {
    setOpen: (value: boolean) => void
}

const EditFormButtons: React.FC<Props> = ({ setOpen }) => {
  return (
    <>
      <CustomButton style={{ marginRight: '20px' }} variant="outlined" onClick={() => setOpen(false)} text="Cancel"/>
      <CustomButton type="submit" text="Save" />
    </>
  )
}

export default EditFormButtons
