import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import {  createResumeLanguage } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'
import { IResumeLanguage } from '../../../types/Resume/IResumeLanguage'

export const useCreateResumeLanguage = () => {
  const getUserCv = useGetUserCv()

  return (language: IResumeLanguage, cvId: number) => {
    createResumeLanguage(language)
      .then(() => {
        successNotify('Language added')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Please fill in the contact section'))
  }
}
