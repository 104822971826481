import { StatusType } from 'enums/Types'
import { Record } from 'types/Record'

export const isSquashRecord = (
  data, 
  records: Record[], 
  currentDate: string, 
  adminPage: boolean, 
  userId?: number | undefined) => {
  if (adminPage) {
    return records?.some(
      (record) =>
        record.user_id === userId &&
        record.category_id === data?.category?.value &&
        record.project_id === data?.project?.value &&
        record?.date === currentDate &&
        record.record_type === StatusType.REGULAR
    )
  } else {
    return records?.some(
      (record) =>
        record.category_id === +data.category.value &&
        record.project_id === +data.project.value &&
        record?.date === currentDate &&
        record.record_type === StatusType.REGULAR)
  }
}

export const findRecord = (
  data, 
  records: Record[], 
  currentDate: string, 
  adminPage: boolean, 
  userId?: number | undefined) => {
  if (adminPage) {
    return records?.find(
      (record) =>
        record.user_id === userId &&
        record.category_id === +data?.category?.value &&
        record?.project_id === +data?.project?.value &&
        record?.date === currentDate
    )
  } else {
    return records?.find(
      (record) =>
        record.category_id === +data.category.value &&
        record?.project_id === +data.project.value &&
        record?.date === currentDate
    )}
}
