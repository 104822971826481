import { useDispatch } from 'react-redux'

import { useFetchVacancies } from './useFetchVacancies'
import { updateVacancy as updateVacancyService } from 'services/API/vacancies'
import { Vacancy } from 'types'
import { updateVacancyFailure, updateVacancySuccess } from '../../store/vacanciesSlice'
import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'

export const useUpdateVacancy = () => {
  const fetchVacancies = useFetchVacancies()
  const dispatch = useDispatch()

  return (vacancy: Vacancy | Partial<Vacancy>, page: number, toggle: () => void) => {
    updateVacancyService(vacancy)
      .then((response) => {
        dispatch(updateVacancySuccess(response.data))
        successNotify('Vacancy updated')
        toggle()
        fetchVacancies(page)
      })
      .catch((error) => {
        toggle()
        dispatch(updateVacancyFailure(error))
        dangerNotify(error.response?.data.message)
      })
  }
}
