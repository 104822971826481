import { useDispatch } from 'react-redux'

import { getTechnologies } from '../../services/API/admin/technologies'
import { fetchTechnologies, fetchTechnologiesFailure, fetchTechnologiesSuccess } from '../../store/technologiesSlice'
import dangerNotify from '../../helpers/dangerNotify'

export const useGetTechnologies = () => {
  const dispatch = useDispatch()

  return () => [
    dispatch(fetchTechnologies()),
    getTechnologies()
      .then((response) => {
        dispatch(fetchTechnologiesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchTechnologiesFailure(error))
        dangerNotify('Server error: unable to download Technologies')
      }),
  ]
}
