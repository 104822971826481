import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useSelector } from 'react-redux'

import { renderUser } from 'helpers/renderName'
import { User } from 'types/User'
import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import CustomButton from 'components/custom/CustomButton/CustomButton'
import dangerNotify from 'helpers/dangerNotify'
import { getCsvRecords } from 'services/API/admin/csv-records'

interface ISalaryCsv {
  user_id: number
  sum: number
}

interface Props {
  filename: string
  month: string
}

const ExportSalaryCSV: React.FC<Props> = ({ filename, month }) => {
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const [records, setRecords] = useState()
  const [effectiveHours, setEffectiveHours] = useState()
  const [initiateDownload, setInitiateDownload] = useState(false)
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)

  useEffect(() => {
    if (records?.length) {
      setInitiateDownload(true)
    }
  }, [records])

  useEffect(() => {
    if (initiateDownload) {
      csvLinkRef?.current?.link.click()
      setInitiateDownload(false)
    }
  }, [initiateDownload])

  const headersWithStatus = [
    { label: 'User', key: 'userName' },
    { label: 'Hours', key: 'sum' },
    { label: '', key: '' },
    { label: 'Total hours - ' + effectiveHours, key: 'effective_hours' }
  ]

  const csvDownloadClick = async () => {
    await getCsvRecords(dateFormatDDMMYY(month))
      .then((response) => {
        const filtered = response.data.user_hours
          .map(({ user_id, sum }: ISalaryCsv) => {
            const userName = renderUser(user_id as number, usersNames)
            setEffectiveHours(response.data.effective_hours)
            return { userName, sum }
          })
        setRecords(filtered)
        if(response.data <= 0){
          dangerNotify('There is no records on this month.')
        }
      })
      .catch((error) => dangerNotify(error.message))
  }

  return (
    <>
      <CustomButton 
        text={initiateDownload ? 'Loading...' : 'Download Salary CSV'}
        variant="outlined"
        onClick={csvDownloadClick}/>
      {initiateDownload &&
      <CSVLink
        ref={csvLinkRef}
        className="admin-report__csv_button admin-report__outlined"
        target="_blank"
        data={records}
        filename={filename}
        headers={headersWithStatus}
      />
      }
    </>
  )
}

export default ExportSalaryCSV
