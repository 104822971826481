import React from 'react'
import { useSelector } from 'react-redux'

import { Notification, Project, User } from '../../../types'
import { useUpdateNotification } from '../../../hooks/notification/useUpdateNotification'
import { userRoles } from '../../../constants'
import { useAuth } from '../../../context/auth'
import MobileReadNotifications from './MobileReadNotifications'
import MobileUnreadNotifications from './MobileUnreadNotifications'

const MobileNotifications = () => {
  const { currentUser } = useAuth()
  const managerOrAdmin = currentUser?.role === userRoles.MANAGER.id || currentUser?.role === userRoles.ADMIN.id
  const useUpdateNotificationHook = useUpdateNotification()
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const projectsNames = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const notifications =
        useSelector((state: { notifications: { indexData: Notification[] }} ) => state.notifications.indexData)
  const unreadNotifications = notifications?.filter(notification => !notification.read)
  const readNotifications = notifications?.filter(notification => notification.read)

  const updateNotification = (notification: Notification) => {
    const updatedNotification = {
      user_id: notification.user_id,
      id: notification.id,
      read: true
    }
    !notification.read && useUpdateNotificationHook(updatedNotification as Notification)
  }

  return (
    <div className="mobile_notifications__wrapper">
      {unreadNotifications.length > 0 &&
        <>
          <div className="new-notifications__title">
            New
          </div>
          <MobileUnreadNotifications
            unreadNotifications={unreadNotifications}
            projectsNames={projectsNames}
            usersNames={usersNames}
            updateNotification={updateNotification}
            managerOrAdmin={managerOrAdmin}
          />
          <hr />
        </>
      }
      <MobileReadNotifications
        readNotifications={readNotifications}
        projectsNames={projectsNames}
        usersNames={usersNames}
        updateNotification={updateNotification}
        managerOrAdmin={managerOrAdmin}
      />
    </div>
  )
}

export default MobileNotifications
