import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Box from '@mui/material/Box'

import CvAddPortfolioProject from './CvAddPortfolioProject'
import CvNavButtons from '../CvNavButtons'
import UsersCv from '../UsersCv'
import { useDeleteResumeProject } from '../../../../../hooks/resume/portfolio/useDeleteResumeProject'
import CvUpdatePortfolioProject from './CvUpdatePortfolioProject'
import Modal from '../../../../reusable/Modal/index.tsx'
import { IResume } from '../../../../../types/Resume/IResume'
import { cvNavLinks } from '../../../../../constants'
import { IResumeProject } from '../../../../../types/Resume/IResumeProject'
import { useGetUserCv } from '../../../../../hooks/resume/useGetUserCv'
import { useChangeCvProjectsSorting } from '../../../../../hooks/resume/portfolio/useChangeCvProjectsSorting'

const CvPortfolioProjects = () => {
  const [open, setOpen] = useState(false)
  const [currentProject, setCurrentProject] = useState<IResumeProject | null>(null)
  const [project, setProject] = useState<IResumeProject>()
  const { resumeId } = useParams()
  const resume = useSelector((state: { resumes: { showData: IResume } }) => state.resumes.showData)
  const nextPath = cvNavLinks.SKILLS
  const prevPath = cvNavLinks.EXPERIENCE
  const deleteResumeProject = useDeleteResumeProject()
  const changeCvProjectsSorting = useChangeCvProjectsSorting()
  const getUserCv = useGetUserCv()

  useEffect(() => {
    if (resumeId) {
      getUserCv(+resumeId)
    }
  }, [resumeId])

  const onDeleteResumeProject = (id: number | undefined) => {
    deleteResumeProject(id as number, Number(resumeId))
  }

  const onEditProject = (project: IResumeProject) => {
    setOpen(!open)
    setProject(project)
  }

  const editProject = (
    <CvUpdatePortfolioProject setOpen={setOpen} project={project as IResumeProject} cvId={Number(resumeId)} />
  )

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, project: IResumeProject) => {
    setCurrentProject(project)
  }

  const dropHandler = (e: React.DragEvent<HTMLDivElement>, project: IResumeProject) => {
    e.preventDefault()
    if (currentProject?.id != project.id) {
      changeCvProjectsSorting(
        currentProject?.id as number,
        project.id as number,
        currentProject?.position as number,
        project.position,
        Number(resumeId),
      )
    }
  }

  const dragOverHandler = (e: SyntheticEvent) => e.preventDefault()

  return (
    <div className="cv__form">
      <UsersCv resumeId={resume?.id} userId={resume?.user_id} />
      <div className="cv__form_inner">
        <h4 className="cv__form_title">Portfolio</h4>
      </div>
      <Box sx={{ display: 'flex', width: '95%', gap: '10px' }}>
        <div className="leftSide_section">
          <Box sx={{ width: '60%' }}>
            <CvAddPortfolioProject cvId={Number(resumeId)} cv={resume} />
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            width: '50%',
            minHeight: '600px',
            flexDirection: 'column',
            marginBottom: 'auto',
            gap: '15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              maxHeight: '500px',
              flexDirection: 'column',
              gap: '15px',
              overflowY: 'auto',
              padding: '15px',
              marginBottom: '30px',
            }}
          >
            {resume?.resume_projects?.map((project) => (
              <Box
                draggable={true}
                onDragStart={(e) => dragStartHandler(e, project)}
                onDragOver={(e) => dragOverHandler(e)}
                onDrop={(e) => dropHandler(e, project)}
                key={project.id}
                sx={{
                  padding: '15px',
                  width: '100%',
                  height: 'auto',
                  border: '1px solid #dee0df',
                  borderRadius: 2,
                  cursor: 'pointer',
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontSize: '17px',
                      alignItems: 'center',
                    }}
                  >
                    {project.title}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <IconButton onClick={() => onEditProject(project)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => onDeleteResumeProject(project.id)}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ color: '#65696d', fontSize: '13px' }}>{project.url}</Box>
                  <Box
                    sx={{
                      fontWeight: 'bold',
                      color: '#65696d',
                      fontSize: '13px',
                      fontStyle: 'italic',
                      wordWrap: 'wrap',
                    }}
                  >
                    {project.description}
                  </Box>
                  <Box>
                    <div className="cv__portfolio_block_role">Role: {project.role}</div>
                    {project.team && <div className="cv__portfolio_block_role">Team: {project.team}</div>}
                    <div className="cv__portfolio_block_role">
                      Tech stack: {project.stack.map((project) => project + ', ')}
                    </div>
                    {project?.responsibilities && (
                      <div className="cv__portfolio_block_role">Responsibilities: {project.responsibilities}</div>
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          {open && (
            <Modal title={'Edit project'} component={editProject} open={open} handleClose={() => setOpen(false)} />
          )}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', marginTop: 'auto' }}>
            <CvNavButtons resumeId={resumeId} nextPath={nextPath} prevPath={prevPath} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CvPortfolioProjects
