import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import { fetchDashboardUsersData } from '../../services/API/dashboard'
import { fetchDashboardUsers, fetchDashboardUsersFailure, fetchDashboardUsersSuccess } from '../../store/usersSlice'

export const useFetchDashboardUsers = () => {
  const dispatch = useDispatch()

  return (page: number, subRole: string | undefined) => [
    dispatch(fetchDashboardUsers()),
    fetchDashboardUsersData(page, subRole)
      .then((response) => {
        dispatch(fetchDashboardUsersSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchDashboardUsersFailure(error.response.data[0]))
        dangerNotify(error.response.data[0])
      })
  ]
}
