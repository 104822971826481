import { createSlice } from '@reduxjs/toolkit'

import { UserProfile } from '../types'

const initialState = {
  showData: <UserProfile>{
    position: '',
    skype: '',
    telegram: '',
    github: '',
    phone_number: '',
    english_level: '',
    salary: '',
    job_started_on: '',
    job_ended_on: '',
    marital_status: '',
    children: [],
    home_address: '',
    office: '',
    post_office: '',
  },
  error: {},
  isLoading: true,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfile(state) {
      state.isLoading = true
    },
    getProfileSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    getProfileFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    updateProfile(state) {
      state.isLoading = true
    },
    updateProfileSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    updateProfileFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default profileSlice.reducer
export const {
  getProfile,
  getProfileSuccess,
  getProfileFailure,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
} = profileSlice.actions
