import React, { useState } from 'react'

import MobileGeneralProject from './generalProject/mobileGeneralProject'
import MobileClientInfoProject from './clientInfoProject/mobileClientInfoProject'
import MobileContractsLogsProject from './contractsLogsProject/mobileContractsLogsProject'
import { ProjectProfile } from 'types/Manage/Project/ProjectProfile'
import { EditProject } from 'components/admin/EditProject/EditProject'

const MobileProjectProfile: React.FC<ProjectProfile> = ({
  project,
  projects,
  usersNames,
  allCustomers,
  projectTechnologies,
  technologies,
  status,
  setPage,
  page,
  totalPages,
  openProject,
  toggleProject,
  setProjectData,
  setOpenProject,
}) => {
  const [additionalTab, setAdditionalTab] = useState('general')

  return (
    <div>
      {
        openProject ? (
          <EditProject
            projectTechnologies={projectTechnologies}
            setOpen={setOpenProject}
            selectedProject={project as NewProject}
            technologies={technologies}
            customers={allCustomers}
            usersNames={usersNames}
            setProjectData={setProjectData}
          />
        ) : (
          <div>
            <div className="project-profile__mobile-title">
              <div className={additionalTab === 'general' ? 
                'project-profile__mobile-title-active' : 
                'project-profile__mobile-title-item'}
              onClick={() => setAdditionalTab('general')}
              >
            General
              </div>
              <div className={additionalTab === 'client' ? 
                'project-profile__mobile-title-active' : 
                'project-profile__mobile-title-item'}
              onClick={() => setAdditionalTab('client')}
              >
            Client Info
              </div>
              <div className={additionalTab === 'contracts' ? 
                'project-profile__mobile-title-active' : 
                'project-profile__mobile-title-item'}
              onClick={() => setAdditionalTab('contracts')}
              >
            Contracts Logs
              </div>
            </div>
            <div className="project-profile__mobile-wrapper">
              {
                additionalTab === 'general' ?
                  <MobileGeneralProject
                    project={project}
                    projects={projects}
                    usersNames={usersNames}
                    allCustomers={allCustomers}
                    projectTechnologies={projectTechnologies}
                    technologies={technologies}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    openProject={openProject} 
                    toggleProject={toggleProject}
                    setProjectData={setProjectData}
                    setOpenProject={setOpenProject}
                  />
                  : additionalTab === 'client' ?
                    <MobileClientInfoProject
                      projectData={project}
                      customers={allCustomers}
                    />
                    : <MobileContractsLogsProject
                      usersNames={usersNames}
                      status={status}
                      setPage={setPage}
                      page={page}
                      totalPages={totalPages}
                    />
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export default MobileProjectProfile
