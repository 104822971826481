import { useDispatch } from 'react-redux'

import { fetchUserContracts, fetchUserContractsFailure, fetchUserContractsSuccess } from '../../store/contractsSlice'
import { getUserContracts } from 'services/API/users'
import dangerNotify from '../../helpers/dangerNotify'

export const useGetUsersContracts = () => {
  const dispatch = useDispatch()

  return (id: number, page: number) => [
    dispatch(fetchUserContracts()),
    getUserContracts(id, page)
      .then((response) => {
        dispatch(fetchUserContractsSuccess(response?.data))
      })
      .catch((error) => {
        dispatch(fetchUserContractsFailure(error))
        dangerNotify(error)
      }),
  ]
}
