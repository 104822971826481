import React, { useState } from 'react'
import { Navbar, NavbarToggler } from 'reactstrap'
import { isMobile } from 'react-device-detect'
import { webRoutes } from 'lib'
import CloseIcon from '@mui/icons-material/Close'
import { Drawer, IconButton, List, Collapse } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { HiOutlineBell } from 'react-icons/hi'
import { Link } from 'react-router-dom'

import { isManager } from 'helpers/accessHelpers'
import { ICurrentUser, Notification, User } from 'types'
import { navbarCollapsedData, NavbarTitle } from '../SiteNavbarData'
import MobileNavbarItem from './MobileNavbarItem'
import { Filter } from '../../../../icons'
import CandidatesMobileFilters from '../../../mobile/candidates/CandidatesMobileFilters'
import CustomersFiltersMobile from '../../../admin/CustomersFilters/CustomersFiltersMobile'
import RolesWrapper from './RolesWrapper'
import { disableAccessForRoles } from 'helpers/disableAccessForRoles'
import { SubRole } from 'types/SubRole'
import { rolesForRecruiters } from 'helpers/subRolesHelper'
import { SubRoleType } from 'enums/subRoles'
import { userRoles } from '../../../../constants'
import FilterIcon from 'icons/Filter'
import { useUtility } from 'context/utility'

type Props = {
  newNotifications: Notification[]
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentUser: ICurrentUser | null
  logout: () => void
}

const MobileNavbar: React.FC<Props> = ({ newNotifications, isOpen, setIsOpen, currentUser, logout }) => {
  const [openSubmitHoursCollapse, setOpenSubmitHoursCollapse] = useState(false)
  const [openManagesCollapse, setManageCollapse] = useState(false)
  const [openReportCollapse, setReportCollapse] = useState(false)
  const [filterOpened, setFilterOpened] = useState(false)
  const [openDashboardCollapse, setDashboardCollapse] = useState(false)
  const numberOfCustomers = useSelector(
    (state: { customers: { customersLength: number } }) => state.customers.customersLength,
  )
  const recordsCount = useSelector((state: { records: { count: number } }) => state.records.count)
  const user = useSelector((state: { users: { showData: User } }) => state.users.showData)
  const userLoading = useSelector((state: { users: { isLoading: boolean } }) => state.users.isLoading)
  const requestCount = useSelector((state: { requests: { count: number } }) => state.requests.count)
  const subRoles = useSelector((state: { subRoles: { indexData: SubRole[] } }) => state.subRoles.indexData)

  const location = useLocation()
  const { state: utilityState } = useUtility()
  const userReportPage = location.pathname === webRoutes.MY_REPORT
  const customersPage = location.pathname === webRoutes.CUSTOMERS
  const adminReportPage = location.pathname.startsWith(`${webRoutes.ADMIN_MONTHLY_CALENDAR}`)
  const DASHBOARD_REQUESTS = location.pathname === webRoutes.DASHBOARD_REQUESTS
  const toggle = () => setIsOpen(!isOpen)
  const toggleHoursCollapse = () => setOpenSubmitHoursCollapse(!openSubmitHoursCollapse)
  const toggleManageCollapse = () => setManageCollapse(!openManagesCollapse)
  const toggleReportCollapse = () => setReportCollapse(!openReportCollapse)
  const toggleFilterOpen = () => setFilterOpened(!filterOpened)
  const toggleDashboardCollapse = () => setDashboardCollapse(!openDashboardCollapse)

  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)
  const handleMobileFilterOpen = () => setMobileFilterOpen(true)
  const handleMobileFilterClose = () => setMobileFilterOpen(false)

  function upperCaseFirst(str: string) {
    return str.charAt(0).toUpperCase() + str.substring(1)
  }

  const renderModal = () => {
    if (filterOpened) {
      return <CandidatesMobileFilters closeModal={toggleFilterOpen} />
    } else {
      return null
    }
  }

  const project = useSelector((state: { projects: { showData: NewProject } }) => state.projects.showData)

  return (
    <>
      {renderModal()}
      {mobileFilterOpen && <CustomersFiltersMobile closeModal={handleMobileFilterClose} />}
      <NavbarToggler onClick={toggle} style={{ border: 'none' }} />
      {isMobile && !userReportPage && !adminReportPage && location.pathname !== webRoutes.CUSTOMERS && (
        <h4 className={DASHBOARD_REQUESTS ? 'navbar__title-request' : 'navbar__title'}>
          <div>
            {location.pathname === webRoutes.HOME
              ? 'Home'
              : location.pathname === `/projects/${project.id}`
                ? `${project.name}`
                : upperCaseFirst(location.pathname.replace('/', '')).replace(/-/g, ' ')}
            {DASHBOARD_REQUESTS && <span className="lists-counter-red">{requestCount}</span>}
          </div>
          {DASHBOARD_REQUESTS && (
            <Link to={webRoutes.NOTIFICATIONS} className={newNotifications.length > 0 ? 'bellIcon_after' : 'bellIcon'}>
              <HiOutlineBell fontSize="30px" color="#8992A6" />
            </Link>
          )}
        </h4>
      )}
      {isMobile && location.pathname === webRoutes.NOTIFICATIONS && newNotifications.length > 0 && (
        <div className="new-notifications__counter">{newNotifications.length}</div>
      )}
      {isMobile && customersPage && (
        <>
          <h4 className="navbar__title">Customers List</h4>
          <div className="new-notifications__counter">{numberOfCustomers}</div>
          <div onClick={() => handleMobileFilterOpen()} className="candidates-nav-icon">
            <Filter />
          </div>
        </>
      )}
      {isMobile && userReportPage && (
        <>
          <h4 className="navbar__title">Monthly report</h4>
          <div className="new-notifications__counter">{recordsCount}</div>
        </>
      )}
      {isMobile && adminReportPage && (
        <>
          <h4 className="navbar__title">{!userLoading && user?.full_name} report</h4>
        </>
      )}
      {location.pathname.includes(webRoutes.VACANCIES) && location.pathname !== webRoutes.VACANCIES && (
        <div onClick={() => toggleFilterOpen()} className="candidates-nav-icon">
          <Filter />
        </div>
      )}
      {utilityState.filterToggle && (
        <IconButton sx={{ position: 'absolute', right: '20px' }} onClick={utilityState.filterToggle}>
          <FilterIcon />
        </IconButton>
      )}

      <Drawer anchor="top" open={isOpen} onClose={() => setIsOpen(false)}>
        <Navbar color="light" expand="md" container="xxl" light>
          <IconButton onClick={toggle}>
            <CloseIcon />
          </IconButton>
          <h4 className="navbar__title">Menu</h4>
        </Navbar>
        <List className="navbar-list">
          <RolesWrapper generalRoles={[userRoles.DEFAULT.id]}>
            <MobileNavbarItem route={webRoutes.SUBMIT_HOURS} title={NavbarTitle.SUBMIT_TIME} toggleButton={toggle} />
            <MobileNavbarItem title={NavbarTitle.REPORT} toggleButton={toggleReportCollapse} />
            <Collapse in={openReportCollapse}>
              <List component="div" className="navbar-list__nested" disablePadding>
                {navbarCollapsedData.reportCollapse.map((item, key) => (
                  <MobileNavbarItem key={key} route={item.route} title={item.title} toggleButton={toggle} />
                ))}
              </List>
            </Collapse>
          </RolesWrapper>
          <RolesWrapper subRoles={rolesForRecruiters}>
            <MobileNavbarItem route={webRoutes.VACANCIES} title="Recruiters" toggleButton={toggle} />
          </RolesWrapper>
          <RolesWrapper generalRoles={[userRoles.MANAGER.id, userRoles.ADMIN.id]}>
            <RolesWrapper subRoles={disableAccessForRoles(subRoles, [SubRoleType.CFO])}>
              <MobileNavbarItem title={NavbarTitle.ADMIN_MAIN} toggleButton={toggleDashboardCollapse} />
              <Collapse in={openDashboardCollapse}>
                <List component="div" className="navbar-list__nested" disablePadding>
                  {navbarCollapsedData.dashboardCollapse.map((item, key) => (
                    <MobileNavbarItem key={key} route={item.route} title={item.title} toggleButton={toggle} />
                  ))}
                </List>
              </Collapse>
              <MobileNavbarItem title={NavbarTitle.SUBMIT_TIME} toggleButton={toggleHoursCollapse} />
              <Collapse in={openSubmitHoursCollapse}>
                <List component="div" className="navbar-list__nested" disablePadding>
                  {navbarCollapsedData.submitHoursCollapse.map((item, key) => (
                    <MobileNavbarItem key={key} route={item.route} title={item.title} toggleButton={toggle} />
                  ))}
                </List>
              </Collapse>
            </RolesWrapper>
            <MobileNavbarItem route={webRoutes.MONTHLY_REPORT} title={NavbarTitle.REPORT} toggleButton={toggle} />
            <RolesWrapper subRoles={disableAccessForRoles(subRoles, [SubRoleType.CFO])}>
              <MobileNavbarItem title={NavbarTitle.MANAGE} toggleButton={toggleManageCollapse} />
              <Collapse in={openManagesCollapse}>
                <List component="div" className="navbar-list__nested" disablePadding>
                  {navbarCollapsedData.manageCollapse.map((item, key) => (
                    <MobileNavbarItem key={key} route={item.route} title={item.title} toggleButton={toggle} />
                  ))}
                </List>
              </Collapse>
            </RolesWrapper>
          </RolesWrapper>
          {!isManager(currentUser) ? (
            <MobileNavbarItem route="/requests" title={NavbarTitle.REQUESTS} toggleButton={toggle} />
          ) : (
            <RolesWrapper subRoles={disableAccessForRoles(subRoles, [SubRoleType.CFO])}>
              <MobileNavbarItem route={webRoutes.REQUEST} title={NavbarTitle.REQUESTS} toggleButton={toggle} />
            </RolesWrapper>
          )}
          <MobileNavbarItem route={webRoutes.NOTIFICATIONS} title={NavbarTitle.NOTIFICATIONS} toggleButton={toggle} />
          <MobileNavbarItem route={webRoutes.SETTINGS} title={NavbarTitle.NEW_PASSWORD} toggleButton={toggle} />
          <MobileNavbarItem title={NavbarTitle.LOGOUT} toggleButton={logout} />
        </List>
      </Drawer>
    </>
  )
}

export default MobileNavbar
