import React, { useEffect, useState } from 'react'

import './mobile_dashboard.scss'
import { Pages } from '../../../enums/StartPage'
import { dashboardDefaultParams } from '../../../constants'
import { UserProfile } from '../../../types'
import { MobileFiredOrHiredDevelopers } from './index'
import { fetchUsersProfiles } from '../../../services/API/admin/profile'
import dangerNotify from '../../../helpers/dangerNotify'
import CustomPagination from '../../reusable/Pagination/Pagination'

type IProps = {
    period: string
}

const MobileFiredDevelopers: React.FC<IProps> = ({ period }) => {
  const [page, setPage] = React.useState(Pages.START_PAGE)
  const [profiles, setProfiles] = useState<UserProfile[]>([])
  const [totalPages, setTotalPages] = useState(1)

  useEffect(() => {
    fetchUsersProfiles(dashboardDefaultParams.HIRED, true, page, period)
      .then((response) => {
        setTotalPages(response.data.total_pages)
        setProfiles(response.data.profiles)
      })
      .catch((error) => dangerNotify(error.message))
  }, [page, period])

  return (
    <>
      {profiles ?
        <>
          <MobileFiredOrHiredDevelopers profiles={profiles} />
          <CustomPagination page={page} totalPages={totalPages} setPage={setPage} />
        </>
        :
        <div className="no_profiles">No users were found on your request</div>
      }
    </>
  )
}

export default MobileFiredDevelopers
