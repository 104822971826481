import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import { updateUserStatus } from '../../services/API/admin/users'
import { editUser, editUserFailure, editUserSuccess } from '../../store/usersSlice'
import { useFetchDashboardUsers } from './useFetchDashboardUsers'

export const useUpdateUserStatus = () => {
  const fetchDashboardUsersHook = useFetchDashboardUsers()
  const dispatch = useDispatch()

  return (status: string, userId: number, page: number, subRole?: string) => {
    dispatch(editUser())
    updateUserStatus(status, userId)
      .then((response) => {
        dispatch(editUserSuccess(response.data))
        fetchDashboardUsersHook(page, subRole)
      })
      .catch((error) => {
        dispatch(editUserFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
