import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { changeCvEducationsSorting } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'

export const useChangeCvEducationsSorting = () => {
  const getUserCv = useGetUserCv()

  return (
    prevCvEducationId: number,
    lastCvEducationId: number,
    prevEducationPosition: number,
    lastEducationPosition: number,
    resumeId: number
  ) => {
    changeCvEducationsSorting(prevCvEducationId, lastCvEducationId, prevEducationPosition, lastEducationPosition)
      .then(() => {
        getUserCv(resumeId)
        successNotify('Sorting changed!')
      })
      .catch((error) => dangerNotify(error.response.data.message[0]))
  }
}
