import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import React, { Dispatch, SetStateAction } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextFieldProps } from '@mui/material/TextField'
import uaLocale from 'date-fns/locale/uk'

import { Project } from 'types/Project'
import { Status } from 'types/Status'
import { User } from 'types/User'
import { Contract } from 'types/Contract'
import { contractSchema } from '../../../helpers/validationShema'
import { renderProject, renderStatus, renderUser } from '../../../helpers/renderName'
import { customStyles } from '../../../helpers/customSelectStyles'
import DatePickerTextField from '../ManageContracts/DatePickerTextField'
import { useAuth } from 'context/auth'
import { useEditContract } from '../../../hooks/contract/useEditContract'
import {
  jobStatusesOptionsForContractSelect,
  projectsOptionsForContractSelect,
  usersOptionsForContractSelect,
} from '../../../helpers/createOptionsForSelect'
import CustomButton from 'components/custom/CustomButton/CustomButton'
import { Box } from '@mui/material'
import { useUtility } from 'context/utility'

interface Props {
  usersNames: User[]
  projects: Project[]
  statuses: Status[]
  setOpen: Dispatch<SetStateAction<boolean>>
  selectedContract: Contract
}

interface IOptions {
  value: number | undefined
  label: string | undefined
}

interface IData {
  user: IOptions
  project: IOptions
  status: IOptions
  startDate: Date
  endDate: Date | null
}

const EditContract: React.FC<Props> = ({ usersNames, projects, statuses, setOpen, selectedContract }) => {
  const { currentUser } = useAuth()
  const { height } = useUtility()
  const teammates = usersOptionsForContractSelect(usersNames)
  const existingProjects = projectsOptionsForContractSelect(projects)
  const existingJobStatuses = jobStatusesOptionsForContractSelect(statuses)

  const editContractHook = useEditContract()
  const resolver = yupResolver(contractSchema)

  const defaultValues = {
    user: {
      value: selectedContract?.user_id,
      label: renderUser(selectedContract?.user_id, usersNames) || '',
    },
    project: {
      value: selectedContract?.project_id,
      label: renderProject(selectedContract?.project_id, projects) || '',
    },
    status: {
      value: selectedContract?.job_status_id,
      label: renderStatus(selectedContract?.job_status_id, statuses) || '',
    },
    startDate: selectedContract?.start_date || new Date(),
    endDate: selectedContract?.end_date || null,
  }
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({ resolver, defaultValues })
  const startDate = new Date(watch('startDate'))

  const formSubmit = (values: IData) => {
    const contract: Contract = {
      id: selectedContract?.id,
      user_id: values.user.value,
      project_id: values.project.value,
      job_status_id: values.status.value,
      start_date: values.startDate,
      end_date: values.endDate,
      admin_id: currentUser?.id,
    }
    editContractHook(contract)
    setOpen(false)
  }

  const modalElement = document.querySelector<HTMLElement>('.modal__content-container')

  return (
    <form onSubmit={handleSubmit(formSubmit)} style={{ fontFamily: 'Manrope, sans-serif' }}>
      <div>
        <Controller
          name="user"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isClearable={true}
              placeholder="Users"
              options={teammates}
              styles={customStyles(errors.user)}
              maxMenuHeight={(modalElement && modalElement?.offsetHeight / 2) || height / 5}
            />
          )}
        />
        {errors.user && <p className="form_select__error">This field is required</p>}
      </div>
      <div>
        <Controller
          name="project"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isClearable={true}
              placeholder="Projects"
              options={existingProjects}
              styles={customStyles(errors.project)}
              maxMenuHeight={(modalElement && modalElement?.offsetHeight / 2) || height / 5}
            />
          )}
        />
        {errors.project && <p className="form_select__error">This field is required</p>}
      </div>
      <div>
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isClearable={true}
              placeholder="JobStatus"
              options={existingJobStatuses}
              styles={customStyles(errors.status)}
              maxMenuHeight={(modalElement && modalElement?.offsetHeight / 2) || height / 5}
            />
          )}
        />
        {errors.status && <p className="form_select__error">This field is required</p>}
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
        <Controller
          name="startDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              mask="__.__.____"
              className="form_select__datepicker"
              label="Start date"
              value={value}
              onChange={(value: Date | null) => onChange(value)}
              renderInput={(params: TextFieldProps) => <DatePickerTextField params={params} />}
            />
          )}
        />
        {errors.startDate && <p className="form_select__error">This field is required</p>}
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
        <Controller
          name="endDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              label="End date"
              mask="__.__.____"
              value={value}
              minDate={startDate}
              onChange={(value: Date | null) => onChange(value)}
              renderInput={(params: TextFieldProps) => <DatePickerTextField params={params} />}
            />
          )}
        />
      </LocalizationProvider>
      <Box sx={{ display: 'flex', gap: '1rem', marginTop: '40px' }}>
        <CustomButton variant="outlined" onClick={() => setOpen(false)} text="Cancel" />
        <CustomButton type="submit" text="Edit" />
      </Box>
    </form>
  )
}

export default EditContract
