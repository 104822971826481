import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import {
  fetchProjectTechnologies,
  fetchProjectTechnologiesFailure,
  fetchProjectTechnologiesSuccess
} from '../../store/projectsSlice'
import { fetchProjectTechnologiesData } from '../../services/API/projects'

export const useFetchProjectTechnologies = () => {
  const dispatch = useDispatch()

  return () => [
    dispatch(fetchProjectTechnologies()),
    fetchProjectTechnologiesData()
      .then((response) => {
        dispatch(fetchProjectTechnologiesSuccess(response?.data))
      })
      .catch((error) => {
        dispatch(fetchProjectTechnologiesFailure(error))
        dangerNotify(error)
      }),
  ]
}
