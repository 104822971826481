import { createSlice } from '@reduxjs/toolkit'

import { Customer } from '../types'

const initialState = {
  indexData: <Customer[]>[],
  allCustomersData: <Customer[]>[],
  showData: <Customer>{},
  error: {},
  pages: 1,
  isLoading: true,
  customersLength: 0,
  companies: []
}

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    fetchCustomers(state) {
      state.isLoading = true
    },
    fetchCustomersSuccess(state, action) {
      state.indexData = action.payload.customers
      state.pages = action.payload.pages
      state.isLoading = false
      state.customersLength = action.payload.customersLength
      state.companies = action.payload.companies
    },
    fetchCustomersFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    fetchAllCustomers(state) {
      state.isLoading = true
    },
    fetchAllCustomersSuccess(state, action) {
      state.allCustomersData = action.payload
      state.isLoading = false
    },
    fetchAllCustomersFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    getCustomer(state) {
      state.isLoading = true
    },
    getCustomerSuccess(state, action) {
      state.showData = action.payload
      state.pages = action.payload.pages
      state.isLoading = false
    },
    getCustomerFailure(state, action) {
      state.error = action.payload
      state.isLoading = true
    },

    addCustomer(state) {
      state.isLoading = true
    },
    addCustomerSuccess(state, action) {
      state.indexData.unshift(action.payload)
      state.isLoading = false
    },
    addCustomerFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    updateCustomer(state) {
      state.isLoading
    },
    updateCustomerSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    updateCustomerFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    removeCustomer(state) {
      state.isLoading = true
    },
    removeCustomerSuccess(state, action) {
      state.indexData = state.indexData.filter(
        (customer: Customer) => customer.id !== action.payload)
      state.isLoading = false
    },
    removeCustomerFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default customersSlice.reducer
export const {
  fetchCustomers,
  fetchCustomersFailure,
  fetchCustomersSuccess,
  getCustomer,
  getCustomerFailure,
  getCustomerSuccess,
  removeCustomer,
  removeCustomerFailure,
  removeCustomerSuccess,
  addCustomer,
  addCustomerFailure,
  addCustomerSuccess,
  updateCustomer,
  updateCustomerFailure,
  updateCustomerSuccess,
  fetchAllCustomers,
  fetchAllCustomersSuccess,
  fetchAllCustomersFailure
} = customersSlice.actions
