import { CSSProperties } from 'react'
import { CSSObject } from '@mui/material'
import { StylesConfig } from 'react-select'
import { OptionTypes } from '../components/admin/ManageContracts/ContractsFilters'


export const customStyles = (option: unknown) => ({
  menu: (provided: CSSProperties) => ({
    ...provided,
    zIndex: 5,
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    marginTop: '1%',
    marginBottom: '1%',
    border: option ? '1px solid red' : '1px solid ababab',
    boxShadow: 'none',
  }),
})

export const customStylesEdit = (option: unknown) => ({
  menu: (provided: CSSProperties) => ({
    ...provided,
    zIndex: 5,
  }),
  control: (provided: CSSProperties) => ({
    ...provided,
    marginTop: '1%',
    marginBottom: '1%',
    border: option ? '1px solid red' : '1px solid ababab',
    boxShadow: 'none',
    height: '50px'
  }),
})

export const styles = {
  container: (base: CSSObject) => ({
    ...base,
    zIndex: 5,
    fontSize: '15px',
  }),
  option: (base: CSSObject) => ({
    ...base,
    fontSize: '15px',
  })
}

export const contractFiltersSelectStyles: StylesConfig<OptionTypes, false> = {
  container: (base) => ({
    ...base,
    width: 230,
    marginLeft: 10,
  }),
  option: (base) => ({
    ...base,
    color: 'black',
    backgroundColor: 'white',
    '&::selection': {
      backgroundColor: 'white',
      color: 'black',
    },
    '&:hover': {
      backgroundColor: '#dfe8ff',
      color: 'black',
    },
  }),
}
