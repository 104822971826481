import React, { ChangeEvent, memo, MouseEvent, MouseEventHandler, useEffect, useRef, useState } from 'react'

import { TrashIcon, UploadIcon } from 'icons'
import { Button } from '@mui/material'
import dangerNotify from 'helpers/dangerNotify'

const startEvnts = ['dragenter', 'dragover']
const endEvnts = ['dragleave', 'drop']
const events = [...startEvnts, ...endEvnts]

const preventDefaults = (e: Event) => {
  e.preventDefault()
  e.stopPropagation()
}

type Props = {
  image?: string
  setImage: (file: string | File | null) => void
}

const DragNDrop = ({ image, setImage }: Props) => {
  const areaRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [file, setFile] = useState<File | null>(null)
  const [preview, setPreview] = useState('')
  const [willRemove, setWillRemove] = useState(false)

  const handleFile = (files: FileList | null) => {
    if (files?.length) {
      if (files[0].size > 5 * 1024 * 1024) {
        dangerNotify('Please pick smaller image (< 5MB)')
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = () => setPreview(reader.result as string)

      setFile(files[0])
      setImage(files[0])
    }
  }

  const handleChange = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    handleFile(files)
  }

  const handleRemove = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setFile(null)
    setImage('_destroy')
    setWillRemove(true)
  }

  useEffect(() => {
    if (areaRef.current) {
      const dropArea = areaRef.current

      const highlight = () => dropArea.classList.add('active')
      const unhighlight = () => dropArea.classList.remove('active')
      const handleDrop = (e: DragEvent) => {
        const files = e.dataTransfer?.files || null
        handleFile(files)
      }

      events.forEach((eventName) => {
        dropArea.addEventListener(eventName, preventDefaults, false)
        document.body.addEventListener(eventName, preventDefaults, false)
      })
      startEvnts.forEach((eventName) => {
        dropArea.addEventListener(eventName, highlight, false)
      })
      endEvnts.forEach((eventName) => {
        dropArea.addEventListener(eventName, unhighlight, false)
      })
      dropArea.addEventListener('drop', handleDrop, false)

      return () => {
        events.forEach((eventName) => {
          dropArea.removeEventListener(eventName, preventDefaults, false)
          document.body.removeEventListener(eventName, preventDefaults, false)
        })
        startEvnts.forEach((eventName) => {
          dropArea.removeEventListener(eventName, highlight, false)
        })
        endEvnts.forEach((eventName) => {
          dropArea.removeEventListener(eventName, unhighlight, false)
        })
        dropArea.removeEventListener('drop', handleDrop, false)
      }
    }
  }, [areaRef.current])

  return (
    <div
      className="drop-area"
      ref={areaRef}
      onClick={(e) => {
        e.stopPropagation()
        inputRef.current?.click()
      }}
    >
      {!willRemove && ((file && preview) || image) ? (
        <div className="drop-area-preview-container">
          <div className="drop-area-preview-image-wrap">
            <img className="drop-area-preview-image" src={preview || image} alt="Avatar preview" />
            <div className="drop-area-preview-image-info">
              <p className="drop-area-preview-name">{file?.name || 'Avatar'}</p>
              {file?.size && <p className="drop-area-preview-size">{Math.round(file.size / 1024)}&nbsp;KB</p>}
            </div>
          </div>
          <Button onClick={handleRemove}>
            <TrashIcon />
          </Button>
        </div>
      ) : (
        <>
          <UploadIcon />
          <p className="drop-area-text">
            <span className="drop-area-text-span d-inline d-xl-none">Touch to upload</span>
            <span className="drop-area-text-span d-none d-lg-inline">Click to upload</span>
            &nbsp;<span className="d-none d-lg-inline">or drag and drop</span>
          </p>
          <p className="drop-area-text">JPG, PNG, or GIF (max. 5MB)</p>
        </>
      )}
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/gif, image/jpeg, image/jpg, image/png"
        onChange={handleChange}
      />
    </div>
  )
}

export default memo(DragNDrop)
