import React from 'react'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import Select, { GroupBase, OptionsOrGroups } from 'react-select'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import ReactInput from '../../../../reusable/ReactInput'
import { styles } from '../../../../../helpers/customSelectStyles'
import { IEducationInputs, IOmitedEducationOption } from './CvAddEducation'


interface IProps {
  control: Control<IEducationInputs>
  register: UseFormRegister<IEducationInputs>
  country: {
    label: string,
    value?: string
  }
  changeHandler: (value: IOmitedEducationOption) => void
  options: OptionsOrGroups<{ label: string, value: string }, GroupBase<{ label: string, value: string }>>
}

const CvEducationForm: React.FC<IProps> = ({
  control,
  register,
  country,
  changeHandler,
  options
}) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ReactInput
            type="text"
            label="College Or University Name"
            register={register}
            registerControl="university"
          />
          <ReactInput
            type="text"
            label="Level"
            register={register}
            registerControl="level"
          />
        </Box>
        <Box sx={{ display: 'flex', gap: '5px', width: '100%' }} >
          <Box sx={{ width: '100%' }} >
            <Select isClearable={true} styles={styles} options={options} value={country} onChange={changeHandler} />
          </Box>
          <Box sx={{ width: '100%' }} >
            <ReactInput
              type="text"
              label="City"
              register={register}
              registerControl="city"
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: '100%' }} >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }} >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="startOfStudies"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="Beginning of study"
                  views={['year', 'month']}
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="endOfStudies"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="End of study"
                  views={['year', 'month']}
                  value={value}
                  onChange={onChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </>
  )
}

export default CvEducationForm
