import React, { SyntheticEvent, useEffect } from 'react'
import Box from '@mui/material/Box'
import { Grid } from '@material-ui/core'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { User } from '../../../../types'
import { useGetUser } from '../../../../hooks/user/useGetUser'
import { resumeSections } from '../../../../constants'
import { webRoutes } from '../../../../lib'

type Props = {
  userId: number
  resumeId: number | undefined
}

const UsersCv: React.FC<Props> = ({ userId, resumeId }) => {
  const getUser = useGetUser()
  const user = useSelector((state: { users: { showData: User } }) => state.users.showData)
  const userIdFromUrl = new URLSearchParams(useLocation().search).get('userId')
  const cvId = useParams().cvId
  const isDisabled = !user.has_cv

  useEffect(() => {
    if(userId || userIdFromUrl) {
      getUser(userId || Number(userIdFromUrl))
    }
  },[userId, userIdFromUrl])

  const onHandleClick = (e: SyntheticEvent) => {
    if(isDisabled) e.preventDefault()
  }

  return (
    <Grid container >
      <Grid item xs={12} >
        <Box sx={{ borderBottom: 1 }}>
          <nav className="nav__list">
            <div>
              {resumeSections.map((section, index) => {
                return(
                  <NavLink
                    onClick={onHandleClick}
                    className={({ isActive }) => isActive ? 'nav__item_active' : 'nav__item'}
                    to={`${webRoutes.RESUMES}/${resumeId || cvId}${section.url}`}
                    key={index}
                  >
                    {section.name}
                  </NavLink>
                )
              })}
            </div>
            <div className="nav__item">
              {user.full_name}
            </div>
          </nav>
        </Box>
      </Grid>
    </Grid>
  )
}

export default UsersCv
