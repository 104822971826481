import React, { Dispatch, SetStateAction } from 'react'
import Select, { GroupBase, OptionsOrGroups } from 'react-select'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { UseFormRegister } from 'react-hook-form'

import ReactInput from '../../../../reusable/ReactInput'
import CvAvatarCropper from '../CvAvatarCropper'
import { styles } from '../../../../../helpers/customSelectStyles'
import { User } from '../../../../../types'
import { IContactsDataInputs, IContactsDataOmitedOption } from './CvAddContactsData'

interface Props {
    setReadyFile: Dispatch<SetStateAction<undefined>>
    register: UseFormRegister<IContactsDataInputs>
    country: {
      label: string | undefined,
      value?: string
    }
    onSelectHandler: (value: IContactsDataOmitedOption) => void
    user: User
    setImage: Dispatch<SetStateAction<string>>
    setCropper: Dispatch<SetStateAction<undefined>>
    isCropped: boolean
    setIsCropped: Dispatch<SetStateAction<boolean>>
    cropper: undefined
    options: OptionsOrGroups<{ label: string, value: string }, GroupBase<{ label: string, value: string }>>
    image: string | undefined
}

const CvContactsDataForm: React.FC<Props> = ({
  setReadyFile,
  register,
  country,
  onSelectHandler,
  setImage,
  setCropper,
  isCropped,
  setIsCropped,
  cropper,
  options,
  image
}) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ReactInput type="text" label="Name" register={register} registerControl="name" required/>
          <ReactInput
            required
            type="text"
            label="User's role"
            register={register}
            registerControl="role"
          />
          <ReactInput
            type="text"
            label="User's github URL (optional)"
            register={register}
            registerControl="github" />
          <Box sx={{ display: 'flex', gap: '5px', width: '100%' }} >
            <Box sx={{ width: '50%' }} >
              <Select
                isClearable={true}
                styles={styles}
                options={options}
                value={country}
                onChange={onSelectHandler}
              />
            </Box>
            <Box sx={{ width: '50%' }} >
              <ReactInput
                type="text"
                label="City"
                register={register}
                registerControl="city"
              />
            </Box>
          </Box>
          <TextField
            fullWidth
            multiline
            {...register('summary')}
            label="Summary"
            InputProps={{
              rows: 5
            }}
          />
        </Box>
        <Box >
          <CvAvatarCropper
            setReadyFile={setReadyFile}
            image={image}
            setImage={setImage}
            cropper={cropper}
            setCropper={setCropper}
            isCropped={isCropped}
            setIsCropped={setIsCropped}
          />
        </Box>
      </Box>
    </>
  )}

export default CvContactsDataForm
