import React, { Dispatch, SetStateAction, useMemo } from 'react'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { Box, Button, Tooltip } from '@mui/material'
import { useAuth } from 'context/auth'

import { switchByDate } from 'helpers/tabHeaderRequests'
import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import DatePicker from 'components/user/DatePicker'
import { isManager } from 'helpers/accessHelpers'
import DefaultSelect from 'components/custom/DefaultSelect/DefaultSelect'
import { IOptions } from '../../../types/ISelectOptions'
import { UserLeaves } from '../../../types/UserLeaves'
import { arrowBackItem, arrowNextItem } from '../../../helpers/userDatepickerArrows'
import { leaveCounters, leavesTypes } from '../../../constants'
import { requestIcons } from '../icons'
import { isMobile } from 'react-device-detect'
import { FilterSwitch } from 'components/reusable/FIlterSwitch'

const leaveRegex = /(full_day_|half_day_)/g

interface Props {
  changeTab: (name: string) => void
  alignment: string
  monthDate: Date
  setPage: (page: number) => void
  setMonthDate: Dispatch<SetStateAction<Date>>
  filterDate: Date
  setFilterDate: (value: Date) => void
  updateDate: (value: Date) => void
  handleOpen: () => void
  active: string
  usersName?: IOptions[]
  filterUser?: IOptions | null
  onChangeFilterUser?: ((user: IOptions | null) => void) | undefined
  userLeaves?: UserLeaves
  closeFilter?: () => void
  requestCount?: number
}

const RequestsHeader: React.FC<Props> = ({
  changeTab,
  alignment,
  monthDate,
  setMonthDate,
  setPage,
  filterDate,
  setFilterDate,
  updateDate,
  handleOpen,
  active,
  userLeaves,
  usersName,
  filterUser,
  onChangeFilterUser,
  closeFilter,
  requestCount,
}) => {
  const { currentUser } = useAuth()
  const nextArrow = () => arrowNextItem(monthDate, setMonthDate, setPage)
  const prevArrow = () => arrowBackItem(monthDate, setMonthDate, setPage)

  const _userLeaves = useMemo(() => {
    if (userLeaves) {
      const result: { [k: string]: number } = {}

      Object.keys(userLeaves).forEach((key) => {
        if (leaveRegex.test(key)) {
          const _key = key.replace(leaveRegex, '')

          if (!(_key in result)) result[_key] = 0

          result[_key] += key.includes('half')
            ? userLeaves[key as keyof typeof userLeaves] / 2
            : userLeaves[key as keyof typeof userLeaves]
        } else {
          result[key] = userLeaves[key as keyof typeof userLeaves]
        }
      })

      return result
    } else {
      return {}
    }
  }, [userLeaves])

  const isAdmin = isManager(currentUser)

  return (
    <div className="requests__filters-wrapper">
      <div className="requests__filters-inner">
        <FilterSwitch changeTab={changeTab} active={alignment} />
        {alignment === 'month' ? (
          <CustomDatepicker
            value={monthDate}
            onChange={(value) => {
              setMonthDate(value as Date)
              setPage(1)
            }}
            mask={'__.__.____'}
            inputType="day"
            maxDate={null}
            datePickerType="month"
            borderInputColor="#D4D9E2"
            arrowNextItem={nextArrow}
            arrowBackItem={prevArrow}
            disableClearButton={true}
          />
        ) : (
          <DatePicker date={filterDate} setDate={setFilterDate} updateDate={updateDate} maxDate={null} />
        )}
        {isAdmin && (
          <Box sx={{ minWidth: '300px' }}>
            <DefaultSelect
              placeholder="User"
              options={usersName}
              isClearable={true}
              value={filterUser}
              onChange={onChangeFilterUser}
            />
          </Box>
        )}
      </div>

      {!isAdmin && !isMobile && (
        <div className="requests__pills">
          {Object.entries(leavesTypes).map(([raw, parsed]) => (
            <Tooltip key={raw} title={parsed + 's'} arrow placement="top">
              <div className={`requests__pill pill__${raw}`}>
                {requestIcons[raw as keyof typeof leavesTypes]}
                {_userLeaves[raw.slice(0, -1) as keyof typeof userLeaves] || 0}
                {leaveCounters[raw as keyof typeof leaveCounters] &&
                  `/${leaveCounters[raw as keyof typeof leaveCounters]}`}
              </div>
            </Tooltip>
          ))}
        </div>
      )}

      {isMobile ? (
        <Button
          sx={{ position: 'absolute', bottom: 20, width: 'calc(100% - 40px) !important' }}
          className="modal__button modal__submit mt-4"
          variant="contained"
          onClick={closeFilter}
        >
          Show Results <span>({requestCount})</span>
        </Button>
      ) : (
        <Button className="requests__button" onClick={handleOpen}>
          <div className="requests__button">
            Send Requests <SendOutlinedIcon />
          </div>
        </Button>
      )}
    </div>
  )
}

export default RequestsHeader
