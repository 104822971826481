import React, { SetStateAction, useEffect, useState } from 'react'
import { Form, FormGroup, Input, Row, Col, Button, Label } from 'reactstrap'
import { useSelector } from 'react-redux'
import { OnChangeValue } from 'react-select'
import Switch from 'react-switch'
import { useNavigate } from 'react-router-dom'

import { Candidate, Technology, Vacancy } from '../../../types'
import { useUpdateCandidate } from '../../../hooks/candidate/useUpdateCandidate'
import { significate, englishLevel } from '../../../constants'
import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import { dateFormatYYMMDD } from 'helpers/displayingDateFormat'
import { useGetTechnologies } from 'hooks/Technology/useGetTecnologies'
import dangerNotify from 'helpers/dangerNotify'
import { createCandidate } from 'services/API/candidates'
import { IOptions } from 'types/ISelectOptions'
import MultiSelect from 'components/custom/MultiSelect/MultiSelect'
import Modal from 'components/reusable/NewModal'

const CandidateForm = ({ isNewRecord, isOpen, toggle, vacancyId }) => {
  const initialCandidate = {
    full_name: '',
    vacancy_id: vacancyId,
    stack: '',
    cv_url: '',
    first_interview: '',
    phone: '',
    email: '',
    telegram: '',
    linkedin: '',
    resource_url: '',
    salary: '',
    english: 'elementary',
    location: '',
    company: '',
    experience: '',
    significate: 'hired',
    ping_again: false,
    first_additional_link: '',
    second_additional_link: '',
    technologies: [],
  }
  const [candidateInfo, setCandidateInfo] = useState(initialCandidate)

  const vacanciesPage = useSelector((state: { vacancies: { indexData: Vacancy } }) => state.vacancies.indexData)
  const candidate = useSelector((state: { candidates: { showData: Candidate } }) => state.candidates.showData)
  const technologies = useSelector(
    (state: { technologies: { indexData: Technology[] } }) => state.technologies.indexData,
  )
  const technologiesIds = candidateInfo?.technologies?.map((technology) => technology.value)
  const updateCandidate = useUpdateCandidate()
  const getTechnologies = useGetTechnologies()

  const techStack = technologies.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }))

  const convertedTechnologies = techStack?.filter((technology) => {
    return candidate.technologies?.some((item) => technology.label === item?.toString())
  })

  const navigate = useNavigate()
  const [startDate, setStartDate] = useState<string | undefined>('')

  useEffect(() => {
    getTechnologies()
    if (!isNewRecord) {
      setStartDate(candidate.first_interview)
      setCandidateInfo({ ...candidate, technologies: convertedTechnologies })
    }
  }, [candidate])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    const newCandidate = {
      ...candidateInfo,
      [name]: value,
      first_interview: dateFormatYYMMDD(startDate),
    }
    setCandidateInfo(newCandidate)
  }

  const handleStartDate = (value: SetStateAction<string | undefined>) => {
    setStartDate(value)
  }

  const handleTechStack = (selectedOptions: OnChangeValue<IOptions, true>) => {
    const newCandidate = {
      ...candidateInfo,
      technologies: selectedOptions,
      ping_again: candidateInfo.ping_again,
    }

    setCandidateInfo(newCandidate)
  }

  const onSubmit = () => {
    const newCandidate = {
      ...candidateInfo,
      first_interview: dateFormatYYMMDD(startDate),
      technologies: technologiesIds,
      ping_again: candidateInfo.ping_again,
    }

    if (candidateInfo.full_name != '') {
      if (isNewRecord) {
        createCandidate(newCandidate).then((response) => {
          navigate(`/candidates/${response.data.id}`)
        })
      } else {
        updateCandidate(newCandidate)
      }
      setCandidateInfo(initialCandidate)
      toggle()
    } else {
      dangerNotify('Fill out candidate name.')
    }
  }

  const onPing = (value: boolean) => {
    const newCandidate = { ...candidateInfo, ping_again: value }

    setCandidateInfo(newCandidate)
  }

  return (
    <Modal
      title={isNewRecord ? 'Add New Candidate' : 'Edit Candidate'}
      open={isOpen}
      handleClose={toggle}
      component={
        <>
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Full Name</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Full Name"
                    minLength={3}
                    name="full_name"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.full_name}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Vacancy</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Vacancy"
                    name="vacancy_id"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.vacancy_id}
                    type="select"
                  >
                    {vacanciesPage.vacancies?.map((vacancy: Vacancy) => (
                      <option key={vacancy.id} value={vacancy.id}>
                        {vacancy.title}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup className="labeled">
                  <MultiSelect
                    isClearable
                    placeholder="Tech stack"
                    name="stack"
                    onChange={handleTechStack}
                    value={candidateInfo.technologies}
                    options={techStack}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>CV Link</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="CV Link"
                    name="cv_url"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.cv_url}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="labeled">
                  <CustomDatepicker
                    inputType="default"
                    placeholder="First Contact"
                    value={startDate}
                    onChange={handleStartDate}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Mobile</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Mobile"
                    name="phone"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.phone}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Email</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Email"
                    name="email"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.email}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Telegram</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Telegram"
                    name="telegram"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.telegram}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>LinkedIn</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="LinkedIn"
                    name="linkedin"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.linkedin}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Additional Link 1</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Additional Link 1"
                    name="first_additional_link"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.first_additional_link}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Additional Link 2</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Additional Link 2"
                    name="second_additional_link"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.second_additional_link}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Resource Url</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Resource Url"
                    name="resource_url"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.resource_url}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="labeled">
                  <Label>Salary</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Salary"
                    name="salary"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.salary}
                  />
                </FormGroup>
              </Col>
              <Col md={3} className="labeled">
                <FormGroup>
                  <Label>English</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="English"
                    name="english"
                    type="select"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.english}
                  >
                    {Object.values(englishLevel).map((s) => (
                      <option key={s.value} value={s.value}>
                        {s.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Location</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Kyiv"
                    name="location"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.location}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Experience</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="0"
                    name="experience"
                    type="number"
                    min={0}
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.experience}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup className="labeled">
                  <Label>Significate</Label>
                  <Input
                    bsSize={'lg'}
                    placeholder="Significate"
                    name="significate"
                    onChange={(e) => onChange(e)}
                    value={candidateInfo.significate}
                    type="select"
                  >
                    {Object.values(significate).map((s) => (
                      <option key={s.value} value={s.value}>
                        {s.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <Label className="d-block">Ping again</Label>
                <Switch
                  onColor="#32D583"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  width={50}
                  offColor="#D4D9E2"
                  height={30}
                  onChange={(checked) => onPing(checked)}
                  checked={candidateInfo.ping_again}
                />
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col md={6}>
              <Button onClick={toggle} size={'lg'} block color="light">
                Cancel
              </Button>
            </Col>
            <Col md={6}>
              <Button onClick={onSubmit} size={'lg'} block color="dark">
                {isNewRecord ? 'Create' : 'Update'}
              </Button>
            </Col>
          </Row>
        </>
      }
    />
  )
}

export default CandidateForm
