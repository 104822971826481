import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { Tab, Tabs } from '@mui/material'

import './Preview/mobileStyles.scss'
import { IResume } from '../../../../types/Resume/IResume'
import { GeneralTab, ExperienceTab, EducationTab, ProjectsTab, CoursesTab } from './Preview'
import Loader from '../../../reusable/Loader'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

interface Props {
  resume: IResume | undefined
}

const MobileNavigation: React.FC<Props> = ({ resume }) => {
  const [value, setValue] = useState(0)
  const tabLabels = ['General', 'Experience', 'Education', 'Projects', 'Courses']

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      {!resume?.user_id ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              className="mobile_tabs"
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { background: '#FF655C' } }}
              sx={{ display: 'flex', justifyContent: 'space-between', '& button': { padding: '10px 20px' } }}
              variant="scrollable"
            >
              {tabLabels.map((label, index) => (
                <Tab label={label} style={{ textTransform: 'none' }} key={index} />
              ))}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <GeneralTab resume={resume} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ExperienceTab resume={resume} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EducationTab resume={resume} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ProjectsTab resume={resume} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <CoursesTab resume={resume} />
          </TabPanel>
        </>
      )}
    </>
  )
}

export default MobileNavigation
