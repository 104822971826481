import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useSelector } from 'react-redux'

import UsersCv from '../UsersCv'
import CvNavButtons from '../CvNavButtons'
import Modal from '../../../../reusable/Modal/index.tsx'
import { IResume } from '../../../../../types/Resume/IResume'
import { cvNavLinks } from '../../../../../constants'
import CvAddCertificate from './CvAddCertificate'
import { IResumeCertificate } from '../../../../../types/Resume/IResumeCertificate'
import CvUpdateCertificate from './CvUpdateCertificate'
import { useDeleteResumeCertificate } from '../../../../../hooks/resume/certificates/useDeleteResumeCertificate'
import { useGetUserCv } from '../../../../../hooks/resume/useGetUserCv'

const CvCertificates: React.FC = () => {
  const { resumeId } = useParams()
  const [open, setOpen] = useState(false)
  const [certificate, setCertificate] = useState<IResumeCertificate>()
  const resume = useSelector((state: { resumes: { showData: IResume } }) => state.resumes.showData)
  const getUserCv = useGetUserCv()
  const deleteCertificate = useDeleteResumeCertificate()
  const nextPath = cvNavLinks.COURSES
  const prevPath = cvNavLinks.EDUCATION

  useEffect(() => {
    if (resumeId) {
      getUserCv(+resumeId)
    }
  }, [resumeId])

  const onDeleteResumeCertificate = (id: number | undefined) => {
    deleteCertificate(id as number, Number(resumeId))
  }

  const onEditCertificate = (certificate: IResumeCertificate) => {
    setOpen(!open)
    setCertificate(certificate)
  }

  const editCertificate = (
    <CvUpdateCertificate setOpen={setOpen} certificate={certificate as IResumeCertificate} cvId={Number(resumeId)} />
  )

  return (
    <div className="cv__form">
      <UsersCv resumeId={resume?.id} userId={resume?.user_id} />
      <div className="cv__form_inner">
        <h4 className="cv__form_title">Certificates</h4>
      </div>
      <Box sx={{ display: 'flex', width: '95%', gap: '10px' }}>
        <div className="leftSide_section">
          <Box sx={{ width: '60%' }}>
            <CvAddCertificate cvId={Number(resumeId)} />
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            width: '50%',
            minHeight: '600px',
            flexDirection: 'column',
            marginBottom: 'auto',
            gap: '15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              maxHeight: '500px',
              flexDirection: 'column',
              gap: '15px',
              overflowY: 'auto',
              padding: '15px',
              marginBottom: '30px',
            }}
          >
            {resume?.certificates?.map((certificate) => (
              <Box
                key={certificate.id}
                sx={{
                  padding: '15px',
                  width: '100%',
                  maxHeight: '200px',
                  border: '1px solid #dee0df',
                  borderRadius: 2,
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      fontSize: '17px',
                      alignItems: 'center',
                    }}
                  >
                    {certificate.name}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <IconButton onClick={() => onEditCertificate(certificate)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => onDeleteResumeCertificate(certificate.id)}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ fontWeight: 'bold', color: '#65696d', fontSize: '13px', fontStyle: 'italic' }}>
                    {certificate.url}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          {open && (
            <Modal
              title={'Edit certificate'}
              component={editCertificate}
              open={open}
              handleClose={() => setOpen(false)}
            />
          )}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', marginTop: 'auto' }}>
            <CvNavButtons resumeId={resumeId} nextPath={nextPath} prevPath={prevPath} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CvCertificates
