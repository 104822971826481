import { useDispatch } from 'react-redux'

import { getCandidateNames } from '../../services/API/candidates'
import {
  fetchCandidateNames,
  fetchCandidateNamesSuccess,
  fetchCandidateNamesFailure
} from '../../store/candidatesSlice'
import dangerNotify from '../../helpers/dangerNotify'

export const useFetchCandidateNames = () => {
  const dispatch = useDispatch()

  return (vacancy_id: number) => {
    dispatch(fetchCandidateNames())
    getCandidateNames(vacancy_id)
      .then((response) => {
        dispatch(fetchCandidateNamesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchCandidateNamesFailure(error))
        dangerNotify('Server error: unable to download Candidate Names')
      })
  }
}
