import dangerNotify from '../../helpers/dangerNotify'
import { createContract } from '../../services/API/admin/contracts'
import { Contract } from 'types/Contract'
import successNotify from '../../helpers/successNotify'
import { useDispatch } from 'react-redux'
import { addProjectFailure } from '../../store/projectsSlice'
import { addContract, addContractSuccess } from '../../store/contractsSlice'
import { getContractsParams, getUsersParams } from '../../constants'
import { useGetContracts } from './useGetContracts'
import { useGetUserProjects } from '../project/useGetProjects'
import { useGetUsers } from '../user/useGetUsers'

export const useCreateContract = () => {
  const dispatch = useDispatch()
  const getContracts = useGetContracts()
  const getUsers = useGetUsers()
  const getProjectsHook = useGetUserProjects()

  return (contract: Contract) => {
    dispatch(addContract())
    createContract(contract)
      .then((response) => {
        dispatch(addContractSuccess(response.data))
        getContracts(getContractsParams.userId, getContractsParams.projectId, getContractsParams.statusId)
        getProjectsHook()
        getUsers(
          getUsersParams.active,
          getUsersParams.user_id,
          getUsersParams.project_id,
          getUsersParams.status_id,
          getUsersParams.manager_id
        )
        successNotify('Contract created')
      })
      .catch((error) => {
        dispatch(addProjectFailure(error))
        dangerNotify('Contract already exists')
      })
  }
}
