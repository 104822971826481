import React from 'react'
import { PDFViewer } from '@react-pdf/renderer'

import { IResume } from '../../../../../types/Resume/IResume'
import CvDocument from '../CvDocument'
import { useAuth } from '../../../../../context/auth'

interface Props  {
  resume: IResume
}

const PreviewDesktop: React.FC<Props> = ({ resume }) => {
  const { currentUser } = useAuth()
  const height = currentUser ? '85.2vh' : '94vh'

  return (
    <PDFViewer style={{ width: '100%', height: height }} showToolbar={false} >
      <CvDocument resume={resume}/>
    </PDFViewer>
  )
}

export default PreviewDesktop
