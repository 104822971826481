import { createSlice } from '@reduxjs/toolkit'

import { Contract } from '../types'

const initialState = {
  indexData: <Contract[]>[],
  error: {},
  isLoading: true,
}

const availableContracsSlice = createSlice({
  name: 'availableContracts',
  initialState,
  reducers: {
    fetchAvailableContracts(state) {
      state.isLoading = true
    },
    fetchAvailableContractsSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchAvailableContractsFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default availableContracsSlice.reducer
export const { 
  fetchAvailableContracts, 
  fetchAvailableContractsSuccess, 
  fetchAvailableContractsFailure 
} = availableContracsSlice.actions
