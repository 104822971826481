import { deleteSession } from '../../services/API/session'
import { useAuth } from '../../context/auth'
import dangerNotify from '../../helpers/dangerNotify'

export const useDeleteSession = () => {
  const { setCurrentUser, setAuthToken } = useAuth()

  return () => {
    deleteSession()
      .then(() => {
        setCurrentUser()
        setAuthToken()
        localStorage.clear()
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message
        dangerNotify(errorMessage)
      })
  }
}
