export enum DeveloperStatus {
    HIRED = 'hired',
    FIRED = 'fired',
    BUSY_ON_OUTSTAFF = 'busy_on_full_time_outstaff',
    BUSY_ON_OUTSOURCE = 'busy_on_outsource'
}

export enum ItemsOnPage {
    LIMIT = 10
}
