import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { BaseUser } from 'types/BaseUser'
import ReactInput from '../../reusable/ReactInput'
import { userCreateSchema } from '../../../helpers/validationShema'
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import DragNDrop from 'components/reusable/DragNDrop'

interface Props {
  handleSubmit: (data: BaseUser | FormData) => void
  closeModal: () => void
}

const CreateUser: FC<Props> = ({ handleSubmit: createNewUser, closeModal }) => {
  const subRoles = useSelector(({ subRoles: { indexData } }) => indexData)

  const [image, setImage] = useState<string | File | null>(null)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<BaseUser>({
    defaultValues: {
      email: '',
      first_name: '',
      last_name: '',
      role_ids: '',
    },
    resolver: yupResolver(userCreateSchema),
  })

  const onSubmit: SubmitHandler<any> = (data) => {
    if (image) {
      const form = new FormData()

      form.append('avatar', image)
      Object.keys(data).map((key) => {
        if (key === 'role_ids') return
        form.append(key, data[key])
      })
      if (data['role_ids']) form.append('role_ids[]', data['role_ids'])

      createNewUser(form)
    } else {
      data['role_ids'] = [data['role_ids']]
      createNewUser(data)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="modal__content">
      <DragNDrop setImage={setImage} />
      <div className="modal__double-field">
        <ReactInput
          className="w-100"
          type="text"
          size="medium"
          label="Email"
          register={register}
          registerControl="email"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <ReactInput
          className="w-100"
          type="text"
          size="medium"
          label="Password"
          register={register}
          registerControl="password"
          error={!!errors.password}
          helperText={errors.password?.message}
        />
      </div>
      <div className="modal__double-field">
        <ReactInput
          className="w-100"
          type="text"
          size="medium"
          label="Name"
          register={register}
          registerControl="first_name"
          error={!!errors.first_name}
          helperText={errors.first_name?.message}
        />
        <ReactInput
          className="w-100"
          type="text"
          size="medium"
          label="Last Name"
          register={register}
          registerControl="last_name"
          error={!!errors.last_name}
          helperText={errors.last_name?.message}
        />
      </div>

      <FormControl className="modal__input">
        <InputLabel id="create-user-roles">Role</InputLabel>
        <Select
          labelId="create-user-roles"
          value={watch().role_ids}
          onChange={({ target: { value } }) => setValue('role_ids', value)}
          input={<OutlinedInput label="Role" />}
        >
          {subRoles.map(({ id, name }) => (
            <MenuItem key={name + id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div className="modal__double-field">
        <Button className="modal__button modal__cancel" onClick={closeModal} variant="outlined">
          Cancel
        </Button>
        <Button className="modal__button modal__submit" type="submit" variant="contained">
          Create
        </Button>
      </div>
    </form>
  )
}

export default CreateUser
