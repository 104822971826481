import React from 'react'

const PingIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18 8C15.7909 8 14 9.79086 14 12C14 14.2091 15.7909 16 18
      16C20.2091 16 22 14.2091 22 12C22 9.79086 20.2091 8 18 8ZM13.5278 16C12.5777 14.9385 12 13.5367 12 12C12 10.4633
      12.5777 9.06151 13.5278 8H6C3.79086 8 2 9.79086 2 12C2 14.2091 3.79086 16 6 16H13.5278ZM0 12C0 8.68629 2.68629 6 6
      6H18C21.3137 6 24 8.68629 24 12C24 15.3137 21.3137 18 18 18H6C2.68629 18 0 15.3137 0 12Z" fill={color} />
    </svg>
  )
}

export default PingIcon
