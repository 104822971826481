import { useDispatch } from 'react-redux'

import { getProjectInfo } from '../../services/API/admin/projects'
import { getProject, getProjectFailure, getProjectSuccess } from '../../store/projectsSlice'
import dangerNotify from '../../helpers/dangerNotify'

export const useGetProject = () => {
  const dispatch = useDispatch()

  return (project_id: number) => {
    dispatch(getProject())
    getProjectInfo(project_id)
      .then((response) => {
        dispatch(getProjectSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getProjectFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
