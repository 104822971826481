import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { updateCvProject } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'
import { IResumeProject } from '../../../types/Resume/IResumeProject'

export const useUpdateCvProject = () => {
  const getUserCv = useGetUserCv()

  return (resume_project: IResumeProject, projectId: number, cvId: number ) => {
    updateCvProject(resume_project, projectId)
      .then(() => {
        successNotify('Project updated')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Update error'))
  }
}
