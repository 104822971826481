import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { changeCvExperiencesSorting } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'

export const useChangeCvExperiencesSorting = () => {
  const getUserCv = useGetUserCv()

  return (
    prevCvExperienceId: number,
    lastCvExperienceId: number,
    prevExperiencePosition: number,
    lastExperiencePosition: number,
    resumeId: number
  ) => {
    changeCvExperiencesSorting(prevCvExperienceId, lastCvExperienceId, prevExperiencePosition, lastExperiencePosition)
      .then(() => {
        getUserCv(resumeId)
        successNotify('Sorting changed!')
      })
      .catch((error) => dangerNotify(error.response.data.message[0]))
  }
}
