import { webRoutes } from './index'
import { cvNavLinks } from '../constants'

export default {
  HOME: '/',
  ADMIN_MAIN: '/users_list',
  SUBMIT_HOURS: '/create-record',
  SUBMIT_HOURS_ADMIN: '/create-admin-record',
  MONTHLY_REPORT: '/admin-monthly-report',
  ADMIN_MONTHLY_CALENDAR: (userId: number | string) => `/admin-monthly-report/${userId}`,
  USER_MONTHLY_REPORT: '/monthly-report',
  MY_REQUESTS: '/my-requests',
  MY_REPORT: '/my-report',
  REQUEST: '/requests',
  USERS: '/manage-users',
  CATEGORIES: '/manage-categories',
  PROJECTS: '/manage-projects',
  CONTRACTS: '/manage-contracts',
  CUSTOMERS: '/manage-customers',
  SETTINGS: '/settings',
  LOGOUT: '/logout',
  VACANCIES: '/vacancies',
  VACANCY: (vacancyId: string | number) => `/vacancies/${vacancyId}`,
  CANDIDATE: (candidateId: string) => `/candidates/${candidateId}`,
  NOTIFICATIONS: '/recent-notifications',
  DASHBOARD_USERS_LIST: '/users_list',
  DASHBOARD_LEAVES: '/leaves_list',
  DASHBOARD_REQUESTS: '/requests_list',
  DASHBOARD_MANAGERS: '/managers_list',
  RESUMES: '/resumes',
  CV_CONTACT_SECTION: (cvId: string | number) => `${cvId}${cvNavLinks.CONTACT_SECTION}`,
  CV_EXPERIENCE: (cvId: string) => `${webRoutes.RESUMES}/${cvId}${cvNavLinks.EXPERIENCE}`,
  CV_PORTFOLIO: (cvId: string) => `${webRoutes.RESUMES}/${cvId}${cvNavLinks.PORTFOLIO}`,
  CV_SKILL: (cvId: string) => `${webRoutes.RESUMES}/${cvId}${cvNavLinks.SKILLS}`,
  CV_LANGUAGE: (cvId: string) => `${webRoutes.RESUMES}/${cvId}${cvNavLinks.LANGUAGES}`,
  CV_EDUCATION: (cvId: string) => `${webRoutes.RESUMES}/${cvId}${cvNavLinks.EDUCATION}`,
  CV_CERTIFICATE: (cvId: string) => `${webRoutes.RESUMES}/${cvId}${cvNavLinks.CERTIFICATES}`,
  CV_COURSE: (cvId: string) => `${webRoutes.RESUMES}/${cvId}${cvNavLinks.COURSES}`,
  CV_BOOK: (cvId: string) => `${webRoutes.RESUMES}/${cvId}${cvNavLinks.BOOKS}`,
  CV_PREVIEW: (userSlug: string, cvId: string | number) => `/cv/${userSlug}/${cvId}`,
}
