export enum Status {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum NumberStatus {
  PENDING = 0,
  APPROVED = 1,
  REJECTED = 2,
}

export enum Type {
  SICK_LEAVE = 0,
  WORK_OFF = 1,
  UNPAID_LEAVE = 2,
  VACATION = 3,
  PAID_LEAVE = 4,
  DAY_OFF = 5
}

export enum PaginateLimit {
  CURRENT_ITEM = 1,
  REMNANT_NULL = 0,
}

export enum ProjectTypeSelect {
  ALL_PROJECTS = 0,
  CONCRETE_PROJECT = 1,
}

export enum VacancyLimits {
  VACANCY_LIMIT = 10
}

export enum RequestLimits {
  REQUEST_DASHBOARD_LIMIT = 10,
  USER_REQUESTS_LIMIT = 5,
  DASHBOARD_REQUEST_LIMIT = 25,
}

export enum VacationWeeks {
  ONE_WEEK = 7,
  TWO_WEEKS = 14
}
