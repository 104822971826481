import React, { memo } from 'react'
import { Button } from '@mui/material'

import { ArrowBack, EditIcon } from 'icons'
import { useUtility } from 'context/utility'

type Props = {
  userName: string
  onBackPress: () => void
  onEditPress?: () => void
}

export const UserProfileMobileNavbar = memo(function UserProfileMobileNavbar({
  userName,
  onBackPress,
  onEditPress,
}: Props) {
  const { width } = useUtility()

  if (width > 1199.98) return null

  return (
    <nav className="navbar user-profile">
      <div className="navbar-title-wrap">
        <Button onClick={onBackPress}>
          <ArrowBack width="24" height="24" />
        </Button>
        <h3 className="navbar-title">{userName}</h3>
      </div>
      {onEditPress && (
        <Button onClick={onEditPress}>
          <EditIcon width="24" height="24" />
        </Button>
      )}
    </nav>
  )
})
