import React, { memo } from 'react'

import { switchByDate } from 'helpers/tabHeaderRequests'

type Props = {
  active: string
  items?: { [k: string]: string }[]
  changeTab: (v: string) => void
}

export const FilterSwitch = memo(function FilterSwitch({ active, items, changeTab }: Props) {
  const _items = items || switchByDate

  return (
    <div className="filter-switch-container">
      {_items.map((item, index) => {
        const criteria = items ? item.name : item.alignment

        return (
          <div
            onClick={() => changeTab(criteria)}
            key={index}
            className={'filter-switch-item' + (criteria === active ? ' active' : '')}
          >
            {item.name}
          </div>
        )
      })}
    </div>
  )
})
