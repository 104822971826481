import React from 'react'

import { ProjectProfile } from 'types/Manage/Project/ProjectProfile'

const MobileClientInfoProject: React.FC<ProjectProfile> = ({
  projectData,
  customers,
}) => {
  const customer = customers?.find(customer => customer.id === projectData.customer_id)
  
  return (
    <div>
      <div className="project-profile_title">
        <div>
          <div className="project-profile_general-title">Personal info</div>
          <div className="project-profile_general-mobile-subtitle">
              Client: 
            <span className="project-profile_item">
              {!customer?.full_legal_name ? 'None' : customer?.full_legal_name}
            </span>
          </div>
          <div className="project-profile_general-mobile-subtitle">
              Job title:
            <span className="project-profile_item">
              {customer?.title}
            </span>
          </div>
          <div className="project-profile_general-mobile-subtitle">
              Email:
            <span className="project-profile_additional-item-mobile-email">
              {!customer?.email ? '<None>' : customer?.email}
            </span>
          </div>
          <div className="project-profile_general-mobile-subtitle">
              Company:
            <span className="project-profile_item">
              {!customer?.company_name ? '<None>' : customer?.company_name}
            </span>
          </div>
          <div className="project-profile_general-mobile-subtitle dspl-flx">
              Location:
            <span className="project-profile_item">
              {!customer?.company_address ? '<None>' : <span>{customer?.company_address}</span>}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileClientInfoProject
