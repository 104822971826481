import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { passwordValidation } from 'helpers/passwordValidation'
import ReactForm from '../../reusable/ReactForm'
import { Col, Container, Row } from 'reactstrap'
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CustomButton from 'components/custom/CustomButton/CustomButton'

type Inputs = {
  password: string
  submitPassword?: string
  showPassword: boolean
}

const SettingsForm = ({ handleSubmit: changePass }: { handleSubmit: (params: string) => void }) => {

  const [showPassword, setShowPassword] = React.useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { password: '', submitPassword: '', showPassword: false },
    resolver: yupResolver(passwordValidation),
  })

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    changePass(data.password)
  }

  return (
    <Container className="login-form">
      <Row className="login-form__container">
        <Col className="login-form__col">
          <ReactForm onSubmit={handleSubmit(onSubmit)}>

            <div className="login-form__form-group">
              <h4 className="login-form__form-label">New password</h4>
              <FormControl>
                <InputLabel className="login-form__password_label">Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText className="login-form__helper_label">{errors.password?.message}</FormHelperText>
              </FormControl>

              <FormControl>
                <InputLabel className="login-form__password_label">Submit Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('submitPassword')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText className="login-form__helper_label">{errors.submitPassword?.message}</FormHelperText>
              </FormControl>
            </div>
            <div className="login-form__button">
              <CustomButton text="Save" type="submit"/>
            </div>
          </ReactForm>
        </Col>
      </Row>

    </Container>
  )
}

export default SettingsForm
