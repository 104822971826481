import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'

import { Project, User } from '../../../types'
import { MobileManagersList, DesktopManagersList } from '../index'
import { getUsersParams, userRoles } from '../../../constants'
import { useGetUsers } from 'hooks/user/useGetUsers'
import { SubRoleType } from '../../../enums/subRoles'

const ManagersTable = () => {
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const users = useSelector((state: { users: { indexData: User[] } }) => state.users.indexData)
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const managers = users?.filter(user => user.role === userRoles.MANAGER.id && user.sub_roles.includes(SubRoleType.PM))
  const developers = users?.filter(user => user.role === userRoles.DEFAULT.id)
  const getUsers = useGetUsers()

  useEffect(() => {
    getUsers(
      getUsersParams.active,
      getUsersParams.user_id,
      getUsersParams.project_id,
      getUsersParams.status_id,
      getUsersParams.manager_id
    )
  }, [])

  return (
    <>
      {isMobile ?
        <MobileManagersList
          usersNames={usersNames}
          managersContracts={managers}
          developers={developers}
          projects={projects}
        /> :
        <DesktopManagersList
          usersNames={usersNames}
          managersContracts={managers}
          developers={developers}
          projects={projects}
        />
      }
    </>
  )
}

export default ManagersTable
