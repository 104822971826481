import API from '../../index'
import ROUTES from '../../../../routes'
import { IResumeExperience } from '../../../../types/Resume/IResumeExperience'
import { IResumeProject } from '../../../../types/Resume/IResumeProject'
import { IResumeSkill } from '../../../../types/Resume/IResumeSkill'
import { IResumeEducation } from '../../../../types/Resume/IResumeEducation'
import { IResumeLanguage } from '../../../../types/Resume/IResumeLanguage'
import { IResumeCertificate } from '../../../../types/Resume/IResumeCertificate'
import { IResumeCourse } from '../../../../types/Resume/IResumeCourse'

export const fetchUserCvs = (userId: number) => API.get(ROUTES.ADMIN_CV, { params: { user_id: userId } })

export const getUserCv = (id: number) => API.get(ROUTES.ADMIN_CV + `/${id}`)

export const createResume = (resume: FormData) => API.post(ROUTES.ADMIN_CV, resume)

export const duplicateCv = (cvId: number) => API.post(ROUTES.DUPLICATE_CV, { id: cvId })

export const createResumeExperience = (resume_experience: IResumeExperience) =>
  API.post(ROUTES.ADMIN_RESUME_EXPERIENCES, { resume_experience: resume_experience })

export const createResumeProject = (resume_project: IResumeProject) =>
  API.post(ROUTES.ADMIN_RESUME_PROJECTS, { resume_project: resume_project })

export const createResumeSkill = (skill_set: IResumeSkill) =>
  API.post(ROUTES.ADMIN_RESUME_SKILLS, { skill_set: skill_set })

export const createResumeEducation = (education: IResumeEducation) =>
  API.post(ROUTES.ADMIN_RESUME_EDUCATIONS, { education: education })

export const createResumeLanguage = (language: IResumeLanguage) =>
  API.post(ROUTES.ADMIN_RESUME_LANGUAGES, { language: language })

export const createResumeCertificate = (certificate: IResumeCertificate) =>
  API.post(ROUTES.ADMIN_RESUME_CERTIFICATES, { certificate: certificate })

export const createResumeCourse = (course: IResumeCourse) =>
  API.post(ROUTES.ADMIN_RESUME_COURSES, { course: course })

export const createResumeBook = (book: FormData) => API.post(ROUTES.ADMIN_RESUME_BOOKS, book )

export const updateContactsData = (resume: FormData, cvId: number) =>
  API.put(ROUTES.ADMIN_CV + `/${cvId}`, resume)

export const updateCvExperience = (resume_experience: IResumeExperience, companyId: number) =>
  API.put(ROUTES.ADMIN_RESUME_EXPERIENCES + `/${companyId}`, { resume_experience: resume_experience })

export const updateCvProject = (resume_project: IResumeProject, projectId: number) =>
  API.put(ROUTES.ADMIN_RESUME_PROJECTS + `/${projectId}`, { resume_project: resume_project })

export const changeCvProjectsSorting = (
  prevCvProjectId: number,
  lastCvProjectId: number,
  prevProjectPosition: number,
  lastProjectPosition: number
) => API.put(ROUTES.ADMIN_RESUME_PROJECTS + `/${prevCvProjectId}`, {
  resume_projects : {
    [prevCvProjectId]: { position: lastProjectPosition },
    [lastCvProjectId]: { position: prevProjectPosition }
  } })

export const changeCvExperiencesSorting = (
  prevCvExperienceId: number,
  lastCvExperienceId: number,
  prevExperiencePosition: number,
  lastExperiencePosition: number
) => API.put(ROUTES.ADMIN_RESUME_EXPERIENCES + `/${prevCvExperienceId}`, {
  resume_experiences : {
    [prevCvExperienceId]: { position: lastExperiencePosition },
    [lastCvExperienceId]: { position: prevExperiencePosition }
  } })

export const changeCvSkillSetSorting = (
  prevCvSkillSetId: number,
  lastCvSkillSetId: number,
  prevSkillSetPosition: number,
  lastSkillSetPosition: number
) => API.put(ROUTES.ADMIN_RESUME_SKILLS + `/${prevCvSkillSetId}`, {
  resume_skills : {
    [prevCvSkillSetId]: { position: lastSkillSetPosition },
    [lastCvSkillSetId]: { position: prevSkillSetPosition }
  } })

export const changeCvEducationsSorting = (
  prevCvEducationId: number,
  lastCvEducationId: number,
  prevEducationPosition: number,
  lastEducationPosition: number
) => API.put(ROUTES.ADMIN_RESUME_EDUCATIONS + `/${prevCvEducationId}`, {
  resume_educations : {
    [prevCvEducationId]: { position: lastEducationPosition },
    [lastCvEducationId]: { position: prevEducationPosition }
  } })

export const updateCvSkill = (skill_set: IResumeSkill, skillId: number) =>
  API.put(ROUTES.ADMIN_RESUME_SKILLS + `/${skillId}`, { skill_set: skill_set })

export const updateCvLanguage = (language: IResumeLanguage, languageId: number) =>
  API.put(ROUTES.ADMIN_RESUME_LANGUAGES + `/${languageId}`, { language: language })

export const updateCvEducation = (education: IResumeEducation, educationId: number) =>
  API.put(ROUTES.ADMIN_RESUME_EDUCATIONS + `/${educationId}`, { education: education })

export const updateCvCertificate = (certificate: IResumeCertificate, certificateId: number) =>
  API.put(ROUTES.ADMIN_RESUME_CERTIFICATES + `/${certificateId}`, { certificate: certificate })

export const updateCvCourse = (course: IResumeCourse, courseId: number) =>
  API.put(ROUTES.ADMIN_RESUME_COURSES+ `/${courseId}`, { course: course })

export const updateCvBook = (book: FormData, bookId: number) =>
  API.put(ROUTES.ADMIN_RESUME_BOOKS + `/${bookId}`, book)

export const deleteResume = (resumeId: number) => API.delete(ROUTES.ADMIN_CV + `/${resumeId}`)

export const deleteResumeProject = (id: number) => API.delete(ROUTES.ADMIN_RESUME_PROJECTS + `/${id}`)

export const deleteResumeExperience = (id: number) => API.delete(ROUTES.ADMIN_RESUME_EXPERIENCES + `/${id}`)

export const deleteResumeSkillSet = (id: number) => API.delete(ROUTES.ADMIN_RESUME_SKILLS + `/${id}`)

export const deleteResumeEducation = (id: number) => API.delete(ROUTES.ADMIN_RESUME_EDUCATIONS + `/${id}`)

export const deleteResumeLanguage = (id: number) => API.delete(ROUTES.ADMIN_RESUME_LANGUAGES + `/${id}`)

export const deleteResumeCertificate = (id: number) => API.delete(ROUTES.ADMIN_RESUME_CERTIFICATES + `/${id}`)

export const deleteResumeCourse = (id: number) => API.delete(ROUTES.ADMIN_RESUME_COURSES+ `/${id}`)

export const deleteResumeBook = (id: number) => API.delete(ROUTES.ADMIN_RESUME_BOOKS + `/${id}`)
