import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import ReactForm from '../../reusable/ReactForm'
import { getFirstDayOfWeek } from 'helpers/enableCurrentWeek'
import { useAuth } from 'context/auth'
import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import { dateFormatYYMMDD } from 'helpers/displayingDateFormat'
import { webRoutes } from 'lib'
import { minDate } from '../../../constants'
import { isManager } from '../../../helpers/accessHelpers'

interface Props {
  updateDate: (param: Date | null) => void
  date: Date
  setDate: (date: Date | null) => void
  maxDate?: Date | null
}

const DatePicker: React.FC<Props> = ({ updateDate, date, setDate, maxDate }) => {
  const { currentUser } = useAuth()
  const [currentDate] = useState<Date>(new Date())
  const location = useLocation()
  const isRequestPage = location.pathname === webRoutes.REQUEST
  const isAdmin = isManager(currentUser)
  const disableMinusArrow = !isRequestPage ? !isAdmin && date <= new Date(getFirstDayOfWeek(date)) : ''
  const disablePlusArrow = !isRequestPage ? !isAdmin && dateFormatYYMMDD(date) === dateFormatYYMMDD(new Date()) : ''

  const arrowNextItem = () => {
    setDate(new Date(date.setDate(date.getDate() + 1)))
  }

  const arrowBackItem = () => {
    setDate(new Date(date.setDate(date.getDate() - 1)))
  }
  
  const handleChange = (newValue: Date | null) => {
    updateDate(newValue)
    setDate(newValue)
  }

  return (
    <div>
      <ReactForm className="date-picker__form">
        <div className="date-picker__row">
          <div className="date-picker__col">
            <CustomDatepicker
              value={date}
              onChange={handleChange}
              mask={'__.__.____'}
              minDate={minDate(currentUser, location, currentDate)}
              maxDate={maxDate}
              arrowBackItem={arrowBackItem}
              arrowNextItem={arrowNextItem}
              disableMinusArrow={disableMinusArrow}
              disablePlusArrow={disablePlusArrow}
              inputType="day"
              disableClearButton={true}
            />
          </div>
        </div>
      </ReactForm>

    </div>
  )
}

export default React.memo(DatePicker)
