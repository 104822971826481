import React from 'react'
import { Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import { webRoutes } from '../../../../lib'

interface Props {
    resumeId: string | undefined
    nextPath: string
    prevPath: string
    isResumeExist?: boolean
}

const CvNavButtons: React.FC<Props> = ({ resumeId, nextPath, prevPath, isResumeExist }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isCvBooksLocation = location.pathname === webRoutes.CV_BOOK(resumeId as string)

  const onHandleContinue = () => {
    navigate(`${webRoutes.RESUMES}/${resumeId}${nextPath}`)
  }
  const onHandlePreview = () => {
    navigate(webRoutes.CV_PREVIEW(nextPath, resumeId as string))
  }
  const onHandleBack = () => {
    navigate(`${webRoutes.RESUMES}/${resumeId}${prevPath}`)
  }

  return (
    <>
      <Button
        variant="outlined"
        style={{ width: '200px' }}
        onClick={onHandleBack}
      >
        Back
      </Button>
      <Button
        onClick={!isCvBooksLocation ? onHandleContinue : onHandlePreview}
        variant="contained"
        color="primary"
        style={{ width: '200px', marginLeft: '15px' }}
        type="submit"
        disabled={isCvBooksLocation && !isResumeExist}
      >
        {isCvBooksLocation ? 'Preview cv' :  'Continue'}
      </Button>
    </>
  )
}

export default CvNavButtons
