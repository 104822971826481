import { StatusType } from 'enums/Types'
import { userRoles } from'../constants'
import { ICurrentUser, IRequest } from '../types'

export const isManager = (user: ICurrentUser | null) => {
  if(!user) return false
  return user.role === userRoles.MANAGER.id || user.role === userRoles.ADMIN.id
}

export const isDefault = (user: ICurrentUser | null) => {
  if(!user) return false
  return user.role === userRoles.DEFAULT.id
}

export const isAdmin = (user: ICurrentUser | null) => {
  if(!user) return false
  return user.role === userRoles.ADMIN.id
}

export const requestType = (request: IRequest) => {
  if(!request.request_type) return false
  return request.request_type === StatusType.FULL_DAY_SICK_LEAVE ? 'Full day sick leave' :
    request.request_type === StatusType.HALF_DAY_SICK_LEAVE ? 'Half day sick leave' :
      request.request_type === StatusType.EXTRA_SICK_LEAVE ? 'Extra sick leave' :
        request.request_type === StatusType.VACATION ? 'Vacation' :
          request.request_type === StatusType.UNPAID_LEAVE ? 'Unpaid leave' :
            request.request_type === StatusType.WORK_OFF ? 'Work Off' :
              request.request_type === StatusType.PAID_LEAVE ? 'Paid leave' :
                request.request_type === StatusType.HALF_DAY_UNPAID_LEAVE ? 'Half day unpaid leave' :
                  request.request_type === StatusType.DAY_OFF ? 'Day off' : ''
}
