import * as React from 'react'

interface Props {
  text?: string
  type?: 'button' | 'submit' | 'reset'
  variant?: 'contained' | 'outlined'
  size?: 'sm' | 'lg'
  onClick?: () => void
  disabled?: boolean
  style?: React.CSSProperties
  prefixIcon?: JSX.Element
}

const CustomButton: React.FC<Props> = ({ text, type, variant, onClick, disabled, style, prefixIcon }) => {
  return (
    <button
      className={variant === 'outlined' ? 'outlined-custom-button' : 'custom-button'}
      style={{ ...style }}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <span className="prefix-icon">{prefixIcon}</span>{text}
    </button>
  )
}

export default CustomButton
