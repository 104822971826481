import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { createResumeBook } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'

export const useCreateResumeBook = () => {
  const getUserCv = useGetUserCv()

  return (book: FormData, cvId: number) => {
    createResumeBook(book)
      .then(() => {
        successNotify('Book added')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Please fill in the contact section'))
  }
}
