import React from 'react'
import { useLocation } from 'react-router-dom'

import { webRoutes } from 'lib'

const Total = ({ total }: { total: number }) => {
  const location = useLocation()
  const isCreateRecordPage = location.pathname === webRoutes.SUBMIT_HOURS_ADMIN || webRoutes.SUBMIT_HOURS

  return (
    <div className={!isCreateRecordPage ? 'total__row' : 'total__row_create_page'}>
      <div className="total__col">
        <h6 className="totalText">Total hours: {total.toFixed(1)}</h6>
      </div>
    </div>
  )
}

export default Total
