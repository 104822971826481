import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { useCreateResumeCertificate } from '../../../../../hooks/resume/certificates/useCreateResumeCertificate'
import { IResumeCertificate } from '../../../../../types/Resume/IResumeCertificate'
import CvCertificateForm from './CvCertificateForm'
import AddFormItemButton from '../FormButtons/AddFormItemButton'

export interface ICertificateInputs {
    name: string
    url: string
}

interface IProps {
  cvId: number
}

const CvAddCertificate: React.FC<IProps> = ({ cvId }) => {
  const createResumeCertificate = useCreateResumeCertificate()

  const { register, handleSubmit, reset } = useForm<ICertificateInputs>({
    defaultValues: {
      name: '',
      url: '',
    }
  })

  const onSubmit: SubmitHandler<ICertificateInputs> = (data) => {
    const certificate = {
      name: data.name,
      url: data.url,
      resume_id: cvId
    }

    createResumeCertificate(certificate as IResumeCertificate, cvId)
    reset()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvCertificateForm register={register} />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <AddFormItemButton isDisabled={false}/>
      </Box>
    </form>
  )
}

export default CvAddCertificate
