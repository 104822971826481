import React, { memo, useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { BsArrowCounterclockwise, BsCheckLg } from 'react-icons/bs'

type Props = {
  note: string
  inputName: string
  onSave: (newNote: { [x: string]: string }) => void
}

const CandidateNote = ({ note = '', onSave, inputName }: Props) => {
  const [newNote, setNewNote] = useState('')

  useEffect(() => {
    if (note) setNewNote(note)
  }, [note])

  const handleSubmit = () => {
    onSave({ [inputName]: newNote })
  }

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setNewNote(value)
  }

  return (
    <tr className="note-body">
      <td className="note-body-text">
        <Input name={inputName} onChange={handleChange} type="textarea" rows={6} value={newNote} />
      </td>
      <td className="note-body-button">
        <button className="btn" onClick={handleSubmit} disabled={note === newNote}>
          <BsCheckLg />
        </button>
        <button className="btn" disabled={note === newNote} onClick={() => setNewNote(note)}>
          <BsArrowCounterclockwise />
        </button>
      </td>
    </tr>
  )
}

export default memo(CandidateNote)
