import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { useUpdateCvBook } from '../../../../../hooks/resume/books/useUpdateCvBook'
import { IResumeBook } from '../../../../../types/Resume/IResumeBook'
import CvBookForm from './CvBookForm'
import { IBookInputs } from './CvAddBook'
import EditFormButtons from '../FormButtons/EditFormButtons'

interface Props {
  setOpen: (value: boolean) => void
  book: IResumeBook
  cvId: string | undefined
}

const CvUpdateBook: React.FC<Props> = ({ setOpen, book, cvId }) => {
  const [image, setImage] = useState()
  const updateBook = useUpdateCvBook()

  const { register, handleSubmit, reset } = useForm<IBookInputs>({
    defaultValues: {
      title: book ? book.title : '',
      author: book ? book.author : ''
    }
  })

  const onSubmit: SubmitHandler<IBookInputs> = (data, event) => {
    event?.preventDefault()
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('author', data.author)
    formData.append('resume_id', cvId as string)
    image && formData.append('image', image)

    updateBook(formData, book.id as number, Number(cvId))
    setOpen(false)
    reset()
  }

  const onImageChange = (event: { target: { files: React.SetStateAction<undefined>[] } }) => {
    setImage(event.target.files[0])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvBookForm register={register} onImageChange={onImageChange} />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <EditFormButtons setOpen={setOpen} />
      </Box>
    </form>
  )
}

export default CvUpdateBook
