import { Buffer } from 'buffer'
import { defaultAvatar } from 'images'
import { dateFormatMMYY } from 'helpers/displayingDateFormat'
import { createReport } from '../../lib/docGenerator.js'

export const useDocCv = ({ resume, filename = 'CV' }: any) => {
  const buildJson = () => {
    if (!resume) return {}

    const json = {
      ...resume,
      address: resume.location,
      experiences: resume.experiences.map((experience: any) => ({
        ...experience,
        job_started_on: dateFormatMMYY(experience.job_started_on),
        job_ended_on: experience.job_ended_on ? dateFormatMMYY(experience.job_ended_on) : 'Ongoing',
        responsibilities: experience.responsibilities.map(
          (item: any, index: any) => `${item.trim()}${index < experience.responsibilities.length - 1 ? ', ' : ''}`,
        ).join(''),
      })),
      educations: resume.educations.map((education: any) => ({
        ...education,
        start_date: dateFormatMMYY(education.start_date),
        end_date: dateFormatMMYY(education.end_date),
      })),
      resume_projects: resume.resume_projects.map((project: any) => ({
        ...project,
        stack: project.stack.map(
          (item: any, index: any) => `${item.trim()}${index < project.stack.length - 1 ? ', ' : ''}`,
        ).join(''),
      })),
      courses: resume.courses.map((course: any) => ({
        ...course,
        acquire_date: dateFormatMMYY(course.acquire_date),
        stack: course.stack.map(
          (item: any, index: any) => `${item.trim()}${index < course.stack.length - 1 ? ', ' : ''}`,
        ).join(''),
      })),
      skills: resume.skills.map((skill: any) => ({
        ...skill,
        skills: skill.skills.map(
          (item: any, index: any) => `${item.trim()}${index < skill.skills.length - 1 ? ', ' : ''}`,
        ).join(''),
      })),
    }
    return json
  }

  const prepareImage = async (image: any, width = 5, height = 5) => {
    if (!image) {
      const data = defaultAvatar.slice('data:image/png;base64,'.length)
      return { width, height, data, extension: '.png' }
    } else {
      const fetchImage = await fetch(image)
      const data = Buffer.from(await fetchImage.arrayBuffer())
      return { width, height, data, extension: '.png' }
    }
  }

  const saveDataToFile = (data: any) => {
    const link = document.createElement('a')
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
    const url = window.URL.createObjectURL(blob)
    link.href = url
    link.download = `${filename}.docx`
    document.body.appendChild(link)
    link.click()
    link?.parentNode?.removeChild(link)
  }

  const downloadDocCv = async () => {
    const template = await fetch(`${process.env.REACT_APP_BASE_URL}/template.docx`).then((res) => res.arrayBuffer())
    const data = buildJson()
    const report = await createReport({
      template,
      data,
      additionalJsContext: {
        injectAvatar: async (image: any) => {
          return prepareImage(image, 11, 11)
        },
        injectBookImage: async (image: any) => {
          return prepareImage(image, 2.55, 2.55)
        },
      },
    })
    saveDataToFile(report)
  }

  return { downloadDocCv }
}
