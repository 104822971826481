import { createSlice } from '@reduxjs/toolkit'

import { User } from '../types'

const initialState = {
  indexData: <User[]>[],
  indexNamesData: <User[]>[],
  dashboardUsersData: <User[]>[],
  totalPages: 1,
  showData: <User>{ active: true },
  error: {},
  isLoading: true,
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsers(state) {
      state.isLoading = true
    },
    fetchUsersSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchUsersFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    fetchUsersNames(state) {
      state.isLoading = true
    },
    fetchUsersNamesSuccess(state, action) {
      state.indexNamesData = action.payload
      state.isLoading = false
    },
    fetchUsersNamesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    fetchDashboardUsers(state) {
      state.isLoading = true
    },
    fetchDashboardUsersSuccess(state, action) {
      state.dashboardUsersData = action.payload.users
      state.totalPages = action.payload.total_pages
      state.isLoading = false
    },
    fetchDashboardUsersFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    getUser(state) {
      state.isLoading = true
    },
    getUserSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    getUserFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    editUser(state) {
      state.isLoading = true
    },
    editUserSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    editUserFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default usersSlice.reducer
export const {
  fetchUsers,
  fetchUsersFailure,
  fetchUsersSuccess,
  fetchUsersNames,
  fetchUsersNamesFailure,
  fetchUsersNamesSuccess,
  getUser,
  getUserFailure,
  getUserSuccess,
  editUser,
  editUserFailure,
  editUserSuccess,
  fetchDashboardUsers,
  fetchDashboardUsersSuccess,
  fetchDashboardUsersFailure,
} = usersSlice.actions
