import React from 'react'

import { IResume } from '../../../../../types/Resume/IResume'
import './mobileStyles.scss'
import { dateFormatMMYY } from '../../../../../helpers/displayingDateFormat'

interface Props {
    resume: IResume
}

const MobileCoursesTab: React.FC<Props> = ({ resume }) => {
  return (
    <div className="mobile_preview">
      {resume?.courses?.length > 0 ?
        <>
          {resume?.courses?.map(course => (
            <div key={course.id} className="course_item">
              <span className="course_name">
                {course.name}
              </span>
              <span className="course_license">
                License: {course.license}
              </span>
              <span className="course_issued">
                Issued: {dateFormatMMYY(course.acquire_date)}
              </span>
              <span className="course_stack">
                Tech stack: {course.stack.map((item, index) =>
                  `${item.trim()}${index < course.stack.length - 1 ? ', ' : ''}`)}
              </span>
            </div>
          ))}
        </> : <span className="no_courses">This user has no completed courses</span>
      }
    </div>
  )
}

export default MobileCoursesTab
