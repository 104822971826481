import React from 'react'

import { ProjectProfile } from 'types/Manage/Project/ProjectProfile'
import AdditionalProject from './additionalProject/additionalProject'
import DesktopGeneralProject from './generalProject/desktopGeneralProject'

const DesktopProjectProfile: React.FC<ProjectProfile> = ({
  project,
  projects,
  usersNames,
  allCustomers,
  allProjectTechnologies,
  projectTechnologies,
  technologies,
  status,
  setPage,
  page,
  totalPages,
  openProject,
  toggleProject,
  setProjectData,
  setOpenProject,
}) => {
  return (
    <div className="container project-profile_wrap">
      <DesktopGeneralProject 
        project={project}
        projects={projects}
        usersNames={usersNames}
        allCustomers={allCustomers}
        allProjectTechnologies={allProjectTechnologies}
        projectTechnologies={projectTechnologies}
        technologies={technologies}
        openProject={openProject} 
        toggleProject={toggleProject}
        setProjectData={setProjectData}
        setOpenProject={setOpenProject}
        setPage={setPage}
      />
      <div className="project-profile_additional-project-wrap">
        <AdditionalProject
          customers={allCustomers}
          projectData={project}
          usersNames={usersNames}
          status={status}
          setPage={setPage}
          page={page}
          totalPages={totalPages}
        />
      </div>
    </div>
  )
}

export default DesktopProjectProfile
