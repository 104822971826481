import { useDispatch } from 'react-redux'

import { getAllContracts, getAvailableContracts, getUserAvailableContracts } from '../../services/API/admin/contracts'
import { fetchContracts, fetchContractsSuccess, fetchContractsFailure } from '../../store/contractsSlice'
import dangerNotify from '../../helpers/dangerNotify'
import { fetchAvailableContracts, 
  fetchAvailableContractsFailure, 
  fetchAvailableContractsSuccess } from 'store/availableContractsSlice'

export const useGetContracts = () => {
  const dispatch = useDispatch()

  return (userId: number | undefined, projectId: number | undefined, statusId: number | undefined) => {
    dispatch(fetchContracts())
    getAllContracts(userId, projectId, statusId)
      .then((response) => {
        dispatch(fetchContractsSuccess(response?.data))
      })
      .catch((error) => {
        dispatch(fetchContractsFailure(error))
        dangerNotify('Server error: unable to download Contracts')
      })
  }
}

export const useGetUserAvailableContracts = () => {
  const dispatch = useDispatch()

  return (userId: number | undefined) => {
    dispatch(fetchAvailableContracts())
    getUserAvailableContracts(userId)
      .then((response) => {
        dispatch(fetchAvailableContractsSuccess(response?.data))
      })
      .catch((error) => {
        dispatch(fetchAvailableContractsFailure(error))
        dangerNotify('Server error: unable to download Available contracts')
      })
  }
}

export const useGetAvailableContracts = () => {
  const dispatch = useDispatch()

  return () => {
    dispatch(fetchAvailableContracts())
    getAvailableContracts()
      .then((response) => {
        dispatch(fetchAvailableContractsSuccess(response?.data))
      })
      .catch((error) => {
        dispatch(fetchAvailableContractsFailure(error))
        dangerNotify('Server error: unable to download Available contracts')
      })
  }
}
