import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import {  getInventories } from '../../services/API/admin/inventories'
import { fetchInventories, fetchInventoriesFailure, fetchInventoriesSuccess } from '../../store/inventoriesSlice'

export const useGetInventories = () => {
  const dispatch = useDispatch()

  return (profile_id: number) => {
    dispatch(fetchInventories())
    getInventories(profile_id)
      .then((response) => {
        dispatch(fetchInventoriesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchInventoriesFailure(error))
        dangerNotify('Server error: unable to download inventories')
      })
  }
}
