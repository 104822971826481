import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { AxiosRequestConfig } from 'axios'
import { Data } from 'react-csv/components/CommonPropTypes'

import { CSVLink } from 'react-csv'
import { MonthlyReport } from 'types/MonthlyReport'
import { renderProject, renderUser } from 'helpers/renderName'
import { User } from 'types/User'
import DownloadCSVIcon from 'icons/DownloadCSVIcon'
import { getMonthlyReport } from 'services/API/admin/monthly-report'
import { CSV_LIMIT } from '../../../../constants'
import dangerNotify from 'helpers/dangerNotify'
import CustomButton from 'components/custom/CustomButton/CustomButton'
import { Project } from 'types'
import { Pages } from 'enums/StartPage'

interface Props {
  filename: string
  paramsType: (limit: number | undefined, page: number | undefined) => AxiosRequestConfig
}

const ExportCSV: React.FC<Props> = ({ filename, paramsType }) => {
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const [csvRecords, setCsvRecords] = useState<MonthlyReport[]>()
  const [initiateDownload, setInitiateDownload] = useState(false)
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)

  const headersWithStatus = [
    { label: 'User', key: 'userName' },
    { label: 'Project', key: 'projectName' },
    { label: 'Job Status', key: 'job_status' },
    { label: 'Hours', key: 'hours' },
  ]

  useEffect(() => {
    if (csvRecords?.length) {
      setInitiateDownload(true)
    }
  }, [csvRecords])

  useEffect(() => {
    if (initiateDownload) {
      csvLinkRef?.current?.link.click()
      setInitiateDownload(false)
    }
  }, [initiateDownload])

  const csvDownloadClick = async () => {
    await getMonthlyReport(paramsType(CSV_LIMIT, Pages.START_PAGE))
      .then((response) => {
        const filtered = response.data.paginate_report.filter(( report: MonthlyReport ) => report.hours !== 0)
          .map(({ user, project, job_status, hours }: MonthlyReport) => {
            const projectName = renderProject(project as number, projects)
            const userName = renderUser(user as number, usersNames)
            return { userName, projectName, job_status, hours }
          })
        setCsvRecords(filtered)
      })
      .catch((error) => {
        dangerNotify(error.message)
      })
  }

  return (
    <>
      <CustomButton
        text={initiateDownload ? 'Loading...' : 'Download CSV'}
        prefixIcon={<DownloadCSVIcon/>} 
        onClick={csvDownloadClick}/>
      {initiateDownload &&
        <CSVLink
          ref={csvLinkRef}
          filename={filename}
          target="_blank"
          data={csvRecords as Data}
          headers={headersWithStatus}
        />
      }
    </>
  )
}

export default ExportCSV
