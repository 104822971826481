import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Tab, Tabs } from '@mui/material'

import { UserProfileMobileNavbar } from './components/navbar'
import { useGetUsersProfile } from 'hooks/profile/useGetProfile'
import IOSSwitch from 'components/reusable/IOSSwitch'
import {
  UserProfilePersonalInfo,
  UserProfileContractLog,
  UserProfileLeaves,
  UserProfileGeneral,
  UserProfileRequests,
  UserProfileInventory,
  UserProfileCVList,
} from './tabs'
import { useUtility } from 'context/utility'
import { useGetUser } from 'hooks/user/useGetUser'
import { useGetTechnologies } from 'hooks/Technology/useGetTecnologies'
import { UserProfileGeneralForm } from './forms/general'
import Modal from 'components/reusable/Modal'
import { useUpdateUser } from 'hooks/user/useUpdateUser'
import { User } from 'types'
import Loader from 'components/reusable/Loader'

const tabNames = ['general', 'personal', 'contract log', 'leaves', 'requests', 'inventory', 'CV list']
const tabs = {
  general: <UserProfileGeneral />,
  personal: <UserProfilePersonalInfo />,
  'contract log': <UserProfileContractLog />,
  leaves: <UserProfileLeaves />,
  requests: <UserProfileRequests />,
  inventory: <UserProfileInventory />,
  'CV list': <UserProfileCVList />,
}

export const UserProfileContainer = () => {
  const userId = Number(useParams().userId)
  const { width } = useUtility()
  const navigate = useNavigate()

  const getUser = useGetUser()
  const getUsersProfile = useGetUsersProfile()
  const getTechnologies = useGetTechnologies()
  const updateUser = useUpdateUser()

  const [user, userLoading] = useSelector(({ users: { showData, isLoading } }): [User, boolean] => [
    showData,
    isLoading,
  ])
  const profileLoading = useSelector(({ profile: { isLoading } }) => isLoading)

  const [index, setIndex] = useState(width < 1199.98 ? 'general' : 'personal')
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => setModalOpen((ps) => !ps)

  useEffect(() => {
    getUser(userId)
    getUsersProfile(userId)
    getTechnologies()
  }, [userId])

  useEffect(() => {
    if (width > 1199.98 && index === 'general') setIndex('personal')
  }, [width])

  const handleStatusChange = () => {
    updateUser(user.id, { active: !user.active })
  }

  const isLoading = profileLoading || userLoading

  return (
    <>
      <UserProfileMobileNavbar
        userName={user.full_name}
        onEditPress={index === 'general' ? toggleModal : undefined}
        onBackPress={() => navigate(-1)}
      />

      {width > 1199.98 && isLoading ? (
        <Loader />
      ) : (
        <div className="user-profile container-lg">
          {width > 1199.98 && <UserProfileGeneral onEditClick={toggleModal} />}

          <div className="user-profile__tabs-wrapper">
            <div className="user-profile__active-switch d-none d-xl-flex">
              <IOSSwitch checked={!!user.active} label="Active status" onChange={handleStatusChange} />
            </div>

            <div className="user-profile__tabs-content">
              <Tabs value={index} onChange={(_, index) => setIndex(index)}>
                {tabNames.map((t) => (
                  <Tab key={t} label={t} value={t} className={t === 'general' ? 'd-xl-none' : ''} />
                ))}
              </Tabs>
              {tabs[index as keyof typeof tabs]}
            </div>
          </div>
        </div>
      )}

      <Modal
        title="General Info"
        component={<UserProfileGeneralForm onCancel={toggleModal} />}
        open={modalOpen}
        handleClose={toggleModal}
      />
    </>
  )
}
