import React from 'react'
import { useNavigate } from 'react-router-dom'

import SettingsForm from 'components/utils/SettingsForm'
import { updateUserPassword } from 'services/API/users'
import dangerNotify from 'helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'

const SettingsContainer = () => {
  const navigate = useNavigate()

  const handleSubmit = (params: string) => {
    updateUserPassword(params)
      .then(() => {
        successNotify('Password updated')
        navigate('/')
      })
      .catch((error) => {
        dangerNotify(error)
      })
  }

  return <SettingsForm handleSubmit={handleSubmit} />
}

export default React.memo(SettingsContainer)
