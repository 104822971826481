import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { MIN_INPUTS_ELEMENTS } from '../../../../../constants'
import { useCreateResumeCourse } from '../../../../../hooks/resume/Course/useCreateResumeCourse'
import { IResumeCourse } from '../../../../../types/Resume/IResumeCourse'
import CvCourseForm from './CvCourseForm'
import AddFormItemButton from '../FormButtons/AddFormItemButton'

interface Inputs {
    name: string
    license: string
    acquire_date: Date
}

interface IProps {
    cvId: number
}

const CvAddCourse: React.FC<IProps> = ({ cvId }) => {
  const [inputFields, setInputFields] = useState([{ value: '' }])
  const isDisabled = inputFields.length <= MIN_INPUTS_ELEMENTS
  const createResumeCourse= useCreateResumeCourse()

  const { register, handleSubmit, control, reset } = useForm<Inputs>(
    {
      defaultValues: {
        name: '',
        license: '',
        acquire_date: new Date(),
      }
    })

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const courseStack = inputFields.map(({ value }) => value)
    const course = {
      name: data.name,
      license: data.license,
      acquire_date: data.acquire_date,
      stack: courseStack,
      resume_id: cvId
    }

    createResumeCourse(course as IResumeCourse, cvId)
    reset()
    setInputFields([{ value: '' }])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvCourseForm
        register={register}
        setInputFields={setInputFields}
        control={control}
        inputFields={inputFields}
        isDisabled={isDisabled}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <AddFormItemButton isDisabled={false}/>
      </Box>
    </form>
  )
}

export default CvAddCourse
