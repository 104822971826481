import { webRoutes } from '../lib'

export const disableBreadcrumbs = (path: string) => {
  return (
    path === webRoutes.DASHBOARD_USERS_LIST ||
        path === webRoutes.DASHBOARD_REQUESTS ||
        path === webRoutes.DASHBOARD_MANAGERS ||
        path === webRoutes.DASHBOARD_LEAVES ||
        path.match(/^(\/cv\/.*)/) ||
        path.match(/^(\/resumes\/.*)/)
  )
}
