import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import { User, UserProfile } from '../../../types'
import { tableHeadStyles } from '../../../utilts/styles'
import { AvailableDevsTableRow, FiredOrHiredDevsTableRow } from '../../../views/dashboard'
import { ProfileTechnology } from '../../../types/Profile/ProfileTechnology'
import CustomPagination from '../../reusable/Pagination/Pagination'

type Props = {
    tableTitles: string[]
    profiles?: UserProfile[]
    isFired: boolean
    isHired: boolean
    totalPages: number
    page: number
    setPage: (value: number) => void
    users?: User[]
    profileTechnologies?: ProfileTechnology[]
    subRole?: string
}

const DashboardUsersTable: React.FC<Props> = ({
  tableTitles,
  profiles,
  isHired,
  isFired,
  totalPages,
  page,
  setPage,
  users,
  profileTechnologies,
  subRole
}) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead className="requests-table__title">
            <TableRow>
              {tableTitles.map((title, index) => (
                <TableCell key={index} align="left" sx={tableHeadStyles}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFired || isHired ?
              <FiredOrHiredDevsTableRow profiles={profiles as UserProfile[]} isHired={isHired} /> :
              <AvailableDevsTableRow
                users={users}
                page={page}
                profileTechnologies={profileTechnologies}
                subRole={subRole}
              />
            }
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination totalPages={totalPages} page={page} setPage={setPage} />
    </>
  )
}

export default DashboardUsersTable
