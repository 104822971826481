import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container, Button } from 'reactstrap'

import { Vacancy } from '../../types'
import { useFetchVacancies } from '../../hooks/vacancies/useFetchVacancies'
import { VacanciesTable, VacancyForm } from '../../components/vacancies'
import { PlusIcon } from '../../icons'
import { Pages } from 'enums/StartPage'

const VacancyIndex = () => {
  const [isFormOpen, setFormOpen] = useState(false)
  const vacancies = useSelector((state: { vacancies: { indexData: Vacancy[] } }) => state.vacancies.indexData)
  const [page, setPage] = React.useState(Pages.START_PAGE)

  const fetchVacancies = useFetchVacancies()
  useEffect(() => {
    fetchVacancies(page)
  }, [page])

  const toggleForm = () => setFormOpen(!isFormOpen)

  return (
    <Container fluid="xxl">
      <div className="page-top">
        <div>{/* Vacancies filter */}</div>
        <Button size={'lg'} onClick={toggleForm}>
          Add New Vacancy
          <div style={{ display: 'inline-block', marginLeft: '10px' }}>
            <PlusIcon />
          </div>
        </Button>
      </div>
      <VacanciesTable vacancies={vacancies} page={page} setPage={setPage} />
      <VacancyForm
        page={page}
        isNewRecord
        toggle={toggleForm}
        isOpen={isFormOpen} />
    </Container>
  )
}

export default VacancyIndex
