import React, { useState, Dispatch, SetStateAction } from 'react'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { AiOutlineDelete, FiEdit2, RiDeleteBin2Line } from 'react-icons/all'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Link } from 'react-router-dom'

import { tableCellStyles, tableHeadStyles } from 'utilts/styles'
import { Customer, Project } from '../../../types'
import { EditCustomer } from '../EditCustomer/EditCustomer'
import Modal from '../../reusable/Modal'
import { customerListRows } from '../../../constants'
import Pagination from '../../reusable/Pagination/Pagination'
import { BENCH_PROJECT } from '../../../constants'

interface Props {
  deleteCustomer: (id: number | undefined) => void
  customers: Customer[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  setEdited: (edited: boolean) => void
  projects: Project[]
  edited: boolean
}

const CustomersList: React.FC<Props> = ({ deleteCustomer,
  page, customers, setPage, setEdited, projects }) => {
  const customersPages = useSelector((state: { customers: { pages: number } }) => state.customers.pages)

  const [open, setOpen] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null)
  const perPage = 10

  const handleEditCustomer = (customer: Customer) => {
    setOpen(!open)
    setSelectedCustomer(customer)
  }

  const editCustomer = <EditCustomer selectedCustomer={selectedCustomer}
    setOpen={setOpen} customers={customers} setEdited={setEdited} page={page} />

  const handleDeleteCustomer = (id: number | undefined ) => {
    deleteCustomer(id)
    setEdited(true)
  }

  return (
    <>
      {isMobile && open && <>
        <EditCustomer selectedCustomer={selectedCustomer} setOpen={setOpen} customers={customers} />
      </>}
      {!isMobile &&
      <div className="manage-customers__wrapper">
        <TableContainer style={{ display: 'flex' }}>
          <Table sx={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
                <TableCell scope="col" align="left" style={{ width: 100 }} sx={tableHeadStyles}>
                  #
                </TableCell>
                {customerListRows.map((row, index) => (
                  <TableCell scope="col" align="left" sx={tableHeadStyles} key={index}>
                    {row}
                  </TableCell>
                )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer: Customer, numbering: number) => {
                const customerProjects = projects?.filter(project => project.customer_id === customer.id)
                const isDeletable = customerProjects.every(project => {
                  if (project.has_records || project.name === BENCH_PROJECT) {
                    return false
                  }
                  return true
                })

                return <TableRow key={customer.id}>
                  <TableCell align="left" sx={tableCellStyles}>
                    {customersPages == 1 ? <> {numbering + 1}</>
                      : <>{(page - 1) * perPage + numbering + 1}</> }
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {customer.company_name}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {customerProjects.map(project => (
                      <div key={project.id} className="manage-customers__culomn">
                        <Link to={`/projects/${project.id}`}>{project.name}</Link>
                      </div>
                    ))}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    <strong>{customer.full_legal_name}</strong>
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {customer.title}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {customer.email}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {customer.company_address}
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: '100px' }}>
                    <div className="manage-customers__row-icons">
                      {isDeletable ?
                        <AiOutlineDelete
                          color="#8992A6"
                          size="20"
                          cursor="pointer"
                          onClick={() => handleDeleteCustomer(customer.id)} />
                        :
                        <RiDeleteBin2Line
                          color="#8992A6"
                          size="20"
                          cursor="pointer" />
                      }
                      <div className="manage-customers__edit-icon">
                        <FiEdit2
                          color="#8992A6"
                          size="20"
                          cursor="pointer"
                          onClick={() => handleEditCustomer(customer)} />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination totalPages={customersPages} page={page} setPage={setPage} />
        {open &&
          <Modal title="Edit Customer" component={editCustomer} open={open} handleClose={() => setOpen(false)} />
        }
      </div>}

      {isMobile &&
      <>
        {customers?.map((customer: Customer) => {
          const customerProjects = projects?.filter(project => project.customer_id === customer.id)
          let deletable = 0
          customerProjects.every(project => {
            if (project.has_records || project.name == BENCH_PROJECT) {
              deletable += 1
            }
          })

          return (
            <div key={customer.id} className="manage-customers__row">
              <Card sx={{ width: 335, marginTop: '1em' }}>
                <CardHeader
                  action={
                    <div className="dropdown">
                      <button className="dropbtn"><MoreVertIcon/></button>
                      <div className="dropdown-content">
                        {deletable == 0 &&
                          <div onClick={() => deleteCustomer(customer.id)} className="manage-customers__dropdow-row">
                            <AiOutlineDelete
                              size="20"
                              cursor="pointer"
                            /> Delete
                          </div>
                        }
                        <div onClick={() => handleEditCustomer(customer)} className="manage-customers__dropdow-row">
                          <FiEdit2
                            size="20"
                            cursor="pointer"
                          /> Edit
                        </div>
                      </div>
                    </div>
                  }
                  title={customer.company_name}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    <div className="manage-customers__row-name">
                      Projects:&nbsp;
                      {customerProjects.map(project => (
                        <div key={project.id}>
                          <Link to={`/projects/${project.id}`}>{project.name}</Link>&nbsp;
                        </div>
                      ))}
                    </div>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Representative: <strong>{customer.full_legal_name}</strong>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Title: {customer.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Email: {customer.email}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Address: {customer.company_address}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          )
        })}
        <Pagination totalPages={customersPages} page={page} setPage={setPage} />
      </>
      }
    </>
  )
}

export default CustomersList
