import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import { useGetUser } from './useGetUser'
import { updateUser } from '../../services/API/admin/users'
import { editUser, editUserFailure, editUserSuccess } from '../../store/usersSlice'
import { useGetUsersProfile } from 'hooks/profile/useGetProfile'
import successNotify from 'helpers/successNotify'

export const useUpdateUser = () => {
  const getUser = useGetUser()
  const getUsersProfile = useGetUsersProfile()
  const dispatch = useDispatch()

  return (userId: number, data: FormData | { active: boolean }) => {
    dispatch(editUser())
    updateUser(userId, data)
      .then((response) => {
        dispatch(editUserSuccess(response.data))
        getUser(userId)
        getUsersProfile(userId)
        successNotify('User updated successfully')
      })
      .catch((error) => {
        dispatch(editUserFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
