import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Login from 'containers/LoginContainer'
import Logout from 'containers/LogoutContainer'
import Settings from 'containers/SettingsContainer'
import ReportMonthly from 'containers/MonthlyReportContainer'
import MyReport from 'containers/UserReportContainer'
import ManageUsers from '../../admin/ManageUsers'
import ManageCategories from '../../admin/ManageCategories'
import ManageProjects from '../../admin/ManageProjects'
import ManageContracts from '../../admin/ManageContracts'
import ManageCustomers from '../../admin/ManageCustomers'
import MonthlyCalendarReport from '../../reusable/MonthlyCalendarReport/MonthlyCalendarReport'
import NotFound from '../NotFound'
import RequestsContainer from 'containers/RequestsContainer/RequestsContainer'
import CvContactsData from '../../admin/UserPage/Resume/ContactsData/CvContactsData'
import CvExperienceCompanies from '../../admin/UserPage/Resume/Experience/CvExperienceCompanies'
import CvEducations from '../../admin/UserPage/Resume/Education/CvEducations'
import CvPortfolioProjects from '../../admin/UserPage/Resume/Portfolio/CvPortfolioProjects'
import CvSkillSets from '../../admin/UserPage/Resume/Skills/CvSkillSets'
import CvLanguages from '../../admin/UserPage/Resume/Languages/CvLanguages'
import CvBooks from '../../admin/UserPage/Resume/Books/CvBooks'
import CvPreview from '../../admin/UserPage/Resume/Preview/CvPreview'
import CvCertificates from '../../admin/UserPage/Resume/Certificates/CvCertificates'
import CvCourses from '../../admin/UserPage/Resume/Courses/CvCourses'
import { VacancyIndex, VacancyShow } from 'views/vacancies'
import { CandidateShow } from 'views/candidates'
import { webRoutes } from 'lib'
import ProtectedRoute from './ProtectedRoute'
import Unauthorized from '../Unauthorized/Unauthorized'
import { userRoles } from '../../../constants'
import MainContainerRoute from './MainContainerRoute'
import MobileNotifications from '../../notifications/mobile/MobileNotifications'
import { DashboardUsersList, ManagersTable } from '../../../views/dashboard'
import CreateRecordContainer from 'containers/RecordContainer/CreateRecordContainer'
import ProjectProfile from 'views/manage/projects/profile/ProjectProfile'
import SubRoleProtectedRoute from './SubRoleProtectedRoute'
import { rolesForRecruiters } from 'helpers/subRolesHelper'
import UsersLeavesTable from '../../../views/dashboard/leaves/usersLeavesTable'
import { UserProfileContainer } from 'containers/UserProfileContainer'

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<SubRoleProtectedRoute allowedSubRoles={rolesForRecruiters}/>}>
        <Route path={webRoutes.VACANCIES} element={<VacancyIndex/>}/>
        <Route path={webRoutes.VACANCY(':vacancyId')} element={<VacancyShow/>}/>
        <Route path={webRoutes.CANDIDATE(':candidateId')} element={<CandidateShow/>}/>
      </Route>

      <Route element={
        <ProtectedRoute
          allowedGeneralRoles={[
            userRoles.DEFAULT.id,
            userRoles.MANAGER.id,
            userRoles.ADMIN.id,]}/>}>

        <Route path={webRoutes.DASHBOARD_USERS_LIST} element={<MainContainerRoute/>}/>
        <Route path={webRoutes.SUBMIT_HOURS} element={<MainContainerRoute/>}/>
        <Route path={webRoutes.HOME} element={<Login/>} />

        <Route path={'/requests'} element={<RequestsContainer/>}/>
        <Route path={'/settings'} element={<Settings/>}/>
        <Route path={'/create-record'} element={<CreateRecordContainer/>}/>
        <Route path={'/recent-notifications'} element={<MobileNotifications/>}/>
      </Route>

      <Route element={<ProtectedRoute allowedGeneralRoles={[userRoles.DEFAULT.id]}/>}>
        <Route path={'/my-report'} element={<MyReport/>}/>
        <Route path={'/monthly-report'} element={<MonthlyCalendarReport/>}/>
      </Route>

      <Route element={<ProtectedRoute allowedGeneralRoles={[userRoles.MANAGER.id, userRoles.ADMIN.id]}/>}>
        <Route path={'/manage-categories'} element={<ManageCategories/>}/>
        <Route path={'/create-admin-record'} element={<CreateRecordContainer/>}/>
        <Route path={'/manage-users'} element={<ManageUsers/>}/>
        <Route path={'/manage-projects'} element={<ManageProjects/>}/>
        <Route path={'/manage-customers'} element={<ManageCustomers/>}/>
        <Route path={'/manage-contracts'} element={<ManageContracts/>}/>
        <Route path={'/admin-monthly-report'} element={<ReportMonthly/>}/>
        <Route path={webRoutes.ADMIN_MONTHLY_CALENDAR(':userId')} element={<MonthlyCalendarReport/>}/>
        <Route path={'/users/:userId'} element={<UserProfileContainer/>}/>
        <Route path={`${webRoutes.RESUMES}/`}>
          <Route path={webRoutes.CV_CONTACT_SECTION(':cvId')} element={<CvContactsData/>} />
          <Route path="cv_contact" element={<CvContactsData/>} />
        </Route>
        <Route path={webRoutes.CV_EXPERIENCE(':resumeId')} element={<CvExperienceCompanies/>}/>
        <Route path={webRoutes.CV_PORTFOLIO(':resumeId')} element={<CvPortfolioProjects/>}/>
        <Route path={webRoutes.CV_SKILL(':resumeId')} element={<CvSkillSets/>}/>
        <Route path={webRoutes.CV_LANGUAGE(':resumeId')} element={<CvLanguages/>}/>
        <Route path={webRoutes.CV_EDUCATION(':resumeId')} element={<CvEducations/>}/>
        <Route path={webRoutes.CV_CERTIFICATE(':resumeId')} element={<CvCertificates/>}/>
        <Route path={webRoutes.CV_COURSE(':resumeId')} element={<CvCourses/>}/>
        <Route path={webRoutes.CV_BOOK(':resumeId')} element={<CvBooks/>}/>
        <Route path={webRoutes.CV_PREVIEW(':userSlug', ':cvId' )} element={<CvPreview/>}/>
        <Route path={'/projects/:projectId'} element={<ProjectProfile/>}/>
        <Route path={webRoutes.VACANCIES} element={<VacancyIndex/>}/>
        <Route path={webRoutes.VACANCY(':vacancyId')} element={<VacancyShow/>}/>
        <Route path={webRoutes.CANDIDATE(':candidateId')} element={<CandidateShow/>}/>
        <Route path={webRoutes.DASHBOARD_USERS_LIST} element={<DashboardUsersList/>}/>
        <Route path={webRoutes.DASHBOARD_MANAGERS} element={<ManagersTable/>}/>
        <Route path={webRoutes.DASHBOARD_LEAVES} element={<UsersLeavesTable/>}/>
      </Route>

      <Route element={<ProtectedRoute allowedGeneralRoles={[userRoles.ADMIN.id]}/>}>
      </Route>

      <Route path={'/logout'} element={<Logout/>}/>
      <Route path={'/unauthorized'} element={<Unauthorized/>}/>
      <Route path={'/cv/:cvSlug'} element={<CvPreview/>}/>
      <Route path={'*'} element={<NotFound/>}/>
    </Routes>
  )
}

export default AppRouter
