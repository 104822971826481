import React from 'react'

import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'

interface Props {
  start: string | undefined
  end: string | Date | undefined
}

const RequestDate: React.FC<Props> = ({ start, end }) => {

  return (
    <div>
      <div>
          Start Date: {dateFormatDDMMYY(start)}
      </div>
      <div>
          End Date: {end && dateFormatDDMMYY(end)}
      </div>
    </div>
  )
}

export default RequestDate
