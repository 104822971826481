import { StylesConfig } from 'react-select'

type MyOptionType = {
  label: string
  value: number
}

type IsMulti = false

const stylesLeftSelect: StylesConfig<MyOptionType, IsMulti> = {
  control: (css) =>
    ({
      ...css,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 16px',
      width: '100%',
      height: '48px',
      background: '#FF655C',
      borderRadius: '4px',
      border: '0'
    }),
  placeholder: (css) => ({
    ...css,
    color: '#FFFFFF',
    letterSpacing: '0.01em',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px'
  }),
  singleValue: (css) => ({
    ...css,
    color: '#FFFFFF',
    letterSpacing: '0.01em',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px'
  }),
  dropdownIndicator: (css) => ({
    ...css,
    color: '#FFFFFF'
  }),
  menu: (css) => ({
    ...css,
    zIndex: 5,
  }),
  option: (css, { isFocused, isSelected }) => ({
    ...css,
    backgroundColor: isFocused || isSelected ? '#F9F9FA' : 'white',
    color: 'black'
  })
}

const stylesRightSelect: StylesConfig<MyOptionType, IsMulti> = {
  control: (css) =>
    ({
      ...css,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      height: '48px',
      background: '#FFFFFF',
      borderRadius: '8px',
      border: '0',
      lineHeight: '142%'
    }),
  placeholder: (css) => ({
    ...css,
    color: '#9EA5B8',
    letterSpacing: '0.01em',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px'
  }),
  singleValue: (css) => ({
    ...css,
    color: '#9EA5B8',
    letterSpacing: '0.01em',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px'
  }),
  dropdownIndicator: (css) => ({
    ...css,
    color: '#9EA5B8'
  }),
  menu: (css) => ({
    ...css,
    zIndex: 5,
  }),
  option: (css, { isFocused, isSelected }) => ({
    ...css,
    backgroundColor: isFocused || isSelected ? '#F9F9FA' : 'white',
    color: 'black'
  })
}

export { stylesLeftSelect, stylesRightSelect }
