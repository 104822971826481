import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material'

import { webRoutes } from '../../../lib'
import { TrashIcon, EditIcon } from '../../../icons'
import { useDeleteVacancy } from '../../../hooks/vacancies/useDeleteVacancy'
import { useGetVacancy } from '../../../hooks/vacancies/useGetVacancy'
import { VacancyForm } from '../'
import { tableHeadStyles } from 'utilts/styles'
import { vacancyLevels } from '../../../constants'
import { Pages } from 'enums/StartPage'
import CustomPagination from 'components/reusable/Pagination/Pagination'
import { ItemsOnPage } from 'enums/DashboardEnums'
import { PaginateLimit, VacancyLimits } from '../../../enums/RequestEnums'
import { wasLastOnPage } from '../../../helpers/paginationPage'
import { Vacancy } from 'types'

interface Props {
  vacancies: Vacancy[],
  page: number,
  setPage: (page: number) => void
}

const VacanciesTable: React.FC<Props> = ({ vacancies, page, setPage }) => {
  const { pages: totalPages, count: vacancyCount } = useSelector(({ vacancies: { indexData } }) => indexData)

  const deleteVacancyHook = useDeleteVacancy()
  const [isFormOpen, setFormOpen] = useState(false)
  const getVacancy = useGetVacancy()
  const limit = ItemsOnPage.LIMIT
  let numbering = 1
  const lastOnPage = wasLastOnPage(vacancyCount, VacancyLimits.VACANCY_LIMIT, page) ?
    page - PaginateLimit.CURRENT_ITEM : page

  const toggleForm = (id) => {
    getVacancy(id)
    setFormOpen(!isFormOpen)
  }

  function deleteVacancy(id: number) {
    if (window.confirm('Are you sure?')) {
      deleteVacancyHook(id, page, vacancyCount)
      setPage(lastOnPage)
    }
  }

  return (
    <div className="table-wrapper">
      <div className="table-heading">
        <span>Vacancies List <small className="text-secondary">{vacancies.count}</small></span>
      </div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead className="requests-table__title">
            <TableRow>
              {<TableCell align="left" sx={tableHeadStyles}>
                    #
              </TableCell>}
              <TableCell align="left" sx={tableHeadStyles}>
                    Vacancy
              </TableCell>
              {<TableCell align="left" sx={tableHeadStyles}>
                    Where published
              </TableCell>}
              <TableCell align="left" sx={tableHeadStyles}>
                    Desired level
              </TableCell>
              <TableCell align="left" sx={tableHeadStyles}>
                    Salary range
              </TableCell>
              {<TableCell align="left" sx={tableHeadStyles}>
                    Candidates
              </TableCell>}
              <TableCell align="left" sx={tableHeadStyles}>
                    Options
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vacancies?.vacancies?.map((vacancy) => {
              return (
                <TableRow key={vacancy.id}>
                  <TableCell>
                    {page > 1 ?
                      `${numbering < limit ? page - 1 : ''}${numbering < limit ? numbering++ : page * limit}` :
                      numbering++
                    }
                  </TableCell>
                  <TableCell>
                    {vacancy.title}
                  </TableCell>
                  <TableCell>
                    {vacancy.url != '' ? <a href={vacancy.url} target="_blank" rel="noreferrer">
                      {vacancy.where_published}</a> : vacancy.where_published}
                  </TableCell>
                  <TableCell>
                    {vacancy.level ? vacancyLevels[vacancy.level.toUpperCase()].name : ''}
                  </TableCell>
                  <TableCell>
                    {'$' + vacancy.min_salary} - {'$' + vacancy.max_salary}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => localStorage.setItem('page', Pages.START_PAGE)}
                      outline
                      tag={Link}
                      to={webRoutes.VACANCY(vacancy.id)}>
                      All Сandidates ({vacancy.candidates_counter})
                    </Button>
                  </TableCell>
                  <TableCell>
                    <div className="table-options">
                      <button onClick={() => deleteVacancy(vacancy.id)}>
                        <TrashIcon />
                      </button>
                      <button onClick={() => toggleForm(vacancy.id)}>
                        <EditIcon />
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination
        totalPages={totalPages}
        page={page}
        setPage={setPage}
      />
      <VacancyForm
        isNewRecord={false}
        isOpen={isFormOpen}
        toggle={toggleForm}
        page={page}
        vacancyCount={vacancyCount} />
    </div>
  )
}

export default VacanciesTable
