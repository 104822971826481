import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { Box } from '@mui/material'
import Select from 'react-select'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import { TextFieldProps } from '@mui/material/TextField'
import uaLocale from 'date-fns/locale/uk'

import { User, Project, Status, Contract } from '../../../types'
import { customStyles } from '../../../helpers/customSelectStyles'
import { contractSchema } from '../../../helpers/validationShema'
import DatePickerTextField from '../ManageContracts/DatePickerTextField'
import { useAuth } from 'context/auth'
import { useCreateContract } from '../../../hooks/contract/useCreateContract'
import {
  jobStatusesOptionsForContractSelect,
  projectsOptionsForContractSelect,
  usersOptionsForContractSelect,
} from '../../../helpers/createOptionsForSelect'
import CustomButton from 'components/custom/CustomButton/CustomButton'
import { useUtility } from 'context/utility'

interface Props {
  closeModal: () => void
  usersNames: User[]
  projects: Project[]
  statuses: Status[]
}

export interface IOptions {
  value: number
  label: string
}

interface IData {
  user: IOptions | null
  project: IOptions | null
  status: IOptions | null
  startDate: Date
  endDate: Date | null
}

const defaultValues = {
  user: null,
  project: null,
  status: null,
  startDate: new Date(),
  endDate: null,
}

const CreateContract: React.FC<Props> = ({ closeModal, usersNames, projects, statuses }) => {
  const { currentUser } = useAuth()
  const { height } = useUtility()
  const resolver = yupResolver(contractSchema)
  const teammates = usersOptionsForContractSelect(usersNames)
  const existingProjects = projectsOptionsForContractSelect(projects)
  const existingJobStatuses = jobStatusesOptionsForContractSelect(statuses)

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({ resolver, defaultValues })
  const startDate = new Date(watch('startDate'))

  const createContractHook = useCreateContract()

  const formSubmit = (data: IData) => {
    const newContract: Contract = {
      user_id: data?.user?.value,
      project_id: data?.project?.value,
      job_status_id: data?.status?.value,
      start_date: data.startDate,
      end_date: data.endDate,
      admin_id: currentUser?.id,
    }
    createContractHook(newContract)
    closeModal()
  }

  const modalElement = document.querySelector<HTMLElement>('.modal__content-container')

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <div>
        <Controller
          name="user"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isClearable={true}
              placeholder="Users"
              options={teammates}
              styles={customStyles(errors.user)}
              maxMenuHeight={(modalElement && modalElement.offsetHeight / 2) || height / 5}
            />
          )}
        />
        {errors.user && <p className="form_select__error">This field is required</p>}
      </div>
      <div>
        <Controller
          name="project"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isClearable={true}
              placeholder="Projects"
              options={existingProjects}
              styles={customStyles(errors.project)}
              maxMenuHeight={(modalElement && modalElement.offsetHeight / 2) || height / 5}
            />
          )}
        />
        {errors.project && <p className="form_select__error">This field is required</p>}
      </div>
      <div>
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isClearable={true}
              placeholder="JobStatus"
              options={existingJobStatuses}
              styles={customStyles(errors.status)}
              maxMenuHeight={(modalElement && modalElement.offsetHeight / 2) || height / 5}
            />
          )}
        />
        {errors.status && <p className="form_select__error">This field is required</p>}
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
        <Controller
          name="startDate"
          control={control}
          defaultValue={new Date()}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              className="form_select__datepicker"
              mask="__.__.____"
              label="Start date"
              value={value}
              onChange={(value: Date | null) => onChange(value)}
              renderInput={(params: TextFieldProps) => <DatePickerTextField params={params} />}
            />
          )}
        />
        {errors.startDate && <p className="form_select__error">This field is required</p>}
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
        <Controller
          name="endDate"
          control={control}
          defaultValue={null}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              mask="__.__.____"
              label="End date"
              minDate={startDate}
              value={value}
              onChange={(value: Date | null) => onChange(value)}
              renderInput={(params: TextFieldProps) => <DatePickerTextField params={params} />}
            />
          )}
        />
      </LocalizationProvider>
      <Box sx={{ display: 'flex', gap: '1rem', marginTop: '40px' }}>
        <CustomButton variant="outlined" onClick={() => closeModal()} text="Cancel" />
        <CustomButton type="submit" text="Create" />
      </Box>
    </form>
  )
}

export default CreateContract
