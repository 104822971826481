import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { updateCvSkill } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'
import { IResumeSkill } from '../../../types/Resume/IResumeSkill'

export const useUpdateCvSkill = () => {
  const getUserCv = useGetUserCv()
    
  return (skill_set: IResumeSkill, skillId: number,  cvId: number) => {
    updateCvSkill(skill_set, skillId)
      .then(() => {
        successNotify('Skill set updated')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Update error'))
  }
}
