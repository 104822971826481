export enum StatusType {
  FULL_DAY_SICK_LEAVE = 'full_day_sick_leave',
  HALF_DAY_SICK_LEAVE = 'half_day_sick_leave',
  EXTRA_SICK_LEAVE = 'extra_sick_leave',
  WORK_OFF = 'work_off',
  UNPAID_LEAVE = 'unpaid_leave',
  HALF_DAY_UNPAID_LEAVE = 'half_day_unpaid_leave',
  VACATION = 'vacation',
  PAID_LEAVE = 'paid_leave',
  REGULAR = 'regular',
  DAY_OFF = 'day_off'
}

export enum VacationType {
  ONE_WEEK = 'one_week',
  TWO_WEEKS = 'two_weeks'
}
