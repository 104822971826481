import { useDispatch } from 'react-redux'

import { createProject } from '../../services/API/admin/projects'
import { addProject, addProjectFailure, addProjectSuccess } from '../../store/projectsSlice'
import { NewProject } from '../../types'
import successNotify from '../../helpers/successNotify'
import dangerNotify from '../../helpers/dangerNotify'
import { getProjectParams } from '../../constants'
import { useGetProjects, useGetUserProjects } from './useGetProjects'
import { useFetchProjectTechnologies } from './useFetchProjectTechnologies'

export const useCreateProject = () => {
  const dispatch = useDispatch()
  const fetchProjectTechnologiesHook = useFetchProjectTechnologies()
  const getProjectHook = useGetProjects()
  const getProjectsHook = useGetUserProjects()

  return (newProject: NewProject, page: number) => {
    dispatch(addProject())
    createProject(newProject)
      .then((response) => {
        dispatch(addProjectSuccess(response.data))
        getProjectHook(
          getProjectParams.name,
          getProjectParams.technology,
          getProjectParams.company,
          getProjectParams.fromScratch,
          getProjectParams.application,
          getProjectParams.manager,
          page
        )
        fetchProjectTechnologiesHook()
        getProjectsHook()
        successNotify('Project created')
      })
      .catch((error) => {
        dispatch(addProjectFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
