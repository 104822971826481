import API from '../..'
import ROUTES from '../../../../routes'
import { Project, NewProject } from '../../../../types'

export const getProjects = (
  name: string | undefined,
  technology: string | undefined,
  company: string | undefined,
  fromScratch: string | undefined,
  application: string | undefined,
  manager: number | undefined,
  page: number
) => {
  return API.get(ROUTES.ADMIN_PROJECTS, {
    params: {
      name,
      technology,
      company,
      from_scratch: fromScratch,
      application,
      manager,
      page
    },
  })
}

export const getProjectContracts = (id: number, page: number) => {
  return API.get(ROUTES.ADMIN_PROJECT + `/${id}/contracts?page=${page}`)
}

export const getProjectInfo = (id: number) => {
  return API.get(`${ROUTES.ADMIN_PROJECTS}/${id}`)
}

export const createProject = (newProject: NewProject) => API.post(ROUTES.ADMIN_PROJECTS, { project: newProject })

export const editProject = (project: NewProject, project_id: number | undefined) =>
  API.patch(ROUTES.ADMIN_PROJECTS + `/${project_id}`, { project: project })

export const updateProject = (params: Project) => {
  return API.put(ROUTES.ADMIN_PROJECTS + `/${params.id}`, {
    project: {
      title: params.title,
      url: params.url,
      representative: params.representative,
      company_name: params.company_name,
      email: params.email,
      company_address: params.company_address,
      design_url: params.design_url,
      specs_url: params.specs_url,
      milestones_url: params.milestones_url,
    },
  })
}

export const deleteProject = (project: NewProject) => {
  return API.delete(ROUTES.ADMIN_PROJECTS + `/${project.id}`)
}
