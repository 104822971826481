import { useDispatch } from 'react-redux'

import { useGetAdminRequests, useGetRequests } from './useGetRequests'
import { deleteRequest } from 'services/API/request'
import { removeRequest, removeRequestFailure, removeRequestSuccess } from '../../store/requestsSlice'
import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'
import { tabDayDate, tabMonthDate } from '../../constants'
import { RequestLimits } from 'enums/RequestEnums'
import { deleteRecords } from 'services/API/record'
import { PaginateLimit } from '../../enums/RequestEnums'
import { wasLastOnPage } from '../../helpers/paginationPage'

export const useDeleteRequest = () => {
  const getRequestsHook = useGetRequests()
  const dispatch = useDispatch()

  return (
    id: number,
    day: Date | null,
    month: Date,
    alignment: string,
    page: number,
    requestCount: number,
    setPage: (page: number) => void,
  ) => {
    const lastOnPage = wasLastOnPage(requestCount, RequestLimits.REQUEST_DASHBOARD_LIMIT, page)
      ? page - PaginateLimit.CURRENT_ITEM
      : page

    dispatch(removeRequest())
    deleteRequest(id)
      .then(() => {
        dispatch(removeRequestSuccess(id))
        successNotify('Request deleted')
        getRequestsHook(lastOnPage, tabDayDate(alignment, day), tabMonthDate(alignment, month))
        setPage(lastOnPage)
      })
      .catch((error) => {
        dispatch(removeRequestFailure(error))
        dangerNotify('You cant delete approved or rejected request.')
      })
  }
}

export const useDeleteAdminRequest = () => {
  const getRequestsHook = useGetAdminRequests()
  const dispatch = useDispatch()

  return (
    id: number,
    day: Date | null,
    month: Date,
    alignment: string,
    page: number,
    requestCount: number,
    setPage: (page: number) => void,
    by_user_id?: number
  ) => {
    const lastOnPage = wasLastOnPage(requestCount, RequestLimits.REQUEST_DASHBOARD_LIMIT, page)
      ? page - PaginateLimit.CURRENT_ITEM
      : page

    dispatch(removeRequest())
    deleteRecords(id)
      .then(() => {
        deleteRequest(id).then(() => {
          dispatch(removeRequestSuccess(id))
          successNotify('Request deleted')
          getRequestsHook({
            page: lastOnPage,
            limit: RequestLimits.REQUEST_DASHBOARD_LIMIT,
            by_date: tabDayDate(alignment, day),
            by_month: tabMonthDate(alignment, month),
            by_user_id
          })
          setPage(lastOnPage)
        })
      })
      .catch((error) => {
        dispatch(removeRequestFailure(error))
        dangerNotify('You cant delete approved or rejected request.')
      })
  }
}
