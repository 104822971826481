import React, { SVGProps } from 'react'

interface Props {
  onHandleBack?: () => void
}

const EditIcon: React.FC<Props & SVGProps<SVGSVGElement>> = ({ onHandleBack, ...rest }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onHandleBack}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3603 2.5C14.2107 2.5 14.0625 2.52948 13.9242 2.58675C13.7859
      2.64403 13.6603 2.72797 13.5545 2.8338L3.52965 12.8586L2.92524 15.0748L5.14139 14.4704L15.1662 4.44555C15.272
      4.33972 15.356 4.21408 15.4133 4.07581C15.4705 3.93754 15.5 3.78934 15.5 3.63968C15.5 3.49001 15.4705 3.34181
      15.4133 3.20354C15.356 3.06527 15.272 2.93963 15.1662 2.8338C15.0604 2.72797 14.9348 2.64403 14.7965
      2.58675C14.6582 2.52948 14.51 2.5 14.3603 2.5ZM13.1588 0.738993C13.5398 0.58121 13.948 0.5 14.3603 0.5C14.7727
      0.5 15.1809 0.58121 15.5618 0.738993C15.9428 0.896777 16.2889 1.12804 16.5804 1.41959C16.872 1.71113 17.1032
      2.05725 17.261 2.43817C17.4188 2.8191 17.5 3.22737 17.5 3.63968C17.5 4.05198 17.4188 4.46025 17.261
      4.84118C17.1032 5.2221 16.872 5.56822 16.5804 5.85976L6.36782 16.0724C6.24477 16.1954 6.09173 16.2842 5.92383
      16.33L1.76314 17.4648C1.41693 17.5592 1.04667 17.4609 0.792916 17.2071C0.539166 16.9534 0.440838 16.5831 0.535259
      16.2369L1.66999 12.0762C1.71578 11.9083 1.8046 11.7553 1.92765 11.6322L12.1403 1.41959C12.4318 1.12804 12.7779
      0.896777 13.1588 0.738993Z"
        fill="#8992A6"
      />
    </svg>
  )
}

export default EditIcon
