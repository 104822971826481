import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { User } from '../../../types'
import { ILeave } from './usersLeavesTable'
import { availableDayOff, availableSickLeave, availableVacation } from '../../../constants'
import {  renderUserName } from '../../../helpers/renderName'
import CustomPagination from '../../../components/reusable/Pagination/Pagination'
import { MobileLeaveItem } from '../index'

interface IProps {
    leavesData: ILeave[]
    totalPages: number
    page: number
    setPage: (value: number) => void
}

const MobileLeavesPage: React.FC<IProps> = ({
  leavesData,
  totalPages,
  page,
  setPage
}) => {
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)

  return (
    <div className="mobile_dashboard__wrapper">
      <div className="mobile_leaves__card-list">
        {leavesData.map(l => {
          const sickLeaveCount = Number(`${l.full_day_sick_leaves + (l.half_day_sick_leaves / 2)}`)
          const unpaidLeavesCount = Number(`${l.unpaid_leaves + (l.half_day_unpaid_leaves / 2)}`)

          return(
            <div className="mobile_leaves__card" key={l.user_id}>
              <Link to={`/users/${l.user_id}`} className="mobile_leaves__card-managerName">
                {renderUserName(l.user_id, usersNames)}
              </Link>
              <div className="mobile_leaves__items">
                <div>
                  <MobileLeaveItem leaveType="Sick leaves" leavesCount={sickLeaveCount} maxLeaves={availableSickLeave}/>
                  <MobileLeaveItem leaveType="Day offs" leavesCount={l.day_offs} maxLeaves={availableDayOff} />
                  <MobileLeaveItem leaveType="Vacations" leavesCount={l.vacations} maxLeaves={availableVacation}/>
                </div>
                <div>
                  <MobileLeaveItem leaveType="Extra leaves" leavesCount={l.extra_sick_leaves}/>
                  <MobileLeaveItem leaveType="Unpaid leaves" leavesCount={unpaidLeavesCount}/>
                  <MobileLeaveItem leaveType="Paid leaves" leavesCount={l.paid_leaves}/>
                </div>
              </div>
            </div>
          )
        })}
        <CustomPagination totalPages={totalPages} page={page} setPage={setPage} />
      </div>
    </div>
  )
}

export default MobileLeavesPage
