import API from '..'
import ROUTES from '../../../routes'
import { Candidate } from '../../../types'

export const getCandidates = (vacancy_id: number, filter: string, page: number) => {
  return API.get(ROUTES.CANDIDATES, { params: { vacancy_id: vacancy_id, filter: filter, page: page } })
}

export const getCandidate = (id: number | string) => {
  return API.get(ROUTES.CANDIDATE(id))
}

export const createCandidate = (params: Partial<Candidate>) => {
  return API.post(ROUTES.CANDIDATES, params)
}

export const updateCandidate = (params: Partial<Candidate>) => {
  return API.put(ROUTES.CANDIDATE(params.id), params)
}

export const deleteCandidate = (id: number | string) => {
  return API.delete(ROUTES.CANDIDATE(id))
}

export const getCandidateNames = (vacancy_id: number) => {
  return API.get(ROUTES.CANDIDATE_NAMES, { params: { vacancy_id: vacancy_id } })
}
