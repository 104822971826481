import { useDispatch } from 'react-redux'

import { getVacancy as getVacancyService } from '../../services/API/vacancies'
import { getVacancy, getVacancyFailure, getVacancySuccess } from '../../store/vacanciesSlice'
import dangerNotify from '../../helpers/dangerNotify'

export const useGetVacancy = () => {
  const dispatch = useDispatch()

  return (id: number) => {
    dispatch(getVacancy())
    getVacancyService(id)
      .then((response) => {
        dispatch(getVacancySuccess(response.data))
      })
      .catch((error) => {
        dispatch(getVacancyFailure(error))
        dangerNotify('Server error: unable to download Vacancy')
      })
  }
}
