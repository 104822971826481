import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'

import Modal from '../../reusable/Modal/index.tsx'
import ProjectsList from '../ProjectsList'
import CreateProject from '../CreateProject'
import useToggle from 'hooks/useToggle'
import { useGetProjects } from 'hooks/project/useGetProjects'
import ReactButton from '../../reusable/ReactButton'
import { User, Technology, NewProject, Project } from '../../../types'
import { Customer } from '../../../types'
import ProjectsFilters from './ProjectsFilters'
import Loader from 'components/reusable/Loader'
import { Pages } from '../../../enums/StartPage'
import { useFetchProjectTechnologies } from '../../../hooks/project/useFetchProjectTechnologies'
import { IProjectTechnology } from '../ProjectsList/ProjectsList'
import { useFetchAllCustomers } from '../../../hooks/customer/useFetchAllCustomers'
import { IOptions, IStringOptions } from '../../../types/ISelectOptions'
import CustomPagination from '../../reusable/Pagination/Pagination'
import { useGetTechnologies } from '../../../hooks/Technology/useGetTecnologies'

const ManageProjects = () => {
  const [open, toggle] = useToggle(false)
  const [page, setPage] = React.useState(Pages.START_PAGE)
  const [technology, setTechnology] = useState<IOptions | null>(null)
  const [application, setApplication] = useState<IOptions | null>(null)
  const [manager, setManager] = useState<IOptions | null>(null)
  const [project, setProject] = useState<IOptions | null>(null)
  const [company, setCompany] = useState<IOptions | null>(null)
  const [fromScratch, setFromScratch] = useState<IStringOptions | null>(null)
  const projects = useSelector((state: { projects: { indexData: NewProject[] } }) => state.projects.indexData)
  const allProjects =
      useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)
  const projectsLoading = useSelector((state: { projects: { isLoading: boolean } }) => state.projects.isLoading)
  const totalPages = useSelector((state: { projects: { totalPages: number } }) => state.projects.totalPages)
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const technologies = useSelector(
    (state: {technologies: {indexData: Technology[]}}) => state.technologies.indexData)
  const allCustomers = useSelector(
    (state: { customers: { allCustomersData: Customer[] } }) => state.customers.allCustomersData)
  const projectTechnologies = useSelector(
    (state: { projects: { projectTechnologies: IProjectTechnology[] } }) => state.projects.projectTechnologies)
  const fetchTechnologies = useGetTechnologies()
  const getProjectsHook = useGetProjects()
  const fetchCustomers = useFetchAllCustomers()
  const fetchProjectTechnologiesHook = useFetchProjectTechnologies()

  useEffect(() => {
    fetchTechnologies()
    fetchProjectTechnologiesHook()
    fetchCustomers()
  }, [])

  useEffect(() => {
    getProjectsHook(
      project?.label,
      technology?.label,
      company?.label,
      fromScratch?.value,
      application?.label,
      manager?.value,
      page
    )
  }, [project?.label, technology?.label, company?.label, fromScratch?.value, application?.label, manager?.value, page])

  const createProject = (
    <CreateProject
      closeModal={toggle}
      usersNames={usersNames}
      technologies={technologies}
      customers={allCustomers}
      page={page}
    />
  )

  return (
    <>
      <div className="manage-project__row">
        <div className="manage-project__col">
          <ReactButton sx={{ height: '40px' }} text={'Add Project'} onClick={toggle} variant="contained" />
          {open && <Modal title="Create Project" component={createProject} open={open} handleClose={toggle} />}
          <Box style={{ display: 'flex', width: '90%' }}>
            <ProjectsFilters
              projects={allProjects}
              technologies={technologies}
              customers={allCustomers}
              usersNames={usersNames}
              setTechnology={setTechnology}
              setApplication={setApplication}
              setManager={setManager}
              setProject={setProject}
              setCompany={setCompany}
              setFromScratch={setFromScratch}
              fromScratch={fromScratch}
              application={application}
              manager={manager}
              project={project}
              company={company}
              technology={technology}
              setPage={setPage}
            />
          </Box>
        </div>
      </div>
      <div className="manage-project__row">
        <div className="manage-project__table">
          <h3>Projects List</h3>
          {projectsLoading ? <Loader/> :
            <ProjectsList
              projectTechnologies={projectTechnologies}
              usersNames={usersNames}
              projects={projects}
              technologies={technologies}
              customers={allCustomers}
              page={page}
              setTechnology={setTechnology}
              setApplication={setApplication}
              setManager={setManager}
              setProject={setProject}
              setCompany={setCompany}
              setFromScratch={setFromScratch}
              setPage={setPage}
            />
          }
          <CustomPagination totalPages={totalPages} page={page} setPage={setPage} />
        </div>
      </div>
    </>
  )
}

export default ManageProjects
