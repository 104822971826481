import React, { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Button, IconButton } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { webRoutes } from 'lib'
import { CopyIcon } from 'icons'
import { API_URL } from 'routes'
import successNotify from 'helpers/successNotify'
import { cvNavLinks } from '../../../constants'
import { useFetchUserCvs } from 'hooks/resume/useFetchUserCvs'
import { IResume } from 'types/Resume/IResume'
import Loader from 'components/reusable/Loader'
import { useDeleteResume } from 'hooks/resume/useDeleteUserCv'
import { useDuplicateCv } from 'hooks/resume/useDuplicateCv'

const UserProfileCVList = () => {
  const navigate = useNavigate()
  const getResumes = useFetchUserCvs()
  const deleteResume = useDeleteResume()
  const duplicateResume = useDuplicateCv()

  const user = useSelector(({ users: { showData } }) => showData)
  const [resumes, isLoading] = useSelector(({ resumes: { indexData, isLoading } }): [IResume[], boolean] => [
    indexData,
    isLoading,
  ])

  useEffect(() => {
    getResumes(user.id)
  }, [])

  if (isLoading) return <Loader />

  return (
    <>
      {!resumes.length ? (
        <Button
          variant="contained"
          onClick={() => navigate(`${webRoutes.RESUMES}${cvNavLinks.CONTACT_SECTION}?userId=${user.id}`)}
        >
          Create CV
        </Button>
      ) : (
        <table className="user-profile__table cv-list">
          <tbody>
            {resumes.map((r) => (
              <tr className="user-profile__table-tr" key={r.id + 'USERRESUME'}>
                <td className="user-profile__table-td">
                  <Link target="_blank" to={webRoutes.CV_PREVIEW(user.slug, r.id)}>
                    {r.role}
                  </Link>
                </td>
                <td className="user-profile__table-td text-end">
                  <IconButton onClick={() => deleteResume(r.id, user.id)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                  <IconButton onClick={() => duplicateResume(r.id, r.role, user.id)}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => navigate(`${webRoutes.RESUMES}/${webRoutes.CV_CONTACT_SECTION(r.id)}`)}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  <CopyToClipboard
                    text={`${API_URL}/cv/${user.slug}/${r.id}`}
                    onCopy={() => successNotify('CV link copied!')}
                  >
                    <button className="btn-copy">
                      <CopyIcon />
                    </button>
                  </CopyToClipboard>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  )
}

export default memo(UserProfileCVList)
