import React from 'react'

import { useAuth } from 'context/auth'
import { isManager } from 'helpers/accessHelpers'
import { IManager } from 'types/IManager'
import { BENCH_MANAGER_FIRST_NAME, BENCH_MANAGER_LAST_NAME } from '../../../constants'

interface Props {
  note: string | undefined,
  description: string | undefined,
  managers: IManager[] | undefined,
}

const RequestDescription: React.FC<Props> = ({ note, managers }) => {
  const { currentUser } = useAuth()
  const isAdmin = isManager(currentUser)

  return (
    <div>
      {isAdmin ?
        <div className="requests-mobile-label-manager">
           Manager:
          <span className="requests-mobile-managers">
            {managers?.filter((m: IManager) => m.first_name != BENCH_MANAGER_FIRST_NAME
              && m.last_name != BENCH_MANAGER_LAST_NAME).map((manager: IManager) =>
              <span className="requests-mobile-label-manager-item" key={manager.id}>
                {manager.first_name} {manager.last_name}
              </span>
            )}
          </span>
        </div> :
        <div className="requests-mobile-label-manager">
          Description: {note}
        </div>
      }  
    </div>
  )
}

export default RequestDescription
