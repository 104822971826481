import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {  SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import { User } from 'types/User'
import { useEditProject } from 'hooks/project/useEditProject'
import { createProjectSchema } from '../../../helpers/validationShema'
import { userRoles } from '../../../constants'
import { Customer, Technology, NewProject } from '../../../types'
import ProjectForm from '../ManageProjects/ProjectForm'
import ReactForm from '../../reusable/ReactForm'
import CustomButton from 'components/custom/CustomButton/CustomButton'
import { IProjectTechnology } from '../ProjectsList/ProjectsList'
import { IOptions, IStringOptions } from '../../../types/ISelectOptions'
import webRoutes from 'lib/webRoutes'
import EditProjectForm from '../ManageProjects/EditProjectForm'
import { Pages } from 'enums/StartPage'

interface Props {
  setOpen: (show: boolean) => void
  selectedProject: NewProject | undefined
  technologies: Technology[]
  customers: Customer[]
  usersNames: User[]
  setProjectData?: React.Dispatch<React.SetStateAction<NewProject | undefined>>
  projectTechnologies: IProjectTechnology[]
  setTechnology?: (technology: IOptions | null) => void
  setApplication?: (application: IOptions | null) => void
  setProject?: (project: IOptions | null) => void
  setCompany?: (company: IOptions | null) => void
  setManager?: (manager: IOptions | null) => void
  setFromScratch?: (value: IStringOptions | null) => void
  setPage: (page: number) => void
}

export interface IEditProjectInputs {
  customer: { value: number | undefined, label: string }
  name: string | undefined
  manager: { value: number, label: string; }
  technologies: { value: number, label: string }[] | []
  description: string | undefined
  industry: string | undefined
  from_scratch: boolean
  payment_integration: boolean
  applications: string[] | []
  designs: string
  milestones: string
  specs: string
  url: string
}

export const EditProject: React.FC<Props> = ({
  setOpen,
  selectedProject,
  technologies,
  customers,
  usersNames,
  setProjectData,
  projectTechnologies,
  setProject,
  setTechnology,
  setApplication,
  setManager,
  setCompany,
  setFromScratch,
  setPage,
}) => {
  const editProject = useEditProject()
  const { projectId } = useParams()
  const selectedProjectTechnologies = projectTechnologies?.filter(project => project.project_id === selectedProject?.id)
  const location = useLocation()
  const tech_stack = technologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }))

  const managers = usersNames.filter((user) =>
    (user.role === userRoles.MANAGER.id || user.role === userRoles.ADMIN.id) && user.active)
    .map((user) => ({
      value: user.id,
      label: user.full_name
    }))

  const companies = customers.map((company) => ({
    value: company.id,
    label: company.company_name
  }))

  const selectedTechnologies = tech_stack.filter((technology) => {
    return selectedProjectTechnologies?.some((item) => technology.value === item.technology_id)})
  const selectedManager = managers?.find((manager) => manager.value === selectedProject?.manager_id)
  const selectedCustomer = companies?.find((customer) => customer.value === selectedProject?.customer_id)

  const defaultValues: IEditProjectInputs = {
    customer: selectedCustomer || '',
    name: selectedProject?.name,
    manager: selectedManager || '',
    description: selectedProject?.description,
    industry: selectedProject?.industry,
    technologies: selectedTechnologies || [],
    from_scratch: selectedProject?.from_scratch || false,
    payment_integration: selectedProject?.payment_integration || false,
    applications: selectedProject?.app || [],
    url: selectedProject?.url || '',
    designs: selectedProject?.designs || '',
    milestones: selectedProject?.milestones || '',
    specs: selectedProject?.specs || '',
  }
  const resolver = yupResolver(createProjectSchema)
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({ resolver, defaultValues, mode: 'onChange' })

  const onSubmit: SubmitHandler<IEditProjectInputs> = (data) => {
    const technologies_ids = data.technologies?.map((technology) => technology.value )
    const project_id = selectedProject?.id
    const project: NewProject = {
      name: data.name,
      manager_id: data.manager.value,
      from_scratch: data.from_scratch,
      description: data.description || '',
      industry: data.industry || '',
      customer_id: data.customer.value,
      payment_integration: data.payment_integration,
      technologies: technologies_ids,
      app: data.applications,
      designs: data.designs || '',
      milestones: data.milestones || '',
      specs: data.specs || '',
      url: data.url || '',
    }

    if (projectId) {
      editProject(project, +projectId)
      setProjectData && setProjectData(project)
    } else {
      editProject(project, project_id)
      setTechnology && setTechnology(null)
      setApplication && setApplication(null)
      setManager && setManager(null)
      setProject && setProject(null)
      setCompany && setCompany(null)
      setFromScratch && setFromScratch(null)
    }
    setPage(Pages.START_PAGE)
    setOpen(false)
  }

  return (
    <ReactForm onSubmit={handleSubmit(onSubmit)}>
      {
        location.pathname === webRoutes.PROJECTS ? 
          <ProjectForm
            selectedProject={selectedProject}
            defaultValues={defaultValues}
            register={register}
            control={control}
            companies={companies}
            errors={errors}
            managers={managers}
            tech_stack={tech_stack} />
          : 
          <EditProjectForm
            selectedProject={selectedProject}
            defaultValues={defaultValues}
            register={register}
            control={control}
            companies={companies}
            errors={errors}
            managers={managers}
            tech_stack={tech_stack} />
      }
      <div className={isMobile ? 'project-profile_mobile-edit-links-wrap' : 'edit-profile__buttons-wrap'}>
        <CustomButton variant="outlined" onClick={() => setOpen(false)} text="Cancel"/>
        <CustomButton type="submit" text="Edit" />
      </div>
    </ReactForm>
  )
}
