import * as Yup from 'yup'

import { Type } from 'enums/RequestEnums'

export const validationSchema = Yup.object().shape({
  email: Yup.string().required('This field is required').email('Invalid email address'),
  password: Yup.string()
    .required('This field is required')
    .min(6, 'Password must contain at least 6 characters')
    .max(40, 'Password must contain not more 40 characters'),
})

export const createProjectSchema = Yup.object({
  name: Yup.string().trim().required('This field is required'),
  customer: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required('Select company'),
  manager: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required(),
  technologies: Yup.array().min(1),
  applications: Yup.array().min(1),
})

export const createCustomerSchema = Yup.object({
  full_legal_name: Yup.string().trim().required('This field is required'),
  email: Yup.string().required('This field is required').email('Invalid email address'),
  title: Yup.string().trim().required('This field is required'),
  company_name: Yup.string().trim().required('This field is required'),
})

export const contractSchema = Yup.object({
  user: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required(),
  project: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required(),
  status: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required(),
  startDate: Yup.date().nullable().required('This field is required'),
})

export const userCreateSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('This field is required'),
  first_name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, 'First name can only contain Latin letters')
    .required('This field is required'),
  last_name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, 'Last name can only contain Latin letters')
    .required('This field is required'),
  password: Yup.string()
    .required('No password provided.')
    .min(6, 'Password is too short - should be 6 chars minimum.')
    .required('This field is required'),
}).required()

export const requestsSchema = Yup.object({
  type: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required(),
  vacationType: Yup.object().when('type', {
    is: (item) => item.value === Type.VACATION,
    then: Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() })
  })
    .nullable()
    .notRequired(),
  note: Yup.string().max(30, 'Note is too long.'),
  hours: Yup.number().moreThan(0),
}).required()

export const categorySchema = Yup.object({
  categoryName: Yup.string().trim().required('This field is required'),
})
