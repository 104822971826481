import { useDispatch } from 'react-redux'

import { getVacancies } from '../../services/API/vacancies'
import { fetchVacancies, fetchVacanciesFailure, fetchVacanciesSuccess } from '../../store/vacanciesSlice'
import dangerNotify from '../../helpers/dangerNotify'

export const useFetchVacancies = () => {
  const dispatch = useDispatch()

  return (page: number) => {
    dispatch(fetchVacancies())
    getVacancies(page)
      .then((response) => {
        dispatch(fetchVacanciesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchVacanciesFailure(error))
        dangerNotify('Server error: unable to download Vacancies')
      })
  }
}
