import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'

import { Project } from 'types/Project'
import { Record } from 'types/Record'
import { Contract } from '../../../types'
import { renderProject } from '../../../helpers/renderName'
import { WeekDay } from '../../../constants'
import { dateFormatYYMM } from 'helpers/displayingDateFormat'
import { webRoutes } from 'lib'
import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import DefaultSelect from 'components/custom/DefaultSelect/DefaultSelect'
import { IOptions } from 'types/ISelectOptions'
import { Pages } from 'enums/StartPage'
import { FilterSwitch } from 'components/reusable/FIlterSwitch'

interface Props {
  projects: Project[]
  records: Record[]
  date: Date | undefined
  setDate: React.Dispatch<React.SetStateAction<Date | null>>
  setProject: React.Dispatch<React.SetStateAction<SingleValue<IOptions | null>>>
  project: IOptions | undefined
  closeModal?: () => void
  setPage: React.Dispatch<React.SetStateAction<Pages>>
}

const dashboardTabs = [
  { name: 'Monthly Report', url: webRoutes.MY_REPORT },
  { name: 'Monthly Calendar', url: webRoutes.USER_MONTHLY_REPORT }
]

const UserReportFilters: React.FC<Props> = ({ projects, setPage, date, setDate, setProject, project }) => {
  const [active, setActive] = useState('Monthly Report')
  const availableContracts = useSelector(
    (state: { availableContracts: { indexData: Contract[] } }) => state.availableContracts.indexData
  )

  const navigate = useNavigate()

  const firstDayOfMonth = new Date(date?.getFullYear(), date?.getMonth(), 1)

  const arrowNextItem = () => {
    if (setDate) {
      setDate(new Date(firstDayOfMonth?.setMonth(firstDayOfMonth.getMonth() + WeekDay.OneDay)))
    }
  }
  const arrowBackItem = () => {
    if (setDate) {
      setDate(new Date(firstDayOfMonth?.setMonth(firstDayOfMonth.getMonth() - WeekDay.OneDay)))
    }
  }

  const usersProjects: IOptions[] = availableContracts?.map((contract) => ({
    label: renderProject(contract.project_id, projects),
    value: contract.project_id,
  })) as IOptions[]

  const changeTab = (name: string) => {
    setActive(name)
    name === 'Monthly Calendar' && navigate(webRoutes.USER_MONTHLY_REPORT, { state: date })
  }

  return (
    <div className={`${!isMobile ? 'user-report__filters_container' : 'user-report__filters_mobile'}`}>
      <div className={`${!isMobile ? 'user-report__filters_left' : 'user-report__filters_mobile'}`}>
        <FilterSwitch changeTab={changeTab} active={active} items={dashboardTabs} />
        <CustomDatepicker
          value={date as Date}
          onChange={(value) => setDate(value as Date)}
          mask={'__.__.____'}
          inputType="day"
          minDate={dayjs().add(-1, 'M').startOf('month').toDate()}
          maxDate={dayjs().add(1, 'M').endOf('month').toDate()}
          datePickerType="month"
          borderInputColor="#D4D9E2"
          arrowNextItem={arrowNextItem}
          arrowBackItem={arrowBackItem}
          disableMinusArrow={dateFormatYYMM(date) === dateFormatYYMM(dayjs().add(-1, 'M').startOf('month').toDate())}
          disablePlusArrow={dateFormatYYMM(date) === dateFormatYYMM(dayjs().add(1, 'M').endOf('month').toDate())}
          disableClearButton={true}
        />
      </div>
      <div className={`${!isMobile ? 'user-report__filters_select' : 'user-report__filters_mobile_select'}`}>
        <DefaultSelect
          options={usersProjects}
          value={project}
          onChange={newValue => {
            setProject(newValue as IOptions)
            setPage(Pages.START_PAGE)
          }}
          placeholder="All Projects"
          isClearable
        />
      </div>
    </div>
  )
}

export default UserReportFilters
