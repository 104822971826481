import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import { isMobile } from 'react-device-detect'

import { Customer, Project } from '../../../types'
import { useGetCustomer } from '../../../hooks/customer/useGetCustomer'
import CreateCustomer from '../CreateCustomer/CreateCustomer'
import CustomersList from '../CustomersList'
import { useDeleteCustomer } from '../../../hooks/customer/useDeleteCustomer'
import CustomersFilters from './CustomerFilters'
import { getCustomerParams } from '../../../constants'
import Loader from 'components/reusable/Loader'
import useToggle from '../../../hooks/useToggle'
import Modal from '../../reusable/Modal/index.tsx'
import { Pages } from 'enums/StartPage'

const ManageCustomers = () => {
  const [open, toggle] = useToggle(false)
  const [page, setPage] = React.useState(Pages.START_PAGE)
  const [edited, setEdited] = useState(false)
  const [mobileCreateOpen, setMobileCreateOpen] = React.useState(false)
  const getCustomersHook = useGetCustomer()
  const deleteCustomerHook = useDeleteCustomer()
  const customers = useSelector(
    (state: { customers: { indexData: { customers: Customer[] } }}) => state.customers.indexData
  )
  const customersLoading = useSelector((state: { customers: { isLoading: Customer[] } }) => state.customers.isLoading)
  const numberOfCustomers = useSelector(
    (state: { customers: { customersLength: number }}) => state.customers.customersLength
  )
  const companies = useSelector((state: { customers: { companies: [] } }) => state.customers.companies)
  const projects = useSelector((state: { projects: { indexUserData: Project[] } }) => state.projects.indexUserData)

  const handleMobileCreateOpen = () => setMobileCreateOpen(true)
  const handleMobileCreateClose = () => setMobileCreateOpen(false)

  function removeCustomer(id: number | undefined) {
    if (window.confirm('Are you sure?')) {
      deleteCustomerHook(id)
    }
  }

  useEffect(() => {
    getCustomersHook(
      getCustomerParams.company,
      getCustomerParams.representative,
      getCustomerParams.projectId,
      page
    )
    setEdited(edited)
  }, [page, edited])

  const createCustomer = <CreateCustomer closeModal={toggle} />

  return (
    <div className={isMobile ? 'manage-customers__body' : ''}>
      {isMobile && !mobileCreateOpen &&
        <div className="manage-customers__create-button-mibile" onClick={handleMobileCreateOpen}>+</div>
      }
      {open &&
        <Modal title="Create Customer" component={createCustomer} open={open} handleClose={toggle} />
      }

      {!isMobile &&
        <div className="manage-customers__row-filters">
          <div className="manage-customers__filters-column">
            <CustomersFilters
              companiesSliced={companies}
              edited={edited}
              setEdited={setEdited}
              page={page}
              setPage={setPage} />
          </div>
          <div className="manage-customers__button-position">
            <Button className="requests__button" onClick={toggle}>
              <div className="requests__button">
                Add Customer +
              </div>
            </Button>
          </div>
          {open && <Modal title="Create Customer" component={createCustomer} open={open} handleClose={toggle} />}
        </div>
      }

      {mobileCreateOpen && <>
        <CreateCustomer closeModal={handleMobileCreateClose} />
      </>}

      {!mobileCreateOpen && <div className="manage-customers__row">
        <div className="manage-customers__table">
          <div className="manage-customers__row-mobile">
            {!isMobile &&
              <div className="requests-list__title">
                List of Companies <span className="lists-counter">{numberOfCustomers}</span>
              </div>
            }
          </div>
          {customersLoading ? <Loader /> :
            <CustomersList
              deleteCustomer={removeCustomer}
              customers={customers}
              projects={projects}
              page={page}
              setPage={setPage}
              setEdited={setEdited}
              edited={edited}
            />
          }
        </div>
      </div>}
    </div>
  )
}

export default ManageCustomers
