import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'

import UsersCv from '../UsersCv'
import CvNavButtons from '../CvNavButtons'
import CvAddLanguage from './CvAddLanguage'
import { useDeleteLanguage } from '../../../../../hooks/resume/languages/useDeleteLanguage'
import CvUpdateLanguage from './CvUpdateLanguage'
import Modal from '../../../../reusable/Modal/index.tsx'
import { IResume } from '../../../../../types/Resume/IResume'
import { cvNavLinks } from '../../../../../constants'
import { IResumeLanguage } from '../../../../../types/Resume/IResumeLanguage'
import { useGetUserCv } from '../../../../../hooks/resume/useGetUserCv'

const CvLanguages = () => {
  const { resumeId } = useParams()
  const [open, setOpen] = useState(false)
  const [language, setLanguage] = useState<IResumeLanguage>()
  const resume = useSelector((state: { resumes: { showData: IResume } }) => state.resumes.showData)
  const getUserCv = useGetUserCv()
  const deleteLanguage = useDeleteLanguage()
  const nextPath = cvNavLinks.EDUCATION
  const prevPath = cvNavLinks.SKILLS

  useEffect(() => {
    if (resumeId) {
      getUserCv(+resumeId)
    }
  }, [resumeId])

  const onDeleteLanguage = (id: number | undefined) => {
    deleteLanguage(id as number, Number(resumeId))
  }

  const onEditLanguage = (language: IResumeLanguage) => {
    setOpen(!open)
    setLanguage(language)
  }

  const editLanguage = (
    <CvUpdateLanguage setOpen={setOpen} language={language as IResumeLanguage} cvId={Number(resumeId)} />
  )

  return (
    <div className="cv__form">
      <UsersCv resumeId={resume?.id} userId={resume?.user_id} />
      <div className="cv__form_inner">
        <h4 className="cv__form_title">Languages</h4>
      </div>
      <Box sx={{ display: 'flex', width: '95%', gap: '10px' }}>
        <div className="leftSide_section">
          <Box sx={{ width: '60%' }}>
            <CvAddLanguage cvId={Number(resumeId)} />
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            width: '50%',
            minHeight: '600px',
            flexDirection: 'column',
            marginBottom: 'auto',
            gap: '15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              maxHeight: '500px',
              flexDirection: 'column',
              gap: '15px',
              overflowY: 'auto',
              padding: '15px',
              marginBottom: '30px',
            }}
          >
            {resume?.languages?.map((language) => (
              <Box
                key={language.id}
                sx={{
                  padding: '15px',
                  width: '100%',
                  maxHeight: '200px',
                  border: '1px solid #dee0df',
                  borderRadius: 2,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ fontSize: '17', fontWeight: '500' }}>{language.name}</Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton onClick={() => onEditLanguage(language)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteLanguage(language.id)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ fontSize: '13px' }}>{language.level}</Box>
              </Box>
            ))}
          </Box>
          {open && (
            <Modal title={'Edit languages'} component={editLanguage} open={open} handleClose={() => setOpen(false)} />
          )}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', marginTop: 'auto' }}>
            <CvNavButtons resumeId={resumeId} nextPath={nextPath} prevPath={prevPath} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CvLanguages
