import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'
import { createResume } from '../../services/API/admin/resume'
import { addResumeFailure, addResumeSuccess } from '../../store/resumesSlice'
import { useFetchUserCvs } from './useFetchUserCvs'

export const useCreateResume = () => {
  const fetchUserCvsHook = useFetchUserCvs()
  const dispatch = useDispatch()

  return (newCv: FormData, userId: number) => {
    createResume(newCv)
      .then((response) => {
        dispatch(addResumeSuccess(response.data))
        successNotify('Contacts added')
        fetchUserCvsHook(userId)
      })
      .catch((error) => {
        dispatch(addResumeFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
