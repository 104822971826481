import { useDispatch } from 'react-redux'

import { deleteProject } from '../../services/API/admin/projects'
import { removeProject, removeProjectFailure, removeProjectSuccess } from '../../store/projectsSlice'
import { NewProject } from '../../types'
import successNotify from '../../helpers/successNotify'
import dangerNotify from '../../helpers/dangerNotify'
import { useGetProjects, useGetUserProjects } from './useGetProjects'
import { getProjectParams } from '../../constants'

export const useDeleteProject = () => {
  const dispatch = useDispatch()
  const getUserProjectsHook = useGetUserProjects()
  const getProjectHook = useGetProjects()

  return (project: NewProject, page: number) => {
    dispatch(removeProject())
    deleteProject(project)
      .then(() => {
        dispatch(removeProjectSuccess(project.id))
        getProjectHook(
          getProjectParams.name,
          getProjectParams.technology,
          getProjectParams.company,
          getProjectParams.fromScratch,
          getProjectParams.application,
          getProjectParams.manager,
          page
        )
        getUserProjectsHook()
        successNotify('Project deleted')
      })
      .catch((error) => {
        dispatch(removeProjectFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
