import React from 'react'
import { UseFormRegister } from 'react-hook-form'

import ReactInput from '../../reusable/ReactInput/ReactInput'
import { IEditProjectInputs } from '../EditProject/EditProject'
import { IProjectInputs } from '../CreateProject/CreateProject'

interface Props {
    register: UseFormRegister<IEditProjectInputs> | UseFormRegister<IProjectInputs>
}

const MobileEditProjectLinks: React.FC<Props> = ({
  register,
}) => {

  return (
    <>
      <div className="project-profile_mobile-links-wrap">
        <div className="mrg-bottm10px">
          <ReactInput 
            type="text" label="Project URL" register={register} registerControl="url" />
        </div>
        <div className="mrg-bottm10px">
          <ReactInput 
            type="text" label="Design URL" register={register} registerControl="designs" />
        </div>
        <div className="mrg-bottm10px">
          <ReactInput type="text" label="Specs URL" register={register} registerControl="specs" />
        </div>
        <div className="mrg-bottm10px">
          <ReactInput type="text" label="Milestones URL" register={register} registerControl="milestones" />
        </div>
      </div>
    
    </>
  )
}

export default MobileEditProjectLinks
