import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import DashboardUsersTable from '../../../components/dashboard/desktop/DashboardUsersTable'
import { IDashboardTableProps } from './hiredUsersTable'
import { useFetchDashboardUsers } from '../../../hooks/user/useFetchDashboardUsers'
import { User } from '../../../types'
import { fetchProfileTechnologies } from '../../../services/API/admin/profile'
import { useGetTechnologies } from '../../../hooks/Technology/useGetTecnologies'

const availableDevelopersTableTitles = ['Full name', 'Cv link', 'Tech stack', 'Experience', 'Status']

const AvailableDevelopersTable: React.FC<IDashboardTableProps> = ({ isFired, isHired, subRole, page, setPage }) => {
  const [profileTechnologies, setProfileTechnologies] = React.useState([])
  const fetchDashboardUsers = useFetchDashboardUsers()
  const getTechnologies = useGetTechnologies()
  const users =
      useSelector((state: { users: { dashboardUsersData: User[] }} ) => state.users.dashboardUsersData)
  const totalPages = useSelector((state: { users: { totalPages: number }} ) => state.users.totalPages)

  useEffect(() => {
    getTechnologies()
    fetchProfileTechnologies()
      .then((response) => {
        setProfileTechnologies(response.data)
      })
  }, [])

  useEffect(() => {
    fetchDashboardUsers(page, subRole)
  }, [page, subRole])


  return (
    <DashboardUsersTable
      tableTitles={availableDevelopersTableTitles}
      users={users}
      totalPages={totalPages}
      page={page}
      isFired={isFired}
      isHired={isHired}
      setPage={setPage}
      profileTechnologies={profileTechnologies}
      subRole={subRole}
    />
  )
}

export default AvailableDevelopersTable
