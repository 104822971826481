import API from '..'
import ROUTES from '../../../routes'
import { NewRecord } from '../../../types/NewRecord'
import { UpdatedRecord } from '../../../types/UpdatedRecord'
import { IRequest } from 'types/Request/IRequest'

export const getRecords = (
  project_id: string | undefined,
  year: string | undefined,
  month: string | undefined,
  limit?: number,
  page?: number,
) => {
  return API.get(ROUTES.RECORDS, {
    params: {
      project_id,
      year,
      month,
      limit,
      page,
    },
  })
}

export const getAdminRecords = () => {
  return API.get(ROUTES.ADMIN_RECORDS)
}

export const getAdminUserRecords = (
  user_id: number | undefined,
  year: string | undefined,
  month: string | undefined,
  project_id: number | undefined,
  limit?: number,
  page?: number,
) => {
  return API.get(ROUTES.ADMIN_RECORDS, {
    params: { user_id, year, month, project_id, limit, page },
  })
}

export const createRecord = (record: NewRecord) => {
  return API.post(ROUTES.RECORDS, {
    record,
  })
}

export const createAdminRecord = (record: NewRecord) => {
  return API.post(ROUTES.ADMIN_RECORDS, {
    record,
  })
}

export const createRecords = (request: IRequest) => {
  return API.post(ROUTES.INTERVAL_RECORDS, {
    request,
  })
}

export const updateRecord = (record: UpdatedRecord) => {
  return API.put(ROUTES.RECORDS + `/${record.id}`, {
    record,
  })
}

export const updateAdminRecord = (record: UpdatedRecord) => {
  return API.put(ROUTES.ADMIN_RECORDS + `/${record.id}`, {
    record,
  })
}

export const deleteRecord = (id: number) => {
  return API.delete(ROUTES.RECORDS + `/${id}`)
}

export const deleteRecords = (request_id: number) => {
  return API.delete(ROUTES.INTERVAL_RECORDS + `/${request_id}`)
}
