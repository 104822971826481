import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { changeCvProjectsSorting } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'

export const useChangeCvProjectsSorting = () => {
  const getUserCv = useGetUserCv()
    
  return (
    prevCvProjectId: number,
    lastCvProjectId: number,
    prevProjectPosition: number,
    lastProjectPosition: number,
    resumeId: number
  ) => {
    changeCvProjectsSorting(prevCvProjectId, lastCvProjectId, prevProjectPosition, lastProjectPosition)
      .then(() => {
        getUserCv(resumeId)
        successNotify('Sorting changed!')
      })
      .catch((error) => dangerNotify(error.response.data.message[0]))
  }
}
