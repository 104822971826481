import * as React from 'react'
import { useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import enLocale from 'date-fns/locale/uk'
import { useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { Category } from 'types/Category'
import { ReadyRecord } from 'types/ReadyRecord'
import { Project } from 'types/Project'
import { useUpdateAdminRecord, useUpdateRecord } from 'hooks/record/useUpdateRecord'
import ReactInput from '../ReactInput'
import ReactForm from '../ReactForm'
import { Contract, User } from '../../../types'
import { renderProject } from '../../../helpers/renderName'
import { minDateDisable } from 'helpers/enableCurrentWeek'
import { IOptions } from 'types/ISelectOptions'
import { useAuth } from 'context/auth'
import CustomButton from 'components/custom/CustomButton/CustomButton'
import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import DefaultSelect from 'components/custom/DefaultSelect/DefaultSelect'
import { Modify } from 'helpers/ModifyInterface'
import { UpdatedRecord } from '../../../types/UpdatedRecord'
import { useGetAvailableContracts, useGetUserAvailableContracts } from 'hooks/contract/useGetContracts'
import { isAdmin, isManager } from 'helpers/accessHelpers'
import { fetchAvailableContractsSuccess } from 'store/availableContractsSlice'
import { webRoutes } from '../../../lib'

export type IUpdatedRecord = Modify<
  UpdatedRecord,
  {
    project_id: IOptions
    category_id: IOptions
    date: string
  }
>

interface Props {
  categories: Category[]
  closeModal: () => void
  record: ReadyRecord
  currentUser: User | undefined
  projects: Project[]
  chosenUser: User | undefined
  userId: IOptions
  setUserId: React.Dispatch<React.SetStateAction<IOptions | undefined>>
}

const EditForm: React.FC<Props> = ({ categories, closeModal, record, projects, userId, setUserId }) => {
  const { currentUser } = useAuth()
  const location = useLocation()
  const dispatch = useDispatch()
  const adminLocation = location.pathname === webRoutes.SUBMIT_HOURS_ADMIN
  const mainAdminPage = location.pathname === webRoutes.ADMIN_MAIN
  const updateRecordHook = isManager(currentUser) && adminLocation ? useUpdateAdminRecord() : useUpdateRecord()
  const getUsersAvailableContractsHook = useGetUserAvailableContracts()
  const getUserAvailableContractHook = useGetAvailableContracts()

  React.useEffect(() => {
    if (adminLocation || (mainAdminPage && isManager(currentUser)) || isAdmin(currentUser)) {
      dispatch(fetchAvailableContractsSuccess([]))
      location.pathname === webRoutes.SUBMIT_HOURS_ADMIN
        ? getUsersAvailableContractsHook(record?.user_id)
        : getUserAvailableContractHook(userId)
    }
  }, [])

  const availableContracts = useSelector(
    (state: { availableContracts: { indexData: Contract[] } }) => state.availableContracts.indexData,
  )

  const [currentDate] = useState<Date | null>(new Date())
  const [date, setDate] = useState<string>(record?.date)

  const userCategories: IOptions[] = categories?.map((category: Category) => ({
    value: category.id,
    label: category.name,
  }))

  const convertedProjects: IOptions[] = availableContracts?.map((contract: Contract) => ({
    value: contract.project_id as number,
    label: renderProject(contract?.project_id, projects) as string,
  })) as IOptions[]

  const selectedCategory = userCategories?.find((category) => category.label == record.category)
  const selectedProject = convertedProjects?.find((project) => project.label == record.project)

  const { register, handleSubmit, control, setValue } = useForm<IUpdatedRecord>({
    defaultValues: {
      category_id: selectedCategory,
      project_id: { value: NaN, label: '' },
      description: record.description,
      hours: record.hours,
      date: date,
    },
  })

  setValue('project_id', selectedProject)

  const handleChange = (newValue: string) => {
    setDate(newValue)
  }

  const submitForm: SubmitHandler<IUpdatedRecord> = async (data) => {
    const selectedContract = availableContracts.find((contract: Contract) => {
      return data.project_id?.value == contract.project_id
    })

    const params = {
      id: record.id,
      project_id: data?.project_id.value,
      category_id: data?.category_id?.value,
      description: data.description,
      job_status_id: selectedContract?.job_status_id,
      hours: data.hours,
      date: date,
      user_id: record.user_id,
      record_type: record.record_type,
    }

    await updateRecordHook(params)
    location.pathname === webRoutes.SUBMIT_HOURS_ADMIN
      ? getUsersAvailableContractsHook(userId)
      : getUserAvailableContractHook(userId)
    adminLocation && setUserId(null)
    closeModal()
  }

  const closeEditModal = () => {
    location.pathname === webRoutes.SUBMIT_HOURS_ADMIN
      ? getUsersAvailableContractsHook(userId)
      : getUserAvailableContractHook(userId)
    closeModal()
  }

  const minDate = isManager(currentUser) ? undefined : !adminLocation && minDateDisable(currentDate)

  return (
    <ReactForm onSubmit={handleSubmit(submitForm)}>
      <div className="update-form__form-group">
        <Controller
          name="project_id"
          control={control}
          render={({ field }) => (
            <DefaultSelect {...field} className="modal__input" placeholder="Project" options={convertedProjects} />
          )}
        />
      </div>
      <div className="update-form__form-group">
        <Controller
          name="category_id"
          control={control}
          render={({ field }) => (
            <DefaultSelect {...field} className="modal__input" placeholder="Category" options={userCategories} />
          )}
        />
      </div>
      <div className="update-form__form-group">
        <ReactInput
          className="modal__input"
          size="medium"
          type="text"
          label="Description"
          register={register}
          registerControl="description"
        />
      </div>
      <div className="update-form__form-group">
        <ReactInput
          className="modal__input"
          size="medium"
          type="text"
          label="Hours"
          register={register}
          registerControl="hours"
        />
      </div>
      <div className="update-form__form-group modal__input">
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
          <CustomDatepicker
            value={date}
            onChange={handleChange}
            minDate={minDate}
            mask={'__.__.____'}
            maxDate={new Date()}
            disableClearButton={true}
          />
        </LocalizationProvider>
      </div>
      <Box sx={{ display: 'flex', gap: '1rem', marginTop: '40px' }}>
        <CustomButton variant="outlined" onClick={() => closeEditModal()} text="Cancel" />
        <CustomButton type="submit" text="Edit" />
      </Box>
    </ReactForm>
  )
}

export default React.memo(EditForm)
