import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import { useSelector } from 'react-redux'

import { tableCellStyles, tableHeadStyles } from '../../../utilts/styles'
import { renderUserName } from '../../../helpers/renderName'
import DashboardNavigation from '../../../components/dashboard/desktop/dashboardNavigation'
import { Project, User } from '../../../types'
import Loader from '../../../components/reusable/Loader'
import { ManagersProjectsAndDevelopers } from '../index'

export type DashboardManagerListProps = {
    usersNames: User[]
    developers: User[]
    managersContracts: User[]
    projects: Project[]
}

const DesktopManagersTable: React.FC<DashboardManagerListProps> = ({
  usersNames,
  developers,
  managersContracts,
  projects
}) => {
  let numbering = 1
  const isUsersLoading = useSelector((state: { users: { isLoading: boolean } }) => state.users.isLoading)

  return (
    <Container fluid="xxl">
      <div className="dashboard_main__tabs">
        <DashboardNavigation />
      </div>
      <div className="dashboard_main__tableName">
        <h4>Managers List</h4>
      </div>
      {isUsersLoading ? <Loader /> :
        <TableContainer >
          <Table style={{ marginBottom: '2%' }}>
            <TableHead className="requests-table__title">
              <TableRow>
                <TableCell align="left" sx={tableHeadStyles}>
                  #
                </TableCell>
                <TableCell align="left" sx={tableHeadStyles}>
                  Manager Name
                </TableCell>
                <TableCell  align="left" sx={tableHeadStyles}>
                  <span className="managers_table-head">Projects</span>
                  <span>Developers</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {managersContracts?.map(manager => {
                return <TableRow key={manager.id}>
                  <TableCell align="left" sx={tableCellStyles} style={{ width: '5%', verticalAlign: 'top' }}>
                    {numbering++}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles} style={{ width: '35%', verticalAlign: 'top' }}>
                    <Link to={`/users/${manager.id}`}>
                      {renderUserName(manager.id as number, usersNames)}
                    </Link>
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles} style={{ width: '60%', marginBottom: '15px' }}>
                    <ManagersProjectsAndDevelopers
                      manager={manager}
                      developers={developers}
                      usersNames={usersNames}
                      projects={projects}
                    />
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Container>
  )
}

export default DesktopManagersTable
