import { IconButton, Menu, MenuItem } from '@mui/material'
import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useLocation } from 'react-router-dom'

import { Category, Project, User } from 'types'
import { ReadyRecord } from 'types/ReadyRecord'
import { useDeleteAdminRecord, useDeleteRecord } from 'hooks/record/useDeleteRecord'
import ModalContainer from 'containers/ModalContainer'
import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import { StatusType } from 'enums/Types'
import { webRoutes } from '../../../lib'
import { IOptions } from '../../../types/ISelectOptions'
import { isManager } from '../../../helpers/accessHelpers'

interface Props {
  records: ReadyRecord[]
  record: ReadyRecord
  categories: Category[]
  currentUser: User
  projects: Project[]
  setUserId: React.Dispatch<React.SetStateAction<IOptions | undefined>>
}

const RecordsCard: React.FC<Props> = ({ categories, currentUser, projects, record, setUserId }) => {
  const location = useLocation()
  const adminLocation = location.pathname === webRoutes.SUBMIT_HOURS_ADMIN
  const deleteRecordHook = isManager(currentUser) && adminLocation ? useDeleteAdminRecord() : useDeleteRecord()
  const isSubmitHoursPage = location.pathname === webRoutes.SUBMIT_HOURS_ADMIN ||
      location.pathname === webRoutes.SUBMIT_HOURS
  const isSubmitHoursForUsersPage = location.pathname === webRoutes.SUBMIT_HOURS_ADMIN
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  
  return (
    <>
      {isSubmitHoursForUsersPage &&       
        <h5 className="mobile-card__user_name">{record.user}</h5>
      }
      <div className="mobile-card__container">
        <div className="mobile-card__container_header">
          <h6>{record.project}</h6>
          {record?.record_type === StatusType.REGULAR ? <IconButton onClick={handleClick}>
            <MoreVertIcon/>
          </IconButton>
            : 
            ''
          }
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {isSubmitHoursPage && (record.record_type === StatusType.REGULAR && (
              <ModalContainer
                setUserId={setUserId}
                record={record}
                categories={categories}
                currentUser={currentUser}
                projects={projects} />
            ))}
            <MenuItem disabled={!record.id} onClick={() => deleteRecordHook(record.id)}>Delete</MenuItem>
          </Menu>
        </div>
        <div className="mobile-card__container_body">
          <h6>Category: {record.category}</h6>
          <h6>Date: {dateFormatDDMMYY(record.date)}</h6>
          <h6>Hours: {record.hours}</h6>
          <h6>Description: {record.description}</h6>
        </div>
      </div>
    </>
  )
}

export default RecordsCard
