import { Project } from '../types/Project'
import { Category } from '../types/Category'
import { Record } from '../types/Record'
import { ReadyRecord } from '../types/ReadyRecord'
import { User } from 'types'

function newRecordFromId(
  projects: Project[],
  categories: Category[],
  filteredRecords: Record[],
  users: User[]
): ReadyRecord[] {
  const recordFromId = []

  for (let i = 0; i < filteredRecords.length; i++) {
    const findProject = projects.find((project) => project.id === +filteredRecords[i].project_id)
    const findCategory = categories.find((category) => category.id === +filteredRecords[i].category_id)
    const findUser = users.find((user) => user.id === +filteredRecords[i].user_id)

    recordFromId.push({
      user: findUser ? findUser.full_name : '',
      user_id: findUser ? findUser.id : '',
      project: findProject ? findProject.name : '',
      category: findCategory ? findCategory.name : '',
      description: filteredRecords[i].description,
      hours: filteredRecords[i].hours,
      id: filteredRecords[i].id,
      date: filteredRecords[i].date,
      record_type: filteredRecords[i].record_type
    })
  }

  return recordFromId
}

export default newRecordFromId
