import React from 'react'
import { useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import UserReportFilters from 'components/user/UserReportFilters'
import { Project, Record } from 'types'
import { Pages } from 'enums/StartPage'
import { getRecordsParams } from '../../constants'
import { useGetRecords } from 'hooks/record/useGetRecords'
import { dateFormatMM, dateFormatYYYY } from 'helpers/displayingDateFormat'
import { RecordLimits } from 'enums/RecordsEnums'
import { IOptions } from 'types/ISelectOptions'

type Props = {
  projects: Project[]
  records: Record[]
  date: Date | undefined
  setDate: React.Dispatch<React.SetStateAction<Date | null>>
  setProject: React.Dispatch<React.SetStateAction<IOptions | null>>
  project: IOptions | undefined
  closeModal: () => void
  setPage: React.Dispatch<React.SetStateAction<Pages>>
}

const MobileReportFilters: React.FC<Props> = ({ 
  closeModal,
  projects,
  records,
  date,
  setProject,
  project,
  setDate,
  setPage
}) => {
  const recordsCount = useSelector((state: { records: { count: number } }) => state.records.count)
  const getRecordsHook = useGetRecords()
  const closeModalForm = () => {
    setDate(new Date())
    setProject(null)
    getRecordsHook(
      getRecordsParams.project_id, 
      dateFormatYYYY(new Date()), 
      dateFormatMM(new Date()), 
      RecordLimits.USER_RECORD_TABLE_LIMIT, Pages.START_PAGE)
    closeModal()
  }

  return (
    <>
      <div className="filters-modal-wrapper">
        <div className="filters-modal-navbar">
          <div onClick={closeModalForm} className="filters-modal-back-arrow">
            <ArrowBackIcon />
          </div>
          <p className="filters-modal-navbar-text">Filter</p>
        </div>
        <div className="filters-modal-body">
          <div className="filters-modal-text-wrapper user-report__wrapper">
            <UserReportFilters 
              project={project}
              projects={projects} 
              records={records}
              date={date}
              setProject={setProject}
              setDate={setDate}
              setPage={setPage}
              closeModal={closeModal}/>
          </div>
          <div className="filters-modal-text-wrapper"></div>
          <div className="filters-modal-button-wrapper">
            <div onClick={closeModal} className="filters-modal-button">
              <p className="filters-modal-button-text">Show results ({recordsCount})</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(MobileReportFilters)
