import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { useCreateResumeSkill } from '../../../../../hooks/resume/skills/useCreateResumeSkill'
import { MIN_INPUTS_ELEMENTS } from '../../../../../constants'
import { IResumeSkill } from '../../../../../types/Resume/IResumeSkill'
import CvSkillSetForm from './CvSkillSetForm'
import AddFormItemButton from '../FormButtons/AddFormItemButton'
import { IResume } from '../../../../../types/Resume/IResume'

export interface ISkillSetInputs {
  skill_set: string
}

interface IProps {
  cvId: number
  cv: IResume
}

const CvAddSkillSet: React.FC<IProps> = ({ cvId, cv }) => {
  const [inputFields, setInputFields] = useState([{ value: '' }])
  const isDisabled = inputFields.length <= MIN_INPUTS_ELEMENTS
  const createSkillSet = useCreateResumeSkill()
  const cvSkillSetPositions = cv.skills?.map(s => s.position)
  const maxPosition = cvSkillSetPositions && Math.max(...cvSkillSetPositions)

  const { register, handleSubmit, reset } = useForm<ISkillSetInputs>({
    defaultValues: {
      skill_set: ''
    }
  })

  const onSubmit = (data: ISkillSetInputs) => {
    const skills = inputFields.map(({ value }) => value)
    const newSkillSet = {
      skill_set: data.skill_set,
      resume_id: cvId,
      skills: skills,
      position: maxPosition && maxPosition + 1
    }

    createSkillSet(newSkillSet as IResumeSkill, cvId)
    reset()
    setInputFields(  [{ value: '' }])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvSkillSetForm
        inputFields={inputFields}
        isDisabled={isDisabled}
        setInputFields={setInputFields}
        register={register}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <AddFormItemButton isDisabled={false}/>
      </Box>
    </form>
  )
}

export default CvAddSkillSet
