import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Pagination from '@mui/material/Pagination'

import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import { useUtility } from 'context/utility'
import { UserProfileTabCard } from '../components/tab-card'
import { useGetUsersContracts } from 'hooks/user/useGetUserContracts'
import Loader from 'components/reusable/Loader'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import CustomPagination from 'components/reusable/Pagination/Pagination'

const UserProfileContractLog = () => {
  const userId = Number(useParams().userId)
  const { width } = useUtility()
  const getContracts = useGetUsersContracts()

  const {
    indexLogsUserData: { paginate_contracts: contracts, pages: totalPages },
    isLoading,
  } = useSelector(({ contracts }) => contracts)

  const [page, setPage] = useState(1)

  useEffect(() => {
    getContracts(userId, page)
  }, [page])

  if (isLoading) return <Loader />

  return (
    <>
      {width < 991.98 ? (
        <>
          <h3 className="user-profile__title">Contract log</h3>

          {contracts.map((c) => (
            <UserProfileTabCard key={c.id + 'CONTRACT'} item={c} />
          ))}
        </>
      ) : (
        <table className="user-profile__table contract-log">
          <thead className="user-profile__table-thead">
            <tr>
              <th className="user-profile__table-th">Project</th>
              <th className="user-profile__table-th">Job Status</th>
              <th className="user-profile__table-th">Manager</th>
              <th className="user-profile__table-th">Start Date</th>
              <th className="user-profile__table-th">End Date</th>
            </tr>
          </thead>
          <tbody>
            {contracts.map((c) => (
              <tr className="user-profile__table-tr" key={c.id + 'USERCONTRACT'}>
                <td className="user-profile__table-td contract-name">
                  <Link to={`/project/${c.project_id}`}>{c.project_name}</Link>
                </td>
                <td
                  className={`user-profile__table-td${
                    c.status ? ` contract-${c.status.replace(/\s/g, '-').toLowerCase()}` : ''
                  }`}
                >
                  {c.status}
                </td>
                <td className="user-profile__table-td">{c.admin_name}</td>
                <td className="user-profile__table-td">{c.start_date && dateFormatDDMMYY(c.start_date)}</td>
                <td className="user-profile__table-td">{c.end_date && dateFormatDDMMYY(c.start_date)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <CustomPagination totalPages={totalPages} page={page} setPage={setPage} />
    </>
  )
}

export default memo(UserProfileContractLog)
