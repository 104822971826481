import React from 'react'
import { Link } from 'react-router-dom'

import { dateFormatDDMM } from '../../helpers/displayingDateFormat'
import { notificationTimestamp } from '../../helpers/notificationsTimestamps'
import { Notification, Project, User } from '../../types'
import { renderProject, renderUser, renderUserName } from '../../helpers/renderName'

interface IProps {
    notification: Notification
    projectsNames: Project[]
    usersNames: User[]
    managerOrAdmin: boolean
}

const NotificationsList: React.FC<IProps> = ({
  notification,
  projectsNames,
  usersNames,
  managerOrAdmin
}) => {
  const isRequestStartDate = notification.request_start_date && !notification.request_end_date
  const isRequestEndDate = notification.request_end_date && notification.request_start_date
  const isProjectStarted = notification.project_id
  const projectManager = projectsNames?.find(project => project.id === notification.project_id)

  return (
    <div className= "notifications__item-inner">
      <div className="notification__data">
        <div className="notification__user">
          {notification.message_owner && usersNames?.length &&
            <Link className="notification__link" to={`/users/${notification?.message_owner}`}>
              {renderUserName(notification?.message_owner, usersNames)}
            </Link>
          }
          {notification.message_owner &&
            <div className="notification__message">
              {notificationTimestamp(notification.created_at)}
            </div>
          }
        </div>
        <span className="notification__message">
          {notification.message} {' '} {managerOrAdmin && notification.message_owner && notification.request_type}
        </span>
        {isRequestStartDate ?
          <span className="notification__message">
            {' '} ({dateFormatDDMM(notification.request_start_date)})
          </span>  :
          isRequestEndDate ?
            <span>
              ({dateFormatDDMM(notification.request_start_date)}) - ({dateFormatDDMM(notification.request_end_date)})
            </span>
            : isProjectStarted &&
            <>
              <Link className="notification__link" to={`/projects/${notification.project_id}`}>
                {renderProject(notification.project_id, projectsNames )}
              </Link>, {' '}
              <span className="notification__message">
                manager
              </span> {' '}
              <Link
                className="notification__link"
                state={{ projectId: notification.project_id, managerId: projectManager?.manager_id }}
                to={'/manage-users' }
              >
                {renderUser(projectManager?.manager_id, usersNames)}
              </Link>
            </>
        }
      </div>
      {!notification.message_owner &&
        <div className="notification__timestamp">
          <span className="notification__message">
            {notificationTimestamp(notification.created_at)}
          </span>
        </div>}
    </div>
  )
}

export default NotificationsList
