import React, { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import countryList from 'react-select-country-list'
import Box from '@mui/material/Box'

import { useCreateResumeExperience } from '../../../../../hooks/resume/experience/useCreateResumeExperience'
import { IOptions } from '../../../../../types/ISelectOptions'
import { Modify } from '../../../../../helpers/ModifyInterface'
import { IResumeExperience } from '../../../../../types/Resume/IResumeExperience'
import { MIN_INPUTS_ELEMENTS } from '../../../../../constants'
import CvExperienceCompanyForm from './CvExperienceCompanyForm'
import AddFormItemButton from '../FormButtons/AddFormItemButton'
import { IResume } from '../../../../../types/Resume/IResume'

export type IEducationOmitedOption = Modify<IOptions, { value: string }>

export interface IEducationCompanyInputs {
  job_title: string
  company: string
  city: string
  description: string
  job_started_on: Date
  job_ended_on: Date
}

interface IProps {
  resumeId: number
  cv: IResume
}

const CvAddExperienceCompany: React.FC<IProps> = ({ resumeId, cv }) => {
  const [checked, setChecked] = useState(false)
  const [country, setCountry] = useState({ label: 'Ukraine', value: 'UA' })
  const [inputFields, setInputFields] = useState([{ value: '' }])
  const isDisabled = inputFields.length <= MIN_INPUTS_ELEMENTS
  const createResumeExperience = useCreateResumeExperience()
  const options = useMemo(() => countryList().getData(), [])
  const cvExperiencesPositions = cv.experiences?.map(e => e.position)
  const maxPosition = cvExperiencesPositions && Math.max(...cvExperiencesPositions)

  const { register, handleSubmit, control, reset } = useForm<IEducationCompanyInputs>(
    {
      defaultValues: {
        job_title: '',
        company: '',
        city: '',
        description: '',
        job_started_on: new Date(),
        job_ended_on: new Date(),
      }
    })

  const changeHandler = (value: IEducationOmitedOption) => {
    setCountry(value)
  }

  const onSubmit: SubmitHandler<IEducationCompanyInputs> = (data) => {
    const location = `${data.city},${country.label}`
    const experienceResponsibilities = inputFields.map(({ value }) => value)
    const resume_experience = {
      job_title: data.job_title,
      company: data.company,
      location: location,
      description: data.description,
      job_started_on: data.job_started_on,
      job_ended_on: !checked ? data.job_ended_on : undefined,
      ongoing: checked,
      responsibilities: experienceResponsibilities,
      resume_id: resumeId,
      position: maxPosition && maxPosition + 1
    }

    createResumeExperience(resume_experience as IResumeExperience, resumeId)
    reset()
    setChecked(false)
    setInputFields([{ value: '' }])
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvExperienceCompanyForm
        inputFields={inputFields}
        setInputFields={setInputFields}
        register={register}
        options={options}
        changeHandler={changeHandler}
        country={country}
        control={control}
        checked={checked}
        setChecked={setChecked}
        isDisabled={isDisabled}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <AddFormItemButton isDisabled={false}/>
      </Box>
    </form>
  )
}

export default CvAddExperienceCompany
