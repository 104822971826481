import React from 'react'
import { Link } from 'react-router-dom'

import { renderProject, renderUserName } from '../../../helpers/renderName'
import { BENCH_PROJECT } from '../../../constants'
import { Project, User } from '../../../types'

type Props = {
  manager: User
  developers: User[] | undefined
  usersNames: User[]
  projects: Project[]
}

const ManagersProjectsAndDevelopers: React.FC<Props> = ({
  manager,
  developers,
  usersNames,
  projects
}) => {
  return (
    <>
      {manager?.available_contracts.map(contract =>
        renderProject(contract.project_id, projects) !== BENCH_PROJECT && (
          <div key={contract.id} className="managers_table-projectsList">
            <div className="managers_table-projectsItem">
              <Link to={`/projects/${contract.project_id}`}>
                {renderProject(contract.project_id, projects)}
              </Link>
            </div>
            <div className="managers_table-teamList">
              {developers?.map(developer => developer.available_contracts.some(item =>
                item.project_id === contract.project_id) &&
                  <div key={developer.id} className="managers_table-teamItem">
                    <Link to={`/users/${developer.id}`}>
                      {renderUserName(developer.id as number, usersNames)}
                    </Link>
                  </div>
              )}
            </div>
          </div>
        ))
      }
    </>
  )
}

export default ManagersProjectsAndDevelopers
