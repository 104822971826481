import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineDelete } from 'react-icons/all'

import { useAuth } from 'context/auth'
import { Status } from 'enums/RequestEnums'
import { tableCellStyles, tableHeadStyles } from 'utilts/styles'
import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import CustomPagination from 'components/reusable/Pagination/Pagination'
import { leaveTypesFormatted, paidLeaveTypes, tabDayDate, tabMonthDate } from '../../constants'
import { useGetRequests } from '../../hooks/request/useGetRequests'
import { isMobile } from 'react-device-detect'
import { RequestCard } from './RequestCard'
import { fileNameFromUrl } from 'helpers/findNameOfFile'
import { FileIcon } from 'icons'
import Modal from 'components/reusable/Modal'

interface Props {
  page: number
  setPage: Dispatch<SetStateAction<number>>
  onDeleteRequest: (id: number) => void
  requestPages: number
  alignment: string
  filterDate: Date | null
  monthDate: Date
  alignmentType: Date | string
  handleOpen: () => void
}

const tableHeaders = ['Type', 'Start Date', 'End Date', 'Description', 'Attached Photo', 'Status', 'Options']

const UserRequestsTable: React.FC<Props> = ({
  page,
  setPage,
  onDeleteRequest,
  requestPages,
  filterDate,
  monthDate,
  alignment,
  alignmentType,
  handleOpen,
}) => {
  const { currentUser } = useAuth()
  const getUsersRequestsHook = useGetRequests()
  const requests = useSelector((state) => state.requests.indexData)

  const [attachedPhoto, setAttachedPhoto] = useState<string | undefined>()

  useEffect(() => {
    getUsersRequestsHook(page, tabDayDate(alignment, filterDate), tabMonthDate(alignment, monthDate))
  }, [page, filterDate, monthDate, alignmentType, alignment])

  return (
    <>
      <Modal
        title="Attached Photo"
        component={<img className="requests-img" src={attachedPhoto} alt="Attached photo" />}
        open={!!attachedPhoto}
        handleClose={() => setAttachedPhoto(undefined)}
      />

      {isMobile ? (
        <div className="requests-mobile-wrapper">
          <div className="mobile-button" onClick={handleOpen}>
            +
          </div>
          {requests.length ? (
            requests?.map((request) => (
              <RequestCard
                key={'REQUEST' + request.id}
                request={request}
                onDelete={onDeleteRequest}
                onShowFile={setAttachedPhoto}
              />
            ))
          ) : (
            <h3>Requests not found</h3>
          )}
          <CustomPagination totalPages={requestPages} page={page} setPage={setPage} />
        </div>
      ) : (
        <Box sx={{ width: '100%', paddingBottom: '20px' }}>
          <div className="requests-table__head">
            <h4>Requests List</h4>
          </div>

          {requests.length ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead className="requests-table__title">
                  <TableRow>
                    {tableHeaders.map((header) => (
                      <TableCell align="left" sx={tableHeadStyles} key={header}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requests?.map((request) => {
                    const isPending = String(request.status) === Status.PENDING

                    return (
                      <TableRow key={request.id} className="requests-table__style">
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '20%' }}>
                          {request.request_type === 'paid_leave'
                            ? paidLeaveTypes[request.paid_leave_type as keyof typeof paidLeaveTypes]
                            : leaveTypesFormatted[request.request_type]}
                        </TableCell>
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '20%' }}>
                          {dateFormatDDMMYY(request.start_date)}
                        </TableCell>
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '20%' }}>
                          {request.end_date && dateFormatDDMMYY(request.end_date)}
                        </TableCell>
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '25%' }}>
                          {request.note}
                        </TableCell>
                        {request.attached_file ? (
                          <TableCell
                            className="requests-img-modal"
                            align="left"
                            sx={tableCellStyles}
                            style={{ width: '11%' }}
                            onClick={() => setAttachedPhoto(request.attached_file)}
                          >
                            <FileIcon /> {fileNameFromUrl(request.attached_file)}
                          </TableCell>
                        ) : (
                          <TableCell sx={tableCellStyles} style={{ width: '11%' }}></TableCell>
                        )}
                        <TableCell
                          align="left"
                          sx={tableCellStyles}
                          className="requests-desktop-label"
                          style={{ width: '15%' }}
                        >
                          {isPending ? (
                            <div className="requests-desktop-label requests__pending">Pending</div>
                          ) : String(request.status) === Status.APPROVED ? (
                            <div className="requests-desktop-label requests__approved">Approved</div>
                          ) : String(request.status) === Status.REJECTED ? (
                            <div className="requests-desktop-label requests__rejected">Rejected</div>
                          ) : null}
                        </TableCell>
                        <TableCell align="left" sx={tableCellStyles}>
                          {isPending && request.user_id === currentUser?.id && (
                            <AiOutlineDelete
                              color="#8992A6"
                              size="20"
                              cursor="pointer"
                              onClick={() => onDeleteRequest(request.id as number)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <h3 className="text-center text-muted">Requests not found</h3>
          )}

          <CustomPagination totalPages={requestPages} page={page} setPage={setPage} />
        </Box>
      )}
    </>
  )
}

export default UserRequestsTable
