import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import { fetchUserCvs } from '../../services/API/admin/resume'
import { fetchResumes, fetchResumesFailure, fetchResumesSuccess } from '../../store/resumesSlice'

export const useFetchUserCvs = () => {
  const dispatch = useDispatch()

  return (userId: number) => {
    fetchResumes()
    fetchUserCvs(userId)
      .then((response) => {
        dispatch(fetchResumesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchResumesFailure(error))
        dangerNotify('Server error: unable to download user cv\'s')
      })
  }
}
