import { ReadyRecord } from '../types/ReadyRecord'
import { Project } from '../types/Project'
import { Category } from '../types/Category'
import { Record } from '../types/Record'

export const userRecordsFilter = (filteredRecords: Record[], projects: Project[], categories: Category[]) => {
  const recordsFromId: ReadyRecord[] = []

  for (let i = 0; i < filteredRecords.length; i++) {
    const findProject = projects.find((project: Project) => project.id === +filteredRecords[i].project_id)
    const findCategory = categories.find((category: Category) => category.id === +filteredRecords[i].category_id)

    recordsFromId.push({
      project_id: findProject ? findProject.id : '',
      project: findProject ? findProject.name : '',
      category: findCategory ? findCategory.name : '',
      description: filteredRecords[i].description,
      hours: filteredRecords[i].hours,
      id: filteredRecords[i].id,
      date: filteredRecords[i].date,
      record_type: filteredRecords[i].record_type,
      user_id: filteredRecords[i].user_id
    })
  }

  return recordsFromId
}
