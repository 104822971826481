import { useDispatch } from 'react-redux'

import { fetchCategories, fetchCategoriesFailure, fetchCategoriesSuccess } from '../../store/categoriesSlice'
import { getCategories } from '../../services/API/categories'
import dangerNotify from '../../helpers/dangerNotify'

export const useGetCategories = () => {
  const dispatch = useDispatch()

  return () => [
    dispatch(fetchCategories()),
    getCategories()
      .then((response) => {
        dispatch(fetchCategoriesSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchCategoriesFailure(error))
        dangerNotify('Server error: unable to download Categories')
      }),
  ]
}
