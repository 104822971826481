import React, { Dispatch, SetStateAction } from 'react'
import IconButton from '@mui/material/IconButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Box from '@mui/material/Box'

import { addFields, handleFormChange, removeFields } from './addInputItem'


interface IProps {
    inputFields: {value: string}[]
    isDisabled: boolean
    setInputFields: Dispatch<SetStateAction<{ value: string }[]>>
    input: { value: string; }
    index: number
    placeholder: string
}

const AddInputField: React.FC<IProps> = ({
  inputFields,
  isDisabled,
  setInputFields,
  input,
  index,
  placeholder
}) => {
  return (
    <div className="added_div">
      <input
        className="added_input"
        name="value"
        placeholder={placeholder}
        value={input.value}
        onChange={event => handleFormChange(index, event, inputFields, setInputFields)}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => removeFields(index, inputFields, setInputFields)} disabled={isDisabled}>
          <DeleteOutlineIcon />
        </IconButton>
        {index == Math.max(inputFields.length - 1) &&
          <IconButton onClick={() => addFields('value', inputFields, setInputFields)}>
            <AddCircleOutlineIcon />
          </IconButton>
        }
      </Box>
    </div>
  )
}

export default AddInputField
