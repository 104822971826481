import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import countryList from 'react-select-country-list'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'

import { useUpdateContactsData } from '../../../../../hooks/resume/useUpdateContactsData'
import { User } from '../../../../../types'
import EditFormButtons from '../FormButtons/EditFormButtons'
import { IResume } from '../../../../../types/Resume/IResume'
import CvContactsDataForm from './CvContactsDataForm'
import { IContactsDataInputs, IContactsDataOmitedOption } from './CvAddContactsData'

interface Props {
  setOpen: (value: boolean) => void
  usersResume: IResume | undefined
  readyFile: undefined | Blob
  setReadyFile: Dispatch<SetStateAction<undefined>>
  cvId: number
  cvIdFromUrl: number
}

const CvUpdateContactsData: React.FC<Props> = ({
  setOpen,
  usersResume,
  setReadyFile,
  readyFile,
  cvId,
  cvIdFromUrl
}) => {
  const [country, setCountry] = useState({ label: usersResume?.country })
  const [image, setImage] = useState('')
  const [cropper, setCropper] = useState()
  const [isCropped, setIsCropped] = useState(false)

  const user = useSelector((state: { users: { showData: User } }) => state.users.showData)
  const options = useMemo(() => countryList().getData(), [])
  const { register, handleSubmit } = useForm<IContactsDataInputs>(
    {
      defaultValues: {
        name: usersResume?.name ? usersResume.name : '',
        role: usersResume?.role ? usersResume.role : '',
        city: usersResume?.city ? usersResume.city : '',
        github: usersResume?.github_url ? usersResume.github_url : '',
        summary: usersResume?.summary ? usersResume.summary : '',
      }
    })

  const onSelectHandler = (value: IContactsDataOmitedOption) => {
    setCountry(value)
  }

  const updateResume = useUpdateContactsData()
  const onSubmit: SubmitHandler<IContactsDataInputs> = (data, event) => {
    event?.preventDefault()

    const resume = new FormData()
    const location = `${data.city},${country?.label?.trim()}`

    resume.append('user_id', user?.id)
    resume.append('name', data.name)
    resume.append('role', data.role )
    resume.append('github_url', data.github)
    resume.append('location', location)
    resume.append('summary', data.summary )
    image && resume.append('avatar', readyFile)

    updateResume(resume, cvId || cvIdFromUrl)
    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvContactsDataForm
        setReadyFile={setReadyFile}
        register={register}
        country={country}
        onSelectHandler={onSelectHandler}
        user={user}
        setImage={setImage}
        setCropper={setCropper}
        isCropped={isCropped}
        setIsCropped={setIsCropped}
        cropper={cropper}
        options={options}
        image={image}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <EditFormButtons setOpen={setOpen} />
      </Box>
    </form>
  )
}

export default CvUpdateContactsData
