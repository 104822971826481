import React from 'react'

const FilterIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M2.82792 6.32191C2.03585 5.41668 2.67871 4 3.88153 4H20.1185C21.3213 4 21.9642 5.41669 21.1721 6.32191L15 13.3757V21C15 21.3466 14.8206 21.6684 14.5258 21.8507C14.2309 22.0329 13.8628 22.0494 13.5528 21.8944L9.55281 19.8944C9.21402 19.725 9.00002 19.3788 9.00002 19V13.3757L2.82792 6.32191ZM5.20379 6L10.7526 12.3415C10.9121 12.5238 11 12.7578 11 13V18.382L13 19.382V13C13 12.7578 13.0879 12.5238 13.2474 12.3415L18.7963 6H5.20379Z" fill="#8992A6"/>
    </svg>
  )
}

export default FilterIcon
