import React from 'react'

const Unauthorized = () => {
  return (
    <div className="container">
      <div className="header">
        <h1>You are not authorized. Please log in.</h1>
      </div>
    </div>
  )
}

export default Unauthorized
