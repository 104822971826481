import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ArrowBackDefault } from '../../../../icons'
import './styles.scss'
import { Candidate, CandidateNames } from '../../../../types'
import CandidateFilters from '../../../candidates/CandidateFilters'
import { getLastPartOfUrl } from '../../../../helpers/urlLastPart'
import { Pages } from '../../../../enums/StartPage'
import { useFetchCandidates } from '../../../../hooks/candidate/useFetchCandidates'
import { useFetchCandidateNames } from '../../../../hooks/candidate/useFetchCandidateNames'

const CandidatesMobileFilters = ({ closeModal }) => {
  const [filterParams, setFilterParams] = useState('{}')
  const candidates = useSelector((state: { candidates: { indexData: Candidate[] } }) =>
    state.candidates.indexData)
  const candidateNames = useSelector((state: { candidates: { indexNames: CandidateNames[] } }) =>
    state.candidates.indexNames)
  const fetchCandidates = useFetchCandidates()
  const fetchCandidateNames = useFetchCandidateNames()
  const vacancyId = getLastPartOfUrl()

  useEffect(() => {
    fetchCandidateNames(vacancyId)
  }, [vacancyId])

  useEffect(() => {
    fetchCandidates(vacancyId, filterParams, Pages.START_PAGE)
  }, [filterParams])

  return (
    <>
      <div className="filters-modal-wrapper">
        <div className="filters-modal-navbar">
          <div onClick={() => closeModal()} className="filters-modal-back-arrow">
            <ArrowBackDefault />
          </div>
          <p className="filters-modal-navbar-text">Filter</p>
        </div>
        <div className="filters-modal-body">
          <div className="filters-modal-text-wrapper">
            <p className="candidates-filters-modal-text">Sorting by:</p>
            <CandidateFilters
              candidates={candidates}
              candidateNames={candidateNames}
              onSetFilterParams={setFilterParams}
            />
          </div>
          <div className="filters-modal-button-wrapper">
            <div onClick={() => closeModal()} className="filters-modal-button">
              <p className="filters-modal-button-text">Show results ({candidates.length})</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CandidatesMobileFilters
