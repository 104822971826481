export enum SubRoleType {
    CEO = 'CEO', 
    CFO = 'CFO',
    PM = 'PM',
    SALES_MANAGER = 'Sales manager',
    HR_HEAD = 'HR Head',
    PDD_HEAD = 'PDD Head',
    QA = 'QA',
    DEVELOPER = 'Developer',
    OFFICE_MANAGER = 'Office manager',
    RECRUITER = 'Recruiter',
    HR = 'HR',
    PR = 'PR',
    DEVOPS = 'DevOps',
    DESIGNER = 'Designer',
    DATA_ANALYST = 'Data analyst', 
  }
