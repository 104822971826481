import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import { createResumeCourse } from '../../../services/API/admin/resume'
import { IResumeCourse } from '../../../types/Resume/IResumeCourse'
import { useGetUserCv } from '../useGetUserCv'

export const useCreateResumeCourse = () => {
  const getUserCv = useGetUserCv()

  return (course: IResumeCourse, cvId: number) => {
    createResumeCourse(course)
      .then(() => {
        successNotify('Course added')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Please fill in the contact section'))
  }
}
