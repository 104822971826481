import React from 'react'

const NotFound = () => {
  return (
    <div className="container">
      <div className="header">
        <h1>Page Not Found</h1>
        <label>Error 404</label>
      </div>
    </div>
  )
}

export default NotFound
