import React from 'react'
import { Outlet } from 'react-router-dom'

import { useAuth } from 'context/auth'

type Props = {
  allowedSubRoles?: Array<string>
}

const SubRoleProtectedRoute: React.FC<Props> = ({ allowedSubRoles }) => {
  const { currentUser } = useAuth()

  return(
    !currentUser?.sub_roles.map(name => ({ name })).some((role) => allowedSubRoles?.includes(role.name))
      ? <div className="permission_message">You don&apos;t have permission to see this page.</div> : <Outlet /> 
  )
}

export default SubRoleProtectedRoute
