import { useDispatch } from 'react-redux'

import { fetchCustomers, fetchCustomersFailure, fetchCustomersSuccess } from '../../store/customersSlice'
import { getCustomers } from '../../services/API/admin/customers'
import dangerNotify from '../../helpers/dangerNotify'

export const useGetCustomer = () => {
  const dispatch = useDispatch()

  return (
    company: string | undefined,
    representative: string | undefined,
    project: number | undefined,
    page: number
  ) => [
    dispatch(fetchCustomers()),
    getCustomers(company, representative, project, page)
      .then((response) => {
        dispatch(fetchCustomersSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchCustomersFailure(error))
        dangerNotify('Server error: unable to download Customers')
      }),
  ]
}
