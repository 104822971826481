import React, { useState } from 'react'

import { ProjectProfile } from 'types/Manage/Project/ProjectProfile'
import DesktopClientInfoProject from '../clientInfoProject/desktopClientInfoProject'
import DesktopContractsLogsProject from '../contractsLogsProject/desktopContractsLogsProject'

const AdditionalProject: React.FC<ProjectProfile> = ({
  projectData,
  customers,
  status,
  setPage,
  page,
  totalPages,
  usersNames,
}) => {
  const [additionalTab, setAdditionalTab] = useState('client')

  return (
    <div className="project-profile_additional">
      <div className="project-profile_additional-tab-button">
        <div className={`project-profile_additional-tab 
            ${additionalTab === 'client' && 'project-profile_additional-tab-active'}`}
        onClick={() => setAdditionalTab('client')}
        >
          Client Info
        </div>
        <div className={`project-profile_additional-tab 
            ${additionalTab === 'contracts' && 'project-profile_additional-tab-active'}`}
        onClick={() => setAdditionalTab('contracts')}
        >
          Contracts Logs
        </div>
      </div>
      {additionalTab === 'client' ? 
        <DesktopClientInfoProject
          projectData={projectData} 
          customers={customers}
        />
        :      
        <DesktopContractsLogsProject
          usersNames={usersNames}
          status={status}
          setPage={setPage}
          page={page}
          totalPages={totalPages}
        />
      }
    </div>
  )
}

export default AdditionalProject
