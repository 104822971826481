import { ChangeEvent, Dispatch, SetStateAction } from 'react'


export const handleFormChange = (
  index: number,
  event: ChangeEvent<HTMLInputElement>,
  inputFields: { value: string }[],
  setInputFields: Dispatch<SetStateAction<{ value: string }[]>>
) => {
  const data = [...inputFields]
  data[index][event.target.name] = event.target.value
  setInputFields(data)
}

export const addFields = (
  value: string,
  inputFields: {value: string}[],
  setInputFields: Dispatch<SetStateAction<{ value: string }[]>>
) => {
  const newField = { value: '' }
  setInputFields([...inputFields, newField])
}

export const removeFields = (
  index: number,
  inputFields: {value: string}[],
  setInputFields: Dispatch<SetStateAction<{ value: string }[]>>
) => {
  const data = [...inputFields]
  data.splice(index, 1)
  setInputFields(data)
}
