import React from 'react'
import { Link } from 'react-router-dom'

import { renderStatus, renderUser } from 'helpers/renderName'
import { dateFormatDDMMYY } from '../../../../helpers/displayingDateFormat'
import { ProjectProfile } from 'types/Manage/Project/ProjectProfile'
import { statusColorMobile } from '../../../../helpers/statusColor'

const ManageProjectsCard: React.FC<ProjectProfile> = ({
  item,
  usersNames,
  status
}) => {
  return (
    <div className="project-profile__card-wrap">
      <div className="project-profile__card-title">
        <div className="project-profile__card-name">
          <Link to={`/users/${item.user_id}`}>{renderUser(item.user_id, usersNames)}</Link>
        </div>
        <div className={statusColorMobile(renderStatus(item.job_status_id as number,status))  }>
          {renderStatus(item.job_status_id as number,status)}  
        </div>
      </div>
      <div className="project-profile__card-additional-info">
        <div className="mrg-bottm4px">
                Start Date: {item?.start_date && dateFormatDDMMYY(item?.start_date)}
        </div>
        <div>
                End Date: {item?.end_date && dateFormatDDMMYY(item?.end_date)}
        </div>
      </div>
    </div>
  )
}

export default ManageProjectsCard
