import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { useCreateResumeLanguage } from '../../../../../hooks/resume/languages/useCreateResumeLanguage'
import { resumeLanguages, resumeLevels } from '../../../../../constants'
import { IResumeLanguage } from '../../../../../types/Resume/IResumeLanguage'
import CvLanguageForm from './CvLanguageForm'
import AddFormItemButton from '../FormButtons/AddFormItemButton'

export interface ILanguageInputs {
  language: {
      label: string
      value: string
  } | null
  level: {
      label: string
      value: string
  } | null
}

interface IProps {
    cvId: number
}

const CvAddLanguage: React.FC<IProps> = ({ cvId }) => {
  const createResumeLanguage = useCreateResumeLanguage()
    
  const { handleSubmit, reset, control } = useForm<ILanguageInputs>({
    defaultValues: {
      language: null,
      level: null
    }
  })

  const onSubmit: SubmitHandler<ILanguageInputs> = (data) => {
    const newLanguage = {
      name: data?.language?.label,
      level: data?.level?.label,
      resume_id: cvId
    }

    createResumeLanguage(newLanguage as IResumeLanguage, cvId)
    reset()
  }

  const selectLanguage = resumeLanguages.map( language => (
    {
      label: language,
      value: language
    }))
  const languagesLevels = resumeLevels.map( level => (
    {
      label: level,
      value: level
    }
  ))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvLanguageForm selectLanguage={selectLanguage} languagesLevels={languagesLevels} control={control} />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <AddFormItemButton isDisabled={false}/>
      </Box>
    </form>
  )
}

export default CvAddLanguage
