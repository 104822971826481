import { DateTime as DT } from 'luxon'
import dayjs from 'dayjs'

import { isManager } from 'helpers/accessHelpers'
import { webRoutes } from 'lib'
import { getFirstDayOfWeek } from 'helpers/enableCurrentWeek'
import { dateFormatDDMMYY, dateFormatYYMMDD } from 'helpers/displayingDateFormat'

export const minDate = (currentUser, location, currentDate) =>
  !isManager(currentUser) && location.pathname !== webRoutes.MY_REQUESTS ? getFirstDayOfWeek(currentDate) : ''

export const responseCode = {
  unauthorized: 401,
}

export const tabDayDate = (alignment, filterDate) => {
  return alignment === 'day' ? dateFormatYYMMDD(filterDate) : null
}

export const projectProfileUrl = ['Project', 'Design', 'Specs', 'Milestons']

export const tabMonthDate = (alignment, monthDate) => {
  return alignment === 'month' ? dateFormatDDMMYY(monthDate) : null
}

export const vacationTypes = (one_week, two_weeks) => {
  return [
    { value: 'one_week', label: 'One week', isDisabled: one_week },
    { value: 'two_weeks', label: 'Two weeks', isDisabled: two_weeks },
  ]
}

export const usersActiveTypes = [
  { id: 1, value: 'true', name: 'Active users' },
  { id: 2, value: 'false', name: 'Inactive users' },
]

export const WeekDay = {
  SixDays: 6,
  OneDay: 1,
  FirstDayWeek: 0,
}

export const halfDay = 0.5
export const fullDay = 1

export const availableVacation = 21
export const availableSickLeave = 8
export const availableDayOff = 5
export const availablePaidLeaves = 3

export const leaveCounters = {
  vacations: 21,
  sick_leaves: 8,
  day_offs: 5,
}

export const DateNow = dayjs(DT.now().toISODate()).format('YYYY-MM-DD')

export const contractsTableRows = ['Full Name', 'Project', 'Job Status', 'Start date', 'End date', 'Options']

export const defaultItemsInput = {
  hours: 8,
  halfDay: 4,
  workOff: 0,
}

export const holidaysUkraine = {
  '2023-01-02': 'New Year',
  '2023-01-09': 'Christmas',
  '2023-03-08': 'International Women`s Day',
  '2023-04-17': 'Easter',
  '2023-05-01': 'Day of International Workers` Solidarity',
  '2023-06-05': 'Trinity',
  '2023-06-28': 'Day of the Constitution',
  '2023-08-24': 'Independence Day',
  '2023-12-25': 'Catholic Christmas',
}

export const defaultValueLeave = 0

export const moreThan9NumberOfMonth = 9

export const applications = ['Web', 'Mobile', 'Desktop']

export const leavesTypes = {
  sick_leaves: 'Sick Leave',
  extra_sick_leaves: 'Extra Leave',
  vacations: 'Vacation',
  paid_leaves: 'Paid Leave',
  unpaid_leaves: 'Unpaid Leave',
  day_offs: 'Day Off',
  work_offs: 'Work Off',
}

export const leaveTypesFormatted = {
  full_day_sick_leave: 'Full Day Sick Leave',
  half_day_sick_leave: 'Half Day Sick Leave',
  extra_sick_leave: 'Extra Sick Leave',
  work_off: 'Work Off',
  unpaid_leave: 'Unpaid Leave',
  half_day_unpaid_leave: 'Half Day Unpaid Leave',
  vacation: 'Vacation',
  paid_leave: 'Paid Leave',
  regular: 'Regular',
  day_off: 'Day Off',
}

export const recordsTableRows = ['Project', 'Category', 'Description', 'Date']

export const projectsListTableRows = [
  '#',
  'Project',
  'Company name',
  'Tech stack',
  'From scratch',
  'Web/mobile',
  'Industry',
  'Manager',
  'Options',
]

export const customerListRows = ['Company', 'Projects', 'Representative', 'Title', 'Email', 'Address', 'Options']

export const elementsPerPage = 8

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const sickLeaveVariants = {
  fullDay: 'Full Day',
  halfDay: 'Half Day',
}

export const unpaidLeaveVariants = {
  fullDay: 'Full Day',
  halfDay: 'Half Day',
}

export const paidLeaveVariants = {
  birthday: 'Childbirth',
  wedding: 'Wedding day',
  death: 'Death in the family',
  birthdayValue: 0,
  weddingValue: 1,
  deathValue: 2,
}

export const getCustomerParams = {
  company: undefined,
  representative: undefined,
  projectId: undefined,
}

export const getProjectParams = {
  name: undefined,
  technology: undefined,
  company: undefined,
  fromScratch: undefined,
  application: undefined,
  manager: undefined,
}

export const BENCH_PROJECT = 'Bench'
export const BENCH_MANAGER = 'bench manager'
export const BENCH_MANAGER_FIRST_NAME = 'bench'
export const BENCH_MANAGER_LAST_NAME = 'manager'

export const fromScratchValues = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

export const getRecordsParams = {
  user_id: undefined,
  year: undefined,
  month: undefined,
  project_id: undefined,
}

export const getUsersParams = {
  active: 'true',
  user_id: undefined,
  project_id: undefined,
  status_id: undefined,
  manager_id: undefined,
}

export const jobStatusesNames = {
  FREE: 'Free',
  INNER_PROJECT: 'Inner Project',
  OUTSTAFF: 'Outstaff',
  OUTSOURSE: 'Outsourse',
}

export const getContractsParams = {
  userId: undefined,
  projectId: undefined,
  statusId: undefined,
}

export const typeParams = [
  { value: 0, label: 'Sick leave' },
  { value: 1, label: 'Work off' },
  { value: 2, label: 'Unpaid leave' },
  { value: 3, label: 'Vacation' },
  { value: 4, label: 'Paid leave' },
  { value: 5, label: 'Day off' },
]

export const dayTime = [
  { value: 0, label: 'Full Day' },
  { value: 1, label: 'Half Day' },
]

export const firstPage = 1

export const paidLeave = [
  { value: 0, label: 'Childbirth' },
  { value: 1, label: 'Wedding day' },
  { value: 2, label: 'Death in the family' },
]

export const paidLeaveTypes = {
  0: 'Childbirth',
  1: 'Wedding Day',
  2: 'Death in the Family',
}

export const nullableRequestParams = {
  userId: undefined,
  date: undefined,
  page: undefined,
  is_pending: false,
  id: undefined,
  pending: true,
  filter_date: undefined,
  month: undefined,
  day: null,
  firstPage: 1,
}

export const candidateTitleTable = [
  '#',
  'Full Name',
  'Contracts',
  'Experience',
  'Salary',
  'English',
  'Ping',
  'Significate',
]

export const userRoles = {
  DEFAULT: {
    name: 'Default',
    id: 'default',
  },
  MANAGER: {
    name: 'Manager',
    id: 'manager',
  },
  ADMIN: {
    name: 'Admin',
    id: 'admin',
  },
}

export const resumeLanguages = ['Ukrainian', 'English', 'Italian', 'Russian', 'French', 'German', 'Polish']
export const resumeLevels = ['Beginner', 'Elementary', 'Intermediate', 'Upper-Intermediate', 'Advanced', 'Native']

export const cvNavLinks = {
  BACK_TO_PROFILE: '',
  CONTACT_SECTION: '/cv_contact',
  EXPERIENCE: '/cv_experience',
  PORTFOLIO: '/cv_portfolio',
  SKILLS: '/cv_skills',
  LANGUAGES: '/cv_languages',
  EDUCATION: '/cv_education',
  BOOKS: '/cv_books',
  CV_PREVIEW: '/cv_preview',
  CERTIFICATES: '/cv_certificates',
  COURSES: '/cv_courses',
}

export const MIN_INPUTS_ELEMENTS = 1

export const resumeSections = [
  { name: 'Contact section', url: cvNavLinks.CONTACT_SECTION },
  { name: 'Experience', url: cvNavLinks.EXPERIENCE },
  { name: 'Portfolio', url: cvNavLinks.PORTFOLIO },
  { name: 'Skills', url: cvNavLinks.SKILLS },
  { name: 'Languages', url: cvNavLinks.LANGUAGES },
  { name: 'Education', url: cvNavLinks.EDUCATION },
  { name: 'Certificates', url: cvNavLinks.CERTIFICATES },
  { name: 'Courses', url: cvNavLinks.COURSES },
  { name: 'Books', url: cvNavLinks.BOOKS },
]

export const significate = {
  HIRED: {
    value: 'hired',
    name: 'Hired',
    color: '#12B76A',
  },
  PREOFFER: {
    value: 'preoffer',
    name: 'Pre-offer',
    color: '#6172F3',
  },
  TEST_TASK: {
    value: 'test_task',
    name: 'Test Task',
    color: '#F79009',
  },
  INTERVIEWING: {
    value: 'interviewing',
    name: 'Interviewing',
    color: '#9E77ED',
  },
  REJECTED: {
    value: 'rejected',
    name: 'Rejected',
    color: '#FF5641',
  },
}

export const vacancyLevels = {
  JUNIOR: {
    value: 'junior',
    name: 'Junior',
  },
  MIDDLE: {
    value: 'middle',
    name: 'Middle',
  },
  SENIOR: {
    value: 'senior',
    name: 'Senior',
  },
}

export const englishLevel = {
  BEGINNER: {
    value: 'beginner',
    label: 'Beginner',
  },
  ELEMENTARY: {
    value: 'elementary',
    label: 'Elementary',
  },
  INTERMEDIATE: {
    value: 'intermediate',
    label: 'Intermediate',
  },
  UPPER_INTERMEDIATE: {
    value: 'upper_intermediate',
    label: 'Upper Intermediate',
  },
  ADVANCED: {
    value: 'advanced',
    label: 'Advanced',
  },
  NATIVE: {
    value: 'native',
    label: 'Native',
  },
}

export const marriedStatuses = ['Married', 'Single']

export const inventoryTypes = {
  NOTEBOOK: {
    value: 'notebook',
    label: 'Notebook',
  },
  MONITOR: {
    value: 'monitor',
    label: 'Monitor',
  },
  IPHONE: {
    value: 'iphone',
    label: 'Iphone',
  },
  TABLET: {
    value: 'tablet',
    label: 'Tablet',
  },
  MACBOOK: {
    value: 'macbook',
    label: 'Macbook',
  },
}

export const dashboardDefaultParams = {
  HIRED: undefined,
  FIRED: undefined,
}

export const CSV_LIMIT = undefined

export const usersSubRoles = [
  { label: 'All users', value: '' },
  { label: 'Developers', value: 'Developer' },
  { label: 'QA\'s', value: 'QA' },
  { label: 'DevOps', value: 'DevOps' },
  { label: 'Designers', value: 'Designer' },
]

export const mobNavbarHideRoutes = ['tmp']
