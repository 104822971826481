import React from 'react'
import { useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import CustomerFilters from '../ManageCustomers/CustomerFilters'

type Props = {
  closeModal: () => void
}

const CustomersFiltersMobile: React.FC<Props> = ({ closeModal }) => {
  const customers = useSelector((state: { customers: { companies: [] } }) => state.customers.companies)
  const numberOfCustomers = useSelector((state: { customers: { customersLength: number }
  }) => state.customers.customersLength)

  return (
    <>
      <div className="filters-modal-wrapper">
        <div className="filters-modal-navbar">
          <div onClick={closeModal} className="filters-modal-back-arrow">
            <ArrowBackIcon />
          </div>
          <p className="filters-modal-navbar-text">Filter</p>
        </div>
        <div className="filters-modal-body">
          <div className="filters-modal-text-wrapper">
            <p className="candidates-filters-modal-text">Sorting by:</p>
            <CustomerFilters companiesSliced={customers} />
          </div>
          <div className="filters-modal-text-wrapper"></div>
          <div className="filters-modal-button-wrapper">
            <div onClick={closeModal} className="filters-modal-button">
              <p className="filters-modal-button-text">Show results ({numberOfCustomers})</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(CustomersFiltersMobile)
