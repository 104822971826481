import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'
import { editInventory } from '../../services/API/admin/inventories'
import { Inventory } from '../../types'
import { updateInventory, updateInventoryFailure, updateInventorySuccess } from '../../store/inventoriesSlice'
import { useGetInventories } from './useGetInventories'

export const useUpdateInventory = () => {
  const dispatch = useDispatch()
  const getInventoriesHook = useGetInventories()

  return (profileId: number, inventory: Inventory) => {
    dispatch(updateInventory())
    editInventory(inventory)
      .then((response) => {
        dispatch(updateInventorySuccess(response.data))
        successNotify('Inventory updated')
        getInventoriesHook(profileId)
      })
      .catch((error) => {
        dispatch(updateInventoryFailure(error))
        dangerNotify(error.response.data.message[0])
      })
  }
}
