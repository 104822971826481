import dayjs from 'dayjs'

export const dateFormatDDMMYY = (date: string | null | Date) => {
  return dayjs(date).format('DD.MM.YYYY')
}

export const dateFormatYYMMDD = (date: string | null | Date) => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const dateFormatYYMM = (date: string | null | Date) => {
  return dayjs(date).format('YYYY-MM')
}

export const dateFormatMM = (date: string | null | Date) => {
  return dayjs(date).format('MM')
}

export const dateFormatYYYY = (date: string | null | Date) => {
  return dayjs(date).format('YYYY')
}

export const dateFormatMMMMYYYY = (date: string | null | Date) => {
  return dayjs(date).format('MMMM YYYY')
}

export const dateFormatMMYY = (date: string | null | Date) => {
  return dayjs(date).format('MM.YYYY')
}

export const dateFormatDDMM = (date: string | null | Date) => {
  return dayjs(date).format('DD.MM')
}
