import { useDispatch } from 'react-redux'

import { getCandidate as getCandidateService } from '../../services/API/candidates'
import { getCandidate, getCandidateSuccess, getCandidateFailure } from '../../store/candidatesSlice'
import dangerNotify from '../../helpers/dangerNotify'

export const useGetCandidate = () => {
  const dispatch = useDispatch()

  return (id: number | string) => {
    dispatch(getCandidate())
    getCandidateService(id)
      .then((response) => {
        dispatch(getCandidateSuccess(response.data))
      })
      .catch((error) => {
        dispatch(getCandidateFailure(error))
        dangerNotify('Server error: unable to download Candidate')
      })
  }
}
