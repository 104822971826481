import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'

import { MIN_INPUTS_ELEMENTS } from '../../../../../constants'
import { IResumeCourse } from '../../../../../types/Resume/IResumeCourse'
import { useUpdateCvCourse } from '../../../../../hooks/resume/Course/useUpdateResumeCourse'
import CvCourseForm from './CvCourseForm'
import EditFormButtons from '../FormButtons/EditFormButtons'

export interface ICourseInputs {
    name: string
    license: string
    acquire_date: Date
}

interface Props {
    setOpen: (value: boolean) => void
    course: IResumeCourse
    cvId: number
}

const CvUpdateCourse: React.FC<Props> = ({ setOpen, course, cvId }) => {
  const inputFieldsValues = course.stack.map(value => ({ value: value }))
  const [inputFields, setInputFields] = useState(inputFieldsValues)
  const isDisabled = inputFields.length <= MIN_INPUTS_ELEMENTS
  const updateCvCourse = useUpdateCvCourse()

  const { register, handleSubmit, control } = useForm<ICourseInputs>(
    {
      defaultValues: {
        name: course ? course.name : '',
        license: course ? course.license : '',
        acquire_date: course ? course.acquire_date : undefined,
      }
    })

  const onSubmit: SubmitHandler<ICourseInputs> = (data) => {
    const courseStack = inputFields.map(({ value }) => value)
    const updated_course = {
      name: data.name,
      license: data.license,
      acquire_date: data.acquire_date,
      stack: courseStack,
      resume_id: cvId
    }

    updateCvCourse(updated_course as IResumeCourse, course.id as number, cvId)
    setOpen(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CvCourseForm
        register={register}
        control={control}
        setInputFields={setInputFields}
        inputFields={inputFields}
        isDisabled={isDisabled}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <EditFormButtons setOpen={setOpen} />
      </Box>
    </form>
  )
}

export default CvUpdateCourse
