import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactSelect, { SingleValue } from 'react-select'

import { tableCellStyles } from '../../../utilts/styles'
import { Technology, User } from '../../../types'
import { renderProfileTechnology, renderUserName } from '../../../helpers/renderName'
import { useUpdateUserStatus } from '../../../hooks/user/useUpdateUserStatus'
import { busyOutsource, busyOutstaff, readyOutstaff } from './dashboardStyles'
import { DeveloperStatus } from '../../../enums/DashboardEnums'
import { ProfileTechnology } from '../../../types/Profile/ProfileTechnology'
import { webRoutes } from '../../../lib'
import { calculateAge } from '../../../helpers/calculateAge'

type Props = {
  users: User[] | undefined
  page: number
  profileTechnologies?: ProfileTechnology[]
  subRole?: string
}

export type Options = {
  label: string
  value: string
}

const options: Options[] = [
  { label: 'Busy on full-time outstaff', value: 'busy_on_full_time_outstaff' },
  { label: 'Ready for full-time outstaff', value: 'ready_for_full_time_outstaff' },
  { label: 'Busy on outsource', value: 'busy_on_outsource' }
]

const AvailableDevsTableRow: React.FC<Props> = ({ users, page, profileTechnologies, subRole }) => {
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const technologies = useSelector(
    (state: {technologies: {indexData: Technology[]}}) => state.technologies.indexData)
  const updateUserStatusHook = useUpdateUserStatus()
  const outstaffBusyValue = { label: 'Busy on full-time outstaff', value: 'busy_on_full_time_outstaff' }
  const outstaffReadyValue = { label: 'Ready for full-time outstaff', value: 'ready_for_full_time_outstaff' }
  const outsourceBusyValue = { label: 'Busy on outsource', value: 'busy_on_outsource' }

  const onChangeStatus = (status: SingleValue<Options>, userId: number, page: number) => {
    if (status) updateUserStatusHook(status.value, userId, page, subRole)
  }

  const renderProfileStack = (user: User) => {
    return profileTechnologies?.map(item => (
      user.profile_id === item.profile_id &&
        `${renderProfileTechnology(item.technology_id, technologies)}, `
    ))
  }

  return (
    <>
      {users?.map(user => {
        const isBusyOutstaff = user.status === DeveloperStatus.BUSY_ON_OUTSTAFF
        const isBusyOutsource = user.status === DeveloperStatus.BUSY_ON_OUTSOURCE
        const userStatus = isBusyOutstaff ? outstaffBusyValue :
          isBusyOutsource ? outsourceBusyValue : outstaffReadyValue
        const background = isBusyOutstaff ? '#D1FADF' : isBusyOutsource ? '#E0EAFF' : '#FEF0C7'
        const userExperienceYears = user?.job_started_on &&
            calculateAge(new Date(user?.job_started_on), user?.job_ended_on).years
        const userExperienceMonths = user?.job_started_on &&
            calculateAge(new Date(user?.job_started_on), user?.job_ended_on).months

        return(
          <TableRow key={user.id}>
            <TableCell align="left" sx={tableCellStyles} style={{ width: '15%' }}>
              <Link to={`/users/${user.id}`}>
                {renderUserName(user.id, usersNames)}
              </Link>
            </TableCell>
            <TableCell align="left" sx={tableCellStyles} style={{ width: '30%' }}>
              {user?.all_resumes?.map(r =>
                <a
                  key={r.id}
                  href={`${process.env.REACT_APP_BASE_URL}${webRoutes.CV_PREVIEW(user.slug, r.id.toString())}`}
                  target="_blank" rel="noreferrer"
                  className="dashboard_user_cv_link"
                >
                  {r.role}
                </a>
              )}
            </TableCell>
            <TableCell align="left" sx={tableCellStyles} style={{ width: '23%' }}>
              {renderProfileStack(user)}
            </TableCell>
            <TableCell align="left" sx={tableCellStyles} style={{ width: '10%' }}>
              {userExperienceYears || userExperienceMonths ?
                <span>{userExperienceYears}{'y'}.{userExperienceMonths}{'m'}</span> : 'Not set'
              }
            </TableCell>
            <TableCell
              align="left"
              sx={tableCellStyles}
              style={{ width: '22%', backgroundColor: background }}>
              <ReactSelect
                options={options}
                isSearchable={false}
                styles={isBusyOutstaff ? busyOutstaff : isBusyOutsource ? busyOutsource : readyOutstaff}
                value={userStatus}
                onChange={(newValue: SingleValue<Options>) => onChangeStatus(newValue, user.id, page)}
                placeholder=""
                components={{ IndicatorSeparator: () => null }}
              />
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}

export default AvailableDevsTableRow
