import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'

interface IProps {
    leaveCount: number
    leaveCoefficient: number
    maxLeaveCount: number
    color: string
}

const LeaveProgress: React.FC<IProps> = ({ leaveCount, leaveCoefficient, maxLeaveCount, color  }) => {
  return (
    <div className="leaves__wrapper">
      <div>
        {leaveCount}/{maxLeaveCount}
      </div>
      <div className="leaves__progress">
        <LinearProgress sx={{
          backgroundColor: '#EEEFF4',
          borderRadius: '200px',
          height: '6px',
          '& .MuiLinearProgress-bar': {
            backgroundColor: `${color}`,
            height: '6px',
            borderRadius: '200px'
          }
        }}
        variant="determinate"
        value={leaveCount * leaveCoefficient}
        />
      </div>
    </div>
  )
}

export default LeaveProgress
