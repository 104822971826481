import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { deleteSession } from 'services/API/session'
import { useAuth } from '../../context/auth'

const LogoutContainer: React.FC = () => {
  const { setCurrentUser, setAuthToken } = useAuth()

  useEffect(() => {
    deleteSession().then(() => {
      // document.cookie = 'jwt=nil'
      setCurrentUser()
      setAuthToken()
      localStorage.clear()
    })
  }, [])

  return <Navigate to={'/'} />
}

export default LogoutContainer
