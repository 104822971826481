import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import countryList from 'react-select-country-list'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'

import ReactForm from '../../../../reusable/ReactForm'
import { useCreateResume } from '../../../../../hooks/resume/useCreateCv'
import { User } from '../../../../../types'
import { Modify } from '../../../../../helpers/ModifyInterface'
import { IOptions } from '../../../../../types/ISelectOptions'
import CvContactsDataForm from './CvContactsDataForm'
import AddFormItemButton from '../FormButtons/AddFormItemButton'

export type IContactsDataOmitedOption = Modify<IOptions, { value: string }>
export interface IContactsDataInputs {
  name: string
  role: string
  github: string
  city: string
  summary: string
}

interface Props {
  readyFile: File | undefined
  setReadyFile: Dispatch<SetStateAction<undefined>>
}

const CvAddContactsData: React.FC<Props> = ({ setReadyFile, readyFile }) => {
  const [country, setCountry] = useState({ label: 'Ukraine', value: 'UA' })
  const [image, setImage] = useState('')
  const [cropper, setCropper] = useState()
  const [isCropped, setIsCropped] = useState(false)
  const createCv = useCreateResume()
  const user = useSelector((state: { users: { showData: User } }) => state.users.showData)
  const options = useMemo(() => countryList().getData(), [])
  const isDisabled = user.has_cv

  const { register, handleSubmit, reset } = useForm<IContactsDataInputs>(
    {
      defaultValues: {
        name: '',
        role: '',
        city:  '',
        github: '',
        summary: '',
      }
    })

  const onSelectHandler = (value: IContactsDataOmitedOption) => {
    setCountry(value)
  }

  const onSubmit: SubmitHandler<IContactsDataInputs> = (data,event) => {
    event?.preventDefault()
    const location = `${data.city.trim()},${country.label.trim()}`
    const resume = new FormData()
    resume.append('user_id', user?.id)
    resume.append('name', data.name)
    resume.append('role', data.role )
    resume.append('github_url', data.github)
    resume.append('location', location)
    resume.append('summary', data.summary )
    image && resume.append('avatar', readyFile)

    createCv(resume, user.id)
    reset()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <CvContactsDataForm
        setReadyFile={setReadyFile}
        register={register}
        country={country}
        onSelectHandler={onSelectHandler}
        user={user}
        setImage={setImage}
        setCropper={setCropper}
        isCropped={isCropped}
        setIsCropped={setIsCropped}
        options={options}
        cropper={cropper}
        image={image}
      />
      <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '30px' }}>
        <AddFormItemButton isDisabled={isDisabled}/>
      </Box>
    </form>
  )}

export default CvAddContactsData
