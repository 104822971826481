import React from 'react'
import { useSelector } from 'react-redux'

import CustomPagination from 'components/reusable/Pagination/Pagination'
import { Contract } from 'types'
import { ProjectProfile } from 'types/Manage/Project/ProjectProfile'
import ManageProjectsCard from '../ManageProjectsCard'

const MobileContractsLogsProject: React.FC<ProjectProfile> = ({
  status,
  setPage,
  page,
  usersNames,
}) => {
  const contracts = useSelector(
    (state: { contracts: { indexLogsData: Contract[] } }) => state.contracts.indexLogsData
  )
  const totalPages = useSelector(
    (state: { contracts: { indexLogsData: { pages: number } } }) => state.contracts.indexLogsData.pages)

  return (
    <div>
      <div>
        <div>
          <div className="project-profile_general-title">Contract logs</div>
        </div>
        <div>
          {contracts?.paginate_contracts?.map((item: Contract, index: number) => (
            <ManageProjectsCard
              usersNames={usersNames}
              status={status}
              item={item}
              key={index}
            />
          ))
          }
        </div>
        {contracts && 
          <CustomPagination
            totalPages={totalPages}
            page={page}
            setPage={setPage}
          />
        }
      </div>
    </div>
  )
}

export default MobileContractsLogsProject
