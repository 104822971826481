import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { userRoles } from '../../../constants'
import { webRoutes } from '../../../lib'
import CreateRecordContainer from 'containers/RecordContainer/CreateRecordContainer'
import { useAuth } from 'context/auth'
import MonthlyReportContainer from 'containers/MonthlyReportContainer'
import DashboardUsersList from '../../../views/dashboard/usersList/dashboardUsersList'
import { SubRoleType } from 'enums/subRoles'

const MainContainerRoute = () => {
  const user = JSON.parse(localStorage.getItem('user') as string)
  const { currentUser } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const isDeveloper = user?.role === userRoles.DEFAULT.id
  const isDeveloperMainPage = location.pathname === webRoutes.SUBMIT_HOURS
  const isCFORole = currentUser?.sub_roles.map(name => ({ name })).every((role) => role.name == SubRoleType.CFO)

  return (
    <>
      { !user ? navigate(webRoutes.HOME) : (isDeveloper || isDeveloperMainPage) ?
        <CreateRecordContainer /> : isCFORole ? <MonthlyReportContainer/> : <DashboardUsersList />
      }
    </>
  )
}

export default MainContainerRoute
