import React, { ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'

import CustomDatepicker from 'components/custom/CustomDatepicker/CustomDatepicker'
import { resumeLevels } from '../../../constants'
import DragNDrop from 'components/reusable/DragNDrop'
import { dateFormatYYMMDD } from 'helpers/displayingDateFormat'
import { useUpdateUser } from '../../../hooks/user/useUpdateUser'

type Props = {
  onCancel: () => void
}

export const UserProfileGeneralForm = ({ onCancel }: Props) => {
  const user = useSelector(({ users: { showData } }) => showData)
  const profile = useSelector(({ profile: { showData } }) => showData)
  const availableTechs = useSelector(({ technologies: { indexData } }) => indexData)
  const subRolesRaw = useSelector(({ subRoles: { indexData } }) => indexData)

  const updateUser = useUpdateUser()

  const [avatar, setAvatar] = useState<string | File | null>(profile.avatar || null)
  const [state, setState] = useState<{ [k: string]: string }>({
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    position: profile.position || '',
    skype: profile.skype || '',
    telegram: profile.telegram || '',
    github: profile.github || '',
    phone_number: profile.phone_number || '',
    english_level: profile.english_level || '',
    salary: profile.salary || '',
    job_started_on: profile.job_started_on ? dateFormatYYMMDD(profile.job_started_on) : '',
    job_ended_on: profile.job_ended_on ? dateFormatYYMMDD(profile.job_ended_on) : '',
  })
  const [technologies, setTechnologies] = useState(
    profile.technologies?.length ? profile.technologies.map((t) => t.id) : [],
  )
  const [subRole, setSubRole] = useState<string | number>(
    subRolesRaw.filter((sr) => user.sub_roles.includes(sr.name))[0]?.id || '',
  )

  const handleChangeTechnologies = (e: SelectChangeEvent<unknown>) =>
    setTechnologies(e.target.value as (number | undefined)[])

  const handleChangeDate = (field: 'job_started_on' | 'job_ended_on') => (v: Date | null) =>
    setState((ps) => ({ ...ps, [field]: v ? dateFormatYYMMDD(v) : '' }))

  const handleChange =
    (field: keyof typeof state) =>
      ({ target: { value } }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<string>) => {
        setState((ps) => ({
          ...ps,
          [field]: value,
        }))
      }

  const handleSubmit = () => {
    const form = new FormData()

    if (subRole) form.append('role_ids[]', String(subRole))
    if (avatar !== profile.avatar) form.append('avatar', avatar || '')

    form.append('profile_id', String(profile.id))
    technologies.map((t) => form.append('technology_ids[]', String(t)))

    Object.keys(state).map((key) => {
      if (state[key].length) form.append(key, state[key].trim())
    })

    updateUser(user.id, form)
    onCancel()
  }

  return (
    <div className="modal__content">
      <DragNDrop image={profile.avatar} setImage={setAvatar} />
      <div className="modal__double-field">
        <TextField
          autoComplete="off"
          label="First Name"
          className="modal__input"
          value={state.first_name}
          onChange={handleChange('first_name')}
        />
        <TextField
          autoComplete="off"
          label="Last Name"
          className="modal__input"
          value={state.last_name}
          onChange={handleChange('last_name')}
        />
      </div>
      <div className="modal__double-field">
        <TextField
          autoComplete="off"
          label="Position"
          className="modal__input"
          value={state.position}
          onChange={handleChange('position')}
        />
        <FormControl className="modal__input">
          <InputLabel id="create-user-roles">Role</InputLabel>
          <Select
            labelId="create-user-roles"
            value={subRole}
            onChange={({ target: { value } }) => setSubRole(value)}
            input={<OutlinedInput label="Role" />}
          >
            {subRolesRaw.map(({ id, name }) => (
              <MenuItem key={name + id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <FormControl className="w-100">
        <InputLabel id="user-profile-stack">Stack</InputLabel>
        <Select
          labelId="user-profile-stack"
          multiple
          value={technologies}
          onChange={handleChangeTechnologies}
          input={<OutlinedInput label="Stack" />}
        >
          {availableTechs.map(({ id, name }) => (
            <MenuItem key={name + id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="modal__double-field">
        <TextField
          autoComplete="off"
          label="Skype Link"
          className="modal__input"
          value={state.skype}
          onChange={handleChange('skype')}
        />
        <TextField
          autoComplete="off"
          label="Github Link"
          className="modal__input"
          value={state.github}
          onChange={handleChange('github')}
        />
      </div>
      <div className="modal__double-field">
        <TextField
          autoComplete="off"
          label="Telegram"
          className="modal__input"
          value={state.telegram}
          onChange={handleChange('telegram')}
        />
        <TextField
          autoComplete="off"
          label="Phone Number"
          className="modal__input"
          value={state.phone_number}
          onChange={handleChange('phone_number')}
          type="tel"
        />
      </div>

      <div className="modal__double-field w-100">
        <div className="modal__quad-field">
          <CustomDatepicker
            value={state.job_started_on as string | Date | null}
            onChange={handleChangeDate('job_started_on')}
            mask={'__.__.____'}
            maxDate={new Date()}
            inputType="default"
            placeholder="First work day"
          />
          <CustomDatepicker
            value={state.job_ended_on as string | Date | null}
            onChange={handleChangeDate('job_ended_on')}
            minDate={state.job_started_on as string | Date | null}
            mask={'__.__.____'}
            inputType="default"
            placeholder="Last work day"
          />
        </div>
        <div className="modal__quad-field">
          <FormControl sx={{ flex: 2.5 }}>
            <InputLabel id="user-profile-english">English Level</InputLabel>
            <Select
              labelId="user-profile-english"
              value={state.english_level}
              onChange={handleChange('english_level')}
              input={<OutlinedInput label="English Level" />}
            >
              {resumeLevels.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            autoComplete="off"
            label="Salary $"
            type="number"
            onChange={handleChange('salary')}
            sx={{ flex: 1 }}
          />
        </div>
      </div>
      <div className="modal__double-field">
        <Button className="modal__button modal__cancel" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button className="modal__button modal__submit" onClick={handleSubmit} variant="contained">
          Update
        </Button>
      </div>
    </div>
  )
}
