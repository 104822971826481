import React from 'react'
import { createTheme, Pagination, ThemeProvider } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { isMobile } from 'react-device-detect'

type Props = {
  totalPages: number
  page: number
  setPage: (value: number) => void
}

const CustomPagination: React.FC<Props> = ({ totalPages, page, setPage }) => {
  const MIN_PAGES = 2

  return (
    <>
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiPaginationItem: {
              defaultProps: {
                components: { previous: ArrowBackIcon, next: ArrowForwardIcon }
              }
            }
          }
        })}
      >
        {totalPages >= MIN_PAGES &&
         <Pagination
           boundaryCount={isMobile ? 0 : 2}
           count={totalPages}
           page={page}
           className="requests__pagination"
           onChange={(event: React.ChangeEvent<unknown>, page: number) => setPage(page)}
         />
        }
      </ThemeProvider>
    </>
  )
}

export default CustomPagination
