import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'
import { duplicateCv } from '../../services/API/admin/resume'
import { useFetchUserCvs } from './useFetchUserCvs'

export const useDuplicateCv = () => {
  const fetchUserCvs = useFetchUserCvs()

  return (cvId: number, cvRole: string, userId: number) => {
    duplicateCv(cvId)
      .then(() => {
        successNotify(`Cv created on base ${cvRole}`)
        fetchUserCvs(userId)
      })
      .catch(() => {
        dangerNotify('Something went wrong!')
      })
  }
}
