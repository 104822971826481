import dayjs from 'dayjs'

import { VacationWeeks } from 'enums/RequestEnums'
import { IPaginateRequest } from 'types/Request/IPaginateRequests'

export const disableTwoWeeks = (yearRequests: IPaginateRequest) => {
  return yearRequests?.paginate_requests.filter(request => 
    request.status != 'rejected' && 
    request.request_type === 'vacation').some(req => 
    (dayjs(req.end_date).diff(dayjs(req.start_date), 'day') + 1) === VacationWeeks.TWO_WEEKS )
}

export const disableOneWeek = (yearRequests: IPaginateRequest) => {
  return yearRequests?.paginate_requests.filter(request => 
    request.status != 'rejected' && 
        request.request_type === 'vacation').some(req => 
    (dayjs(req.end_date).diff(dayjs(req.start_date), 'day') + 1) === VacationWeeks.ONE_WEEK )
}
