import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactSelect, { SingleValue } from 'react-select'

import './mobile_dashboard.scss'
import { mobileBusyOutsource, mobileBusyOutstaff, mobileReadyOutstaff } from '../desktop/dashboardStyles'
import { User } from '../../../types'
import { renderUserName } from '../../../helpers/renderName'
import { DeveloperStatus } from '../../../enums/DashboardEnums'
import { IStringOptions } from '../../../types/ISelectOptions'
import CustomPagination from '../../reusable/Pagination/Pagination'

type IProps = {
    page: number
    setPage: (value: number) => void
    onChangeStatus: (value: SingleValue<IStringOptions>, userId: number, page: number) => void
    options: IStringOptions[]
}

const MobileAvailableDevelopers: React.FC<IProps> = ({ page, setPage, onChangeStatus, options }) => {
  const users =
        useSelector((state: { users: { dashboardUsersData: User[] }} ) => state.users.dashboardUsersData)
  const totalPages = useSelector((state: { users: { totalPages: number }} ) => state.users.totalPages)
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const outstaffBusyValue = { label: 'Busy on full-time outstaff', value: 'busy_on_full_time_outstaff' }
  const outstaffReadyValue = { label: 'Ready for full-time outstaff', value: 'ready_for_full_time_outstaff' }
  const outsourceBusyValue = { label: 'Busy on outsource', value: 'busy_on_outsource' }

  return (
    <>
      {users?.map(user => {
        const isBusyOutstaff = user.status === DeveloperStatus.BUSY_ON_OUTSTAFF
        const isBusyOutsource = user.status === DeveloperStatus.BUSY_ON_OUTSOURCE
        const userStatus = isBusyOutstaff ? outstaffBusyValue :
          isBusyOutsource ? outsourceBusyValue : outstaffReadyValue
        const styles = isBusyOutstaff ? mobileBusyOutstaff : isBusyOutsource ? mobileBusyOutsource : mobileReadyOutstaff

        return (
          <div className={isBusyOutstaff ? 'mobile_dashboard__card-busyOutstaff' : isBusyOutsource ?
            'mobile_dashboard__card-busyOutsource' : 'mobile_dashboard__card-readyOutstaff'} key={user.id}>
            <Link to={`/users/${user.id}`} className="mobile_dashboard__card-userName">
              {renderUserName(user.id, usersNames)}
            </Link>
            <div className="mobile_dashboard__userStack">
              {user.technologies?.map(technology => technology.name).join(', ')}
            </div>
            <div className="mobile_dashboard__selectStatus">
              <ReactSelect
                options={options}
                styles={styles}
                value={userStatus}
                onChange={(newValue: SingleValue<IStringOptions>) => onChangeStatus(newValue, user.id, page)}
                placeholder=""
                components={{
                  IndicatorSeparator: () => null
                }}
              />
            </div>
          </div>
        )
      })}
      <CustomPagination page={page} totalPages={totalPages} setPage={setPage} />
    </>
  )
}

export default MobileAvailableDevelopers
