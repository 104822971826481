import React from 'react'
import { default as Icon } from '@mui/icons-material/DeleteOutline'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

export default function DeleteIcon({ isRecords, text }: { isRecords?: boolean; text?: string }) {
  return (
    <>
      {isRecords ? (
        <Tooltip title={text == 'bench project'
          ? `Cannot delete. There is ${text}`
          : `Cannot delete. There are records with this ${text}`}
        >
          <IconButton component="div" disabled>
            <DeleteForeverOutlinedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton>
          <Icon />
        </IconButton>
      )}
    </>
  )
}
