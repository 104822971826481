import { IProjectTechnology } from 'types/Project/IProjectTechnology'
import { User, Status, Project, Customer, Technology, Category } from '../types'

interface IUsersName {
  id: number
  full_name: string
  active: boolean
  role: string
}

export const renderProjectStack = (projectTechnologies, technologies) => {
  return projectTechnologies.length >= 1 ?
    projectTechnologies?.map((technology: IProjectTechnology) => (
      `${renderProjectTechnology(technology.technology_id, technologies)}, `
    )) :
    '<None>'
}

export const renderProject = (id: number | undefined, projects: Project[] | undefined) => {
  if (!id) return
  const contractProject = projects?.find((project) => project.id === +id && +id !== 0)

  if (contractProject) {
    return contractProject.name
  }
}

export const renderCategory = (id: number | undefined, categories: Category[] | undefined) => {
  if (!id) return
  const contractCategory = categories?.find((category) => category.id === +id && +id !== 0)

  if (contractCategory) {
    return contractCategory.name
  }
}

export const renderProjectCalendar = (id: number, projects: Project[]) => {
  const contractProject = projects.find((project) => project.id === id && id !== 0)

  if (contractProject) {
    return contractProject.name
  }

  return
}

export const renderStatus = (id: number | undefined, statuses: Status[]) => {
  if (!id) return
  const contractStatus = statuses.find((status) => status.id === +id)

  if (contractStatus) return contractStatus.name
}

export const renderUser = (id: string | number | undefined, users: User[]) => {
  if (!id) return
  const userId = typeof id === 'string' ? Number(id) : id
  const contractUser = users.find((user) => user.id === +userId)

  if (contractUser) return contractUser.full_name
}

export const renderType = (id: number | undefined, types: User[]) => {
  const paidLeave = types.find((type) => type.value === +id)

  if (paidLeave) {
    return paidLeave.label
  }
}

export const renderProjectId = (name: string | undefined | null, projects: Project[] | undefined) => {
  if (!name) return
  const contractProject = projects?.find((project) => project.name === name)

  if (contractProject) return contractProject.id
}

export const renderManager = (id: number | undefined, projects: Project[] | undefined, users: User[]) => {
  if (!id) return
  const contractProject = projects?.find((project) => project.id === +id && +id !== 0)

  if (contractProject && users) return renderUser(contractProject.manager_id, users)
}

export const renderUserName = (id: number, users: IUsersName[]) => {
  if (!id) return
  const user = users.find((user: IUsersName) => user.id == id)

  if (user) return user.full_name
}

export const renderCustomer = (id: number, customers: Customer[]) => {
  if (!id) return
  const customer = customers?.find((customer: Customer) => customer.id === id)

  if (customer) return customer.company_name
}

export const renderProjectTechnology = (id: number, technologies: Technology[]) => {
  if (!id) return
  const technology = technologies?.find((technology: Technology) => technology.id === id)

  if (technology) return technology.name
}

export const renderProfileTechnology = (id: number, technologies: Technology[]) => {
  if (!id) return
  const technology = technologies?.find((technology: Technology) => technology.id === id)

  if (technology) return technology.name
}
