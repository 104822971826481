import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { GetAdminRequest, getAdminRequests, getRequests } from '../../services/API/request'
import { fetchRequests, fetchRequestsFailure, fetchRequestsSuccess } from '../../store/requestsSlice'
import dangerNotify from '../../helpers/dangerNotify'
import webRoutes from 'lib/webRoutes'

export const useGetRequests = () => {
  const dispatch = useDispatch()

  return (page: number, day: string | null, month: string | null, year?: number) => {
    dispatch(fetchRequests())
    getRequests(page, day, month, year)
      .then((response) => {
        !year && dispatch(fetchRequestsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchRequestsFailure(error))
        dangerNotify('Server error: unable to download Requests')
      })
  }
}

export const useGetAdminRequests = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  return (params: GetAdminRequest) => {
    dispatch(fetchRequests())
    getAdminRequests(params)
      .then((response) => {
        dispatch(fetchRequestsSuccess(response.data))
      })
      .catch((error) => {
        dispatch(fetchRequestsFailure(error))
        location.pathname != webRoutes.ADMIN_MAIN && dangerNotify('Server error: unable to download Requests')
      })
  }
}
