import React from 'react'
import { isMobile } from 'react-device-detect'

const StatusIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 
        5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM10 0C4.47715 0 0 4.47715 
        0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM9.0665 
        6.73043C9.41262 6.52701 9.81957 6.45265 10.2153 6.52052C10.611 6.58839 10.9699 6.79411 11.2284 
        7.10125C11.487 7.40839 11.6285 7.79711 11.6279 8.19858L11.6279 8.20007C11.6279 8.56921 11.3378 
        8.98329 10.7232 9.39302C10.4463 9.5776 10.1623 9.72031 9.9436 9.81751C9.83566 9.86548 9.74711 
        9.90084 9.68788 9.9234C9.65835 9.93465 9.63636 9.94262 9.62317 9.94731L9.61064 9.95172L9.61031 
        9.95183C9.08721 10.127 8.80468 10.6928 8.97918 11.2163C9.15382 11.7402 9.72014 12.0234 10.2441 
        11.8488L9.93815 10.931C10.2441 11.8488 10.2444 11.8487 10.2447 11.8486L10.2453 11.8483L10.2468 
        11.8478L10.2506 11.8466L10.261 11.843L10.2932 11.8317C10.3196 11.8224 10.3556 11.8092 10.3999 
        11.7924C10.4883 11.7587 10.6107 11.7097 10.7559 11.6451C11.0434 11.5173 11.4344 11.3225 11.8326 
        11.0571C12.5677 10.567 13.6271 9.63159 13.6279 8.20156L12.6279 8.20007H13.6279V8.20099C13.629 
        7.3274 13.3211 6.48157 12.7585 5.81325C12.1957 5.14478 11.4146 4.69703 10.5534 4.54931C9.69216 
        4.40159 8.80646 4.56343 8.05314 5.00616C7.29981 5.44889 6.72749 6.14395 6.43752 6.96823C6.25425 
        7.48922 6.52803 8.06014 7.04902 8.24341C7.57 8.42668 8.14092 8.15291 8.32419 7.63192C8.45742 
        7.2532 8.72038 6.93384 9.0665 6.73043ZM9.99986 13.5001C9.44757 13.5001 8.99986 13.9478 8.99986 
        14.5001C8.99986 15.0524 9.44757 15.5001 9.99986 15.5001H10.0089C10.5611 15.5001 11.0089 15.0524 
        11.0089 14.5001C11.0089 13.9478 10.5611 13.5001 10.0089 13.5001H9.99986Z" fill={!isMobile ? 'white' : 'black'}/>
    </svg>
  )
}

export default StatusIcon
