import React from 'react'
import { NavLink } from 'react-router-dom'

import { webRoutes } from '../../../lib'

const dashboardTabs = [
  { name: 'Users', url: webRoutes.DASHBOARD_USERS_LIST },
  { name: 'Leaves', url: webRoutes.DASHBOARD_LEAVES },
  { name: 'Managers', url: webRoutes.DASHBOARD_MANAGERS },
]

const DashboardNavigation = () => {
  return (
    <nav className="dashboard_nav__list">
      {dashboardTabs.map((tab, index) => (
        <NavLink
          className={({ isActive }) => isActive ? 'dashboard_nav__item-active' : 'dashboard_nav__item'}
          to={tab.url}
          key={index}
        >
          {tab.name}
        </NavLink>
      ))}
    </nav>
  )
}

export default DashboardNavigation
