import React, { useState } from 'react'
import { Container } from 'reactstrap'
import { isMobile } from 'react-device-detect'

import '../../../components/dashboard/desktop/dashboard_main.scss'
import {
  AvailableDevelopersTable,
  DashboardNavigation,
  DashboardUsersFilters,
  FiredUsersTable,
  HiredUsersTable,
} from '../index'
import { DeveloperStatus } from '../../../enums/DashboardEnums'
import { MobileDashboard } from '../../../components/dashboard/mobile'
import { Pages } from '../../../enums/StartPage'
import { IStringOptions } from '../../../types/ISelectOptions'
import { usersSubRoles } from '../../../constants'

const filterData = [
  { label: 'Available developers', value: 'available' },
  { label: 'Hired developers', value: 'hired' },
  { label: 'Fired developers', value: 'fired' }
]

const selectFilterOptions = filterData.map(option => ({
  label: option.label,
  value: option.value
}))

const selectUsersSubRolesOptions = usersSubRoles.map(option => ({
  label: option.label,
  value: option.value
}))

const DashboardUsersList = () => {
  const [page, setPage] = React.useState(Pages.START_PAGE)
  const [option, setOption] = useState(filterData[0])
  const [subRole, setSubRole] = useState(usersSubRoles[0])
  const [date, setDate] = useState<Date>(new Date())
  const isFired = option.value === DeveloperStatus.FIRED
  const isHired = option.value === DeveloperStatus.HIRED

  const onHandleSubRole = (subRole: IStringOptions) => {
    setSubRole(subRole)
    setPage(Pages.START_PAGE)
  }

  return (
    <>
      {isMobile ?
        <MobileDashboard
          isFired={isFired}
          isHired={isHired}
          date={date}
          option={option}
          setOption={setOption}
          setDate={setDate}
          subRole={subRole}
          page={page}
          setPage={setPage}
          onHandleSubRole={onHandleSubRole}
        /> :
        <Container fluid="xxl">
          <div className="dashboard_main__tabs">
            {!isMobile && <DashboardNavigation />}
            <DashboardUsersFilters
              option={option}
              date={date}
              setPage={setPage}
              setDate={setDate}
              setOption={setOption}
              selectFilterOptions={selectFilterOptions}
              selectUsersSubRolesOptions={selectUsersSubRolesOptions}
              onHandleSubRole={onHandleSubRole}
              subRole={subRole}
            />
          </div>
          <div className="dashboard_main__tableName">
            <h4>Users List</h4>
          </div>
          {isFired ?
            <FiredUsersTable
              isFired={isFired}
              isHired={isHired}
              date={date}
              page={page}
              setPage={setPage}
              subRole={subRole.value}
            /> :
            isHired ?
              <HiredUsersTable
                isHired={isHired}
                isFired={isFired}
                date={date}
                page={page}
                setPage={setPage}
                subRole={subRole.value}
              /> :
              <AvailableDevelopersTable
                isFired={isFired}
                isHired={isHired}
                subRole={subRole.value}
                page={page}
                setPage={setPage}
              />
          }
        </Container>
      }
    </>
  )
}

export default DashboardUsersList
