import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { IRequest } from 'types/Request/IRequest'
import { NumberStatus } from 'enums/RequestEnums'
import { Pages } from 'enums/StartPage'
import { useDeleteAdminRequest, useDeleteRequest } from 'hooks/request/useDeleteRequest'
import { Modify } from 'helpers/ModifyInterface'
import { IOptions, IStringOptions } from 'types/ISelectOptions'
import { useAuth } from 'context/auth'
import { dateFormatYYMMDD } from 'helpers/displayingDateFormat'
import { tabDayDate, tabMonthDate } from '../../constants'
import { isManager, requestType } from 'helpers/accessHelpers'
import Modal from 'components/reusable/Modal'
import RequestsHeader from 'components/requests/RequestsHeader/RequestsHeader'
import { IPaginateRequest } from 'types/Request/IPaginateRequests'
import { disableOneWeek, disableTwoWeeks } from 'helpers/weeksDisabling'
import { IUpdateRequest } from '../../types/Request/IUpdateRequest'
import { useApproveAdminRecords } from '../../hooks/record/useApprovedRecords'
import { useUpdateRequest } from '../../hooks/request/useUpdateRequest'
import AdminRequestsTable from '../../components/requests/AdminRequestsTable'
import CreateRequest from '../../components/requests/CreateRequest'
import UserRequestsTable from '../../components/requests/UserRequestsTable'
import { useGetUsersLeaveCounters } from '../../hooks/user-leave-counters/useGetUserLeaveCounters'
import { UserLeaves } from '../../types/UserLeaves'
import { NewRecord } from '../../types/NewRecord'
import { isMobile } from 'react-device-detect'
import { useUtility } from 'context/utility'

const nonIntervalRegex = /(half_day_|work_off)/g

export type IOmitedRequest = Modify<
  IRequest,
  {
    type: IOptions
    note: string
    paidLeave: string
    vacationType: IStringOptions
    hours: number
  }
>

const RequestsContainer = () => {
  const { currentUser } = useAuth()
  const { setState: setUtilityState } = useUtility()

  const deleteAdminRequest = useDeleteAdminRequest()
  const deleteUserRequest = useDeleteRequest()
  const approveRecords = useApproveAdminRecords()
  const getUsersLeaves = useGetUsersLeaveCounters()
  const updateRequestHook = useUpdateRequest()

  const requestPages = useSelector((state) => state.requests.pages)
  const requestCount = useSelector((state) => state.requests.count)
  const usersNames = useSelector((state) => state.users.indexNamesData)

  const [page, setPage] = useState(Pages.START_PAGE)
  const [userLeaves, setUserLeaves] = useState<UserLeaves>()
  const [filterDate, setFilterDate] = useState<Date>(new Date())
  const [alignment, setAlignment] = useState('month')
  const [monthDate, setMonthDate] = useState<Date>(new Date())
  const [filterUser, setFilterUser] = useState<IOptions | null>(null)
  const [yearRequests, setYearRequests] = useState<IPaginateRequest>()
  const [active, setActive] = useState('month')
  const [open, setOpen] = useState(false)
  const [openFilter, setOpenFilter] = useState(false)

  const filterToggle = () => setOpenFilter((ps) => !ps)

  useEffect(() => {
    setUtilityState({ filterToggle })

    return () => {
      setUtilityState({ filterToggle: null })
    }
  }, [])

  useEffect(() => {
    if (isMobile) {
      document.body.style.setProperty('background-color', '#f9f9fa')
    }

    return () => {
      document.body.style.removeProperty('background-color')
    }
  }, [isMobile])

  const searchParams = new URLSearchParams(window.location.search)
  const userIdFromParams = searchParams.get('user_id')
  const dateFromParams = searchParams.get('date')
  const usersName = usersNames
    .filter((user) => user.active)
    .map((user) => ({
      value: user.id,
      label: user.full_name,
    }))

  const userFromParams = usersName.find((u) => u.value === Number(userIdFromParams))
  const isAdmin = isManager(currentUser)
  const alignmentType = alignment === 'day' || 'month' ? filterDate : ''
  const declined = 'Successfully declined!'
  const approved = 'Successfully approved!'
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const tabMonthValue = tabMonthDate(alignment, monthDate)
  const tabDayValue = tabDayDate(alignment, filterDate)

  useEffect(() => {
    if (userFromParams && dateFromParams) {
      setFilterUser(userFromParams)
      setMonthDate(new Date(dateFromParams))
      searchParams.delete('user_id')
      searchParams.delete('date')
      window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`)
    }
  }, [userFromParams, dateFromParams])

  useEffect(() => {
    if (!isAdmin && currentUser?.id) {
      getUsersLeaves(Number(currentUser?.id), setUserLeaves)
    }
  }, [currentUser?.id])

  const onDeleteRequest = (requestId: number) => {
    isAdmin
      ? deleteAdminRequest(requestId, filterDate, monthDate, alignment, page, requestCount, setPage, filterUser?.value)
      : deleteUserRequest(requestId, filterDate, monthDate, alignment, page, requestCount, setPage)
  }

  const onChangeFilterUser = (user: IOptions | null) => {
    setFilterUser(user)
  }

  const updateDate = (value: Date) => {
    setFilterDate(value)
  }

  const changeTab = (name: string) => {
    setActive(name)
    setAlignment(name)
  }

  const onDeclineSubmit = useCallback(
    (request: IRequest) => {
      updateRequestHook({
        request: { id: request.id, status: NumberStatus.REJECTED, page } as IUpdateRequest,
        by_date: tabDayValue,
        by_month: tabMonthValue,
        by_user_id: filterUser?.value,
        requestCount,
        message: declined,
      })
    },
    [tabDayValue, tabMonthValue, requestCount, declined, page],
  )

  const onApproveRequest = useCallback(
    (request: IRequest) => {
      const common = {
        description: requestType(request),
        hours: 0,
        record_type: request.request_type,
        request_id: request.id,
        user_id: request.user_id,
      }

      const singleRecord = {
        ...common,
        date: dateFormatYYMMDD(request.start_date),
      }

      const intervalRecords = {
        ...common,
        start_date: dateFormatYYMMDD(request.start_date),
        end_date: dateFormatYYMMDD(request.end_date || request.start_date),
        note: request.note,
        paid_leave_type: request.paid_leave_type,
      }

      if (nonIntervalRegex.test(request.request_type)) {
        approveRecords({
          record: singleRecord,
          request,
          page,
          by_date: tabDayValue,
          by_month: tabMonthValue,
          by_user_id: filterUser?.value,
          message: approved,
        })
      } else {
        approveRecords({
          record: intervalRecords,
          request,
          page,
          by_date: tabDayValue,
          by_month: tabMonthValue,
          by_user_id: filterUser?.value,
          message: approved,
          isInterval: true,
        })
      }
    },
    [page, tabDayValue, tabMonthValue, approved, filterUser],
  )

  const createRequest = (
    <CreateRequest
      page={page}
      handleClose={handleClose}
      filterDate={filterDate}
      monthDate={monthDate}
      alignment={alignment}
      setFilterUser={setFilterUser}
      setYearRequests={setYearRequests}
      disableTwoWeeks={disableTwoWeeks(yearRequests as IPaginateRequest)}
      disableOneWeek={disableOneWeek(yearRequests as IPaginateRequest)}
    />
  )

  const filters = (
    <RequestsHeader
      changeTab={changeTab}
      alignment={alignment}
      monthDate={monthDate}
      setMonthDate={setMonthDate}
      filterDate={filterDate}
      setFilterDate={setFilterDate}
      updateDate={updateDate}
      handleOpen={handleOpen}
      setPage={setPage}
      active={active}
      usersName={usersName}
      filterUser={filterUser}
      onChangeFilterUser={onChangeFilterUser}
      userLeaves={userLeaves}
      requestCount={requestCount}
      closeFilter={filterToggle}
    />
  )

  return (
    <div className="container">
      {!isMobile && filters}
      {isAdmin ? (
        <AdminRequestsTable
          page={page}
          setPage={setPage}
          onDeleteRequest={onDeleteRequest}
          requestPages={requestPages}
          alignment={alignment}
          filterDate={filterDate}
          monthDate={monthDate}
          requestCount={requestCount}
          onDeclineSubmit={onDeclineSubmit}
          onApproveSubmit={onApproveRequest}
          filterUser={filterUser}
          alignmentType={alignmentType}
          handleOpen={handleOpen}
        />
      ) : (
        <UserRequestsTable
          page={page}
          setPage={setPage}
          onDeleteRequest={onDeleteRequest}
          requestPages={requestPages}
          alignment={alignment}
          filterDate={filterDate}
          monthDate={monthDate}
          alignmentType={alignmentType}
          handleOpen={handleOpen}
        />
      )}
      <Modal title="Send Request" component={createRequest} open={open} handleClose={handleClose} />
      <Modal title="Filter" component={filters} open={openFilter} handleClose={() => setOpenFilter(false)} />
    </div>
  )
}

export default RequestsContainer
