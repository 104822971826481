import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'
import { updateContactsData } from '../../services/API/admin/resume'
import { updateResumeFailure, updateResumeSuccess } from '../../store/resumesSlice'
import { useGetUserCv } from './useGetUserCv'

export const useUpdateContactsData = () => {
  const getUserCv = useGetUserCv()
  const dispatch = useDispatch()
    
  return (resume: FormData, cvId: number) => {
    updateContactsData(resume, cvId)
      .then((response) => {
        dispatch(updateResumeSuccess(response.data))
        successNotify('Contacts updated')
        getUserCv(cvId)
      })
      .catch((error) => {
        dispatch(updateResumeFailure(error))
        dangerNotify('Update error.')
      })
  }
}
