import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'

import { DesktopLeavesPage, MobileLeavesPage } from '../index'
import { fetchUsersLeaves } from '../../../services/API/admin/leave-counters'
import { Pages } from '../../../enums/StartPage'
import { User } from '../../../types'
import { IOptions } from '../../../types/ISelectOptions'

export interface ILeave {
  extra_sick_leaves: number
  full_day_sick_leaves: number
  half_day_sick_leaves: number
  half_day_unpaid_leaves: number
  paid_leaves: number
  unpaid_leaves: number
  user_id: number
  vacations: number
  day_offs: number
}

const UsersLeavesTable = () => {
  const [leavesData, setLeavesData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = React.useState(Pages.START_PAGE)
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const [selectedUser, setSelectedUser] = useState<IOptions | null>(null)
  const pageForRequest = selectedUser?.value ? Pages.START_PAGE : page

  const usersName: IOptions[] = usersNames.filter((user) => user.active).map((user) => ({
    value: user.id,
    label: user.full_name,
  }))

  useEffect(() => {
    fetchUsersLeaves(pageForRequest, selectedUser?.value)
      .then((response) => {
        setLeavesData(response.data.users_leaves)
        setTotalPages(response.data.pages)
      })
  }, [page, selectedUser?.value])

  return (
    <>
      {isMobile ?
        <MobileLeavesPage
          leavesData={leavesData}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        /> :
        <DesktopLeavesPage
          leavesData={leavesData}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          usersName={usersName}
          setSelectedUser={setSelectedUser}
        />
      }
    </>
  )
}

export default UsersLeavesTable
