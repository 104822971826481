import React from 'react'
import { isMobile } from 'react-device-detect'

const CategoriesIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M6.5 9C5.11929 9 4 7.88071 4 6.5C4 5.11929 5.11929 4 6.5 4C7.88071 4 9 5.11929 9 6.5C9 
        7.88071 7.88071 9 6.5 9ZM2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 
        8.98528 8.98528 11 6.5 11C4.01472 11 2 8.98528 2 6.5ZM4 20V15H9V20H4ZM2 14C2 13.4477 2.44772 
        13 3 13H10C10.5523 13 11 13.4477 11 14V21C11 21.5523 10.5523 22 10 22H3C2.44772 22 2 21.5523 
        2 21V14ZM15 9H20V4H15V9ZM14 2C13.4477 2 13 2.44772 13 3V10C13 10.5523 13.4477 11 14 11H21C21.5523 
        11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14ZM17.5 20C16.1193 20 15 18.8807 15 17.5C15 
        16.1193 16.1193 15 17.5 15C18.8807 15 20 16.1193 20 17.5C20 18.8807 18.8807 20 17.5 20ZM13 17.5C13 
        15.0147 15.0147 13 17.5 13C19.9853 13 22 15.0147 22 17.5C22 19.9853 19.9853 22 17.5 22C15.0147 
        22 13 19.9853 13 17.5Z" fill={ color && !isMobile ? color : '#303540'}/>
    </svg>
  )
}

export default CategoriesIcon
