import { useDispatch } from 'react-redux'

//import { useGetCustomer } from './useGetCustomer'
import { createCustomer } from '../../services/API/admin/customers'
import { addCustomer, addCustomerFailure, addCustomerSuccess } from '../../store/customersSlice'
import { Customer } from '../../types'
//import { getCustomerParams } from '../../constants'
import successNotify from '../../helpers/successNotify'
import dangerNotify from '../../helpers/dangerNotify'

export const useCreateCustomer = () => {
  //const getCustomerHook = useGetCustomer()
  const dispatch = useDispatch()

  return (newCustomer: Customer) => {
    dispatch(addCustomer())
    createCustomer(newCustomer)
      .then((response) => {
        dispatch(addCustomerSuccess(response.data))
        successNotify('Customer created')
        //getCustomerHook(getCustomerParams.company, getCustomerParams.representative, getCustomerParams.project)
      })
      .catch((error) => {
        dispatch(addCustomerFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
