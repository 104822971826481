import * as React from 'react'
import Box from '@mui/material/Box'
import { FieldError } from 'react-hook-form'
import { TextField } from '@mui/material'

interface Props {
  disabled?: boolean
  registerControl?: string
  required?: boolean
  type: string
  label?: string
  helperText?: string
  outlined?: boolean
  className?: string
  value?: string
  focus?: boolean
  defaultInput?: boolean
  onChange?:
    | ((event: { target: { value: React.SetStateAction<string> } }) => void)
    | ((event: { target: { value: string } }) => void)
  error?: FieldError | boolean
  placeholder?: string
  autoComplete?: string
  // eslint-disable-next-line
  register?: any
  max?: string
  min?: string
  size?: string
}

const ReactInput: React.FC<Props> = ({
  disabled = false,
  type,
  placeholder,
  autoComplete,
  error,
  registerControl = '',
  onChange,
  value,
  defaultInput = false,
  className,
  helperText,
  label,
  focus,
  register,
  min,
  max,
  required = false,
  size = 'small',
}) => {
  return (
    <>
      {register ? (
        <>
          {defaultInput ? (
            <input className={className} {...register(registerControl)} type={type} />
          ) : (
            <TextField
              className={className}
              id={Date.now().toString()}
              {...register(registerControl)}
              placeholder={placeholder}
              autoFocus={focus}
              helperText={helperText}
              fullWidth
              disabled={disabled}
              autoComplete={autoComplete}
              error={error}
              size={size}
              label={label}
              variant="outlined"
              type={type}
              required={required}
              sx={{
                '& fieldset': {
                  borderRadius: '10px',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: '#9EA5B8',
                  },
                  '&:hover fieldset': {
                    borderColor: '#9EA5B8',
                  },
                },
                '& .MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: '#9EA5B8',
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        <>
          {defaultInput ? (
            <input
              className={className}
              value={value}
              disabled={disabled}
              onChange={onChange}
              type={type}
              max={max}
              min={min}
            />
          ) : (
            <TextField
              id={Date.now().toString()}
              placeholder={placeholder}
              autoFocus={focus}
              disabled={disabled}
              helperText={helperText}
              autoComplete={autoComplete}
              value={value}
              onChange={onChange}
              fullWidth
              size="small"
              label={label}
              variant="outlined"
              type={type}
              required={required}
            />
          )}
        </>
      )}
    </>
  )
}

export default ReactInput
