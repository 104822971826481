import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import successNotify from '../../helpers/successNotify'
import { deleteResume } from '../../services/API/admin/resume'
import { deleteUserResume, deleteUserResumeFailure, deleteUserResumeSuccess } from '../../store/resumesSlice'
import { useFetchUserCvs } from './useFetchUserCvs'

export const useDeleteResume = () => {
  const fetchUserCvs = useFetchUserCvs()
  const dispatch = useDispatch()

  return (resumeId: number, userId: number) => {
    deleteUserResume()
    deleteResume(resumeId)
      .then((response) => {
        dispatch(deleteUserResumeSuccess(response.data))
        successNotify('Cv deleted')
        fetchUserCvs(userId)
      })
      .catch((error) => {
        dispatch(deleteUserResumeFailure(error))
        dangerNotify(error.response.data.error)
      })
  }
}
