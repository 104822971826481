import React from 'react'
import { Button } from '@mui/material'

interface Props {
    isDisabled: boolean
}

const AddFormItemButton: React.FC<Props> = ({ isDisabled }) => {
  return (
    <Button
      type="submit"
      variant="contained"
      disabled={isDisabled}
      color="primary"
      style={{ width: '200px', marginLeft: '15px' }}
    >
     Save
    </Button>

  )
}

export default AddFormItemButton
