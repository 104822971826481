import React from 'react'

const CustomerIcon = ({ color }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9996 2.42847C10.4877 2.42847 9.3318 3.05823 8.60087
      4.05945C7.89729 5.02322 7.64664 6.24524 7.71096 7.3905C7.77543 8.53847 8.1609 9.71507 8.86202 10.6286C9.57533
      11.558 10.6441 12.2404 11.9996 12.2404C13.355 12.2404 14.4238 11.558 15.1371 10.6286C15.8382 9.71507 16.2237
      8.53847 16.2882 7.3905C16.3525 6.24524 16.1019 5.02322 15.3983 4.05945C14.6673 3.05823 13.5115 2.42847 11.9996
      2.42847ZM11.9996 10.2404C11.3809 10.2404 10.8595 9.94627 10.4486 9.41095C10.0256 8.85976 9.75302 8.08338 9.70781
      7.27836C9.66245 6.47064 9.85049 5.73968 10.2162 5.23871C10.5546 4.7752 11.0986 4.42847 11.9996 4.42847C12.9005
      4.42847 13.4445 4.7752 13.7829 5.23871C14.1487 5.73968 14.3367 6.47064 14.2913 7.27836C14.2461 8.08338 13.9735
      8.85976 13.5505 9.41095C13.1397 9.94627 12.6182 10.2404 11.9996 10.2404ZM12 13.0614C8.17668 13.0614 4.0013 15.1875
      3.21882 19.5218C3.13674 19.9717 3.21377 20.4634 3.49129 20.8681C3.78417 21.2952 4.26867 21.5713 4.83946
      21.5713H19.1609C19.7321 21.5713 20.2164 21.2948 20.509 20.8676C20.7862 20.4628 20.8627 19.9717 20.7818
      19.5231L20.7818 19.523C19.9995 15.188 15.8237 13.0614 12 13.0614ZM18.7489 19.5713H5.25148C5.94048 16.7213 8.85533
      15.0614 12 15.0614C15.1446 15.0614 18.0595 16.7212 18.7489 19.5713Z" fill={color} />
    </svg>
  )
}

export default CustomerIcon
