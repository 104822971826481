import React from 'react'

import { IResume } from '../../../../../types/Resume/IResume'
import './mobileStyles.scss'
import { dateFormatMMYY } from '../../../../../helpers/displayingDateFormat'

interface Props {
  resume: IResume
}

const MobileExperienceTab: React.FC<Props> = ({ resume }) => {
  return (
    <div className="mobile_preview">
      <div className="experiences_list">
        {resume?.experiences?.map(experience => (
          <div key={experience.id} className="experience_item">
            <span className="experience_jobTitle">
              {experience.job_title}
            </span>
            <span className="experience_company">
              {experience.company}
            </span>
            <span className="experience_interval">
              {dateFormatMMYY(experience.job_started_on)} -
              {' '}{experience.job_ended_on ? dateFormatMMYY(experience.job_ended_on) : 'Ongoing'}
            </span>
            <div>
              {experience.responsibilities.map((responsibility, index) => (
                <div key={index} className="experience_responsibilities__list" >
                  <span className="experience_responsibility">
                    <span className="experience_responsibility__item">{responsibility.trim()}</span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MobileExperienceTab
