import React from 'react'
import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Box } from '@mui/material'

import ReactInput from '../../reusable/ReactInput'
import ReactForm from '../../reusable/ReactForm'
import { useCreateCategory } from '../../../hooks/category/useCreateCategory'
import { categorySchema } from '../../../helpers/validationShema'
import CustomButton from 'components/custom/CustomButton/CustomButton'

type Props = {
  closeModal: () => void
}

interface NewCategory {
  categoryName: string
}

interface Inputs {
  categoryName: string
}

const CreateCategory: React.FC<Props> = ({ closeModal }) => {
  const [categoryName, setCategoryName] = useState('')

  const createCategory = useCreateCategory()

  const onChangeCategory = (event: { target: { value: React.SetStateAction<string> } }) => {
    setCategoryName(event.target.value)
  }
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const newCategory: NewCategory = {
      categoryName: data.categoryName,
    }
    createCategory(newCategory)
    closeModal()
  }

  const resolver = yupResolver(categorySchema)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      categoryName: categoryName,
    },
    resolver,
  })

  return (
    <ReactForm onSubmit={handleSubmit(onSubmit)}>
      <ReactInput
        className="modal__input"
        type="text"
        size="medium"
        label="Title"
        register={register}
        registerControl="categoryName"
        error={!!errors.categoryName}
        helperText={errors.categoryName?.message}
        onChange={onChangeCategory}
      />

      <Box sx={{ display: 'flex', gap: '1rem', marginTop: '40px' }}>
        <CustomButton variant="outlined" onClick={() => closeModal()} text="Cancel"/>
        <CustomButton type="submit" text="Create" />
      </Box>
    </ReactForm>
  )
}

export default React.memo(CreateCategory)
