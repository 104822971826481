import React from 'react'
import { Control, Controller } from 'react-hook-form'
import Select from 'react-select'
import Box from '@mui/material/Box'

import { ILanguageInputs } from './CvAddLanguage'

interface IProps {
    languagesLevels: { label: string, value: string}[]
    selectLanguage: { label: string, value: string}[]
    control: Control<ILanguageInputs>
}

const CvLanguageForm: React.FC<IProps> = ({ selectLanguage, languagesLevels, control }) => {
  return (
    <>
      <Box className="modal__input" sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Box sx={{ width: '100%' }}>
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable={true}
                  placeholder="Language"
                  options={selectLanguage}
                />
              )}
            />
          </Box>

          <Box sx={{ width: '100%' }}>
            <Controller
              name="level"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable={true}
                  placeholder="Level"
                  options={languagesLevels}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CvLanguageForm
