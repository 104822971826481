import { createSlice } from '@reduxjs/toolkit'

import { Candidate, CandidateNames } from '../types'

const initialState = {
  indexData: <Candidate[]>[],
  indexNames: <CandidateNames[]>[],
  showData: <Candidate>{},
  error: {},
  isLoading: true,
  pages: 1,
  count: 0,
  filterData: {
    left: 'Candidate',
    right: 'All Candidates',
    filter: {}
  },
}

const candidatesSlice = createSlice({
  name: 'candidates',
  initialState,
  reducers: {
    fetchCandidates(state) {
      state.isLoading = true
    },
    fetchCandidatesSuccess(state, action) {
      state.indexData = action.payload.paginate_candidates
      state.pages = action.payload.pages
      state.count = action.payload.count
      state.isLoading = false
    },
    fetchCandidatesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    fetchCandidateNames(state) {
      state.isLoading = true
    },
    fetchCandidateNamesSuccess(state, action) {
      state.indexNames = action.payload.candidates
      state.isLoading = false
    },
    fetchCandidateNamesFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    getCandidate(state) {
      state.isLoading = true
    },
    getCandidateSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    getCandidateFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    addCandidate(state) {
      state.isLoading = true
    },
    addCandidateSuccess(state, action) {
      state.indexData.push(action.payload)
      state.isLoading = false
    },
    addCandidateFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
    updateCandidate(state) {
      state.isLoading = true
    },
    updateCandidateSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    updateCandidateFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    removeCandidate(state) {
      state.isLoading = true
    },
    removeCandidateSuccess(state, action) {
      state.indexData = state.indexData.filter((candidate: Candidate) => candidate.id !== action.payload)
      state.isLoading = false
    },
    removeCandidateFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
    filterCandidates(state, action) {
      state.filterData = action.payload
    },
  },
})

export default candidatesSlice.reducer
export const {
  fetchCandidates,
  fetchCandidatesFailure,
  fetchCandidatesSuccess,
  fetchCandidateNames,
  fetchCandidateNamesSuccess,
  fetchCandidateNamesFailure,
  getCandidate,
  getCandidateFailure,
  getCandidateSuccess,
  addCandidateFailure,
  addCandidateSuccess,
  addCandidate,
  updateCandidate,
  updateCandidateFailure,
  updateCandidateSuccess,
  removeCandidateSuccess,
  removeCandidateFailure,
  removeCandidate,
  filterCandidates
} = candidatesSlice.actions
