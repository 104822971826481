import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import {  createResumeSkill } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'
import { IResumeSkill } from '../../../types/Resume/IResumeSkill'

export const useCreateResumeSkill = () => {
  const getUserCv = useGetUserCv()

  return (skill_set: IResumeSkill, cvId: number) => {
    createResumeSkill(skill_set)
      .then(() => {
        successNotify('Skill set added')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Please fill in the contact section'))
  }
}
