export const API_URL = process.env.REACT_APP_BASE_URL

export default {
  API: API_URL,

  SIGN_IN: '/api/v1/session',
  CHANGE_PASSWORD: '/api/v1/users',
  SUB_ROLES: '/api/v1/sub_roles',
  RECORDS: '/api/v1/records',
  INTERVAL_RECORDS: '/api/v1/interval_records',
  PROJECTS: '/api/v1/projects',
  CATEGORIES: '/api/v1/categories',
  REQUESTS: '/api/v1/requests',
  USERS_NAMES: '/api/v1/users',
  PROFILES: '/api/v1/profiles',
  PROFILE_TECHNOLOGIES: '/api/v1/profile/profile_technologies',
  CHILDREN: '/api/v1/profile/children',
  UPDATE_CHILDREN: '/api/v1/profile/children/update',
  INVENTORIES: '/api/v1/profile/inventories',
  DASHBOARD_USERS: '/api/v1/dashboard/users',
  TECHNOLOGIES: '/api/v1/technologies',
  PROJECT_TECHNOLOGIES: '/api/v1/project/project_technologies',

  USER_LEAVE_COUNTER: '/api/v1/leave_counters',
  USERS_LEAVES_COUNTER: '/api/v1/admin/leave_counters',
  AVAILABLE_CONTRACTS: '/api/v1/available_contracts',
  NOTIFICATION: (notificationId) => `/api/v1/notifications/${notificationId}`,
  NOTIFICATIONS: '/api/v1/notifications',
  CSV_RECORDS: '/api/v1/admin/csv_records',

  ADMIN_USERS: '/api/v1/admin/users',
  ADMIN_USERS_CONTRACT: '/api/v1/admin/users',
  ADMIN_PROJECTS: '/api/v1/admin/projects',
  ADMIN_PROJECT: '/api/v1/admin/project',
  ADMIN_SORT_USERS: '/api/v1/admin/sort',
  ADMIN_CATEGORIES: '/api/v1/admin/categories',
  ADMIN_CUSTOMERS: '/api/v1/admin/customers',
  ALL_CUSTOMERS: '/api/v1/customers',
  ADMIN_SEARCH_CATEGORY: '/api/v1/admin/search_category',
  ADMIN_CONTRACTS: '/api/v1/admin/contracts',
  ADMIN_JOB_STATUSES: '/api/v1/admin/job_statuses',
  ADMIN_RECORDS: '/api/v1/admin/records',
  ADMIN_REQUESTS: '/api/v1/admin/requests',
  ADMIN_USER_LEAVE_COUNTER: '/api/v1/admin/leave_counters',
  USER_AVAILABLE_CONTRACTS: '/api/v1/admin/available_contracts',

  ADMIN_CV: '/api/v1/admin/resumes',
  DUPLICATE_CV: '/api/v1/admin/duplicate_cv',
  ADMIN_RESUME_EXPERIENCES: '/api/v1/admin/resume_experiences',
  ADMIN_RESUME_PROJECTS: '/api/v1/admin/resume_projects',
  ADMIN_RESUME_SKILLS: '/api/v1/admin/resume_skills',
  ADMIN_RESUME_EDUCATIONS: '/api/v1/admin/resume_educations',
  ADMIN_RESUME_CERTIFICATES: '/api/v1/admin/resume_certificates',
  ADMIN_RESUME_COURSES: '/api/v1/admin/resume_courses',
  ADMIN_RESUME_LANGUAGES: '/api/v1/admin/resume_languages',
  ADMIN_RESUME_BOOKS: '/api/v1/admin/resume_books',

  ADMIN_MONTHLY_REPORT: '/api/v1/admin/report/monthly',

  VACANCIES: '/api/v1/vacancies',
  VACANCY: (vacancyId) => `/api/v1/vacancies/${vacancyId}`,
  CANDIDATES: '/api/v1/candidates',
  CANDIDATE_NAMES: '/api/v1/candidate_names',
  CANDIDATE: (candidateId) => `/api/v1/candidates/${candidateId}`
}
