import React from 'react'

const TrashIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33333 2.875C7.14847 2.875 6.97611 2.94684 6.85292
        3.06695C6.73059 3.18623 6.66667 3.34253 6.66667 3.5V4.125H11.3333V3.5C11.3333 3.34253 11.2694 3.18623 11.1471
        3.06695C11.0239 2.94684 10.8515 2.875 10.6667 2.875H7.33333ZM13.3333 4.125V3.5C13.3333 2.79552 13.0461 2.12517
        12.5433 1.63495C12.0413 1.14557 11.3659 0.875 10.6667 0.875H7.33333C6.63414 0.875 5.95865 1.14557 5.45672
        1.63495C4.95393 2.12517 4.66667 2.79552 4.66667 3.5V4.125H3.16667H1.5C0.947715 4.125 0.5 4.57272 0.5 5.125C0.5
        5.67728 0.947715 6.125 1.5 6.125H2.16667V16.5C2.16667 17.2045 2.45393 17.8748 2.95672 18.365C3.45865 18.8544
        4.13414 19.125 4.83333 19.125H13.1667C13.8659 19.125 14.5413 18.8544 15.0433 18.365C15.5461 17.8748 15.8333
        17.2045 15.8333 16.5V6.125H16.5C17.0523 6.125 17.5 5.67728 17.5 5.125C17.5 4.57272 17.0523 4.125 16.5
        4.125H14.8333H13.3333ZM12.3333 6.125H5.66667H4.16667V16.5C4.16667 16.6575 4.23059 16.8138 4.35292 16.933C4.47611
        17.0532 4.64847 17.125 4.83333 17.125H13.1667C13.3515 17.125 13.5239 17.0532 13.6471 16.933C13.7694 16.8138
        13.8333 16.6575 13.8333 16.5V6.125H12.3333Z"
        fill={color || '#8992A6'}
      />
    </svg>
  )
}

export default TrashIcon
