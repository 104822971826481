import React, { useEffect } from 'react'
import { Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Box, IconButton } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { useAuth } from 'context/auth'
import { RequestLimits, Status } from 'enums/RequestEnums'
import { tableCellStyles, tableHeadStyles } from 'utilts/styles'
import { IRequest, User } from 'types'
import { renderUser } from 'helpers/renderName'
import { dateFormatDDMMYY } from 'helpers/displayingDateFormat'
import Modal from 'components/reusable/Modal'
import { fileNameFromUrl } from 'helpers/findNameOfFile'
import CustomPagination from 'components/reusable/Pagination/Pagination'
import RequestStatusType from 'components/requests/RequestType/RequestStatusType'
import { useUpdateRequest } from '../../hooks/request/useUpdateRequest'
import { StatusType } from '../../enums/Types'
import { BENCH_MANAGER_FIRST_NAME, BENCH_MANAGER_LAST_NAME, tabDayDate, tabMonthDate } from '../../constants'
import { AiOutlineDelete } from 'react-icons/all'
import { useGetAdminRequests } from '../../hooks/request/useGetRequests'
import { IOptions } from '../../types/ISelectOptions'
import { fetchRequestsSuccess } from '../../store/requestsSlice'
import ApprovedExtraSickLeave from '../../icons/ApprovedExtraSickLeave'
import ExtraSickLeave from '../../icons/ExtraSickLeave'
import { isMobile } from 'react-device-detect'
import { RequestCard } from './RequestCard'
import { FileIcon } from 'icons'

interface Props {
  page: number
  setPage: (page: number) => void
  onDeleteRequest: (id: number) => void
  requestPages: number
  alignment: string
  filterDate: Date | null
  monthDate: Date
  requestCount: number
  onDeclineSubmit: (value: IRequest) => void
  onApproveSubmit: (value: IRequest) => void
  filterUser: IOptions | null
  alignmentType: Date | string
  handleOpen: () => void
}

const tableHeaders = [
  'User',
  'Type',
  'Extra sick leave',
  'Managers',
  'Start Date',
  'End Date',
  'Attached Photo',
  'Description',
  'Status',
  'Options',
]

const AdminRequestsTable: React.FC<Props> = ({
  page,
  setPage,
  onDeleteRequest,
  requestPages,
  filterDate,
  monthDate,
  alignment,
  requestCount,
  onDeclineSubmit,
  onApproveSubmit,
  filterUser,
  alignmentType,
  handleOpen,
}) => {
  const { currentUser } = useAuth()
  const requests = useSelector((state) => state.requests.indexData)
  const usersNames = useSelector((state) => state.users.indexNamesData)
  const [illnessPhoto, setIllnessPhoto] = React.useState<string | undefined>('')
  const updateRequestHook = useUpdateRequest()
  const getRequestsHook = useGetAdminRequests()
  const dispatch = useDispatch()
  const toastMessage = 'Successfully modified!'

  useEffect(() => {
    dispatch(fetchRequestsSuccess([]))
    getRequestsHook({
      page,
      limit: RequestLimits.REQUEST_DASHBOARD_LIMIT,
      is_pending: false,
      by_user_id: filterUser?.value,
      by_date: tabDayDate(alignment, filterDate),
      by_month: tabMonthDate(alignment, monthDate),
    })
  }, [page, filterDate, filterUser, alignmentType, alignment, monthDate])

  const handleChangeLeave = (request: IRequest) => {
    const type =
      request.request_type === StatusType.EXTRA_SICK_LEAVE
        ? StatusType.FULL_DAY_SICK_LEAVE
        : StatusType.EXTRA_SICK_LEAVE
    const tabDate = tabDayDate(alignment, filterDate) as string
    const tabMonth = tabMonthDate(alignment, monthDate) as string
    const updatedRequest = {
      id: request.id as number,
      request_type: type,
      status: request.status as number,
      page: page,
    }

    updateRequestHook({
      request: updatedRequest,
      by_date: tabDate,
      by_month: tabMonth,
      by_user_id: filterUser?.value,
      requestCount,
      message: toastMessage,
    })
  }

  return (
    <>
      <Modal
        title="Attached Photo"
        component={<img className="requests-img" src={illnessPhoto} alt="Attached file" />}
        open={!!illnessPhoto}
        handleClose={() => setIllnessPhoto(undefined)}
      />

      {isMobile ? (
        <div className="requests-mobile-wrapper">
          <div className="mobile-button" onClick={handleOpen}>
            +
          </div>
          {requests?.length ? (
            requests.map((request) => (
              <RequestCard
                key={'REQUEST' + request.id}
                request={request}
                onDelete={onDeleteRequest}
                onApprove={() => onApproveSubmit(request)}
                onDecline={() => onDeclineSubmit(request)}
                onExtra={() => handleChangeLeave(request)}
                onShowFile={setIllnessPhoto}
                isAdmin
              />
            ))
          ) : (
            <h3>Requests not found</h3>
          )}
          <CustomPagination totalPages={requestPages} page={page} setPage={setPage} />
        </div>
      ) : (
        <Box sx={{ paddingBottom: '15px' }}>
          <div className="requests-table__head">
            <h4>Requests List</h4>
          </div>

          {requests?.length ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead className="requests-table__title">
                  <TableRow>
                    {tableHeaders.map((header) => (
                      <TableCell align="left" sx={tableHeadStyles} key={header}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requests?.map((request) => {
                    const isSickLeaveStatus =
                      request.request_type === StatusType.FULL_DAY_SICK_LEAVE &&
                      String(request.status) === Status.PENDING
                    const userManagersWithoutBench = request.managers?.filter(
                      (m) => m.first_name != BENCH_MANAGER_FIRST_NAME && m.last_name != BENCH_MANAGER_LAST_NAME,
                    )
                    const isPending = String(request.status) === Status.PENDING
                    const isExtraSickLeaveIconShow =
                      isSickLeaveStatus || request.request_type === StatusType.EXTRA_SICK_LEAVE

                    return (
                      <TableRow key={request.id} className="requests-table__style">
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '12%' }}>
                          <Link to={`/users/${request.user_id}`}>{renderUser(request.user_id, usersNames)}</Link>
                        </TableCell>
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '16%' }}>
                          <RequestStatusType request={request} />
                        </TableCell>
                        <TableCell align="center" sx={tableCellStyles} style={{ width: '9%' }}>
                          {isSickLeaveStatus ? (
                            <ExtraSickLeave handleChangeLeave={handleChangeLeave} request={request} />
                          ) : isPending && isExtraSickLeaveIconShow ? (
                            <ApprovedExtraSickLeave handleChangeLeave={handleChangeLeave} request={request} />
                          ) : null}
                        </TableCell>
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '13%' }}>
                          {userManagersWithoutBench?.map((manager) => (
                            <div key={manager.id}>
                              {manager.first_name} {manager.last_name}
                            </div>
                          ))}
                        </TableCell>
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '4%' }}>
                          {dateFormatDDMMYY(request.start_date)}
                        </TableCell>
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '4%' }}>
                          {request.end_date && dateFormatDDMMYY(request.end_date)}
                        </TableCell>
                        {request.attached_file ? (
                          <TableCell
                            className="requests-img-modal"
                            align="left"
                            sx={tableCellStyles}
                            style={{ width: '11%' }}
                            onClick={() => setIllnessPhoto(request.attached_file)}
                          >
                            <FileIcon /> {fileNameFromUrl(request.attached_file)}
                          </TableCell>
                        ) : (
                          <TableCell sx={tableCellStyles} style={{ width: '11%' }}></TableCell>
                        )}
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '16%' }}>
                          {request.note}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={tableCellStyles}
                          className="requests-desktop-label"
                          style={{ width: '10%' }}
                        >
                          {isPending ? (
                            <>
                              <IconButton
                                color="success"
                                onClick={() => onApproveSubmit(request)}
                                className="approve-button"
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() => onDeclineSubmit(request)}
                                className="decline-button"
                              >
                                <ClearIcon />
                              </IconButton>
                            </>
                          ) : isPending ? (
                            <div className="requests-desktop-label requests__pending">Pending</div>
                          ) : String(request.status) === Status.APPROVED ? (
                            <div className="requests-desktop-label requests__approved">Approved</div>
                          ) : String(request.status) === Status.REJECTED ? (
                            <div className="requests-desktop-label requests__rejected">Rejected</div>
                          ) : null}
                        </TableCell>
                        <TableCell align="left" sx={tableCellStyles} style={{ width: '5%' }}>
                          {isPending && request.user_id === currentUser?.id && (
                            <AiOutlineDelete
                              color="#8992A6"
                              size="20"
                              cursor="pointer"
                              onClick={() => onDeleteRequest(request.id as number)}
                            />
                          )}
                          {!isPending && (
                            <AiOutlineDelete
                              color="#8992A6"
                              size="20"
                              cursor="pointer"
                              onClick={() => onDeleteRequest(request.id as number)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <h3 className="text-center text-muted">Requests not found</h3>
          )}

          <CustomPagination totalPages={requestPages} page={page} setPage={setPage} />
        </Box>
      )}
    </>
  )
}

export default AdminRequestsTable
