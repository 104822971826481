import React from 'react'

import DefaultSelect from '../../custom/DefaultSelect/DefaultSelect'
import UserIcon from '../../../icons/UserIcon'
import CustomDatepicker from '../../custom/CustomDatepicker/CustomDatepicker'
import { IStringOptions } from '../../../types/ISelectOptions'
import { dashboardFilterSelect } from './dashboardStyles'
import UsersIcon from '../../../icons/UsersIcon'
import { arrowBackItem, arrowNextItem } from '../../../helpers/userDatepickerArrows'

type Props = {
    option: IStringOptions
    setOption: React.Dispatch<React.SetStateAction<{label: string, value: string}>>
    setPage: (page: number) => void
    selectFilterOptions?: IStringOptions[]
    selectUsersSubRolesOptions?: IStringOptions[]
    date: Date
    setDate: (value: Date, keyboardInputValue?: (string | undefined)) => void
    subRole: IStringOptions
    onHandleSubRole: (newValue: IStringOptions) => void
}

const DashboardUsersFilters: React.FC<Props> = ({
  option,
  date,
  setDate,
  setPage,
  setOption,
  selectFilterOptions,
  subRole,
  selectUsersSubRolesOptions,
  onHandleSubRole
}) => {
  const nextArrow = () => arrowNextItem(date, setDate, setPage)
  const prevArrow = () => arrowBackItem(date, setDate, setPage)

  return (
    <div className="dashboard_main__filters">
      {option?.value === 'available' &&
      <div className="dashboard_main__select">
        <DefaultSelect
          styles={dashboardFilterSelect}
          options={selectUsersSubRolesOptions}
          value={subRole}
          onChange={newValue => onHandleSubRole(newValue as IStringOptions)}
          isSearchable={false}
          prefixIcon={<UsersIcon/>}
        />
      </div>
      }
      <div className="dashboard_main__select">
        <DefaultSelect
          styles={dashboardFilterSelect}
          options={selectFilterOptions}
          value={option}
          onChange={newValue => setOption(newValue as IStringOptions)}
          prefixIcon={<UserIcon/>}
          isSearchable={false}
        />
      </div>
      {option?.value !== 'available' &&
        <div className="dashboard_main__datepicker">
          <CustomDatepicker
            value={date}
            onChange={(value) => setDate(value as Date)}
            mask={'__.__.____'}
            inputType="day"
            maxDate={new Date()}
            datePickerType="month"
            borderInputColor="#D4D9E2"
            arrowNextItem={nextArrow}
            arrowBackItem={prevArrow}
            disableClearButton={true}
          />
        </div>
      }
    </div>
  )
}

export default DashboardUsersFilters
