import dangerNotify from '../../../helpers/dangerNotify'
import successNotify from '../../../helpers/successNotify'
import {  updateCvExperience } from '../../../services/API/admin/resume'
import { useGetUserCv } from '../useGetUserCv'
import { IResumeExperience } from '../../../types/Resume/IResumeExperience'

export const useUpdateCvExperience = () => {
  const getUserCv = useGetUserCv()

  return (resume_experience: IResumeExperience, companyId: number, cvId: number ) => {
    updateCvExperience(resume_experience, companyId)
      .then(() => {
        successNotify('Company updated')
        getUserCv(cvId)
      })
      .catch(() => dangerNotify('Update error'))
  }
}
