import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useSelector } from 'react-redux'

import UsersCv from '../UsersCv'
import CvAddExperienceCompany from './CvAddExperienceCompany'
import CvNavButtons from '../CvNavButtons'
import { useDeleteResumeExperience } from '../../../../../hooks/resume/experience/useDeleteResumeExperience'
import Modal from '../../../../reusable/Modal/index.tsx'
import CvUpdateExperienceCompany from './CvUpdateExperienceCompany'
import { IResume } from '../../../../../types/Resume/IResume'
import { cvNavLinks } from '../../../../../constants'
import { IResumeExperience } from '../../../../../types/Resume/IResumeExperience'
import { dateFormatMMYY } from '../../../../../helpers/displayingDateFormat'
import { useGetUserCv } from '../../../../../hooks/resume/useGetUserCv'
import { useChangeCvExperiencesSorting } from '../../../../../hooks/resume/experience/useChangeCvExperiencesSorting'

const CvExperienceCompanies = () => {
  const [open, setOpen] = useState(false)
  const [currentCompany, setCurrentCompany] = useState<IResumeExperience | null>(null)
  const { resumeId } = useParams()
  const [company, setCompany] = useState<IResumeExperience>()
  const resume = useSelector((state: { resumes: { showData: IResume } }) => state.resumes.showData)
  const nextPath = cvNavLinks.PORTFOLIO
  const prevPath = cvNavLinks.CONTACT_SECTION
  const deleteCompany = useDeleteResumeExperience()
  const getUserCv = useGetUserCv()
  const changeCvExperiencesSorting = useChangeCvExperiencesSorting()

  useEffect(() => {
    if (resumeId) {
      getUserCv(+resumeId)
    }
  }, [resumeId])

  const onDeleteResumeExperience = (id: number | undefined) => {
    deleteCompany(id as number, Number(resumeId))
  }

  const onEditCompany = (company: IResumeExperience) => {
    setOpen(!open)
    setCompany(company)
  }

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, company: IResumeExperience) => {
    setCurrentCompany(company)
  }

  const dropHandler = (e: React.DragEvent<HTMLDivElement>, company: IResumeExperience) => {
    e.preventDefault()
    if (currentCompany?.id != company.id) {
      changeCvExperiencesSorting(
        currentCompany?.id as number,
        company.id as number,
        currentCompany?.position as number,
        company.position,
        Number(resumeId),
      )
    }
  }

  const dragOverHandler = (e: SyntheticEvent) => e.preventDefault()

  const editCompany = (
    <CvUpdateExperienceCompany setOpen={setOpen} company={company as IResumeExperience} resumeId={Number(resumeId)} />
  )

  return (
    <div className="cv__form">
      <UsersCv resumeId={resume?.id} userId={resume?.user_id} />
      <div className="cv__form_inner">
        <h4 className="cv__form_title">Work experience</h4>
      </div>
      <Box sx={{ display: 'flex', width: '95%', gap: '10px' }}>
        <div className="leftSide_section">
          <Box sx={{ width: '60%' }}>
            <CvAddExperienceCompany resumeId={Number(resumeId)} cv={resume} />
          </Box>
        </div>
        <Box
          sx={{
            display: 'flex',
            width: '50%',
            minHeight: '600px',
            flexDirection: 'column',
            marginBottom: 'auto',
            gap: '15px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              maxHeight: '500px',
              flexDirection: 'column',
              gap: '15px',
              overflowY: 'auto',
              padding: '15px',
              marginBottom: '30px',
            }}
          >
            {resume?.experiences?.map((experience) => (
              <Box
                draggable={true}
                onDragStart={(e) => dragStartHandler(e, experience)}
                onDragOver={(e) => dragOverHandler(e)}
                onDrop={(e) => dropHandler(e, experience)}
                key={experience.id}
                sx={{
                  padding: '15px',
                  width: '98%',
                  height: 'auto',
                  border: '1px solid #dee0df',
                  borderRadius: 2,
                  cursor: 'pointer',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '17px', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {experience.company}
                    <Box sx={{ color: '#65696d', fontSize: '14px', marginLeft: '10px' }}>
                      ({experience.job_started_on && dateFormatMMYY(experience.job_started_on)} -
                      {!experience.job_ended_on ? 'ongoing' : dateFormatMMYY(experience.job_ended_on)})
                    </Box>
                    <Box sx={{ color: '#000', fontSize: '14px', marginLeft: '10px' }}>
                      {experience.city}, {experience.country}
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton onClick={() => onEditCompany(experience)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteResumeExperience(experience.id)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ fontStyle: 'italic', fontSize: 12 }}>{experience.description}</Box>
                <Box sx={{ color: '#000', fontSize: '14px' }}>{experience.job_title}</Box>
                <div>
                  {experience.responsibilities.map((responsibility, index) => (
                    <div key={index} className="cv__experience_responsibility">
                      {responsibility}
                    </div>
                  ))}
                </div>
              </Box>
            ))}
          </Box>
          {open && (
            <Modal title={'Edit company'} component={editCompany} open={open} handleClose={() => setOpen(false)} />
          )}
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', marginTop: 'auto' }}>
            <CvNavButtons resumeId={resumeId} nextPath={nextPath} prevPath={prevPath} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CvExperienceCompanies
