import React from 'react'
import { Link } from 'react-router-dom'

import { IResume } from '../../../../../types/Resume/IResume'
import { defaultBookCover, defaultAvatar } from '../../../../../images'
import './mobileStyles.scss'

interface Props {
  resume: IResume
}

const MobileGeneralTab: React.FC<Props> = ({ resume }) => {
  return (
    <div className="mobile_preview">
      <div className="cv_header">
        <div className="cv_header__inner">
          <div className="cv_avatar">
            {resume?.avatar ?
              <img className="cv_avatar__image" src={resume?.avatar} alt="avatar.pic" /> :
              <img className="cv_avatar__image" src={defaultAvatar} alt="avatar.pic" />
            }
          </div>
          <div className="cv_name">
            {resume?.name}
          </div>
        </div>
        <div className="cv_summary">
          {resume.summary}
        </div>
        <hr/>
      </div>
      <div className="user_data__wrapper">
        <span className="user_data">
            Role: {' '} <span className="user_data__item">{resume.role}</span>
        </span>
        <span className="user_data">
           Github: {' '} <span className="user_data__item">{resume.github_url}</span>
        </span>
        <span className="user_data">
           Location: {' '} <span className="user_data__item">{resume.location}</span>
        </span>
        <hr/>
      </div>
      <div className="skills_wrapper">
        <div className="cv_block__title">
           Skills:
        </div>
        {resume?.skills?.map(skillName => (
          <div key={skillName.id} className="skill_items">
            <span className="skill_name">
              {skillName.skill_set}
            </span>
            <div>
              {skillName.skills.map((skill, index) => (
                <div key={index} className="skill_item">
                  <span className="skill">
                    {skill}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
        <hr/>
      </div>
      <div className="languages_wrapper">
        <span className="cv_block__title">
          Languages:
        </span>
        {resume?.languages?.map(language => (
          <div key={language.id} className="language_item">
            <span className="language_name">
              {language.name}
            </span>
            <span className="language_level">
              {language.level}
            </span>
          </div>
        ))}
        <hr />
      </div>
      {resume?.certificates?.length > 0 &&
        <div className="certificates_wrapper">
          <span className="cv_block__title">
             Certifications:
          </span>
          {resume?.certificates?.map(certificate => (
            <div key={certificate.id} className="certificate_item">
              <span className="certificate_name">
                {certificate.name}
              </span>
              <div>
                <Link to={certificate.url} className="certificate_url">
                  {certificate.url}
                </Link>
              </div>
            </div>
          ))}
          <hr />
        </div>
      }
      <div className="books_wrapper">
        <span className="cv_block__title">
          Books:
        </span>
        {resume?.books?.map(book => (
          <div key={book.id} className="books_list">
            <div className="book_item">
              <div className="book_cover">
                {!book.cover ?
                  <img className="book_img" src={defaultBookCover} alt="cover.jpg"/> :
                  <img className="book_img" src={book.cover} alt="cover.jpg"/>
                }
              </div>
              <div className="book_inner">
                <span className="book_title">
                  {book.title}
                </span>
                <span className="book_author">
                  {book.author}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MobileGeneralTab
