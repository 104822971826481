import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'

import { createAdminRecord, createRecords } from '../../services/API/record'
import { addRecordFailure, addRecord } from '../../store/recordsSlice'
import dangerNotify from '../../helpers/dangerNotify'
import { useUpdateRequest } from 'hooks/request/useUpdateRequest'
import { NumberStatus } from 'enums/RequestEnums'
import { IRequest } from 'types'

type Params = {
  record: any
  request: IRequest
  page: number
  by_date: string | null
  by_month: string | null
  by_user_id?: number
  message: string
  isInterval?: boolean
}

export const useApproveAdminRecords = () => {
  const dispatch = useDispatch()
  const updateRequestHook = useUpdateRequest()

  return ({
    record,
    request: { id, request_type },
    page,
    by_date,
    by_month,
    by_user_id,
    message,
    isInterval,
  }: Params) => {
    if (!isInterval) {
      dispatch(addRecord())
    }

    const func = isInterval ? createRecords : createAdminRecord

    func(record)
      .then(() => {
        updateRequestHook({
          request: {
            id,
            status: NumberStatus.APPROVED,
            request_type,
            page,
          },
          by_date,
          by_month,
          by_user_id,
          message,
        })
      })
      .catch((error: AxiosError) => {
        if (!isInterval) dispatch(addRecordFailure(error))
        dangerNotify(error?.response?.data.error)
      })
  }
}
