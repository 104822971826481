import React, { useState } from 'react'
import { FiEdit } from 'react-icons/all'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { Link } from 'react-router-dom'

import { useDeleteProject } from 'hooks/project/useDeleteProject'
import { tableCellStyles, tableHeadStyles } from 'utilts/styles'
import DeleteIcon from '../../reusable/DeleteIcon'
import Modal from 'components/reusable/Modal'
import { EditProject } from '../EditProject/EditProject'
import { BENCH_PROJECT, projectsListTableRows } from '../../../constants'
import { NewProject, Customer, Technology, User, Project } from '../../../types'
import { renderCustomer, renderManager, renderProjectTechnology } from '../../../helpers/renderName'
import { ItemsOnPage } from '../../../enums/DashboardEnums'
import { IOptions, IStringOptions } from '../../../types/ISelectOptions'

interface Props {
  projects: NewProject[]
  technologies: Technology[]
  customers: Customer[]
  usersNames: User[]
  page: number
  projectTechnologies: IProjectTechnology[]
  setTechnology: (technology: IOptions | null) => void
  setApplication: (application: IOptions | null) => void
  setProject: (project: IOptions | null) => void
  setCompany: (company: IOptions | null) => void
  setManager: (manager: IOptions | null) => void
  setFromScratch: (value: IStringOptions | null) => void
  setPage: (page: number) => void
}

export interface IProjectTechnology {
  project_id: number
  technology_id: number
}

const ProjectsList: React.FC<Props> = ({
  projects,
  technologies,
  customers,
  usersNames,
  page,
  projectTechnologies,
  setTechnology,
  setApplication,
  setManager,
  setProject,
  setCompany,
  setFromScratch,
  setPage,
}) => {
  const [open, setOpen] = useState(false)
  const [selectedProject, setSelectedProject] = useState<NewProject | undefined>()
  const deleteProjectHook = useDeleteProject()
  const limit = ItemsOnPage.LIMIT
  let numbering = 1

  function deleteProject(project: NewProject) {
    if (window.confirm('Are you sure?')) {
      deleteProjectHook(project, page)
      setTechnology(null)
      setApplication(null)
      setManager(null)
      setProject(null)
      setCompany(null)
      setFromScratch(null)
    }
  }

  const handleEditForm = (project: NewProject) => () => {
    setOpen(!open)
    setSelectedProject(project)
  }

  const editProject =
    <EditProject
      projectTechnologies={projectTechnologies}
      selectedProject={selectedProject}
      technologies={technologies}
      setOpen={setOpen}
      customers={customers}
      usersNames={usersNames}
      setProject={setProject}
      setTechnology={setTechnology}
      setApplication={setApplication}
      setManager={setManager}
      setCompany={setCompany}
      setFromScratch={setFromScratch}
      setPage={setPage}
    />

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {projectsListTableRows.map((row: string, index: number) => (
                <TableCell align="left" scope="col" sx={tableHeadStyles} key={index}>
                  {row}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {projects?.map((project: NewProject) => {
              const is_bench = project.name == BENCH_PROJECT

              return (
                <TableRow key={project.id}>
                  <TableCell align="left" sx={tableCellStyles}>
                    {page > 1 ?
                      `${numbering < limit ? page - 1 : ''}${numbering < limit ? numbering++ : page * limit}` :
                      numbering++
                    }
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    <Link to={`/projects/${project.id}`}>{project.name}</Link>
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {renderCustomer(project.customer_id as number, customers)}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {projectTechnologies && projectTechnologies.map((technology: IProjectTechnology) => (
                      technology.project_id === project.id &&
                        <div key={technology.technology_id}>
                          {renderProjectTechnology(technology.technology_id, technologies)}
                        </div>
                    ))}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {project.from_scratch ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {project.app.join(',')}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {project.industry}
                  </TableCell>
                  <TableCell align="left" sx={tableCellStyles}>
                    {renderManager(project.id, projects as Project[], usersNames)}
                  </TableCell>
                  <TableCell align="left" sx={{ minWidth: '100px' }}>
                    <span onClick={() => !project.has_records && !is_bench && deleteProject(project)}>
                      <DeleteIcon
                        isRecords={project.has_records || is_bench}
                        text={is_bench ? 'bench project' : 'project'} />
                    </span>
                    <FiEdit size="20" cursor="pointer" onClick={handleEditForm(project)}/>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {open && <Modal title="Edit Project" component={editProject} open={open} handleClose={() => setOpen(!open)} />}
    </>
  )
}

export default ProjectsList
