import React from 'react'

import { ProjectProfile } from 'types/Manage/Project/ProjectProfile'

const DesktopClientInfoProject: React.FC<ProjectProfile> = ({
  projectData,
  customers,
}) => {
  const customer = customers?.find(customer => customer.id === projectData.customer_id)

  return (
    <div>
      <div className="project-profile_manager">
        <div className="project-profile_manager-name">
          {!customer?.full_legal_name ? 'None' : customer?.full_legal_name}
        </div>
        <div className="project-profile_position">
          {customer?.title}
        </div>
      </div>
      <div className="project-profile_client-info-desktop-wrap">
        <div>
          <div className="project-profile_client-info-desktop">
            <span className="project-profile_additonal-title">
            Email:
            </span>
            <span className="project-profile_additional-item-email">
              {!customer?.email ? '<None>' : customer?.email}
            </span>
          </div>
        </div>
        <div className="project-profile_client-info-desktop-even"> 
          <div className="project-profile_client-info-desktop-company">
            <span className="project-profile_additonal-title">
                Company:
            </span>
            <div className="project-profile_additional-item">
              {!customer?.company_name ? '<None>' : customer?.company_name}
            </div>
          </div>
        </div>
        <div>
          <div className="project-profile_client-info-desktop">
            <span className="project-profile_additonal-title">
                Location:
            </span>
            <span className="project-profile_additional-item">
              {!customer?.company_address ? '<None>' : <span>{customer?.company_address}</span>}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesktopClientInfoProject
