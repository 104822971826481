import React, { Dispatch, SetStateAction } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'

interface Props {
  isCropped: boolean
  image: string | undefined
  setImage: Dispatch<SetStateAction<string>>
  setReadyFile: Dispatch<SetStateAction<undefined>>
  cropper: undefined
  setCropper: Dispatch<SetStateAction<undefined>>
  setIsCropped: Dispatch<SetStateAction<boolean>>
}

const CvAvatarCropper: React.FC<Props> = ({
  setReadyFile,
  image,
  setImage,
  cropper,
  setCropper,
  setIsCropped,
  isCropped,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result as string)
    }
    reader.readAsDataURL(files[0])
  }

  const getCropData = () => {
    const avatarString = cropper.getCroppedCanvas().toDataURL()
    const base64StringtoFile = (base64String: string, filename: string) => {
      const arr = base64String.split(',')
      const mime: string = arr[0].match(/:(.*?);/ )[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    }
    const readyFile = base64StringtoFile(avatarString, 'Avatar')

    setReadyFile(readyFile)
    setIsCropped(!isCropped)
  }

  return (
    <>
      <Box sx={{ width: '350px' }}>
        <Button variant="contained" component="label" color="secondary">
          Upload avatar
          <input type="file" hidden onChange={onChange} />
        </Button>
      </Box>
      {!isCropped && <>
        <div style={{ width: '90%' }}>
          <Cropper
            style={{ height: 'auto', width: '90%', marginTop: '10px' }}
            zoomTo={0.5}
            aspectRatio={1}
            preview=".img-preview"
            src={image}
            viewMode={1}
            height={10}
            width={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance)
            }}
            guides={true}
          />
        </div>
        {image &&
          <Button
            sx={{ marginTop: '10px' }}
            variant="contained"
            size="small"
            color="success"
            onClick={getCropData}>
              Crop avatar
          </Button>
        }
      </>}
    </>
  )
}

export default CvAvatarCropper
