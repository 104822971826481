import React from 'react'

const UserIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.81183 0C7.29994 0 6.14406 0.629761 5.41313 1.63099C4.70954
      2.59475 4.4589 3.81678 4.52322 4.96204C4.58769 6.11 4.97315 7.2866 5.67428 8.20015C6.38758 9.12957 7.45637
      9.81193 8.81183 9.81193C10.1673 9.81193 11.2361 9.12957 11.9494 8.20015C12.6505 7.2866 13.036 6.11 13.1004
      4.96204C13.1648 3.81678 12.9141 2.59475 12.2105 1.63099C11.4796 0.629761 10.3237 0 8.81183 0ZM8.81183
      7.81193C8.19319 7.81193 7.67172 7.51781 7.26087 6.98248C6.83785 6.4313 6.56528 5.65491 6.52007 4.8499C6.47471
      4.04217 6.66275 3.31122 7.02847 2.81024C7.36686 2.34673 7.9109 2 8.81183 2C9.71276 2 10.2568 2.34673 10.5952
      2.81024C10.9609 3.31122 11.1489 4.04217 11.1036 4.8499C11.0584 5.65491 10.7858 6.4313 10.3628 6.98248C9.95194
      7.51781 9.43047 7.81193 8.81183 7.81193ZM8.81225 10.6329C4.98893 10.6329 0.813557 12.7591 0.0310807
      17.0933C-0.0509997 17.5433 0.0260303 18.0349 0.303541 18.4396C0.596428 18.8668 1.08093 19.1429 1.65172
      19.1429H15.9732C16.5444 19.1429 17.0287 18.8664 17.3213 18.4391C17.5984 18.0343 17.675 17.5432 17.5941
      17.0946L17.594 17.0946C16.8117 12.7595 12.636 10.6329 8.81225 10.6329ZM15.5611 17.1429H2.06374C2.75274
      14.2928 5.66759 12.6329 8.81225 12.6329C11.9568 12.6329 14.8718 14.2928 15.5611 17.1429Z" fill="#8992A6"/>
    </svg>
  )
}

export default UserIcon
