import React from 'react'
import { AxiosRequestConfig } from 'axios'
import { PropsValue } from 'react-select'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useDispatch, useSelector } from 'react-redux'

import { Project, User } from 'types'
import { IOptions } from 'types/ISelectOptions'
import { getMonthlyReport } from 'services/API/admin/monthly-report'
import { fetchReportFailure, fetchReportSuccess } from 'store/montlyReportSlice'
import dangerNotify from 'helpers/dangerNotify'
import MonthlyReportFilters from 'components/admin/MonthlyReport/MonthlyReportFilters'
import { Status } from 'enums/RequestEnums'
import { TableLimits } from 'enums/TableLimits'
import { IOmitedOptions } from 'containers/MonthlyReportContainer/MonthlyReportContainer'

type Props = {
  month: Date
  setMonth: React.Dispatch<React.SetStateAction<Date>>
  allStatuses?: Status[]
  usersNames: User[]
  projects: Project[]
  typeValue: PropsValue<IOptions> | undefined
  setTypeValue: React.Dispatch<React.SetStateAction<PropsValue<IOptions> | undefined>>
  selectedValue: PropsValue<IOptions> | null
  setSelectedValue: React.Dispatch<React.SetStateAction<IOmitedOptions[] | null>>
  alignment: string
  setAlignment: React.Dispatch<React.SetStateAction<string>>
  selectRef?: React.MutableRefObject<undefined>
  paramsType: (limit: number | undefined) => AxiosRequestConfig
  closeModal: () => void
  setPage: React.Dispatch<React.SetStateAction<Pages>>
}

const MobileReportFilters: React.FC<Props> = ({ 
  month,
  setMonth,
  allStatuses,
  usersNames,
  projects,
  selectedValue,
  setSelectedValue,
  typeValue,
  setTypeValue,
  alignment,
  setAlignment,
  paramsType,
  closeModal,
  selectRef,
  setPage
}) => {
  const reportRecordsCount = useSelector(
    (state: { monthlyReport: { count: number} }) => state.monthlyReport.count,
  )

  const dispatch = useDispatch()

  const closeModalForm = () => {
    setTypeValue(null)
    setSelectedValue(null)
    getMonthlyReport(paramsType(TableLimits.REPORT_LIMIT))
      .then((response) => {
        dispatch(fetchReportSuccess(response.data))
        localStorage.setItem('monthly_report_date', String(month))
      })
      .catch((error) => {
        dispatch(fetchReportFailure(error))
        dangerNotify(error.message)
      })
    closeModal()
  }

  return (
    <>
      <div className="filters-modal-wrapper">
        <div className="filters-modal-navbar">
          <div onClick={closeModalForm} className="filters-modal-back-arrow">
            <ArrowBackIcon />
          </div>
          <p className="filters-modal-navbar-text">Filter</p>
        </div>
        <div className="filters-modal-body">
          <div className="filters-modal-text-wrapper user-report__wrapper">
            <MonthlyReportFilters
              month={month}
              setMonth={setMonth}
              allStatuses={allStatuses}
              usersNames={usersNames}
              projects={projects}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              typeValue={typeValue}
              setTypeValue={setTypeValue}
              alignment={alignment}
              setAlignment={setAlignment}
              paramsType={paramsType}
              selectRef={selectRef}
              setPage={setPage}
            />
          </div>
          <div className="filters-modal-text-wrapper"></div>
          <div className="filters-modal-button-wrapper">
            <div onClick={closeModal} className="filters-modal-button">
              <p className="filters-modal-button-text">Show results ({reportRecordsCount})</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(MobileReportFilters)
