import { useDispatch } from 'react-redux'

import { useFetchVacancies } from './useFetchVacancies'
import { createVacancy } from 'services/API/vacancies'
import { addVacancy, addVacancyFailure, addVacancySuccess } from '../../store/vacanciesSlice'
import { Vacancy } from 'types'
import successNotify from '../../helpers/successNotify'
import dangerNotify from '../../helpers/dangerNotify'

export const useCreateVacancy = () => {
  const fetchVacancies = useFetchVacancies()
  const dispatch = useDispatch()

  return async (vacancy: Vacancy | Partial<Vacancy>, page: number, toggle: () => void) => {
    dispatch(addVacancy())
    await createVacancy(vacancy)
      .then((response) => {
        fetchVacancies(page)
        toggle()
        successNotify('Vacancy created')
        dispatch(addVacancySuccess(response.data))
      })
      .catch((error) => {
        dispatch(addVacancyFailure(error))
        error.response?.data.title[0] &&
        dangerNotify('Title ' + error.response?.data.title[0])
      })
  }
}
