import React from 'react'
import { Link } from 'react-router-dom'
import Switch from 'react-switch'

import { webRoutes } from '../../../../lib'
import { englishLevel, significate } from '../../../../constants'
import './styles.scss'

const CandidatesMobileTable = ({ candidates, onUpdateCandidate }) => {
  const onPing = (checked: boolean, id: number) => {
    const params = {
      id: id,
      ping_again: checked
    }
    onUpdateCandidate(params)
  }

  const onSignificateUpdate = (e, id) => {
    const params = {
      id: id,
      significate: e.target.value
    }
    onUpdateCandidate(params)
  }

  const significateFormatter = (candidate, sign, color) => {
    return candidate.significate
      ? <div className="candidates-mobile-significate label-significate" style={{ color: color }}>
        <select
          className="candidates-mobile-significate-select significate-select-main"
          placeholder="Significate"
          name="significate"
          onChange={(e) => onSignificateUpdate(e, candidate.id)}
          value={sign.value}>
          {Object.values(significate).map((s) => (
            <option key={s.value} value={s.value}>{s.name}</option>
          ))}
        </select>
      </div>
      : ''
  }

  const renderItem = () => (
    candidates.map(candidate => {
      const sign = significate[candidate.significate.toUpperCase()]
      const color = sign.color

      return (
        <div key={candidate.id} className="candidates-mobile-item-wrapper" style={{ color: color }}>
          <div className="candidates-mobile-item-left">
            <Link to={webRoutes.CANDIDATE(candidate.id)} className="candidates-mobile-item-full-name">
              {candidate.full_name}
            </Link>
            <p className="candidates-mobile-item-text">Mobile: {candidate.phone}</p>
            <p className="candidates-mobile-item-text">Email: {candidate.email}</p>
            <p className="candidates-mobile-item-text">Telegram: {candidate.telegram}</p>
            <p className="candidates-mobile-item-text">LinkedIn: {candidate.linkedin && 
                        <a href={candidate.linkedin} target="_blank" rel="noreferrer">
                          tap here to view</a>}</p>
            <p className="candidates-mobile-item-text">Experience: {candidate.experience}</p>
            <p className="candidates-mobile-item-text">Salary: ${candidate.salary}</p>
            <p className="candidates-mobile-item-text">
              English: {candidate.english ? englishLevel[candidate.english.toUpperCase()].label : ''}
            </p>
          </div>
          <div className="candidates-mobile-item-right">
            {significateFormatter(candidate, sign, color)}
            <Switch
              onColor="#32D583"
              checkedIcon={false}
              uncheckedIcon={false}
              width={50}
              offColor="#D4D9E2"
              height={30}
              onChange={(checked) => onPing(checked, candidate.id)}
              checked={candidate.ping_again} />
          </div>
        </div>
      )
    })
  )

  return (
    <div className="candidates-mobile-main-wrapper">
      {renderItem()}
      <div className="candidates-mobile-bottom-space" />
    </div>
  )
}

export default CandidatesMobileTable
