import React from 'react'
import DashboardNavigation from '../../../components/dashboard/desktop/dashboardNavigation'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Container } from 'reactstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { SingleValue } from 'react-select'

import { tableCellStyles, tableHeadStyles } from '../../../utilts/styles'
import { renderUserName } from '../../../helpers/renderName'
import { User } from '../../../types'
import { ILeave } from './usersLeavesTable'
import CustomPagination from '../../../components/reusable/Pagination/Pagination'
import { availableDayOff, availableSickLeave, availableVacation } from '../../../constants'
import { LeaveProgress } from '../index'
import DefaultSelect from '../../../components/custom/DefaultSelect/DefaultSelect'
import { dashboardFilterSelect } from '../../../components/dashboard/desktop/dashboardStyles'
import { IOptions } from '../../../types/ISelectOptions'
import UserIcon from '../../../icons/UserIcon'

interface IProps {
    leavesData: ILeave[]
    totalPages: number
    page: number
    setPage: (value: number) => void
    usersName: IOptions[]
    setSelectedUser: (newValue: SingleValue<IOptions | null>) => void
}

const tableCells = ['User', 'Sick Leaves', 'Extra Sick Leaves', 'Vacation', 'Unpaid Leaves', 'Day offs', 'Paid Leaves']

const DesktopLeavesPage: React.FC<IProps> = ({
  leavesData,
  totalPages,
  page,
  setPage,
  usersName,
  setSelectedUser
}) => {
  const usersNames = useSelector((state: { users: { indexNamesData: User[] } }) => state.users.indexNamesData)
  const sickLeaveCoefficient = 100 / availableSickLeave
  const vacationCoefficient = 100 / availableVacation
  const dayOffsCoefficient = 100 / availableDayOff
  const vacationProgressColor = '#32D583'
  const sickLeaveProgressColor = '#FEC84B'
  const dayOffProgressColor = '#4365b1'

  return (
    <Container fluid="xxl">
      <div className="dashboard_main__tabs">
        {!isMobile && <DashboardNavigation />}
        <div className="dashboard_main__select">
          <DefaultSelect
            styles={dashboardFilterSelect}
            options={usersName}
            onChange={(user) => setSelectedUser(user)}
            prefixIcon={<UserIcon/>}
            isClearable={true}
            placeholder="User"
          />
        </div>
      </div>
      <div className="dashboard_main__tableName">
        <h4>Leaves List</h4>
      </div>
      <TableContainer >
        <Table style={{ marginBottom: '2%' }}>
          <TableHead className="requests-table__title">
            <TableRow>
              {tableCells.map((tableName, index) =>
                <TableCell align="left" sx={tableHeadStyles} key={index}>
                  {tableName}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {leavesData?.map(l => {
              const sickLeavesCount = `${l.full_day_sick_leaves + (l.half_day_sick_leaves / 2)}`
              const unpaidLeavesCount = `${l.unpaid_leaves + (l.half_day_unpaid_leaves / 2)}`

              return <TableRow key={l.user_id}>
                <TableCell align="left" sx={tableCellStyles} style={{ width: '16%', verticalAlign: 'top' }}>
                  <Link to={`/users/${l.user_id}`}>
                    {renderUserName(l.user_id, usersNames)}
                  </Link>
                </TableCell>
                <TableCell align="left" sx={tableCellStyles} style={{ width: '14%', marginBottom: '15px' }}>
                  <LeaveProgress
                    color={sickLeaveProgressColor}
                    leaveCount={Number(sickLeavesCount)}
                    leaveCoefficient={sickLeaveCoefficient}
                    maxLeaveCount={availableSickLeave}
                  />
                </TableCell>
                <TableCell align="left" sx={tableCellStyles} style={{ width: '12%', marginBottom: '15px' }}>
                  {l.extra_sick_leaves}
                </TableCell>
                <TableCell align="left" sx={tableCellStyles} style={{ width: '16%', marginBottom: '15px' }}>
                  <LeaveProgress
                    color={vacationProgressColor}
                    leaveCount={l.vacations}
                    leaveCoefficient={vacationCoefficient}
                    maxLeaveCount={availableVacation}
                  />
                </TableCell>
                <TableCell align="left" sx={tableCellStyles} style={{ width: '14%', marginBottom: '15px' }}>
                  {unpaidLeavesCount}
                </TableCell>
                <TableCell align="left" sx={tableCellStyles} style={{ width: '14%', marginBottom: '15px' }}>
                  <LeaveProgress
                    color={dayOffProgressColor}
                    leaveCount={l.day_offs}
                    leaveCoefficient={dayOffsCoefficient}
                    maxLeaveCount={availableDayOff}
                  />
                </TableCell>
                <TableCell align="left" sx={tableCellStyles} style={{ width: '14%', marginBottom: '15px' }}>
                  {l.paid_leaves}
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPagination totalPages={totalPages} page={page} setPage={setPage} />
    </Container>
  )
}

export default DesktopLeavesPage
