import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Box } from '@mui/material'

import { useCreateProject } from 'hooks/project/useCreateProject'
import { createProjectSchema } from '../../../helpers/validationShema'
import { userRoles } from '../../../constants'
import { Customer, Technology, User, NewProject } from '../../../types'
import ProjectForm from '../ManageProjects/ProjectForm'
import ReactForm from 'components/reusable/ReactForm'
import CustomButton from 'components/custom/CustomButton/CustomButton'

interface Props {
  closeModal: () => void
  usersNames: User[]
  technologies: Technology[]
  customers: Customer[]
  page: number
}

export type ISelectOptions = {
  value: number
  label: string
} | undefined

export interface IOptions {
  value: number
  label: string
}

export interface IProjectInputs {
  customer: ISelectOptions
  name: string | undefined
  manager: ISelectOptions
  technologies: IOptions[]
  description: string
  industry: string
  from_scratch: boolean
  payment_integration: boolean
  applications: string[]
  designs: string
  milestones: string
  specs: string
  url: string
}

const CreateProject: React.FC<Props> = ({
  closeModal,
  usersNames,
  technologies,
  customers,
  page
}) => {
  const createProject = useCreateProject()
  const defaultValues: IProjectInputs = {
    customer: '',
    name: '',
    manager: '',
    technologies: [],
    description: '',
    industry: '',
    from_scratch: false,
    payment_integration: false,
    applications: [],
    designs: '',
    milestones: '',
    specs: '',
    url: ''
  }
  const resolver = yupResolver(createProjectSchema)
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({ resolver, defaultValues })

  const managers = usersNames?.filter((user) => user.role == userRoles.MANAGER.id || user.role == userRoles.ADMIN.id)
    .map((user) => ({
      value: user.id,
      label: user.full_name,
    }))

  const companies = customers?.map((company: Customer) => ({
    value: company.id,
    label: company.company_name,
  }))

  const tech_stack = technologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }))

  const onSubmit: SubmitHandler<IProjectInputs> = (data) => {
    const technologies_ids = data.technologies.map(technology => technology.value)

    const newProject: NewProject = {
      name: data.name,
      manager_id: data?.manager?.value,
      from_scratch: data.from_scratch,
      description: data.description || '',
      industry: data.industry || '',
      customer_id: data?.customer?.value,
      payment_integration: data.payment_integration,
      technologies: technologies_ids,
      app: data.applications,
      designs: data.designs,
      milestones: data.milestones,
      specs: data.specs,
      url: data.url
    }
    createProject(newProject, page)
    closeModal()
  }

  return (
    <ReactForm onSubmit={handleSubmit(onSubmit)}>
      <ProjectForm
        defaultValues={defaultValues}
        register={register}
        control={control}
        companies={companies}
        errors={errors}
        tech_stack={tech_stack}
        managers={managers}
      />
      <Box sx={{ display: 'flex', gap: '1rem', marginTop: '40px' }}>
        <CustomButton variant="outlined" onClick={() => closeModal()} text="Cancel"/>
        <CustomButton type="submit" text="Create" />
      </Box>
    </ReactForm>
  )
}

export default React.memo(CreateProject)
