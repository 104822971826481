import React from 'react'
import Box from '@mui/material/Box'

import ReactInput from '../../../../reusable/ReactInput'
import { UseFormRegister } from 'react-hook-form'
import { ICertificateInputs } from './CvAddCertificate'


type IProps = {
  register: UseFormRegister<ICertificateInputs>
}

const CvCertificateForm: React.FC<IProps> = ({ register }) => {
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <ReactInput
            className="modal__input"
            type="text"
            label="Certificate name"
            register={register}
            registerControl="name"
          />
          <ReactInput
            className="modal__input"
            type="text"
            label="Certificate url"
            register={register}
            registerControl="url"
          />
        </Box>
      </Box>
    </>
  )
}

export default CvCertificateForm
