import { useDispatch } from 'react-redux'

import dangerNotify from '../../helpers/dangerNotify'
import { updateUserInfo } from '../../services/API/users'
import { UserProfile } from '../../types'
import successNotify from '../../helpers/successNotify'
import { useGetUsersProfile } from './useGetProfile'
import { updateProfile, updateProfileFailure, updateProfileSuccess } from '../../store/profileSlice'

export const useUpdateUsersProfile = () => {
  const dispatch = useDispatch()
  const getUsersProfile = useGetUsersProfile()

  return (userInfo: UserProfile, userId: number) => {
    dispatch(updateProfile())
    updateUserInfo(userInfo, userId )
      .then((response) => {
        dispatch(updateProfileSuccess(response.data))
        successNotify('Profile updated')
        getUsersProfile(userId)
      })
      .catch((error) => {
        dispatch(updateProfileFailure(error))
        dangerNotify('Server error: unable to update profile')
      })
  }
}
