import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Cable } from 'actioncable'
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import AppRouter from './components/utils/AppRouter'
import SiteNavbar from './components/utils/SiteNavbar'
import { AuthContext } from './context/auth'
import { IAuthContext } from './types/AuthContext'
import { ICurrentUser } from './types'
import './App.scss'
import { getCurrentUser } from 'services/API/session'
import Breadcrumbs from './components/breadcrumbs/Breadcrumbs'
import { fetchNotificationsSuccess } from './store/notificationsSlice'
import { useFetchNotifications } from './hooks/notification/useFetchNotifications'
import { useGetUsersNames } from './hooks/user/useGetUsers'
import { isAdmin, isManager } from './helpers/accessHelpers'
import { useGetUserProjects } from './hooks/project/useGetProjects'
import { localStorageUser } from 'helpers/localStorageUser'
import { webRoutes } from './lib'
import { isCvPage } from './helpers/isCvPage'
import { useGetSubRoles } from 'hooks/sub-role/useGetSubRoles'
import { UtilityConsumer, UtilityProvider } from 'context/utility'

interface IProps {
  cable: Cable
}

const App: React.FC<IProps> = ({ cable }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [authToken, setAuthToken] = useState<string | null>()
  const [currentUser, setCurrentUser] = useState<ICurrentUser | null>()
  const getUserNotifications = useFetchNotifications()
  const getUsersNames = useGetUsersNames()
  const getProjectsHook = useGetUserProjects()
  const getSubRoles = useGetSubRoles()
  const notifications = useSelector((state) => state.notifications.indexData)
  const isNavigateToLoginPage = !localStorageUser && !isCvPage(location.pathname)

  const sampleAuthContext: IAuthContext = {
    currentUser: currentUser,
    setCurrentUser: setCurrentUser,
    authToken: authToken,
    setAuthToken: setAuthToken,
  }

  useEffect(() => {
    getCurrentUser().then((response) => {
      setCurrentUser(response.data)
    })
  }, [])

  // Workaround for local development

  // useEffect(() => {
  //   if (authToken) document.cookie = `jwt=${authToken}`
  // }, [authToken])

  useEffect(() => {
    if (currentUser?.id) {
      if (isManager(currentUser) || isAdmin(currentUser)) {
        getUsersNames()
      }
      getProjectsHook()
    }
  }, [currentUser?.id])

  useEffect(() => {
    isNavigateToLoginPage && navigate(webRoutes.HOME)
  }, [])

  useEffect(() => {
    if (currentUser?.id) {
      getUserNotifications()
      getSubRoles()
      cable.subscriptions.create(
        { channel: 'NotificationChannel' },
        {
          received: (notification) => {
            dispatch(fetchNotificationsSuccess([notification.data, ...notifications]))
            getUserNotifications()
            getUsersNames()
          },
        },
      )
    }
  }, [currentUser?.id])

  return (
    <AuthContext.Provider value={sampleAuthContext}>
      <UtilityProvider>
        <ToastContainer />
        <SiteNavbar />
        <UtilityConsumer>{({ width }) => width > 1199.98 && <Breadcrumbs />}</UtilityConsumer>
        <AppRouter />
      </UtilityProvider>
    </AuthContext.Provider>
  )
}

export default React.memo(App)
