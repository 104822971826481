import { createSlice } from '@reduxjs/toolkit'

import { Child } from '../types'

const initialState = {
  indexData: <Child[]>[],
  showData: <Child>{},
  error: {},
  isLoading: true,
}

const childrenSlice = createSlice({
  name: 'children',
  initialState,
  reducers: {
    fetchChildren(state) {
      state.isLoading = true
    },
    fetchChildrenSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchChildrenFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    addChild(state) {
      state.isLoading = true
    },
    addChildSuccess(state, action) {
      state.indexData.push(action.payload)
      state.isLoading = false
    },
    addChildFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    updateChild(state) {
      state.isLoading = true
    },
    updateChildSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    updateChildFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    removeChild(state) {
      state.isLoading = true
    },
    removeChildSuccess(state, action) {
      state.indexData = state.indexData.filter((child: Child) => child.id !== action.payload)
      state.isLoading = false
    },
    removeChildFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default childrenSlice.reducer
export const {
  fetchChildren,
  fetchChildrenSuccess,
  fetchChildrenFailure,
  addChild,
  addChildSuccess,
  addChildFailure,
  removeChild,
  removeChildSuccess,
  removeChildFailure,
  updateChild,
  updateChildSuccess,
  updateChildFailure
} = childrenSlice.actions
