import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'

import recordsSlice from './recordsSlice'
import projectsSlice from './projectsSlice'
import categoriesSlice from './categoriesSlice'
import contractsSlice from './contractsSlice'
import usersSlice from './usersSlice'
import customersSlice from './customersSlice'
import technologiesSlice from './technologiesSlice'
import requestsSlice from './requestsSlice'
import loaderSlice from './loaderSlice'
import monthlyReportSlice from './montlyReportSlice'
import resumesSlice from './resumesSlice'
import vacanciesSlice from './vacanciesSlice'
import candidatesSlice from './candidatesSlice'
import inventoriesSlice from './inventoriesSlice'
import childrenSlice from './childrenSlice'
import profileSlice from './profileSlice'
import userLeavesSlice from './userLeavesSlice'
import availableContractsSlice from './availableContractsSlice'
import notificationsSlice from './notificationsSlice'
import subRolesSlice from './subRolesSlice'

const rootReducer = combineReducers({
  availableContracts: availableContractsSlice,
  candidates: candidatesSlice,
  categories: categoriesSlice,
  children: childrenSlice,
  contracts: contractsSlice,
  customers: customersSlice,
  inventories: inventoriesSlice,
  loader: loaderSlice,
  monthlyReport: monthlyReportSlice,
  notifications: notificationsSlice,
  profile: profileSlice,
  projects: projectsSlice,
  records: recordsSlice,
  requests: requestsSlice,
  resumes: resumesSlice,
  subRoles: subRolesSlice,
  technologies: technologiesSlice,
  userLeaves: userLeavesSlice,
  users: usersSlice,
  vacancies: vacanciesSlice
})

const logger = createLogger({
  collapsed: true,
})
const devEnv = process.env.NODE_ENV != 'production'

export default configureStore({
  reducer: rootReducer,
  middleware: devEnv ? [logger] : [],
  devTools: devEnv,
})

export type AppState = ReturnType<typeof rootReducer>
