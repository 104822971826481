import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { DatePicker, DateRangePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import uaLocale from 'date-fns/locale/uk'
import dayjs from 'dayjs'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import EventIcon from '@mui/icons-material/Event'

import { dayTime, paidLeave, sickLeaveVariants, typeParams, vacationTypes } from '../../constants'
import { Type } from '../../enums/RequestEnums'
import DefaultSelect from '../custom/DefaultSelect/DefaultSelect'
import DefaultInput from '../custom/DefaultInput/DefaultInput'
import { minDateDisable } from '../../helpers/enableCurrentWeek'
import addFileIcon from '../../images/attach.png'
import { WeekDay } from '../../enums/WeekDays'
import { isMobile } from 'react-device-detect'

interface Props {
  disableTwoWeeks?: boolean
  disableOneWeek?: boolean
  type: { value: number; label: string }
  dayTimePart: { value: number; label: string }
  control: any
  errors: any
  onIllnesFileChange: (event: { target: { files: React.SetStateAction<undefined>[] } }) => void
  register: any
  startDate: Date | null
  endDate: Date | null
  setWorkOffDay: (date: Date | null) => void
  workOffDay: Date | null
  handleClose: () => void
  setDate: Dispatch<SetStateAction<null[]>>
}

const RequestForm: React.FC<Props> = ({
  disableOneWeek,
  disableTwoWeeks,
  type,
  dayTimePart,
  control,
  errors,
  onIllnesFileChange,
  register,
  startDate,
  endDate,
  setWorkOffDay,
  workOffDay,
  handleClose,
  setDate,
}) => {
  const isWorkOff = type.value === Type.WORK_OFF
  const isSickLeave = type.value === Type.SICK_LEAVE && dayTimePart.label === sickLeaveVariants.halfDay
  const isUnpaidLeave = type.value === Type.UNPAID_LEAVE && dayTimePart.label === sickLeaveVariants.halfDay
  const isDayOff = type.value === Type.DAY_OFF
  const { ref, ...descriptionField } = register('note')
  const startInputRef = useRef<HTMLInputElement>()
  const endInputRef = useRef<HTMLInputElement>()

  const [openDtpckr, setOpenDtpckr] = useState(false)

  const sortedTypeParams = () => {
    return typeParams.sort((a, b) => (a.label > b.label ? 1 : -1))
  }

  const disableWeekends = (date: Date) => {
    return date.getDay() === WeekDay.SUNDAY || date.getDay() === WeekDay.SATURDAY
  }

  return (
    <div className="requests__inputs_container">
      <div className="requests__modal-padd-select">
        <Controller
          name="type"
          control={control}
          render={({ field }) => <DefaultSelect placeholder="Type" {...field} options={sortedTypeParams()} />}
        />
      </div>
      {errors?.type && <p className="form_select__error">This field is required</p>}
      {(type?.value === Type.UNPAID_LEAVE || type?.value === Type.SICK_LEAVE) && (
        <div className="requests__modal-padd-select">
          <Controller
            name="dayTime"
            control={control}
            render={({ field }) => <DefaultSelect placeholder="Part of day" {...field} options={dayTime} />}
          />
        </div>
      )}
      {type?.value === Type.VACATION && (
        <div className="requests__modal-padd-select">
          <Controller
            name="vacationType"
            control={control}
            render={({ field }) => (
              <DefaultSelect
                placeholder="Type of vacation"
                {...field}
                options={vacationTypes(disableOneWeek, disableTwoWeeks)}
                isOptionDisabled={(option) => option.isDisabled}
                isDisabled={disableOneWeek && disableTwoWeeks}
              />
            )}
          />
          <span className="requests__modal-error">
            {disableOneWeek && disableTwoWeeks
              ? 'You have already taken the maximum number of vacations.'
              : errors?.vacationType
                ? 'This field is required'
                : null}
          </span>
        </div>
      )}
      {type?.value === Type.PAID_LEAVE && (
        <div className="requests__modal-padd-select">
          <Controller
            name="paidLeave"
            control={control}
            render={({ field }) => <DefaultSelect placeholder="Type of paid leave" {...field} options={paidLeave} />}
          />
        </div>
      )}
      <div className="requests__modal-padd-select">
        <div>
          <DefaultInput placeholder="Write a note here" type="text" innerRef={ref} {...descriptionField} />
        </div>
      </div>
      {errors.note && <p className="form_select__error">{errors.note.message}</p>}
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={uaLocale}>
        <div className="modal__input requests__modal-datepicker">
          {isWorkOff || isSickLeave || isUnpaidLeave ? (
            <DatePicker
              open={openDtpckr}
              onOpen={() => setOpenDtpckr(true)}
              onClose={() => setOpenDtpckr(false)}
              minDate={minDateDisable(new Date())}
              maxDate={dayjs().add(1, 'M').endOf('month').toDate()}
              mask="__.__.____"
              label={
                isSickLeave
                  ? 'Half day sick Leave'
                  : isUnpaidLeave
                    ? 'Half day unpaid Leave'
                    : isDayOff
                      ? 'Day off leave'
                      : 'Work-Off day'
              }
              value={workOffDay}
              shouldDisableDate={disableWeekends}
              onChange={setWorkOffDay}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="w-100"
                  required
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  onClick={() => setOpenDtpckr(true)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            startInputRef.current?.focus()
                          }}
                        >
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          ) : (
            <DateRangePicker
              minDate={minDateDisable(new Date())}
              mask="__.__.____"
              value={[startDate, endDate]}
              onChange={setDate}
              renderInput={(startProps, endProps) => (
                <div className="modal__double-field">
                  <TextField
                    inputRef={startInputRef}
                    {...startProps}
                    required
                    inputProps={{ ...startProps.inputProps, readOnly: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              startInputRef.current?.focus()
                              startInputRef.current?.click()
                            }}
                          >
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    inputRef={endInputRef}
                    {...endProps}
                    inputProps={{ ...endProps.inputProps, readOnly: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              endInputRef?.current?.focus()
                              startInputRef.current?.click()
                            }}
                          >
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
            />
          )}
          {errors?.type && <p className="form_select__error">This field is required</p>}

          {!isMobile && (
            <div>
              <label onChange={onIllnesFileChange} htmlFor="formId">
                <input name="" type="file" accept="image/png, image/jpeg" id="formId" hidden />
                <img className="icon__add-file" src={addFileIcon} alt="Add file" />
              </label>
            </div>
          )}
        </div>

        {isMobile && (
          <div>
            <label onChange={onIllnesFileChange} htmlFor="formId">
              <input name="" type="file" accept="image/png, image/jpeg" id="formId" hidden />
              <img className="icon__add-file m-0" src={addFileIcon} alt="Add file" />
            </label>
          </div>
        )}
      </LocalizationProvider>

      <div className="modal__double-field my-4">
        <Button className="modal__button modal__cancel" onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button className="modal__button modal__submit" type="submit" variant="contained">
          Create
        </Button>
      </div>
    </div>
  )
}

export default RequestForm
