import { useDispatch } from 'react-redux'

import {
  fetchProjectContracts,
  fetchProjectContractsFailure,
  fetchProjectContractsSuccess,
} from '../../store/contractsSlice'
import { getProjectContracts } from '../../services/API/admin/projects'
import dangerNotify from '../../helpers/dangerNotify'

export const useGetProjectContracts = () => {
  const dispatch = useDispatch()

  return (id: number, page: number, setTotalPages: (pages: number) => void) => [
    dispatch(fetchProjectContracts()),
    getProjectContracts(id, page)
      .then((response) => {
        dispatch(fetchProjectContractsSuccess(response?.data))
        setTotalPages(response.data.pages)
      })
      .catch((error) => {
        dispatch(fetchProjectContractsFailure(error))
        dangerNotify(error)
      }),
  ]
}
