import React, { useEffect, useState } from 'react'
import { Form, FormGroup, Input, Row, Col, Button, Label } from 'reactstrap'
import { useSelector } from 'react-redux'

import { Vacancy } from '../../../types'
import { useCreateVacancy } from '../../../hooks/vacancies/useCreateVacancy'
import { useUpdateVacancy } from '../../../hooks/vacancies/useUpdateVacancy'
import { vacancyLevels } from '../../../constants'
import Modal from 'components/reusable/Modal'

interface Props {
  isNewRecord: boolean
  isOpen: boolean
  toggle: () => void
  page: number
}

const VacancyForm: React.FC<Props> = ({ isNewRecord, isOpen, toggle, page }) => {
  const initialVacancy: Partial<Vacancy> = {
    title: '',
    where_published: '',
    url: '',
    level: 'junior',
    min_salary: '',
    max_salary: '',
  }

  const [vacancyInfo, setVacancyInfo] = useState<Partial<Vacancy>>(initialVacancy)
  const vacancy = useSelector((state: { vacancies: { showData: Vacancy } }) => state.vacancies.showData)
  const createVacancy = useCreateVacancy()
  const updateVacancy = useUpdateVacancy()

  useEffect(() => {
    if (!isNewRecord) {
      setVacancyInfo({ ...vacancy, level: vacancy.level ? vacancy.level : 'junior' })
    }
  }, [vacancy])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const newVacancy = { ...vacancyInfo, [name]: value }

    setVacancyInfo(newVacancy)
  }

  const onSubmit = () => {
    isNewRecord ? createVacancy(vacancyInfo, page, toggle) : updateVacancy(vacancyInfo, page, toggle)
    setVacancyInfo(initialVacancy)
  }

  return (
    <Modal
      title={isNewRecord ? 'Add New Vacancy' : 'Edit Vacancy'}
      open={isOpen}
      handleClose={toggle}
      component={
        <>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    bsSize={'lg'}
                    placeholder="Title"
                    name="title"
                    onChange={(e) => onChange(e)}
                    value={vacancyInfo.title}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    bsSize={'lg'}
                    name="where_published"
                    placeholder="Where published"
                    onChange={(e) => onChange(e)}
                    value={vacancyInfo.where_published}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Input
                    name="url"
                    placeholder="Vacancy link"
                    onChange={(e) => onChange(e)}
                    bsSize={'lg'}
                    value={vacancyInfo.url}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Input
                    name="level"
                    placeholder="Desired level"
                    onChange={(e) => onChange(e)}
                    bsSize={'lg'}
                    type="select"
                    value={vacancyInfo.level}
                  >
                    {Object.values(vacancyLevels).map((v) => (
                      <option key={v.value} value={v.value}>
                        {v.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="labeled">
                  <Label>Salary range</Label>
                  <Input
                    name="min_salary"
                    onChange={(e) => onChange(e)}
                    placeholder="$0"
                    bsSize={'lg'}
                    value={vacancyInfo.min_salary}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Input
                    name="max_salary"
                    placeholder="$0"
                    onChange={(e) => onChange(e)}
                    bsSize={'lg'}
                    value={vacancyInfo.max_salary}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col md={6}>
              <Button onClick={toggle} size={'lg'} block color="light">
                Cancel
              </Button>
            </Col>
            <Col md={6}>
              <Button onClick={onSubmit} size={'lg'} block color="dark">
                {isNewRecord ? 'Create' : 'Update'}
              </Button>
            </Col>
          </Row>
        </>
      }
    />
  )
}

export default VacancyForm
