import { createSlice } from '@reduxjs/toolkit'

import { Contract } from '../types'

const initialState = {
  indexData: <Contract[]>[],
  showData: <Contract>{},
  indexLogsData: <Contract[]>[],
  indexLogsUserData: { paginate_contracts: <Contract[]>[], pages: 1 },
  error: {},
  isLoading: true,
}

//TODO: add GET actions for edit modal
const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    fetchContracts(state) {
      state.isLoading = true
    },
    fetchContractsSuccess(state, action) {
      state.indexData = action.payload
      state.isLoading = false
    },
    fetchContractsFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    fetchUserContracts(state) {
      state.isLoading = true
    },
    fetchUserContractsSuccess(state, action) {
      state.indexLogsUserData = action.payload
      state.isLoading = false
    },
    fetchUserContractsFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    fetchProjectContracts(state) {
      state.isLoading = true
    },
    fetchProjectContractsSuccess(state, action) {
      state.indexLogsData = action.payload
      state.isLoading = false
    },
    fetchProjectContractsFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    addContract(state) {
      state.isLoading = true
    },
    addContractSuccess(state, action) {
      state.indexData.push(action.payload)
      state.isLoading = false
    },
    addContractFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    updateContract(state) {
      state.isLoading = true
    },
    updateContractSuccess(state, action) {
      state.showData = action.payload
      state.isLoading = false
    },
    updateContractFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },

    removeContract(state) {
      state.isLoading = true
    },
    removeContractSuccess(state, action) {
      state.indexData = state.indexData.filter((contract: Contract) => contract.id !== action.payload)
      state.isLoading = false
    },
    removeContractFailure(state, action) {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export default contractsSlice.reducer
export const {
  fetchContracts,
  fetchContractsSuccess,
  fetchContractsFailure,
  fetchProjectContracts,
  fetchProjectContractsSuccess,
  fetchProjectContractsFailure,
  addContract,
  addContractFailure,
  addContractSuccess,
  removeContract,
  removeContractFailure,
  removeContractSuccess,
  updateContract,
  updateContractFailure,
  updateContractSuccess,
  fetchUserContractsSuccess,
  fetchUserContracts,
  fetchUserContractsFailure,
} = contractsSlice.actions
