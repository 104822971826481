import React from 'react'
import { UseFormRegister } from 'react-hook-form'

import ReactInput from '../../reusable/ReactInput/ReactInput'
import { IEditProjectInputs } from '../EditProject/EditProject'
import { IProjectInputs } from '../CreateProject/CreateProject'

interface Props {
    register: UseFormRegister<IEditProjectInputs> | UseFormRegister<IProjectInputs>
}

const DesktopEditProjectLinks: React.FC<Props> = ({
  register,
}) => {
  return (
    <>
      <div className="project-profile_links-wrap">
        <div className="project-profile_links-item mrg-bottm24px">
          <ReactInput 
            type="text" label="Project URL" register={register} registerControl="url" />
        </div>
        <div className="project-profile_links-item mrg-bottm24px">
          <ReactInput 
            type="text" label="Design URL" register={register} registerControl="designs" />
        </div>
      </div>
      <div className="project-profile_links-wrap">
        <div className="project-profile_links-item mrg-bottm24px">
          <ReactInput type="text" label="Milestones URL" register={register} registerControl="milestones" />
        </div>
        <div className="project-profile_links-item mrg-bottm24px">
          <ReactInput type="text" label="Specs URL" register={register} registerControl="specs" />
        </div>
      </div>
    </>
  )
}

export default DesktopEditProjectLinks
