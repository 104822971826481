import { useDispatch } from 'react-redux'

import { useGetAdminRequests } from './useGetRequests'
import { updateRequest } from 'services/API/request'
import { IUpdateRequest } from 'types/Request/IUpdateRequest'
import { updateRequestSuccess, updateRequestFailure } from '../../store/requestsSlice'
import { nullableRequestParams } from '../../constants'
import dangerNotify from '../../helpers/dangerNotify'
import { RequestLimits } from 'enums/RequestEnums'
import successNotify from 'helpers/successNotify'
import { wasLastOnPage } from '../../helpers/paginationPage'
import { PaginateLimit } from '../../enums/RequestEnums'

type Props = {
  request: IUpdateRequest
  by_date: string | null
  by_month: string | null
  by_user_id?: number
  requestCount?: number | undefined
  message?: string | undefined
}

export const useUpdateRequest = () => {
  const getAdminRequestsHook = useGetAdminRequests()
  const dispatch = useDispatch()

  return ({ request, by_date, by_month, by_user_id, requestCount, message }: Props) => {
    const lastOnPage = wasLastOnPage(requestCount as number, RequestLimits.DASHBOARD_REQUEST_LIMIT, request?.page)
      ? request?.page - PaginateLimit.CURRENT_ITEM
      : request?.page

    updateRequest(request)
      .then(() => {
        dispatch(updateRequestSuccess())
        getAdminRequestsHook({
          page: lastOnPage,
          limit: RequestLimits.REQUEST_DASHBOARD_LIMIT,
          by_date,
          by_month,
          by_user_id
        })
        successNotify(message as string)
      })
      .catch((error) => {
        dispatch(updateRequestFailure(error))
        dangerNotify('Update error.')
      })
  }
}
