import { updateRecord, updateAdminRecord } from '../../services/API/record'
import { useGetAdminUserRecords, useGetRecords } from './useGetRecords'
import dangerNotify from '../../helpers/dangerNotify'
import { UpdatedRecord } from '../../types/UpdatedRecord'
import { getRecordsParams } from '../../constants'
import successNotify from '../../helpers/successNotify'

export const useUpdateRecord = () => {
  const getRecordsHook = useGetRecords()

  return (params: UpdatedRecord) => {
    updateRecord(params)
      .then(() => {
        successNotify('Record updated')
        getRecordsHook(getRecordsParams.project_id, getRecordsParams.year, getRecordsParams.month)
      })
      .catch(({ response: { data } }) => {
        dangerNotify(
          Object.keys(data)
            .map((key) => data[key])
            .flat(Infinity)
            .join(', '),
        )
      })
  }
}

export const useUpdateAdminRecord = () => {
  const getRecordsHook = useGetAdminUserRecords()

  return (params: UpdatedRecord) => {
    updateAdminRecord(params)
      .then(() => {
        successNotify('Record updated')
        getRecordsHook(
          getRecordsParams.user_id,
          getRecordsParams.year,
          getRecordsParams.month,
          getRecordsParams.project_id,
        )
      })
      .catch(({ response: { data } }) => {
        dangerNotify(
          Object.keys(data)
            .map((key) => data[key])
            .flat(Infinity)
            .join(', '),
        )
      })
  }
}
